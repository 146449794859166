import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../../assets/Layout'
import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { addPropertySubType, getAllPropertyCategory, uploadDocToS3, getAllSubCategory, deleteSubCategory, updatePropertySubType } from '../../actions';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import { DeleteForever, Draw, Check } from '@mui/icons-material';
import TableStyle from '../../assets/TableStyle';
import { enqueueSnackbar } from 'notistack';



export default function PropertyType() {

    const [propertyType, setPropertyType] = useState("");
    const [parentPropertyType, setParentPropertyType] = useState("");
    const [allProperty, setAllProperty] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState([]);
    const [tempIndex, setTempIndex] = useState("");
    const [tempPropertyType, setTempPropertyType] = useState("");
    const [tempSubPropertyType, setTempSubPropertyType] = useState("");

    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer'
    };

    const dispatch = useDispatch();
    const handleUploadImage = (e) => {
        const file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('documents', file);
            dispatch(uploadDocToS3(formData, (data) => {
            }, (err) => {
                console.log(err);
            }))
        }
    }

    const loadAllPropertyCategory = useCallback(() => {
        dispatch(getAllPropertyCategory({}, (data) => {
            setAllProperty(data.data);
        }))
    }, [dispatch]);

    useEffect(() => {
        loadAllPropertyCategory();
    }, [loadAllPropertyCategory])

    const loadAllPropertySubCategory = useCallback(() => {
        dispatch(getAllSubCategory("", {}, (data) => {
            setAllSubCategories(data);
        }))
    }, [dispatch]);

    useEffect(() => {
        loadAllPropertySubCategory();
    }, [loadAllPropertySubCategory])



    const getPropertyType = (id) => {
        return allProperty.find((item) => { return item._id === id }) != undefined ? allProperty.find((item) => { return item._id === id }).name : "Unknown";
    }

    const handleDelete = (index) => {
        dispatch(deleteSubCategory(allSubCategories[index]._id, {}, (data) => {
            enqueueSnackbar("Data deleted succussfully", { variant: 'success' });
            loadAllPropertySubCategory();
        }))
    }

    const handleUpdate = (index) => {
        let data = [...allSubCategories];
        data[index] = { ...data[index], parentId: tempPropertyType, name: tempSubPropertyType };
        //call api here and send data in payload
        setAllSubCategories(data);
        let formData = new FormData();
        formData.append("name", tempSubPropertyType);
        formData.append("parentId", tempPropertyType);
        formData.append("id", data[index]._id);
        dispatch(updatePropertySubType(formData, (data) => {
            enqueueSnackbar("Data updated successfully", { variant: 'success' });
        }, (err)=>{
            enqueueSnackbar("Error Occured! Please try again", {variant:'error'});
        }))
        setTempIndex("");
    }

    const handleEdit = (index) => {
        setTempIndex(index);
        setTempPropertyType(allSubCategories[index].parentId);
        setTempSubPropertyType(allSubCategories[index].name);
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (!propertyType || !parentPropertyType) {
            enqueueSnackbar("Please add all the required fields", { variant: 'error' });
        } else {
            let payload = {}
            if (parentPropertyType) {
                payload = {
                    name: propertyType,
                    parentId: parentPropertyType
                }
            } else {
                payload = {
                    name: propertyType,
                }
            }


            dispatch(addPropertySubType(payload, (data) => {
                if (data.data.status == 200) {
                    enqueueSnackbar(data.data.message, { variant: 'success' });
                    loadAllPropertySubCategory();
                    setPropertyType("");
                    setParentPropertyType("");
                } else {
                    enqueueSnackbar("Error Occured", { variant: 'error' });
                }
            }))
        }
    }
    return (
        <Layout>
            <Paper>
                <Box p={4}>
                    <Grid container spacing={4}>
                        <Grid item md={5} >
                            <Typography sx={{ fontWeight: '600', fontSize: '28px', color: '#000000', marginBottom: '48px' }}>Property Type</Typography>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <label >Property Type</label>
                                        <input onChange={e => setPropertyType(e.target.value)} value={propertyType} className='inputBox' type="text" placeholder="Property Type" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <label >Parent Property Type</label>
                                        <select value={parentPropertyType} onChange={(e) => setParentPropertyType(e.target.value)}>
                                            <option disabled selected value={""}>Choose parent property</option>
                                            {allProperty.map((item, index) => (
                                                <option key={index} value={item._id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </Grid>
                                    <Grid item xs={12}>

                                        {/* <UploadDataBox>
                                            <UploadData name={'Property Type Icon'} mt={0}>
                                                <UploadDataCard>
                                                    <img
                                                        src={'/Images/uploadImg.png'}
                                                        alt="upload"
                                                        className="img-fluid"
                                                    />
                                                    <input type="file" accept="image/*" onChange={(e) => handleUploadImage(e)} />
                                                </UploadDataCard>
                                            </UploadData>
                                        </UploadDataBox> */}
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Button type='submit' variant="contained" sx={{ padding: '13px', mt: 5 }} fullWidth>Add</Button>

                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                        <Grid item md={7} my={5}>
                            <TableStyle>
                                <tr>
                                    {/* <th>Icon</th> */}
                                    <th>Property Type</th>
                                    <th>Child Property Type</th>
                                    <th>Action</th>
                                </tr>
                                <tbody>
                                    {allSubCategories && allSubCategories.length > 0 && allSubCategories.map((item, index) => (
                                        <>
                                            {item.isDeleted
                                                ?
                                                ""
                                                :
                                                <tr key={index}>
                                                    {/* <td>
                                                <img
                                                    src="/Images/area-image.png"
                                                    alt="Bank Icon"
                                                    className="icon"
                                                />
                                            </td> */}
                                                    <td>
                                                        {tempIndex === index ?
                                                            <select style={{ width: '90%' }} value={tempPropertyType} onChange={(e) => setTempPropertyType(e.target.value)}>
                                                                <option disabled selected>Choose parent property</option>
                                                                {allProperty.map((item, index) => (
                                                                    <option key={index} value={item._id}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                            :
                                                            getPropertyType(item.parentId)}
                                                    </td>

                                                    <td>{tempIndex === index
                                                        ?
                                                        <input style={{ width: '90%' }} onChange={e => setTempSubPropertyType(e.target.value)} value={tempSubPropertyType} className='inputBox' type="text" placeholder="Property Type" />
                                                        :
                                                        item.name}</td>
                                                    <td>
                                                        <Stack
                                                            direction={'row'}
                                                            spacing={2}
                                                            justifyContent={'center'}
                                                        >
                                                            <div>
                                                                {tempIndex === index
                                                                    ?
                                                                    <IconButton size="small" color="primary" onClick={() => handleUpdate(index)}>
                                                                        <Check />
                                                                    </IconButton>
                                                                    :
                                                                    <IconButton size="small" color="primary" onClick={() => handleEdit(index)}>
                                                                        <Draw />
                                                                    </IconButton>
                                                                }
                                                            </div>
                                                            <div>
                                                                <IconButton color="error" size="small" onClick={() => handleDelete(index)}>
                                                                    <DeleteForever />
                                                                </IconButton>
                                                            </div>
                                                        </Stack>
                                                    </td>
                                                </tr>}
                                        </>
                                    ))}
                                </tbody>
                            </TableStyle>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </Layout>
    )
}
