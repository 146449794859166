import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadDocToS3, getAllSpecification, getAllpropertySubCategory, getPropertyById, getPropertyCategoryById, getAllFurnishingDetails } from '../../actions';
import { Add, Check, Delete, Done, Draw, Info, ModeEditOutline } from '@mui/icons-material';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import { enqueueSnackbar } from 'notistack';
import deleteSvg from "../../assets/img/bin13.svg";
import editSvg from "../../assets/img/Light.png";
import ClearIcon from '@mui/icons-material/Clear';

const FloorPlanForm = () => {
  const navigate = useNavigate();
  const [propertyID, setPropertyID] = useState("");
  const [propertyType, setPropertyType] = useState('');
  const [constructionStatus, setConstructionStatus] = React.useState('');
  const [specifications, setSpecifications] = React.useState([]);
  const [specification, setSpecification] = React.useState('');
  const [quanitity, setQuantity] = React.useState('');
  const [subPropertyID, setSubPropertyID] = React.useState('');
  const [subProperty, setSubProperty] = React.useState('');
  const [furnishing, setFurnishing] = React.useState([]);
  const [tempIndex, setTempIndex] = React.useState('');
  const [tempSpecification, setTempSpecification] = React.useState('');
  const [tempQuantity, setTempQuantity] = React.useState('');
  const [possessionDate, setPossessionDate] = React.useState("");
  const [enterMinPrice, setEnterMinPrice] = React.useState("");
  const [enterMaxPrice, setEnterMaxPrice] = React.useState("");
  const [enterPrice, setEnterPrice] = React.useState("");
  const [minCarpetSize, setMinCarpetSize] = React.useState("");
  const [maxCarpetSize, setMaxCarpetSize] = React.useState("");
  const [carpetSize, setCarpetSize] = React.useState("");
  const [basicQuantity, setBasicQuantity] = React.useState("");
  const [plotSizeLength, setPlotSizeLength] = React.useState("");
  const [plotSizeBreath, setPlotSizeBreath] = React.useState("");
  const [floorPlanImage, setFloorPlanImage] = React.useState([]);
  const [floorPlanImagePicView, setFloorPlanImagePicView] = React.useState([]);
  const [unitType, setUnitType] = React.useState("");
  const [floorPlans, setFloorPlans] = React.useState([]);
  const [tempIndex1, setTempIndex1] = React.useState("");
  const [tempFloorPlanImage, setTempFloorImage] = React.useState([]);
  const [tempUnitType, setTempUnitType] = React.useState("");
  const [tempCarpetSize, setTempCarpetSize] = React.useState("");
  const [tempFurnishing, setTempFurnishing] = React.useState([]);
  const [tempEnterPrice, setTempEnterPrice] = React.useState("");
  const [tempSpecifications, setTempSpecifications] = React.useState([]);
  const [tempQuantiti, setTempQuantiti] = React.useState("");
  const [allSpecifications, setAllSpecifications] = React.useState([]);
  const [tempIndex2, setTempIndex2] = React.useState("");
  const [furnishingQuantity, setFurnishingQuantity] = React.useState("");
  const [furnishings, setFurnishings] = React.useState([]);
  const [tempFurnishingQuantity, setTempFurnishingQuantity] = React.useState("");
  const [tempFurnishingDetail, setTempFurnishingDetail] = React.useState("");
  const [allFurnishing, setAllFurnishing] = React.useState([]);
  const [specificationList, setSpecificationList] = React.useState([]);
  const [minUnitSize, setMinUnitSize] = React.useState("");
  const [maxUnitSize, setMaxUnitSize] = React.useState("");
  const [plotSize, setPlotSize] = useState("");
  const [specificationLength, setSpecificationLength] = useState("");
  const [specificationBreath, setSpecificationBreath] = useState("");
  const [allSpecificationList, setAllSpecificationList] = useState("");
  const [unitSize, setUnitSize] = useState("");
  const dispatch = useDispatch();

  const loadAllSpecifications = React.useCallback(() => {
    dispatch(
      getAllSpecification({}, (data) => {
        setAllSpecifications(data.data);
      })
    )
  }, [dispatch])

  useEffect(() => (
    loadAllSpecifications()
  ), [loadAllSpecifications])

  const loadALLFurnishingDetails = useCallback(() => {
    dispatch(getAllFurnishingDetails({}, (data) => {
      setAllFurnishing(data);
    }))
  }, [dispatch])

  useEffect(() => {
    loadALLFurnishingDetails();
  }, [loadALLFurnishingDetails])

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (localStorage.getItem("floorplans") != null) {
      const data = JSON.parse(localStorage.getItem("floorplans"));
      const arr = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let tempData = {};
        tempData.floorPlanImage = element?.floorPlanImage;
        tempData.unitType = element?.unitType;
        tempData.unitSize = element?.unitSize;
        tempData.carpetSize = element?.carpetSize;
        tempData.furnishing = element?.furnishing;
        tempData.price = element?.price;
        tempData.specifications = element?.specifications;
        tempData.quanitity = element?.quanitity;
        tempData.plotSizeLength = element?.plotSizeLength;
        tempData.plotSizeBreath = element?.plotSizeBreath;
        tempData.constructionStatus = element?.constructionStatus;
        tempData.specificationList = element?.specificationList;
        tempData.furnishings = element?.furnishings;
        tempData.minUnitSize = element?.minUnitSize;
        tempData.maxUnitSize = element?.maxUnitSize;
        tempData.plotSize = element?.plotSize;
        arr.push(tempData);
      }
      setFloorPlans(arr);
    }

    if (localStorage.getItem("formData") != null) {
      let Tempdata = JSON.parse(localStorage.getItem("formData"));
      setPropertyID(Tempdata.propertyType);
      setSubProperty(Tempdata.selectedSubCategory);
    }

    if (localStorage.getItem("floorPlanData") != null) {
      let floorplanData = JSON.parse(localStorage.getItem('floorPlanData'));
      if (floorplanData.floorPlanImages) {
        setFloorPlanImage(floorplanData.floorPlanImages);
      }

      setUnitType(floorplanData.unitType);
      setConstructionStatus(floorplanData.constructionStatus);
      setPossessionDate(floorplanData.possessionDate);
      setEnterPrice(floorplanData.enterPrice);
      setCarpetSize(floorplanData.carpetSize);
      setBasicQuantity(floorplanData.basicQuantity);
      if (floorplanData.furnishings) {
        setFurnishings(floorplanData.furnishings);
      }
      setPlotSize(floorplanData.plotSize);
      setPlotSizeBreath(floorplanData.plotSizeBreath);
      setPlotSizeLength(floorplanData.plotSizeLength);
      if (floorplanData.specifications) {
        setSpecifications(floorplanData.specifications);
      }
      setUnitSize(floorplanData.unitSize);
      if (floorplanData?.allSpecificationList?.length > 0) {
        setAllSpecificationList(floorplanData.allSpecificationList);
      }
    }
  }, [])

  const loadPropertyDetails = useCallback(() => {
    dispatch(
      getPropertyCategoryById(propertyID, {}, (data) => {
        setPropertyType(data.data[0].name);
      })
    );
  }, [propertyID]);

  useEffect(() => {
    loadPropertyDetails();
  }, [loadPropertyDetails]);

  // const loadSubPropertyDetails = useCallback(() => {
  //   dispatch(
  //     getAllpropertySubCategory({ parentId: subPropertyID }, (data) => {
  //       console.log("sub property",data);
  //       setSubProperty(data);
  //     })
  //   );
  // }, [dispatch, subPropertyID]);

  // useEffect(() => {
  //   loadSubPropertyDetails();
  // }, [subPropertyID]);

  const hideInput = {
    opacity: 0,
    position: 'absolute',
    left: '26.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  const handleCreatePlan = () => {
    if (!specification || !quanitity) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    setSpecifications((oldArr) => [...oldArr, { specification, quanitity }]);
    setSpecification("");
    setQuantity("");
  };
  const handleUpdatePlan = (index) => {
    const val = specifications;
    val[index] = { specification: tempSpecification, quanitity: tempQuantity };
  };
  const handleRemoveFromPlan = (index) => {
    setSpecifications((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the new array
    });
  };
  const handleEditPlan = (index) => {
    setTempIndex(index);
    setTempSpecification(specifications[index].milestone);
    setTempQuantity(specifications[index].paymentPlan);
  };

  const handleChange = (event) => {
    // const {
    //   target: { value },
    // } = event;
    setFurnishing(event.target.value);
    // setFurnishing(
    //   // On autofill we get a stringified value.
    //   typeof value === 'string' ? value.split(',') : value
    // );
  };

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setTempFurnishing(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChange2 = (event) => {
    const {
      target: { value },
    } = event;
    setSpecifications(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    AddOrUpdateLocalStorage({
      specifications: typeof value === 'string' ? value.split(',') : value
    });
  };

  const handleCreatePlan1 = () => {
    if (
      floorPlanImage.length === 0 ||
      specifications.length === 0
    ) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    setFloorPlans((oldArr) => [...oldArr, { floorPlanImage, unitType, carpetSize, furnishing: furnishings, enterPrice, specifications, quanitity: basicQuantity, plotSizeBreath, plotSizeLength, constructionStatus }]);
    localStorage.setItem("floorplans", JSON.stringify([...floorPlans, { floorPlanImage, unitType, carpetSize, furnishing: furnishings, enterPrice, specifications, quanitity: basicQuantity, plotSizeBreath, plotSizeLength, constructionStatus }]));
    localStorage.removeItem("floorPlanData");
    setFloorPlanImage([]);
    setUnitType("");
    setCarpetSize("");
    setFurnishing("");
    setEnterPrice("");
    setSpecifications([]);
    setBasicQuantity("");
    setPlotSizeBreath("");
    setPlotSizeLength("");
    setConstructionStatus("");
    setPossessionDate("");
    setFurnishing("");
    setQuantity("");
    setSpecifications([]);
  };

  const handleRemoveFromPlan1 = (index) => {
    let newArray = [...floorPlans]; // Create a copy of the original array
    newArray.splice(index, 1);
    setFloorPlans(newArray);
    localStorage.setItem("floorplans", JSON.stringify(newArray));
  };

  const handleEditPackage1 = (index) => {
    setTempIndex1(index);
    setFloorPlanImage(floorPlans[index].floorPlanImage);
    setUnitType(floorPlans[index].unitType);
    setConstructionStatus(floorPlans[index].constructionStatus);
    setEnterPrice(floorPlans[index].enterPrice);
    setCarpetSize(floorPlans[index].carpetSize);
    setFurnishing(floorPlans[index].furnishing);
    setSpecifications(floorPlans[index].specifications);
    setBasicQuantity(floorPlans[index].basicQuantity);
    setPlotSizeBreath(floorPlans[index].plotSizeBreath);
    setPlotSizeLength(floorPlans[index].plotSizeLength);
  };

  const handleUpdatePackage1 = (index) => {
    setTempIndex1("");
    let val = floorPlans;
    val[index] = { ...val[index], floorPlanImage, unitType, constructionStatus, enterPrice, carpetSize, furnishing, specifications, basicQuantity, plotSizeBreath, plotSizeLength };
    localStorage.setItem("floorplans", JSON.stringify(val));
    localStorage.removeItem("floorPlanData");
    setFloorPlans(val);
    setTempIndex1("");
    setFloorPlanImage([]);
    setUnitType("");
    setCarpetSize("");
    setFurnishing("");
    setEnterPrice("");
    setSpecifications([]);
    setBasicQuantity("");
    setPlotSizeBreath("");
    setPlotSizeLength("");
    setConstructionStatus("");
    setPossessionDate("");
    setFurnishing("");
    setQuantity("");
    setFurnishings([]);
  };


  const handleUploadLogo = (e) => {
    const file = e.target.files;

    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setFloorPlanImage(oldArr => [...oldArr, data.data.documents[0]]);
            AddOrUpdateLocalStorage({
              floorPlanImages: [...floorPlanImage, data.data.documents[0]]
            });
          }, (err) => {
            console.log(err);
          })
        );

      }
    }
  };


  const handleCreatePlan2 = () => {
    if (!furnishing || !furnishingQuantity) {
      enqueueSnackbar("Please add all the fields", { variant: 'error' });
    } else {
      setFurnishings((oldArr) => [...oldArr, { furnishing, quanitity: furnishingQuantity }]);
      AddOrUpdateLocalStorage({
        furnishings: [...furnishings, { furnishing, quanitity: furnishingQuantity }]
      });
      setFurnishing("");
      setFurnishingQuantity("");
    }
  };

  const handleUpdatePlan2 = (index) => {
    const val = furnishings;
    val[index] = { furnishing: tempFurnishingDetail, quanitity: tempFurnishingQuantity };
    setFurnishings(val);
    setTempIndex2(null);
  };

  const handleRemoveFromPlan2 = (index) => {
    const newArray = [...furnishings]; // Create a copy of the original array
    newArray.splice(index, 1);
    localStorage.setItem("floorPlanData", JSON.stringify({ ...JSON.parse(localStorage.getItem("floorPlanData")), furnishings: newArray }));
    setFurnishings(newArray);
  };

  const handleEditPlan2 = (index) => {
    setTempIndex2(index);
    setTempFurnishingDetail(furnishings[index].furnishing);
    setTempFurnishingQuantity(furnishings[index].quanitity);
  };


  const AddOrUpdateLocalStorage = (formData) => {
    if (localStorage.getItem("floorPlanData")) {
      var obj = JSON.parse(localStorage.getItem("floorPlanData"));
      var final = { ...obj, ...formData };
      localStorage.setItem("floorPlanData", JSON.stringify(final));
    } else {
      localStorage.setItem("floorPlanData", JSON.stringify(formData));
    }
  }

  function isArrayOfTypeString(arr) {
    return arr.every(element => typeof element === 'string');
  }

  const getSpecificationsName = (arr) => {
    if (arr.length > 0) {
      var tempArr = allSpecifications.filter(element1 => {
        return arr.some(element2 => element2 === element1._id);
      });
      if (tempArr.length > 0) {
        return tempArr.map((item) => item.name).join(', ');
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (floorPlans.length === 0) {
      enqueueSnackbar('Please add at least one floor plan.', { variant: 'error' });
      return;
    }
    let formData = [];
    // var formData = new FormData();
    const tempData = {};
    for (let i = 0; i < floorPlans.length; i++) {
      const floorPlan = floorPlans[i];

      tempData['floorPlanImage'] = floorPlans[i].floorPlanImage;
      tempData['mainId'] = "";
      tempData['price'] = floorPlans[i].enterPrice;
      tempData['unitType'] = floorPlans[i].unitType;
      tempData['quantity'] = floorPlans[i].quanitity;
      tempData['specifications'] = floorPlans[i].specifications;
      tempData['furnishingDetails'] = floorPlans[i].furnishing;
      tempData['carpetSize'] = floorPlans[i].carpetSize;
      tempData['onesqft'] = 10;
      formData.push(tempData);
      // dispatch(addFloorPlanInProperty(formData, (data) => {
      //   enqueueSnackbar(data.message, { variant: 'success' });
      //   navigate('/property-management/brokerage/' + propertyID);
      // }));
    }
    // localStorage.setItem("floorplans", JSON.stringify(formData));
    navigate('/property-management/brokerage');
  };

  const handleAddAllSpecifications = () => {
    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
      if (!specification || !specificationBreath || !specificationLength) {
        enqueueSnackbar("Please choose correct specification", { variant: 'warning' });
      } else {
        setAllSpecificationList(oldArr => [...oldArr, { specification, specificationLength, specificationBreath }]);
        AddOrUpdateLocalStorage({
          allSpecificationList: [...allSpecificationList, { specification, specificationLength, specificationBreath }]
        });
        setSpecification("");
        setSpecificationBreath("");
        setSpecificationLength("");
      }
    } else if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
      if (!specification || !specificationBreath || !specificationLength) {
        enqueueSnackbar("Please choose correct specification", { variant: 'warning' });
      } else {
        setAllSpecificationList(oldArr => [...oldArr, { specification, specificationLength, specificationBreath }]);
        AddOrUpdateLocalStorage({
          allSpecificationList: [...allSpecificationList, { specification, specificationLength, specificationBreath }]
        });
        setSpecification("");
        setSpecificationBreath("");
        setSpecificationLength("");
      }
    } else {
      if (!specification) {
        enqueueSnackbar("Please choose correct specification", { variant: 'warning' });
      } else {
        setAllSpecificationList(oldArr => [...oldArr, specification]);
        AddOrUpdateLocalStorage({
          allSpecificationList: [...allSpecificationList, specification]
        });
        setSpecification("");
      }
    }
  }

  const [tempSpec, setTempSpec] = useState("");
  const [tempIndex3, setTempIndex3] = useState("");
  const handleEditAllSpecification = (index) => {
    setTempIndex3(index);
    setTempSpec(specification);
  }


  const getSpecificationName = (id) => {
    var temp = allSpecifications.filter((item) => item._id == id);
    if (temp.length > 0) {
      return temp[0].name;
    } else {
      return "";
    }
  }

  const [tempSpecificationWidth, setTempSpecificationWidth] = useState("");
  const [tempSpecificationHeight, setTempSpecificationHeight] = useState("");

  const handleEditSpecification = (index) => {
    setTempIndex(index);
    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
      setTempSpecification(allSpecificationList[index].specification);
      setTempSpecificationHeight(allSpecificationList[index].specificationLength);
      setTempSpecificationWidth(allSpecificationList[index].specificationBreath);
    } else if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
      setTempSpecification(allSpecificationList[index].specification);
      setTempSpecificationHeight(allSpecificationList[index].specificationLength);
      setTempSpecificationWidth(allSpecificationList[index].specificationBreath);
    }
    else {
      setTempSpecification(specification);
    }
  }


  const handleUpdateSpecification = (index) => {
    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
      if (!tempSpecification || !tempSpecificationWidth || !tempSpecificationWidth) {
        enqueueSnackbar("Please enter valid values", { variant: 'warning' });
      } else {
        var item = allSpecificationList;
        item[index].specification = tempSpecification;
        item[index].specificationBreath = tempSpecificationWidth;
        item[index].specificationLength = tempSpecificationHeight;
        setAllSpecificationList(item);
        setTempIndex("");
        setTempSpecification("");
      }
    } else if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
      var item = allSpecificationList;
      item[index].specification = tempSpecification;
      item[index].specificationBreath = tempSpecificationWidth;
      item[index].specificationLength = tempSpecificationHeight;
      setAllSpecificationList(item);
      setTempIndex("");
      setTempSpecification("");
    }
    else {
      if (!tempSpecification) {
      } else {
        var item = allSpecificationList;
        item[index] = tempSpecification;
        setAllSpecificationList(item);
        setTempIndex("");
        setTempSpecification("");
      }
    }
  }

  const handleDeleteSpecification = (index) => {
    const newArray = [...allSpecificationList];
    newArray.splice(index, 1);

    localStorage.setItem("floorPlanData", JSON.stringify({ ...JSON.parse(localStorage.getItem("floorPlanData")), allSpecificationList: newArray }));
    setAllSpecificationList(newArray);
  }

  const getFurnishingDetailName = (id) => {
    var temp = allFurnishing.filter((item) => item._id == id);
    if (temp.length > 0) {
      return temp[0].name;
    } else {
      return "";
    }
  }

  const getFurnishingDetailsName = (itemList) => {
    if (itemList && itemList.length > 0) {
      const namesArray = itemList.map(obj => obj.furnishing + ': ' + obj.quanitity);
      return namesArray.join(", ");
    } else {
      return "";
    }
  }

  const getSpecificationsNameForFlatsAndFarmHouse = (arr) => {
    if (arr && arr.length > 0) {
      const namesArray = arr.map(obj => obj.specification + ', Length: ' + obj.specificationLength + ', Breath: ' + obj.specificationBreath);
      return namesArray.join(", ");
    } else {
      return "";
    }
  }


  const handleAddFloorPlan = () => {
    if (propertyType.toLowerCase() === "land/plots") {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        var val = { floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList };
        setFloorPlans(oldArr => [...oldArr, val]);
        setFloorPlanImage([]);
        setUnitType("");
        setUnitSize("");
        setPlotSizeLength("");
        setPlotSizeBreath("");
        setEnterPrice("");
        setSpecification("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
        localStorage.removeItem("floorPlanData");
      }
    }

    if (propertyType.toLowerCase() === "commercial" && (subProperty.toLowerCase() === "sco" || subProperty.toLowerCase() === "commercial space")) {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        var val = { floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlans(oldArr => [...oldArr, val]);
        setFloorPlanImage([]);
        setUnitType("");
        setUnitSize("");
        setPlotSizeLength("");
        setPlotSizeBreath("");
        setEnterPrice("");
        setSpecification("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
        localStorage.removeItem("floorPlanData");
      }
    }

    if (propertyType.toLowerCase() === "commercial" && subProperty.toLowerCase() === "warehouse") {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        var val = { floorPlanImage, unitType, minUnitSize, maxUnitSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlans(oldArr => [...oldArr, val]);
        setFloorPlanImage([]);
        setUnitType("");
        setUnitSize("");
        setMinUnitSize("");
        setMaxUnitSize("");
        setEnterPrice("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
        localStorage.removeItem("floorPlanData");
      }
    }

    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        var val = { floorPlanImage, plotSize, plotSizeLength, carpetSize, plotSizeBreath, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlans(oldArr => [...oldArr, val]);
        setFloorPlanImage([]);
        setUnitType("");
        setPlotSize("");
        setCarpetSize("");
        setPlotSizeLength("");
        setPlotSizeBreath("");
        setEnterPrice("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
        localStorage.removeItem("floorPlanData");
      }
    }
    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        var val = { floorPlanImage, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlans(oldArr => [...oldArr, val]);
        setFloorPlanImage([]);
        setUnitType("");
        setPlotSize("");
        setCarpetSize("");
        setPlotSizeLength("");
        setPlotSizeBreath("");
        setEnterPrice("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
        localStorage.removeItem("floorPlanData");
      }
    }
  }

  const [tempFloorPlanIndex, setTempFloorPlanIndex] = useState("");
  const [temp, setTemp] = useState(false);

  const handleEditFloorPlan = (index) => {
    setTemp(true);
    setTempFloorPlanIndex(index);
    if (propertyType.toLowerCase() === "land/plots") {
      setFloorPlanImage(floorPlans[index].floorPlanImage);
      setUnitType(floorPlans[index].unitType);
      setUnitSize(floorPlans[index].unitSize);
      setPlotSizeLength(floorPlans[index].plotSizeLength);
      setPlotSizeBreath(floorPlans[index].plotSizeBreath);
      setEnterPrice(floorPlans[index].price);
      setConstructionStatus(floorPlans[index].constructionStatus);
      setPossessionDate(floorPlans[index].possessionDate);
      setAllSpecificationList(floorPlans[index].specificationList);
    }
    if (propertyType.toLowerCase() === "commercial" && (subProperty.toLowerCase() === "sco" || subProperty.toLowerCase() === "commercial space")) {
      setFloorPlanImage(floorPlans[index].floorPlanImage);
      setUnitType(floorPlans[index].unitType);
      setUnitSize(floorPlans[index].unitSize);
      setPlotSizeLength(floorPlans[index].plotSizeLength);
      setPlotSizeBreath(floorPlans[index].plotSizeBreath);
      setEnterPrice(floorPlans[index].price);
      setConstructionStatus(floorPlans[index].constructionStatus);
      setPossessionDate(floorPlans[index].possessionDate);
      setAllSpecificationList(floorPlans[index].specificationList);
      setFurnishings(floorPlans[index].furnishings);
    }
    if (propertyType.toLowerCase() === "commercial" && subProperty.toLowerCase() === "warehouse") {
      setFloorPlanImage(floorPlans[index].floorPlanImage);
      setUnitType(floorPlans[index].unitType);
      setMinUnitSize(floorPlans[index].minUnitSize);
      setMaxUnitSize(floorPlans[index].maxUnitSize);
      setEnterPrice(floorPlans[index].price);
      setConstructionStatus(floorPlans[index].constructionStatus);
      setPossessionDate(floorPlans[index].possessionDate);
      setAllSpecificationList(floorPlans[index].specificationList);
      setFurnishings(floorPlans[index].furnishings);
    }
    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
      setFloorPlanImage(floorPlans[index].floorPlanImage);
      setUnitType(floorPlans[index].unitType);
      setPlotSize(floorPlans[index].plotSize);
      setCarpetSize(floorPlans[index].carpetSize);
      setPlotSizeLength(floorPlans[index].plotSizeLength);
      setPlotSizeBreath(floorPlans[index].plotSizeBreath);
      setEnterPrice(floorPlans[index].price);
      setConstructionStatus(floorPlans[index].constructionStatus);
      setPossessionDate(floorPlans[index].possessionDate);
      setAllSpecificationList(floorPlans[index].specificationList);
      setFurnishings(floorPlans[index].furnishings);
    }

    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
      setFloorPlanImage(floorPlans[index].floorPlanImage);
      setUnitType(floorPlans[index].unitType);
      setCarpetSize(floorPlans[index].carpetSize);
      setEnterPrice(floorPlans[index].price);
      setConstructionStatus(floorPlans[index].constructionStatus);
      setPossessionDate(floorPlans[index].possessionDate);
      setAllSpecificationList(floorPlans[index].specificationList);
      setFurnishings(floorPlans[index].furnishings);
    }
  }

  const handleDeleteFloorPlan = (index) => {
    var oldArr = [...floorPlans];
    oldArr.splice(index, 1);
    localStorage.setItem("floorplans", JSON.stringify(oldArr));
    setFloorPlans(oldArr);
  }


  const handleUpdateFloorPlan = (index) => {
    let tempFloorPlan = [...floorPlans];
    if (propertyType.toLowerCase() === "land/plots") {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        tempFloorPlan[index] = { floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList };
        setFloorPlanImage([]);
        setUnitType("");
        setUnitSize("");
        setPlotSizeLength("");
        setPlotSizeBreath("");
        setEnterPrice("");
        setSpecification("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        localStorage.removeItem("floorPlanData");
      }
    }


    if (propertyType.toLowerCase() === "commercial" && (subProperty.toLowerCase() === "sco" || subProperty.toLowerCase() === "commercial space")) {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        tempFloorPlan[index] = { floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlanImage([]);
        setUnitType("");
        setUnitSize("");
        setPlotSizeLength("");
        setPlotSizeBreath("");
        setEnterPrice("");
        setSpecification("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.removeItem("floorPlanData");
      }
    }

    if (propertyType.toLowerCase() === "commercial" && subProperty.toLowerCase() === "warehouse") {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        tempFloorPlan[index] = { floorPlanImage, unitType, minUnitSize, maxUnitSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlanImage([]);
        setUnitType("");
        setMinUnitSize("");
        setMaxUnitSize("");
        setEnterPrice("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.removeItem("floorPlanData");
      }
    }

    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        tempFloorPlan[index] = { floorPlanImage, plotSize, plotSizeLength, plotSizeBreath, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlanImage([]);
        setPlotSize("");
        setCarpetSize("");
        setPlotSizeBreath("");
        setPlotSizeLength("");
        setUnitType("");
        setCarpetSize("");
        setEnterPrice("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.removeItem("floorPlanData");
      }
    }

    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
      if (!unitType || !enterPrice) {
        enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
      } else {
        tempFloorPlan[index] = { floorPlanImage, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
        setFloorPlanImage([]);
        setPlotSize("");
        setCarpetSize("");
        setPlotSizeBreath("");
        setPlotSizeLength("");
        setUnitType("");
        setCarpetSize("");
        setEnterPrice("");
        setConstructionStatus("");
        setPossessionDate("");
        setAllSpecificationList([]);
        setFurnishings([]);
        localStorage.removeItem("floorPlanData");
      }
    }

    setTempFloorPlanIndex("");
    setTemp(false);
    localStorage.setItem("floorplans", JSON.stringify(tempFloorPlan));
    setFloorPlans(tempFloorPlan);
  }

  function numberToWords(number) {
    const ones = [
      '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
      'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
      'seventeen', 'eighteen', 'nineteen'
    ];

    const tens = [
      '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
    ];

    if (number === 0) {
      return 'zero';
    } else if (number < 0) {
      return 'minus ' + numberToWords(Math.abs(number));
    }

    let words = '';

    if (Math.floor(number / 10000000) > 0) {
      words += numberToWords(Math.floor(number / 10000000)) + ' crore ';
      number %= 10000000;
    }

    if (Math.floor(number / 100000) > 0) {
      words += numberToWords(Math.floor(number / 100000)) + ' lakh ';
      number %= 100000;
    }

    if (Math.floor(number / 1000) > 0) {
      words += numberToWords(Math.floor(number / 1000)) + ' thousand ';
      number %= 1000;
    }

    if (Math.floor(number / 100) > 0) {
      words += numberToWords(Math.floor(number / 100)) + ' hundred ';
      number %= 100;
    }

    if (number > 0) {
      if (number < 20) {
        words += ones[number];
      } else {
        words += tens[Math.floor(number / 10)];
        if (number % 10 > 0) {
          words += '-' + ones[number % 10];
        }
      }
    }

    return words.trim();
  }


  const handleRemoveFloorPlanImage = (index) => {
    setFloorPlanImage(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    setFloorPlanImagePicView(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    let tempArr = [...floorPlanImage];
    tempArr.splice(index, 1);

    if (localStorage.getItem("floorPlanData")) {
      localStorage.setItem("floorPlanData", JSON.stringify({ ...JSON.parse(localStorage.getItem("floorPlanData")), floorPlanImages: tempArr }));
    }
  }

  const getFurnishingDetailsName1 = (itemList) => {
    if (itemList && itemList.length > 0) {
      const namesArray = itemList.map(obj => obj.furnishing + ': ' + obj.quanitity);
      return namesArray.join(", ");
    } else {
      return "";
    }
  }

  return (
    <>
      <Box sx={{ marginTop: '73px' }}>
        <Grid container>
          <Grid item md={3}>
            <UploadDataBox>
              <UploadData name="Upload Floor Pan">
                <Box sx={{ display: 'flex' }}>
                  {
                    floorPlanImage.length > 0
                    && floorPlanImage.map((item, index) => (
                      <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                        <img
                          src={item}
                          className="img-fluid"
                          style={{ width: '130px', height: '130px' }}
                        />
                        <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemoveFloorPlanImage(index)}>
                          <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                        </IconButton>
                      </Box>
                    ))
                  }
                  <UploadDataCard>
                    <img
                      src={
                        '/uploadImg.png'
                      }
                      alt="upload"
                      className="img-fluid"
                    />
                    <input type="file" accept="image/*" onChange={handleUploadLogo} multiple />
                  </UploadDataCard>
                </Box>
              </UploadData>
            </UploadDataBox>
            {/* <Box sx={{ py: 2 }}>
                              <Typography>Upload Floor Pan</Typography>
                              <Box component="button" sx={{ background: '#FFFFFF', border: 'none', cursor: 'pointer' }}>
                                  <img className='uploadimg' src={"/Images/uploadImg.png"} alt='upload' />
                                  <input
                                      type="file"
                                      accept="image/*"
                                      style={hideInput}
                                  />
                              </Box>
                          </Box> */}
          </Grid>
        </Grid>
        <Divider
          sx={{
            height: '1.5px',
            background: '#E0E4EC',
            marginTop: '73px',
            marginBottom: '34px',
          }}
        />

        {/* Form for Lands/Plots */}
        {
          propertyType.toLowerCase() === "land/plots"
            ?
            <>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <label for="fname">Unit Type*</label>
                  <input
                    required
                    className="inputBox"
                    type="text"
                    value={unitType}
                    placeholder="Unit Type"
                    onChange={(e) => {
                      AddOrUpdateLocalStorage({
                        unitType: e.target.value
                      });
                      setUnitType(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <label for="fname">Unit Size (sq. ft.)</label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Size"
                        value={unitSize}
                        onChange={(e) => {
                          AddOrUpdateLocalStorage({
                            unitSize: e.target.value
                          });
                          setUnitSize(e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label for="fname">Length (ft.)</label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Length"
                        value={plotSizeLength}
                        onChange={(e) => {
                          AddOrUpdateLocalStorage({
                            plotSizeLength: e.target.value
                          });
                          setPlotSizeLength(e.target.value)
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <label for="fname">Breath (ft.)</label>
                      <input
                        className="inputBox"
                        type="text"
                        placeholder="Breath"
                        value={plotSizeBreath}
                        onChange={(e) => {
                          AddOrUpdateLocalStorage({
                            plotSizeBreath: e.target.value
                          });
                          setPlotSizeBreath(e.target.value)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <label >Price*</label>
                  <input
                    required
                    className="inputBox"
                    type="text"
                    placeholder="Price"
                    value={enterPrice}
                    onChange={(e) => {
                      AddOrUpdateLocalStorage({
                        enterPrice: e.target.value
                      });
                      setEnterPrice(e.target.value);
                    }}
                  />
                  <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
                </Grid>
                <Grid item xs={6}>
                  <label>Construction Status</label>
                  <select value={constructionStatus} onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      constructionStatus: e.target.value
                    });
                    setConstructionStatus(e.target.value)
                  }}>
                    <option value="" disabled selected>
                      Choose Construction Status
                    </option>
                    <option value="Ready to move">Ready to move</option>
                    <option value="Under Construction">Under Construction</option>
                    <option value="New Launch">New Launch</option>
                  </select>
                </Grid>
                <Grid item xs={6}>
                  <label>
                    Possession Date
                    {constructionStatus == 'Ready to move' && (
                      <Tooltip title="this field is not enabled for 'ready to move' contruction status">
                        <Info
                          sx={{
                            fontSize: '20px',
                            verticalAlign: 'sub',
                            marginLeft: '1px',
                          }}
                        />
                      </Tooltip>
                    )}
                  </label>
                  <input
                    value={possessionDate}
                    onChange={(e) => {
                      AddOrUpdateLocalStorage({
                        possessionDate: e.target.value
                      });
                      setPossessionDate(e.target.value)
                    }
                    }
                    className="inputBox"
                    type="date"
                    placeholder="Possession Date"
                    disabled={constructionStatus == 'Ready to move'}
                  />
                </Grid>
                <Grid item xs={11}>
                  <label>Specification</label>
                  <select
                    value={specification}
                    onChange={(e) => {
                      setSpecification(e.target.value)
                    }}>
                    <option value="" disabled>Choose specification</option>
                    {allSpecifications && allSpecifications.map((item, index) => (
                      <option value={item.name} key={index}>{item.name}</option>
                    ))}
                  </select>
                </Grid>
                <Grid item xs={1}>
                  <p
                    onClick={handleAddAllSpecifications}
                    style={{
                      borderRadius: '50%',
                      background: '#278FD9',
                      fontWeight: '600',
                      padding: '1px 16px',
                      fontSize: '35px',
                      width: '54px',
                      height: '54px',
                      marginTop: '25px',
                      cursor: 'pointer',
                      boxShadow: '0px 4px 4px #ddd'
                    }}
                  >
                    <span style={{ color: '#FFFFFF' }}>+</span>
                  </p>
                </Grid>
                {
                  allSpecificationList && allSpecificationList.length > 0
                    ?
                    <Grid item xs={12}>
                      <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }}>Specification</TableCell>
                            <TableCell align="center">Action</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {allSpecificationList.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }} align='center'>
                                {
                                  tempIndex === index
                                    ?
                                    <select
                                      value={tempSpecification}
                                      onChange={(e) => {
                                        setTempSpecification(e.target.value)
                                      }}>
                                      <option disabled selected>Choose specification</option>
                                      {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                      ))}
                                    </select>
                                    :
                                    item
                                }
                              </TableCell>
                              <TableCell align='center'>
                                {
                                  tempIndex === index
                                    ?
                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                    :
                                    <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                }
                                <Delete sx={{ cursor: 'pointer' }} onClick={() => handleDeleteSpecification(index)} />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                    :
                    ""
                }
                <Grid item xs={12}>
                  {floorPlans && floorPlans.length > 0
                    ?
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Photo
                          </TableCell>
                          <TableCell>
                            Unit Type
                          </TableCell>
                          <TableCell>
                            Unit Size
                          </TableCell>
                          <TableCell>
                            Length
                          </TableCell>
                          <TableCell>
                            Breath
                          </TableCell>
                          <TableCell>
                            Price
                          </TableCell>
                          <TableCell>
                            Construction Status
                          </TableCell>
                          <TableCell>
                            Possession Date
                          </TableCell>
                          <TableCell>
                            Specification
                          </TableCell>
                          <TableCell>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {floorPlans.map((item, index) => (
                          <>
                            <TableRow key={index}>
                              {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                              <TableCell>
                                <img src={item.floorPlanImage[0]} alt="" style={{ width: '90px' }} />
                              </TableCell>
                              <TableCell>
                                {item.unitType}
                              </TableCell>
                              <TableCell>
                                {item?.unitSize ? item?.unitSize : "--"}
                              </TableCell>
                              <TableCell>
                                {item?.plotSizeLength ? item?.plotSizeLength : "--"}
                              </TableCell>
                              <TableCell>
                                {item?.plotSizeBreath ? item?.plotSizeBreath : "--"}
                              </TableCell>
                              <TableCell>
                                {item.price}
                              </TableCell>
                              <TableCell>
                                {item?.constructionStatus ? item?.constructionStatus : "--"}
                              </TableCell>
                              <TableCell>
                                {item?.possessionDate ? item?.possessionDate : "Not applicable"}
                              </TableCell>
                              <TableCell>
                                {item?.specificationList?.length > 0 && item?.specificationList != "" ? item.specificationList?.join(", ") : "--"}
                              </TableCell>
                              <TableCell>
                                {
                                  tempFloorPlanIndex === index
                                    ?
                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                    :
                                    <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                                }
                                <Delete sx={{ cursor: 'pointer' }} onClick={() => handleDeleteFloorPlan(index)} />
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                    :
                    ""}
                </Grid>
              </Grid>
            </>
            :
            ""
        }

        {/* SCO / Commercial / Commercial Space */}
        {
          propertyType.toLowerCase() == "commercial" && (subProperty.toLowerCase() == "sco" || subProperty.toLowerCase() === "commercial space")
            ?
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <label for="fname">Unit Type*</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Unit Type"
                  value={unitType}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      unitType: e.target.value
                    });
                    setUnitType(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <label for="fname">Unit Size (sq. ft.)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Size"
                      value={unitSize}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          unitSize: e.target.value
                        });
                        setUnitSize(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label for="fname">Length (ft.)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Length"
                      value={plotSizeLength}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          plotSizeLength: e.target.value
                        });
                        setPlotSizeLength(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label for="fname">Breath (ft.)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Breath"
                      value={plotSizeBreath}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          plotSizeBreath: e.target.value
                        });
                        setPlotSizeBreath(e.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <label >Price*</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Price"
                  value={enterPrice}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      enterPrice: e.target.value
                    });
                    setEnterPrice(e.target.value);
                  }}
                />
                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
              </Grid>
              <Grid item xs={6}>
                <label>Construction Status</label>
                <select value={constructionStatus} onChange={(e) => {
                  AddOrUpdateLocalStorage({
                    constructionStatus: e.target.value
                  });
                  setConstructionStatus(e.target.value)
                }}>
                  <option value="" disabled selected>
                    Choose Construction Status
                  </option>
                  <option value="Ready to move">Ready to move</option>
                  <option value="Under Construction">Under Construction</option>
                  <option value="New Launch">New Launch</option>
                </select>
              </Grid>
              <Grid item xs={6}>
                <label>
                  Possession Date
                  {constructionStatus == 'Ready to move' && (
                    <Tooltip title="this field is not enabled for 'ready to move' contruction status">
                      <Info
                        sx={{
                          fontSize: '20px',
                          verticalAlign: 'sub',
                          marginLeft: '1px',
                        }}
                      />
                    </Tooltip>
                  )}
                </label>
                <input
                  value={possessionDate}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      possessionDate: e.target.value
                    });
                    setPossessionDate(e.target.value)
                  }
                  }
                  className="inputBox"
                  type="date"
                  placeholder="Possession Date"
                  disabled={constructionStatus == 'Ready to move'}
                />
              </Grid>
              <Grid item xs={7}>
                <label for="fname">
                  Furnishing
                </label>
                <select
                  labelId="amenties"
                  id="demo-multiple-name"
                  value={furnishing}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={handleChange}
                >
                  <option value="" disabled>Choose an option</option>
                  {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={4}>
                <label for="fname">
                  Quantity
                </label>
                <input

                  required
                  value={furnishingQuantity}
                  className="inputBox"
                  type="text"
                  placeholder="Enter Furnishing Quantity"
                  onChange={(e) => {
                    setFurnishingQuantity(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleCreatePlan2}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {furnishings.length > 0 ? (
                <Grid item xs={12}>
                  <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Furnishing</TableCell>
                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Quantity</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {furnishings.map((item, index) => (
                        <TableRow key={index} >
                          <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            {tempIndex2 === index ? (
                              <select
                                labelId="amenties"
                                id="demo-multiple-name"
                                value={tempFurnishingDetail}
                                sx={{
                                  width: '100%',
                                  background:
                                    'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                }}
                                onChange={(e) => setTempFurnishingDetail(e.target.value)}
                              >
                                <option value="" disabled>Choose an option</option>
                                {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                                  <option value={item.name} key={index}>{item.name}</option>
                                ))}
                              </select>
                            ) : (
                              item.furnishing
                            )}
                          </TableCell>
                          <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            {tempIndex2 === index ? (
                              <input
                                value={tempFurnishingQuantity}
                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                className="inputBox"
                                type="text"
                                placeholder="Quantity"
                              />
                            ) : (
                              item.quanitity
                            )}
                          </TableCell>
                          <TableCell sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            <p style={{ textAlign: 'center' }}>
                              {tempIndex2 === index ? (
                                <Done
                                  onClick={() => handleUpdatePlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ModeEditOutline
                                  onClick={() => handleEditPlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <Delete
                                onClick={() => handleRemoveFromPlan2(index)}
                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              ) : (
                ''
              )}

              <Grid item xs={11}>
                <label>Specification</label>
                <select
                  value={specification}
                  onChange={(e) => {
                    setSpecification(e.target.value)
                  }}>
                  <option value={""} disabled selected>Choose specification</option>
                  {allSpecifications && allSpecifications.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleAddAllSpecifications}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {
                allSpecificationList && allSpecificationList.length > 0
                  ?
                  <Grid item xs={12}>
                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }}>Specification</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allSpecificationList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2', width: '80%' } : { borderRight: '1px solid #e2e2e2', width: '80%' }} align='center'>
                              {
                                tempIndex === index
                                  ?
                                  <select
                                    value={tempSpecification}
                                    onChange={(e) => {
                                      setTempSpecification(e.target.value)
                                    }}>
                                    <option disabled selected>Choose specification</option>
                                    {allSpecifications && allSpecifications.map((item, index) => (
                                      <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                  </select>
                                  :
                                  item
                              }
                            </TableCell>
                            <TableCell align='center'>
                              {
                                tempIndex === index
                                  ?
                                  <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                  :
                                  <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                              }
                              <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                  :
                  ""
              }
              <Grid item xs={12}>
                {floorPlans && floorPlans.length > 0
                  ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Photo
                        </TableCell>
                        <TableCell>
                          Unit Type
                        </TableCell>
                        <TableCell>
                          Unit Size
                        </TableCell>
                        <TableCell>
                          Length
                        </TableCell>
                        <TableCell>
                          Breath
                        </TableCell>
                        <TableCell>
                          Price
                        </TableCell>
                        <TableCell>
                          Construction Status
                        </TableCell>
                        <TableCell>
                          Possession Date
                        </TableCell>
                        <TableCell>
                          Specification
                        </TableCell>
                        <TableCell>
                          Furnishing
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {floorPlans.map((item, index) => (
                        <>
                          <TableRow key={index}>
                            {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                            <TableCell>
                              <img src={item.floorPlanImage[0]} alt="" style={{ width: '90px' }} />
                            </TableCell>
                            <TableCell>
                              {item.unitType}
                            </TableCell>
                            <TableCell>
                              {item.unitSize}
                            </TableCell>
                            <TableCell>
                              {item.plotSizeLength}
                            </TableCell>
                            <TableCell>
                              {item.plotSizeBreath}
                            </TableCell>
                            <TableCell>
                              {item.price}
                            </TableCell>
                            <TableCell>
                              {item.constructionStatus}
                            </TableCell>
                            <TableCell>
                              {item.possessionDate ? item.possessionDate : "Not applicable"}
                            </TableCell>
                            <TableCell>
                              {getSpecificationsName(item.specificationList)}
                            </TableCell>
                            <TableCell>
                              {getFurnishingDetailsName(item.furnishings)}
                            </TableCell>
                            <TableCell>
                              {
                                tempFloorPlanIndex === index
                                  ?
                                  <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                  :
                                  <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                              }
                              <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  :
                  ""}
              </Grid>
            </Grid>
            :
            ""
        }

        {/* Commercial / Warehouse */}
        {
          propertyType.toLowerCase() == "commercial" && subProperty.toLowerCase() == "warehouse"
            ?
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <label for="fname">Unit Type*</label>
                <input
                  required
                  value={unitType}
                  className="inputBox"
                  type="text"
                  placeholder="Unit Type"
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      unitType: e.target.value
                    });
                    setUnitType(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <label for="fname">Unit Size (sq. ft)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Min"
                      value={minUnitSize}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          minUnitSize: e.target.value
                        });
                        setMinUnitSize(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <input
                      className="inputBox"
                      type="text"
                      style={{ marginTop: '1.7rem' }}
                      placeholder="Max"
                      value={maxUnitSize}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          maxUnitSize: e.target.value
                        });
                        setMaxUnitSize(e.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <label >Price / Per Sq. FT*</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Price"
                  value={enterPrice}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      enterPrice: e.target.value
                    });
                    setEnterPrice(e.target.value);
                  }}
                />
                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
              </Grid>
              <Grid item xs={6}>
                <label>Construction Status</label>
                <select value={constructionStatus} onChange={(e) => {
                  AddOrUpdateLocalStorage({
                    constructionStatus: e.target.value
                  });
                  setConstructionStatus(e.target.value)
                }}>
                  <option value="" disabled selected>
                    Choose Construction Status
                  </option>
                  <option value="Ready to move">Ready to move</option>
                  <option value="Under Construction">Under Construction</option>
                  <option value="New Launch">New Launch</option>
                </select>
              </Grid>
              <Grid item xs={6}>
                <label>
                  Possession Date
                  {constructionStatus == 'Ready to move' && (
                    <Tooltip title="this field will not be enabled for under 'ready to move' status">
                      <Info
                        sx={{
                          fontSize: '20px',
                          verticalAlign: 'sub',
                          marginLeft: '1px',
                        }}
                      />
                    </Tooltip>
                  )}
                </label>
                <input
                  value={possessionDate}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      possessionDate: e.target.value
                    });
                    setPossessionDate(e.target.value)
                  }
                  }
                  className="inputBox"
                  type="date"
                  placeholder="Possession Date"
                  disabled={constructionStatus == 'Ready to move'}
                />
              </Grid>
              <Grid item xs={7}>
                <label for="fname">
                  Furnishing
                </label>
                <select
                  labelId="amenties"
                  id="demo-multiple-name"
                  value={furnishing}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={(e) => setFurnishing(e.target.value)}
                >
                  <option disabled value={""} selected>Choose an option</option>
                  {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={4}>
                <label for="fname">
                  Quantity
                </label>
                <input

                  required
                  value={furnishingQuantity}
                  className="inputBox"
                  type="text"
                  placeholder="Enter Furnishing Quantity"
                  onChange={(e) => {
                    setFurnishingQuantity(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleCreatePlan2}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {furnishings.length > 0 ? (
                <Grid item xs={12}>
                  <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Furnishing</TableCell>
                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Quantity</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {furnishings.map((item, index) => (
                        <TableRow key={index} >
                          <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            {tempIndex2 === index ? (
                              <select
                                labelId="amenties"
                                id="demo-multiple-name"
                                value={tempFurnishingDetail}
                                sx={{
                                  width: '100%',
                                  background:
                                    'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                }}
                                onChange={(e) => setTempFurnishingDetail(e.target.value)}
                              >
                                <option value="" disabled>Choose an option</option>
                                {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                                  <option value={item.name} key={index}>{item.name}</option>
                                ))}
                              </select>
                            ) : (
                              item.furnishing
                            )}
                          </TableCell>
                          <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            {tempIndex2 === index ? (
                              <input
                                value={tempFurnishingQuantity}
                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                className="inputBox"
                                type="text"
                                placeholder="Quantity"
                              />
                            ) : (
                              item.quanitity
                            )}
                          </TableCell>
                          <TableCell sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            <p style={{ textAlign: 'center' }}>
                              {tempIndex2 === index ? (
                                <Done
                                  onClick={() => handleUpdatePlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ModeEditOutline
                                  onClick={() => handleEditPlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <Delete
                                onClick={() => handleRemoveFromPlan2(index)}
                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={11}>
                <label>Specification</label>
                <select
                  value={specification}
                  onChange={(e) => {
                    setSpecification(e.target.value)
                  }}>
                  <option disabled selected value={""}>Choose specification</option>
                  {allSpecifications && allSpecifications.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleAddAllSpecifications}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {
                allSpecificationList && allSpecificationList.length > 0
                  ?
                  <Grid item xs={12}>
                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }}>Specification</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allSpecificationList.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2', width: '80%' } : { borderRight: '1px solid #e2e2e2', width: '80%' }} align='center'>
                              {
                                tempIndex === index
                                  ?
                                  <select
                                    value={tempSpecification}
                                    onChange={(e) => {
                                      setTempSpecification(e.target.value)
                                    }}>
                                    <option disabled selected>Choose specification</option>
                                    {allSpecifications && allSpecifications.map((item, index) => (
                                      <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                  </select>
                                  :
                                  item
                              }
                            </TableCell>
                            <TableCell align='center' sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2', width: '80%' } : { borderRight: '1px solid #e2e2e2', width: '80%' }}>
                              {
                                tempIndex === index
                                  ?
                                  <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                  :
                                  <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                              }
                              <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                  :
                  ""
              }
              <Grid item xs={12}>
                {floorPlans && floorPlans.length > 0
                  ?
                  <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Photo
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Unit Type
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Unit Size
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Construction Status
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Possession Date
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Specification
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Furnishing
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {floorPlans.map((item, index) => (
                        <>
                          <TableRow key={index}>
                            {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              <img src={item.floorPlanImage[0]} alt="" style={{ width: '90px' }} />
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.unitType}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.minUnitSize} - {item.maxUnitSize}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.price}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.constructionStatus}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.possessionDate ? item.possessionDate : "Not applicable"}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.specificationList.join(", ")}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {getFurnishingDetailsName1(item.furnishings)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              {
                                tempFloorPlanIndex === index
                                  ?
                                  <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                  :
                                  <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                              }
                              <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  :
                  ""}
              </Grid>
            </Grid>
            :
            ""
        }

        {/* Flats & Apartment (Builder Floor/ Duplex House) and Farmhouse / Villa */}
        {
          propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa"
            ?
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <label for="fname">Plot Size (sq. ft.)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Size"
                      value={plotSize}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          plotSize: e.target.value
                        });
                        setPlotSize(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label for="fname">Length (ft.)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Length"
                      value={plotSizeLength}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          plotSizeLength: e.target.value
                        });
                        setPlotSizeLength(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <label for="fname">Breath (ft.)</label>
                    <input
                      className="inputBox"
                      type="text"
                      placeholder="Breath"
                      value={plotSizeBreath}
                      onChange={(e) => {
                        AddOrUpdateLocalStorage({
                          plotSizeBreath: e.target.value
                        });
                        setPlotSizeBreath(e.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <label for="fname">
                  Choose Unit Type*
                </label>
                <select
                  value={unitType}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      unitType: e.target.value
                    });
                    setUnitType(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Choose
                  </option>
                  <>
                    <option value="1RK">1RK</option>
                    <option value="1BHK">1BHK</option>
                    <option value="2BHK">2BHK</option>
                    <option value="3BHK">3BHK</option>
                    <option value="4BHK">4BHK</option>
                    <option value="5BHK">5BHK</option>
                    <option value="5BHK+">5BHK+</option>
                  </>
                </select>
              </Grid>
              <Grid item xs={6}>
                <label for="fname">Carpet Area</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Carpet Area"
                  value={carpetSize}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      carpetSize: e.target.value
                    });
                    setCarpetSize(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <label >Price*</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Price"
                  value={enterPrice}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      AddOrUpdateLocalStorage({
                        enterPrice: e.target.value
                      });
                      setEnterPrice(e.target.value);
                    }
                  }}
                />
                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
              </Grid>
              <Grid item xs={6}>
                <label>Construction Status</label>
                <select value={constructionStatus} onChange={(e) => {
                  AddOrUpdateLocalStorage({
                    constructionStatus: e.target.value
                  });
                  setConstructionStatus(e.target.value)
                }}>
                  <option value="" disabled selected>
                    Choose Construction Status
                  </option>
                  <option value="Ready to move">Ready to move</option>
                  <option value="Under Construction">Under Construction</option>
                  <option value="New Launch">New Launch</option>
                </select>
              </Grid>
              <Grid item xs={6}>
                <label>
                  Possession Date
                  {constructionStatus == 'Ready to move' && (
                    <Tooltip title="this field will only be disabled for 'ready to move' status">
                      <Info
                        sx={{
                          fontSize: '20px',
                          verticalAlign: 'sub',
                          marginLeft: '1px',
                        }}
                      />
                    </Tooltip>
                  )}
                </label>
                <input
                  value={possessionDate}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      possessionDate: e.target.value
                    });
                    setPossessionDate(e.target.value)
                  }
                  }
                  className="inputBox"
                  type="date"
                  placeholder="Possession Date"
                  disabled={constructionStatus == 'Ready to move'}
                />
              </Grid>
              <Grid item xs={7}>
                <label for="fname">
                  Furnishing
                </label>
                <select
                  labelId="amenties"
                  id="demo-multiple-name"
                  value={furnishing}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={(e) => setFurnishing(e.target.value)}
                >
                  <option disabled selected value="">Choose an option</option>
                  {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={4}>
                <label for="fname">
                  Quantity
                </label>
                <input

                  required
                  value={furnishingQuantity}
                  className="inputBox"
                  type="text"
                  placeholder="Enter Furnishing Quantity"
                  onChange={(e) => {
                    setFurnishingQuantity(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleCreatePlan2}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {furnishings.length > 0 ? (
                <Grid xs={12} item>
                  <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Furnishing</TableCell>
                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Quantity</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {furnishings.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell Cell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            {tempIndex2 === index ? (
                              <input
                                value={tempFurnishingDetail}
                                onChange={(e) =>
                                  setTempFurnishingDetail(e.target.value)
                                }
                                className="inputBox"
                                type="text"
                                placeholder="Specification"
                              />
                            ) : (
                              item.furnishing
                            )}
                          </TableCell>
                          <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                            {tempIndex2 === index ? (
                              <input
                                value={tempFurnishingQuantity}
                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                className="inputBox"
                                type="text"
                                placeholder="Quantity"
                              />
                            ) : (
                              item.quanitity
                            )}
                          </TableCell>
                          <TableCell sx={index == furnishings.length - 1 ? { borderBottom: '0px' } : {}}>
                            <p style={{ textAlign: 'center' }}>
                              {tempIndex2 === index ? (
                                <Done
                                  onClick={() => handleUpdatePlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ModeEditOutline
                                  onClick={() => handleEditPlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <Delete
                                onClick={() => handleRemoveFromPlan2(index)}
                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={6}>
                <label>Specification</label>
                <select
                  value={specification}
                  onChange={(e) => {
                    setSpecification(e.target.value)
                  }}>
                  <option disabled selected value="">Choose specification</option>
                  {allSpecifications && allSpecifications.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <label>Length</label>
                    <input
                      type='text'
                      className='inputBox'
                      value={specificationLength}
                      placeholder='Length'
                      onChange={(e) => setSpecificationLength(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label>Breath</label>
                    <input
                      type='text'
                      className='inputBox'
                      value={specificationBreath}
                      placeholder='Breath'
                      onChange={(e) => setSpecificationBreath(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleAddAllSpecifications}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {
                allSpecificationList && allSpecificationList.length > 0
                  ?
                  <Grid item xs={12} sx={{ width: '100%', overflow: 'hidden' }}>
                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Specification</TableCell>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Length</TableCell>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Breath</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          allSpecificationList.map((item, index) => (
                            <TableRow>
                              <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }} >
                                {
                                  tempIndex === index
                                    ?
                                    <select
                                      value={tempSpecification}
                                      onChange={(e) => {
                                        setTempSpecification(e.target.value)
                                      }}>
                                      <option disabled selected>Choose specification</option>
                                      {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                      ))}
                                    </select>
                                    : item.specification
                                }
                              </TableCell>
                              <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                {
                                  tempIndex === index
                                    ?
                                    <input
                                      type='text'
                                      className='inputBox'
                                      value={tempSpecificationHeight}
                                      placeholder='Breath'
                                      onChange={(e) => setTempSpecificationHeight(e.target.value)}
                                    />
                                    : item.specificationLength
                                }
                              </TableCell>
                              <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                {
                                  tempIndex === index
                                    ?
                                    <input
                                      type='text'
                                      className='inputBox'
                                      value={tempSpecificationWidth}
                                      placeholder='Breath'
                                      onChange={(e) => setTempSpecificationWidth(e.target.value)}
                                    />
                                    :
                                    item.specificationBreath}
                              </TableCell>
                              <TableCell align='center' sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                {
                                  tempIndex === index
                                    ?
                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                    :
                                    <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                }
                                <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Grid>
                  :
                  ""
              }

              {floorPlans && floorPlans.length > 0
                ?
                <Grid item xs={12}>
                  <Table stickyHeader aria-label="sticky table" sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Photo
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Unit Type
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Carpet Area
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Plot Size
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Construction Status
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Possession Date
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Specification
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Furnishing
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {floorPlans.map((item, index) => (
                        <>
                          <TableRow key={index}>
                            {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              <img src={item.floorPlanImage[0]} alt="" style={{ width: '90px' }} />
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.unitType}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.carpetSize}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              Size: {item.plotSize}
                              <br />
                              Length: {item.plotSizeLength}
                              <br />
                              Breath: {item.plotSizeBreath}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.price}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.constructionStatus}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.possessionDate ? item.possessionDate : "Not applicable"}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {getSpecificationsNameForFlatsAndFarmHouse(item.specificationList)}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {getFurnishingDetailsName(item.furnishings)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              {
                                tempFloorPlanIndex === index
                                  ?
                                  <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                  :
                                  <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                              }
                              <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                :
                ""
              }
            </Grid>
            :
            ""
        }


        {/* Flats & Apartment (high rise/ low rise) */}
        {
          propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")
            ?
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <label for="fname">
                  Choose Unit Type*
                </label>
                <select
                  value={unitType}
                  disabled={
                    propertyType === 'Commercial'
                  }
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      unitType: e.target.value
                    });
                    setUnitType(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    Choose
                  </option>
                  <>
                    <option value="1RK">1RK</option>
                    <option value="1BHK">1BHK</option>
                    <option value="2BHK">2BHK</option>
                    <option value="3BHK">3BHK</option>
                    <option value="4BHK">4BHK</option>
                    <option value="5BHK">5BHK</option>
                    <option value="5BHK+">5BHK+</option>
                  </>
                </select>
              </Grid>
              <Grid item xs={6}>
                <label for="fname">Carpet Area</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Carpet Area"
                  value={carpetSize}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      carpetSize: e.target.value
                    });
                    setCarpetSize(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <label >Price*</label>
                <input
                  required
                  className="inputBox"
                  type="text"
                  placeholder="Price"
                  value={enterPrice}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      enterPrice: e.target.value
                    });
                    setEnterPrice(e.target.value);
                  }}
                />
                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
              </Grid>
              <Grid item xs={6}>
                <label>Construction Status</label>
                <select value={constructionStatus} onChange={(e) => {
                  AddOrUpdateLocalStorage({
                    constructionStatus: e.target.value
                  });
                  setConstructionStatus(e.target.value)
                }}>
                  <option value="" disabled>
                    Choose Construction Status
                  </option>
                  <option value="Ready to move">Ready to move</option>
                  <option value="Under Construction">Under Construction</option>
                  <option value="New Launch">New Launch</option>
                </select>
              </Grid>
              <Grid item xs={6}>
                <label>
                  Possession Date
                  {constructionStatus == 'Ready to move' && (
                    <Tooltip title="this field will be disabled for 'ready to move' status">
                      <Info
                        sx={{
                          fontSize: '20px',
                          verticalAlign: 'sub',
                          marginLeft: '1px',
                        }}
                      />
                    </Tooltip>
                  )}
                </label>
                <input
                  value={possessionDate}
                  onChange={(e) => {
                    AddOrUpdateLocalStorage({
                      possessionDate: e.target.value
                    });
                    setPossessionDate(e.target.value)
                  }
                  }
                  className="inputBox"
                  type="date"
                  placeholder="Possession Date"
                  disabled={constructionStatus == 'Ready to move'}
                />
              </Grid>
              <Grid item xs={7}>
                <label for="fname">
                  Furnishing
                </label>
                <select
                  labelId="amenties"
                  id="demo-multiple-name"
                  value={furnishing}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={(e) => setFurnishing(e.target.value)}
                >
                  <option disabled selected value="">Choose an option</option>
                  {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={4}>
                <label for="fname">
                  Quantity
                </label>
                <input

                  required
                  value={furnishingQuantity}
                  className="inputBox"
                  type="text"
                  placeholder="Enter Furnishing Quantity"
                  onChange={(e) => {
                    setFurnishingQuantity(e.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleCreatePlan2}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {furnishings.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Furnishing</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {furnishings.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell Cell align="center">
                            {tempIndex2 === index ? (
                              <select
                                labelId="amenties"
                                id="demo-multiple-name"
                                value={tempFurnishingDetail}
                                sx={{
                                  width: '100%',
                                  background:
                                    'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                }}
                                onChange={(e) => setTempFurnishingDetail(e.target.value)}
                              >
                                <option disabled selected value="">Choose an option</option>
                                {allFurnishing && allFurnishing.length > 0 && allFurnishing.map((item, index) => (
                                  <option value={item.name} key={index}>{item.name}</option>
                                ))}
                              </select>
                            ) : (
                              item.furnishing
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tempIndex2 === index ? (
                              <input
                                value={tempFurnishingQuantity}
                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                className="inputBox"
                                type="text"
                                placeholder="Quantity"
                              />
                            ) : (
                              item.quanitity
                            )}
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: 'center' }}>
                              {tempIndex2 === index ? (
                                <Done
                                  onClick={() => handleUpdatePlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ModeEditOutline
                                  onClick={() => handleEditPlan2(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <Delete
                                onClick={() => handleRemoveFromPlan2(index)}
                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                ''
              )}
              <Grid item xs={6}>
                <label>Specification</label>
                <select
                  value={specification}
                  onChange={(e) => {
                    setSpecification(e.target.value)
                  }}>
                  <option disabled selected value={""}>Choose specification</option>
                  {allSpecifications && allSpecifications.map((item, index) => (
                    <option value={item.name} key={index}>{item.name}</option>
                  ))}
                </select>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <label>Length</label>
                    <input
                      type='text'
                      className='inputBox'
                      value={specificationLength}
                      placeholder='Length'
                      onChange={(e) => setSpecificationLength(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label>Breath</label>
                    <input
                      type='text'
                      className='inputBox'
                      value={specificationBreath}
                      placeholder='Breath'
                      onChange={(e) => setSpecificationBreath(e.target.value)}
                    />

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <p
                  onClick={handleAddAllSpecifications}
                  style={{
                    borderRadius: '50%',
                    background: '#278FD9',
                    fontWeight: '600',
                    padding: '1px 16px',
                    fontSize: '35px',
                    width: '54px',
                    height: '54px',
                    marginTop: '25px',
                    cursor: 'pointer',
                    boxShadow: '0px 4px 4px #ddd'
                  }}
                >
                  <span style={{ color: '#FFFFFF' }}>+</span>
                </p>
              </Grid>
              {
                allSpecificationList && allSpecificationList.length > 0
                  ?
                  <Grid item xs={12} sx={{ width: '100%', overflow: 'hidden' }}>
                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Specification</TableCell>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Length</TableCell>
                          <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Breath</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          allSpecificationList.map((item, index) => (
                            <TableRow>
                              <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }} >
                                {
                                  tempIndex === index
                                    ?
                                    <select
                                      value={tempSpecification}
                                      onChange={(e) => {
                                        setTempSpecification(e.target.value)
                                      }}>
                                      <option disabled selected value={""}>Choose specification</option>
                                      {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                      ))}
                                    </select>
                                    : item.specification
                                }
                              </TableCell>
                              <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                {
                                  tempIndex === index
                                    ?
                                    <input
                                      type='text'
                                      className='inputBox'
                                      value={tempSpecificationHeight}
                                      placeholder='Breath'
                                      onChange={(e) => setTempSpecificationHeight(e.target.value)}
                                    />
                                    : item.specificationLength
                                }
                              </TableCell>
                              <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                {
                                  tempIndex === index
                                    ?
                                    <input
                                      type='text'
                                      className='inputBox'
                                      value={tempSpecificationWidth}
                                      placeholder='Breath'
                                      onChange={(e) => setTempSpecificationWidth(e.target.value)}
                                    />
                                    :
                                    item.specificationBreath}
                              </TableCell>
                              <TableCell align='center' sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                {
                                  tempIndex === index
                                    ?
                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                    :
                                    <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                }
                                <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </Grid>
                  :
                  ""
              }
              {floorPlans && floorPlans.length > 0
                ?
                <Grid item xs={12}>
                  <Table stickyHeader aria-label="sticky table" sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Photo
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Unit Type
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Carpet Area
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Construction Status
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Possession Date
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Specification
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                          Furnishing
                        </TableCell>
                        <TableCell>
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {floorPlans.map((item, index) => (
                        <>
                          <TableRow key={index}>
                            {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              <img src={item.floorPlanImage[0]} alt="" style={{ width: '90px' }} />
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.unitType}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.carpetSize}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.price}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.constructionStatus}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {item.possessionDate ? item.possessionDate : "Not applicable"}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {getSpecificationsNameForFlatsAndFarmHouse(item.specificationList)}
                            </TableCell>
                            <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                              {getFurnishingDetailsName(item.furnishings)}
                            </TableCell>
                            <TableCell sx={{ borderBottom: 'none' }}>
                              {
                                tempFloorPlanIndex === index
                                  ?
                                  <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                  :
                                  <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                              }
                              <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                            </TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
                :
                ""
              }
            </Grid>

            :
            ""
        }

        <br />
        <Button
          onClick={handleSubmit}
          style={{
            width: '224px',
            height: '52px',
            borderRadius: '8.62px',
            background: '#278FD9',
            color: '#FFFFFF',
            fontWeight: '700',
            marginBottom: '33px',
          }}
        >
          Next
        </Button>
        <Button
          onClick={handleAddFloorPlan}
          style={{
            width: '224px',
            height: '52px',
            marginBottom: '33px',
            marginLeft: '10px',
            borderRadius: '8.62px'
          }}
          variant='outlined'
          disabled={temp}
        >
          Add
        </Button>
        <Button
          onClick={() => navigate('/property-management/add-property')}
          style={{
            width: '224px',
            height: '52px',
            borderRadius: '8.62px',
            background: '#E2E2E2',
            color: '#676767',
            fontWeight: '700',
            marginBottom: '33px',
            marginLeft: '10px'
          }}
        >
          Back
        </Button>
      </Box>
    </>
  );
};
export default FloorPlanForm;