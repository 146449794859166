import Layout from "../../assets/Layout";
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Typography,
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import {
  addsubcompany,
  deleteSubCompany,
  getAllpropertySubCategorys,
  updateSubCompany,
  uploadDocToS3,
} from "../../actions";
import UploadDataBox from "../../assets/UploadDataBox";
import UploadData from "../../assets/UploadData";
import UploadDataCard from "../../assets/UploadDataCard";
import { enqueueSnackbar } from "notistack";
import { Delete, Done, Download, ModeEditOutline } from "@mui/icons-material";

function SubCompany() {
  const [documentPicView, setDocumentPicView] = useState([]);
  const [subCompanyName, setSubCompanyName] = useState("");
  const [representativeName, setRepresentativeName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gst, setGst] = useState("");
  const [pan, setPan] = useState("");
  const [paymentPlans, setPaymentPlans] = React.useState([]);
  const [tempIndex, setTempIndex] = React.useState([]);
  const [tempSubCompanyName, setTempSubCompanyName] = React.useState("");
  const [tempRepresentativeName, setTempRepresentativeName] =
    React.useState("");
  const [tempContactNumber, setTempContactNumber] = React.useState("");
  const [tempEmail, setTempEmail] = React.useState("");
  const [tempGst, setTempGst] = React.useState("");
  const [tempPan, setTempPan] = React.useState("");
  const [tempDocumentPicView, setTempDocumentPicView] = React.useState([]);

  const dispatch = useDispatch();

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "26.3%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  const loadAllSubCompanies = useCallback(() => {
    dispatch(
      getAllpropertySubCategorys({}, (data) => {
        setPaymentPlans(data.data);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllSubCompanies();
  }, [loadAllSubCompanies]);

  // const token = localStorage.getItem('token');
  const handleUploadImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("documents", file);
      dispatch(
        uploadDocToS3(
          formData,
          (data) => {
            setDocumentPicView((oldArr) => [...oldArr, data.data.documents[0]]);
          },
          (err) => {
            console.log(err);
          }
        )
      );
    }
  };

  const handleUploadTempImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("documents", file);
      dispatch(
        uploadDocToS3(
          formData,
          (data) => {
            setTempDocumentPicView([data.data.documents[0]]);
          },
          (err) => {
            console.log(err);
          }
        )
      );
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (
      !subCompanyName ||
      !representativeName ||
      !contactNumber ||
      !gst ||
      !pan
    ) {
      enqueueSnackbar("Please fill all the fields", { variant: "warning" });
    } else {
      let payload = {
        subCompanyName,
        represnetativeName: representativeName,
        contactNumber,
        email,
        gst,
        pan,
        documents: documentPicView,
      };

      dispatch(
        addsubcompany(payload, (data) => {
          if (data.status === 201) {
            enqueueSnackbar("Data added successfully", { variant: "success" });
            setSubCompanyName("");
            setRepresentativeName("");
            setContactNumber("");
            setEmail("");
            setGst("");
            setPan("");
            setDocumentPicView([]);
            loadAllSubCompanies();
          } else {
            enqueueSnackbar("Error occured", { variant: "error" });
          }
        })
      );
    }
  }

  const handleRemoveFromPlan = (id) => {
    dispatch(
      deleteSubCompany(id, {}, (data) => {
        if (data.status == 200) {
          enqueueSnackbar(data.data.message, { variant: "success" });
        } else {
          enqueueSnackbar("Error occured", { variant: "error" });
        }
        loadAllSubCompanies();
      })
    );
    // setPaymentPlans((prevArray) => {
    //   const newArray = [...prevArray]; // Create a copy of the original array
    //   newArray.splice(index, 1); // Remove the element at the specified index
    //   return newArray; // Update the state with the new array
    // });
  };

  const handleEditPackage = (index) => {
    setTempIndex(index);
    setTempDocumentPicView(paymentPlans[index].documents);
    setTempSubCompanyName(paymentPlans[index].subCompanyName);
    setTempRepresentativeName(paymentPlans[index].representativeName);
    setTempContactNumber(paymentPlans[index].contactNumber);
    setTempEmail(paymentPlans[index].email);
    setTempGst(paymentPlans[index].gst);
    setTempPan(paymentPlans[index].pan);
  };

  const handleUpdatePackage = (index, id) => {
    if (
      !tempSubCompanyName ||
      !tempRepresentativeName ||
      !tempContactNumber ||
      !tempGst ||
      !tempPan
    ) {
    } else {
      const val = paymentPlans;
      val[index] = {
        documents: tempDocumentPicView,
        subCompanyName: tempSubCompanyName,
        represnetativeName: tempRepresentativeName,
        contactNumber: tempContactNumber,
        email: tempEmail,
        gst: tempGst,
        pan: tempPan,
        document,
      };
      dispatch(
        updateSubCompany(id, val[index], (data) => {
          if (data.status === 200) {
            enqueueSnackbar("Sub company updated sucessfully", {
              variant: "success",
            });
          } else {
            enqueueSnackbar("Error Occured", { variant: "error" });
          }
        })
      );
      setPaymentPlans(val);
      setTempIndex(null);
    }
  };

  return (
    <Layout>
      <Paper>
        <Box sx={{ p: 4 }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "28px",
              color: "#000000",
              marginBottom: "48px",
            }}
          >
            Sub Company
          </Typography>

          <Box sx={{ py: 2 }}>
            <UploadDataBox>
              <UploadData name={"Upload your documents"}>
                <UploadDataCard>
                  <img
                    src={
                      documentPicView.length > 0
                        ? documentPicView[0]
                        : "/uploadImg.png"
                    }
                    alt="upload"
                    className="img-fluid"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    style={hideInput}
                    onChange={(e) => handleUploadImage(e)}
                  />
                </UploadDataCard>
              </UploadData>
            </UploadDataBox>
          </Box>

          <Divider
            sx={{
              height: "1.5px",
              background: "#E0E4EC",
              marginTop: "73px",
              marginBottom: "34px",
            }}
          />
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label>Sub Company Name*</label>
                <input
                  onChange={(e) => setSubCompanyName(e.target.value)}
                  value={subCompanyName}
                  className="inputBox"
                  type="text"
                  name="name"
                  placeholder="Sub Company Name"
                />
              </Grid>
              <Grid item xs={6}>
                <label>Representative Name*</label>
                <input
                  onChange={(e) => setRepresentativeName(e.target.value)}
                  value={representativeName}
                  className="inputBox"
                  type="text"
                  placeholder="Representative Name"
                />
              </Grid>
              <Grid item xs={6}>
                <label>Contact Number*</label>
                <input
                  onChange={(e) => setContactNumber(e.target.value)}
                  value={contactNumber}
                  className="inputBox"
                  type="text"
                  pattern="[789][0-9]{9}"
                  name="role"
                  placeholder="Enter Contact Number"
                />
              </Grid>
              <Grid item xs={6}>
                <label>Email</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  className="inputBox"
                  type="email"
                  name="role"
                  placeholder="Enter Email"
                />
              </Grid>
              <Grid item xs={6}>
                <label>Gst*</label>
                <input
                  onChange={(e) => setGst(e.target.value)}
                  value={gst}
                  className="inputBox"
                  type="text"
                  id="txtGSTIN"
                  pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                  name="role"
                  placeholder="Enter Gst"
                />
              </Grid>

              <Grid item xs={6}>
                <label>Pan*</label>
                <input
                  onChange={(e) => setPan(e.target.value)}
                  value={pan}
                  className="inputBox"
                  type="text"
                  name="description"
                  placeholder="Enter PAN"
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ padding: "13px 50px", mt: 5 }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </form>
          <Divider
            sx={{
              height: "1.5px",
              background: "#E0E4EC",
              marginTop: "73px",
              marginBottom: "34px",
            }}
          />
          <Typography>
            <b>All Sub companies : </b>
          </Typography>
          {paymentPlans.length > 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Icon</TableCell>
                    <TableCell align="center">Name</TableCell>
                    <TableCell align="center">Representative</TableCell>
                    <TableCell align="center">Contact</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">GST</TableCell>
                    <TableCell align="center">PAN</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paymentPlans.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <UploadDataBox>
                            <UploadData name={"Upload your documents"}>
                              <UploadDataCard>
                                <img
                                  src={
                                    tempDocumentPicView.length > 0
                                      ? tempDocumentPicView[0]
                                      : "/uploadImg.png"
                                  }
                                  alt="upload"
                                  className="img-fluid"
                                />
                                <IconButton
                                  aria-label="clear_image"
                                  sx={{
                                    position: "absolute",
                                    zIndex: 2,
                                    right: "0px",
                                    top: "5px",
                                    background: "#278FD9aa",
                                    height: "25px",
                                    width: "25px",
                                  }}
                                >
                                  <a
                                    href={tempDocumentPicView}
                                    target="_blank"
                                    download
                                  >
                                    <Download
                                      color={"white"}
                                      sx={{ fontSize: ".8rem" }}
                                    />
                                  </a>
                                </IconButton>
                                <input
                                  type="file"
                                  accept="image/*"
                                  style={hideInput}
                                  onChange={(e) => handleUploadTempImage(e)}
                                />
                              </UploadDataCard>
                            </UploadData>
                          </UploadDataBox>
                        ) : item.documents.length > 0 ? (
                          <img
                            src={item.documents[0]}
                            alt={item.subCompanyName}
                            style={{ width: "130px" }}
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <input
                            value={tempSubCompanyName}
                            onChange={(e) =>
                              setTempSubCompanyName(e.target.value)
                            }
                            className="inputBox"
                            type="text"
                            placeholder="Milestone"
                          />
                        ) : (
                          item.subCompanyName
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <input
                            value={tempRepresentativeName}
                            onChange={(e) =>
                              setTempRepresentativeName(e.target.value)
                            }
                            className="inputBox"
                            type="text"
                            placeholder="Plan"
                          />
                        ) : (
                          item.represnetativeName
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <input
                            value={tempContactNumber}
                            onChange={(e) =>
                              setTempContactNumber(e.target.value)
                            }
                            className="inputBox"
                            type="text"
                            placeholder="Plan"
                          />
                        ) : (
                          item.contactNumber
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <input
                            value={tempEmail}
                            onChange={(e) => setTempEmail(e.target.value)}
                            className="inputBox"
                            type="text"
                            placeholder="Plan"
                          />
                        ) : (
                          item.email
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <input
                            value={tempGst}
                            onChange={(e) => setTempGst(e.target.value)}
                            className="inputBox"
                            type="text"
                            placeholder="Plan"
                          />
                        ) : (
                          item.gst
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {tempIndex === index ? (
                          <input
                            value={tempPan}
                            onChange={(e) => setTempPan(e.target.value)}
                            className="inputBox"
                            type="text"
                            placeholder="Plan"
                          />
                        ) : (
                          item.pan
                        )}
                      </TableCell>
                      <TableCell>
                        <p style={{ textAlign: "center" }}>
                          {tempIndex === index ? (
                            <Done
                              onClick={() =>
                                handleUpdatePackage(index, item._id)
                              }
                              sx={{ cursor: "pointer" }}
                            />
                          ) : (
                            <ModeEditOutline
                              onClick={() => handleEditPackage(index)}
                              sx={{ cursor: "pointer" }}
                            />
                          )}
                          <Delete
                            onClick={() => handleRemoveFromPlan(item._id)}
                            sx={{ color: "#EA000D", cursor: "pointer" }}
                          />
                        </p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            ""
          )}
        </Box>
      </Paper>
    </Layout>
  );
}

export default SubCompany;
