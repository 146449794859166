import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../assets/Layout";
import { useDispatch } from "react-redux";
import {
  getAllBroker,
  getAllBuilders,
  getAllClaims,
  getAllCustomers,
  getAllInvoicesForBuilder,
  getAllPropertiesForBroker,
  getAllProperty,
  getAllVisit,
  getAllreferalsPayment,
} from "../actions";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [pendingInvoice, setPendingInvoice] = useState(0);
  const [processingInvoice, setProcessingInvoice] = useState(0);
  const [paidInvoice, setPaidInvoice] = useState(0);
  const [submittedClaim, setSubmittedClaim] = useState(0);
  const [approvedClaim, setApprovedClaim] = useState(0);
  const [paymentClaim, setPaymentClaim] = useState(0);
  const [paidClaim, setPaidClam] = useState(0);
  const [rejectedClaim, setRejectedClaim] = useState(0);
  const [newSubscription, setNewSubscription] = useState(0);
  const [currentSubscription, setCurrentSubscription] = useState(0);
  const [processingPayment, setProcessingPayment] = useState(0);
  const [paidPayment, setPaidPayment] = useState(0);
  const [pendingVisit, setPendingVisit] = useState(0);
  const [boughtVisit, setBoughtVisit] = useState(0);
  const [completedVisit, setCompletedVisit] = useState(0);
  const [pendingPromotedVisit, setPendingPromotedVisit] = useState(0);
  const [boughtPromotedVisit, setBoughtPromotedVisit] = useState(0);
  const [completedPromotedVisit, setCompletedPromotedVisit] = useState(0);
  const [queries, setQueries] = useState(0);
  const [intent, setIntent] = useState(0);
  const [disputes, setDisputes] = useState(0);
  const [raisedQuries, setRaisedQuries] = useState(0);
  const [assignedQuries, setAssignedQuries] = useState(0);
  const [maturedQuries, setMaturedQuries] = useState(0);
  const [notMaturedQuries, setNotMaturedQuries] = useState(0);
  const [brokerStatistics, setBrokerStatistics] = useState(0);
  const [builderStatistics, setBuilderStatistics] = useState(0);
  const [propertyStatistics, setPropertyStatistics] = useState(0);
  const [loanStatistics, setLoanStatistics] = useState(0);
  const [customerStatistics, setCustomerStatistics] = useState(0);
  const [visitStatistics, setVisitStatistics] = useState(0);
  const [claimStatistics, setClaimStatistics] = useState(0);
  const [brokerageStatistics, setBrokerageStatistics] = useState(0);
  const [loanQStatistics, setLoanQStatistics] = useState(0);
  const [referalStatistics, setReferalStatistics] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllInvoicesForBuilder({ status: "pending" }, (data) => {
        setPendingInvoice(data.data.length);
      })
    );

    dispatch(
      getAllInvoicesForBuilder({ status: "processing" }, (data) => {
        setProcessingInvoice(data.data.length);
      })
    );

    dispatch(
      getAllInvoicesForBuilder({ status: "settled" }, (data) => {
        setPaidInvoice(data.data.length);
      })
    );

    dispatch(
      getAllClaims({ claimStatus: "submitted" }, (data) => {
        setSubmittedClaim(data.data.length);
      })
    );

    dispatch(
      getAllClaims({ claimStatus: "approved" }, (data) => {
        setApprovedClaim(data.data.length);
      })
    );

    dispatch(
      getAllClaims({ claimStatus: "rejected" }, (data) => {
        setRejectedClaim(data.data.length);
      })
    );

    dispatch(
      getAllClaims({ claimStatus: "recieved" }, (data) => {
        setPaymentClaim(data.data.length);
      })
    );

    dispatch(
      getAllClaims({ claimStatus: "paid" }, (data) => {
        setPaidClam(data.data.length);
      })
    );

    dispatch(
      getAllreferalsPayment({ status: "pending" }, (data) => {
        setProcessingPayment(data.data.length);
      },
      (err) => {
        console.log(err);
      })
    );

    dispatch(
      getAllreferalsPayment({ status: "paid" }, (data) => {
        setPaidPayment(data.data.length);
      },
      (err) => {
        console.log(err);
      })
    );

    dispatch(
      getAllVisit({ activeVisits: true }, (data) => {
        setPendingVisit(data.data.length);
      })
    );

    dispatch(
      getAllVisit({ visitStatus: "bought" }, (data) => {
        setBoughtVisit(data.data.length);
      })
    );

    dispatch(
      getAllVisit({ visitStatus: "completed" }, (data) => {
        setCompletedVisit(data.data.length);
      })
    );

    dispatch(
      getAllVisit({ activeVisits: true, isPromoted: true }, (data) => {
        setPendingPromotedVisit(data.data.length);
      })
    );

    dispatch(
      getAllVisit({ visitStatus: "bought", isPromoted: true }, (data) => {
        setBoughtPromotedVisit(data.data.length);
      })
    );

    dispatch(
      getAllVisit({ visitStatus: "completed", isPromoted: true }, (data) => {
        setCompletedPromotedVisit(data.data.length);
      })
    );

    dispatch(
      getAllBroker(
        {},
        (data) => {
          setBrokerStatistics(data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllBuilders(
        {isAdmin: true},
        (data) => {
          setBuilderStatistics(data.data.length);
        },
        (err) => {
          console.log(err);
        }
      )
    );

    dispatch(
      getAllPropertiesForBroker({}, (data) => {
        setPropertyStatistics(data.data.length);
      })
    );

    dispatch(
      getAllCustomers({}, (data) => {
        setCustomerStatistics(data.data.length);
      })
    );

    dispatch(
      getAllVisit({}, (data) => {
        setVisitStatistics(data.data.length);
      })
    );

    dispatch(
      getAllClaims({}, (data) => {
        setClaimStatistics(data.data.length);
      })
    );
  }, []);

  return (
    <Layout path="dashboard">
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Invoice
      </Typography>
      <Grid container my={3}>
        <Grid item md={3}>
          <Link to={"/invoices/all"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Pending
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {pendingInvoice}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={3}>
          <Link to={"/invoices/all/processed"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Processing
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {processingInvoice}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={3}>
          <Link to={"/invoices/all/paid"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Paid
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {paidInvoice}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Claims
      </Typography>
      <Grid container my={3}>
        <Grid item md={2.4}>
          <Link to={"/claims"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Submitted
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {submittedClaim}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Link to="/claims/claims-approved">
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Approved
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {approvedClaim}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Link to={"/claims/payment-received"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Payment Received
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {paymentClaim}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Link to="/claims/claims-paid">
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Paid
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {paidClaim}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Link to={"/claims/claims-rejected"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Rejected
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {rejectedClaim}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <Typography sx={{ fontWeight: "500" }} variant="h5">
            Subscriptions
          </Typography>
          <Grid container my={3}>
            <Grid item md={6}>
              <Link to={"/subscriptions"}>
                <Card
                  sx={{
                    borderRadius: "1.25rem",
                    height: "4.5rem",
                    minWId: "15.9rem",
                    width: {
                      xl: "12.9375rem",
                      md: "12rem",
                      sm: "10rem",
                      xs: "10rem",
                    },
                    boxShadow: "2px 4px #ededed",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CardContent className="textArea">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          variant="p"
                          color={"#898989"}
                          fontSize={"400"}
                        >
                          New
                        </Typography>
                        <Typography variant="h6" textAlign={"center"}>
                          {newSubscription}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item md={3}>
              <Link to={"/new-subscriptions"}>
                <Card
                  sx={{
                    borderRadius: "1.25rem",
                    height: "4.5rem",
                    minWId: "15.9rem",
                    width: {
                      xl: "12.9375rem",
                      md: "12rem",
                      sm: "10rem",
                      xs: "10rem",
                    },
                    boxShadow: "2px 4px #ededed",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CardContent className="textArea">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          variant="p"
                          color={"#898989"}
                          fontSize={"400"}
                        >
                          Current
                        </Typography>
                        <Typography variant="h6" textAlign={"center"}>
                          {currentSubscription}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Typography sx={{ fontWeight: "500" }} variant="h5">
            Referral Payment
          </Typography>
          <Grid container my={3}>
            <Grid item md={6}>
              <Link to={"/broker-management/refer-payments"}>
                <Card
                  sx={{
                    borderRadius: "1.25rem",
                    height: "4.5rem",
                    minWId: "15.9rem",
                    width: {
                      xl: "12.9375rem",
                      md: "12rem",
                      sm: "10rem",
                      xs: "10rem",
                    },
                    boxShadow: "2px 4px #ededed",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CardContent className="textArea">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          variant="p"
                          color={"#898989"}
                          fontSize={"400"}
                        >
                          Processing
                        </Typography>
                        <Typography variant="h6" textAlign={"center"}>
                          {processingPayment}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
            <Grid item md={6}>
              <Link to={"/broker-management/refer-payments/paid"}>
                <Card
                  sx={{
                    borderRadius: "1.25rem",
                    height: "4.5rem",
                    minWId: "15.9rem",
                    width: {
                      xl: "12.9375rem",
                      md: "12rem",
                      sm: "10rem",
                      xs: "10rem",
                    },
                    boxShadow: "2px 4px #ededed",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <CardContent className="textArea">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <Typography
                          variant="p"
                          color={"#898989"}
                          fontSize={"400"}
                        >
                          Paid
                        </Typography>
                        <Typography variant="h6" textAlign={"center"}>
                          {paidPayment}
                        </Typography>
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Visits
      </Typography>
      <Grid container my={3}>
        <Grid item md={3}>
          <Link to={"/all-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "6.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Pending / FollowUp / Negotiate
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {pendingVisit}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={3}>
          <Link to={"/bought-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Bought
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {boughtVisit}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={3}>
          <Link to={"/completed-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Completed
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {completedVisit}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Promoted Visits
      </Typography>
      <Grid container my={3}>
        <Grid item md={3}>
          <Link to={"/all-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "6.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Pending / FollowUp / Negotiate
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {pendingPromotedVisit}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={3}>
          <Link to={"/bought-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Bought
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {boughtPromotedVisit}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={3}>
          <Link to={"/completed-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Completed
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {completedPromotedVisit}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Actionable
      </Typography>
      <Grid container my={3}>
        <Grid item md={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Queries
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {queries}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Intent
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {intent}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Disputes
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {disputes}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Loan Queries
      </Typography>
      <Grid container my={3}>
        <Grid item md={4}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Raised
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {raisedQuries}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Assigned
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {assignedQuries}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Matured
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {maturedQuries}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4} my={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Not Matured
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {notMaturedQuries}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography sx={{ fontWeight: "500" }} variant="h5">
        Statistics
      </Typography>
      <Grid container my={3}>
        <Grid item md={2.4}>
          <Link to={"/broker-management"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Broker
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {brokerStatistics}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Link to={"/builder-management"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Builder
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {builderStatistics}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Link to={"/property-management"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Property
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {propertyStatistics}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Loan Agents
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {loanStatistics}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2.4}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Customers
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {customerStatistics}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2.4} my={3}>
          <Link to={"/all-visits"}>
            <Card
              sx={{
                borderRadius: "1.25rem",
                height: "4.5rem",
                minWId: "15.9rem",
                width: {
                  xl: "12.9375rem",
                  md: "12rem",
                  sm: "10rem",
                  xs: "10rem",
                },
                boxShadow: "2px 4px #ededed",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
              }}
            >
              <CardContent className="textArea">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <Stack
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Typography variant="p" color={"#898989"} fontSize={"400"}>
                      Visits
                    </Typography>
                    <Typography variant="h6" textAlign={"center"}>
                      {visitStatistics}
                    </Typography>
                  </Stack>
                </Box>
              </CardContent>
            </Card>
          </Link>
        </Grid>
        <Grid item md={2.4} my={3}>
          <Link to={"/claims"}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Claims
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {claimStatistics}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
          </Link>
        </Grid>
        <Grid item md={2.4} my={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Brokerage
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {brokerageStatistics}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2.4} my={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Loan Queries
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {loanQStatistics}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={2.4} my={3}>
          <Card
            sx={{
              borderRadius: "1.25rem",
              height: "4.5rem",
              minWId: "15.9rem",
              width: {
                xl: "12.9375rem",
                md: "12rem",
                sm: "10rem",
                xs: "10rem",
              },
              boxShadow: "2px 4px #ededed",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <CardContent className="textArea">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Typography variant="p" color={"#898989"} fontSize={"400"}>
                    Referral
                  </Typography>
                  <Typography variant="h6" textAlign={"center"}>
                    {referalStatistics}
                  </Typography>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Dashboard;
