import React, { useEffect, useState } from 'react';

import Layout from '../../assets/Layout';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';
import { sectionBarBtn } from '../../Style/stylesheet';
import EditIcon from '../../assets/img/edit.svg'
import {
    Checkbox,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    Button,
    Grid,
    Rating,
} from '@mui/material';

import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonController from '../../assets/ButtonController';
import SearchFilter from '../../assets/SearchFilter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch } from 'react-redux';
import { getAllInvoicesForBuilder, updateClaimByAdmin, updateInvoice } from '../../actions';
import InvoiceModal from '../../assets/InvoiceModal';
import { enqueueSnackbar } from 'notistack';
import InvoiceTransactionDetailsForm from './InvoiceTransactionDetailsForm';



export default function InvoiceList() {
    const [sectionBarActive, setSectionBarActive] = useState('Invoices')
    const [sectionBarActiveQueries, setSectionBarActiveQueries] = useState('Invoices/PendingInvoice')
    const [viewModal, setViewModal] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [searchQuery, setSearchQuery] = useState('');
    const builderId = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    useEffect(() => {
        dispatch(getAllInvoicesForBuilder({ builderId: builderId?.id, status: "pending" }, (data) => {
            var invoices = [];
            data.data.map((item) => {
                item?.invoices.map((item1) => {
                    invoices.push(item1);
                })
            })
            const filter = invoices.filter((item) => {
                if (searchQuery) {
                    if (item?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.visitId?._id.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.brokerId?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.builderId?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (new Date(item?.createdAt).toLocaleDateString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.invoiceAmount.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.status.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.subCompany?.represnetativeName.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.subCompany?.email.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.subCompany?.contactNumber?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    return false;
                } else {
                    return true;
                }
            })
            setAllInvoices(invoices);
            if (searchQuery) {
                setInvoices(filter);
            }
        }))
    }, [builderId?.id, searchQuery]);

    useEffect(() => {
        dispatch(getAllInvoicesForBuilder({ builderId: builderId?.id, status: "pending", pageSize: countPerPage, pageNo: currentPage }, (data) => {
            var invoices = [];
            data.data.map((item) => {
                item?.invoices.map((item1) => {
                    invoices.push(item1);
                })
            })
            if(searchQuery == ""){
                setInvoices(invoices);
            }
        }))
    }, [builderId?.id, countPerPage, currentPage, searchQuery]);

    const handleButtonClick = (id) => {
        setViewModal(true)
        setInvoiceId(id);
        console.log("fhgvjhx")
    };

    const headData = [
        'Inovice ID',
        'Visit ID',
        'Broker ID',
        'Builder ID',
        'Property Name',
        'Claim ID',
        'Invoice Date',
        'Invoice amount',
        'Invoice Status',
        'View Invoice',
        'Sub-Company Rep Name',
        'Sub-Company Rep Email',
        'Sub-Company Rep No.',

    ];

    const [transactionDetailsAction, setTransactionDetailsAction] = useState(false);
    const [invoiceId, setInvoiceId] = useState("");
    const [claimId, setClaimId] = useState("");
    const [invoicePrice, setInvoicePrice] = useState("");

    const handleInvoiceStatusChange = (e, claimId, invoiceId, price) => {
        if (e?.target?.value?.toLowerCase() == "settled") {
            setTransactionDetailsAction(true);
            setInvoiceId(invoiceId);
            setClaimId(claimId);
            setInvoicePrice(price);
        } else {
            const payload = {
                id: invoiceId,
                status: e.target.value
            }
            dispatch(updateInvoice(payload, (data) => {
                enqueueSnackbar("Success", { variant: 'success' });
                window.location.reload();
            }, (err) => {
                console.log(err);
                enqueueSnackbar("Error Occurred while creating api", { variant: 'error' });
            }))
        }
    }
    return (
        <Layout path="builder-management">
            <Box>
                <Grid container my={2} >
                    {/* <Grid item md={1}>
                        <IconButton aria-label="left-arrow">
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid> */}
                    <Grid item md={12}>
                        <Stack
                            direction={"row"}
                            sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
                            spacing={3}
                        >
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Profile" && "active"}
                                onClick={() => navigate("/builder-management/profile/" + builderId?.id)}
                            >
                                Profile
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Properties" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/properties/" + builderId?.id)
                                }
                            >
                                Properties
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Invoices" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/invoices/" + builderId?.id)
                                }
                            >
                                Invoices
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Subscription" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/subscription/" + builderId?.id)
                                }
                            >
                                Subscription
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Queries" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/queries/" + builderId?.id)
                                }
                            >
                                Queries
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "PropertyEditRequest" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/property-edit-request/" + builderId?.id)
                                }
                            >
                                Property Edit Request
                            </Button>
                        </Stack>

                        <Stack
                            direction={'row'}
                            sx={{ p: 1, bgcolor: '#fff', my: 5, borderRadius: 10, justifyContent: 'center', mx: 20 }}
                        >
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActiveQueries === 'Invoices/PendingInvoice' && 'active'}
                                onClick={() => navigate('/builder-management/invoices/' + builderId?.id)}
                            >
                                Pending Invoice
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActiveQueries === 'Processing' && 'active'}
                                onClick={() => navigate('/builder-management/processing-invoices/' + builderId?.id)}
                            >
                                Processing Invoice
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActiveQueries === 'paid' && 'active'}
                                onClick={() => navigate('/builder-management/paid-invoices/' + builderId?.id)}
                            >
                                Paid Invoice
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                </SearchFilter>
                <Box sx={{ mb: 4 }}></Box>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {invoices?.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        <TableCell>{item?._id}</TableCell>
                                        <TableCell>{item?.claimId?.visitId ? item?.claimId?.visitId?._id : '--'}</TableCell>{' '}
                                        <TableCell>{item?.brokerId?._id}</TableCell>
                                        <TableCell sx={{ color: '#D12953' }}>{item?.builderId?._id}</TableCell>
                                        <TableCell>{item?.claimId?.propertyId?.name ? item?.claimId?.propertyId?.name : '--'}</TableCell>
                                        <TableCell>{item?.claimId?._id}</TableCell>
                                        <TableCell>{new Date(item?.createdAt)?.toLocaleDateString()}</TableCell>
                                        <TableCell>{item?.invoiceAmount}</TableCell>
                                        <TableCell>
                                            <Box sx={{ borderRadius: '20px', padding: '0px' }}>
                                                <select value={item?.status} onChange={(e) => handleInvoiceStatusChange(e, item?.claimId?._id, item?._id, item?.invoiceAmount)}>
                                                    <option value="All" selected disabled>
                                                        Select
                                                    </option>
                                                    <option value="settled">Paid</option>
                                                    <option value="pending">Pending</option>
                                                </select>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                sx={{ color: '#278FD9' }}
                                                onClick={()=>handleButtonClick(item?._id)}
                                            >
                                                VIEW
                                            </Button>
                                        </TableCell>
                                        <TableCell>{item?.claimId?.propertyId?.subCompany?.represnetativeName ? item?.claimId?.propertyId?.subCompany?.represnetativeName : '--'}</TableCell>
                                        <TableCell>{item?.claimId?.propertyId?.subCompany?.email ? item?.claimId?.propertyId?.subCompany?.email : '--'}</TableCell>
                                        <TableCell>{item?.claimId?.propertyId?.subCompany?.contactNumber ? item?.claimId?.propertyId?.subCompany?.contactNumber : '--'}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {/* <Box
                    sx={{
                        bgcolor: "mute.dark",
                        borderRadius: 1,
                        color: "dark.text",
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography>
                            {allInvoices?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                            {countPerPage > allInvoices?.length
                                ? allInvoices?.length
                                : currentPage * countPerPage > allInvoices?.length
                                    ? allInvoices?.length
                                    : currentPage * countPerPage}{' '}
                            of {allInvoices?.length}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography> Rows per page:</Typography>
                        <select style={{ marginTop: "-5px" }}
                            value={countPerPage}
                            onChange={(e) => setCountPerPage(e.target.value)}>
                            <option value="10" >
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="30">
                                30
                            </option>
                        </select>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1 || countPerPage > allInvoices?.length}
                                        sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allInvoices?.length ? "#EFEFEF" : "#FFF" }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={"span"}>
                                        {currentPage}/{Math.ceil(allInvoices?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={countPerPage > allInvoices?.length || currentPage == Math.ceil(allInvoices?.length / countPerPage)}
                                        sx={{
                                            border: "1px solid",
                                            borderColor: "mute.main",
                                            bgcolor: "white.main",
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box> */}
            </Box>
            <InvoiceModal
                isOpen={viewModal}
                setIsOpen={setViewModal}
                invoice={invoiceId} />
            <InvoiceTransactionDetailsForm
                claimId={claimId}
                invoiceId={invoiceId}
                invoicePrice={invoicePrice}
                transactionDetailsAction={transactionDetailsAction}
                setTransactionDetailsAction={setTransactionDetailsAction}
            />
        </Layout>
    );
}
