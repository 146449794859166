import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadDataCard from '../../assets/UploadDataCard';
import UploadData from '../../assets/UploadData';
import InputStyle from '../../assets/InputStyle';
import Stepper from '../../assets/Stepper';
import { useDispatch } from 'react-redux';
import { addDsa, uploadDocToS3 } from '../../actions';
import ClearIcon from '@mui/icons-material/Clear';
import { enqueueSnackbar } from 'notistack';
import regex from '../../utils/regex';

export default function AddDSA({ addDSAAction, setAddDSAAction }) {
  const [step, setStep] = useState(1);
  const [profileImage, setProfileImage] = useState("");
  const [document, setDocument] = useState(null);
  const dispatch = useDispatch();
  const [dsaName, setDsaName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [areaOfOperations, setAreaOfOperations] = useState('');
  const [bankAssociations, setBankAssociations] = useState('');
  const [maxLoanRange, setMaxLoanRange] = useState('');
  const [minLoanRange, setMinLoanRange] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [step1FieldsFilled, setStep1FieldsFilled] = useState(false);

  useEffect(() => {
    const isStep1Valid = dsaName && phoneNumber && companyName && email && address;
    setStep1FieldsFilled(isStep1Valid);
  }, [dsaName, phoneNumber, companyName, email, address]);

  const handleProfileImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setProfileImage(file);
    }
  };

  const handleDocumentUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      setDocument(file);
    }
  };

  const handleRemovePropertyBrochure = (index) => {
    setDocument(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });



    let tempArr = [...document];
    tempArr.splice(index, 1);


    if (localStorage.getItem("formData")) {
      localStorage.setItem("formData", JSON.stringify({ ...JSON.parse(localStorage.getItem("formData")), brochure: tempArr }));
    }
  }

  const backHandler = () => {
    setStep(1);
  };
  const nextHandler = () => {
    setStep(step < 3 ? step + 1 : step);
  };

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "0%",
    top: "0%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  React.useEffect(() => {
    if (email) {
      const isEmailValid = regex.emailPattern.test(email)
      setEmailError(!isEmailValid)

    }
  }, [email])

  React.useEffect(() => {
    if (phoneNumber) {
      const isPhoneValid = regex.numberPattern.test(phoneNumber)
      setPhoneError(!isPhoneValid)
    }
  }, [phoneNumber])

  const handleSubmit = () => {

    const formData = new FormData();

    // Append broker details to formData
    // formData.append('subscriptionName', subscriptionName);
    // formData.append('colorCode', colorCode);
    // formData.append('numberOfVisits', noOfVisits);
    // formData.append('bannerDays', bannerDays);
    // formData.append('flashDays', flashDays);
    // formData.append('description', description);
    // formData.append('price', price);
    // formData.append('bottomSubliner', bottomSubliner);
    // formData.append('propertyList', noOfProperties);

    const payload =
    {
      name: dsaName,
      phoneNumber,
      companyName,
      email,
      address: address,
      areaOfOperations,
      bankAssociations,
      minLoanRange,
      maxLoanRange,
      documents: URL.createObjectURL(document),
      profilePicture: URL.createObjectURL(profileImage),
    }


    // Append profile picture to formData

    setIsLoading(true);
    dispatch(
      addDsa(payload, (data) => {
        if (data.status === 200) {
          setIsLoading(false);

          enqueueSnackbar("Subscription Updated Successfully!", { variant: 'success' })
          setAddDSAAction(false)
          window.location.reload()
        }
        else {
          enqueueSnackbar("Error While updating subscription!", { variant: 'error' })
        }
      }
      )
    )
    // navigate('/subscriptions')


  };
  return (
    <Dialog
      open={addDSAAction}
      onClose={() => {
        setAddDSAAction(!addDSAAction);
       
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Add DSA
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={() => {
                setAddDSAAction(!addDSAAction);
              }}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Grid container spacing={3}>
          <Grid item md={2}>
            <Stepper arrData={['Onboarding', 'Details']} step={step} />
          </Grid>

          <Grid item md={10}>
            {step === 1 ? (
              <>
                {/* <UploadDataBox>
                  <UploadData name="Profile ">
                    <UploadDataCard>
                      <img src="/uploadImg.png" alt="Profile" />
                    </UploadDataCard>
                  </UploadData>
                  <UploadData name="Upload Documents ">
                    <UploadDataCard>
                      <img src="/uploadImg.png" alt="Profile" />
                    </UploadDataCard>
                  </UploadData>
                </UploadDataBox> */}
                <Box sx={{ display: 'flex' }}>
                  <Box mr={3}>
                    <UploadDataBox>
                      <UploadData name="Profile">
                        <UploadDataCard>
                          {
                            profileImage
                              ?
                              <img
                                src={
                                  URL.createObjectURL(profileImage)
                                }
                                style={{ width: '101px', height: '101px', objectFit: 'cover' }}
                                alt="upload"
                                className="img-fluid"
                              />
                              :
                              <img
                                src="/uploadImg.png"
                                alt="upload"
                                className="img-fluid"
                              />
                          }
                          <input
                            type="file"
                            accept="image/*"
                            multiple
                            style={hideInput}
                            onChange={(event) => handleProfileImageUpload(event, setProfileImage)}
                          />
                        </UploadDataCard>
                      </UploadData>
                    </UploadDataBox>
                  </Box>

                  <Box>
                    <UploadDataBox>
                      <UploadData name="Upload Document">
                        <UploadDataCard>
                          {document ? (
                            <embed
                              src={
                                URL.createObjectURL(document)
                              }
                              style={{ width: '101px', height: '101px', objectFit: 'cover' }}
                              alt="upload"
                              className="img-fluid"
                            />
                          ) : (
                            <img
                              src="/uploadImg.png"
                              alt="upload"
                              className="img-fluid"
                            />
                          )}
                          <input
                            accept="application/pdf"
                            type="file"
                            style={hideInput}
                            onChange={(event) => handleDocumentUpload(event, setDocument)}
                          />
                        </UploadDataCard>
                      </UploadData>

                      {/* <UploadData name={'Upload Property Brochure'}>
                        {document &&
                          document.map((item, index) => (
                            <>
                              <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                <embed src={item} width="200" height="200" />
                                <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '30px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                                  <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                </IconButton>
                              
                              </Box>
                            </>
                          ))}
                        <UploadDataCard>
                          <img
                            src={'/uploadImg.png'}
                            alt="upload"
                            className="img-fluid"
                          />
                          <input
                            type="file"
                            accept=".doc,.pdf"
                            style={hideInput}
                            multiple
                            onChange={handleUploadBrochure}
                          />
                        </UploadDataCard>
                      </UploadData> */}
                    </UploadDataBox>
                  </Box>
                </Box>

                <Divider sx={{ my: 4 }} />
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <InputStyle name="DSA Name">
                      <input
                        type="text"
                        placeholder="Enter DSA name"
                        value={dsaName}
                        onChange={(e) => setDsaName(e.target.value)}
                      />
                    </InputStyle>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputStyle name="Phone Number">
                      <input
                        type="text"
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </InputStyle>
                    {phoneError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputStyle name="Company Name">
                      <input
                        type="text"
                        placeholder="Enter Company Name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </InputStyle>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputStyle name="Email">
                      <input
                        type="text"
                        placeholder="Please enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </InputStyle>
                    {emailError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <InputStyle name="Address">
                      <input
                        type="text"
                        placeholder="Please enter your address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </InputStyle>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Stack direction="row" spacing={2}>
                      <Button
                        variant="contained"
                        sx={{ width: 120 }}
                        onClick={nextHandler}
                        disabled={!step1FieldsFilled} // Disable the button if step1FieldsFilled is false
                      >
                        Next
                      </Button>
                      <Button variant="outlined" sx={{ width: 120 }}>
                        Reset
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <InputStyle name="Area of Operations">
                    <input
                      type="text"
                      placeholder="Enter area of operations"
                      value={areaOfOperations}
                      onChange={(e) => setAreaOfOperations(e.target.value)}
                    />
                  </InputStyle>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputStyle name="Bank Associations">
                    <input
                      type="text"
                      placeholder="Enter bank associations"
                      value={bankAssociations}
                      onChange={(e) => setBankAssociations(e.target.value)}
                    />
                  </InputStyle>
                </Grid>
                <Grid item md={6} xs={12}>
                  <InputStyle name="Minimum Loan Value">
                    <input
                      type="text"
                      placeholder="Choose loan range"
                      value={minLoanRange}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (/^[0-9]*$/.test(inputText) || inputText === '') {
                          setMinLoanRange(inputText);
                        }
                      }}
                      pattern="[0-9]*"
                    />
                  </InputStyle>
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputStyle name="Maximum Loan Range">
                    <input
                      type="text"
                      placeholder="Choose loan range"
                      value={maxLoanRange}
                      onChange={(e) => {
                        const inputText = e.target.value;
                        if (/^[0-9]*$/.test(inputText) || inputText === '') {
                          setMaxLoanRange(inputText);
                        }
                      }}
                      pattern="[0-9]*"
                    />
                  </InputStyle>
                </Grid>

                <Grid item md={12} xs={12}>
                  <Stack direction="row" spacing={2}>
                    <Button variant="contained" sx={{ width: 120 }} onClick={handleSubmit} >
                      Submit
                    </Button>
                    <Button variant="outlined" sx={{ width: 120 }} onClick={backHandler}>
                      Back
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
