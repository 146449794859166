import React, { useState } from 'react';

import Layout from '../../assets/Layout';
import Breadcrumbs from '../../assets/Breadcrumbs';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';
import { sectionBarBtn } from '../../Style/stylesheet';

import {
    Checkbox,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    Button,
} from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import ButtonController from '../../assets/ButtonController';
import SearchFilter from '../../assets/SearchFilter';


import Profile from './Profile';
import AddDSA from './AddDSA';

export default function DsaPaymentList() {
    const navigate = useNavigate();
    const [sectionBarActive, setSectionBarActive] = useState('All')
    const [addDSAAction, setAddDSAAction] = useState(false);
    const [profileAction, setProfileAction] = useState(false);

    const headData = [
        'PROPERTY NAME',
        'PROPERTY TYPE',
        'ADDRESS',
        'BROKER ID',
        'BROKER NAME',
        'CLIENT NAME',
        'QUERY NO.',
        'BANK NAME',
        'DISBURSED AMT',
        'DISBURSED DATE',
        'INVOICE NO.',
        'INVOICE DATE',
        'INVOICE AMOUNT',
    ];
    return (
        <Layout path="builder-management">
            <Box>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="DSA" />
                    <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box >
                            <MainButton>
                                <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> New DSA
                            </MainButton>
                        </Box>
                    </ButtonController>
                </Stack>
                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." />
                    </Box>
                    <Box className="style-1 filter p-1">
                        <select>
                            <option value="All" selected>
                                All
                            </option>
                            <option value="option 1">option 1</option>
                        </select>
                    </Box>
                    <Box className="style-1">
                        <input type="date" placeholder="Choose Date" />
                    </Box>
                </SearchFilter>

                <Stack
                    direction={'row'}
                    sx={{ p: 1, bgcolor: '#fff', my: 2, borderRadius: 10 }}
                    spacing={3}
                >
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'All' && 'active'}
                        onClick={() => navigate('/')}
                    >
                        All
                    </Button>
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Overdue' && 'active'}
                        onClick={() => navigate('/')}
                    >
                        Overdue
                    </Button>
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Pending' && 'active'}
                        onClick={() => navigate('/')}
                    >
                        Pending
                    </Button>

                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Processing' && 'active'}
                        onClick={() => navigate('/')}
                    >
                        Processing
                    </Button>

                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Paid' && 'active'}
                        onClick={() => navigate('/')}
                    >
                        Paid
                    </Button>
                </Stack>
                <Box sx={{ mb: 4 }}></Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...Array(10)].map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        {/* <TableCell>
                                            <Link
                                                onClick={() => setProfileAction(true)}
                                                className="link"
                                            >
                                                Ann Culhane
                                            </Link>
                                        </TableCell> */}
                                        <TableCell>Eaton Garth Penthouse</TableCell>
                                        <TableCell>3BHK</TableCell>
                                        <TableCell>Sector 28</TableCell>
                                        <TableCell>46676732989</TableCell>{' '}
                                        <TableCell>Ann Culhane</TableCell>
                                        <TableCell>Ann Culhane</TableCell>
                                        <TableCell>3242</TableCell>
                                        <TableCell>HDFC</TableCell>
                                        <TableCell>₹ 3.94 Cr</TableCell>
                                        <TableCell>24 JULY</TableCell>
                                        <TableCell>1234</TableCell>
                                        <TableCell>30 AUG</TableCell>
                                        <TableCell>₹ 3.94 Cr</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: 'mute.dark',
                        borderRadius: 1,
                        color: 'dark.text',
                        p: 2,
                        mt: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography>1 - 10 of 97</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ' center',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Typography component="p">Rows per page:</Typography>
                        </Box>
                        <Box>
                            <select
                                style={{
                                    fontSize: '0.8rem',
                                    height: '35px',
                                    padding: '0',
                                    background: 'transparent',
                                }}
                            >
                                <option value="10" selected>
                                    10
                                </option>
                                <option value="10" selected>
                                    20
                                </option>
                                <option value="10" selected>
                                    30
                                </option>
                            </select>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box>
                                    <IconButton
                                        sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={'span'}>
                                        1/10
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'mute.main',
                                            bgcolor: 'white.main',
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Modals */}
            {/* <Profile
                profileAction={profileAction}
                setProfileAction={setProfileAction}
            /> */}
            <AddDSA addDSAAction={addDSAAction} setAddDSAAction={setAddDSAAction} />
            {/* Modals */}
        </Layout>
    );
}
