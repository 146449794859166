import React, { useEffect, useState } from 'react';

import Layout from '../../assets/Layout';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';
import { sectionBarBtn } from '../../Style/stylesheet';

import {
    Checkbox,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    Button,
    Grid,
    Rating,
} from '@mui/material';

import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonController from '../../assets/ButtonController';
import SearchFilter from '../../assets/SearchFilter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch } from 'react-redux';
import { getBuilderSubscription } from '../../actions';


export default function OtherInformation() {
    const [sectionBarActive, setSectionBarActive] = useState('Subscription')
    const [addDSAAction, setAddDSAAction] = useState(false);
    const [profileAction, setProfileAction] = useState(false);
    const [subscription, setSubscription] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();
    const builderId = useParams();
    const dispatch = useDispatch();


    const headData = [
        'PROPERTY NAME',
        'PLAN NAME',
        'VISITS LEFT',
        'BANNNER DAYS',
        'FLASH SCREEN',
        'TRANSACTION ID',
        'PAYMENT STATUS',
    ];

    useEffect(() => {
        dispatch(getBuilderSubscription({ builderId: builderId?.id, pageNo: currentPage, pageSize: countPerPage }, (data) => {
            const filter = data.filter((item) => {
                if (item?.transactionId.includes(searchQuery?.toLowerCase())) {
                    return true;
                }

                if (item?.planId?.flashDays.includes(searchQuery?.toLowerCase())) {
                    return true;
                }

                if (item?.planId?.bannerDays.includes(searchQuery?.toLowerCase())) {
                    return true;
                }

                if (item?.planId?.numberOfVisit.includes(searchQuery?.toLowerCase())) {
                    return true;
                }

                if (item?.planId?.subscriptionName.includes(searchQuery?.toLowerCase())) {
                    return true;
                }

                if (item?.selectProperties?.length > 0) {
                    let values = item?.selectProperties?.map(item => { return item?.name });
                    if(values.find(item => item?.toLowerCase().includes(searchQuery?.toLowerCase()))){
                        return true;
                    }
                }

                if (item?.transactionId.includes(searchQuery?.toLowerCase())) {
                    return true;
                }
                return false
            })
            setSubscription(filter);
        }))
    }, [currentPage, countPerPage, searchQuery])

    return (
        <Layout path="builder-management">
            <Box>
                <Grid container my={2} >
                    {/* <Grid item md={1}>
                        <IconButton aria-label="left-arrow">
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid> */}
                    <Grid item md={12}>
                        <Stack
                            direction={"row"}
                            sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
                            spacing={3}
                        >
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Profile" && "active"}
                                onClick={() => navigate("/builder-management/profile/" + builderId?.id)}
                            >
                                Profile
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Properties" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/properties/" + builderId?.id)
                                }
                            >
                                Properties
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Invoices" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/invoices/" + builderId?.id)
                                }
                            >
                                Invoices
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Subscription" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/subscription/" + builderId?.id)
                                }
                            >
                                Subscription
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Queries" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/queries/" + builderId?.id)
                                }
                            >
                                Queries
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "PropertyEditRequest" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/property-edit-request/" + builderId?.id)
                                }
                            >
                                Property Edit Request
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                </SearchFilter>
                <Box sx={{ mb: 4 }}></Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscription.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        {/* <TableCell>
                                            <Link
                                                onClick={() => setProfileAction(true)}
                                                className="link"
                                            >
                                                Ann Culhane
                                            </Link>
                                        </TableCell> */}

                                        <TableCell>{item?.selectProperties && item?.selectProperties.length > 0
                                            ?
                                            item?.selectProperties?.map((prop, index) => (
                                                <Typography key={index}>{prop.name}</Typography>
                                            ))
                                            :
                                            ""}</TableCell>
                                        <TableCell>{item?.planId?.subscriptionName}</TableCell>{' '}
                                        <TableCell>{item?.planId?.numberOfVisit}</TableCell>
                                        <TableCell>{item?.planId?.bannerDays}</TableCell>
                                        <TableCell>{item?.planId?.flashDays}</TableCell>
                                        <TableCell>{item?.transactionId}</TableCell>
                                        <TableCell>
                                            {!item?.transactionId ? "Pending" : "Paid"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: 'mute.dark',
                        borderRadius: 1,
                        color: 'dark.text',
                        p: 2,
                        mt: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography>1 - {subscription?.length >= 10 ? 10 : subscription?.length} of {subscription?.length}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: ' center',
                        alignItems: 'center',
                    }}>
                        <Box>
                            <Typography component="p">Rows per page:</Typography>
                        </Box>
                        <Box>
                            <select style={{
                                fontSize: '0.8rem',
                                height: '35px',
                                padding: '0',
                                background: '#FFF',
                            }}
                                value={countPerPage}
                                onChange={(e) => setCountPerPage(e.target.value)}
                                disabled={countPerPage > subscription?.length}>
                                <option value="10">
                                    10
                                </option>
                                <option value="20" >
                                    20
                                </option>
                                <option value="30" >
                                    30
                                </option>
                            </select>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                                        sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={'span'}>
                                        {currentPage}/{subscription && subscription?.length > 0 && Math.ceil(subscription?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        disabled={Math.ceil(subscription?.length / countPerPage) >= currentPage}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'mute.main',
                                            bgcolor: 'white.main',
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Layout>
    );
}
