import React, { useCallback, useEffect } from "react";
import Layout from "../../../assets/Layout";
import { BoughtData } from '../../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../../Style/stylesheet';
import AddIcon from '@mui/icons-material/Add';

import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { useState } from "react";
import ClaimsRemarks from "../../Claims/ClaimsRemarks";
import Breadcrumbs from "../../../assets/Breadcrumbs";
import ButtonController from "../../../assets/ButtonController";
import SearchFilter from "../../../assets/SearchFilter";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllVisits, updateVisitById } from "../../../actions";
import { enqueueSnackbar } from "notistack";
// import VisitRemarks from "./../VisitRemarks";

const NewSubscription = () => {
    const [sectionBarActive, setSectionBarActive] = useState('New')
    const [sectionBarActive2, setSectionBarActive2] = useState('Claims')
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const [allVisits, setAllVisits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [searchQuery, setSearchQuery] = useState('');
    const [remark, setRemark] = useState("");
    const [claimId, setClaimId] = useState('')
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const headData = [
        'Subscription ID',
        'Subscription Date',
        'Banner Days ',
        'Flash Days',
        'No. of Visits',
        'Status',
        
    ];


    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };

    const handleButtonClick = (id) => {
        navigate(`/visit-details/${id}`)
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const loadAllvisits = useCallback(() => {
        dispatch(getAllVisits({ visitStatus: "bought", pageNo: currentPage, pageSize: countPerPage }, (data) => {
            setAllVisits(data.data);
        }))

    }, [dispatch])

    useEffect(() => {
        dispatch(getAllVisits({ visitStatus: "bought", pageNo: currentPage, pageSize: countPerPage }, (data) => {
            const filter = data.data.filter((item) => {
                if (item?._id.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.brokerId?._id?.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.propertyName?.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.customerId?._id.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.date.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.visitStatus.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                return false;
            })

            setAllVisits(filter);
        }))
    }, [currentPage, countPerPage, searchQuery, addRemarkAction]);

    const updateStatus = (id, status) => {
        let payload = {
            visitId: id, visitStatus: status
        }
        console.log("Here is Payload", payload)
        dispatch(
            updateVisitById(payload, () => {
                loadAllvisits()
                enqueueSnackbar("Status updated!", { variant: 'success' })
            },
            )
        )
    }

    return (
        <>
            <Layout path="normalVisits">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Subscription - Purchase subscription" />
                    {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
                     <Button variant="contained" disableElevation   >
                       <Link to="/add-subscriptions" style={{display:'flex', justifyContent:'cente', alignItems:'center'}}> <AddIcon/>Add Subscription</Link>
                    </Button>
                </Stack>

                <Stack
                    direction={'row'}
                    sx={{ p: 1, bgcolor: '#fff', mb: 2, borderRadius: 10 }}
                    spacing={3}
                >
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'New' && 'active'}
                        onClick={() => navigate('/new-subscriptions')}
                    >
                        New Subscription
                    </Button>
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Current' && 'active'}
                        onClick={() => navigate('/current-subscriptions')}
                    >
                        Current Subscription
                    </Button>
                </Stack>

              

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                </SearchFilter>

                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allVisits?.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>46676732989</TableCell>
                                        <TableCell>14 Jan 2023</TableCell>
                                        <TableCell>46676732989</TableCell>
                                        <TableCell>14 Jan 2023</TableCell>
                                        <TableCell>₹ 3.94 L</TableCell>
                                        <TableCell>
                                            <select style={{backgroundColor:'#E9E9E9 !important'}}>
                                                <option value="New">New</option>
                                                <option value="Active">Active</option>
                                            </select>
                                        </TableCell>

                                     
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: 'mute.dark',
                        borderRadius: 1,
                        color: 'dark.text',
                        p: 2,
                        mt: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography>1 - {allVisits?.length >= 10 ? 10 : allVisits?.length} of {allVisits?.length}</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: ' center',
                        alignItems: 'center',
                    }}>
                        <Box>
                            <Typography component="p">Rows per page:</Typography>
                        </Box>
                        <Box>
                            <select style={{
                                fontSize: '0.8rem',
                                height: '35px',
                                padding: '0',
                                background: '#FFF',
                            }}
                                value={countPerPage}
                                onChange={(e) => setCountPerPage(e.target.value)}
                                disabled={countPerPage > allVisits?.length}>
                                <option value="10">
                                    10
                                </option>
                                <option value="20" >
                                    20
                                </option>
                                <option value="30" >
                                    30
                                </option>
                            </select>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                                        sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={'span'}>
                                        {currentPage}/{allVisits && allVisits?.length > 0 && Math.ceil(allVisits?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        disabled={Math.ceil(allVisits?.length / countPerPage) >= currentPage}
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'mute.main',
                                            bgcolor: 'white.main',
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Layout>

            {/* <VisitRemarks
                remark={remark}
                setRemark={setRemark}
                claimId={claimId}
                claimStatus={status}
                addRemarkAction={addRemarkAction}
                setAddRemarkAction={setAddRemarkAction}
            /> */}
        </>
    );
}

export default NewSubscription;