export const menuIcon = {
  background: "#fff",
  boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.03)",
  borderRadius: "8px",
  width: "40px",
  height: "38px",
  marginRight: "0.5rem",
};
export const menuDropDown = {
  "&.open": {
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.13)",
    my: 1,
    borderRadius: 2,
    overFlow: "hidden",
  },
};
export const menuBtn = {
  borderRadius: 2,
  color: "#69748C",
};

export const sectionBarBtn = {
  borderRadius: 10,
  boxShadow: 0,
  flexGrow: "1",
  textTransform: "capitalize",
  color: "#090909",
  "&.active": {
    bgcolor: "primary.main",
    color: "#fff",
  },
};
export const selectBar = {
  p: "3px 1rem",
  bgcolor: "rgba(217, 217, 217, 0.44)",
  mb: 3,
  borderRadius: 10,
  mx: "auto",
  flexWrap: "wrap",
  "&.col-3": {
    maxWidth: 500,
  },
  maxWidth: 350,
};

export const selectBarBtn = {
  borderRadius: 10,
  boxShadow: 0,
  flexGrow: "1",
  textTransform: "capitalize",
  color: "#090909",
  display: "flex",
  "&.box": {
    py: 1,
  },
  "&.active": {
    color: "#000",
    "& .icon": {
      bgcolor: "primary.main",
      color: "#fff",
    },
  },

  "&.done": {
    "& .icon": {
      bgcolor: "success.light",
      color: "#fff",
    },
    "& svg": {
      fontSize: "1rem",
    },
  },
  "& .icon": {
    bgcolor: "#fff",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.8rem",
    width: 25,
    height: 25,
    mr: 1,
  },
};
export const uplodeImageBtn = {
  borderRadius: "20px",
  p: 1,
  bgcolor: "#EDF2F7",
  height: 140,
  width: 140,
  display: "flex",
  alignItems: "center",
  boxShadow: "0",
  "& input": {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    opacity: "0",
  },
  "& .button": {
    display: "block",
    position: "relative",
  },
  "& .icon": {
    position: "absolute",
    bottom: 0,
    right: 0,
    bgcolor: "primary.main",
    borderRadius: 10,
    color: "#fff",
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& img": {
    borderRadius: "20px",
    width: "100%",
    height: "auto",
    mb: "-5px",
  },
  "& svg": {
    fontSize: "1rem",
  },
};
