import React from "react";
import Layout from "../assets/Layout";
import ContactUsTable from "../components/Contact/ContactUsTable";

const Contact = () => {
    return (
        <>
        <Layout path="contact">
            <ContactUsTable/>
        </Layout>
        </>
    );
}

export default Contact;