import { Delete, Done, ModeEditOutline } from "@mui/icons-material";
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFloorPlanInProperty, addPropertyDetails, getPropertyCategoryById, updateBrokerageDetails } from "../../actions";
import { enqueueSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';

const BrokerageForm = () => {
    const navigate = useNavigate();
    const [tempIndex, setTempIndex] = React.useState("");
    const [tempSpecification, setTempSpecification] = React.useState("");
    const [tempQuantity, setTempQuantity] = React.useState("");
    const [specifications, setSpecifications] = React.useState([]);
    const [specification, setSpecification] = React.useState("");
    const [quanitity, setQuantity] = React.useState("");
    const [brokerageValue, setBrokerageValue] = React.useState("");
    const [brokerageValueType, setBrokerageValueType] = React.useState("Amount");
    const [isLoading, setIsLoading] = useState(false);
    const [propertyID, setPropertyID] = useState("");
    const [propertyType, setPropertyType] = useState("");
    const [subProperty, setSubProperty] = useState("");

    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate("/");
        }
    }, [navigate])

    useEffect(() => {
        if (localStorage.getItem("formData") != null) {
            let Tempdata = JSON.parse(localStorage.getItem("formData"));
            setPropertyID(Tempdata.propertyType);
            setSubProperty(Tempdata.selectedSubCategory);
        }
    })

    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer'
    };

    const handleCreatePlan = () => {
        if (!specification || !quanitity) {
            enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
            return;
        }
        setSpecifications((oldArr) => [...oldArr, { condition: specification, brokerage: quanitity }]);
        setSpecification("");
        setQuantity("");
    }

    const handleUpdatePlan = (index) => {
        if (!tempSpecification || !tempQuantity) {
            enqueueSnackbar("please add valid fields", { variant: 'warning' });
        } else {
            const val = specifications;
            val[index] = { condition: tempSpecification, brokerage: tempQuantity };
            setSpecifications(val);
            setTempIndex("");
        }
    }

    const handleRemoveFromPlan = (index) => {
        setSpecifications((prevArray) => {
            const newArray = [...prevArray]; // Create a copy of the original array
            newArray.splice(index, 1); // Remove the element at the specified index
            return newArray; // Update the state with the new array
        });
    }

    const handleEditPlan = (index) => {
        setTempIndex(index);
        setTempSpecification(specifications[index].condition);
        setTempQuantity(specifications[index].brokerage);
    };

    const dispatch = useDispatch();

    const loadPropertyDetails = useCallback(() => {
        dispatch(
            getPropertyCategoryById(propertyID, {}, (data) => {
                setPropertyType(data.data[0].name);
            })
        );
    }, [propertyID]);

    useEffect(() => {
        loadPropertyDetails();
    }, [loadPropertyDetails]);

    const getPriceForProperty = (floorPlanAndPricing) => {
        if (propertyType.toLowerCase() === "commercial" && subProperty.toLowerCase() === "warehouse") {
            if (floorPlanAndPricing.length > 0) {
                if (floorPlanAndPricing[0].price == "") {
                    var minPrice = 0;
                    var maxPrice = 0;
                } else {
                    var minPrice = floorPlanAndPricing[0].price * floorPlanAndPricing[0].minUnitSize;
                    var maxPrice = floorPlanAndPricing[0].price * floorPlanAndPricing[0].maxUnitSize;
                }
                for (let i = 1; i < floorPlanAndPricing.length; i++) {
                    if (floorPlanAndPricing[i].price * floorPlanAndPricing[0].minUnitSize < minPrice) {
                        minPrice = floorPlanAndPricing[i].price;
                    }

                    if (floorPlanAndPricing[i].price * floorPlanAndPricing[0].maxUnitSize > maxPrice) {
                        maxPrice = floorPlanAndPricing[i].price;
                    }
                }
                return { minPrice, maxPrice };
            }
        } else {
            if (floorPlanAndPricing.length > 0) {
                if (floorPlanAndPricing[0].price == "") {
                    var minPrice = 0;
                    var maxPrice = 0;
                } else {
                    var minPrice = floorPlanAndPricing[0].price;
                    var maxPrice = floorPlanAndPricing[0].price;
                }
                for (let i = 1; i < floorPlanAndPricing.length; i++) {
                    if (parseInt(floorPlanAndPricing[i].price) < parseInt(minPrice)) {
                        minPrice = floorPlanAndPricing[i].price;
                    }

                    if (parseInt(floorPlanAndPricing[i].price) > parseInt(maxPrice)) {
                        maxPrice = floorPlanAndPricing[i].price;
                    }
                }
                return { minPrice, maxPrice };
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (
            !brokerageValue ||
            !brokerageValueType ||
            specifications.length === 0 ||
            !localStorage.getItem("formData") ||
            !localStorage.getItem("floorplans")
        ) {
            enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
            setIsLoading(false);
            return;
        }

        if (brokerageValueType !== "Amount" && brokerageValue > 100) {
            enqueueSnackbar('Please enter value which is less than 100', { variant: 'error' });
            setIsLoading(false);
        } else {
            if (localStorage.getItem("formData") != null) {
                let form = JSON.parse(localStorage.getItem("formData"));

                // create data for add property details api
                const formData = new FormData();
                formData.append("possessionDate", form?.possessionDate);
                formData.append('name', form?.name);
                formData.append("location", form?.location);
                formData.append('propertyType', form?.propertyType);
                formData.append("latitude", form?.latitude);
                formData.append("longitude", form?.longitude);
                formData.append("constructionStatus", form?.constructionStatus);
                formData.append('paymentPlan', JSON.stringify(form?.paymentPlan));
                formData.append("propertyDescription", form?.propertyDescription);
                formData.append("loanApprovedByIds", JSON.stringify(form?.loanApprovedByIds));
                formData.append("builderId", form?.builderId);
                formData.append("termAndCondition", form?.termAndCondition);
                formData.append("currentlyComparing", JSON.stringify(form?.currentlyComparing));
                formData.append("images", JSON.stringify(form?.images));
                formData.append("brochureUrl", JSON.stringify(form?.brochure));
                formData.append("amenitiesId", JSON.stringify(form?.amenitiesId));
                formData.append("locationAdvantagesId", JSON.stringify(form?.locationAdvantagesId));
                formData.append("subCompany", form?.subCompany);
                formData.append("propertyLiner", form?.propertyLiner);
                formData.append("builderDiscount", form?.builderDiscount);
                formData.append("projectSpecification", form?.projectSpecification);
                formData.append("propertyLogo", form?.propertyLogo);
                formData.append("propertyTypeSubCategory", form?.selectedSubCategory);
                let floorPlans = JSON.parse(localStorage.getItem("floorplans"));
                formData.append("minPrice", getPriceForProperty(floorPlans).minPrice);
                formData.append("maxPrice", getPriceForProperty(floorPlans).maxPrice);
                dispatch(
                    addPropertyDetails(formData, async (data) => {
                        if (data.status === 200) {
                            // on successful hit of add property details api, fetch the newly created property id
                            // and hit api to add floor plans
                            const propertyID = data.data._id;
                            if (localStorage.getItem("floorplans") != null) {
                                let floorPlans = JSON.parse(localStorage.getItem("floorplans"));

                                for (let index = 0; index < floorPlans.length; index++) {
                                    // create data for adding floor plans
                                    var formData1 = new FormData();
                                    formData1.append("floorPlanImageUrl", JSON.stringify(floorPlans[index].floorPlanImage));
                                    formData1.append("mainId", propertyID);
                                    if (propertyType.toLowerCase() === "land/plots") {
                                        formData1.append("unitType", floorPlans[index]?.unitType);
                                        formData1.append("unitSize", JSON.stringify({ unitSize: floorPlans[index]?.unitSize, length: floorPlans[index]?.plotSizeLength, breadth: floorPlans[index]?.plotSizeBreath }));
                                        formData1.append("price", floorPlans[index].price);
                                        formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                                        formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                                        formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                                    }
                                    if (propertyType.toLowerCase() === "commercial" && (subProperty.toLowerCase() === "sco" || subProperty.toLowerCase() === "commercial space")) {
                                        formData1.append("unitType", floorPlans[index]?.unitType);
                                        formData1.append("unitSize", JSON.stringify({ unitSize: floorPlans[index]?.unitSize, length: floorPlans[index]?.plotSizeLength, breadth: floorPlans[index]?.plotSizeBreath }));
                                        formData1.append("price", floorPlans[index].price);
                                        formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                                        formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                                        formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                                        formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                                    }
                                    if (propertyType.toLowerCase() === "commercial" && subProperty.toLowerCase() === "warehouse") {
                                        formData1.append("unitType", floorPlans[index]?.unitType);
                                        formData1.append("unitSize", JSON.stringify({ min: floorPlans[index]?.minUnitSize, max: floorPlans[index]?.maxUnitSize }));
                                        formData1.append("price", floorPlans[index].price);
                                        formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                                        formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                                        formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                                        formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                                    }

                                    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "builder floor" || subProperty.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
                                        formData1.append("plotSize", JSON.stringify([{ totalPlotSize: floorPlans[index]?.plotSize, length: floorPlans[index]?.plotSizeLength, breadth: floorPlans[index]?.plotSizeBreath }]));
                                        formData1.append("unitType", floorPlans[index]?.unitType);
                                        formData1.append("carpetSize", floorPlans[index].carpetSize);
                                        formData1.append("price", floorPlans[index].price);
                                        formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                                        formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                                        formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                                        formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                                    }
                                    if (propertyType.toLowerCase() == "flats / apartment" && (subProperty.toLowerCase() == "high rise" || subProperty.toLowerCase() == "low rise")) {
                                        formData1.append("unitType", floorPlans[index]?.unitType);
                                        formData1.append("carpetSize", floorPlans[index].carpetSize);
                                        formData1.append("price", floorPlans[index].price);
                                        formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                                        formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                                        formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                                        formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                                    }
                                    dispatch(addFloorPlanInProperty(formData1, async (data) => {
                                        //in the last add brokerage in the property
                                        payload.id = propertyID;
                                        dispatch(
                                            updateBrokerageDetails(payload, (data) => {
                                                enqueueSnackbar("Data added succesfully", { variant: 'success' });
                                                // once all the data has been added clear the local storage
                                                localStorage.removeItem("formData");
                                                localStorage.removeItem("floorplans");
                                                localStorage.removeItem("floorPlanData");
                                                navigate("/property-management");
                                            })
                                        )
                                    }));
                                    setIsLoading(false)
                                }
                            }


                        } else {
                            console.log("We are here");
                            enqueueSnackbar(data.message, { variant: 'error' });
                            setIsLoading(false)
                        }
                        setIsLoading(false)
                    }, (err) => {
                        enqueueSnackbar(err.message, { variant: 'error' });
                        setIsLoading(false)
                    })
                );
            }
        }


        let payload = {
            id: propertyID,
            brokerageType: brokerageValueType,
            brokerageValue,
            milestonesTermsConditions: specifications
        }





        // dispatch(
        //     updateBrokerageDetails(payload,(data)=>{
        //         enqueueSnackbar("Data added succesfully", {variant: 'success'});
        //         navigate("/property-management");
        //     })
        // )
    }

    return (
        <>
            <Box sx={{ marginTop: '73px' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <label>Brokerage Type</label>
                            <FormControl sx={{ display: 'block' }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Amount"
                                    name="radio-buttons-group"
                                    row
                                    onChange={(e) => setBrokerageValueType(e.target.value)}
                                >
                                    <FormControlLabel value="Percentage" control={<Radio />} label="Percentage %" />
                                    <FormControlLabel value="Amount" control={<Radio />} label="Amount" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label>Brokerage Value {brokerageValueType === "Percentage" ? "(%)" : ""}</label>
                            <input className='inputBox' placeholder="Enter Amount" value={brokerageValue} onChange={(e) => setBrokerageValue(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ color: '#4C535F', fontWeight: '700', fontSize: '20px' }}>
                                Milestones
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <label>Milestone Terms</label>
                            <input className='inputBox' placeholder="Condition" value={specification} onChange={(e) => setSpecification(e.target.value)} />
                        </Grid>
                        <Grid item xs={7}>
                            <label>Brokerage %</label>
                            <input className='inputBox' placeholder="brokerage" value={quanitity} onChange={(e) => setQuantity(e.target.value)} />
                        </Grid>
                        <Grid item xs={1}>
                            <p onClick={handleCreatePlan} style={{ borderRadius: '50%', background: '#278FD9', color: '#FFFFFF', fontWeight: '600', padding: '1px 16px', fontSize: '35px', width: '54px', height: '54px', marginTop: '25px', cursor: 'pointer' }}>+</p>
                        </Grid>
                        <Grid item xs={12}>
                            {specifications.length > 0 ? (
                                <Table sx={{ borderRadius: '11px', borderCollapse: 'unset', border: '1px solid #e2e2e2' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Milestone Terms</TableCell>
                                            <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Brokerage %</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {specifications.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell Cell align="center" sx={index == specifications.length - 1 ? { borderBottom: 'none', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                    {tempIndex === index ? (
                                                        <input
                                                            value={tempSpecification}
                                                            onChange={(e) => setTempSpecification(e.target.value)}
                                                            className="inputBox"
                                                            type="text"
                                                            placeholder="Condition"
                                                        />
                                                    ) : (
                                                        item.condition
                                                    )}
                                                </TableCell>
                                                <TableCell align="center" sx={index == specifications.length - 1 ? { borderBottom: 'none', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                    {tempIndex === index ? (
                                                        <input
                                                            value={tempQuantity}
                                                            onChange={(e) => setTempQuantity(e.target.value)}
                                                            className="inputBox"
                                                            type="text"
                                                            placeholder="Brokerage"
                                                        />
                                                    ) : (
                                                        item.brokerage
                                                    )}
                                                </TableCell>
                                                <TableCell sx={index == specifications.length - 1 ? { borderBottom: 'none' } : {}}>
                                                    <p style={{ textAlign: 'center' }}>
                                                        {tempIndex === index ? (
                                                            <Done
                                                                onClick={() => handleUpdatePlan(index)}
                                                                sx={{ cursor: 'pointer' }}
                                                            />
                                                        ) : (
                                                            <ModeEditOutline
                                                                onClick={() => handleEditPlan(index)}
                                                                sx={{ cursor: 'pointer' }}
                                                            />
                                                        )}
                                                        <Delete
                                                            onClick={() => handleRemoveFromPlan(index)}
                                                            sx={{ color: '#EA000D', cursor: 'pointer' }}
                                                        />
                                                    </p>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                    <br />
                    {isLoading ?
                        <Button disabled type="submit" style={{ width: '204px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '16px', marginBottom: '33px' }}><CircularProgress size={24} sx={{ color: '#fff' }} /></Button>
                        :
                        <Button type="submit" style={{ width: '204px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '16px', marginBottom: '33px' }}>Add</Button>
                    }
                    <Button onClick={() => navigate('/property-management/add-property')} variant="outlined" style={{ width: '204px', height: '52px', borderRadius: '8.62px', fontWeight: '500', fontSize: '16px', marginBottom: '33px', marginLeft: '10px', marginRight: '10px' }}>Reset</Button>
                    <Button onClick={() => navigate('/property-management/floor-Plan')} style={{ background: '#E2E2E2', color: '#69748C', width: '204px', height: '52px', borderRadius: '8.62px', fontWeight: '500', fontSize: '16px', marginBottom: '33px', marginLeft: '10px', marginRight: '10px' }}>Back</Button>
                </form>
            </Box>
        </>
    )
};

export default BrokerageForm;
