import React, { useState } from "react";
import { Lock, Mail, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import common from "../utils/common";
import regex from "../utils/regex";


const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [tempConfirmPassword, setTempConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isRemembermeChecked, setIsRememberMeChecked] = useState(false);
    const [emailError, setEmailError] = React.useState(false);


    const handleShowPassword = () => {
        if (showPassword) {
            setPassword(common?.replaceWithAsterisks(tempPassword));
        } else {
            setPassword(tempPassword);
        }
        setShowPassword(!showPassword);
    }

    const handleShowConfirmPassword = () => {
        if (showConfirmPassword) {
            setConfirmPassword(common?.replaceWithAsterisks(tempPassword));
        } else {
            setConfirmPassword(tempPassword);
        }
        setShowConfirmPassword(!showConfirmPassword);
    }

    // Regex
    
    React.useEffect(() => {
        if (email) {
            const isEmailValid = regex.emailPattern.test(email)
            setEmailError(!isEmailValid)

        }
    }, [email])

    return (
        <>
            <Paper sx={{ margin: '2rem', height: '60rem' }} elevation={4}>
                <Grid container>
                    <Grid item md={6}>
                        <Box sx={{ margin: '2rem 5rem 0' }} >
                            <img src="/logo.png" alt="NestoHub" />
                            <Typography sx={{ fontWeight: '500', fontSize: '2.20rem', lineHeight: '3.25rem', marginTop: '8rem' }}>
                                Forgot Password
                            </Typography>
                            <Typography sx={{ fontWeight: '500', fontSize: '1.20rem', lineHeight: '1.75rem', marginTop: '0.5rem' }}>
                                If you are remember your password
                            </Typography>
                            <Typography sx={{ fontWeight: '500', fontSize: '1.20rem', lineHeight: '1.75rem', marginTop: '0.125rem', marginBottom: '3.5rem' }}>
                                Go back <Link to="/" style={{ textDecoration: 'none', color: '#278FD9', marginLeft: '0.625rem' }}>Login Here!</Link>
                            </Typography>
                            <form>
                                <Typography sx={{ marginTop: '3.5rem', marginBottom: '0.5rem', fontSize: '0.9rem', color: '#999999', lineHeight: '1.43rem', fontWeight: '500' }}>
                                    Email
                                </Typography>
                                <TextField
                                    id="standard-textarea"
                                    placeholder="Enter your email address"
                                    multiline
                                    required
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Mail />
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ width: '31.25rem' }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {emailError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}

                                <Typography sx={{ marginTop: '3.5rem', marginBottom: '0.5rem', fontSize: '0.9rem', color: '#999999', lineHeight: '1.43rem', fontWeight: '500' }}>
                                    Password
                                </Typography>
                                <TextField
                                    id="password"
                                    placeholder="Enter your password"
                                    multiline
                                    required
                                    type={"password"}
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end" sx={{ cursor: 'pointer' }} onClick={handleShowPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ width: '31.25rem' }}
                                    value={password}
                                    onChange={(e) => {
                                        if (showPassword) {
                                            setPassword(e.target.value);
                                        } else {
                                            var temp = e.target.value;
                                            setPassword(common?.replaceWithAsterisks(temp));
                                        }
                                        setTempPassword(tempPassword + e.target.value.replaceAll("*", ""));
                                    }}
                                />
                                <Typography sx={{ marginTop: '3.5rem', marginBottom: '0.5rem', fontSize: '0.9rem', color: '#999999', lineHeight: '1.43rem', fontWeight: '500' }}>
                                    Confirm Password
                                </Typography>
                                <TextField
                                    id="confirmPassword"
                                    placeholder="Confirm your password"
                                    multiline
                                    required
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Lock />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="start" sx={{ cursor: 'pointer' }} onClick={handleShowConfirmPassword}>
                                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                            </InputAdornment>
                                        )
                                    }}
                                    sx={{ width: '31.25rem' }}
                                    value={confirmPassword}
                                    onChange={(e) => {
                                        if (showConfirmPassword) {
                                            setConfirmPassword(e.target.value);
                                        } else {
                                            var temp = e.target.value;
                                            setConfirmPassword(common?.replaceWithAsterisks(temp));
                                        }
                                        setTempConfirmPassword(tempConfirmPassword + e.target.value.replaceAll("*", ""));
                                    }}
                                />
                                <Box sx={{ marginTop: '1.25rem' }}>
                                    <FormControlLabel control={<Checkbox value={isRemembermeChecked} onChange={(e) => { setIsRememberMeChecked(e.target.checked) }} />} label="Remeber me" />
                                    <span style={{ float: 'right', marginRight: '4.2rem', marginTop: '0.7rem' }}>Forgot Password?</span>
                                </Box>
                                <Button variant="contained" sx={{ marginTop: '4.25rem', width: '30.875rem', height: '3.1875', boxShadow: '0px 4.60889px 29.9578px rgba(0, 0, 0, 0.25)', borderRadius: '2.625rem' }}>Verify</Button>
                            </form>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <img src="/LoginBanner.png" alt="NestoHub" style={{ marginTop: '2rem', marginRight: '2rem', width: '96%' }} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}

export default ForgotPassword;