import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Stack,
    Button,
    IconButton,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
} from '@mui/material';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';
import SearchFilter from '../../assets/SearchFilter';
import InputStyle from '../../assets/InputStyle';
import Layout from '../../assets/Layout';
import {
    sectionBarBtn,
    selectBar,
    selectBarBtn,
    uplodeImageBtn,
} from '../../Style/stylesheet';
import { DeleteForever, Draw, Edit } from '@mui/icons-material';
import TableStyle from '../../assets/TableStyle';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllRequestPropertybyId, getBuilderDetails, updateRequestProperty } from '../../actions';
import { enqueueSnackbar } from 'notistack';

export default function PropertyEditRequest(id, action) {

    const builderId = useParams();

    const [sectionBarActive, setSectionBarActive] = useState('PropertyEditRequest');

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);


    const loadVisitById = useCallback(() => {
        dispatch(getAllRequestPropertybyId( builderId.id , (data) => {
            setProperties(data?.data);
        }, (err) => {
            console.log(err);
        }))
    }, [dispatch])

    useEffect(() => {
        loadVisitById();
    }, [loadVisitById])

   


  const handleSubmit = (value, id) => {   
      setLoading(true);
      let payload = {      
          id: id,    
       status : value,
      };

      dispatch(updateRequestProperty(payload, (data) => {
        enqueueSnackbar('update property successfully', { variant: 'success' });
        loadVisitById()
        setLoading(false);
      }, (err) => {
        const message = err?.response?.data ? err?.response?.data : 'Error occured While update property';
        enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      }));
   
  };


    const headData = [
        'NAME',
        'LOCATION',
        'PROPERTY TYPE ',
        'ABOUT THE PROECT ',
        'ABOUT THE BUILDER',
        'ACTION',
    ];

    const maxContent = (html, maxWords) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const text = tempDiv.textContent || tempDiv.innerText || '';
        const words = text.split(/\s+/).slice(0, maxWords).join(' ');
        return `${words}...`;
      };

    return (
        <Layout>
            <Stack
                direction={"row"}
                sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
                spacing={3}
            >
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Profile" && "active"}
                >
                    Profile
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Properties" && "active"}
                    onClick={() =>
                        navigate("/builder-management/properties/" + builderId?.id)
                    }
                >
                    Properties
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Invoices" && "active"}
                    onClick={() =>
                        navigate("/builder-management/invoices/" + builderId?.id)
                    }
                >
                    Invoices
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Subscription" && "active"}
                    onClick={() =>
                        navigate("/builder-management/subscription/" + builderId?.id)
                    }
                >
                    Subscription
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Queries" && "active"}
                    onClick={() =>
                        navigate("/builder-management/queries/" + builderId?.id)
                    }
                >
                    Queries
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "PropertyEditRequest" && "active"}
                    onClick={() =>
                        navigate("/builder-management/property-edit-request/" + builderId?.id)
                    }
                >
                    Property Edit Request
                </Button>
            </Stack>

            <SearchFilter my={2}>
                <Box className="search style-1" >
                    <input type="text" placeholder="Search..." />
                </Box>
                <Box className="style-1 filter p-1">
                    <select>
                        <option value="All" selected>
                            All
                        </option>
                        <option value="option 1">option 1</option>
                    </select>
                </Box>
                <Box className="style-1">
                    <input type="date" placeholder="Choose Date" />
                </Box>
            </SearchFilter>
            <Box sx={{ mb: 4 }}></Box>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>
                                    <Checkbox />
                                </TableCell> */}
                                <TableCell>#</TableCell>
                                {headData.map((item, index) => (
                                    <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                        <Typography
                                            sx={{
                                                color: 'mute.darktext',
                                                textTransform: 'uppercase',
                                                fontSize: '0.9rem',
                                            }}
                                        >
                                            {item}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties.map((item, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                >
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>{index + 1}</TableCell>
                                    {/* <TableCell>
                                            <Link
                                                onClick={() => setProfileAction(true)}
                                                className="link"
                                            >
                                                Ann Culhane
                                            </Link>
                                        </TableCell> */}

                                    <TableCell sx={{ color: '#D12953' }}>{item?.name}</TableCell>
                                    <TableCell>{item?.location}</TableCell>{' '}
                                    <TableCell>{item?.propertyType}</TableCell>
                                    <TableCell >
                                    <div dangerouslySetInnerHTML={{ __html: maxContent(item?.aboutProject, 10) }} />

                                    </TableCell>
                                    <TableCell> <div dangerouslySetInnerHTML={{ __html: maxContent(item?.aboutBuilder, 10) }} /></TableCell>


                                    <TableCell>
                                        <Box sx={{ borderRadius: '20px', padding: '0px' }}>
                                            <select value={item?.status} onChange={(e) => handleSubmit(e.target.value, item?._id)}>
                                                <option value="" selected>
                                                    Select
                                                </option>
                                                <option value="Not Approved">Not Approved</option>
                                                <option value="Approved">Approved</option>
                                                <option value="Rejected">Rejected</option>
                                                <option value="Pending">Pending</option>
                                            </select>
                                        </Box>
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Box
                sx={{
                    bgcolor: 'mute.dark',
                    borderRadius: 1,
                    color: 'dark.text',
                    p: 2,
                    mt: 4,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <Typography>1 - 10 of 97</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: ' center',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography component="p">Rows per page:</Typography>
                    </Box>
                    <Box>
                        <select
                            style={{
                                fontSize: '0.8rem',
                                height: '35px',
                                padding: '0',
                                background: 'transparent',
                            }}
                        >
                            <option value="10" selected>
                                10
                            </option>
                            <option value="10" selected>
                                20
                            </option>
                            <option value="10" selected>
                                30
                            </option>
                        </select>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Box>
                                <IconButton
                                    sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                >
                                    <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography variant="text" component={'span'}>
                                    1/10
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    sx={{
                                        border: '1px solid',
                                        borderColor: 'mute.main',
                                        bgcolor: 'white.main',
                                    }}
                                >
                                    <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Box>




        </Layout>
    );
}
