import React from "react";
import Layout from "../../assets/Layout";
import { Box, Stack } from "@mui/system";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { Typography, Grid, Checkbox, FormControlLabel, Button } from '@mui/material';

const BuilderRoles = () => {
    return (
        <Layout path={"roles"}>
            <Box>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Roles & Employees - Builder" />
                </Stack>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Finance Manager</Typography>
                    <Grid container>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                    </Grid>
                    <Typography variant="h6" mt={2}>Property Manager</Typography>
                    <Grid container>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                        <Grid item md={2}>
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Label" />
                        </Grid>
                    </Grid>
                    <Button variant="contained" sx={{mt:10}}>Save</Button>
                </Box>
            </Box>
        </Layout>
    )
}

export default BuilderRoles;