import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Select, MenuItem, Checkbox, Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CancelIcon from '@mui/icons-material/Cancel';
import InputStyle from '../../assets/InputStyle';
import { getAllProperty, getSubscriptionById, updateSubscription } from '../../actions';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';

const EditSubscription = ({ open, setOpen, id }) => {
    // const [open, setOpen] = useState(false);
    const [allVisits, setAllVisits] = React.useState([]);
    const dispatch = useDispatch();
    const [subscriptionName, setSubscriptionName] = useState('');
    const [noOfProperties, setNoOfProperties] = useState([]);
    const [bottomSubliner, setBottomSubliner] = useState('');
    const [subLiner, setSubLiner] = useState('');
    const [bannerDays, setBannerDays] = useState('');
    const [flashDays, setFlashDays] = useState('');
    const [noOfVisits, setNoOfVisits] = useState('');
    const [description, setDescription] = useState('');
    const [colorCode, setColorCode] = useState('');
    const [isRecommended, setIsRecommended] = useState(false);
    const [price, setPrice] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [properties, setProperties] = useState([]);

    const handleCheckboxChange = () => {
        setIsRecommended(prevChecked => !prevChecked);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const headData = [
        'Subscription Id',
        'Builder NAme',
        'No. of Properties ',
        'Date of Purchase',
        'Payment status',
        'Transaction No.',
    ];

    const handleReset = () => {
        setSubscriptionName("")
        setColorCode("")
        setNoOfVisits("")
        setBannerDays("")
        setFlashDays("")
        setDescription("")
        setPrice("")
        setBottomSubliner("")
        setNoOfProperties("")
        setIsRecommended(false)
        setSubLiner("")
    };

    useEffect(() => {
        dispatch(getSubscriptionById({ id }, (data) => {
            setSubscriptionName(data?.data[0]?.subscriptionName)
            setColorCode(data?.data[0]?.colorCode)
            setPrice(data?.data[0]?.price)
            setNoOfVisits(data?.data[0]?.numberOfVisit)
            setBannerDays(data?.data[0]?.bannerDays)
            setFlashDays(data?.data[0]?.flashDays)
            setDescription(data?.data[0]?.description)
            setNoOfProperties(data?.data[0]?.propertyList)
            setNoOfProperties(data?.data[0]?.propertyList)
            setBottomSubliner(data?.data[0]?.bottomSubliner)
            setIsRecommended(data?.data[0]?.isRecommended)
            setSubLiner(data?.data[0]?.subLiner)
            // setSubscription(data.data);
        }))
    }, []);

    const handleSubmit = (id) => {

        // const formData = new FormData();

        // Append broker details to formData
        // formData.append('subscriptionName', subscriptionName);
        // formData.append('colorCode', colorCode);
        // formData.append('numberOfVisits', noOfVisits);
        // formData.append('bannerDays', bannerDays);
        // formData.append('flashDays', flashDays);
        // formData.append('description', description);
        // formData.append('price', price);
        // formData.append('bottomSubliner', bottomSubliner);
        // formData.append('propertyList', noOfProperties);

        let payload =
        {
            id,
            subscriptionName,
            colorCode,
            numberOfVisit: noOfVisits,
            bannerDays,
            flashDays,
            description,
            price,
            bottomSubliner,
            propertyList: noOfProperties,
            isRecommended,
            subLiner
        }


        // Append profile picture to formData

        setIsLoading(true);
        dispatch(

            updateSubscription(id, payload, (data) => {
                setIsLoading(false);
                enqueueSnackbar("Updated Successfully!", { variant: 'success' })

            }, (err) => {
                enqueueSnackbar("Error While Updating Subscription", { variant: 'error' });
            })
        )
        handleClose()

    };

    useEffect(() => {
        dispatch(getAllProperty({}, (data) => {
            console.log(data?.data)
            const filteredProperties = data?.data.filter(item => item.name);
            setProperties(filteredProperties);

        }))
    }, [dispatch]);


    return (
        <div>

            <Dialog
                open={open}
                sx={{
                    borderRadius: '20px', // Set border radius
                }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Subscription Plan Edit</Typography>
                    <IconButton>
                        <CancelIcon onClick={handleClose} />
                    </IconButton>

                </DialogTitle>
                <DialogContent sx={{ height: '100vh', }}>
                    <Grid container spacing={2} mt={1}>
                        <Grid item md={6} flexGrow={1}>
                            <InputStyle name="Subscription Name">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter Name"
                                    value={subscriptionName}
                                    onChange={(e) => { setSubscriptionName(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1}>
                            <InputStyle name="Color Code">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter color code"
                                    value={colorCode}
                                    onChange={(e) => { setColorCode(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="Price">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter price"
                                    value={price}
                                    onChange={(e) => { setPrice(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="Number of Visits">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter visits"
                                    value={noOfVisits}
                                    onChange={(e) => { setNoOfVisits(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="Banner Days">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter banner days"
                                    value={bannerDays}
                                    onChange={(e) => { setBannerDays(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="Flash Days">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter flash days"
                                    value={flashDays}
                                    onChange={(e) => { setFlashDays(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="Description">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter description"
                                    value={description}
                                    onChange={(e) => { setDescription(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="No. of Properties">
                                {/* <select
                                    placeholder="Enter no. of properties"
                                    value={noOfProperties}
                                    onChange={(e) => { setNoOfProperties(e.target.value) }}
                                    style={{ height: '2.4rem', borderRadius: '5px', fontSize: '10px', fontWeight: '600' }}
                                >
                                    <option value="" disabled>Enter number of properties</option>

                                    {properties?.map((item, index) => (
                                        <option key={index} value={item?._id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select> */}

                                <Select
                                    sx={{ height: '2.4rem', borderRadius: '5px', fontSize: '10px', fontWeight: '600', width:'265px' }}
                                    multiple
                                    value={noOfProperties}
                                    onChange={(e) => { setNoOfProperties(e.target.value) }}
                                    // className={classes.select}
                                    renderValue={(selected) => selected.join(', ')} // Display selected options as a comma-separated list
                                >
                                    {properties.map((item, index) => (
                                        <MenuItem key={index} value={item?._id}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name="Bottom Subliner">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter bottom subliner"
                                    value={bottomSubliner}
                                    onChange={(e) => { setBottomSubliner(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>

                        <Grid item md={6} flexGrow={1} mt={1}>
                            <InputStyle name=" Subliner">
                                <input
                                    type="text"
                                    name="subLiner"
                                    placeholder="Enter  subliner"
                                    value={subLiner}
                                    onChange={(e) => { setSubLiner(e.target.value) }}
                                />
                            </InputStyle>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mt: 10 }}>
                        <Checkbox
                            sx={{ ml: -1.5 }}
                            checked={isRecommended}
                            onChange={handleCheckboxChange}
                        />
                        <Typography>Recommended</Typography>
                    </Box>


                    <Box >
                        <Button variant="contained" disableElevation sx={{ px: 5 }} onClick={() => handleSubmit(id)}  >
                            {isLoading === true ? <CircularProgress /> : "Next"}
                        </Button>
                        <Button variant="outlined" disableElevation sx={{ px: 5, ml: 2 }} onClick={handleReset} >
                            Reset
                        </Button>
                    </Box>

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EditSubscription;
