import React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';

export default function InputStyle({ children, name, topSpace }) {
  return (
    <Box
      sx={{
        position: 'relative',
        mt: topSpace ? '0' : '-18px',
        '& textarea': {
          display: 'block',
          width: '100%',
          bgcolor: '#EDF2F7',
          border: '1px solid #E0E4EC',
          height: 150,
          p: 1,
          borderRadius: 2,
          fontFamily: 'Manrope',
        },
      }}
      className="inputStyle"
    >
      <Typography
        variant="text"
        component="label"
        sx={{
          fontWeight: 500,
        }}
      >
        {name}
      </Typography>
      {children}
    </Box>
  );
}
