// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ADMIN_LOGIN: 'v1/admin/login',
  BROKER_SEND_OTP: 'v1/broker/sendOtp',
  BROKER_RESEND_OTP: 'v1/broker/resendOtp',
  BROKER_VERIFY_OTP: 'v1/broker/verifyOtp',
  BUILDER_SEND_OTP: 'v1/builder/sendOtp',
  BUILDER_VERIFY_OTP: 'v1/builder/verifyOtp',
  REGISTER_BROKER: 'v1/broker/registerName',
  REGISTER_BUILDER: 'v1/builder/addShareYourIntent',
  GET_BROKER_DETAILS: 'v1/broker/getBrokerById',
  GET_BUILDER_DETAILS: 'v1/builder/getBuilderById',
  GET_BUILDER_SUBSCRIPTION: 'v1/subscriptionorder/getAllSubscriptionOrder',
  UPDATE_BUILDER_DETAILS: 'v1/builder/updatePersonalInfo',
  GET_ALL_PROPERTIES: 'v1/property/getAllPropertyForAdmin',
  GET_ALL_SOLD_OUT_PROPERTIES:
    'v1/boughtProperty/getAllBoughtPropertyForMobile',
  GET_CURRENT_PACKAGE: 'v1/subscriptionOrder/getAllSubscriptionOrder',
  GET_NOTIFICATIONS: 'v1/notification/getAllNotification',
  UPDATE_PREFERENCES: 'v1/broker/updatePreferences',
  GET_TUTORIAL: 'v1/tutorials/getAllTutorials',
  GET_RAISE_QUERY: 'v1/raiseQuery/getAllRaiseQuery',
  GET_ALL_SUPPORT_FAQ: 'v1/faqAndSupport/getAllFaqAndSupport',
  GET_ALL_CUSTOMER: 'v1/customer/getAllCustomer',
  UPDATE_CUSTOMER: 'v1/customer/updateCustomer',
  ADD_CUSTOMER: 'v1/customer/addCustomer',
  GET_ALL_REQUIREMENT: 'v1/requirement/getAllRequirement',
  GET_TERMS_AND_CONDITIONS:
    'v1/termsAndConditionsBroker/getAllTermsAndConditionsBroker',
  GET_ALL_BUILDER: 'v1/builder/getBuilderById',
  UPDATE_ROLES: 'v1/roles/updateRoles',
  DELETE_ROLE: 'v1/roles/deleteRoles',
  DELETE_PROPERTY: 'v1/property/deleteProperty',
  GET_ALL_VISIT: 'v1/visit/getAllVisit',
  GET_VISIT_BY_ID: 'v1/visit/getVisitById',
  GET_ALL_BANNERS: 'v1/banner/getAllBanner',
  GET_ALL_PROMOTION_BANNERS: 'v1/promotionBanner/getAllPromotionBanner',
  GET_ALL_PROPERTY_CATEGORY: 'v1/propertyCategory/getAllPropertyCategory',
  GET_ALL_BOUGHT_PROPERTY: 'v1/boughtProperty/getAllBoughtProperty',
  GETALLPROPERTY: 'v1/property/getAllproperty',
  GETALLBOUGHTPROPERTY: 'v1/boughtProperty/getAllBoughtProperty',
  GET_PROPERTY_ANALYTICS: 'v1/builder/getPropertyAnalytics',
  GET_PROPERTY_BY_ID: 'v1/property/getPropertyById',
  SHARE_BBROCHURE: 'v1/share/addShare',
  ADD_ROLE: 'v1/roles/addRoles',
  ADD_BUILDER: '/v1/builder/addBuilder',
  ADD_BROKER: 'v1/broker/addBroker',
  GET_ALL_INVOICES_FOR_BUILDER: 'v1/invoice/getAllInvoiceForBuilder',
  UPDATE_BROKER_PERSONAL_INFO: 'v1/broker/updatePersonalInfo',
  UPDATE_BROKER_BANK_INFO: 'v1/broker/updateBankInfo',
  GET_ALL_ELIGIBLE_CLAIMS: 'v1/claim/getAllEligibleClaimsForMobile',
  GET_ALL_CLAIMS: 'v1/claim/getAllClaim',
  GET_ALL_LOAN_QUERIES: 'v1/loanQueryDetails/getAllLoanQueryDetails',
  GET_ALL_FLOOR_PLAN_AND_PRICING: 'v1/property/getAllFloorPlanAndPricing',
  UPDATE_VISIT_SUMMARY: 'v1/visit/visitSummary',
  ADD_VISIT_COMMENT: 'v1/visit/addVisitComment',
  GET_ALL_BROKER_EARNINGS: 'v1/brokerEarnings/getAllBrokerEarningsForMobile',
  GET_DISABLED_TIME: 'v1/visit/getDisabledTime',
  ADD_VISIT: 'v1/visit/addVisit',
  GET_ALL_BROKER_EARNING_BY_BROKER_ID:
    'v1/brokerEarnings/getBrokerEarningsByBrokerId',
  ADD_REQUIREMENT: 'v1/requirement/addRequirement',
  GET_LOCATION_AND_COUNTRY_CODES: 'v1/city/getAllCountryAndCity',
  ADD_BOUGHT_PROPERTY: 'v1/boughtProperty/addBoughtProperty',
  UPDATE_CLAIM_STATUS: 'v1/claim/updateClaimStatusForBroker',
  ADD_LOAN_QUERIES: 'V1/loanQueryDetails/addLoanQueryDetails',
  ADD_VISIT_CLAIM: 'v1/claim/addVisitClaim',
  BOOK_VISIT_VERIFY_OTP: 'v1/visit/verifyOtp',
  GET_VISIT_ALERT: 'v1/visit/getAllVisitAlert',
  GET_ALL_PAYMENT: 'v1/payment/getAllPayment',
  GET_VISIT_ANALYTICS: 'v1/visit/getVisitAnalytics',
  GET_PENDING_INVOICE: 'v1/builder/getPendingInvoice',
  GET_ALL_ROLES: 'v1/roles/getAllRoles',
  ADD_REQUEST_PROPERTY: 'v1/requestProperty/addRequestProperty',
  GET_ALL_REQUEST_PROPERTY: 'v1/requestProperty/getAllRequestProperty',
  UPDATE_REQUEST_PROPERTY: 'v1/requestProperty/updateRequestProperty',
  GET_ALL_AMENITIES: 'v1/amenities/getAllAmenities',
  GET_ALL_BANK: 'v1/bank/getAllBank',
  REQUEST_NEW_PROPERTY: 'v1/requestNewProperty/addRequestNewProperty',
  ADD_RAISE_DISPUTE: 'v1/raiseDispute/addRaiseDispute',
  UPLOAD_DOC: 'v1/globals/docUplaods',
  GET_ALL_DISPUTE_REASONS: 'v1/reason/getAllReason',
  GET_ALL_PRICE_RANGE: 'v1/property/property-price-range',
  PROPERTY_FILTER: 'v1/property/properties-filter-apis',
  GET_ALL_BUILDERS: 'v1/builder/getAllBuilder',
  DELETE_BUILDERS: 'v1/builder/deleteBuilder',
  GET_ALL_PAST_PROPERTIES: 'v1/builderPastProperty/getbuilderPastProperty',
  ADD_PROPERTY: 'v1/property/addPropertyDetails',
  ADD_BUILDER_PAST_PROPERTIES: 'v1/builderPastProperty/addbuilderPastProperty',
  DELETE_PAST_PROPERTY_BY_BUILDER: 'v1/builderPastProperty/deletebuilderPastProperty',
  GET_ALL_PROPERTY_SUB_CATEGORY: 'v1/propertySubCategory/getAllpropertySubCategory',
  GET_ALL_SUB_COMPANIES: 'v1/subCompany/getallsubcompany',
  Add_FLOOR_PLAN_IN_PROPERTY: 'v1/property/addFloorPlanAndPricing',
  UPDATE_BROKERAGE: 'v1/property/updateBrokerageDetails',
  GET_ALL_BROKERS: 'v1/broker/getAllBrokerAdmin',
  ADD_SUBCOMPANY: 'v1/subCompany/addsubcompany',
  DELETE_SUBCOMAPNIES: 'v1/subCompany/deletesubcompany',
  UPDATE_SUBCOMPANY: 'v1/subCompany/updatesubcompany',
  ADD_SUB_PROPERTY_TYPE: 'v1/propertysubCategory/addPropertySubCategory',
  UPDATE_SUB_PROPERTY_TYPE: 'v1/propertysubCategory/updatePropertySubCategory',
  UPDATE_PROPERTY_DETAILS: 'v1/property/updatePropertyDetails',
  DELETE_FLOOR_PLAN_PRICING: 'v1/property/deleteFloorPlanAndPricing?mainId={0}&floorPlanId={1}',
  GET_PROPERTY_CATEGORY_BY_ID: 'v1/propertyCategory/getPropertyCategoryById?id={0}',
  DELETE_BROKER: 'v1/broker/updateBrokerStatus',
  GET_ALL_FURNISHING_DETAILS: 'v1/furnishingDetail/getAllFurnishingDetail',
  GET_FURNISHING_DETAIL_BY_ID: 'v1/furnishingDetail/getFurnishingDetailById?id={0}',
  ADD_FURNISHING_DETAIL: 'v1/furnishingDetail/addFurnishingDetail',
  DELETE_FURNISHING_DETAIL: 'v1/furnishingDetail/deleteFurnishingDetail?id={0}',
  UPDATE_FURNISHING_DETAIL_BY_ID: 'v1/furnishingDetail/updateFurnishingDetail',
  DELETE_SUB_CATEGORY: 'v1/propertysubCategory/deletePropertySubCategory?id={0}',
  GET_ALL_SUBCATEGORIS: 'v1/propertysubCategory/getallPropertysubCategory',
  UPDATE_SUBCATEGORY: 'v1/propertysubCategory/updatePropertySubCategory',
  ADD_LOCATION_ADVANTAGES: 'v1/locationAdvantage/addLocationAdvantage',
  UPDATE_LOCATION_ADVANTAGES: 'v1/locationAdvantage/updateLocationAdvantage',
  DELETE_LOCATION_ADVANTAGES: 'v1/locationAdvantage/deleteLocationAdvantage?id={0}',
  GET_ALL_LOCATION_ADVANTAGES: 'v1/locationAdvantage/getAllLocationAdvantage',
  ADD_AMENTIES: 'v1/amenities/addAmenities',
  DELETE_AMENTIES: 'v1/amenities/deleteAmenities?id={0}',
  UPDATE_AMENTIES: 'v1/amenities/updateAmenities',
  UPDATE_BUILDER: 'v1/builder/updateBuilder',
  UPDATE_PAST_PROPERTY: 'v1/builderPastProperty/updatebuilderPastProperty',
  GET_ALL_INTENTS: 'v1/builder/getAllIntents',
  UPDATE_CLAIM_FOR_ADMIN: 'v1/claim/updateClaim',
  GET_ALL_SPECIFICATIONS: 'v1/specification/getAllSpecification',
  ADD_SPECIFICATIONS: 'v1/specification/addSpecification',
  DELETE_SPECIFICATIONS: 'v1/specification/deleteSpecification',
  UPDATE_SPECIFICATIONS_BY_ID: 'v1/specification/updateSpecification',
  GET_ALL_NEAR_BY_AREA: 'v1/nearByArea/getAllNearByArea',
  ADD_NEAR_BY_AREA: 'v1/nearByArea/addNearByArea',
  DELETE_NEAR_BY_AREA: 'v1/nearByArea/deleteNearByArea',
  UPDATE_NEAR_BY_AREA: 'v1/nearByArea/updateNearByArea',
  GET_ALL_LOAN_APPROVAL: 'v1/bank/getAllBank',
  ADD_LOAN_APPROVAL: 'v1/bank/addBank',
  UPDATE_LOAN_APPROVAL: 'v1/bank/updateBank',
  DELETE_LOAN_APPROVAL: 'v1/bank/deleteBank',
  GET_ALL_VISITS: 'v1/visit/getAllVisit',
  GET_VISITS_BY_ID: 'v1//visit/getVisitById',
  UPDATE_INTENT_STATUS: 'v1/builder/updateIntentStatus',
  UPDATE_PROPERTY_AVAILABILITY: 'v1/property/property/{0}/update-availability',
  UPDATE_FLOOR_PLAN_PRICING: 'v1/property/updateFloorPlanAndPricing',
  ADD_BLOG: 'v1/blog/addblog',
  GET_ALL_BLOG: 'v1/blog/getAllBlog',
  DELETE_BLOG: 'v1/blog/deleteBlog',
  GET_BLOG_BY_ID: 'v1/blog/getBlogBySlug?slug={0}',
  GET_ALL_BLOG_ADMIN: 'v1/blog/getAllBlogAdmin',
  UPDATE_BLOG: 'v1/blog/updateBlog',
  ADD_TUTORIAL: 'v1/tutorials/addtutorials',
  GET_ALL_TUTORIAL: 'v1/tutorials/getAllTutorialAdmin',
  GET_TUTORIAL_BY_ID: 'v1/tutorials/getTutorialsById?id={0}',
  UPDATE_TUTORIAL: 'v1/tutorials/updatetutorials',
  GET_CONSUMERS_CLIENT_ID: 'v1/customer/getAllCustomer',
  GET_CLIENT_DETAIL_BY_ID: 'v1/customer/getCustomerById',
  GET_CLAIMS_BY_ID: 'v1/claim/getClaimById',
  UPDATE_VISIT_BY_ID: 'v1/visit/updateVisitById',
  GET_ALL_REFERALS: 'v1/refer/getAllReferalEarnings',
  GET_ALL_REFERAL_PAYMENT: 'v1/refer/getAllReferalPayments',
  UPDATE_REFERAL_PAYMENT: 'v1/refer/updateReferalPayment',
  UPDATE_REFERAL_BY_ID: 'v1/refer/updateReferalEarnings',
  GET_ALL_SOLD_PROPERTY_BY_BROKER_ID: 'v1/boughtProperty/getAllBoughtProperty',
  ADD_SUBSCRIPTION:'v1/subscription/addSubscription',
  DELETE_SUBSCRIPTION:'v1/subscription/deleteSubscription',
  GET_ALL_SUBSCRIPTION:'v1/subscription/getAllSubscription',
  UPDATE_SUBSCRIPTION:'v1/subscription/updateSubscription',
  GET_SUBSCRIPTION_BY_ID:'v1/subscription/getSubscriptionById',
  GET_SUBSCRIBERS_BY_SUBSCRIPTION_ID:'v1/subscriptionorder/getAllSubscriptionOrder',
  GET_ALL_QUERIES:'v1/raiseQuery/getAllRaisequery',
  UPDATE_QUERIES:'v1/raiseQuery/updateRaiseQuery',
  GET_ALL_FAQ:'v1/faqAndSupport/getAllfaqandsupport',
  GET_FAQ_BY_ID:'v1/faqAndSupport/getFaqAndSupportById',
  UPDATE_FAQ:'v1/faqAndSupport/updateFaqAndSupport',
  ADD_FAQ:'v1/faqAndSupport/addFaqAndSupport',
  GET_ALL_DSA:'v1/dsa/getAllDSa',
  ADD_DSA:'v1/dsa/adddsa',
  GET_US_CONTACT_US: 'v1/contactus/getallcontactus',
  ADD_INVOICE: 'v1/Invoice/addInvoice',
  UPDATE_INVOICE: 'v1/Invoice/updateInvoice',
  ADD_PAYMENT: 'v1/payment/addPayment',
  GET_ALL_GLOBAL_BUILDER: 'v1/globals/search/builders',
  GET_INVOICE_PDF: 'v1/claim/getClaimInvoicePdfView',
  GET_CLIAM_PDF: 'v1/claim/getClaimPaymentView',
  GET_PAYMENT_PDF: 'v1/claim/getPaymentPdfView',
  UPDATE_CONTACT_STATUS: 'v1/contactus/updateContactus',
  ADMIN_EMPLOYEE: 'v1/adminEmployees',
  DELETE_QNA_BY_ID: 'v1/faqAndSupport/deleteFaqAndSupport',
  ADD_REFERAL_PAYMENT: 'v1/refer/addReferalPayment'
};
