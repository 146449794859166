import { Card, CardActionArea } from '@mui/material';
import React from 'react';
import { uplodeImageBtn } from '../Style/stylesheet';

export default function UploadDataCard({ children }) {
  return (
    <Card sx={uplodeImageBtn} >
      <CardActionArea className="button">{children}</CardActionArea>
    </Card>
  );
}
