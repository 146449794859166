import Layout from "../../assets/Layout";
import { getAllIntentsForBuilder, updateIntentStatus } from '../../actions/index'
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
    Switch,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { React, useEffect, useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import IntentsRejection from "./IntentsRejection";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

const RaisedDisputeQuestion = () => {
    const dispatch = useDispatch();
    const [sectionBarActive, setSectionBarActive] = useState('Intents')
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const [intentStatus,setIntentStatus]=useState('')
    const [intentId,setIntentId]=useState('')
    const [allintents, setAllIntents] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    // const [value, setValue] = React.useState < number | null > (2);

    const headData = [
        'Questions',
         'Action'
    ];

    const handleChange = (e,item) => {
        setIntentStatus(e)
        setIntentId(item)
        if(e.toLowerCase()==="rejected"){
            setAddRemarkAction(true)
        }
        else{
            dispatch(
                updateIntentStatus({id:item,status:e.toLowerCase()},(data)=>{
                    if(data?.status==200){
                        enqueueSnackbar(data?.data?.message,{variant:'success'});
                        window.location.reload()
                    }
                    else{
                        enqueueSnackbar(data?.data?.message,{variant:'error'});
                    }
                })
            )
        }
       
        // setStatus(event.target.value);
    };

    useEffect(() => {
        let payload = {
            pageNo: 1, pageSize: 10
        }

        dispatch(getAllIntentsForBuilder(payload, (data) => {
            setAllIntents(data?.data)
        }))
    }, [dispatch])

    useEffect(()=>{
        dispatch(getAllIntentsForBuilder({pageNo: 1, pageSize: 10}, (data)=>{
            
            const filterIntents = data.data.filter((intent)=>{

                // by id
                if(intent?._id.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                // by name
                if(intent?.name.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                // by phone number
                if(intent?.phoneNumber.includes(searchQuery)){
                    return true
                }

                // by email address
                if(intent?.email.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                // by type of property
                if(intent?.typeOfProperty.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                // by location of property
                if(intent?.locationProperty.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                // by project name
                if(intent?.projectName.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                // by brief description
                if(intent?.description.toLowerCase().includes(searchQuery?.toLocaleLowerCase())){
                    return true
                }

                return false
                
            })

            setAllIntents(filterIntents)
        }))
    },[searchQuery]) // currentPage, countPerPage, searchQuery

    return (
        <>
            <Layout path="raised-dispute-question">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Raised Dispute Question" />
               
                </Stack>




                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e)=>setSearchQuery(e.target.value)} />
                    </Box>
                </SearchFilter>

                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} sx={{ minWidth: '220px', width: 'maxContent' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {/* {
                            [...Array(20)].map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        <TableCell>{index+1}</TableCell>
                                        
                                        <TableCell>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy </TableCell>
                                        <TableCell>
                                        <Switch defaultChecked />
                                        </TableCell>
                                    </TableRow>
                              ))
                            } */}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        sx={{
                            bgcolor: 'mute.dark',
                            borderRadius: 1,
                            color: 'dark.text',
                            p: 2,
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Typography>1 - 10 of 97</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: ' center',
                                alignItems: 'center',
                            }}
                        >
                            <Box>
                                <Typography component="p">Rows per page:</Typography>
                            </Box>
                            <Box>
                                <select
                                    style={{
                                        fontSize: '0.8rem',
                                        height: '35px',
                                        padding: '0',
                                        background: 'transparent',
                                    }}
                                >
                                    <option value="10" selected>
                                        10
                                    </option>
                                    <option value="10" selected>
                                        20
                                    </option>
                                    <option value="10" selected>
                                        30
                                    </option>
                                </select>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Box>
                                        <IconButton
                                            sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                        >
                                            <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Typography variant="text" component={'span'}>
                                            1/10
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            sx={{
                                                border: '1px solid',
                                                borderColor: 'mute.main',
                                                bgcolor: 'white.main',
                                            }}
                                        >
                                            <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>

                </Paper>
            </Layout>
        </>
    );
}

export default RaisedDisputeQuestion;