import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import SearchFilter from "../../assets/SearchFilter";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch } from "react-redux";
import { getAllreferals } from "../../actions";
import ReferRemarks from "./ReferRemarks";

export default function Refer() {
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [referals, setReferals] = useState([]);
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [addRemarkAction, setAddRemarkAction] = useState(false);
  const [claimId, setClaimId] = useState("");
  const [remark, setRemark] = useState("");
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  const [allReferals, setAllReferals] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      dispatch(
        getAllreferals(
          {},
          (data) => {
            const filter = data?.data.filter((item) => {
              if (
                item?.refererId?._id
                  ?.toLowerCase()
                  ?.includes(searchQuery?.toLowerCase())
              ) {
                return true;
              }

              if (
                item?.BrokerId?._id
                  ?.toLowerCase()
                  ?.includes(searchQuery?.toLowerCase())
              ) {
                return true;
              }
              if (
                new Date(item?.createdAt)
                  .toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                  .includes(searchQuery)
              ) {
                return true;
              }
              if (
                new Date(item?.referDate)
                  .toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })
                  .includes(searchQuery)
              ) {
                return true;
              }

              if (
                item?.BrokerPhoneNumber.includes(searchQuery?.toLowerCase())
              ) {
                return true;
              }

              if (item?._id.includes(searchQuery?.toLowerCase())) {
                return true;
              }
              return false;
            });
            if (searchQuery) {
              setReferals(filter);
            }
            setAllReferals(data.data);
          },
          (err) => {
            console.log(err);
          }
        )
      );
    }
  }, [searchQuery, addRemarkAction]);

  useEffect(() => {
    dispatch(
      getAllreferals(
        { pageSize: countPerPage, pageNo: currentPage },
        (data) => {
          if (searchQuery == "") {
            setReferals(data.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [currentPage, countPerPage, searchQuery, addRemarkAction]);

  const getValidUptoDate = (date) => {
    let modifiedDate = new Date(date);
    modifiedDate.setFullYear(new Date(date).getFullYear() + 1);
    return modifiedDate.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <Layout path={"refer"}>
      <Box>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Broker Management : Refer" />
          {/* <ButtonController>
            <Box>
              <IconButton className="iconBtn">
                <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
              </IconButton>
            </Box>
            <Box>
              <Typography variant="text" component={"span"}>
                <strong>Jun 24, 2022</strong> Today
              </Typography>
            </Box>
            <Box>
              <IconButton className="iconBtn">
                <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
              </IconButton>
            </Box>
            <Box onClick={() => {}}>
              <MainButton>
                <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Broker
              </MainButton>
            </Box>
          </ButtonController> */}
        </Stack>

        <SearchFilter>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </SearchFilter>

        <Box sx={{ mb: 4 }}></Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                    <Checkbox />
                  </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {referals.map((item, index) => (
                  <TableRow sx={{ bgcolor: "#f9fafc" }} key={index}>
                    {/* <TableCell align="left">
                        <Checkbox />
                      </TableCell> */}
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell>
                      {item?.refererId ? item?.refererId?._id : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.BrokerId ? item?.BrokerId?._id : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.BrokerPhoneNumber ? item?.BrokerPhoneNumber : "--"}
                    </TableCell>
                    <TableCell>
                      {new Date(item.createdAt).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </TableCell>
                    <TableCell>{getValidUptoDate(item.createdAt)}</TableCell>
                    <TableCell>
                      ₹ {item?.amount ? item?.amount : "--"}
                    </TableCell>
                    <TableCell sx={{ color: "#278FD9" }}>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setOpen(true);
                          setTransactionHistory(item.transactionHistory);
                        }}
                      >
                        view
                      </span>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          setClaimId(item?._id);
                          setAddRemarkAction(true);
                          setRemark(item?.remark);
                        }}
                      >
                        <Draw onClick={() => {}} sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allReferals?.length ? allReferals?.length : currentPage * countPerPage} of {allReferals?.length}</Typography> */}
            <Typography>
              {allReferals?.length > 0
                ? (currentPage - 1) * countPerPage + 1
                : 0}{" "}
              -{" "}
              {countPerPage > allReferals?.length
                ? allReferals?.length
                : currentPage * countPerPage > allReferals?.length
                ? allReferals?.length
                : currentPage * countPerPage}{" "}
              of {allReferals?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      currentPage === 1 || countPerPage > allReferals?.length
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      background:
                        currentPage === 1 || countPerPage > allReferals?.length
                          ? "#EFEFEF"
                          : "#FFF",
                    }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/
                    {Math.ceil(allReferals?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > allReferals?.length ||
                      currentPage ==
                        Math.ceil(allReferals?.length / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        size="sm"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: 900, fontSize: "1.5rem", letterSpacing: ".04rem" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Transaction history</span>
            <IconButton
              aria-label="close"
              sx={{
                border: "1px solid #DFDFE6",
                height: "25px",
                width: "25px",
              }}
              onClick={() => setOpen(false)}
            >
              <ClearIcon
                sx={{
                  color: "black",
                  fontSize: "1rem",
                }}
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {transactionHistory.length > 0 ? (
            <Table
              sx={{ borderCollapse: "separate", borderSpacing: "0px 12px" }}
            >
              <TableHead>
                <TableRow
                  sx={{
                    boxShadow:
                      "0px 3.0399999618530273px 13.300000190734863px 0px #00000012",
                    borderRadius: "10px",
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>Claim ID</TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>Amount</TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      boxShadow:
                        "0px 3.0399999618530273px 13.300000190734863px 0px #00000012",
                      borderRadius: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <TableCell sx={{ borderBottom: "none" }}>
                      {item[0]?.claimId}
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      ₹ {item[0]?.amount}
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      {item[0]?.date}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography align="center">No transaction history found</Typography>
          )}
        </DialogContent>
      </Dialog>
      <ReferRemarks
        remark={remark}
        setRemark={setRemark}
        claimId={claimId}
        addRemarkAction={addRemarkAction}
        setAddRemarkAction={setAddRemarkAction}
      />
    </Layout>
  );
}

const headData = [
  "REFER by ID",
  "refer to id",
  "mobile number",
  "onboarding date",
  "valid upto date",
  "refer amount",
  "transaction history",
  "remarks",
];
