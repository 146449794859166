import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../assets/Layout";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addLocationAdvantages,
  deleteLocationAdvantage,
  getAllLocationAdvantages,
  updateLocationAdvantage,
  uploadDocToS3,
} from "../../actions";
import { enqueueSnackbar } from "notistack";
import { Check, DeleteForever, Download, Draw } from "@mui/icons-material";
import TableStyle from "../../assets/TableStyle";
import UploadDataBox from "../../assets/UploadDataBox";
import UploadData from "../../assets/UploadData";
import UploadDataCard from "../../assets/UploadDataCard";

export default function LocationAdvantages() {
  const [location, setLocation] = useState("");
  const [distance, setDistance] = useState("");
  const [icon, setIcon] = useState("");
  const [iconPicView, setIconPicView] = useState([]);
  const [allAdvantages, setAllAdvantages] = useState([]);
  const [tempIndex, setTempIndex] = useState("");
  const [tempIcon, setTempIcon] = useState([]);
  const [tempIconPicView, setTempIconPicView] = useState([]);
  const [tempName, setTempName] = useState("");
  const [tempDistance, setTempDistance] = useState("");

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "26.3%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  const dispatch = useDispatch();

  const loadAllLocationAdvantages = useCallback(() => {
    dispatch(
      getAllLocationAdvantages({}, (data) => {
        setAllAdvantages(data);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllLocationAdvantages();
  }, [loadAllLocationAdvantages]);

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setIcon(file);
    setIconPicView([URL.createObjectURL(file)]);
    // if (file) {
    //     const formData = new FormData();
    //     formData.append('documents', file);
    //     dispatch(uploadDocToS3(formData, (data) => {
    //         console.log("Token", data)
    //     }))
    // }
  };

  const handleTempUploadImage = (e) => {
    const file = e.target.files[0];
    setTempIcon(file);
    setTempIconPicView([URL.createObjectURL(file)]);
    // if (file) {
    //     const formData = new FormData();
    //     formData.append('documents', file);
    //     dispatch(uploadDocToS3(formData, (data) => {
    //         console.log("Token", data)
    //     }))
    // }
  };

  const handleDelete = (index) => {
    dispatch(
      deleteLocationAdvantage(allAdvantages[index]._id, {}, (data) => {
        loadAllLocationAdvantages();
      })
    );
  };

  const handleEdit = (index) => {
    setTempIndex(index);
    setTempIconPicView([allAdvantages[index].image]);
    setTempName(allAdvantages[index].name);
    setTempDistance(allAdvantages[index].distance);
  };

  const handleUpdate = (index) => {
    let val = allAdvantages;
    val[index] = {
      ...val[index],
      name: tempName,
      distance: tempDistance,
      image: tempIconPicView,
    };
    let formData = new FormData();
    formData.append("id", val[index]._id);
    formData.append("name", tempName);
    formData.append("distance", tempDistance);
    formData.append("image", tempIcon);
    dispatch(
      updateLocationAdvantage(formData, (data) => {
        loadAllLocationAdvantages();
      })
    );
    setTempIndex("");
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!icon || !location || !distance) {
      enqueueSnackbar("Please add all the fields", { variant: "error" });
    } else {
      const formData = new FormData();
      formData.append("image", icon);
      formData.append("name", location);
      formData.append("distance", distance);
      dispatch(
        addLocationAdvantages(formData, (data) => {
          enqueueSnackbar("Data added successfully", { variant: "success" });
          loadAllLocationAdvantages();
          setLocation("");
          setIcon("");
          setIconPicView("");
          setDistance("");
        })
      );
    }
  }

  return (
    <Layout>
      <Paper>
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "28px",
                  color: "#000000",
                  marginBottom: "48px",
                }}
              >
                Location Advantages
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>Location Name</label>
                    <input
                      onChange={(e) => setLocation(e.target.value)}
                      value={location}
                      className="inputBox"
                      type="text"
                      placeholder="Location Name*"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Distance</label>
                    <input
                      onChange={(e) => setDistance(e.target.value)}
                      value={distance}
                      className="inputBox"
                      type="text"
                      placeholder="Distance"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UploadDataBox>
                      <UploadData name={"Icon"} mt={0}>
                        <UploadDataCard>
                          <img
                            src={
                              iconPicView && iconPicView.length > 0
                                ? iconPicView[0]
                                : "/uploadImg.png"
                            }
                            alt="upload"
                            className="img-fluid"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleUploadImage(e)}
                          />
                        </UploadDataCard>
                      </UploadData>
                    </UploadDataBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ padding: "13px", mt: 5 }}
                      fullWidth
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item md={7} my={5}>
              <TableStyle>
                <tr>
                  <th>Icon</th>
                  <th>Location Name</th>
                  <th>Location Distance</th>
                  <th>Action</th>
                </tr>
                <tbody>
                  {allAdvantages.map((item, index) => (
                    <>
                      {item.isDeleted ? (
                        ""
                      ) : (
                        <tr key={index}>
                          <td>
                            {tempIndex === index ? (
                              <UploadDataBox>
                                <UploadData name={"Icon"} mt={0}>
                                  <UploadDataCard>
                                    <img
                                      src={
                                        tempIconPicView &&
                                        tempIconPicView.length > 0
                                          ? tempIconPicView[0]
                                          : "/uploadImg.png"
                                      }
                                      alt="upload"
                                      className="img-fluid"
                                    />
                                    <IconButton
                                      aria-label="clear_image"
                                      sx={{
                                        position: "absolute",
                                        zIndex: 2,
                                        right: "0px",
                                        top: "5px",
                                        background: "#278FD9aa",
                                        height: "25px",
                                        width: "25px",
                                      }}
                                    >
                                      <a
                                        href={tempIconPicView}
                                        target="_blank"
                                        download
                                      >
                                        <Download
                                          color={"white"}
                                          sx={{ fontSize: ".8rem" }}
                                        />
                                      </a>
                                    </IconButton>
                                    <input
                                      type="file"
                                      accept="image/*"
                                      onChange={(e) => handleTempUploadImage(e)}
                                    />
                                  </UploadDataCard>
                                </UploadData>
                              </UploadDataBox>
                            ) : (
                              <img
                                src={item.image}
                                alt="Bank Icon"
                                className="icon"
                              />
                            )}
                          </td>
                          <td>
                            {tempIndex === index ? (
                              <input
                                onChange={(e) => setTempName(e.target.value)}
                                value={tempName}
                                className="inputBox"
                                type="text"
                                placeholder="Location Name*"
                              />
                            ) : (
                              item.name
                            )}
                          </td>
                          <td>
                            {tempIndex === index ? (
                              <input
                                onChange={(e) =>
                                  setTempDistance(e.target.value)
                                }
                                value={tempDistance}
                                className="inputBox"
                                type="text"
                                placeholder="Location Name*"
                              />
                            ) : (
                              item.distance
                            )}
                          </td>
                          <td>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              justifyContent={"center"}
                            >
                              <div>
                                {tempIndex === index ? (
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => handleUpdate(index)}
                                  >
                                    <Check />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => handleEdit(index)}
                                  >
                                    <Draw />
                                  </IconButton>
                                )}
                              </div>
                              <div>
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => handleDelete(index)}
                                >
                                  <DeleteForever />
                                </IconButton>
                              </div>
                            </Stack>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </TableStyle>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
