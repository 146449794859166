import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,

    FormControlLabel,

    FormGroup,

    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';


export default function AddPolicy({ addRemarkAction, setAddRemarkAction }) {

    const [remark, setRemark] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(remark)
    }

    const closeHandler = () => setAddRemarkAction(false);
    const ButtonStyle = {
        maxWidth: 150,
        boxShadow: 0,
        width: '100%',
        marginTop: 4
    };
    return (
        <Dialog
            open={addRemarkAction}
            onClose={closeHandler}
            maxWidth="md"
            fullWidth
        >
            <DialogContent sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                >
                    <div>
                        <Typography variant="title" component="h3">
                            Add New Policy
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            sx={{ border: '2px solid #DFDFE6' }}
                            size="small"
                            onClick={closeHandler}
                        >
                            <Close sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </div>
                </Stack>
                <Box component={'from'} flexGrow={1}>
                    <form onSubmit={handleSubmit}>
                        <textarea value={remark} onChange={(e) => setRemark(e.target.value)} className='inputBox' style={{ resize: 'none', height: '300px', width: '100%' }} placeholder="Write here"></textarea>

                        <Typography variant="h6">Shown In</Typography>

                        <FormGroup row>
                            <FormControlLabel control={<Checkbox />} label="Informative Website" />
                            <FormControlLabel control={<Checkbox />} label="Builder" />
                            <FormControlLabel control={<Checkbox />} label="Broker" />
                        </FormGroup>
                    <Button variant="contained" sx={ButtonStyle} type='submit'>
                        Update
                    </Button>

                    <Button
                        sx={{
                            backgroundColor: '#fff',
                            color: '#278FD9',
                            border: '1px solid #278FD9 ',
                            mt:4,
                            mx:2,
                            maxWidth: 150,
                            '&:hover': {
                                backgroundColor: '#278FD9',
                                color: '#fff',
                               
                            },
                        }}
                    >
                        <AddIcon />Add New
                    </Button>
                </form>
            </Box>
        </DialogContent>
    </Dialog >
  );
}
