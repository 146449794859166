import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography, Grid, Autocomplete, TextField, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { addBuilder, getLocationByInput, uploadDocToS3 } from '../../actions';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import regex from '../../utils/regex';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { Download, LocationSearching } from '@mui/icons-material';
import { useRef } from "react";
import JoditEditor from "jodit-react";
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import ClearIcon from '@mui/icons-material/Clear';


const AddBuilderForm = () => {
    const navigate = useNavigate();
    const [profilePic, setProfilePic] = React.useState(null);
    const [profilePicture, setProfilePictureFiles] = React.useState(null);
    const [document, setDocumentFiles] = React.useState([]);
    const [documentView, setDocumentView] = React.useState([])
    const [logo, setLogoFiles] = React.useState([]);
    const [active, setActive] = React.useState(false);
    const [phoneError, setPhoneError] = React.useState(false);
    const [gstError, setGstError] = React.useState(false);
    const [panError, setPanError] = React.useState(false);
    const [emailError, setEmailError] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [locationSearch, setLocationSearch] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [value1, setValue1] = React.useState('');
    const [locationSelected, setLocationSelected] = React.useState('');
    const [formValues, setFormValues] = React.useState({
        name: '',
        phoneNumber: '',
        email: '',
        description: '',
        companyName: '',
        companyType: '',
        location: '',
        gst: '',
        panOfCompany: '',
        address: '',
    });
    const [value, setValue] = React.useState("");
    const [logoPic, setLogoPic] = React.useState(null);
    const [logoPictureFiles, setLogoPictureFiles] = React.useState(null);


    const editor = useRef(null);


    const handleSetFormValues = (name, value) => {
        if (name === 'location') {
            getLocationByInput({ searchText: value }).then((res) => {
                if (res.data.data.length > 0) {
                    var arr = [];
                    for (let index = 0; index < res.data.data.length; index++) {
                        let element = { label: res.data.data[index].location };
                        arr.push(element);
                    }
                    setLocationSearch(arr);
                }
            }).catch((err) => {
                console.log(err)
            })
        }
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };
    const handleProfileImageChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            setProfilePic(file);
            setProfilePictureFiles(URL.createObjectURL(file));
        }
    };

    const handleLogoImageChange = (event) => {

        const file = event.target.files[0];
        if (file) {
            setLogoPic(file);
            setLogoPictureFiles(URL.createObjectURL(file));
        }
    };
    //handle
    // const handlePdfChange = (e) => {
    //     const file = e.target.files[0];
    //     const reader = new FileReader();

    //     reader.onload = (e) => {
    //         const pdfUrl = e.target.result;
    //         setDocumentFiles(pdfUrl)
    //         setDocumentView(pdfUrl);
    //     };

    //     reader.readAsDataURL(file);
    // };

    const handlePdfChange = (e) => {
        const file = e.target.files;
        if (file.length > 0) {
            for (let index = 0; index < file.length; index++) {
                let formData = new FormData();
                formData.append('documents', file[index]);
                // dispatch(
                //     uploadDocToS3(formData, (data) => {
                //         setDocumentFiles((oldArr) => [...oldArr, data.data.documents[0]]);
                //     })
                // );
                setDocumentFiles((oldArr) => [...oldArr, file[index]]);
                setDocumentView((oldArr) => [...oldArr, URL.createObjectURL(file[index])]);

            }
        }
    };

    // Regex
    React.useEffect(() => {
        if (formValues.phoneNumber) {
            const isPhoneValid = regex.numberPattern.test(formValues.phoneNumber)
            setPhoneError(!isPhoneValid)
        }
    }, [formValues.phoneNumber])


    React.useEffect(() => {
        if (formValues.email) {
            const isEmailValid = regex.emailPattern.test(formValues.email)
            setEmailError(!isEmailValid)

        }
    }, [formValues.email])

    React.useEffect(() => {
        if (formValues.panOfCompany) {
            const isPanValid = regex.panPattern.test(formValues.panOfCompany)
            setPanError(!isPanValid)

        }
    }, [formValues.panOfCompany])

    React.useEffect(() => {
        if (formValues.gst) {
            const isGstValid = regex.gstPattern.test(formValues.gst)
            setGstError(!isGstValid)

        }
    }, [formValues.gst])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (
            !formValues.name ||
            !formValues.phoneNumber ||
            !formValues.companyName ||
            !formValues.email ||
            !value
        ) {
            enqueueSnackbar('Please fill in all the required fields.', { variant: 'warning' });
            return;
        }
        setIsLoading(true);
        // Create a new FormData object
        const formData = new FormData();

        if (profilePic) {
            formData.append('profilePicture', profilePic);
        }

        if (logoPic) {
            formData.append('logo', logoPic);
        }
        for (let i = 0; i < document.length; i++) {
            formData.append('documents', document[i]);
        }

        formData.append('name', formValues.name);
        formData.append('address', locationSelected);
        formData.append('companyName', formValues.companyName);
        formData.append('gst', formValues.gst);
        formData.append('phoneNumber', formValues.phoneNumber);
        formData.append('email', formValues.email);
        formData.append('description', value);
        formData.append('panOfCompany', formValues.panOfCompany);
        formData.append('companyType', formValues.companyType);
        dispatch(
            addBuilder(formData, (data) => {
                if (data.message === "Successfully added new builder") {
                    enqueueSnackbar(data?.message, { variant: 'success' })
                    setIsLoading(false);
                    navigate(`/past-properties/${data?.data?._id}`, { state: { builderId: data?.data?._id } })
                }
                else {
                    enqueueSnackbar(data?.message, { variant: 'error' })
                }
            }, (err) => {
                const message = err?.response?.data?.message ? err?.response?.data?.message : "Error Occurred! Please try again";
                enqueueSnackbar(message, { variant: 'error' });
            })
        )
        setIsLoading(false);

    }




    // const token = localStorage.getItem('token');
    const dispatch = useDispatch();
    const handleUploadBussinessLogoImage = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('documents', file);
            dispatch(uploadDocToS3(formData, (data) => {
                console.log("Token", data)
            }, (err) => {
                console.log(err);
            }))
        }
    }
    const handleUploadProfileImage = (e) => {
        const file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('documents', file);
            dispatch(uploadDocToS3(formData, (data) => {
                console.log("Token", data)
            }, (err) => {
                console.log(err);
            }))
        }
    }
    const handleUploadDocument = (e) => {
        const file = e.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('documents', file);
            dispatch(uploadDocToS3(formData, (data) => {
                console.log("Token", data)
            }, (err) => {
                console.log(err);
            }))
        }
    }

    const handleRemovePropertyBrochure = () => {
        setDocumentView("");
    }

    return (
        <Box sx={{ marginTop: '73px' }}>

            <Grid container>
                <Grid item md={3}>
                    {/* <Box sx={{ py: 2 }}>
                        <Typography>Upload Profile Image</Typography>
                        <label htmlFor="profile-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                            {profilePicture ? (
                                <img className="uploadimg" src={profilePicture} alt="upload" />
                            ) : (
                                <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                            )}
                        </label>
                        <input
                            id="profile-image-upload"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleProfileImageChange}
                        />
                    </Box> */}
                    <UploadData name={"Upload Profile Image"}>
                        <UploadDataCard>
                            <label htmlFor="profile-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                {profilePicture ? (
                                    <img className="uploadimg" src={profilePicture} alt="upload" style={{ width: '140px', height: '140px' }} />
                                ) : (
                                    <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                                )}
                            </label>
                            <input
                                id="profile-image-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleProfileImageChange}
                            />
                        </UploadDataCard>
                    </UploadData>
                </Grid>
                <Grid item md={3}>
                    {/* <Box sx={{ py: 2 }}>
                        <Typography>Upload Your Business Logo</Typography>
                        <label htmlFor="logo-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                            {logoPictureFiles ? (
                                <img className="uploadimg" src={logoPictureFiles} alt="upload" />

                            ) : (
                                <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                            )}
                        </label>
                        <input
                            id="logo-image-upload"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleLogoImageChange}
                        />
                    </Box> */}
                    <UploadData name={'Upload Your Business Logo'}>
                        <UploadDataCard>
                            <label htmlFor="logo-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                {logoPictureFiles ? (
                                    <img className="uploadimg" src={logoPictureFiles} alt="upload" width="200" height="200" />

                                ) : (
                                    <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                                )}
                            </label>
                            <input
                                id="logo-image-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleLogoImageChange}
                            />
                        </UploadDataCard>
                    </UploadData>
                </Grid>
                <Grid item md={3}>
                    {/* <UploadData name={'Upload Property Brochure'}>
                        <Box sx={{ display: 'flex' }}>
                            {documentView &&
                                documentView.map((item, index) => (
                                    <>
                                        <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                            <embed src={item} width="200" height="200" />
                                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                                                <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                            </IconButton> */}
                    {/* <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                                                <a href={item}>
                                                    <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                                                </a>
                                            </IconButton> */}
                    {/* </Box>
                                    </>
                                ))}
                            <UploadDataCard>
                                <img
                                    src={'/uploadImg.png'}
                                    alt="upload"
                                    className="img-fluid"
                                />
                                <input
                                    type="file"
                                    accept=".doc,.pdf"

                                    multiple
                                    onChange={handlePdfChange}
                                />
                            </UploadDataCard>
                        </Box>
                    </UploadData> */}
                    {/* <UploadDataBox>
                        <UploadData name={'Upload Property Brochure'}>
                            {
                                documentView ?
                                    <>
                                        <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                            <embed src={documentView} width="200" height="200" />
                                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '30px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyBrochure}>
                                                <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                            </IconButton>
                                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                                                <a href={documentView} download>
                                                    <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                                                </a>
                                            </IconButton>
                                        </Box>
                                    </>
                                    :
                                    <UploadDataCard>
                                        <img
                                            src={'/Images/uploadImg.png'}
                                            alt="upload"
                                            className="img-fluid"
                                        />
                                        <input
                                            type="file"
                                            accept=".doc,.pdf"
                                            onChange={handlePdfChange}
                                        />
                                    </UploadDataCard>
                            }
                        </UploadData>
                    </UploadDataBox> */}
                </Grid>
            </Grid>
            <Divider sx={{ height: '1.5px', background: '#E0E4EC', marginTop: '73px', marginBottom: '34px' }} />
            <form>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <label>Builder Name*</label>
                        <input required className='inputBox' type="text" placeholder="Enter  builder name" name="name" value={formValues.name} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                        <label>Phone Number*</label>
                        <input required className='inputBox' type="text" placeholder="Enter phone number" value={formValues.phoneNumber} name="phoneNumber" onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} />
                        {phoneError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                    </Grid>
                    <Grid item xs={6}>
                        <label>Email*</label>
                        <input
                            className='inputBox'
                            type="text"
                            placeholder="Enter email"
                            name="email"
                            value={formValues.email}
                            onChange={(e) => {
                                handleSetFormValues(e.target.name, e.target.value);
                            }}
                        />
                        {emailError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                    </Grid>

                    <Grid item xs={6}>
                        <label>Location</label>
                        <Autocomplete
                            onChange={(event, value1) => setLocationSelected(value1?.label)}
                            disablePortal
                            name="location"
                            id="combo-box-demo"
                            options={locationSearch}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField className='inputBox' {...params} value={formValues.location} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} name='location' />}
                        />
                        {/* <input required className='inputBox' type="text" name="location" value={formValues.location} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} placeholder="Enter location" /> */}
                    </Grid>

                    <Grid item xs={6}>
                        <label>Company Name*</label>
                        <input required className='inputBox' type="text" name="companyName" value={formValues.companyName} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} placeholder="Enter company" />
                    </Grid>

                    <Grid item xs={6}>
                        <label>Company Type</label>
                        {/* <input required className='inputBox' type="text" name="companyType" value={formValues.companyType} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} placeholder="Enter company type" /> */}
                        <select value={formValues.companyType} name="companyType" onChange={(e) => handleSetFormValues(e.target.name, e.target.value)}>
                            <option value="" disabled>
                                Company Type
                            </option>
                            <option value="individual">individual</option>
                            <option value="llp">llp</option>
                            <option value="llc">llc</option>
                            <option value="one person">one person</option>
                            <option value="private limited company">private limited company</option>
                            <option value="proprietorship">proprietorship</option>
                            <option value="partnership">partnership</option>
                            <option value="public">public</option>
                            <option value="solo proprietorship">solo proprietorship</option>
                        </select>
                    </Grid>

                    <Grid item xs={6}>
                        <label for="fname">Company GST</label>
                        <input required className='inputBox' type="text" name="gst" value={formValues.gst} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} placeholder="Enter company GST" />
                        {gstError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                    </Grid>

                    <Grid item xs={6}>
                        <label for="fname">Company PAN</label>
                        <input required className='inputBox' type="text" name="panOfCompany" value={formValues.panOfCompany} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} placeholder="Enter company PAN" />
                        {panError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                    </Grid>

                    <Grid item xs={12}>
                        <label for="fname">About the Builder*</label>

                        <JoditEditor
                            ref={editor}
                            onBlur={(newContent) => setValue(newContent)}
                            value={value}
                        />

                    </Grid>
                </Grid>
                <Typography sx={{ fontSize: '19px', color: '#F1323D', fontWeight: '600', marginTop: '44px', marginBottom: '22px' }}>
                    "Fields marked with an asterisk (*) are required."
                </Typography>
                {!emailError && !phoneError && !gstError && !panError ?
                    <Button type="submit" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={handleSubmit}>Add</Button>
                    :
                    isLoading ?
                        <Button disabled type="submit" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#E5E5E5', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={handleSubmit}><CircularProgress size={24} /></Button>
                        :
                        <Button disabled type="submit" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#E5E5E5', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={handleSubmit}>ADD</Button>
                }
            </form>

        </Box>
    );
}

export default AddBuilderForm;
