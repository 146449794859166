import { useCallback, useState } from 'react';
import Layout from '../../assets/Layout';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import SearchFilter from '../../assets/SearchFilter';
import { Link } from 'react-router-dom';
import AddQNA from './addQNA';
import UpdateQNA from './updateQNA';
import { deleteQNAById, getAllFaq, updateFaq } from '../../actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import {

  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CustomDialog from '../CustomDialog';

export default function ManageQandA() {
  const [addQNAAction, setAddQNAAction] = useState(false);
  const [updateQNAAction, setUpdateQNAAction] = useState(false);
  const [FAQ, setFAQ] = useState([]);
  const [faqId, setFaqId] = useState("");
  const headData = ['#', 'Question', 'answer', 'Type', 'file', 'Status', 'Action'];
  const dispatch = useDispatch();
  const [switchState, setSwitchState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState('');
  const [allFaq, setAllFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const handleEdit = (id) => {
    setUpdateQNAAction(true)
    setFaqId(id)
  }

  useEffect(() => {
    dispatch(getAllFaq({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
      const filter = data.data.filter((item) => {


        // if (item?.price.toLowerCase().includes(searchQuery?.toLowerCase())) {
        //     return true
        // }

        if (item?.question?.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }


        if (item?.answer?.includes(searchQuery?.toLowerCase())) {
          return true
        }


        return false;
      })
      console.log("subscription", data.data)
      setFAQ(filter);
    }))
  }, [searchQuery, currentPage, countPerPage]);

  const loadAllFaq = useCallback(() => {
    dispatch(getAllFaq({}, (data) => {
      setAllFaq(data.data);
    }))

  }, [dispatch])

  useEffect(() => {
    loadAllFaq()
  }, [loadAllFaq]);

  const handleUpdate = (id, status) => {
    setSwitchState(!switchState);

    // const selectedFor = Object.keys(checkboxValues)
    //   .filter((key) => checkboxValues[key])
    //   .join(', ');

    const payload =
    {
      id,
      isDeleted: status
    }

    // Append profile picture to formData

    setIsLoading(true);
    dispatch(
      updateFaq(payload, (data) => {
        enqueueSnackbar("FAQ Updated Successfully!", { variant: 'success' })
        setIsLoading(false);
        setTimeout(() => {
          document.location.reload();
        }, 1000);
      }, (err) => {
        enqueueSnackbar("Error While updating FAQ!", { variant: 'error' })
      }
      )
    )
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteQNAById(faqId, (data) => {
      enqueueSnackbar("Employee Deleted Successfuly", { variant: 'success' });
      setFaqId("");
      setLoading(false);
      setTimeout(() => window.location.reload(), 200);
    }, (err) => {
      console.log(err);
      enqueueSnackbar("Error Occured! while deleting employee", { variant: 'error' });
      setLoading(false);
    }))
  }

  return (
    <Layout>
      <Stack direction="row" justifyContent={'space-between'} sx={{ mb: 4 }}>
        <Typography variant="title">Manage Q&A</Typography>
        <div>
          <Button variant="contained" onClick={() => setAddQNAAction(true)}>
            <Add sx={{ mr: 1 }} /> Add New
          </Button>
        </div>
      </Stack>
      <SearchFilter>
        <Box className="search style-1" >
          <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
        </Box>
        {/* <Box className="search style-1">
          <input type="text" placeholder="Builder ID" />
        </Box>
        <Box className="style-1 filter p-1">
          <select>
            <option selected value="" disabled>
              Builder ID
            </option>
            <option value="All">All</option>
            <option value="option 1">option 1</option>
          </select>
        </Box>
        <Box className="style-1">
          <input type="date" placeholder="Choose Date" />
        </Box> */}
      </SearchFilter>

      {/* Table */}
      <Paper sx={{ width: '100%', overflow: 'hidden', mt: 4 }}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox />
                </TableCell>

                {headData.map((item) => (
                  <TableCell align="left">
                    <Typography
                      sx={{
                        color: 'mute.darktext',
                        textTransform: 'uppercase',
                        fontSize: '0.9rem',
                      }}
                    >
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {FAQ.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                >
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>

                  <TableCell>
                    {item.question}
                  </TableCell>
                  <TableCell>
                    {item.answer}
                  </TableCell>
                  <TableCell>
                    {item.type}
                  </TableCell>
                  <TableCell>
                    {item?.supportFile ? <a href={item.file} download>
                      <a href={item.supportFile} target='_blank' download={true}>
                        <InsertDriveFileIcon />
                      </a>
                    </a>
                      : "--"}
                  </TableCell>
                  <TableCell>
                    <Switch checked={item?.isDeleted} onChange={(e) => handleUpdate(item?._id, e.target.checked)} />
                  </TableCell>
                  <TableCell>
                    <IconButton> <Edit onClick={() => handleEdit(item._id)} /></IconButton>
                    <IconButton> <Delete sx={{ cursor: 'pointer', color: '#F90000' }} onClick={() => { setFaqId(item._id); setDeleteConfirmation(true) }} /> </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Box
        sx={{
          bgcolor: "mute.dark",
          borderRadius: 1,
          color: "dark.text",
          p: 2,
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allFaq?.length ? allFaq?.length : currentPage * countPerPage} of {allFaq?.length}</Typography> */}
          <Typography>
            {(currentPage - 1) * countPerPage + 1} -{' '}
            {countPerPage > allFaq?.length
              ? allFaq?.length
              : currentPage * countPerPage > allFaq?.length
                ? allFaq?.length
                : currentPage * countPerPage}{' '}
            of {allFaq?.length}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography> Rows per page:</Typography>
          <select style={{ marginTop: "-5px" }}
            value={countPerPage}
            onChange={(e) => setCountPerPage(e.target.value)}>
            <option value="10" >
              10
            </option>
            <option value="20">
              20
            </option>
            <option value="30">
              30
            </option>
          </select>
          <Box sx={{ ml: 2 }}>
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box>
                <IconButton
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1 || countPerPage > allFaq?.length}
                  sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allFaq?.length ? "#EFEFEF" : "#FFF" }}
                >
                  <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="text" component={"span"}>
                  {currentPage}/{Math.ceil(allFaq?.length / countPerPage)}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={countPerPage > allFaq?.length || currentPage == Math.ceil(allFaq?.length / countPerPage)}
                  sx={{
                    border: "1px solid",
                    borderColor: "mute.main",
                    bgcolor: "white.main",
                  }}
                >
                  <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
      {/* Table */}

      {/*ADD*/}
      <AddQNA addQNAAction={addQNAAction} setAddQNAAction={setAddQNAAction} />
      {/*ADD*/}
      <UpdateQNA
        updateQNAAction={updateQNAAction}
        setUpdateQNAAction={setUpdateQNAAction}
        id={faqId}
      />
      <CustomDialog
        title={""}
        open={deleteConfirmation}
        setOpen={setDeleteConfirmation}
        size={"sm"}
      >
        <Typography sx={{ textAlign: 'center', mb: 2 }}>Are you sure you want to delete the selected Q & A</Typography>
        <Button variant="contained" sx={{ float: 'right' }} onClick={handleDelete}>{loading ? <CircularProgress size={24} /> : "Yes"}</Button>
        <Button variant="outlined" sx={{ float: 'right', marginRight: '10px' }} onClick={() => setDeleteConfirmation(false)}>No</Button>
      </CustomDialog>
    </Layout>
  );
}
