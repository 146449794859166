// eslint-disable-next-line import/no-anonymous-default-export
export default {
    namePattern:  /^[A-Za-z\s]+$/,
    emailPattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    numberPattern: /^[6789]\d{9}$/,
    passwordPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    panPattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    gstPattern:/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
    bankAccountNumberPattern: /^[0-9]{9,18}$/,
    ifscPattern: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
};