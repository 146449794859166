import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../assets/Layout';
import InputStyle from '../../assets/InputStyle';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import TableStyle from '../../assets/TableStyle';
import { Check, DeleteForever, Draw } from '@mui/icons-material';
import { addSpecification, deleteSpecificationDetail, getAllSpecificationDetails, updateSpecificationDetail } from '../../actions';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from "notistack";

export default function Specifications() {
  const dispatch = useDispatch();
  const [name, setName] = useState("")
  const [allSpecificationDetails, setAllSpecificationDetails] = useState([]);
  const [tempIndex, setTempIndex] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempQuantity, setTempQuantity] = useState("");
  
  const loadALLSpecificationDetails = useCallback(() => {
    dispatch(getAllSpecificationDetails({}, (data) => {
      setAllSpecificationDetails(data);
    }))
  }, [dispatch])

  useEffect(() => {
    loadALLSpecificationDetails();
  }, [loadALLSpecificationDetails])
  
  const handleEditSpecifications = (index) => {
    setTempIndex(index);
    setTempName(allSpecificationDetails[index].name);
    setTempQuantity(allSpecificationDetails[index].furnishingQuantity);
  }
  
  const handleUpdateSpecifications = (index) => {
    const val = allSpecificationDetails[index];
    dispatch(updateSpecificationDetail({ id: val._id, furnishingQuantity: tempQuantity, name: tempName }, (data) => {
      enqueueSnackbar("Data updated successfully", { variant: 'success' });
      loadALLSpecificationDetails();
      setTempIndex("");
    }))
  }
  
  const handleDelete = (id) => {
    dispatch(deleteSpecificationDetail({ id }, (data) => {
      enqueueSnackbar("Data deleted succesfully", { variant: 'success' });
      loadALLSpecificationDetails();
      setName("");
    }))
  }
  
  function handleSubmit(event) {
    event.preventDefault();
    if (!name) {
      enqueueSnackbar("Please fill all fields", { variant: 'error' });
    } else {
      dispatch(addSpecification({ name }, (data) => {
        enqueueSnackbar("Data added succesfully", { variant: 'success' });
        loadALLSpecificationDetails()
      }))
    }
  }
  
  return (
    <Layout>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography
            variant="title"
            sx={{ fontWeight: 900, mb: 4 }}
            component={'h3'}
          >
            Specifications
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Stack spacing={3} component="form" onSubmit={handleSubmit}>
                <InputStyle name="Name" topSpace={true}>
                  <input type="text" name="" id="" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                </InputStyle>{' '}
                <Button type='submit' variant="contained" fullWidth>
                  Add
                </Button>
              </Stack>
            </Grid>
            <Grid item md={7}>
              <TableStyle>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
                <tbody>
                  {allSpecificationDetails?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {
                          tempIndex === index ?
                            <InputStyle topSpace={true} >
                              <input type="text" style={{ width: '90%' }} placeholder="Quantity Number" value={tempName} onChange={(e) => setTempName(e.target.value)} />
                            </InputStyle>
                            :
                            item.name
                        }
                      </td>
                      <td>
                        <Stack
                          direction={'row'}
                          spacing={2}
                          justifyContent={'center'}
                        >
                          <div>
                            {
                              tempIndex === index
                                ?
                                <IconButton size="small" color="primary" onClick={() => handleUpdateSpecifications(index)}>
                                  <Check />
                                </IconButton>
                                :
                                <IconButton size="small" color="primary" onClick={() => handleEditSpecifications(index)}>
                                  <Draw />
                                </IconButton>
                            }
                          </div>
                          <div>
                            <IconButton color="error" size="small">
                              <DeleteForever onClick={() => handleDelete(item._id)} />
                            </IconButton>
                          </div>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
}
