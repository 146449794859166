import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,

    Divider,

    FormControlLabel,

    FormGroup,

    Grid,

    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export default function AddPromotionModal({ addRemarkAction, setAddRemarkAction }) {

    const [remark, setRemark] = useState("")
    const [uploadedImage, setUploadedImage] = useState(null);
    const [id, setId] = useState("");
    const [location, setLocation] = useState("");
    const [heading, setHeading] = useState("");
    const [text, setText] = useState("");
    const [type, setType] = useState("");

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            setUploadedImage(e.target.result);
        };

        reader.readAsDataURL(file);
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(remark)
    }

    const closeHandler = () => setAddRemarkAction(false);
    const ButtonStyle = {
        maxWidth: 150,
        boxShadow: 0,
        width: '100%',
        marginTop: 4,
        border: '1px solid #278FD9',
        mx: 3
    };
    return (
        <Dialog
            open={addRemarkAction}
            onClose={closeHandler}
            maxWidth="md"
            fullWidth
        >
            <DialogContent sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                >
                    <div>
                        <Typography variant="title" component="h3">
                            Add Banner
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            sx={{ border: '2px solid #DFDFE6' }}
                            size="small"
                            onClick={closeHandler}
                        >
                            <Close sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </div>
                </Stack>
                <Box component={'from'} flexGrow={1}>
                    <form onSubmit={handleSubmit}>
                        <Box sx={{ py: 2 }}>
                            <Typography>Upload Banner Image</Typography>
                            <label htmlFor="profile-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                {uploadedImage ? (
                                    <img className="uploadimg" src={uploadedImage} alt="upload" />
                                ) : (
                                    <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                                )}
                            </label>
                            <input
                                id="profile-image-upload"
                                type="file"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleImageUpload}
                            />
                        </Box>

                        <Divider sx={{ color: '#d2d2d2', my: 3 }} />

                        <Grid container spacing={2}>
                            {/* <Grid item md={6}>
                                <label>Subscription Id</label>
                                <input
                                    required
                                    className='inputBox'
                                    type="text"
                                    name="id"
                                    value={id}
                                    onChange={(e) => setId(e.target.value)}
                                    placeholder="Enter subscription id"
                                />
                            </Grid> */}
                            <Grid item md={6}>
                                <label>Location</label>
                                <input
                                    required
                                    className='inputBox'
                                    type="text"
                                    name="location"
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    placeholder="Enter location"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <label>Heading</label>
                                <input
                                    required
                                    className='inputBox'
                                    type="text"
                                    name="heading"
                                    value={heading}
                                    onChange={(e) => setHeading(e.target.value)}
                                    placeholder="Enter banner days"
                                />
                            </Grid>

                            <Grid item md={6}>
                                <label>Text</label>
                                <select
                                    required
                                    className='inputBox'
                                    type="text"
                                    name="text"
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    placeholder="Enter banner days"
                                />
                            </Grid>

                            <Grid item md={6}>
                                <label>Promotion Type</label>
                                <select
                                    required
                                    className='inputBox'
                                    type="text"
                                    name="text"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    placeholder="Enter banner days"
                                >
                                    <option value="Subscription">Subscription</option>
                                    <option value="Offer" >Offer</option>
                                    <option value="Coupons">Coupons</option>
                                    <option value="Events">Events</option>
                                </select>
                            </Grid>
                        </Grid>


                        <Button variant="contained" sx={ButtonStyle} type='submit' onClick={handleSubmit}>
                            Submit
                        </Button>
                    </form>
                </Box>
            </DialogContent >
        </Dialog >
    );
}
