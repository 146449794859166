import common from "./common"


const getAPI = (endPoint, body, successCallback, errorCallback) => {
    const token=localStorage.getItem('token')
    common.setAuthorizationToken(token)
    common?.axiosInstance?.get(endPoint, { params: body })
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        })
}

const postAPI = (endPoint, body, successCallback, errorCallback) => {
    const token=localStorage.getItem('token')
    common.setAuthorizationToken(token)
    common?.axiosInstance?.post(endPoint, body)
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        })
}

const putAPI = (endPoint, body, successCallback, errorCallback) => {
    const token=localStorage.getItem('token')
    common.setAuthorizationToken(token)
    common?.axiosInstance?.put(endPoint, body)
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        })
}

const patchApi = (endPoint, body, successCallback, errorCallback) => {
    const token=localStorage.getItem('token')
    common.setAuthorizationToken(token)
    common?.axiosInstance?.patch(endPoint, body)
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        })
}

const deleteAPI = (endPoint, body, successCallback, errorCallback) => {
    const token=localStorage.getItem('token')
    common.setAuthorizationToken(token)
    common?.axiosInstance?.delete(endPoint, { params: body })
        .then((response) => {
            successCallback(response);
        })
        .catch((error) => {
            errorCallback(error);
        })
}

export {
    getAPI,
    postAPI,
    putAPI,
    deleteAPI,
    patchApi
}