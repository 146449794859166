import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import InputStyle from '../../../assets/InputStyle';
const ActivateSubscription = ({ open, setOpen }) => {
    // const [open, setOpen] = useState(false);
    const [allVisits, setAllVisits] = useState([]);
    const [uploadedImage, setUploadedImage] = useState(null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const headData = [
        'Subscription Id',
        'Builder NAme',
        'No. of Properties ',
        'Date of Purchase',
        'Payment status',
        'Transaction No.',
    ];

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            setUploadedImage(e.target.result);
        };

        reader.readAsDataURL(file);
    };

    return (
        <div>

            <Dialog
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                      "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px",  // Set your width here
                      },
                    },
                  }}
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>Activate Subscription</Typography>
                    <IconButton>
                        <CancelIcon onClick={handleClose} />
                    </IconButton>

                </DialogTitle>
                <DialogContent sx={{ height: '100vh', }}>
                    <Grid container spacing={2} >
                        <Grid item md={4} flexGrow={1}>
                            <Box sx={{  }}>
                                <Typography>Upload Banner Image</Typography>
                                <label htmlFor="profile-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                    {uploadedImage ? (
                                        <img className="uploadimg" src={uploadedImage} alt="upload" />
                                    ) : (
                                        <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                                    )}
                                </label>
                                <input
                                    id="profile-image-upload"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageUpload}
                                />
                            </Box>
                        </Grid>

                        <Grid item md={8} flexGrow={1} mt={2}>
                            <InputStyle name="Choose Location">
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Enter Name"
                                // value={brokerDetails.address}
                                // onChange={handleChange}
                                />
                            </InputStyle>
                        </Grid>

                    </Grid>

                    <Grid container spacing={2} mt={2}>
                        <Grid item md={4} flexGrow={1}>
                            <Box sx={{  }}>
                                <Typography>Upload Flash Image</Typography>
                                <label htmlFor="profile-image-upload" style={{ display: 'block', cursor: 'pointer' }}>
                                    {uploadedImage ? (
                                        <img className="uploadimg" src={uploadedImage} alt="upload" />
                                    ) : (
                                        <img className="uploadimg" src="/uploadImg.png" alt="upload" />
                                    )}
                                </label>
                                <input
                                    id="profile-image-upload"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageUpload}
                                />
                            </Box>
                        </Grid>
                    </Grid>


                    <Box mt={10} >
                        <Button variant="contained" disableElevation sx={{ px: 5 }} >
                            Save
                        </Button>
                   
                    </Box>

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ActivateSubscription;
