import React, { useEffect, useState } from "react";
import Layout from "../../assets/Layout";
import { Box, Paper, Stack, Typography } from "@mui/material";
import PropertyUpdateForm from "./PropertyUpdateForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllBanks, getAllBuilders, getAllPropertyCategory, getPropertyById } from "../../actions";
import CircularProgress from '@mui/material/CircularProgress';


const UpdateProperty = () => {
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const [builder,setBuilder]=useState([]);
    const [category,setCategory]=useState([]);
    const [banks,setBanks]=useState([])
    const [isLoading, setIsLoading] = useState(false);

    const {id} = useParams();
    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate("/");
        }
        dispatch(
            getAllBuilders({},(data)=>{
                setBuilder(data.data)
            })    
        )
        dispatch(
            getAllPropertyCategory({},(data)=>{
                setCategory(data.data)
            })
        )
        dispatch(
            getAllBanks({},(data)=>{
                setBanks(data)
            })
        )
    }, [navigate])

    return (
        <>
            <Layout path="">
                <Typography sx={{fontWeight:'600', fontSize:'28px', color:'#000000',marginTop:'48px', marginBottom:'48px'}}>Update Property</Typography>
                <Paper sx={{borderRadius:'20px'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack direction="row" spacing={2} sx={{width: '634px', height: '51px', borderRadius: '120px', background: 'rgba(217, 217, 217, 0.44)',marginTop:'65px' }}>
                            <div style={{ width: '40%' }}>
                                <p style={{ marginTop: '16px' }}>
                                    <span style={{ padding: '7px 13px', background: '#278FD9', borderRadius: '50%', marginLeft: '28px', marginRight: '20px', color: '#FFFFFF' }}>1</span>
                                    Property Description
                                </p>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p style={{ marginTop: '16px' }}>
                                    <span style={{ padding: '7px 13px', background: '#FFFFFF', borderRadius: '50%', marginLeft: '28px', marginRight: '20px' }}>2</span>
                                    Floor Plan
                                </p>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p style={{ marginTop: '16px' }}>
                                    <span style={{ padding: '7px 13px', background: '#FFFFFF', borderRadius: '50%', marginLeft: '28px', marginRight: '20px' }}>3</span>
                                    Brokerage
                                </p>
                            </div>
                        </Stack>
                    </Box>

            
                    <Box sx={{padding:'0px 40px'}}>
                        <PropertyUpdateForm  propertyID={id}/>
                    </Box>
                </Paper>
            </Layout>
        </>
    );
}

export default UpdateProperty;