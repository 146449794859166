import React, { useEffect, useCallback, useState } from 'react';
import Layout from '../../assets/Layout';
import {
    Box,
    Checkbox,
    IconButton,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Breadcrumbs from '../../assets/Breadcrumbs';
import ButtonController from '../../assets/ButtonController';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Delete,
    Edit,
} from '@mui/icons-material';
import MainButton from '../../assets/MainButton';
import { useDispatch } from 'react-redux';
import { getAllBlog, getAllTutorial, updateTutorial } from '../../actions';
import AddTraining from './AddTraining';
import UpdateTraining from './UpdateTraining';
import SearchFilter from '../../assets/SearchFilter';
import { enqueueSnackbar } from 'notistack';

export default function Tutorials() {
    const dispatch = useDispatch()
    const [updateDialog, setUpdateDialog] = useState(false);
    const [addDialog, setAddDialog] = useState(false);
    const [tutorial, setTutorial] = useState([]);
    const [allTutorial, setAllTutorial] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [id, setId] = useState('');
    const [loading, setLoading] = useState("");

    useEffect(() => {
        dispatch(getAllTutorial({}, (data) => {
            const filterData = data.data.filter((broker) => {
                // by link
                if (broker?.link.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }
                // by description
                if (broker?.description.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }
                // by title
                if (broker?.title.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }
            })
            if (searchQuery) {
                setTutorial(filterData);
            }
            setAllTutorial(data.data);
        }, (err) => {
            console.log(err);
        },
        ))
    }, [searchQuery]);

    useEffect(() => {
        dispatch(getAllTutorial({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
            if (searchQuery == "") {
                setTutorial(data.data);
            }
        }, (err) => {
            console.log(err);
        },
        ))
    }, [currentPage, countPerPage,searchQuery]);


    const handleUpdateStatus = (item, status) => {
        let payload = {
            id: item._id, isActive: status, title: item.title, description: item.description
        }
        dispatch(
            updateTutorial(payload, (data) => {
                // loadAllProperties()
                enqueueSnackbar("Status updated!", { variant: 'success' })
            })
        )
    }

    const handleDelete = (item,) => {
        let payload = {
            id: item._id, isDeleted: true, title: item.title, description: item.description
        }
        dispatch(
            updateTutorial(payload, (data) => {
                // loadAllProperties()
                enqueueSnackbar("Status updated!", { variant: 'success' })
                window.location.reload(true);
            })
        )
    }





    return (
        <Layout>
            <Stack
                direction={{ md: 'row', xs: 'column' }}
                spacing={3}
                sx={{ my: 4 }}
                justifyContent={'space-between'}
            >
                <Breadcrumbs primary="Training" />
                <ButtonController>
                    <Box onClick={() => setAddDialog(true)}>
                        <MainButton>
                            <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> Add Training
                        </MainButton>
                    </Box>
                </ButtonController>
            </Stack>
            <SearchFilter>
                <Box className="search style-1">
                    <input type="text" placeholder="Search..." onChange={(e) => setSearchQuery(e.target.value)} />
                </Box>
            </SearchFilter>
            <Box sx={{ mb: 4 }}></Box>
            <TableContainer component={Paper} sx={{ '& th': { color: '#BABABA' } }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Checkbox />
                            </TableCell>
                            <TableCell>#</TableCell>
                            <TableCell>Training Name</TableCell>
                            <TableCell>Training Except</TableCell>
                            <TableCell>Video Link</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="center">ACTION</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tutorial?.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <Checkbox />
                                </TableCell>
                                <TableCell>{item?._id}</TableCell>
                                <TableCell>
                                    <div className="link" onClick={() => { setId(item?._id); setUpdateDialog(true) }}>
                                        {item?.title}
                                    </div>
                                </TableCell>
                                <TableCell>{item?.description}</TableCell>
                                <TableCell>{item?.link}</TableCell>
                                {/* <TableCell>{item?.isActive}</TableCell> */}
                                <TableCell>
                                    <Switch checked={item?.isActive}
                                        onChange={(e) => { handleUpdateStatus(item, e.target.checked); }}
                                    />
                                </TableCell>
                                <TableCell align="center">
                                    {/* <IconButton size="small">
                                        <Edit />
                                    </IconButton> */}
                                    <IconButton size="small" onClick={() => handleDelete(item)}>
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box
                sx={{
                    bgcolor: "mute.dark",
                    borderRadius: 1,
                    color: "dark.text",
                    p: 2,
                    mt: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box>
                    {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allTutorial?.length ? allTutorial?.length : currentPage * countPerPage} of {allTutorial?.length}</Typography> */}
                    <Typography>
                        {allTutorial?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                        {countPerPage > allTutorial?.length
                            ? allTutorial?.length
                            : currentPage * countPerPage > allTutorial?.length
                                ? allTutorial?.length
                                : currentPage * countPerPage}{' '}
                        of {allTutorial?.length}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Typography> Rows per page:</Typography>
                    <select style={{ marginTop: "-5px" }}
                        value={countPerPage}
                        onChange={(e) => setCountPerPage(e.target.value)}>
                        <option value="10" >
                            10
                        </option>
                        <option value="20">
                            20
                        </option>
                        <option value="30">
                            30
                        </option>
                    </select>
                    <Box sx={{ ml: 2 }}>
                        <Stack
                            direction={"row"}
                            spacing={2}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Box>
                                <IconButton
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1 || countPerPage > allTutorial?.length}
                                    sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allTutorial?.length ? "#EFEFEF" : "#FFF" }}
                                >
                                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography variant="text" component={"span"}>
                                    {currentPage}/{Math.ceil(allTutorial?.length / countPerPage)}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={countPerPage > allTutorial?.length || currentPage == Math.ceil(allTutorial?.length / countPerPage)}
                                    sx={{
                                        border: "1px solid",
                                        borderColor: "mute.main",
                                        bgcolor: "white.main",
                                    }}
                                >
                                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Box>
            <AddTraining
                profileAction={addDialog}
                setProfileAction={setAddDialog}
            />
            <UpdateTraining
                profileAction={updateDialog}
                setProfileAction={setUpdateDialog}
                id={id}
            />
        </Layout>
    );
}
