import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addFloorPlanInProperty, deleteFloorPlanAndPricing, getAllFurnishingDetails, getAllpropertySubCategory, getPropertyById, updateFloorPlanAndPricing } from '../../actions';
import { Check, Delete, Done, Download, Draw, Info, ModeEditOutline } from '@mui/icons-material';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import { enqueueSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteAPI } from '../../utils/api';
import { uploadDocToS3, getAllSpecification, getPropertyCategoryById } from '../../actions';
import { Add } from '@mui/icons-material';
import deleteSvg from "../../assets/img/bin13.svg";
import editSvg from "../../assets/img/Light.png";
import ClearIcon from '@mui/icons-material/Clear';

const UpdateFloorPlanForm = ({ propertyID }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [allFurnishingPlans, setAllFurnishingPlans] = useState([]);
    const [propertyType, setPropertyType] = useState('');
    const [constructionStatus, setConstructionStatus] = React.useState('');
    const [specifications, setSpecifications] = React.useState([]);
    const [specification, setSpecification] = React.useState('');
    const [quanitity, setQuantity] = React.useState('');
    const [subPropertyID, setSubPropertyID] = React.useState('');
    const [subProperty, setSubProperty] = React.useState('');
    const [furnishing, setFurnishing] = React.useState([]);
    const [tempIndex, setTempIndex] = React.useState('');
    const [tempSpecification, setTempSpecification] = React.useState('');
    const [tempQuantity, setTempQuantity] = React.useState('');
    const [possessionDate, setPossessionDate] = React.useState("");
    const [enterMinPrice, setEnterMinPrice] = React.useState("");
    const [enterMaxPrice, setEnterMaxPrice] = React.useState("");
    const [enterPrice, setEnterPrice] = React.useState("");
    const [minCarpetSize, setMinCarpetSize] = React.useState("");
    const [maxCarpetSize, setMaxCarpetSize] = React.useState("");
    const [carpetSize, setCarpetSize] = React.useState("");
    const [basicQuantity, setBasicQuantity] = React.useState("");
    const [plotSizeLength, setPlotSizeLength] = React.useState("");
    const [plotSizeBreath, setPlotSizeBreath] = React.useState("");
    const [floorPlanImage, setFloorPlanImage] = React.useState([]);
    const [floorPlanImagePicView, setFloorPlanImagePicView] = React.useState([]);
    const [unitType, setUnitType] = React.useState("");
    const [floorPlans, setFloorPlans] = React.useState([]);
    const [tempIndex1, setTempIndex1] = React.useState("");
    const [tempFloorPlanImage, setTempFloorImage] = React.useState([]);
    const [tempUnitType, setTempUnitType] = React.useState("");
    const [tempCarpetSize, setTempCarpetSize] = React.useState("");
    const [tempFurnishing, setTempFurnishing] = React.useState([]);
    const [tempEnterPrice, setTempEnterPrice] = React.useState("");
    const [tempSpecifications, setTempSpecifications] = React.useState([]);
    const [tempQuantiti, setTempQuantiti] = React.useState("");
    const [allSpecifications, setAllSpecifications] = React.useState([]);
    const [tempIndex2, setTempIndex2] = React.useState("");
    const [furnishingQuantity, setFurnishingQuantity] = React.useState("");
    const [furnishings, setFurnishings] = React.useState([]);
    const [tempFurnishingQuantity, setTempFurnishingQuantity] = React.useState("");
    const [tempFurnishingDetail, setTempFurnishingDetail] = React.useState("");
    const [allFurnishing, setAllFurnishing] = React.useState([]);
    const [specificationList, setSpecificationList] = React.useState([]);
    const [minUnitSize, setMinUnitSize] = React.useState("");
    const [maxUnitSize, setMaxUnitSize] = React.useState("");
    const [plotSize, setPlotSize] = useState("");
    const [specificationLength, setSpecificationLength] = useState("");
    const [specificationBreath, setSpecificationBreath] = useState("");
    const [allSpecificationList, setAllSpecificationList] = useState("");
    const [unitSize, setUnitSize] = useState("");
    const [subPropertyType, setSubPropertyType] = useState("");
    const [open, setOpen] = useState(false);
    const [updateImages, setUpdateImages] = useState([]);


    const dispatch = useDispatch();

    const loadAllSpecifications = React.useCallback(() => {
        dispatch(
            getAllSpecification({}, (data) => {
                setAllSpecifications(data.data);
            })
        )
    }, [dispatch])

    useEffect(() => (
        loadAllSpecifications()
    ), [loadAllSpecifications])

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/');
        }
    }, [navigate]);


    const loadALLFurnishingDetails = useCallback(() => {
        dispatch(getAllFurnishingDetails({}, (data) => {
            setAllFurnishingPlans(data);
        }))
    }, [dispatch])

    useEffect(() => {
        loadALLFurnishingDetails();
    }, [loadALLFurnishingDetails])
    const loadPropertyDetails = useCallback(() => {
        dispatch(
            getPropertyById(propertyID, {}, (data) => {
                setPropertyType(data.data[0].propertyType.name);
                setSubPropertyID(data.data[0].propertySubTypes[0]);
                setSubPropertyType(data.data[0].propertyTypeSubCategory);
                if (data.data[0].propertyType.name.toLowerCase() == "flats / apartment" && (data.data[0].propertyTypeSubCategory.toLowerCase() == "builder floor" || data.data[0].propertyTypeSubCategory.toLowerCase() == "duplex house") || data.data[0].propertyType.name.toLowerCase() == "farmhouse / villa") {
                    setFloorPlans(data.data[0].floorPlanAndPricing.map((item) => {
                        return {
                            _id: item?._id,
                            floorPlanImage: item?.floorPlanImageUrl,
                            unitType: item?.unitType[0],
                            carpetSize: item?.carpetSize,
                            price: item?.price,
                            specificationList: item?.specifications,
                            constructionStatus: item?.constructionStatus,
                            possessionDate: item?.possessionDate == undefined ? "" : item?.possessionDate,
                            plotSize: item?.plotSize[0]?.totalPlotSize,
                            plotSizeLength: item?.plotSize[0]?.length,
                            plotSizeBreath: item?.plotSize[0]?.breadth,
                            furnishings: item?.furnishingDetails
                        }
                    }));
                }
                if (data.data[0].propertyType.name.toLowerCase() == "land/plots") {
                    setFloorPlans(data.data[0].floorPlanAndPricing.map((item) => {
                        return {
                            _id: item?._id,
                            floorPlanImage: item?.floorPlanImageUrl,
                            unitType: item?.unitType[0],
                            unitSize: item?.unitSize?.unitSize,
                            plotSizeLength: item?.unitSize?.length,
                            plotSizeBreath: item?.unitSize?.breadth,
                            price: item?.price,
                            specificationList: item?.specifications,
                            constructionStatus: item?.constructionStatus,
                            possessionDate: item?.possessionDate == undefined ? "" : item?.possessionDate
                        }
                    }));
                }
                if (data.data[0].propertyType.name.toLowerCase() == "commercial" && (data.data[0].propertyTypeSubCategory.toLowerCase() === "sco" || data.data[0].propertyTypeSubCategory.toLowerCase() === "commercial space")) {
                    setFloorPlans(data.data[0].floorPlanAndPricing.map((item) => {
                        return {
                            _id: item?._id,
                            floorPlanImage: item?.floorPlanImageUrl,
                            unitType: item?.unitType[0],
                            unitSize: item?.unitSize?.unitSize,
                            plotSizeLength: item?.unitSize?.length,
                            plotSizeBreath: item?.unitSize?.breadth,
                            price: item?.price,
                            specificationList: item?.specifications,
                            constructionStatus: item?.constructionStatus,
                            possessionDate: item?.possessionDate == undefined ? "" : item?.possessionDate,
                            furnishings: item?.furnishingDetails
                        }
                    }));
                }
                if (data.data[0].propertyType.name.toLowerCase() === "commercial" && data.data[0].propertyTypeSubCategory.toLowerCase() === "warehouse") {
                    setFloorPlans(data.data[0].floorPlanAndPricing.map((item) => {
                        return {
                            _id: item?._id,
                            floorPlanImage: item?.floorPlanImageUrl,
                            unitType: item?.unitType[0],
                            minUnitSize: item.unitSize.min,
                            maxUnitSize: item.unitSize.max,
                            constructionStatus: item?.constructionStatus,
                            price: item?.price,
                            specificationList: item?.specifications,
                            possessionDate: item?.possessionDate == undefined ? "" : item?.possessionDate,
                            furnishings: item?.furnishingDetails
                        }
                    }));
                }
                if (data.data[0].propertyType.name.toLowerCase() == "flats / apartment" && (data.data[0].propertyTypeSubCategory.toLowerCase() == "high rise" || data.data[0].propertyTypeSubCategory.toLowerCase() == "low rise")) {
                    setFloorPlans(data.data[0].floorPlanAndPricing.map((item) => {
                        return {
                            _id: item?._id,
                            unitType: item.unitType,
                            floorPlanImage: item?.floorPlanImageUrl,
                            carpetSize: item?.carpetSize,
                            price: item?.price,
                            constructionStatus: item?.constructionStatus,
                            possessionDate: item?.possessionDate == undefined ? "" : item?.possessionDate,
                            furnishings: item?.furnishingDetails,
                            specificationList: item?.specifications
                        }
                    }));
                }
                // if (data.data[0].floorPlanAndPricing.length > 0) {
                //     setFloorPlans(data.data[0].floorPlanAndPricing.map((item) => { return { _id: item._id, floorPlanImage: item.floorPlanImageUrl, unitType: item.unitType[0], carpetSize: item.carpetSize, furnishing: item.furnishingDetails, enterPrice: item.price, specifications: item.specifications, quanitity: item.quantity } }));
                // }
            })
        );
    }, [propertyID]);
    const loadSubPropertyDetails = useCallback(() => {
        dispatch(
            getAllpropertySubCategory({ parentId: subPropertyID }, (data) => {
                setSubProperty(data);
            })
        );
    }, [dispatch, subPropertyID]);
    useEffect(() => {
        loadSubPropertyDetails();
    }, [subPropertyID]);
    useEffect(() => {
        loadPropertyDetails();
    }, [loadPropertyDetails]);
    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer',
    };

    const handleCreatePlan2 = () => {
        if (!furnishing || !furnishingQuantity) {
            enqueueSnackbar("Please add all the fields", { variant: 'error' });
        } else {
            setFurnishings((oldArr) => [...oldArr, { furnishing, quanitity: furnishingQuantity }]);
            setFurnishing("");
            setFurnishingQuantity("");
        }
    };

    const getSpecificationsName = (arr) => {
        if (arr?.length > 0) {
            var tempArr = allSpecifications.filter(element1 => {
                return arr.some(element2 => element2 === element1._id);
            });
            if (tempArr.length > 0) {
                return tempArr.map((item) => item.name).join(', ');
            } else {
                return "";
            }
        } else {
            return "";
        }
    }

    const handleUpdatePlan2 = (index) => {
        const val = furnishings;
        val[index] = { furnishing: tempFurnishingDetail, quanitity: tempFurnishingQuantity };
        setFurnishings(val);
        setTempIndex2(null);
    };

    const handleRemoveFromPlan2 = (index) => {
        const newArray = [...furnishings]; // Create a copy of the original array
        newArray.splice(index, 1);
        localStorage.setItem("floorPlanData", JSON.stringify({ ...JSON.parse(localStorage.getItem("floorPlanData")), furnishings: newArray }));
        setFurnishings(newArray);
    };

    const handleEditPlan2 = (index) => {
        setTempIndex2(index);
        setTempFurnishingDetail(furnishings[index].furnishing);
        setTempFurnishingQuantity(furnishings[index].quanitity);
    };

    const handleCreatePlan = () => {
        if (!specification || !quanitity) {
            enqueueSnackbar("Please add all the fields", { variant: 'error' });
        } else {
            setSpecifications((oldArr) => [...oldArr, { specification, quanitity }]);
            setSpecification("");
            setQuantity("");
        }
    };
    const handleUpdatePlan = (index) => {
        const val = specifications;
        val[index] = { specification: tempSpecification, quanitity: tempQuantity };
        setSpecifications(val);
        setTempIndex("");
        setTempSpecification("");
        setTempQuantity("");
    };
    const handleRemoveFromPlan = (index) => {
        setSpecifications((prevArray) => {
            const newArray = [...prevArray]; // Create a copy of the original array
            newArray.splice(index, 1); // Remove the element at the specified index
            return newArray; // Update the state with the new array
        });
    };
    const handleEditPlan = (index) => {
        setTempIndex(index);
        setTempSpecification(specifications[index].specification);
        setTempQuantity(specifications[index].quanitity);
    };

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setFurnishing(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;
        setTempFurnishing(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleCreatePlan1 = () => {
        setFloorPlans((oldArr) => [...oldArr, { floorPlanImage, unitType, carpetSize, furnishing, enterPrice, specification: specifications, quanitity: basicQuantity }]);
        setFloorPlanImage([]);
        setFloorPlanImagePicView([]);
        setUnitType("");
        setCarpetSize("");
        setFurnishing([]);
        setEnterPrice("");
        setSpecifications([]);
        setQuantity("");
        setBasicQuantity("");
    };

    const handleRemoveFromPlan1 = (index) => {
        setFloorPlans((prevArray) => {
            const newArray = [...prevArray]; // Create a copy of the original array
            newArray.splice(index, 1); // Remove the element at the specified index
            return newArray; // Update the state with the new array
        });

        dispatch(deleteFloorPlanAndPricing(floorPlans[index]._id, propertyID, {}, (data) => {
        }))
    };

    const handleEditPackage1 = (index) => {
        setTempIndex1(index);
        setTempFloorImage(floorPlans[index].floorPlanImage);
        setTempUnitType(floorPlans[index].unitType);
        setTempCarpetSize(floorPlans[index].carpetSize);
        setTempFurnishing(floorPlans[index].furnishing);
        setTempEnterPrice(floorPlans[index].enterPrice);
        setTempSpecification(floorPlans[index].specification);
        setTempQuantiti(floorPlans[index].quanitity);
    };

    const handleUpdatePackage1 = (index) => {
        let val = floorPlans;
        val[index] = { _id: val[index]._id, floorPlanImage: floorPlanImage, unitType: tempUnitType, carpetSize: tempCarpetSize, furnishing: tempFurnishing, enterPrice: tempEnterPrice, specification: tempSpecification, quanitity: tempQuantiti };
        if (tempUnitType.trim() === '' || tempCarpetSize.trim() === '' || tempEnterPrice.trim() === '' || tempQuantiti.trim() === '') {
            enqueueSnackbar("Please fill in all the required fields!", { variant: 'error' });
            return;
        }

        if (val.length > 0) {
            setIsLoading(true)
        }
        setFloorPlans(val);
        var formData = new FormData();
        for (let i = 0; i < val.length; i++) {
            formData.append("floorPlanId", val[i]._id);
            formData.append("mainId", propertyID);
            formData.append("price", val[i].enterPrice);
            formData.append("unitType", val[i].unitType);
            formData.append("quantity", val[i].quanitity);
            formData.append("specifications", JSON.stringify(["East Facing", "Rain Water Harvesting", "Corner Property", "Corner Property", "East Facing"]));
            formData.append("furnishingDetails", JSON.stringify(val[i].furnishing));
            if (propertyType === 'Farmhouse / Villa' ||
                propertyType === 'Plots / Land') {
                // formData.append("plotSize", JSON.stringify([{ length: plotSizeLength, breadth: plotSizeBreath }]));
            }
            // formData.append("carpetSize", val[i].carpetSize);
            // formData.append("onesqft", 10);
            dispatch(updateFloorPlanAndPricing(formData, (data) => {
                setIsLoading(false)

                enqueueSnackbar(data.message, { variant: 'success' });
                // navigate('/property-management/brokerage/' + propertyID);
            }));
        }
        setIsLoading(false)

        setTempIndex1(null);
    };

    const getFurnishingsDetailName = (ids) => {
        var arr = [];
        for (var i = 0; i < ids.length > 0; i++) {
            var name = allFurnishingPlans.find((item) => item._id == ids[i]);
            if (name) {
                arr.push(name.name);
            }
        }

        if (arr.length > 0) {
            return arr.join(', ');
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        for (let index = 0; index < floorPlans.length; index++) {
            let formData1 = new FormData();
            // if floor plan does not contains the id then it needs to be added
            if (floorPlans[index]._id == undefined) {
                formData1.append("floorPlanImageUrl", JSON.stringify(floorPlans[index].floorPlanImage));
                formData1.append("mainId", propertyID);
                if (propertyType.toLowerCase() === "land/plots") {
                    formData1.append("unitType", floorPlans[index]?.unitType);
                    formData1.append("unitSize", JSON.stringify({ unitSize: floorPlans[index]?.unitSize, length: floorPlans[index]?.plotSizeLength, breath: floorPlans[index]?.plotSizeLength }));
                    formData1.append("price", floorPlans[index].price);
                    formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                    formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                    formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                }
                if (propertyType.toLowerCase() === "commercial" && (subPropertyType.toLowerCase() === "sco" || subPropertyType.toLowerCase() === "commercial space")) {
                    formData1.append("unitType", floorPlans[index]?.unitType);
                    formData1.append("unitSize", JSON.stringify({ unitSize: floorPlans[index]?.unitSize, length: floorPlans[index]?.plotSizeLength, breath: floorPlans[index]?.plotSizeLength }));
                    formData1.append("price", floorPlans[index].price);
                    formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                    formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                    formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                    formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                }
                if (propertyType.toLowerCase() === "commercial" && subPropertyType.toLowerCase() === "warehouse") {
                    formData1.append("unitType", floorPlans[index]?.unitType);
                    formData1.append("unitSize", JSON.stringify({ min: floorPlans[index]?.minUnitSize, max: floorPlans[index]?.maxUnitSize }));
                    formData1.append("price", floorPlans[index].price);
                    formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                    formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                    formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                    formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                }

                if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
                    formData1.append("plotSize", JSON.stringify([{ totalPlotSize: floorPlans[index]?.plotSize, length: floorPlans[index]?.plotSizeLength, breath: floorPlans[index]?.plotSizeBreath }]));
                    formData1.append("unitType", floorPlans[index]?.unitType);
                    formData1.append("carpetSize", floorPlans[index].carpetSize);
                    formData1.append("price", floorPlans[index].price);
                    formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                    formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                    formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                    formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                }
                if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
                    formData1.append("unitType", floorPlans[index]?.unitType);
                    formData1.append("carpetSize", floorPlans[index].carpetSize);
                    formData1.append("price", floorPlans[index].price);
                    formData1.append("constructionStatus", floorPlans[index]?.constructionStatus);
                    formData1.append("possessionDate", floorPlans[index]?.possessionDate);
                    formData1.append("furnishingDetails", JSON.stringify(floorPlans[index]?.furnishings));
                    formData1.append("specifications", JSON.stringify(floorPlans[index]?.specificationList));
                }

                dispatch(addFloorPlanInProperty(formData1, async (data) => {
                    enqueueSnackbar("Floor plan added successfully", { variant: 'success' });
                }))
            }
        }
        navigate('/update-property/brokerage/' + propertyID);
    };

    const handleAddAllSpecifications = () => {
        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
            if (!specification || !specificationBreath || !specificationLength) {
                enqueueSnackbar("Please choose correct specification", { variant: 'warning' });
            } else {
                setAllSpecificationList(oldArr => [...oldArr, { specification, specificationLength, specificationBreath }]);
                setSpecification("");
                setSpecificationBreath("");
                setSpecificationLength("");
            }
        } else if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
            if (!specification || !specificationBreath || !specificationLength) {
                enqueueSnackbar("Please choose correct specification", { variant: 'warning' });
            } else {
                setAllSpecificationList(oldArr => [...oldArr, { specification, specificationLength, specificationBreath }]);

                setSpecification("");
                setSpecificationBreath("");
                setSpecificationLength("");
            }
        } else {
            if (!specification) {
                enqueueSnackbar("Please choose correct specification", { variant: 'warning' });
            } else {
                setAllSpecificationList(oldArr => [...oldArr, specification]);

                setSpecification("");
            }
        }
    }

    const [tempSpec, setTempSpec] = useState("");
    const [tempIndex3, setTempIndex3] = useState("");
    const handleEditAllSpecification = (index) => {
        setTempIndex3(index);
        setTempSpec(specification);
    }


    const getSpecificationName = (id) => {
        var temp = allSpecifications.filter((item) => item._id == id);
        if (temp.length > 0) {
            return temp[0].name;
        } else {
            return "";
        }
    }

    const [tempSpecificationWidth, setTempSpecificationWidth] = useState("");
    const [tempSpecificationHeight, setTempSpecificationHeight] = useState("");

    const handleEditSpecification = (index) => {
        setTempIndex(index);
        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
            setTempSpecification(allSpecificationList[index].specification);
            setTempSpecificationHeight(allSpecificationList[index].specificationLength);
            setTempSpecificationWidth(allSpecificationList[index].specificationBreath);
        } else if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
            setTempSpecification(allSpecificationList[index].specification);
            setTempSpecificationHeight(allSpecificationList[index].specificationLength);
            setTempSpecificationWidth(allSpecificationList[index].specificationBreath);
        }
        else {
            setTempSpecification(allSpecificationList[index]);
        }
    }


    const handleUpdateSpecification = (index) => {
        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
            if (!tempSpecification || !tempSpecificationWidth || !tempSpecificationWidth) {
                enqueueSnackbar("Please enter valid values", { variant: 'warning' });
            } else {
                var item = allSpecificationList;
                item[index].specification = tempSpecification;
                item[index].specificationBreath = tempSpecificationWidth;
                item[index].specificationLength = tempSpecificationHeight;
                setAllSpecificationList(item);
                setTempIndex("");
                setTempSpecification("");
            }
        } else if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
            var item = allSpecificationList;
            item[index].specification = tempSpecification;
            item[index].specificationBreath = tempSpecificationWidth;
            item[index].specificationLength = tempSpecificationHeight;
            setAllSpecificationList(item);
            setTempIndex("");
            setTempSpecification("");
        }
        else {
            if (!tempSpecification) {
            } else {
                var item = allSpecificationList;
                item[index] = tempSpecification;
                setAllSpecificationList(item);
                setTempIndex("");
                setTempSpecification("");
            }
        }
    }

    const handleDeleteSpecification = (index) => {
        const newArray = [...allSpecificationList];
        newArray.splice(index, 1);

        localStorage.setItem("floorPlanData", JSON.stringify({ ...JSON.parse(localStorage.getItem("floorPlanData")), allSpecificationList: newArray }));
        setAllSpecificationList(newArray);
    }

    const getFurnishingDetailName = (id) => {
        var temp = allFurnishingPlans.filter((item) => item._id == id);
        if (temp.length > 0) {
            return temp[0].name;
        } else {
            return "";
        }
    }

    const getFurnishingDetailsName = (itemList) => {
        if (itemList && itemList.length > 0) {
            const namesArray = itemList.map(obj => getFurnishingDetailName(obj.furnishing) + ': ' + obj.quanitity);
            return namesArray.join(", ");
        } else {
            return "";
        }
    }

    const getFurnishingDetailsName1 = (itemList) => {
        if (itemList && itemList.length > 0) {
            const namesArray = itemList.map(obj => obj.furnishing + ': ' + obj.quanitity);
            return namesArray.join(", ");
        } else {
            return "";
        }
    }

    const getSpecificationsNameForFlatsAndFarmHouse = (arr) => {
        if (arr && arr.length > 0) {
            const namesArray = arr.map(obj => obj.specification + ', Length: ' + obj.specificationLength + ', Breath: ' + obj.specificationBreath);
            return namesArray.join(", ");
        } else {
            return "";
        }
    }


    const handleAddFloorPlan = () => {
        if (propertyType.toLowerCase() === "land/plots") {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                var val = { floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList };
                setFloorPlans(oldArr => [...oldArr, val]);
                setFloorPlanImage([]);
                setUnitType("");
                setUnitSize("");
                setPlotSizeLength("");
                setPlotSizeBreath("");
                setEnterPrice("");
                setSpecification("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
                localStorage.removeItem("floorPlanData");
            }
        }

        if (propertyType.toLowerCase() === "commercial" && (subPropertyType.toLowerCase() === "sco" || subPropertyType.toLowerCase() === "commercial space")) {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                var val = { floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlans(oldArr => [...oldArr, val]);
                setFloorPlanImage([]);
                setUnitType("");
                setUnitSize("");
                setPlotSizeLength("");
                setPlotSizeBreath("");
                setEnterPrice("");
                setSpecification("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
                localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
                localStorage.removeItem("floorPlanData");
            }
        }

        if (propertyType.toLowerCase() === "commercial" && subPropertyType.toLowerCase() === "warehouse") {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                var val = { floorPlanImage, unitType, minUnitSize, maxUnitSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlans(oldArr => [...oldArr, val]);
                setFloorPlanImage([]);
                setUnitType("");
                setUnitSize("");
                setMinUnitSize("");
                setMaxUnitSize("");
                setEnterPrice("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
                localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
                localStorage.removeItem("floorPlanData");
            }
        }

        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                var val = { floorPlanImage, plotSize, plotSizeLength, carpetSize, plotSizeBreath, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlans(oldArr => [...oldArr, val]);
                setFloorPlanImage([]);
                setUnitType("");
                setPlotSize("");
                setCarpetSize("");
                setPlotSizeLength("");
                setPlotSizeBreath("");
                setEnterPrice("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
                localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
                localStorage.removeItem("floorPlanData");
            }
        }
        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                var val = { floorPlanImage, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlans(oldArr => [...oldArr, val]);
                setFloorPlanImage([]);
                setUnitType("");
                setPlotSize("");
                setCarpetSize("");
                setPlotSizeLength("");
                setPlotSizeBreath("");
                setEnterPrice("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
                localStorage.setItem("floorplans", JSON.stringify([...floorPlans, val]));
                localStorage.removeItem("floorPlanData");
            }
        }
    }


    const [temp1, setTemp1] = useState(false);
    const handleEditFloorPlan = (index) => {
        setTempFloorPlanIndex(index);
        setTemp1(true);
        if (propertyType.toLowerCase() === "land/plots") {
            setFloorPlanImage(floorPlans[index].floorPlanImage);
            setUnitType(floorPlans[index].unitType);
            setUnitSize(floorPlans[index].unitSize);
            setPlotSizeLength(floorPlans[index].plotSizeLength);
            setPlotSizeBreath(floorPlans[index].plotSizeBreath);
            setEnterPrice(floorPlans[index].price);
            setConstructionStatus(floorPlans[index].constructionStatus);
            setPossessionDate(floorPlans[index].possessionDate);
            setAllSpecificationList(floorPlans[index].specificationList);
        }
        if (propertyType.toLowerCase() === "commercial" && (subPropertyType.toLowerCase() === "sco" || subPropertyType.toLowerCase() === "commercial space")) {
            setFloorPlanImage(floorPlans[index].floorPlanImage);
            setUnitType(floorPlans[index].unitType);
            setUnitSize(floorPlans[index].unitSize);
            setPlotSizeLength(floorPlans[index].plotSizeLength);
            setPlotSizeBreath(floorPlans[index].plotSizeBreath);
            setEnterPrice(floorPlans[index].price);
            setConstructionStatus(floorPlans[index].constructionStatus);
            setPossessionDate(floorPlans[index].possessionDate);
            setAllSpecificationList(floorPlans[index].specificationList);
            setFurnishings(floorPlans[index].furnishings);
        }
        if (propertyType.toLowerCase() === "commercial" && subPropertyType.toLowerCase() === "warehouse") {
            setFloorPlanImage(floorPlans[index].floorPlanImage);
            setUnitType(floorPlans[index].unitType);
            setMinUnitSize(floorPlans[index].minUnitSize);
            setMaxUnitSize(floorPlans[index].maxUnitSize);
            setEnterPrice(floorPlans[index].price);
            setConstructionStatus(floorPlans[index].constructionStatus);
            setPossessionDate(floorPlans[index].possessionDate);
            setAllSpecificationList(floorPlans[index].specificationList);
            setFurnishings(floorPlans[index].furnishings);
        }
        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
            setFloorPlanImage(floorPlans[index].floorPlanImage);
            setUnitType(floorPlans[index].unitType);
            setPlotSize(floorPlans[index].plotSize);
            setCarpetSize(floorPlans[index].carpetSize);
            setPlotSizeLength(floorPlans[index].plotSizeLength);
            setPlotSizeBreath(floorPlans[index].plotSizeBreath);
            setEnterPrice(floorPlans[index].price);
            setConstructionStatus(floorPlans[index].constructionStatus);
            setPossessionDate(floorPlans[index].possessionDate);
            setAllSpecificationList(floorPlans[index].specificationList);
            setFurnishings(floorPlans[index].furnishings);
        }

        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
            setFloorPlanImage(floorPlans[index].floorPlanImage);
            setUnitType(floorPlans[index].unitType);
            setCarpetSize(floorPlans[index].carpetSize);
            setEnterPrice(floorPlans[index].price);
            setConstructionStatus(floorPlans[index].constructionStatus);
            setPossessionDate(floorPlans[index].possessionDate);
            setAllSpecificationList(floorPlans[index].specificationList);
            setFurnishings(floorPlans[index].furnishings);
        }
    }

    const handleDeleteFloorPlan = (index) => {
        var oldArr = [...floorPlans];
        if (oldArr[index]) {
            dispatch(deleteFloorPlanAndPricing(oldArr[index]._id, propertyID, {}, (data) => {
                enqueueSnackbar("Floor plan deleted successfully", { variant: 'success' });
            }))
        }
        oldArr.splice(index, 1);
        setFloorPlans(oldArr);
    }

    const [tempFloorPlanIndex, setTempFloorPlanIndex] = useState("");
    const handleUpdateFloorPlan = (index) => {
        let tempFloorPlan = [...floorPlans];
        if (propertyType.toLowerCase() === "land/plots") {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                tempFloorPlan[index] = { ...floorPlans[index], floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList };
                setFloorPlanImage([]);
                setUnitType("");
                setUnitSize("");
                setPlotSizeLength("");
                setPlotSizeBreath("");
                setEnterPrice("");
                setSpecification("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
            }
        }


        if (propertyType.toLowerCase() === "commercial" && (subPropertyType.toLowerCase() === "sco" || subPropertyType.toLowerCase() === "commercial space")) {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                tempFloorPlan[index] = { ...floorPlans[index], floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlanImage([]);
                setUnitType("");
                setUnitSize("");
                setPlotSizeLength("");
                setPlotSizeBreath("");
                setEnterPrice("");
                setSpecification("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
            }
        }

        if (propertyType.toLowerCase() === "commercial" && subPropertyType.toLowerCase() === "warehouse") {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                tempFloorPlan[index] = { ...floorPlans[index], floorPlanImage, unitType, minUnitSize, maxUnitSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlanImage([]);
                setUnitType("");
                setMinUnitSize("");
                setMaxUnitSize("");
                setEnterPrice("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
            }
        }

        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                tempFloorPlan[index] = { ...floorPlans[index], floorPlanImage, plotSize, plotSizeLength, plotSizeBreath, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlanImage([]);
                setPlotSize("");
                setCarpetSize("");
                setPlotSizeBreath("");
                setPlotSizeLength("");
                setUnitType("");
                setCarpetSize("");
                setEnterPrice("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
            }
        }

        if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
            if (!unitType || !enterPrice) {
                enqueueSnackbar("Please add all the required fields", { variant: 'warning' });
            } else {
                tempFloorPlan[index] = { ...floorPlans[index], floorPlanImage, unitType, carpetSize, price: enterPrice, constructionStatus, possessionDate, specificationList: allSpecificationList, furnishings: furnishings };
                setFloorPlanImage([]);
                setPlotSize("");
                setCarpetSize("");
                setPlotSizeBreath("");
                setPlotSizeLength("");
                setUnitType("");
                setCarpetSize("");
                setEnterPrice("");
                setConstructionStatus("");
                setPossessionDate("");
                setAllSpecificationList([]);
                setFurnishings([]);
            }
        }

        setTempFloorPlanIndex("");
        if (tempFloorPlan?.length > 0) {
            for (let index = 0; index < tempFloorPlan.length; index++) {
                var formData1 = new FormData();
                formData1.append("floorPlanImageUrl", JSON.stringify(tempFloorPlan[index].floorPlanImage));
                formData1.append("mainId", propertyID);
                formData1.append("floorPlanId", tempFloorPlan[index]._id);
                if (propertyType.toLowerCase() === "land/plots") {
                    formData1.append("unitType", tempFloorPlan[index]?.unitType);
                    formData1.append("unitSize", JSON.stringify({ unitSize: tempFloorPlan[index]?.unitSize, length: tempFloorPlan[index]?.plotSizeLength, breadth: tempFloorPlan[index]?.plotSizeBreath }));
                    formData1.append("price", tempFloorPlan[index].price);
                    formData1.append("constructionStatus", tempFloorPlan[index]?.constructionStatus);
                    formData1.append("possessionDate", tempFloorPlan[index]?.possessionDate);
                    formData1.append("specifications", JSON.stringify(tempFloorPlan[index]?.specificationList));
                }
                if (propertyType.toLowerCase() === "commercial" && (subPropertyType.toLowerCase() === "sco" || subPropertyType.toLowerCase() === "commercial space")) {
                    formData1.append("unitType", tempFloorPlan[index]?.unitType);
                    formData1.append("unitSize", JSON.stringify({ unitSize: tempFloorPlan[index]?.unitSize, length: tempFloorPlan[index]?.plotSizeLength, breadth: tempFloorPlan[index]?.plotSizeBreath }));
                    formData1.append("price", tempFloorPlan[index].price);
                    formData1.append("constructionStatus", tempFloorPlan[index]?.constructionStatus);
                    formData1.append("furnishingDetails", JSON.stringify(tempFloorPlan[index]?.furnishings));
                    formData1.append("possessionDate", tempFloorPlan[index]?.possessionDate);
                    formData1.append("specifications", JSON.stringify(tempFloorPlan[index]?.specificationList));
                }
                if (propertyType.toLowerCase() === "commercial" && subPropertyType.toLowerCase() === "warehouse") {
                    formData1.append("unitType", tempFloorPlan[index]?.unitType);
                    formData1.append("unitSize", JSON.stringify({ min: tempFloorPlan[index]?.minUnitSize, max: tempFloorPlan[index]?.maxUnitSize }));
                    formData1.append("price", tempFloorPlan[index].price);
                    formData1.append("constructionStatus", tempFloorPlan[index]?.constructionStatus);
                    formData1.append("furnishingDetails", JSON.stringify(tempFloorPlan[index]?.furnishings));
                    formData1.append("possessionDate", tempFloorPlan[index]?.possessionDate);
                    formData1.append("specifications", JSON.stringify(tempFloorPlan[index]?.specificationList));
                }

                if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa") {
                    formData1.append("plotSize", JSON.stringify([{ totalPlotSize: tempFloorPlan[index]?.plotSize, length: tempFloorPlan[index]?.plotSizeLength, breadth: tempFloorPlan[index]?.plotSizeBreath }]));
                    formData1.append("unitType", tempFloorPlan[index]?.unitType);
                    formData1.append("carpetSize", tempFloorPlan[index].carpetSize);
                    formData1.append("price", tempFloorPlan[index].price);
                    formData1.append("constructionStatus", tempFloorPlan[index]?.constructionStatus);
                    formData1.append("possessionDate", tempFloorPlan[index]?.possessionDate);
                    formData1.append("furnishingDetails", JSON.stringify(tempFloorPlan[index]?.furnishings));
                    formData1.append("specifications", JSON.stringify(tempFloorPlan[index]?.specificationList));
                }
                if (propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")) {
                    formData1.append("unitType", tempFloorPlan[index]?.unitType);
                    formData1.append("carpetSize", tempFloorPlan[index].carpetSize);
                    formData1.append("price", tempFloorPlan[index].price);
                    formData1.append("constructionStatus", tempFloorPlan[index]?.constructionStatus);
                    formData1.append("possessionDate", tempFloorPlan[index]?.possessionDate);
                    formData1.append("furnishingDetails", JSON.stringify(tempFloorPlan[index]?.furnishings));
                    formData1.append("specifications", JSON.stringify(tempFloorPlan[index]?.specificationList));
                }
                dispatch(updateFloorPlanAndPricing(formData1, async (data) => {
                    enqueueSnackbar("Floor plan updated successfully", { variant: 'success' });
                }))
            }
        }
        setTemp1(false);
        setFloorPlans(tempFloorPlan);
    }

    function numberToWords(number) {
        const ones = [
            '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
            'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
            'seventeen', 'eighteen', 'nineteen'
        ];

        const tens = [
            '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
        ];

        if (number === 0) {
            return 'zero';
        } else if (number < 0) {
            return 'minus ' + numberToWords(Math.abs(number));
        }

        let words = '';

        if (Math.floor(number / 10000000) > 0) {
            words += numberToWords(Math.floor(number / 10000000)) + ' crore ';
            number %= 10000000;
        }

        if (Math.floor(number / 100000) > 0) {
            words += numberToWords(Math.floor(number / 100000)) + ' lakh ';
            number %= 100000;
        }

        if (Math.floor(number / 1000) > 0) {
            words += numberToWords(Math.floor(number / 1000)) + ' thousand ';
            number %= 1000;
        }

        if (Math.floor(number / 100) > 0) {
            words += numberToWords(Math.floor(number / 100)) + ' hundred ';
            number %= 100;
        }

        if (number > 0) {
            if (number < 20) {
                words += ones[number];
            } else {
                words += tens[Math.floor(number / 10)];
                if (number % 10 > 0) {
                    words += '-' + ones[number % 10];
                }
            }
        }

        return words.trim();
    }

    const handleRemoveFloorPlanImage = (index) => {
        setFloorPlanImage(prevArray => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });

        setFloorPlanImagePicView(prevArray => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });

        let tempArr = [...floorPlanImage];
        tempArr.splice(index, 1);

        if (localStorage.getItem("floorPlanData")) {
            localStorage.setItem("floorPlanData", JSON.stringify({ ...JSON.parse(localStorage.getItem("floorPlanData")), floorPlanImages: tempArr }));
        }
    }

    const handleUploadBrochure = (e) => {
        const file = e.target.files;

        if (file && file.length > 0) {
            for (let index = 0; index < file.length; index++) {
                let formData = new FormData();
                formData.append('documents', file[index]);
                dispatch(
                    uploadDocToS3(formData, (data) => {
                        setFloorPlanImage(oldArr => [...oldArr, data.data.documents[0]]);
                    }, (err) => {
                        console.log(err);
                    })
                );

            }
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    return (
        <>
            <Box sx={{ marginTop: '73px' }}>
                <Grid container>
                    <Grid item md={3}>
                        <UploadDataBox>
                            <UploadData name="Upload Floor Pan">
                                <Box sx={{ display: 'flex' }}>
                                    {
                                        floorPlanImage.length > 0
                                        && floorPlanImage.map((item, index) => (
                                            <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                                <img
                                                    src={item}
                                                    className="img-fluid"
                                                    style={{ width: '130px', height: '130px' }}
                                                />
                                                <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemoveFloorPlanImage(index)}>
                                                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                                </IconButton>
                                                <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, left: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                                                    <a href={item} download>
                                                        <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                                                    </a>
                                                </IconButton>
                                            </Box>
                                        ))
                                    }
                                    <UploadDataCard>
                                        <img
                                            src={'/uploadImg.png'}
                                            alt="upload"
                                            className="img-fluid"
                                        />
                                        <input type="file" accept="image/*" onChange={handleUploadBrochure} multiple />
                                    </UploadDataCard>
                                </Box>
                            </UploadData>
                        </UploadDataBox>
                        {/* <Box sx={{ py: 2 }}>
                                <Typography>Upload Floor Pan</Typography>
                                <Box component="button" sx={{ background: '#FFFFFF', border: 'none', cursor: 'pointer' }}>
                                    <img className='uploadimg' src={"/Images/uploadImg.png"} alt='upload' />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={hideInput}
                                    />
                                </Box>
                            </Box> */}
                    </Grid>
                </Grid>
                <Divider
                    sx={{
                        height: '1.5px',
                        background: '#E0E4EC',
                        marginTop: '73px',
                        marginBottom: '34px',
                    }}
                />
                {/* Form for Lands/Plots */}
                {
                    propertyType.toLowerCase() === "land/plots"
                        ?
                        <>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <label for="fname">Unit Type*</label>
                                    <input
                                        required
                                        className="inputBox"
                                        type="text"
                                        value={unitType}
                                        placeholder="Unit Type"
                                        onChange={(e) => {
                                            setUnitType(e.target.value);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={4}>
                                            <label for="fname">Unit Size</label>
                                            <input
                                                className="inputBox"
                                                type="text"
                                                placeholder="Size"
                                                value={unitSize}
                                                onChange={(e) => {
                                                    setUnitSize(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for="fname">Length</label>
                                            <input
                                                className="inputBox"
                                                type="text"
                                                placeholder="Length"
                                                value={plotSizeLength}
                                                onChange={(e) => {
                                                    setPlotSizeLength(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for="fname">Breath</label>
                                            <input
                                                className="inputBox"
                                                type="text"
                                                placeholder="Breath"
                                                value={plotSizeBreath}
                                                onChange={(e) => {
                                                    setPlotSizeBreath(e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <label >Price*</label>
                                    <input
                                        required
                                        className="inputBox"
                                        type="text"
                                        placeholder="Price"
                                        value={enterPrice}
                                        onChange={(e) => {
                                            setEnterPrice(e.target.value);
                                        }}
                                    />
                                    <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
                                </Grid>
                                <Grid item xs={6}>
                                    <label>Construction Status</label>
                                    <select value={constructionStatus} onChange={(e) => {
                                        setConstructionStatus(e.target.value)
                                    }}>
                                        <option value="" disabled selected>
                                            Choose Construction Status
                                        </option>
                                        <option value="Ready to move">Ready to move</option>
                                        <option value="Under Construction">Under Construction</option>
                                        <option value="New Launch">New Launch</option>
                                    </select>
                                </Grid>
                                <Grid item xs={6}>
                                    <label>
                                        Possession Date
                                        {constructionStatus == 'Ready to move' && (
                                            <Tooltip title="this field is not enabled for 'ready to move' contruction status">
                                                <Info
                                                    sx={{
                                                        fontSize: '20px',
                                                        verticalAlign: 'sub',
                                                        marginLeft: '1px',
                                                    }}
                                                />
                                            </Tooltip>
                                        )}
                                    </label>
                                    <input
                                        value={possessionDate}
                                        onChange={(e) => {
                                            setPossessionDate(e.target.value)
                                        }
                                        }
                                        className="inputBox"
                                        type="date"
                                        placeholder="Possession Date"
                                        disabled={constructionStatus == 'Ready to move'}
                                    />
                                </Grid>
                                <Grid item xs={11}>
                                    <label>Specification</label>
                                    <select
                                        value={specification}
                                        onChange={(e) => {
                                            setSpecification(e.target.value)
                                        }}>
                                        <option value="" disabled>Choose specification</option>
                                        {allSpecifications && allSpecifications.map((item, index) => (
                                            <option value={item.name} key={index}>{item.name}</option>
                                        ))}
                                    </select>
                                </Grid>
                                <Grid item xs={1}>
                                    <p
                                        onClick={handleAddAllSpecifications}
                                        style={{
                                            borderRadius: '50%',
                                            background: '#278FD9',
                                            fontWeight: '600',
                                            padding: '1px 16px',
                                            fontSize: '35px',
                                            width: '54px',
                                            height: '54px',
                                            marginTop: '25px',
                                            cursor: 'pointer',
                                            boxShadow: '0px 4px 4px #ddd'
                                        }}
                                    >
                                        <span style={{ color: '#FFFFFF' }}>+</span>
                                    </p>
                                </Grid>
                                {
                                    allSpecificationList && allSpecificationList.length > 0
                                        ?
                                        <Grid item xs={12}>
                                            <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }}>Specification</TableCell>
                                                        <TableCell align="center">Action</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {allSpecificationList.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }} align='center'>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <select
                                                                            value={tempSpecification}
                                                                            onChange={(e) => {
                                                                                setTempSpecification(e.target.value)
                                                                            }}>
                                                                            <option disabled selected>Choose specification</option>
                                                                            {allSpecifications && allSpecifications.map((item, index) => (
                                                                                <option value={item.name} key={index}>{item.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        :
                                                                        item
                                                                }
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                                                        :
                                                                        <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                                                }
                                                                <Delete sx={{ cursor: 'pointer' }} onClick={() => handleDeleteSpecification(index)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        :
                                        ""
                                }
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                    {floorPlans && floorPlans.length > 0
                                        ?
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Photo
                                                    </TableCell>
                                                    <TableCell>
                                                        Unit Type
                                                    </TableCell>
                                                    <TableCell>
                                                        Unit Size
                                                    </TableCell>
                                                    <TableCell>
                                                        Length
                                                    </TableCell>
                                                    <TableCell>
                                                        Breath
                                                    </TableCell>
                                                    <TableCell>
                                                        Price
                                                    </TableCell>
                                                    <TableCell>
                                                        Construction Status
                                                    </TableCell>
                                                    <TableCell>
                                                        Possession Date
                                                    </TableCell>
                                                    <TableCell>
                                                        Specification
                                                    </TableCell>
                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {floorPlans.map((item, index) => (
                                                    <>
                                                        <TableRow key={index}>
                                                            {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                                                            <TableCell>
                                                                <img src={item.floorPlanImage[0]} onClick={() => {
                                                                    // handleEditPackage(index, true);
                                                                    setUpdateImages(
                                                                        item?.floorPlanImage
                                                                    );
                                                                    handleClickOpen();
                                                                }} alt="" style={{ width: '90px', cursor: 'pointer' }} />
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.unitType}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.unitSize ? item?.unitSize : "--"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.plotSizeLength ? item?.plotSizeLength : "--"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.plotSizeBreath ? item?.plotSizeBreath : "--"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item.price}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.constructionStatus ? item?.constructionStatus : "--"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.possessionDate ? item?.possessionDate : "Not applicable"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {item?.specificationList?.length > 0 && item?.specificationList != "" ? item.specificationList?.join(", ") : "--"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    tempFloorPlanIndex === index
                                                                        ?
                                                                        <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                                                        :
                                                                        <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                                                                }
                                                                <Delete sx={{ cursor: 'pointer' }} onClick={() => handleDeleteFloorPlan(index)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        :
                                        ""}
                                </Grid>
                            </Grid>
                        </>
                        :
                        ""
                }

                {/* SCO / Commercial / Commercial Space */}
                {
                    propertyType.toLowerCase() == "commercial" && (subPropertyType.toLowerCase() == "sco" || subPropertyType.toLowerCase() === "commercial space")
                        ?
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <label for="fname">Unit Type*</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Unit Type"
                                    value={unitType}
                                    onChange={(e) => {
                                        setUnitType(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <label for="fname">Unit Size</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Size"
                                            value={unitSize}
                                            onChange={(e) => {
                                                setUnitSize(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <label for="fname">Length</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Length"
                                            value={plotSizeLength}
                                            onChange={(e) => {
                                                setPlotSizeLength(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <label for="fname">Breath</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Breath"
                                            value={plotSizeBreath}
                                            onChange={(e) => {
                                                setPlotSizeBreath(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <label >Price*</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Price"
                                    value={enterPrice}
                                    onChange={(e) => {
                                        setEnterPrice(e.target.value);
                                    }}
                                />
                                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
                            </Grid>
                            <Grid item xs={6}>
                                <label>Construction Status</label>
                                <select value={constructionStatus} onChange={(e) => {
                                    setConstructionStatus(e.target.value)
                                }}>
                                    <option value="" disabled selected>
                                        Choose Construction Status
                                    </option>
                                    <option value="Ready to move">Ready to move</option>
                                    <option value="Under Construction">Under Construction</option>
                                    <option value="New Launch">New Launch</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label>
                                    Possession Date
                                    {constructionStatus == 'Ready to move' && (
                                        <Tooltip title="this field is not enabled for 'ready to move' contruction status">
                                            <Info
                                                sx={{
                                                    fontSize: '20px',
                                                    verticalAlign: 'sub',
                                                    marginLeft: '1px',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </label>
                                <input
                                    value={possessionDate}
                                    onChange={(e) => {
                                        setPossessionDate(e.target.value)
                                    }
                                    }
                                    className="inputBox"
                                    type="date"
                                    placeholder="Possession Date"
                                    disabled={constructionStatus == 'Ready to move'}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <label for="fname">
                                    Furnishing
                                </label>
                                <select
                                    labelId="amenties"
                                    id="demo-multiple-name"
                                    value={furnishing}
                                    sx={{
                                        width: '100%',
                                        background:
                                            'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                    }}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled>Choose an option</option>
                                    {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={4}>
                                <label for="fname">
                                    Quantity
                                </label>
                                <input

                                    required
                                    value={furnishingQuantity}
                                    className="inputBox"
                                    type="text"
                                    placeholder="Enter Furnishing Quantity"
                                    onChange={(e) => {
                                        setFurnishingQuantity(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleCreatePlan2}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {furnishings.length > 0 ? (
                                <Grid item xs={12}>
                                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Furnishing</TableCell>
                                                <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Quantity</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {furnishings.map((item, index) => (
                                                <TableRow key={index} >
                                                    <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        {tempIndex2 === index ? (
                                                            <select
                                                                labelId="amenties"
                                                                id="demo-multiple-name"
                                                                value={tempFurnishingDetail}
                                                                sx={{
                                                                    width: '100%',
                                                                    background:
                                                                        'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                                                }}
                                                                onChange={(e) => setTempFurnishingDetail(e.target.value)}
                                                            >
                                                                <option value="" disabled>Choose an option</option>
                                                                {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                                                    <option value={item.name} key={index}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            item.furnishing
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        {tempIndex2 === index ? (
                                                            <input
                                                                value={tempFurnishingQuantity}
                                                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                                                className="inputBox"
                                                                type="text"
                                                                placeholder="Quantity"
                                                            />
                                                        ) : (
                                                            item.quanitity
                                                        )}
                                                    </TableCell>
                                                    <TableCell sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        <p style={{ textAlign: 'center' }}>
                                                            {tempIndex2 === index ? (
                                                                <Done
                                                                    onClick={() => handleUpdatePlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            ) : (
                                                                <ModeEditOutline
                                                                    onClick={() => handleEditPlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            )}
                                                            <Delete
                                                                onClick={() => handleRemoveFromPlan2(index)}
                                                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                                                            />
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            ) : (
                                ''
                            )}

                            <Grid item xs={11}>
                                <label>Specification</label>
                                <select
                                    onChange={(e) => {
                                        setSpecification(e.target.value)
                                    }}>
                                    <option value={""} disabled selected>Choose specification</option>
                                    {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleAddAllSpecifications}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {
                                allSpecificationList && allSpecificationList.length > 0
                                    ?
                                    <Grid item xs={12}>
                                        <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }}>Specification</TableCell>
                                                    <TableCell align="center">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allSpecificationList.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2', width: '80%' } : { borderRight: '1px solid #e2e2e2', width: '80%' }} align='center'>
                                                            {
                                                                tempIndex === index
                                                                    ?
                                                                    <select
                                                                        value={tempSpecification}
                                                                        onChange={(e) => {
                                                                            setTempSpecification(e.target.value)
                                                                        }}>
                                                                        <option disabled selected>Choose specification</option>
                                                                        {allSpecifications && allSpecifications.map((item, index) => (
                                                                            <option value={item.name} key={index}>{item.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    :
                                                                    item
                                                            }
                                                        </TableCell>
                                                        <TableCell align='center'>
                                                            {
                                                                tempIndex === index
                                                                    ?
                                                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                                                    :
                                                                    <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                                            }
                                                            <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    :
                                    ""
                            }
                            <Grid item xs={12}>
                                {floorPlans && floorPlans.length > 0
                                    ?
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontSize: '8px', p: 0 }}>
                                                    Photo
                                                </TableCell>
                                                <TableCell>
                                                    Unit Type
                                                </TableCell>
                                                <TableCell>
                                                    Unit Size
                                                </TableCell>
                                                <TableCell>
                                                    Length
                                                </TableCell>
                                                <TableCell>
                                                    Breath
                                                </TableCell>
                                                <TableCell>
                                                    Price
                                                </TableCell>
                                                <TableCell>
                                                    Construction Status
                                                </TableCell>
                                                <TableCell>
                                                    Possession Date
                                                </TableCell>
                                                <TableCell>
                                                    Specification
                                                </TableCell>
                                                <TableCell>
                                                    Furnishing
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {floorPlans.map((item, index) => (
                                                <>
                                                    <TableRow key={index}>
                                                        {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                                                        <TableCell>
                                                            <img src={item.floorPlanImage[0]} onClick={() => {
                                                                // handleEditPackage(index, true);
                                                                setUpdateImages(
                                                                    item?.floorPlanImage
                                                                );
                                                                handleClickOpen();
                                                            }} alt="" style={{ width: '90px', cursor: 'pointer' }} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.unitType}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.unitSize}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.plotSizeLength}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.plotSizeBreath}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.price}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.constructionStatus}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.possessionDate ? item.possessionDate : "Not applicable"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {item?.specificationList?.length > 0 ? item?.specificationList?.join(", ") : "--"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {getFurnishingDetailsName1(item.furnishings)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                tempFloorPlanIndex === index
                                                                    ?
                                                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                                                    :
                                                                    <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                                                            }
                                                            <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    :
                                    ""}
                            </Grid>
                        </Grid>
                        :
                        ""
                }

                {/* Commercial / Warehouse */}
                {
                    propertyType.toLowerCase() == "commercial" && subPropertyType.toLowerCase() == "warehouse"
                        ?
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <label for="fname">Unit Type*</label>
                                <input
                                    required
                                    value={unitType}
                                    className="inputBox"
                                    type="text"
                                    placeholder="Unit Type"
                                    onChange={(e) => {
                                        setUnitType(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <label for="fname">Unit Size</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Min"
                                            value={minUnitSize}
                                            onChange={(e) => {
                                                setMinUnitSize(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            style={{ marginTop: '1.7rem' }}
                                            placeholder="Max"
                                            value={maxUnitSize}
                                            onChange={(e) => {
                                                setMaxUnitSize(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <label >Price / Per Sq. FT*</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Price"
                                    value={enterPrice}
                                    onChange={(e) => {
                                        setEnterPrice(e.target.value);
                                    }}
                                />
                                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
                            </Grid>
                            <Grid item xs={6}>
                                <label>Construction Status</label>
                                <select value={constructionStatus} onChange={(e) => {
                                    setConstructionStatus(e.target.value)
                                }}>
                                    <option value="" disabled selected>
                                        Choose Construction Status
                                    </option>
                                    <option value="Ready to move">Ready to move</option>
                                    <option value="Under Construction">Under Construction</option>
                                    <option value="New Launch">New Launch</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label>
                                    Possession Date
                                    {constructionStatus == 'Ready to move' && (
                                        <Tooltip title="this field will not be enabled for under 'ready to move' status">
                                            <Info
                                                sx={{
                                                    fontSize: '20px',
                                                    verticalAlign: 'sub',
                                                    marginLeft: '1px',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </label>
                                <input
                                    value={possessionDate}
                                    onChange={(e) => {
                                        setPossessionDate(e.target.value)
                                    }
                                    }
                                    className="inputBox"
                                    type="date"
                                    placeholder="Possession Date"
                                    disabled={constructionStatus == 'Ready to move'}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <label for="fname">
                                    Furnishing
                                </label>
                                <select
                                    labelId="amenties"
                                    id="demo-multiple-name"
                                    value={furnishing}
                                    sx={{
                                        width: '100%',
                                        background:
                                            'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                    }}
                                    onChange={(e) => setFurnishing(e.target.value)}
                                >
                                    <option disabled value={""} selected>Choose an option</option>
                                    {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={4}>
                                <label for="fname">
                                    Quantity
                                </label>
                                <input

                                    required
                                    value={furnishingQuantity}
                                    className="inputBox"
                                    type="text"
                                    placeholder="Enter Furnishing Quantity"
                                    onChange={(e) => {
                                        setFurnishingQuantity(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleCreatePlan2}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {furnishings.length > 0 ? (
                                <Grid item xs={12}>
                                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Furnishing</TableCell>
                                                <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Quantity</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {furnishings.map((item, index) => (
                                                <TableRow key={index} >
                                                    <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        {tempIndex2 === index ? (
                                                            <select
                                                                labelId="amenties"
                                                                id="demo-multiple-name"
                                                                value={tempFurnishingDetail}
                                                                sx={{
                                                                    width: '100%',
                                                                    background:
                                                                        'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                                                }}
                                                                onChange={(e) => setTempFurnishingDetail(e.target.value)}
                                                            >
                                                                <option value="" disabled>Choose an option</option>
                                                                {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                                                    <option value={item.name} key={index}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            item.furnishing
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        {tempIndex2 === index ? (
                                                            <input
                                                                value={tempFurnishingQuantity}
                                                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                                                className="inputBox"
                                                                type="text"
                                                                placeholder="Quantity"
                                                            />
                                                        ) : (
                                                            item.quanitity
                                                        )}
                                                    </TableCell>
                                                    <TableCell sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        <p style={{ textAlign: 'center' }}>
                                                            {tempIndex2 === index ? (
                                                                <Done
                                                                    onClick={() => handleUpdatePlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            ) : (
                                                                <ModeEditOutline
                                                                    onClick={() => handleEditPlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            )}
                                                            <Delete
                                                                onClick={() => handleRemoveFromPlan2(index)}
                                                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                                                            />
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            ) : (
                                ''
                            )}
                            <Grid item xs={11}>
                                <label>Specification</label>
                                <select
                                    value={specification}
                                    onChange={(e) => {
                                        setSpecification(e.target.value)
                                    }}>
                                    <option disabled selected>Choose specification</option>
                                    {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleAddAllSpecifications}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {
                                allSpecificationList && allSpecificationList.length > 0
                                    ?
                                    <Grid item xs={12}>
                                        <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2', width: '80%' }}>Specification</TableCell>
                                                    <TableCell align="center">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {allSpecificationList.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2', width: '80%' } : { borderRight: '1px solid #e2e2e2', width: '80%' }} align='center'>
                                                            {
                                                                tempIndex === index
                                                                    ?
                                                                    <select
                                                                        value={tempSpecification}
                                                                        onChange={(e) => {
                                                                            setTempSpecification(e.target.value)
                                                                        }}>
                                                                        <option disabled selected>Choose specification</option>
                                                                        {allSpecifications && allSpecifications.map((item, index) => (
                                                                            <option value={item.name} key={index}>{item.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    :
                                                                    item
                                                            }
                                                        </TableCell>
                                                        <TableCell align='center' sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2', width: '80%' } : { borderRight: '1px solid #e2e2e2', width: '80%' }}>
                                                            {
                                                                tempIndex === index
                                                                    ?
                                                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                                                    :
                                                                    <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                                            }
                                                            <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    :
                                    ""
                            }
                            <Grid item xs={12}>
                                {floorPlans && floorPlans.length > 0
                                    ?
                                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Photo
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Unit Type
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Unit Size
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Price
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Construction Status
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Possession Date
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Specification
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2' }}>
                                                    Furnishing
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {floorPlans.map((item, index) => (
                                                <>
                                                    <TableRow key={index}>
                                                        {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            <img src={item.floorPlanImage[0]} onClick={() => {
                                                                // handleEditPackage(index, true);
                                                                setUpdateImages(
                                                                    item?.floorPlanImage
                                                                );
                                                                handleClickOpen();
                                                            }} alt="" style={{ width: '90px', cursor: 'pointer' }} />
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.unitType}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.minUnitSize} - {item.maxUnitSize}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.price}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.constructionStatus}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.possessionDate && item.possessionDate != "" ? item.possessionDate : "Not applicable"}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.specificationList.join(', ')}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {getFurnishingDetailsName1(item.furnishings)}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: 'none' } : {}}>
                                                            {
                                                                tempFloorPlanIndex === index
                                                                    ?
                                                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                                                    :
                                                                    <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                                                            }
                                                            <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    :
                                    ""}
                            </Grid>
                        </Grid>
                        :
                        ""
                }

                {/* Flats & Apartment (Builder Floor/ Duplex House) and Farmhouse / Villa */}
                {
                    propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "builder floor" || subPropertyType.toLowerCase() == "duplex house") || propertyType.toLowerCase() == "farmhouse / villa"
                        ?
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <label for="fname">Plot Size (sq. Ft.)</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Size"
                                            value={plotSize}
                                            onChange={(e) => {
                                                setPlotSize(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <label for="fname">Length (Ft.)</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Length"
                                            value={plotSizeLength}
                                            onChange={(e) => {
                                                setPlotSizeLength(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <label for="fname">Breath (Ft.)</label>
                                        <input
                                            className="inputBox"
                                            type="text"
                                            placeholder="Breath"
                                            value={plotSizeBreath}
                                            onChange={(e) => {
                                                setPlotSizeBreath(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <label for="fname">
                                    Choose Unit Type*
                                </label>
                                <select
                                    value={unitType}
                                    onChange={(e) => {
                                        setUnitType(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected>
                                        Choose
                                    </option>
                                    <>
                                        <option value="1RK">1RK</option>
                                        <option value="1BHK">1BHK</option>
                                        <option value="2BHK">2BHK</option>
                                        <option value="3BHK">3BHK</option>
                                        <option value="4BHK">4BHK</option>
                                        <option value="5BHK">5BHK</option>
                                        <option value="5BHK+">5BHK+</option>
                                    </>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label for="fname">Carpet Area</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Carpet Area"
                                    value={carpetSize}
                                    onChange={(e) => {
                                        setCarpetSize(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label >Price*</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Price"
                                    value={enterPrice}
                                    onChange={(e) => {
                                        setEnterPrice(e.target.value);
                                    }}
                                />
                                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
                            </Grid>
                            <Grid item xs={6}>
                                <label>Construction Status</label>
                                <select value={constructionStatus} onChange={(e) => {
                                    setConstructionStatus(e.target.value)
                                }}>
                                    <option value="" disabled selected>
                                        Choose Construction Status
                                    </option>
                                    <option value="Ready to move">Ready to move</option>
                                    <option value="Under Construction">Under Construction</option>
                                    <option value="New Launch">New Launch</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label>
                                    Possession Date
                                    {constructionStatus == 'Ready to move' && (
                                        <Tooltip title="this field will only be disabled for 'ready to move' status">
                                            <Info
                                                sx={{
                                                    fontSize: '20px',
                                                    verticalAlign: 'sub',
                                                    marginLeft: '1px',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </label>
                                <input
                                    value={possessionDate}
                                    onChange={(e) => {
                                        setPossessionDate(e.target.value)
                                    }
                                    }
                                    className="inputBox"
                                    type="date"
                                    placeholder="Possession Date"
                                    disabled={constructionStatus == 'Ready to move'}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <label for="fname">
                                    Furnishing
                                </label>
                                <select
                                    labelId="amenties"
                                    id="demo-multiple-name"
                                    value={furnishing}
                                    sx={{
                                        width: '100%',
                                        background:
                                            'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                    }}
                                    onChange={(e) => setFurnishing(e.target.value)}
                                >
                                    <option disabled selected value="">Choose an option</option>
                                    {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={4}>
                                <label for="fname">
                                    Quantity
                                </label>
                                <input

                                    required
                                    value={furnishingQuantity}
                                    className="inputBox"
                                    type="text"
                                    placeholder="Enter Furnishing Quantity"
                                    onChange={(e) => {
                                        setFurnishingQuantity(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleCreatePlan2}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {furnishings.length > 0 ? (
                                <Grid xs={12} item>
                                    <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Furnishing</TableCell>
                                                <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Quantity</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {furnishings.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell Cell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        {tempIndex2 === index ? (
                                                            <select
                                                                labelId="amenties"
                                                                id="demo-multiple-name"
                                                                value={tempFurnishingDetail}
                                                                sx={{
                                                                    width: '100%',
                                                                    background:
                                                                        'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                                                }}
                                                                onChange={(e) =>
                                                                    setTempFurnishingDetail(e.target.value)
                                                                }
                                                            >
                                                                <option disabled selected value="">Choose an option</option>
                                                                {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                                                    <option value={item._id} key={index}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            getFurnishingDetailName(item.furnishing)
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                        {tempIndex2 === index ? (
                                                            <input
                                                                value={tempFurnishingQuantity}
                                                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                                                className="inputBox"
                                                                type="text"
                                                                placeholder="Quantity"
                                                            />
                                                        ) : (
                                                            item.quanitity
                                                        )}
                                                    </TableCell>
                                                    <TableCell sx={index == furnishings.length - 1 ? { borderBottom: '0px' } : {}}>
                                                        <p style={{ textAlign: 'center' }}>
                                                            {tempIndex2 === index ? (
                                                                <Done
                                                                    onClick={() => handleUpdatePlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            ) : (
                                                                <ModeEditOutline
                                                                    onClick={() => handleEditPlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            )}
                                                            <Delete
                                                                onClick={() => handleRemoveFromPlan2(index)}
                                                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                                                            />
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            ) : (
                                ''
                            )}
                            <Grid item xs={6}>
                                <label>Specification</label>
                                <select
                                    onChange={(e) => {
                                        setSpecification(e.target.value)
                                    }}>
                                    <option disabled selected value="">Choose specification</option>
                                    {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <label>Length</label>
                                        <input
                                            type='text'
                                            className='inputBox'
                                            value={specificationLength}
                                            placeholder='Length'
                                            onChange={(e) => setSpecificationLength(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label>Breath</label>
                                        <input
                                            type='text'
                                            className='inputBox'
                                            value={specificationBreath}
                                            placeholder='Breath'
                                            onChange={(e) => setSpecificationBreath(e.target.value)}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleAddAllSpecifications}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {
                                allSpecificationList && allSpecificationList.length > 0
                                    ?
                                    <Grid item xs={12} sx={{ width: '100%', overflow: 'hidden' }}>
                                        <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Specification</TableCell>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Length</TableCell>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Breath</TableCell>
                                                    <TableCell align="center">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    allSpecificationList.map((item, index) => (
                                                        <TableRow>
                                                            <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }} >
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <select
                                                                            value={tempSpecification}
                                                                            onChange={(e) => {
                                                                                setTempSpecification(e.target.value)
                                                                            }}>
                                                                            <option disabled selected>Choose specification</option>
                                                                            {allSpecifications && allSpecifications.map((item, index) => (
                                                                                <option value={item._id} key={index}>{item.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        : getSpecificationName(item.specification)
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <input
                                                                            type='text'
                                                                            className='inputBox'
                                                                            value={tempSpecificationHeight}
                                                                            placeholder='Breath'
                                                                            onChange={(e) => setTempSpecificationHeight(e.target.value)}
                                                                        />
                                                                        : item.specificationLength
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center" sx={index == furnishings.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <input
                                                                            type='text'
                                                                            className='inputBox'
                                                                            value={tempSpecificationWidth}
                                                                            placeholder='Breath'
                                                                            onChange={(e) => setTempSpecificationWidth(e.target.value)}
                                                                        />
                                                                        :
                                                                        item.specificationBreath}
                                                            </TableCell>
                                                            <TableCell align='center' sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                                                        :
                                                                        <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                                                }
                                                                <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    :
                                    ""
                            }

                            {floorPlans && floorPlans.length > 0
                                ?
                                <Grid item xs={12}>
                                    <Table stickyHeader aria-label="sticky table" sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', p: 0.5, fontSize: "12px" }}>
                                                    Photo
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Unit Type
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Carpet Area
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Plot Size
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Price
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Construction Status
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Possession Date
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Specification
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Furnishing
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {floorPlans.map((item, index) => (
                                                <>
                                                    <TableRow key={index}>
                                                        {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            <img src={item.floorPlanImage[0]} onClick={() => {
                                                                // handleEditPackage(index, true);
                                                                setUpdateImages(
                                                                    item?.floorPlanImage
                                                                );
                                                                handleClickOpen();
                                                            }} alt="" style={{ width: '90px', cursor: 'pointer' }} />
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.unitType}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.carpetSize}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            Size: {item.plotSize}
                                                            <br />
                                                            Length: {item.plotSizeLength}
                                                            <br />
                                                            Breath: {item.plotSizeBreath}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.price}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.constructionStatus}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.possessionDate ? item.possessionDate : "Not applicable"}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {getSpecificationsNameForFlatsAndFarmHouse(item.specificationList)}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {getFurnishingDetailsName1(item.furnishings)}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: 'none' } : {}}>
                                                            {
                                                                tempFloorPlanIndex === index
                                                                    ?
                                                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                                                    :
                                                                    <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                                                            }
                                                            <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                :
                                ""
                            }
                        </Grid>
                        :
                        ""
                }


                {/* Flats & Apartment (high rise/ low rise) */}
                {
                    propertyType.toLowerCase() == "flats / apartment" && (subPropertyType.toLowerCase() == "high rise" || subPropertyType.toLowerCase() == "low rise")
                        ?
                        <Grid container spacing={4}>
                            <Grid item xs={6}>
                                <label for="fname">
                                    Choose Unit Type*
                                </label>
                                <select
                                    value={unitType}
                                    disabled={
                                        propertyType === 'Commercial'
                                    }
                                    onChange={(e) => {
                                        setUnitType(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected>
                                        Choose
                                    </option>
                                    <>
                                        <option value="1RK">1RK</option>
                                        <option value="1BHK">1BHK</option>
                                        <option value="2BHK">2BHK</option>
                                        <option value="3BHK">3BHK</option>
                                        <option value="4BHK">4BHK</option>
                                        <option value="5BHK">5BHK</option>
                                        <option value="5BHK+">5BHK+</option>
                                    </>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label for="fname">Carpet Area</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Carpet Area"
                                    value={carpetSize}
                                    onChange={(e) => {
                                        setCarpetSize(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <label >Price*</label>
                                <input
                                    required
                                    className="inputBox"
                                    type="text"
                                    placeholder="Price"
                                    value={enterPrice}
                                    onChange={(e) => {
                                        setEnterPrice(e.target.value);
                                    }}
                                />
                                <span style={{ fontSize: '14px', color: '#8D98AA' }}>{numberToWords(enterPrice)}</span>
                            </Grid>
                            <Grid item xs={6}>
                                <label>Construction Status</label>
                                <select value={constructionStatus} onChange={(e) => {
                                    setConstructionStatus(e.target.value)
                                }}>
                                    <option value="" disabled>
                                        Choose Construction Status
                                    </option>
                                    <option value="Ready to move">Ready to move</option>
                                    <option value="Under Construction">Under Construction</option>
                                    <option value="New Launch">New Launch</option>
                                </select>
                            </Grid>
                            <Grid item xs={6}>
                                <label>
                                    Possession Date
                                    {constructionStatus == 'Ready to move' && (
                                        <Tooltip title="this field will be disabled for 'ready to move' status">
                                            <Info
                                                sx={{
                                                    fontSize: '20px',
                                                    verticalAlign: 'sub',
                                                    marginLeft: '1px',
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </label>
                                <input
                                    value={possessionDate}
                                    onChange={(e) => {
                                        setPossessionDate(e.target.value)
                                    }
                                    }
                                    className="inputBox"
                                    type="date"
                                    placeholder="Possession Date"
                                    disabled={constructionStatus == 'Ready to move'}
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <label for="fname">
                                    Furnishing
                                </label>
                                <select
                                    labelId="amenties"
                                    id="demo-multiple-name"
                                    value={furnishing}
                                    sx={{
                                        width: '100%',
                                        background:
                                            'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                    }}
                                    onChange={(e) => setFurnishing(e.target.value)}
                                >
                                    <option disabled selected value="">Choose an option</option>
                                    {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={4}>
                                <label for="fname">
                                    Quantity
                                </label>
                                <input

                                    required
                                    value={furnishingQuantity}
                                    className="inputBox"
                                    type="text"
                                    placeholder="Enter Furnishing Quantity"
                                    onChange={(e) => {
                                        setFurnishingQuantity(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleCreatePlan2}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {furnishings.length > 0 ? (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Furnishing</TableCell>
                                                <TableCell align="center">Quantity</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {furnishings.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell Cell align="center">
                                                        {tempIndex2 === index ? (
                                                            <select
                                                                labelId="amenties"
                                                                id="demo-multiple-name"
                                                                value={tempFurnishingDetail}
                                                                sx={{
                                                                    width: '100%',
                                                                    background:
                                                                        'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                                                }}
                                                                onChange={(e) => setTempFurnishingDetail(e.target.value)}
                                                            >
                                                                <option disabled selected value="">Choose an option</option>
                                                                {allFurnishingPlans && allFurnishingPlans.length > 0 && allFurnishingPlans.map((item, index) => (
                                                                    <option value={item.name} key={index}>{item.name}</option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            item.furnishing
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tempIndex2 === index ? (
                                                            <input
                                                                value={tempFurnishingQuantity}
                                                                onChange={(e) => setTempFurnishingQuantity(e.target.value)}
                                                                className="inputBox"
                                                                type="text"
                                                                placeholder="Quantity"
                                                            />
                                                        ) : (
                                                            item.quanitity
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <p style={{ textAlign: 'center' }}>
                                                            {tempIndex2 === index ? (
                                                                <Done
                                                                    onClick={() => handleUpdatePlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            ) : (
                                                                <ModeEditOutline
                                                                    onClick={() => handleEditPlan2(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            )}
                                                            <Delete
                                                                onClick={() => handleRemoveFromPlan2(index)}
                                                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                                                            />
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                ''
                            )}
                            <Grid item xs={6}>
                                <label>Specification</label>
                                <select
                                    onChange={(e) => {
                                        setSpecification(e.target.value)
                                    }}>
                                    <option disabled selected value={""}>Choose specification</option>
                                    {allSpecifications && allSpecifications.map((item, index) => (
                                        <option value={item.name} key={index}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <label>Length</label>
                                        <input
                                            type='text'
                                            className='inputBox'
                                            value={specificationLength}
                                            placeholder='Length'
                                            onChange={(e) => setSpecificationLength(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <label>Breath</label>
                                        <input
                                            type='text'
                                            className='inputBox'
                                            value={specificationBreath}
                                            placeholder='Breath'
                                            onChange={(e) => setSpecificationBreath(e.target.value)}
                                        />

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                <p
                                    onClick={handleAddAllSpecifications}
                                    style={{
                                        borderRadius: '50%',
                                        background: '#278FD9',
                                        fontWeight: '600',
                                        padding: '1px 16px',
                                        fontSize: '35px',
                                        width: '54px',
                                        height: '54px',
                                        marginTop: '25px',
                                        cursor: 'pointer',
                                        boxShadow: '0px 4px 4px #ddd'
                                    }}
                                >
                                    <span style={{ color: '#FFFFFF' }}>+</span>
                                </p>
                            </Grid>
                            {
                                allSpecificationList && allSpecificationList.length > 0
                                    ?
                                    <Grid item xs={12} sx={{ width: '100%', overflow: 'hidden' }}>
                                        <Table sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Specification</TableCell>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Length</TableCell>
                                                    <TableCell align="center" sx={{ borderRight: '1px solid #e2e2e2' }}>Breath</TableCell>
                                                    <TableCell align="center">Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    allSpecificationList.map((item, index) => (
                                                        <TableRow>
                                                            <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }} >
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <select
                                                                            value={tempSpecification}
                                                                            onChange={(e) => {
                                                                                setTempSpecification(e.target.value)
                                                                            }}>
                                                                            <option disabled selected value={""}>Choose specification</option>
                                                                            {allSpecifications && allSpecifications.map((item, index) => (
                                                                                <option value={item.name} key={index}>{item.name}</option>
                                                                            ))}
                                                                        </select>
                                                                        : item.specification
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <input
                                                                            type='text'
                                                                            className='inputBox'
                                                                            value={tempSpecificationHeight}
                                                                            placeholder='Breath'
                                                                            onChange={(e) => setTempSpecificationHeight(e.target.value)}
                                                                        />
                                                                        : item.specificationLength
                                                                }
                                                            </TableCell>
                                                            <TableCell align="center" sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <input
                                                                            type='text'
                                                                            className='inputBox'
                                                                            value={tempSpecificationWidth}
                                                                            placeholder='Breath'
                                                                            onChange={(e) => setTempSpecificationWidth(e.target.value)}
                                                                        />
                                                                        :
                                                                        item.specificationBreath}
                                                            </TableCell>
                                                            <TableCell align='center' sx={index == allSpecificationList.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                                {
                                                                    tempIndex === index
                                                                        ?
                                                                        <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateSpecification(index)} />
                                                                        :
                                                                        <ModeEditOutline sx={{ cursor: 'pointer' }} onClick={() => handleEditSpecification(index)} />
                                                                }
                                                                <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteSpecification(index)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    :
                                    ""
                            }
                            {floorPlans && floorPlans.length > 0
                                ?
                                <Grid item xs={12}>
                                    <Table stickyHeader aria-label="sticky table" sx={{ border: '1px solid #e2e2e2', borderCollapse: 'unset', borderRadius: '11px' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Photo
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Unit Type
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Carpet Area
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Price
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Construction Status
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Possession Date
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Specification
                                                </TableCell>
                                                <TableCell sx={{ borderRight: '1px solid #e2e2e2', fontSize: '12px', px: 0.5 }}>
                                                    Furnishing
                                                </TableCell>
                                                <TableCell>
                                                    Action
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {floorPlans.map((item, index) => (
                                                <>
                                                    <TableRow key={index}>
                                                        {/* floorPlanImage, unitType, unitSize, plotSizeBreath, plotSizeLength, price: enterPrice, constructionStatus, possessionDate, specificationList */}
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            <img onClick={() => {
                                                                // handleEditPackage(index, true);
                                                                setUpdateImages(
                                                                    item?.floorPlanImage
                                                                );
                                                                handleClickOpen();
                                                            }} src={item.floorPlanImage[0]} alt="" style={{ width: '90px', cursor: 'pointer' }} />
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.unitType}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.carpetSize}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.price}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.constructionStatus}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {item.possessionDate ? item.possessionDate : "Not applicable"}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {getSpecificationsNameForFlatsAndFarmHouse(item.specificationList)}
                                                        </TableCell>
                                                        <TableCell sx={index == floorPlans.length - 1 ? { borderBottom: '0px', borderRight: '1px solid #e2e2e2' } : { borderRight: '1px solid #e2e2e2' }}>
                                                            {getFurnishingDetailsName1(item.furnishings)}
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none' }}>
                                                            {
                                                                tempFloorPlanIndex === index
                                                                    ?
                                                                    <Check sx={{ cursor: 'pointer' }} onClick={() => handleUpdateFloorPlan(index)} />
                                                                    :
                                                                    <Draw sx={{ cursor: 'pointer' }} onClick={() => handleEditFloorPlan(index)} />
                                                            }
                                                            <Delete sx={{ cursor: 'pointer', color: 'red' }} onClick={() => handleDeleteFloorPlan(index)} />
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                                :
                                ""
                            }
                        </Grid>

                        :
                        ""
                }

                <br />
                <Button
                    onClick={handleSubmit}
                    style={{
                        width: '224px',
                        height: '52px',
                        borderRadius: '8.62px',
                        background: '#278FD9',
                        color: '#FFFFFF',
                        fontWeight: '700',
                        marginBottom: '33px',
                    }}
                >
                    Next
                </Button>
                <Button
                    onClick={handleAddFloorPlan}
                    style={{
                        width: '224px',
                        height: '52px',
                        marginBottom: '33px',
                        marginLeft: '10px',
                        borderRadius: '8.62px'
                    }}
                    variant='outlined'
                    disabled={temp1}
                >
                    Add
                </Button>
                <Button
                    onClick={() => navigate('/update-property/' + propertyID)}
                    style={{
                        width: '224px',
                        height: '52px',
                        borderRadius: '8.62px',
                        background: '#E2E2E2',
                        color: '#676767',
                        fontWeight: '700',
                        marginBottom: '33px',
                        marginLeft: '10px'
                    }}
                >
                    Back
                </Button>
                {/* images modal  */}
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    size="lg"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Images"} </DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex" }}>
                            {updateImages.length > 0 &&
                                updateImages.map((item, index) => (

                                    <a href={item} target="_blank" download>
                                        <Box
                                            mx={"8px"}
                                            height={"140px"}
                                            borderRadius={"16px"}
                                            overflow={"hidden"}
                                            sx={{ aspectRatio: "1/1", position: "relative" }}
                                        >
                                            <img
                                                src={item}
                                                alt="profile"
                                                className="img-fluid"
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    objectFit: "contain",
                                                    backgroundColor: "grey",
                                                    zIndex: 1,
                                                }}
                                            />

                                            {/* <IconButton
                    aria-label="clear_image"
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      right: "5px",
                      top: "5px",
                      background: "#278FD9aa",
                      height: "25px",
                      width: "25px",
                    }}
                    onClick={() => {
                      let temp = updateImages;
                      temp.splice(index, 1);
                      setUpdateImages([...temp]);
                    }}
                  >
                    <ClearIcon color={"white"} sx={{ fontSize: ".8rem" }} />
                  </IconButton> */}

                                        </Box>
                                    </a>
                                ))}
                            {/* <Stack direction="row" spacing={2}>
              <Card sx={uplodeImageBtn}>
                <CardActionArea className="button">
                  <img
                    src={"/Images/uploadImg.png"}
                    alt="profile"
                    className="img-fluid"
                  />

                  <input
                    type="file"
                    name="profileImage"
                    id="profileImageInput"
                    onChange={(e) => {
                      setUpdateImages((old) => {
                        return [
                          ...old,
                          {
                            imageUrl: URL.createObjectURL(e.target.files[0]),
                            imageObject: e.target.files[0],
                          },
                        ];
                      });
                    }}
                  />
                </CardActionArea>
              </Card>
            </Stack> */}
                        </Box>
                    </DialogContent>
                </Dialog>
            </Box>
        </>
    );
};
export default UpdateFloorPlanForm;