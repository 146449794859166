import React, { useState } from 'react';

import Layout from '../../../assets/Layout';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../../assets/MainButton';
import { sectionBarBtn } from '../../../Style/stylesheet';

import {
    Checkbox,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    Button,
    Grid,
    Rating,
} from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import ButtonController from '../../../assets/ButtonController';
import SearchFilter from '../../../assets/SearchFilter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Profile from '../Profile';

export default function BuilderAllVisits() {
    const navigate = useNavigate();
    const [sectionBarActive, setSectionBarActive] = useState('Visits')
    const [addDSAAction, setAddDSAAction] = useState(false);
    const [profileAction, setProfileAction] = useState(false);

    const headData = [
        'VISIT ID',
        'BROKER NAME',
        'VISIT DATE',
        'STATUS',
        'CLIENT NAME',
        'RATING',
    ];
    return (
        <Layout path="builder-management">
            <Box>
                <Grid container my={2} >
                    <Grid item md={1}>
                        <IconButton aria-label="left-arrow">
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid>
                    <Grid item md={11}>
                        <Stack
                            direction={'row'}
                            sx={{ p: 1, bgcolor: '#fff', borderRadius: 10 }}
                            spacing={3}
                        >

                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === 'Visits' && 'active'}
                                onClick={() => navigate('/')}
                            >
                                All Visits
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === 'promoted' && 'active'}
                                onClick={() => navigate('/')}
                            >
                                Promoted Visits
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." />
                    </Box>
                </SearchFilter>
                <Box sx={{ mb: 4 }}></Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...Array(10)].map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        {/* <TableCell>
                                            <Link
                                                onClick={() => setProfileAction(true)}
                                                className="link"
                                            >
                                                Ann Culhane
                                            </Link>
                                        </TableCell> */}

                                        <TableCell>Sector 28</TableCell>
                                        <TableCell>46676732989</TableCell>{' '}
                                        <TableCell>₹ 3.94 Cr</TableCell>
                                        <TableCell>₹ 3.94 Cr</TableCell>
                                        <TableCell>₹ 3.94 Cr</TableCell>
                                        <TableCell><Rating readOnly /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: 'mute.dark',
                        borderRadius: 1,
                        color: 'dark.text',
                        p: 2,
                        mt: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography>1 - 10 of 97</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ' center',
                            alignItems: 'center',
                        }}
                    >
                        <Box>
                            <Typography component="p">Rows per page:</Typography>
                        </Box>
                        <Box>
                            <select
                                style={{
                                    fontSize: '0.8rem',
                                    height: '35px',
                                    padding: '0',
                                    background: 'transparent',
                                }}
                            >
                                <option value="10" selected>
                                    10
                                </option>
                                <option value="10" selected>
                                    20
                                </option>
                                <option value="10" selected>
                                    30
                                </option>
                            </select>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box>
                                    <IconButton
                                        sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={'span'}>
                                        1/10
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'mute.main',
                                            bgcolor: 'white.main',
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Modals */}
            {/* <Profile
                profileAction={profileAction}
                setProfileAction={setProfileAction}
            /> */}
            {/* <AddDSA addDSAAction={addDSAAction} setAddDSAAction={setAddDSAAction} /> */}
            {/* Modals */}
        </Layout>
    );
}
