import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Icon,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { UpdateBrokerPreferences, addNewBroker, getAllBuilders, getAllPropertyCategory, getAllBuilders1 } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import regex from '../../utils/regex';
import CircularProgress from '@mui/material/CircularProgress';
import { selectBar, selectBarBtn } from '../../Style/stylesheet';
import { Check } from '@mui/icons-material';
import CustomAutoComplete from '../../assets/CustomAutoComplete';
import CustomAutoComplete1 from '../../assets/CustomAutoComplete1';

export default function Preferences({ brokerId, setAddBrokerAction, setStep }) {
  const [locality, setLocality] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [experience, setExperiance] = useState("");
  const [top3Builders, setTop3Builders] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [propertyCategories, setPropertyCategories] = useState([]);
  const dispatch = useDispatch()
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
  };

  const handleSubmit = () => {
    if (!locality || !propertyType || !experience || !top3Builders) {
      enqueueSnackbar("Please add all the required fields", { variant: 'error' });
    }
    let payload = {
      id: brokerId,
      locality,
      propertyType,
      experience,
      top3Builders
    }
    dispatch(
      UpdateBrokerPreferences(payload, (data) => {
        enqueueSnackbar("Broker Added Successfully!", { variant: 'success' })
        setAddBrokerAction(false);
        setLocality([]);
        setPropertyType([]);
        setExperiance("");
        setTop3Builders([]);
        setBuilders([]);
        setStep(1);
      }
      )
    )

  };


  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedValues(value);
  };

  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if (searchQuery) {
      dispatch(getAllBuilders1({ searchQuery }, (data) => {
        setBuilders(data.map(x => x.builderName));
      }, (err) => {
        console.log(err)
      }))
    } else {
      setBuilders([]);
    }
  }, [searchQuery]);

  useEffect(() => {
    dispatch(
      getAllPropertyCategory({}, (data) => {
        console.log("properties", data);
        setPropertyCategories(data.data);
      })
    );
  }, [dispatch]);

  return (
    <>
      <div>
        <Typography variant="title" component="h3">
          Preferences
        </Typography>
      </div>
      <Box component={'from'} flexGrow={1}>
        <Divider sx={{ my: 4 }} />
        <Box>
          <Grid container spacing={3}>
            <Grid item md={6} flexGrow={1} flexDirection={'column'}>
              <CustomAutoComplete1 top3Builders={locality} setTop3Builders={setLocality} />
            </Grid>
            <Grid item md={6} flexGrow={1} flexDirection={'column'}>
              <FormControl>
                <InputStyle name="Property Type*">
                  <Select
                    placeholder="Enter Locality"
                    labelId="multi-select-label"
                    id="multi-select"
                    multiple
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}

                    sx={{
                      height: '2.5rem',
                      width: '25rem',
                      backgroundColor: '#EDF2F7',
                      '& .MuiSelect-select.MuiSelect-selectMultiple': {
                        height: 'auto',
                        minHeight: '36px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      '& .MuiSelect-icon': {
                        top: 'calc(50% - 12px)',
                      },
                    }}
                  >
                    {propertyCategories.map((item, index) => (
                      <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </InputStyle>
              </FormControl>
            </Grid>

            {/* Email input */}
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="Experience* (In Years)"   >
                <input
                  style={{ backgroundColor: '#fff' }}
                  type="text"
                  placeholder="Enter your experience"
                  value={experience}
                  onChange={(e) => setExperiance(e.target.value)}
                />
              </InputStyle>
            </Grid>

            <Grid item md={6} flexGrow={1} flexDirection={'column'}>
              <CustomAutoComplete searchQuery={searchQuery} setSearchQuery={setSearchQuery} builders={builders} top3Builders={top3Builders} setTop3Builders={setTop3Builders} />
            </Grid>

            <Grid item md={12} xs={12} flexGrow={1}>
              <Stack direction={'row'} spacing={3}>
                <Button variant="contained" sx={ButtonStyle} onClick={handleSubmit}>
                  Next
                </Button>

                <Button variant="outlined" sx={ButtonStyle}>
                  Reset
                </Button>
              </Stack>
            </Grid>

          </Grid>
        </Box>
      </Box>
    </>
  );
}
