import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputStyle from "../../assets/InputStyle";
import { useDispatch } from "react-redux";
import {
  createInvoice,
  getBrokerDetails,
  updateClaimByAdmin,
} from "../../actions";
import { enqueueSnackbar } from "notistack";

export default function ClaimsApproveForm({
  claimId,
  claimStatus,
  approveAction,
  setApproveAction,
  property = {},
  broker = {},
  amount,
  claimType
}) {
  const dispatch = useDispatch();
  const [unitNumber, setUnitNumber] = useState("");
  const [amount1, setAmount1] = useState("");
  const [gstValue, setGstValue] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [terms, setTerms] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let totalvalue = parseInt(amount1) + parseInt(amount1) * (gstValue / 100);
    let payload = {
      id: claimId,
      claimStatus: claimStatus,
      unitNumber: unitNumber,
      amount: amount1,
      gstValue,
      totalAmount: totalvalue.toString(),
      terms,
    };
    dispatch(
      updateClaimByAdmin(
        payload,
        (data) => {
          if (data.status == 200) {
            const payload1 = {
              paidTo: "admin",
              status: "pending",
              invoiceAmount: totalvalue.toString(),
              brokerId: broker,
              builderId: property?.builderId,
              claimId,
            };
            if (claimType != "visit") {
            }
            dispatch(
              createInvoice(
                payload1,
                (data) => {
                  enqueueSnackbar(data?.data?.message, { variant: "success" });
                  window.location.reload();
                  setLoading(false);
                  closeHandler();
                },
                (err) => {
                  console.log(err);
                  const message = err?.response?.data
                    ? err?.response?.data
                    : "Error Occured! can not create invoice";
                  enqueueSnackbar(message?.toString(), { variant: "error" });
                  setLoading(false);
                }
              )
            );
          }
        },
        (err) => {
          console.log(err);
          enqueueSnackbar("Error Occured! please try again", {
            variant: "error",
          });
          setLoading(false);
        }
      )
    );
  };

  const closeHandler = () => setApproveAction(false);
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: "100%",
    marginTop: 4,
  };
  return (
    <Dialog open={approveAction} onClose={closeHandler} maxWidth="xs" fullWidth>
      <DialogContent sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Approve
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: "2px solid #DFDFE6" }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </div>
        </Stack>
        <Box component={"from"}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <InputStyle name="Unit Number">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Unit Number"
                    value={unitNumber}
                    onChange={(e) => setUnitNumber(e.target.value)}
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <InputStyle name="Amount">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Amount"
                    value={amount1}
                    onChange={(e) => setAmount1(e.target.value)}
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <InputStyle name="GST Value (%)">
                  <input
                    type="text"
                    name=""
                    maxLength={2}
                    id=""
                    placeholder="Enter GST Value"
                    value={gstValue}
                    onChange={(e) => setGstValue(e.target.value)}
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <InputStyle name="Total Amount">
                  <input
                    type="text"
                    disabled
                    name=""
                    id=""
                    placeholder="Amount + GST Value"
                    value={
                      amount1
                        ? parseInt(amount1) +
                          parseInt(amount1) * (gstValue.substring(0, 2) / 100)
                        : "Amount + GST value"
                    }
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <InputStyle name="Terms (Payment Days)">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Terms "
                    value={terms}
                    onChange={(e) => setTerms(e.target.value)}
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <Button
                  variant="contained"
                  sx={ButtonStyle}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

// const [approveAction, setApproveAction] = useState(false);
// <Box onClick={() => setApproveAction(true)}>

// <ClaimsApprove
//         approveAction={approveAction}
//         setApproveAction={setApproveAction}
//       />
