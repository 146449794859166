import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../assets/Layout";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import {
  addAmenties,
  deleteAmenities,
  getAllAmenties,
  updateAmenities,
  uploadDocToS3,
} from "../../actions";
import TableStyle from "../../assets/TableStyle";
import { Check, DeleteForever, Download, Draw } from "@mui/icons-material";
import UploadDataBox from "../../assets/UploadDataBox";
import UploadData from "../../assets/UploadData";
import UploadDataCard from "../../assets/UploadDataCard";
import { enqueueSnackbar } from "notistack";

export default function Amenity() {
  const [amenityName, setAmenityName] = useState("");
  const [amenityQuantity, setAmenityQuantity] = useState("");
  const [icon, setIcon] = useState([]);
  const [iconPicView, setIconPicView] = useState([]);
  const [allAmenties, setAllAmenties] = useState([]);
  const [tempIndex, setTempIndex] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempQuantity, setTempQuantity] = useState("");
  const [tempIcon, setTempIcon] = useState([]);
  const [tempIconPicView, setTempIconPicView] = useState([]);
  const dispatch = useDispatch();

  const loadAllAmenties = useCallback(() => {
    dispatch(
      getAllAmenties({}, (data) => {
        setAllAmenties(data);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllAmenties();
  }, [loadAllAmenties]);

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setIcon([file]);
    setIconPicView([URL.createObjectURL(file)]);
    // if (file) {
    //     const formData = new FormData();
    //     formData.append('documents', file);
    //     dispatch(uploadDocToS3(formData, (data) => {
    //         console.log("Token", data)
    //     }))
    // }
  };

  const handleUploadImage1 = (e) => {
    const file = e.target.files[0];
    setTempIcon([file]);
    setTempIconPicView([URL.createObjectURL(file)]);
  };

  const handleEdit = (index) => {
    setTempIndex(index);
    setTempIcon([allAmenties[index].image]);
    setTempIconPicView([allAmenties[index].image]);
    setTempName(allAmenties[index].name);
    setTempQuantity(allAmenties[index].quantity);
  };

  const handleUpdate = (index) => {
    let val = allAmenties;
    val[index] = {
      ...val[index],
      name: tempName,
      quantity: tempQuantity,
      icon: tempIcon,
    };
    let formData = new FormData();
    formData.append("id", val[index]._id);
    formData.append("image", tempIcon);
    formData.append("name", tempName);
    formData.append("quantity", tempQuantity);
    dispatch(
      updateAmenities(formData, (data) => {
        enqueueSnackbar("Data updated successfully", { variant: "success" });
        setTempIndex("");
      })
    );
  };

  const handleDelete = (index) => {
    dispatch(
      deleteAmenities(allAmenties[index]._id, {}, (data) => {
        enqueueSnackbar("Data delete successfully", { variant: "success" });
        loadAllAmenties();
      })
    );
  };

  function handleSubmit(event) {
    event.preventDefault();

    if (icon.length === 0 || !amenityName || !amenityQuantity) {
      enqueueSnackbar("Please add all the fields", { variant: "error" });
    } else {
      let formData = new FormData();
      formData.append("image", icon[0]);
      formData.append("name", amenityName);
      formData.append("quantity", amenityQuantity);

      dispatch(
        addAmenties(formData, (data) => {
          enqueueSnackbar("Data added successfully", { variant: "success" });
          setIcon([]);
          setIconPicView([]);
          setAmenityName("");
          setAmenityQuantity("");
          loadAllAmenties();
        })
      );
    }
  }

  return (
    <Layout>
      <Paper>
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "28px",
                  color: "#000000",
                  marginBottom: "48px",
                }}
              >
                Amenity
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <label>Amenity Name</label>
                    <input
                      onChange={(e) => setAmenityName(e.target.value)}
                      value={amenityName}
                      className="inputBox"
                      type="text"
                      placeholder="Amenity Name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <label>Amenity Quantity</label>
                    <input
                      onChange={(e) => setAmenityQuantity(e.target.value)}
                      value={amenityQuantity}
                      className="inputBox"
                      type="text"
                      placeholder="Amenity Quantity"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ py: 2 }}>
                      <UploadDataBox>
                        <UploadData name={"Amenity Icon"} mt={0}>
                          <UploadDataCard>
                            <img
                              src={
                                iconPicView && iconPicView.length > 0
                                  ? iconPicView[0]
                                  : "/uploadImg.png"
                              }
                              alt="upload"
                              className="img-fluid"
                            />

                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleUploadImage(e)}
                            />
                          </UploadDataCard>
                        </UploadData>
                      </UploadDataBox>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ padding: "13px", mt: 5 }}
                      fullWidth
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item md={7} my={5}>
              <TableStyle>
                <tr>
                  <th>Icon</th>
                  <th>Amenity Name</th>
                  <th>Amenity Quantity</th>
                  <th>Action</th>
                </tr>
                <tbody>
                  {allAmenties.map((item, index) => (
                    <>
                      {!item.isDeleted ? (
                        <tr key={index}>
                          <td>
                            {tempIndex === index ? (
                              <UploadDataBox>
                                <UploadDataCard>
                                  <img
                                    src={
                                      tempIconPicView &&
                                      tempIconPicView.length > 0
                                        ? tempIconPicView[0]
                                        : "/uploadImg.png"
                                    }
                                    alt="upload"
                                    className="img-fluid"
                                  />
                                  <IconButton
                                    aria-label="clear_image"
                                    sx={{
                                      position: "absolute",
                                      zIndex: 2,
                                      right: "0px",
                                      top: "5px",
                                      background: "#278FD9aa",
                                      height: "25px",
                                      width: "25px",
                                    }}
                                  >
                                    <a
                                      href={iconPicView}
                                      target="_blank"
                                      download
                                    >
                                      <Download
                                        color={"white"}
                                        sx={{ fontSize: ".8rem" }}
                                      />
                                    </a>
                                  </IconButton>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleUploadImage1(e)}
                                  />
                                </UploadDataCard>
                              </UploadDataBox>
                            ) : (
                              <img
                                src={item.image}
                                alt="Bank Icon"
                                className="icon"
                              />
                            )}
                          </td>
                          <td>
                            {tempIndex === index ? (
                              <input
                                style={{ width: "90%" }}
                                onChange={(e) => setTempName(e.target.value)}
                                value={tempName}
                                className="inputBox"
                                type="text"
                                placeholder="Amenity Quantity"
                              />
                            ) : (
                              item.name
                            )}
                          </td>

                          <td>
                            {tempIndex === index ? (
                              <input
                                style={{ width: "90%" }}
                                onChange={(e) =>
                                  setTempQuantity(e.target.value)
                                }
                                value={tempQuantity}
                                className="inputBox"
                                type="text"
                                placeholder="Amenity Quantity"
                              />
                            ) : (
                              item.quantity
                            )}
                          </td>
                          <td>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              justifyContent={"center"}
                            >
                              <div>
                                {tempIndex === index ? (
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => handleUpdate(index)}
                                  >
                                    <Check />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={() => handleEdit(index)}
                                  >
                                    <Draw />
                                  </IconButton>
                                )}
                              </div>
                              <div>
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => handleDelete(index)}
                                >
                                  <DeleteForever />
                                </IconButton>
                              </div>
                            </Stack>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </tbody>
              </TableStyle>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
