import React, { useState, useEffect, useCallback } from "react";
import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import {
  Checkbox,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { brokerData } from "../../utils/data";
import { Link, useNavigate } from "react-router-dom";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import AddBroker from "./AddBroker";
import Profile from "./Profile";
import { useDispatch } from "react-redux";
import { getAllBroker, updateBrokerStatus } from "../../actions";
import { enqueueSnackbar } from "notistack";
import { Button } from "@mui/base";

export default function BrokerManagement() {
  const navigate = useNavigate();
  const [addBrokerAction, setAddBrokerAction] = useState(false);
  const [profileAction, setProfileAction] = useState(false);
  const [brokerId, setBrokerId] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [allBrokers, setAllBrokers] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [updateId, setUpdateId] = React.useState("");
  const [updateValue, setUpdateValue] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [brokerByCount, setBrokerBycount] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const profileHandler = (x) => {
    setBrokerId(x);
    setProfileAction(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  const loadAllBrokers = useCallback(() => {
    dispatch(
      getAllBroker({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
        if (searchQuery == "") {
          setBrokers(data);
        }
      })
    );
  }, [dispatch, currentPage, countPerPage, searchQuery]);

  const getAllBrokerList = useCallback(async () => {
    dispatch(
      getAllBroker({}, (data) => {
        const filters = data.filter((broker) => {
          // by id
          if (
            broker?._id
              ?.toLowerCase()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.name
              ?.toLowerCase()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.phoneNumber
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            broker?.email
              ?.toLowerCase()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.panNumber
              ?.toLowerCase()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.reraRegistrationNumber
              ?.toLowerCase()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.address
              ?.toLowerCase()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.noOfVisits
              ?.toString()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.referCount
              ?.toString()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.noOfCustomers
              ?.toString()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          if (
            broker?.noOfClaimsCount
              ?.toString()
              .includes(searchQuery?.toLocaleLowerCase())
          ) {
            return true;
          }

          return false;
        });
        if (searchQuery) {
          setAllBrokers(data);
        }
        setBrokers(filters);
      })
    );
  }, [dispatch, searchQuery]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      getAllBrokerList();
    }
  }, [getAllBrokerList, navigate]);

  useEffect(() => {
    dispatch(
      getAllBroker({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
        setBrokers(data);
      })
    );
  }, [currentPage, countPerPage]);

  const updateDeleteStatus = (id, status) => {
    let payload = {
      id,
      status,
    };
    dispatch(
      updateBrokerStatus(payload, (data) => {
        enqueueSnackbar("Status Updated!", { variant: "success" });
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      })
    );
    handleClose();
  };

  const headData = [
    "Broker ID",
    "Broker Name",
    "Phone Number",
    "Email",
    "PAN Number",
    "RERA Regi. Number",
    "Address",
    "Visits",
    "Earnings",
    "Clients",
    "Sold Prperties",
    "Claims",
    "Disable",
  ];

  return (
    <Layout path="builder-management">
      <Box>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Broker Management" />
          <ButtonController>
            {/* <Box>
              <IconButton className="iconBtn">
                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
              </IconButton>
            </Box>
            <Box>
              <Typography variant="text" component={'span'}>
                <strong>Jun 24, 2022</strong> Today
              </Typography>
            </Box>
            <Box>
              <IconButton className="iconBtn">
                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
              </IconButton>
            </Box> */}
            <Box onClick={() => setAddBrokerAction(true)}>
              <MainButton>
                <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Broker
              </MainButton>
            </Box>
          </ButtonController>
        </Stack>
        <SearchFilter>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          {/* <Box className="style-1 filter p-1">
            <select>
              <option value="All" selected>
                All
              </option>
              <option value="option 1">option 1</option>
            </select>
          </Box>
          <Box className="style-1">
            <input type="date" placeholder="Choose Date" />
          </Box> */}
        </SearchFilter>
        <Box sx={{ mb: 4 }}></Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                    <Checkbox />
                  </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {brokers?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                  >
                    {/* <TableCell>
                      <Checkbox />
                    </TableCell> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item._id}</TableCell>
                    <TableCell>
                      <Link
                        onClick={() => profileHandler(item?._id)}
                        className="link"
                      >
                        {item.name}
                      </Link>
                    </TableCell>
                    <TableCell>{item.phoneNumber}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.panNumber}</TableCell>
                    <TableCell>{item.reraRegistrationNumber}</TableCell>
                    <TableCell>
                      <strong> {item.address}</strong> <br />{" "}
                    </TableCell>{" "}
                    <TableCell sx={{ color: "#278FD9" }}>
                      <Link to={`/broker-management/visit/${item._id}`}>
                        {item?.noOfVisits ? item?.noOfVisits : 0}
                      </Link>
                    </TableCell>
                    <TableCell>{item.earnings ? item?.earnings : 0}</TableCell>
                    <TableCell sx={{ color: "#278FD9" }}>
                      <Link to={`/broker-management/clients/${item._id}`}>
                        {item.noOfCustomers ? item.noOfCustomers : 0}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ color: "#278FD9" }}>
                      <Link to={`/broker-management/sold-property/${item._id}`}>
                        {item.noOfSoldProperties ? item?.noOfSoldProperties : 0}
                      </Link>
                    </TableCell>
                    <TableCell sx={{ color: "#278FD9" }}>
                      {" "}
                      <Link to={`/broker-management/claim/${item._id}`}>
                        {item.noOfClaimsCount ? item?.noOfClaimsCount : 0}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={item?.isDeleted}
                        onChange={(e) => {
                          setUpdateId(item?._id);
                          setUpdateValue(item?.value);
                          handleClickOpen();
                        }}
                        // updateDeleteStatus(item?._id, e.target.checked); }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allBrokers?.length ? allBrokers?.length : currentPage * countPerPage} of {allBrokers?.length}</Typography> */}
            <Typography>
              {allBrokers?.length > 0
                ? (currentPage - 1) * countPerPage + 1
                : 0}{" "}
              -{" "}
              {countPerPage > allBrokers?.length
                ? allBrokers?.length
                : currentPage * countPerPage > allBrokers?.length
                ? allBrokers?.length
                : currentPage * countPerPage}{" "}
              of {allBrokers?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      currentPage === 1 || countPerPage > allBrokers?.length
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      background:
                        currentPage === 1 || countPerPage > allBrokers?.length
                          ? "#EFEFEF"
                          : "#FFF",
                    }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allBrokers?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > allBrokers?.length ||
                      currentPage ==
                        Math.ceil(allBrokers?.length / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Want to Update the Status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box onClick={handleClose}>
            <MainButton>No</MainButton>
          </Box>
          <Box
            onClick={() => updateDeleteStatus(updateId, updateValue)}
            autoFocus
          >
            <MainButton>Yes</MainButton>
          </Box>
        </DialogActions>
      </Dialog>
      <AddBroker
        addBrokerAction={addBrokerAction}
        setAddBrokerAction={setAddBrokerAction}
      />
      <Profile
        profileAction={profileAction}
        setProfileAction={setProfileAction}
        brokerId={brokerId}
      />
    </Layout>
  );
}
