import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import SearchFilter from "../../assets/SearchFilter";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getBrokerDetails, getConsumerByClientId } from "../../actions";
import { useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import ClientRemarks from "./ClientRemarks";

const Clients = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [addRemarkAction, setAddRemarkAction] = useState(false);
  const dispatch = useDispatch()
  const { id } = useParams();
  const [consumer, setConsumer] = useState([]);
  const [allConsumer, setAllCosumer] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  useEffect(() => {
    dispatch(
      getConsumerByClientId({ brokerId: id }, (data) => {
        const filter = data.data.filter((item) => {
          if (item?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }
          if (item?.brokerId?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }
          if (item?.clientName.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }
          if (item?.phoneNumber.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }
          if (item?.email.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }
          if (item?.activeRequirements?.toString().includes(searchQuery?.toLowerCase())) {
            return true
          }
          return false;
        })
        if (searchQuery) {
          setConsumer(filter);
        }
        setAllCosumer(data.data);
      })
    );
  }, [id, dispatch, searchQuery]);

  useEffect(() => {
    dispatch(
      getConsumerByClientId({ brokerId: id }, (data) => {
        if (searchQuery == "") {
          setConsumer(data.data);
        }
      })
    );
  }, [id, dispatch, countPerPage, currentPage, searchQuery]);


  return (
    <>
      <Layout path={"clients"}>
        <Box>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={3}
            sx={{ my: 4 }}
            justifyContent={"space-between"}
          >
            <Breadcrumbs primary="Broker Management : Clients" />
            {/* <ButtonController>
              <Box onClick={() => { }}>
                <MainButton>
                  <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Broker
                </MainButton>
              </Box>
            </ButtonController> */}
          </Stack>

          <SearchFilter>
            <Box className="search style-1">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Box>
          </SearchFilter>

          <Box sx={{ mb: 4 }}></Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      <Checkbox />
                    </TableCell> */}
                    <TableCell>#</TableCell>
                    {headData.map((item, index) => (
                      <TableCell
                        key={index}
                        style={{ minWidth: "200px" }}
                        align="left"
                      >
                        <Typography
                          sx={{
                            color: "mute.darktext",
                            textTransform: "uppercase",
                            fontSize: "0.9rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    consumer && consumer.length > 0 ? consumer.map((item, index) => (
                      <TableRow sx={{ bgcolor: "#f9fafc" }} key={index}>
                        {/* <TableCell align="left">
                          <Checkbox />
                        </TableCell> */}
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell>{id}</TableCell>
                        <TableCell>{item._id}</TableCell>
                        <TableCell>{item.clientName}</TableCell>
                        <TableCell>{item.phoneNumber}</TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell key={index}>{item?.activeRequirements}</TableCell>
                        <TableCell>{item?.latestVisit?.visitStatus == "bought" || item?.latestVisit?.visitStatus == "completed" || item?.activeRequirements == 0 ? "--" : new Date(item?.latestVisit?.date)?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}</TableCell>
                        <TableCell sx={{ color: "#278FD9" }}>
                          <Link to={`/broker-management/clients/view/${item._id}`}>
                            <span style={{ cursor: "pointer" }}>view</span>
                          </Link>
                        </TableCell>
                        <TableCell>
                          <IconButton size="small" color="primary">
                            <Draw onClick={() => { setAddRemarkAction(true) }} sx={{ cursor: "pointer" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                      : ""
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box
            sx={{
              bgcolor: "mute.dark",
              borderRadius: 1,
              color: "dark.text",
              p: 2,
              mt: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography>
                {allConsumer?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                {countPerPage > allConsumer?.length
                  ? allConsumer?.length
                  : currentPage * countPerPage > allConsumer?.length
                    ? allConsumer?.length
                    : currentPage * countPerPage}{' '}
                of {allConsumer?.length}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography> Rows per page:</Typography>
              <select style={{ marginTop: "-5px" }}
                value={countPerPage}
                onChange={(e) => setCountPerPage(e.target.value)}>
                <option value="10" >
                  10
                </option>
                <option value="20">
                  20
                </option>
                <option value="30">
                  30
                </option>
              </select>
              <Box sx={{ ml: 2 }}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <IconButton
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1 || countPerPage > allConsumer?.length}
                      sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allConsumer?.length ? "#EFEFEF" : "#FFF" }}
                    >
                      <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="text" component={"span"}>
                      {currentPage}/{Math.ceil(allConsumer?.length / countPerPage)}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={countPerPage > allConsumer?.length || currentPage == Math.ceil(allConsumer?.length / countPerPage)}
                      sx={{
                        border: "1px solid",
                        borderColor: "mute.main",
                        bgcolor: "white.main",
                      }}
                    >
                      <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
      <ClientRemarks
        addRemarkAction={addRemarkAction}
        setAddRemarkAction={setAddRemarkAction}
      />
    </>
  );
};

const headData = [
  "broker id",
  "client id",
  "client name",
  "client mobile",
  "client email",
  "active requirement",
  "upcoming visits",
  "view",
  "remarks",
];

export default Clients;
