import React, { useEffect, useState } from 'react';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';
import { sectionBarBtn } from '../../Style/stylesheet';
import EditIcon from '../../assets/img/edit.svg'
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    Button,
    Grid,
    Rating,
} from '@mui/material';

import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../assets/Layout";
import SearchFilter from '../../assets/SearchFilter';
import { getAllProperty } from '../../actions';



export default function Properties(id, action) {
    const [sectionBarActive, setSectionBarActive] = useState('Properties');
    const [builderProperties, setBuilderProperties] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [searchQuery, setSearchQuery] = useState('');
    const [allProperties, setAllProperties] = useState([]);
    const builderId = useParams();
    const dispatch = useDispatch();

    const headData = [
        'NAME OF PROPERTY',
        'CONSTRUCTION STATUS',
        'PROPERTY TYPE',
        'LOCATION',
        'VISITS',
        'PRICE',
        'brokerage',
    ];

    useEffect(() => {
        dispatch(getAllProperty({ builderId: builderId?.id, pageSize: countPerPage, pageNo: currentPage }, (data) => {
            const filters = data.data.filter((prop) => {
                if (prop?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (prop?.constructionStatus.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (prop?.propertyType?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (prop?.location.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (prop?.minPrice.toString().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (prop?.maxPrice.toString().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (prop?.brokerageValue.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }
                return false;
            })
            if(searchQuery){
                setBuilderProperties(filters);
            }
            setAllProperties(data.data);
        }))
    }, [searchQuery]);

    useEffect(() => {
        dispatch(getAllProperty({ builderId: builderId?.id, pageSize: countPerPage, pageNo: currentPage }, (data) => {
            if(searchQuery == ""){
                setBuilderProperties(data.data)
            }
        }))
    }, [currentPage, countPerPage, searchQuery]);

    const navigate = useNavigate();

    const getPriceForProperty = (item) => {
        if (item.floorPlanAndPricing.length > 0) {
            if (item.floorPlanAndPricing[0].price == "") {
                var minPrice = 0;
                var maxPrice = 0;
            } else {
                var minPrice = item.floorPlanAndPricing[0].price;
                var maxPrice = item.floorPlanAndPricing[0].price;
            }
            for (let i = 1; i < item.floorPlanAndPricing.length; i++) {
                if (item.floorPlanAndPricing[i].price < minPrice) {
                    minPrice = item.floorPlanAndPricing[i].price;
                }

                if (item.floorPlanAndPricing[i].price > maxPrice) {
                    maxPrice = item.floorPlanAndPricing[i].price;
                }
            }
            if (minPrice === maxPrice) {
                return minPrice;
            } else {
                return `${minPrice} - ${maxPrice}`;
            }
        } else {
            return "Not Mentioned";
        }
    }

    return (
        <Layout>
            <Stack
                direction={"row"}
                sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
                spacing={3}
            >
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Profile" && "active"}
                    onClick={() => navigate("/builder-management/profile/" + builderId?.id)}
                >
                    Profile
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Properties" && "active"}
                    onClick={() =>
                        navigate("/builder-management/properties/" + builderId?.id)
                    }
                >
                    Properties
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Invoices" && "active"}
                    onClick={() =>
                        navigate("/builder-management/invoices/" + builderId?.id)
                    }
                >
                    Invoices
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Subscription" && "active"}
                    onClick={() =>
                        navigate("/builder-management/subscription/" + builderId?.id)
                    }
                >
                    Subscription
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Queries" && "active"}
                    onClick={() =>
                        navigate("/builder-management/queries/" + builderId?.id)
                    }
                >
                    Queries
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "PropertyEditRequest" && "active"}
                    onClick={() =>
                        navigate("/builder-management/property-edit-request/" + builderId?.id)
                    }
                >
                    Property Edit Request
                </Button>
            </Stack>

            <Box my={5}>
                <SearchFilter>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                </SearchFilter>
                <Box sx={{ mb: 4 }}></Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {builderProperties.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell>
                                        {/* <TableCell>
                                            <Link
                                                onClick={() => setProfileAction(true)}
                                                className="link"
                                            >
                                                Ann Culhane
                                            </Link>
                                        </TableCell> */}

                                        <TableCell sx={{ color: '#278FD9' }}>{item?.name} </TableCell>
                                        <TableCell>{item?.constructionStatus}</TableCell>{' '}
                                        <TableCell sx={{ color: '#278FD9' }}>{item?.propertyType.name}</TableCell>
                                        <TableCell>{item?.location}</TableCell>
                                        <TableCell sx={{ color: '#278FD9' }}>{item?.noOfVisits}</TableCell>
                                        <TableCell >{item?.minPrice} - {item?.maxPrice}</TableCell>
                                        <TableCell sx={{ color: '#278FD9' }}>{item.brokerageValue}</TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: "mute.dark",
                        borderRadius: 1,
                        color: "dark.text",
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allProperties?.length ? allProperties?.length : currentPage * countPerPage} of {allProperties?.length}</Typography> */}
                        <Typography>
                            {allProperties?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                            {countPerPage > allProperties?.length
                                ? allProperties?.length
                                : currentPage * countPerPage > allProperties?.length
                                    ? allProperties?.length
                                    : currentPage * countPerPage}{' '}
                            of {allProperties?.length}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography> Rows per page:</Typography>
                        <select style={{ marginTop: "-5px" }}
                            value={countPerPage}
                            onChange={(e) => setCountPerPage(e.target.value)}>
                            <option value="10" >
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="30">
                                30
                            </option>
                        </select>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1 || countPerPage > allProperties?.length}
                                        sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allProperties?.length ? "#EFEFEF" : "#FFF" }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={"span"}>
                                        {currentPage}/{Math.ceil(allProperties?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={countPerPage > allProperties?.length || currentPage == Math.ceil(allProperties?.length / countPerPage)}
                                        sx={{
                                            border: "1px solid",
                                            borderColor: "mute.main",
                                            bgcolor: "white.main",
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Layout>
    );
}
