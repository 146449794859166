import React, { useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Stack } from '@mui/system';

import Layout from '../../assets/Layout';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Delete,
} from '@mui/icons-material';
import MainButton from '../../assets/MainButton';
import {
    Checkbox,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllPropertiesForBroker, getPropertyByCount, updatePropertyAvailability, updatePropertyDetails, updatePropertyStatus, uploadDocToS3 } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import SearchFilter from '../../assets/SearchFilter';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';

export default function PropertyManagement() {
    const headData = [
        'BUILDER',
        'SUB-BUILDER',
        'NAME OF PROPERTY',
        'CONSTRUCTION STATUS',
        'PROPERTY TYPE',
        'LOCATION',
        'NUMBER OF VISITS',
        'PRICE',
        'BROKERAGE',
        'TAGS',
        'STATUS',
        'Availability'
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const [properties, setProperties] = useState([]);
    const [allProperties, setAllProperties] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [availability, setAvailability] = useState(false);
    const [propertyDocument, setPropertyDocument] = useState("");

    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate("/");
        }
    }, [navigate])



    useEffect(() => {
        dispatch(getAllPropertiesForBroker({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
            if(searchQuery == ""){
                setProperties(data.data);
            }
        }))
    }, [currentPage, countPerPage, searchQuery])

    const dispatch = useDispatch();
    const updateDeleteStatus = (id, status) => {
        let payload = {
            id, status
        }
        dispatch(
            updatePropertyStatus(payload, (data) => {
                if (data?.status === 200) {
                    loadAllProperties()
                    enqueueSnackbar("Status updated!", { variant: 'success' })
                }
                else {
                    enqueueSnackbar("Error while updating!", { variant: 'error' })
                }
            })
        )
    }

    const updateAvailablityStatus = (id, index, status) => {
        var val = [...properties];
        val[index].isAvailability = status;
        setProperties(val);
        if (!status) {
            let payload = {
                isAvailability: status,
                isAvailabilityDoc: ""
            }
            dispatch(
                updatePropertyAvailability(id, payload, (data) => {
                    if (data?.message === "Availability fields updated successfully") {
                        loadAllProperties()
                        enqueueSnackbar("Availability updated!", { variant: 'success' })
                    }
                    else {
                        enqueueSnackbar("Error while updating!", { variant: 'error' })
                    }
                })
            )
        }
    }

    const updateTagsStatus = (id, index, status, names) => {
        var val = [...properties];
        val[index][names] = status;
        setProperties(val);
        const formData = new FormData();
        formData.append("id", id);
        formData.append(names, status);
        dispatch(
            updatePropertyDetails(formData, (data) => {
                loadAllProperties()
                enqueueSnackbar("Availability updated!", { variant: 'success' })
            })
        )
    }

    const getAllPropertiesList = useCallback(async () => {
        dispatch(
            getAllPropertiesForBroker({}, (data) => {
                const filter = data.data.filter((broker) => {

                    // by id
                    if (broker?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    // by builder
                    if (broker?.builderId?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    // by sub-builder
                    // if(broker?.subCompany?.subCompanyName.toLowerCase().includes(searchQuery?.toLowerCase())){
                    //     return true
                    // }

                    // by property name
                    if (broker?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    // by construction status
                    if (broker?.constructionStatus.toLowerCase().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    // by property type
                    if (broker?.propertyType?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    // by location
                    if (broker?.location.toLowerCase().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    if (broker?.minPrice.toString().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    if (broker?.maxPrice.toString().includes(searchQuery?.toLowerCase())) {
                        return true
                    }

                    if (broker?.brokerageValue.toString().includes(searchQuery?.toLowerCase())) {
                        return true
                    }
                    // by tags
                    // if(broker?.tags.toLowerCase().includes(searchQuery?.toLowerCase())){
                    //     return true
                    // }

                    return false
                });
                setAllProperties(data.data)
                if(searchQuery){
                    setProperties(filter);
                }
            })
        )

    }, [dispatch, searchQuery])

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/');
        }
        else {
            getAllPropertiesList()
        }
    }, [getAllPropertiesList, navigate])


    const loadAllProperties = useCallback(() => {
        dispatch(
            getAllPropertiesForBroker({}, (data) => {
                const filter = data.data.filter((broker) =>
                    broker.name.toLowerCase().includes(searchQuery.toLowerCase())
                );
                setProperties(filter);
            })
        )
    }, [searchQuery])


    const getPriceForProperty = (item) => {
        if (item.floorPlanAndPricing.length > 0) {
            if (item.floorPlanAndPricing[0].price == "") {
                var minPrice = 0;
                var maxPrice = 0;
            } else {
                var minPrice = item.floorPlanAndPricing[0].price;
                var maxPrice = item.floorPlanAndPricing[0].price;
            }
            for (let i = 1; i < item.floorPlanAndPricing.length; i++) {
                if (item.floorPlanAndPricing[i].price < minPrice) {
                    minPrice = item.floorPlanAndPricing[i].price;
                }

                if (item.floorPlanAndPricing[i].price > maxPrice) {
                    maxPrice = item.floorPlanAndPricing[i].price;
                }
            }
            if (minPrice === maxPrice) {
                return minPrice;
            } else {
                return `${minPrice} - ${maxPrice}`;
            }
        } else {
            return "Not Mentioned";
        }
    }

    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer',
    };

    const handleUploadBrochure = (e, index, id) => {
        const file = e.target.files[0];
        var val = properties;
        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                const pdfUrl = e.target.result;
            };
            reader.readAsDataURL(file);

            const formData = new FormData();
            formData.append('documents', file);
            dispatch(
                uploadDocToS3(formData, (data) => {
                    val[index].isAvailabilityDoc = data.data.documents[0];

                    setProperties(val);
                    let payload = {
                        isAvailability: properties[index].isAvailability,
                        isAvailabilityDoc: data.data.documents[0]
                    }
                    dispatch(
                        updatePropertyAvailability(id, payload, (data) => {
                            if (data?.message === "Availability fields updated successfully") {
                                loadAllProperties()
                                enqueueSnackbar("Availability updated!", { variant: 'success' })
                            }
                            else {
                                enqueueSnackbar("Error while updating!", { variant: 'error' })
                            }
                        })
                    )
                    //   setPropertyBrochure((oldArr) => [...oldArr, data.data.documents[0]]);
                }, (err) => {
                    console.log(err);
                })
            );
        }
    };

    return (
        <Layout>
            <Box>
                <Stack
                    direction={'row'}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Typography variant="breadcrumbs" component="h1" sx={{ fontFamily: 'Poppins' }}>
                        Property Listing
                    </Typography>
                    <Stack direction={'row'} spacing={2} alignItems={'center'}>
                        <Box>
                            <Link to="/property-management/add-property">
                                <MainButton>
                                    <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> Add Property
                                </MainButton>
                            </Link>
                        </Box>
                    </Stack>
                </Stack>
                <Box sx={{ mb: 4 }}>
                    <SearchFilter>
                        <Box className="search style-1">
                            <input type="text" placeholder="Search..." value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)} />
                        </Box>
                        {/* <Box className="style-1 filter p-1">
                            <select>
                                <option value="All" selected>
                                    All
                                </option>
                                <option value="option 1">option 1</option>
                            </select>
                        </Box>
                        <Box className="style-1">
                            <input type="date" placeholder="Choose Date" />
                        </Box> */}
                    </SearchFilter>
                </Box>

                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                    fontFamily: 'Manrope',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {properties.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell>{item?._id}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.builderId?.companyName}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.builderId?.name}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>
                                            {
                                                item?.isDeleted
                                                    ?
                                                    item?.name
                                                    :
                                                    <Link style={{ color: '#278FD9' }} to={`/update-property/${item?._id}`}>{item?.name}</Link>
                                            }
                                        </TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.constructionStatus}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.propertyType?.name}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.location}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.visitCount}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.minPrice} - {item?.maxPrice}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>{item?.brokerageValue}</TableCell>
                                        <TableCell sx={{ fontFamily: 'Manrope' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ padding: '10px' }}>Rera</span>
                                                <Switch
                                                    size='small'
                                                    checked={item?.isRera}
                                                    onChange={(e) => { updateTagsStatus(item._id, index, e.target.checked, 'isRera'); }}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ padding: '10px' }}>Promoted </span>
                                                <Switch
                                                    size='small'
                                                    checked={item?.isPromoted}
                                                    onChange={(e) => { updateTagsStatus(item._id, index, e.target.checked, 'isPromoted'); }}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ padding: '10px' }}>Best Selling </span>
                                                <Switch
                                                    size='small'
                                                    checked={item?.isBestSelling}
                                                    onChange={(e) => { updateTagsStatus(item._id, index, e.target.checked, 'isBestSelling'); }}
                                                />
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={item?.isDeleted}
                                                onChange={(e) => { updateDeleteStatus(item?._id, e.target.checked); }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                checked={item?.isAvailability}
                                                onChange={(e) => { updateAvailablityStatus(item._id, index, e.target.checked); }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {
                                                item?.isAvailabilityDoc == undefined || item?.isAvailabilityDoc == ""
                                                    ?
                                                    <UploadDataBox
                                                    >
                                                        <UploadData
                                                        >
                                                            <UploadDataCard
                                                            >
                                                                <img
                                                                    src={'/uploadImg.png'}
                                                                    alt="upload"
                                                                    className={!item?.isAvailability ? "disabled" : "img-fluid"}
                                                                />
                                                                <input
                                                                    type="file"
                                                                    accept=".pdf"
                                                                    style={hideInput}
                                                                    disabled={!item?.isAvailability}
                                                                    onChange={(e) => handleUploadBrochure(e, index, item._id)}
                                                                />
                                                            </UploadDataCard>
                                                        </UploadData>
                                                    </UploadDataBox>
                                                    :
                                                    <Box sx={{ display: 'flex' }}>
                                                        <embed src={item.isAvailabilityDoc} width="200" height="200" />
                                                        {/* {propertyDocument && <Delete onClick={() => setPropertyDocument("")} />} */}
                                                    </Box>
                                            }

                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: "mute.dark",
                        borderRadius: 1,
                        color: "dark.text",
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allProperties?.length ? allProperties?.length : currentPage * countPerPage} of {allProperties?.length}</Typography> */}
                        <Typography>
                            {allProperties?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                            {countPerPage > allProperties?.length
                                ? allProperties?.length
                                : currentPage * countPerPage > allProperties?.length
                                    ? allProperties?.length
                                    : currentPage * countPerPage}{' '}
                            of {allProperties?.length}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography> Rows per page:</Typography>
                        <select style={{ marginTop: "-5px" }}
                            value={countPerPage}
                            onChange={(e) => setCountPerPage(e.target.value)}>
                            <option value="10" >
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="30">
                                30
                            </option>
                        </select>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1 || countPerPage > allProperties?.length}
                                        sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allProperties?.length ? "#EFEFEF" : "#FFF" }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={"span"}>
                                        {currentPage}/{Math.ceil(allProperties?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={countPerPage > allProperties?.length || currentPage == Math.ceil(allProperties?.length / countPerPage)}
                                        sx={{
                                            border: "1px solid",
                                            borderColor: "mute.main",
                                            bgcolor: "white.main",
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Layout >
    );
}
