import React, { useCallback, useEffect, useState } from 'react';

import Layout from '../../assets/Layout';
import Breadcrumbs from '../../assets/Breadcrumbs';
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';

import {
  Checkbox,
  IconButton,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
} from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import ButtonController from '../../assets/ButtonController';
import SearchFilter from '../../assets/SearchFilter';


import Profile from './Profile';
import AddDSA from './AddDSA';
import { getAllDsa } from '../../actions';
import { useDispatch } from 'react-redux';
import regex from '../../utils/regex';

export default function BrokerManagement() {
  const [addDSAAction, setAddDSAAction] = useState(false);
  const [profileAction, setProfileAction] = useState(false);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dsa, setDsa] = useState([]);
  const [allDsa, setAllDsa] = useState([]);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headData = [
    'Dsa ID',
    'DSA NAME',
    'COMPANY NAME',
    'PHONE NUMBER',
    'EMAIL',
    'ADDRESS',
    'loan',
    'payment',
    'AREA OF OPERATION',
    'BANK ASSOCIATIONS',
    'LOAN RANGE',
  ];



  const loadAllDsa = useCallback(() => {
    dispatch(getAllDsa({}, (data) => {
      setAllDsa(data.data);
    }))

  }, [dispatch])


  useEffect(() => {
    loadAllDsa()
  }, [loadAllDsa]);

  useEffect(() => {
    dispatch(getAllDsa({ pageNo: currentPage, pageSize: countPerPage }, (data) => {

      const filter = data.data.filter((item) => {

        if (item._id?.includes(searchQuery?.toLowerCase())) {
          console.log("subscription")

          return true
        }

        if (item?.name?.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }

        if (item.companyName?.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }

        if (item.phoneNumber?.toString().includes(searchQuery?.toLowerCase())) {
          return true;
        }

        return false;
      })
      console.log("subscription", filter)

      setDsa(filter);
    }))
  }, [currentPage, countPerPage, searchQuery,]);

  return (
    <Layout path="builder-management">
      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary="DSA" />
          <ButtonController>
            <Box onClick={() => setAddDSAAction(true)}>
              <MainButton>
                <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> New DSA
              </MainButton>
            </Box>
          </ButtonController>
        </Stack>
        <SearchFilter>
          <Box className="search style-1" >
            <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
          </Box>
        </SearchFilter>
        <Box sx={{ mb: 4 }}></Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                      <Typography
                        sx={{
                          color: 'mute.darktext',
                          textTransform: 'uppercase',
                          fontSize: '0.9rem',
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {dsa.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                  >
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?._id}</TableCell>
                    <TableCell>
                      <Link
                        onClick={() => setProfileAction(true)}
                        className="link"
                      >
                        {item?.name}
                      </Link>
                    </TableCell>
                    <TableCell>{item?.companyName}</TableCell>
                    <TableCell>{item?.phoneNumber}</TableCell>
                    <TableCell>{item?.email}</TableCell>
                    <TableCell>{item?.address}</TableCell>{' '}
                    <TableCell>{item?.loanQueries?.length}</TableCell>
                    <TableCell>{item?.payment?.length}</TableCell>
                    {item?.areaOfOperations ? (
                      item?.areaOfOperations.map((area, index) => (
                        <TableCell key={index}>{area}</TableCell>
                      ))
                    ) : (
                      <TableCell></TableCell>
                    )}

                    {item?.bankAssociations ? (
                      item?.bankAssociations.map((bank, index) => (
                        <TableCell key={index}>{bank}</TableCell>
                      ))
                    ) : (
                      <TableCell></TableCell>
                    )}
                    <TableCell>₹{item?.minLoanRange} - ₹{item?.maxLoanRange}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > dsa?.length ? dsa?.length : currentPage * countPerPage} of {allDsa?.length}</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}>
              <option value="10" >
                10
              </option>
              <option value="20">
                20
              </option>
              <option value="30">
                30
              </option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1 || countPerPage > allDsa?.length}
                    sx={{ border: "1px solid", borderColor: "mute.main" }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allDsa?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={countPerPage > allDsa?.length || currentPage == Math.ceil(allDsa?.length / countPerPage)}
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Modals */}
      <Profile
        profileAction={profileAction}
        setProfileAction={setProfileAction}
      />
      <AddDSA addDSAAction={addDSAAction} setAddDSAAction={setAddDSAAction} />
      {/* Modals */}
    </Layout>
  );
}
