import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function CustomAutoComplete({ builders, top3Builders, setTop3Builders, searchQuery, setSearchQuery }) {
  const [value, setValue] = React.useState("");
  const [labeldBuilder, setLabeldBuilder] = React.useState([]);
  useEffect(()=>{
    setLabeldBuilder(builders);
  }, [builders]);
  const handleKeyDown = (e) => {
    if (e.keyCode == 13) {
      if (top3Builders.length <= 3) {
        setTop3Builders(oldArr => [...oldArr, value]);
      } else {
        enqueueSnackbar("you have already selected top 3 builders", { variant: 'warning' });
      }
    }
  }
  return (
    <>
      {/* <Autocomplete
        multiple
        id="tags-standard"
        options={builders}
        defaultValue={top3Builders}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Multiple values"
            placeholder="Favorites"
          />
        )}
      /> */}
      <Autocomplete
        multiple
        value={top3Builders}
        onChange={(event, newValue) => {
          if (newValue.length <= 3) {
            setTop3Builders(newValue);
          } else {
            enqueueSnackbar("you have already selected top 3 builders", { variant: 'warning' });
          }
        }}
        // inputValue={inputValue}
        // onInputChange={(event, newInputValue) => {
        //   setInputValue(newInputValue);
        // }}
        id="controllable-states-demo"
        sx={{ width: '100%' }}
        options={labeldBuilder}
        renderInput={(params) => <TextField {...params} onChange={(e) => setSearchQuery(e.target.value)} onKeyDown={handleKeyDown}
          label="Top 3 builders" variant="standard" />}
      />
    </>
  );
}