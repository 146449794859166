import React, { useCallback, useEffect } from "react";
import Layout from "../../assets/Layout";
import { BoughtData } from '../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../Style/stylesheet';
import AddIcon from '@mui/icons-material/Add';
import Edit from '../../assets/img/edit.svg'
import BrokerPromotionImage from '../../assets/img/brokerPromotion.svg'
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
    Switch,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllVisits } from "../../actions";
import AddPolicy from "../Policies/AddPolicy";

import AddVisitsModal from "./AddVisitsModal";

const PromotionVisits = () => {
    const [sectionBarActive, setSectionBarActive] = useState('Bought')
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const dispatch = useDispatch();
    const [allVisits, setAllVisits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page

    const headData = [
        "SUB ID",
        'BANNER IMAGE',
        'TOATL DAYS ',
        'REMAINING DAYS',
        'LOCATION',
        'STATUS',
    ];

    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };

    const handleButtonClick = (id) => {
        navigate(`/visit-details/${id}`)
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const loadAllvisits = useCallback(() => {
        dispatch(getAllVisits({ visitStatus: "bought", pageNo: currentPage, pageSize: countPerPage }, (data) => {
            setAllVisits(data.data);
        }))

    }, [dispatch])

    console.log(allVisits)

    useEffect(() => {
        loadAllvisits();
    }, [loadAllvisits])

    const myObject = {
        text: 'Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text of the',
        shownIn: 'Informative Website'
    }



    return (
        <>
            <Layout path="normalVisits">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 2 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Promotion - Visits" />
                    <Button
                    onClick={() => setAddRemarkAction(true)}
                        sx={{
                            backgroundColor: '#278FD9',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: '#278FD9',
                                border: '1px solid #278FD9 '
                            },
                        }}
                    >
                        <AddIcon />Add New
                    </Button>
                </Stack>

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." />
                    </Box>

                </SearchFilter>

                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{  }} align="center">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow
                                >
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell sx={{textAlign:'center'}}>1</TableCell>
                                    <TableCell sx={{textAlign:'center'}}>46676732989</TableCell>
                                    <TableCell sx={{textAlign:'center'}}>
                                        <Button onClick={() => setAddRemarkAction(true)}><img src={BrokerPromotionImage} al="image" /></Button>
                                    </TableCell>
                                    <TableCell sx={{textAlign:'center'}}>10</TableCell>
                                    <TableCell sx={{textAlign:'center'}}>10</TableCell>
                                    <TableCell sx={{textAlign:'center'}}>
                                        Top
                                    </TableCell>
                                    <TableCell sx={{textAlign:'center'}}>
                                        <Switch
                                            style={{  }}
                                        // checked={item?.isDeleted}
                                        // onChange={(e) => { updateDeleteStatus(item?._id, e.target.checked); }}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        sx={{
                            bgcolor: 'mute.dark',
                            borderRadius: 1,
                            color: 'dark.text',
                            p: 2,
                            mt: 4,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
              
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: ' center',
                                alignItems: 'center',
                                float: 'right'
                            }}
                        >
                            <Box>
                                <Typography component="p">Rows per page:</Typography>
                            </Box>
                            <Box>
                                <select value={countPerPage} onChange={handleSelectChange}>
                                    <option value="10" selected>
                                        10
                                    </option>
                                    <option value="20" selected>
                                        20
                                    </option>
                                    <option value="30" selected>
                                        30
                                    </option>
                                </select>
                            </Box>
                            <Box sx={{ ml: 2 }}>
                                <Stack
                                    direction={'row'}
                                    spacing={2}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                                            sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                        >
                                            <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Typography variant="text" component={'span'}>
                                            {currentPage}/10
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            sx={{
                                                border: '1px solid',
                                                borderColor: 'mute.main',
                                                bgcolor: 'white.main',
                                            }}
                                        >
                                            <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>

                    <AddVisitsModal
                        addRemarkAction={addRemarkAction}
                        setAddRemarkAction={setAddRemarkAction}
                    />
                </Paper>
            </Layout>
        </>
    );
}

export default PromotionVisits;