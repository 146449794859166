import * as React from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Stack,
  IconButton,
  Autocomplete,
  TextField,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { useRef } from "react";
import JoditEditor from "jodit-react";
import {
  getAllBuilders,
  uploadDocToS3,
  getAllPropertyCategory,
  getAllBanks,
  getAllAmenties,
  getAllLocationAdvantages,
  addPropertyDetails,
  getAllpropertySubCategory,
  getAllpropertySubCategorys,
  getAllSpecification,
  getPropertyById,
  updatePropertyDetails,
  getLocationByInput
} from '../../actions';
import {
  Article,
  Delete,
  Done,
  Download,
  Info,
  ModeEditOutline,
  PictureAsPdf,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';

const PropertyUpdateForm = ({ propertyID }) => {
  const editor = useRef(null);
  const [propertyImages, setPropertyImages] = React.useState([]);
  const [propertyImagesPicView, setPropertyImagesPicView] = React.useState([]);
  const [propertyLogo, setPropertyLogo] = React.useState([]);
  const [propertyLogoPicView, setPropertyLogoPicView] = React.useState([]);
  const [propertyBrochure, setPropertyBrochure] = React.useState([]);
  const [propertyBrochurePicView, setPropertyBrochurePicView] = React.useState([]);
  const [builders, setBuilders] = React.useState([]);
  const [propertyCategories, setPropertyCategories] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [allAmenities, setAllAmenties] = React.useState([]);
  const [paymentPlans, setPaymentPlans] = React.useState([]);
  const [selectedBuilder, setSelectedBuilder] = React.useState('');
  const [builderDiscount, setBuilderDiscount] = React.useState('');
  const [propertyName, setPropertyName] = React.useState('');
  const [propertyLocation, setPropertyLocation] = React.useState('');
  const [propertyLiner, setPropertyLiner] = React.useState('');
  const [subCompany, setSubCompany] = React.useState('');
  const [constructionStatus, setConstructionStatus] = React.useState('');
  const [possessionDate, setPossessionDate] = React.useState('');
  const [selectedProperty, setSelectedProperty] = React.useState('');
  const [latitutde, setLatitude] = React.useState('');
  const [longitude, setLongitude] = React.useState('');
  const [amenties, setAmenties] = React.useState([]);
  const [advantages, setAdvantages] = React.useState([]);
  const [loanApprovedBy, setLoanApprovedBy] = React.useState([]);
  const [about, setAbout] = React.useState('');
  const [specification, setSpecification] = React.useState('');
  const [terms, setTerms] = React.useState('');
  const [paymentPlan, setPayment] = React.useState('');
  const [milestone, setMileStone] = React.useState('');
  const [withLocality, setWithLocality] = React.useState('');
  const [currentPrice, setCurrentPrice] = React.useState('');
  const [last1Year, setFirstYearPrice] = React.useState('');
  const [last2Year, setSecondYearPrice] = React.useState('');
  const [last3Year, setThirdYearPrice] = React.useState('');
  const [last4Year, setFourthYearPrice] = React.useState('');
  const [tempPlan, setTempPlan] = React.useState('');
  const [tempMilestone, setTempMilestone] = React.useState('');
  const [tempIndex, setTempIndex] = React.useState('');
  const [tempLocality, setTempLocality] = React.useState('');
  const [tempCurrentPrice, setTempCurrentPrice] = React.useState('');
  const [tempFirstPrice, setTempFIrstPrice] = React.useState('');
  const [tempSecondPrice, setTempSecondPrice] = React.useState('');
  const [tempThirdPrice, setTempThirdPrice] = React.useState('');
  const [tempFourthPrice, setTemoFourthPrice] = React.useState('');
  const [tempPriceIndex, setTempPriceIndex] = React.useState('');
  const [trends, setTrends] = React.useState([]);
  const [allSubCompanies, setAllSubCompanies] = React.useState([]);
  const [allSpecifications, setAllSpecifications] = React.useState([]);
  const [subCategory, setSubCategory] = React.useState(false);
  const [propertyDetails, setPropertyDetails] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [subCategoryType, setSubCategoryType] = React.useState("");
  const [distance, setDistance] = React.useState("");
  const [locationAdvantages, setLocationAdvantages] = React.useState([]);
  const [tempLocationAdvantageIndex, setTempLocationAdvantageIndex] = React.useState("");
  const [tempLocationAdvantage, setTempLocationAdvantage] = React.useState("");
  const [tempDistance, setTempDistance] = React.useState("");
  const [tempLocationAdvantages, setTempLocationAdvantages] = React.useState([]);
  const [locationSearch, setLocationSearch] = React.useState([]);

  const handleSetFormValues = (name, value) => {
    if (name === 'location') {
      getLocationByInput({ searchText: value }).then((res) => {
        if (res.data.data.length > 0) {
          var arr = [];
          for (let index = 0; index < res.data.data.length; index++) {
            let element = { label: res.data.data[index].location };
            arr.push(element);
          }
          setLocationSearch(arr);
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    setPropertyLocation((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const hideInput = {
    opacity: 0,
    position: 'absolute',
    left: '26.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  const hideInput1 = {
    opacity: 0,
    position: 'absolute',
    left: '45.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  const hideInput2 = {
    opacity: 0,
    position: 'absolute',
    left: '63.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  const dispatch = useDispatch();
  const handleUploadImage = (e) => {
    const file = e.target.files;

    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setPropertyImages((oldArr) => [...oldArr, data.data.documents[0]]);
            setPropertyImagesPicView((oldArr) => [...oldArr, data.data.documents[0]]);
          }, (err) => {
            console.log(err);
          })
        );
      }
    }
  };

  const handleUploadLogo = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append('documents', file);
      dispatch(
        uploadDocToS3(formData, (data) => {
          setPropertyLogo((oldArr) => [...oldArr, data.data.documents[0]]);
        }, (err) => {
          console.log(err);
        })
      );
    }
  };

  const handleUploadBrochure = (e) => {
    const file = e.target.files;
    if (file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setPropertyBrochure((oldArr) => [...oldArr, data.data.documents[0]]);
            setPropertyBrochurePicView(oldArr => [...oldArr, data.data.documents[0]])
          }, (err) => {
            console.log(err);
          })
        );

      }
    }
  };

  const loadAllBulders = React.useCallback(() => {
    dispatch(
      getAllBuilders({ isAdmin: true }, (data) => {
        var temp = data.data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setBuilders(temp);
        }
      })
    );
  }, [dispatch]);

  const loadAllProperties = React.useCallback(() => {
    dispatch(
      getAllPropertyCategory({}, (data) => {
        setPropertyCategories(data.data);
      })
    );
  }, [dispatch]);


  const loadAllBanks = React.useCallback(() => {
    dispatch(
      getAllBanks({}, (data) => {
        setBanks(data);
      })
    );
  }, [dispatch]);

  const loadAllAmenties = React.useCallback(() => {
    dispatch(
      getAllAmenties({}, (data) => {
        setAllAmenties(data);
      })
    );
  }, [dispatch]);

  const [allLocationAdvantages, setAllLocationAdvantages] = React.useState([]);
  const loadAllLocationAdvantages = React.useCallback(() => {
    dispatch(
      getAllLocationAdvantages({}, (data) => {
        setAllLocationAdvantages(data);
      })
    );
  }, [dispatch]);

  const loadAllSubCompanies = React.useCallback(() => {
    dispatch(
      getAllpropertySubCategorys({}, (data) => {
        setAllSubCompanies(data.data);
      })
    )
  }, [dispatch])

  const loadAllSpecifications = React.useCallback(() => {
    dispatch(
      getAllSpecification({}, (data) => {
        setAllSpecifications(data.data);
      })
    )
  }, [dispatch])

  const loadPropertyDetails = React.useCallback(() => {
    dispatch(
      getPropertyById(propertyID, {}, (data) => {
        setPropertyDetails(data);

        // Setting data in respected states
        if (data.data[0].images) {
          setPropertyImages(data.data[0].images)
          setPropertyImagesPicView(data.data[0].images)
        }

        if (data.data[0].propertyLogo) {
          setPropertyLogo(oldArr => [...oldArr, data.data[0].propertyLogo[0]])
          setPropertyLogoPicView(oldArr => [...oldArr, data.data[0].propertyLogo[0]])
        }

        if (data.data[0].brochureUrl) {
          setPropertyBrochure(data.data[0].brochureUrl)
          setPropertyBrochurePicView(data.data[0].brochureUrl)
        }

        if (data.data[0].builderId && builders?.length > 0) {
          console.log(builders);
          const temppbuilder = builders.filter((item) => item._id == data.data[0].builderId._id).map((item) => ({ id: item._id, companyName: item.companyName || "--" }));
          setSelectedBuilder(temppbuilder[0]);
        }

        setBuilderDiscount(data.data[0].builderDiscount);
        setPropertyName(data.data[0].name);
        setPropertyLocation(data.data[0].location);
        setPropertyLiner(data.data[0].propertyLiner);
        setConstructionStatus(data.data[0].constructionStatus);
        setPossessionDate(data.data[0].possessionDate);

        if (data.data[0].propertyType) {
          setSelectedProperty(data.data[0].propertyType._id);
          loadAllPropertySubCategory(data.data[0].propertyType._id);
        }
        setLatitude(data.data[0].latitude);
        setLongitude(data.data[0].longitude);

        if (data.data[0].amenitiesId.length > 0) {

          setAmenties(data.data[0].amenitiesId.map((item) => item._id));
        }

        if (data.data[0].locationAdvantagesId.length > 0) {
          setLocationAdvantages(data.data[0].locationAdvantagesId);
        }

        if (data.data[0].loanApprovedByIds.length > 0) {
          setLoanApprovedBy(data.data[0].loanApprovedByIds.map((item) => item._id));
        }

        setAbout(data.data[0].propertyDescription);
        setTerms(data.data[0].termAndCondition);
        if (data.data[0].paymentPlan.length > 0) {
          let tempData = data.data[0].paymentPlan.map((item) => { return { payment: item.payment, milestone: item.milestone } })
          setPaymentPlans(tempData);
        }
        if (data.data[0].currentlyComparing.length > 0) {
          setTrends(data.data[0].currentlyComparing);
        }

        console.log(data.data[0].subCompany);
        //   if (data.data[0].subCompany) {
        //   setSubCompany(data.data[0].subCompany._id);
        // }
        if (allSubCompanies?.length > 0 && data.data[0].subCompany) {
          const temppcompany = allSubCompanies.filter((item) => item._id == data.data[0].subCompany._id).map((item) => ({ id: item._id, subCompanyName: item.subCompanyName || "--" }));
          setSubCompany(temppcompany[0]);
        }

        if (data.data[0].propertySubTypes.length > 0) {
          setSubCategory(data.data[0].propertySubTypes[0]);
        }
        setSubCategoryType(data.data[0].propertyTypeSubCategory);
        setSpecification(data.data[0].projectSpecification);
      })
    )
  }, [allSubCompanies, builders])

  const loadAllPropertySubCategory = (id) => {
    dispatch(
      getAllpropertySubCategory({ parentId: id }, (data) => {
        setSubCategory(data.data);
      })
    );
  };

  React.useEffect(() => {
    loadAllBulders();
    loadAllSubCompanies();
  },[loadAllBulders, loadAllSubCompanies]);
  React.useEffect(() => {
    loadAllProperties();
    loadAllBanks();
    loadAllAmenties();
    loadAllLocationAdvantages();
    loadAllSpecifications();
    loadPropertyDetails();
  }, [
    loadAllProperties,
    loadAllBanks,
    loadAllAmenties,
    loadAllLocationAdvantages,
    loadAllSpecifications,
    loadPropertyDetails
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAmenties(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleLocationAdvantageChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdvantages(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleLoanApprovedChange = (event) => {
    const {
      target: { value },
    } = event;
    setLoanApprovedBy(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleCreatePlan = () => {
    if (!paymentPlan || !milestone) {
      enqueueSnackbar("Please fill all the fields", { variant: 'error' });
    } else {
      setPaymentPlans((oldArr) => [...oldArr, { payment: paymentPlan, milestone }]);
    }
  };

  const handleRemoveFromPlan = (index) => {
    setPaymentPlans((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the new array
    });
  };

  const handleEditPackage = (index) => {
    setTempIndex(index);
    setTempMilestone(paymentPlans[index].milestone);
    setTempPlan(paymentPlans[index].payment);
  };

  const handleUpdatePackage = (index) => {
    const val = paymentPlans;
    val[index] = { payment: tempPlan, milestone: tempMilestone };
    setPaymentPlans(val);
    setTempIndex(null);
    setTempMilestone(null);
    setTempPlan(null);
  };

  const handleCreatePriceTrends = () => {
    if (!withLocality || !currentPrice || !last1Year || !last2Year || !last3Year || !last4Year) {
      enqueueSnackbar("please fill all the fields", { variant: 'error' });
    } else {
      setTrends((oldArr) => [
        ...oldArr,
        {
          withLocality,
          currentPrice,
          last1Year,
          last2year: last2Year,
          last3Year,
          last4Year,
        },
      ]);
    }
  };

  const handleRemoveFromPriceTrends = (index) => {
    setTrends((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the new array
    });
  };

  const handleEditPriceTrends = (index) => {
    setTempPriceIndex(index);
    setTempLocality(trends[index].withLocality);
    setTempCurrentPrice(trends[index].currentPrice);
    setTempFIrstPrice(trends[index].last1Year);
    setTempSecondPrice(trends[index].last2year);
    setTempThirdPrice(trends[index].last3Year);
    setTemoFourthPrice(trends[index].last4Year);
  };

  const handleUpdatePriceTrends = (index) => {
    const val = trends;
    val[index] = {
      withLocality: tempLocality,
      currentPrice: tempCurrentPrice,
      last1Year: tempFirstPrice,
      last2year: tempSecondPrice,
      last3Year: tempThirdPrice,
      last4Year: tempFourthPrice,
    };
    setTempPriceIndex(null);
    setTempLocality(null);
    setTempCurrentPrice(null);
    setTempFIrstPrice(null);
    setTempSecondPrice(null);
    setTempThirdPrice(null);
    setTemoFourthPrice(null);
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !selectedBuilder.id ||
      propertyImages?.length === 0 ||
      !propertyName ||
      !propertyLocation ||
      !propertyLiner ||
      !subCompany.id ||
      !constructionStatus ||
      (constructionStatus != "Ready to Move" && !possessionDate) ||
      !selectedProperty ||
      !subCategoryType ||
      !latitutde ||
      !longitude ||
      !about
    ) {
      // Display an error message or handle the empty fields appropriately
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    // farmhouse - PRPTY-911465
    // flats - PRPTY-906626
    // commercial - PRPTY-671815
    // plots - PRPTY-557497
    setIsLoading(true)
    const formData = new FormData();
    // formData.append("isRera", true);
    formData.append("id", propertyID);
    formData.append("possessionDate", possessionDate);
    formData.append('name', propertyName);
    formData.append("location", propertyLocation);
    formData.append('propertyType', selectedProperty);
    formData.append("latitude", latitutde);
    formData.append("longitude", longitude);
    formData.append("constructionStatus", constructionStatus);
    formData.append('paymentPlan', JSON.stringify(paymentPlans));
    formData.append("propertyDescription", about);
    formData.append("loanApprovedByIds", JSON.stringify(loanApprovedBy));
    formData.append("builderId", selectedBuilder.id);
    formData.append("termAndCondition", terms);
    formData.append("currentlyComparing", JSON.stringify(trends));
    if (propertyImages?.length > 0) {
      formData.append("images", JSON.stringify(propertyImages));
    }

    if (propertyBrochure?.length > 0) {
      formData.append("brochure", JSON.stringify(propertyBrochure));
    }
    formData.append("amenitiesId", JSON.stringify(amenties));
    formData.append("locationAdvantagesId", JSON.stringify(locationAdvantages));
    formData.append("subCompany", subCompany.id);
    formData.append("propertyLiner", propertyLiner);
    formData.append("builderDiscount", builderDiscount);
    formData.append("projectSpecification", specification);
    formData.append("propertyLogo", propertyLogo[0]);
    formData.append("propertyTypeSubCategory", subCategoryType);
    dispatch(
      updatePropertyDetails(formData, (data) => {
        if (data.status === 200) {
          enqueueSnackbar(data.message, { variant: 'success' });
          navigate('/update-property/floor-Plan/' + data.data._id);
        } else {
          enqueueSnackbar(data.message, { variant: 'error' });
        }
      })
    );
    setIsLoading(false)
  };

  const handleRemovePropertyImage = (index) => {
    setPropertyImages(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    setPropertyImagesPicView(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    let tempArr = [...propertyImages];
    tempArr.splice(index, 1);
  }

  const handleRemovePropertyLogo = () => {
    setPropertyLogo([]);
    setPropertyLogoPicView([]);
  }

  const handleRemovePropertyBrochure = (index) => {
    setPropertyBrochure(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    setPropertyBrochurePicView(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });
  }

  const handleAddLocationAdvantage = () => {
    if (!distance || !advantages) {
      enqueueSnackbar("Select a location advantage and distance as well", { variant: 'warning' });
    } else if (isNaN(distance)) {
      enqueueSnackbar("Please enter a valid distance", { variant: 'warning' });
    } else if (locationAdvantages.filter((item) => item?.name?.toLowerCase() === advantages?.name?.toLowerCase())?.length > 0) {
      enqueueSnackbar("Can not same location advantage twice", { variant: 'warning' });
    } else {
      const temp = [...locationAdvantages, { ...advantages, distance }];
      setLocationAdvantages(temp);
      setAdvantages("");
      setDistance("");
    }

  }

  const handleEditLocationAdvantage = (index) => {
    setTempLocationAdvantageIndex(index);
    setTempLocationAdvantage(locationAdvantages[index]);
    setTempDistance(locationAdvantages[index]?.distance);
  }

  const handleUpdateLocationAdvantage = (index) => {
    var tempArr = [...locationAdvantages];
    tempArr[index] = tempLocationAdvantage;
    tempArr[index].distance = tempDistance;
    setLocationAdvantages(tempArr);
    setTempLocationAdvantageIndex("");
    setTempLocationAdvantage("");
    setTempDistance("");
  }

  const handleRemoveLocationAdvantage = (index) => {
    const newArray = locationAdvantages.filter((item, index1) => index1 != index);
    setLocationAdvantages(newArray);
    setTempLocationAdvantageIndex("");
  }

  return (
    <Box sx={{ marginTop: '73px' }}>
      <UploadDataBox>
        {propertyImagesPicView && propertyImagesPicView.length > 0
          ?
          propertyImagesPicView.map((item, index) => (
            <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
              <img
                key={index}
                src={
                  item
                }
                style={{ width: '130px', height: '130px' }}
                alt="upload"
                className="img-fluid"
              />
              <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyImage(index)}>
                <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
              </IconButton>
              <a href={item} download target="_blank" style={{ position: 'absolute', zIndex: 2, right: '5px', bottom: '10px', }}>
                <IconButton
                  sx={{
                    background: '#278FD9aa',
                    height: '25px',
                    width: '25px',
                  }}
                >
                  <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                </IconButton>
              </a>
            </Box>
          ))
          :
          ""
        }
        <UploadData name={'Upload Property Image*'} >
          <UploadDataCard>
            <img
              src={
                "/uploadImg.png"
              }
              alt="upload"
              className="img-fluid"
            />
            <input
              type="file"
              accept="image/*"
              style={hideInput}
              multiple
              onChange={handleUploadImage}
            />
          </UploadDataCard>
        </UploadData>
        <UploadData name={'Upload Property Logo'}>
          <UploadDataCard>
            {propertyLogo.length > 0 ?

              <>
                <img
                  src={propertyLogo[0]}
                  alt="upload"
                  style={{ width: '130px', height: '130px' }}
                  className="img-fluid"
                />
                <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyLogo}>
                  <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                </IconButton>
                <a href={propertyLogo[0]} download target="_blank" style={{ position: 'absolute', zIndex: 2, right: '5px', bottom: '10px', }}>
                  <IconButton
                    sx={{
                      background: '#278FD9aa',
                      height: '25px',
                      width: '25px',
                    }}
                  >
                    <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                  </IconButton>
                </a>
              </>
              :
              <>
                <img
                  src={'/uploadImg.png'}
                  alt="upload"
                />
                <input
                  type="file"
                  accept="image/*"
                  style={hideInput}
                  onChange={handleUploadLogo}
                />
              </>
            }
          </UploadDataCard>
        </UploadData>
        <UploadData name={'Upload Property Brochure'}>
          {propertyBrochurePicView && propertyBrochurePicView.length > 0 &&
            propertyBrochurePicView.map((item, index) => (
              <>
                <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                  <embed src={item} width="200" height="200" />
                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                  </IconButton>
                </Box>
                <a href={item} target="_blank" download >
                  <IconButton
                    sx={{
                      position: 'absolute', zIndex: 2, right: '80px', bottom: '5px',
                      background: '#278FD9aa',
                      height: '25px',
                      width: '25px',
                    }}
                  >
                    <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                  </IconButton>
                </a>
              </>
            ))}
          <UploadDataCard>
            <img
              src={'/upload-doc.png'}
              alt="upload"
              className="img-fluid"
            />
            <input
              type="file"
              accept=".pdf"
              style={hideInput}
              multiple
              onChange={handleUploadBrochure}
            />
          </UploadDataCard>
        </UploadData>
      </UploadDataBox>
      {/* <Grid container>
        <Grid item md={3}>
          <Box sx={{ py: 2 }}>
            <Typography>Upload Property Image</Typography>
            {propertyImages.length > 0 ? (
              <img
                width={130}
                height={130}
                style={{ borderRadius: '20px' }}
                src={propertyImages[0]}
                alt="property"
              />
            ) : (
              <Box
                component="button"
                sx={{
                  background: '#FFFFFF',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <img
                  className="uploadimg"
                  src={'/Images/uploadImg.png'}
                  alt="upload"
                />
                <input
                  type="file"
                  accept="image/*"
                  style={hideInput}
                  onChange={(e) => handleUploadImage(e)}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={3}>
          <Box sx={{ py: 2 }}>
            <Typography>Upload Property Logo</Typography>
            {propertyLogo.length > 0 ? (
              <img
                width={130}
                height={130}
                style={{ borderRadius: '20px' }}
                src={propertyLogo}
                alt="property"
              />
            ) : (
              <Box
                component="button"
                sx={{
                  background: '#FFFFFF',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <img
                  className="uploadimg"
                  src={'/Images/uploadImg.png'}
                  alt="upload"
                />
                <input
                  type="file"
                  accept="image/*"
                  style={hideInput1}
                  onChange={(e) => handleUploadLogo(e)}
                />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box sx={{ py: 2 }}>
            <Typography>Upload Property Brochure</Typography>
            {propertyBrochure.length > 0 ? (
              <>
                {propertyBrochure.map((item, index) => (
                  <a target="_blank" rel="noreferrer" href={item} key={index}>
                    {item.includes('doc') ? (
                      <Article sx={{ fontSize: '130px' }} />
                    ) : (
                      <PictureAsPdfIcon sx={{ fontSize: '130px' }} />
                    )}
                  </a>
                ))}
                <Box
                  component="button"
                  sx={{
                    background: '#FFFFFF',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    className="uploadimg"
                    src={'/Images/uploadImg.png'}
                    alt="upload"
                  />
                  <input
                    type="file"
                    accept=".doc,.pdf"
                    style={hideInput2}
                    onChange={(e) => handleUploadBrochure(e)}
                  />
                </Box>
              </>
            ) : (
              <Box
                component="button"
                sx={{
                  background: '#FFFFFF',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <img
                  className="uploadimg"
                  src={'/Images/uploadImg.png'}
                  alt="upload"
                />
                <input
                  type="file"
                  accept=".doc,.pdf"
                  style={hideInput2}
                  onChange={(e) => handleUploadBrochure(e)}
                />
              </Box>
            )}
          </Box>
        </Grid>
      </Grid> */}
      <Divider
        sx={{
          height: '1.5px',
          background: '#E0E4EC',
          marginTop: '73px',
          marginBottom: '34px',
        }}
      />
      <form onSubmit={handleSubmit}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label>Select Builder*</label>
              {/* <select value={selectedBuilder} onChange={(e) => setSelectedBuilder(e.target.value)}>
                <option value="" disabled>
                  Select Builder
                </option>
                {builders.length > 0 &&
                  builders.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item?.companyName ? item?.companyName : "--"}
                    </option>
                  ))}
              </select> */}
              <Autocomplete
                onChange={(event, value) => {
                  console.log(value)
                  if (value) {
                    setSelectedBuilder(value);
                  }
                }}
                disabled
                value={selectedBuilder || { id: '123', companyName: "" }}
                disablePortal
                name="builder"
                id="combo-box-demo"
                options={builders.map((item) => ({ id: item._id, companyName: item.companyName || "--" }))}
                getOptionLabel={(option) => option.companyName}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField className='inputBox' {...params} name='builder' />}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Builder Discount</label>
              <input
                value={builderDiscount}
                onChange={(e) => setBuilderDiscount(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Builder Discount"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Property Name*</label>
              <input
                value={propertyName}
                disabled
                onChange={(e) => setPropertyName(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Property Name"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Property Location*</label>
              {/* <input
                value={propertyLocation}
                onChange={(e) => setPropertyLocation(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Property Location"
              /> */}
              <Autocomplete
                onChange={(event, value1) => {
                  setPropertyLocation(value1?.label);
                }}
                disablePortal
                value={propertyLocation}
                name="location"
                id="combo-box-demo"
                options={locationSearch}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField className='inputBox' {...params} name='location' value={propertyLocation} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} />}
              />
            </Grid>

            <Grid item xs={6}>
              <label>Property Liner*</label>
              <input
                value={propertyLiner}
                onChange={(e) => setPropertyLiner(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Property Liner"
              />
            </Grid>

            <Grid item xs={6}>
              <label>Sub Company*</label>
              {/* <select value={subCompany} onChange={(e) => setSubCompany(e.target.value)}>
                <option selected disabled>Choose sub company</option>
                {allSubCompanies && allSubCompanies.length && allSubCompanies.map((item, index) => (
                  <>
                    {
                      item.isDeleted
                        ?
                        ""
                        :
                        <option value={item._id} key={index}>{item.subCompanyName}</option>
                    }
                  </>
                ))}
              </select> */}
              <Autocomplete
              disabled
                onChange={(event, value) => {
                  console.log(value)
                  if (value) {
                    setSubCompany(value);
                  }
                }}
                value={subCompany || { id: '123', subCompanyName: "" }}
                disablePortal
                name="builder"
                id="combo-box-demo"
                options={allSubCompanies.map((item) => ({ id: item._id, subCompanyName: item.subCompanyName || "--" }))}
                getOptionLabel={(option) => option.subCompanyName}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField className='inputBox' {...params} name='builder' />}
              />
            </Grid>

            <Grid item xs={6}>
              <label>Construction Status*</label>
              <select value={constructionStatus} onChange={(e) => setConstructionStatus(e.target.value)}>
                <option value="" disabled>
                  Construction Status
                </option>
                <option value="Ready to Move">Ready to move</option>
                <option value="Under Construction">Under Construction</option>
                <option value="New Launch">New Launch</option>
                <option value="Other">Other</option>
              </select>
            </Grid>

            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <label>Possession Date*</label>
                {constructionStatus == 'Ready to Move' && (
                  <Tooltip title="this field will be disabled for 'ready to move construction status' construction status">
                    <Info sx={{ fontSize: '1rem' }} />
                  </Tooltip>
                )}
              </Stack>

              <input
                value={possessionDate}
                onChange={(e) => setPossessionDate(e.target.value)}
                className="inputBox"
                type="date"
                placeholder="Possession Date"
                disabled={constructionStatus == 'Ready to Move'}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Property Type*</label>
              <Tooltip title="can not update property type once property type has been created">
                <Info sx={{ fontSize: '1rem' }} />
              </Tooltip>
              <select
                value={selectedProperty}
                disabled
                onChange={(e) => { setSelectedProperty(e.target.value); loadAllPropertySubCategory(e.target.value) }}
              >
                <option value="" disabled>
                  Property Type
                </option>
                {propertyCategories.length > 0 &&
                  propertyCategories.map((item, index) => (
                    <>
                      {
                        item.isDeleted
                          ?
                          ""
                          :
                          <option value={item._id} key={index}>
                            {item.name}
                          </option>
                      }
                    </>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6}>
              <label>Sub . Category*</label>
              <Tooltip title="can not update sub property type once property type has been created">
                <Info sx={{ fontSize: '1rem' }} />
              </Tooltip>
              <select value={subCategoryType} disabled onChange={(e) => setSubCategoryType(e.target.value)}>
                {subCategory &&
                  subCategory.map((item) => (
                    <>
                      {
                        item.isDeleted
                          ?
                          ""
                          :
                          <option key={item.name}>{item.name}</option>
                      }
                    </>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6}>
              <label>Location Latitude*</label>
              <input
                value={latitutde}
                onChange={(e) => setLatitude(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Location Latitude"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Location Longitude*</label>
              <input
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Location Longitude"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Amenities</label>
              <FormControl sx={{ m: 1, width: '100%', display: 'block' }}>
                <InputLabel id="amenties">Amenities</InputLabel>
                <Select
                  labelId="amenties"
                  id="demo-multiple-name"
                  multiple
                  value={amenties}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={handleChange}
                >
                  {allAmenities.length > 0
                    ?
                    allAmenities.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name} : (quantity: {item.quantity})
                      </MenuItem>
                    ))
                    :
                    ""}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <label>Loan Approved by</label>
              <FormControl sx={{ m: 1, width: '100%', display: 'block' }}>
                <InputLabel id="loans">Loan Approved by</InputLabel>
                <Select
                  labelId="loans"
                  id="demo-multiple-name2"
                  multiple
                  value={loanApprovedBy}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={handleLoanApprovedChange}
                >
                  {banks.length > 0 &&
                    banks.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <label>Location Advantage</label>
              <FormControl sx={{ m: 1, width: '100%', display: 'block' }}>
                <InputLabel id="advantage">Location Advantage</InputLabel>
                <Select
                  labelId="advantage"
                  id="demo-multiple-name1"
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  value={JSON.stringify(advantages)}
                  onChange={(e) => setAdvantages(JSON.parse(e.target.value))}
                >
                  {allLocationAdvantages.length > 0 &&
                    allLocationAdvantages.map((item, index) => (
                      <MenuItem key={index} value={JSON.stringify(item)}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5} sx={{ m: 0.3 }}>
              <label>Distance (in mtr)</label>
              <input
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Enter distance"
              />
            </Grid>
            <Grid item xs={1}>
              <p
                onClick={handleAddLocationAdvantage}
                style={{
                  borderRadius: '50%',
                  background: '#278FD9',
                  color: '#FFFFFF',
                  fontWeight: '600',
                  padding: '1px 16px',
                  fontSize: '35px',
                  width: '54px',
                  height: '54px',
                  marginTop: '25px',
                  cursor: 'pointer',
                }}
              >
                +
              </p>
            </Grid>
            {
              locationAdvantages?.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Location Advantage</TableCell>
                        <TableCell align="center">Distance</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationAdvantages.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {tempLocationAdvantageIndex === index ? (
                              <select
                                labelId="advantage"
                                id="demo-multiple-name2"
                                style={{
                                  width: '100%',
                                  background:
                                    'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                }}
                                value={JSON.stringify(tempLocationAdvantage)}
                                onChange={(e) => setTempLocationAdvantage(JSON.parse(e.target.value))}
                              >
                                {allLocationAdvantages.length > 0 &&
                                  allLocationAdvantages.map((item, index) => (
                                    <option key={index} value={JSON.stringify(item)}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                            ) : (
                              item.name
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tempLocationAdvantageIndex === index ? (
                              <input
                                value={tempDistance}
                                onChange={(e) => setTempDistance(e.target.value)}
                                className="inputBox"
                                type="text"
                                placeholder="Plan"
                              />
                            ) : (
                              item.distance
                            )}
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: 'center' }}>
                              {tempLocationAdvantageIndex === index ? (
                                <Done
                                  onClick={() => handleUpdateLocationAdvantage(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ModeEditOutline
                                  onClick={() => handleEditLocationAdvantage(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <Delete
                                onClick={() => handleRemoveLocationAdvantage(index)}
                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            }

            <Grid item xs={6}>
              <label>About The Project</label>
              <JoditEditor
                ref={editor}
                onBlur={(newContent) => setAbout(newContent)}
                value={about}

              />
            </Grid>
            <Grid item xs={12}>
              <label>Project Specification</label>
              <JoditEditor
                ref={editor}
                onBlur={(newContent) => setSpecification(newContent)}
                value={specification}

              />
            </Grid>
            <Grid item xs={12} mt={20}>
              <label>Terms & Conditions</label>
              <JoditEditor
                ref={editor}
                onBlur={(newContent) => setTerms(newContent)}
                value={terms}

              />
            </Grid>

            <Grid item xs={4}>
              <label>Payment Plan(%)*</label>
              <input
                onChange={(e) => setPayment(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Payment Plan"
              />
            </Grid>
            <Grid item xs={7}>
              <label>Milestone</label>
              <input
                onChange={(e) => setMileStone(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Milestone"
              />
            </Grid>
            <Grid item xs={1}>
              <p
                onClick={handleCreatePlan}
                style={{
                  borderRadius: '50%',
                  background: '#278FD9',
                  color: '#FFFFFF',
                  fontWeight: '600',
                  padding: '1px 16px',
                  fontSize: '35px',
                  width: '54px',
                  height: '54px',
                  marginTop: '25px',
                  cursor: 'pointer',
                }}
              >
                +
              </p>
            </Grid>
            {paymentPlans.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Payment %</TableCell>
                      <TableCell align="center">Milestone</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentPlans.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {tempIndex === index ? (
                            <input
                              value={tempPlan}
                              onChange={(e) => setTempPlan(e.target.value)}
                              className="inputBox"
                              type="text"
                              placeholder="Milestone"
                            />
                          ) : (
                            item.payment
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempIndex === index ? (
                            <input
                              value={tempMilestone}
                              onChange={(e) => setTempMilestone(e.target.value)}
                              className="inputBox"
                              type="text"
                              placeholder="Plan"
                            />
                          ) : (
                            item.milestone
                          )}
                        </TableCell>
                        <TableCell>
                          <p style={{ textAlign: 'center' }}>
                            {tempIndex === index ? (
                              <Done
                                onClick={() => handleUpdatePackage(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <ModeEditOutline
                                onClick={() => handleEditPackage(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                            <Delete
                              onClick={() => handleRemoveFromPlan(index)}
                              sx={{ color: '#EA000D', cursor: 'pointer' }}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </Grid>
        </Box>

        <Box mt={6}>
          <Typography variant="h6" mb={2}>
            Price Trend
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label>Locality</label>
              <input
                onChange={(e) => setWithLocality(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Locality"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Current Price</label>
              <input
                onChange={(e) => setCurrentPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Current Price"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Price For Last 1 Year</label>
              <input
                onChange={(e) => setFirstYearPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Price For Last 1 Year"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Price For Last 2 Year</label>
              <input
                onChange={(e) => setSecondYearPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Price For Last 2 Year"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Price For Last 3 Year</label>
              <input
                onChange={(e) => setThirdYearPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Price For Last 3 Year"
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={9}>
                  <label>Price For Last 4 Year</label>
                  <input
                    onChange={(e) => setFourthYearPrice(e.target.value)}
                    className="inputBox"
                    type="text"
                    placeholder="Price For Last 4 Year"
                  />
                </Grid>
                <Grid item xs={3}>
                  <p
                    onClick={handleCreatePriceTrends}
                    style={{
                      marginLeft: '15px',
                      borderRadius: '50%',
                      background: '#278FD9',
                      color: '#FFFFFF',
                      fontWeight: '600',
                      padding: '1px 16px',
                      fontSize: '35px',
                      width: '54px',
                      height: '54px',
                      marginTop: '25px',
                      cursor: 'pointer',
                    }}
                  >
                    +
                  </p>
                </Grid>
              </Grid>
            </Grid>
            {trends.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Locality</TableCell>
                      <TableCell align="center">Current Price</TableCell>
                      <TableCell align="center">Last 1 year</TableCell>
                      <TableCell align="center">Last 2 year</TableCell>
                      <TableCell align="center">Last 3 year</TableCell>
                      <TableCell align="center">Last 4 year</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trends.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempLocality}
                              onChange={(e) => setTempLocality(e.target.value)}
                              className="inputBox"
                              type="text"
                              placeholder="locality"
                            />
                          ) : (
                            item.withLocality
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempCurrentPrice}
                              onChange={(e) =>
                                setTempCurrentPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Current Price"
                            />
                          ) : (
                            item.currentPrice
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempFirstPrice}
                              onChange={(e) =>
                                setTempFIrstPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 1 year price"
                            />
                          ) : (
                            item.last1Year
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempSecondPrice}
                              onChange={(e) =>
                                setTempSecondPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 2 year price"
                            />
                          ) : (
                            item.last2year
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempThirdPrice}
                              onChange={(e) =>
                                setTempThirdPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 3 year price"
                            />
                          ) : (
                            item.last3Year
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempFourthPrice}
                              onChange={(e) =>
                                setTemoFourthPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 4 year price"
                            />
                          ) : (
                            item.last4Year
                          )}
                        </TableCell>
                        <TableCell>
                          <p style={{ textAlign: 'center' }}>
                            {tempPriceIndex === index ? (
                              <Done
                                onClick={() => handleUpdatePriceTrends(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <ModeEditOutline
                                onClick={() => handleEditPriceTrends(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                            <Delete
                              onClick={() => handleRemoveFromPriceTrends(index)}
                              sx={{ color: '#EA000D', cursor: 'pointer' }}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </Grid>
        </Box>

        {isLoading ?
          <Button
            disabled
            type="submit"
            style={{
              marginTop: '44px',
              width: '224px',
              height: '52px',
              borderRadius: '8.62px',
              background: '#278FD9',
              color: '#FFFFFF',
              fontWeight: '700',
              fontSize: '19px',
              marginBottom: '33px',
            }}
          >
            <CircularProgress size={24} sx={{ color: '#fff' }} />
          </Button> :
          <Button
            type="submit"
            style={{
              marginTop: '44px',
              width: '224px',
              height: '52px',
              borderRadius: '8.62px',
              background: '#278FD9',
              color: '#FFFFFF',
              fontWeight: '700',
              fontSize: '19px',
              marginBottom: '33px',
            }}
          >
            Next
          </Button>
        }


      </form>
    </Box>
  );
};

export default PropertyUpdateForm;
