import React, { useCallback, useEffect } from "react";
import Layout from "../../../assets/Layout";
import { BoughtData } from "../../../utils/data";
import Rating from "@mui/material/Rating";
import { sectionBarBtn } from "../../../Style/stylesheet";

import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";

import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";

import { useState } from "react";
import ClaimsRemarks from "../../Claims/ClaimsRemarks";
import Breadcrumbs from "../../../assets/Breadcrumbs";
import ButtonController from "../../../assets/ButtonController";
import SearchFilter from "../../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllreferals,
  getAllVisits,
  updateVisitById,
} from "../../../actions";
import { enqueueSnackbar } from "notistack";
// import VisitRemarks from "./../VisitRemarks";

const Referal = () => {
  const [sectionBarActive, setSectionBarActive] = useState("Paid");
  const [sectionBarActive2, setSectionBarActive2] = useState("Referal");
  const [status, setStatus] = useState("");
  const [addRemarkAction, setAddRemarkAction] = useState(false);
  const [allVisits, setAllVisits] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState("");
  const [remark, setRemark] = useState("");
  const [claimId, setClaimId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headData = [
    "refer by id",
    "refer to id",
    "Transaction Id",
    "Transaction date",
    "Transaction Amount",
    "beneficiary Bank Name",
    "beneficiary ifsc",
    "beneficiary account number",
    "beneficiary name",
  ];

  const [referals, setReferals] = useState([]);
  const [allReferals, setAllReferals] = useState([]);

  useEffect(() => {
    dispatch(
      getAllreferals(
        {},
        (data) => {
          const filter = data?.data.filter((item) => {
            if (
              item?.refererId?._id
                ?.toLowerCase()
                ?.includes(searchQuery?.toLowerCase())
            ) {
              return true;
            }

            if (
              item?.BrokerId?._id
                ?.toLowerCase()
                ?.includes(searchQuery?.toLowerCase())
            ) {
              return true;
            }

            return false;
          });
          if (searchQuery) {
            setReferals(filter);
          }
          console.log(data.data);
          setAllReferals(data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [searchQuery]);

  useEffect(() => {
    dispatch(
      getAllreferals(
        { pageSize: countPerPage, pageNo: currentPage },
        (data) => {
          if (searchQuery == "") {
            setReferals(data.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [currentPage, countPerPage, searchQuery]);

  return (
    <>
      <Layout path="normalVisits">
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Transaction History" />
          {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Received" && "active"}
            onClick={() => navigate("/transaction-history/recieved-invoices")}
          >
            Received
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Paid" && "active"}
            onClick={() => navigate("/transaction-history/paid-claims")}
          >
            Paid
          </Button>
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
          mx={20}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive2 === "Claims" && "active"}
            onClick={() => navigate("/transaction-history/paid-claims")}
          >
            Claims
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive2 === "Referal" && "active"}
            onClick={() => navigate("/transaction-history/paid-referal")}
          >
            Referal
          </Button>
        </Stack>

        <SearchFilter my={2}>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </SearchFilter>

        <Paper sx={{ width: "100%", overflow: "hidden", my: 2 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {referals.map((item, index) => (
                  <TableRow sx={{ bgcolor: "#f9fafc" }} key={index}>
                    {/* <TableCell align="left">
                        <Checkbox />
                      </TableCell> */}
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell>
                      {item?.refererId ? item?.refererId?._id : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.BrokerId ? item?.BrokerId?._id : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.transactionId ? item?.transactionId : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.transactionDate
                        ? new Date(item?.transactionDate).toLocaleDateString()
                        : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.transactionAmount ? item?.transactionAmount : "--"}
                    </TableCell>
                    <TableCell>
                      {item?.benificiery ? item?.benificiery : "--"}
                    </TableCell>
                    <TableCell>{item?.ifsc ? item?.ifsc : "--"}</TableCell>
                    <TableCell>
                      {item?.account ? item?.account : "--"}
                    </TableCell>
                    <TableCell>{item?.name ? item?.name : "--"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allReferals?.length ? allReferals?.length : currentPage * countPerPage} of {allReferals?.length}</Typography> */}
            <Typography>
              {allReferals?.length > 0
                ? (currentPage - 1) * countPerPage + 1
                : 0}{" "}
              -{" "}
              {countPerPage > allReferals?.length
                ? allReferals?.length
                : currentPage * countPerPage > allReferals?.length
                ? allReferals?.length
                : currentPage * countPerPage}{" "}
              of {allReferals?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      currentPage === 1 || countPerPage > allReferals?.length
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      background:
                        currentPage === 1 || countPerPage > allReferals?.length
                          ? "#EFEFEF"
                          : "#FFF",
                    }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/
                    {Math.ceil(allReferals?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > allReferals?.length ||
                      currentPage ==
                        Math.ceil(allReferals?.length / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Layout>

      {/* <VisitRemarks
                remark={remark}
                setRemark={setRemark}
                claimId={claimId}
                claimStatus={status}
                addRemarkAction={addRemarkAction}
                setAddRemarkAction={setAddRemarkAction}
            /> */}
    </>
  );
};

export default Referal;
