import { Check, Close, Download, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Icon,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { getBrokerDetails, updateBrokerInfo, uploadDocToS3 } from '../../actions';
import CircularProgress from '@mui/material/CircularProgress';
import regex from '../../utils/regex';
import ClearIcon from '@mui/icons-material/Clear';
import { selectBar, selectBarBtn } from '../../Style/stylesheet';
import BankDetails from './BankDetails';
import Preferences from './Preferences';
import UpdateBankDetails from './UpdateBankDetails';
import UpdatePreferences from './UpdatePreferences';
import { enqueueSnackbar } from 'notistack';

export default function Profile({ profileAction, setProfileAction, brokerId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [panError, setPanError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const closeHandler = () => setProfileAction(false);
  const dispatch = useDispatch()
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
  };
  const [broker, setBroker] = useState({
    name: "",
    id: "",
    phoneNumber: "",
    email: "",
    panNumber: "",
    reraRegistrationNumber: "",
    address: "",
    profilePicture: "",
    documents: [],
  });
  const [profileImage, setProfileImage] = useState(null);
  const [profilePicView, setProfilePicView] = useState(null)
  const [documents, setDocuments] = useState([]);
  const [documentsView, setDocumentsView] = useState([]);
  const [step, setStep] = useState(1);
  useEffect(() => {
    dispatch(
      getBrokerDetails({ id: brokerId }, (data) => {
        if (data && data.length > 0) {
          const brokerData = data[0];
          setBroker(prevState => ({
            ...prevState,
            name: brokerData?.name,
            id: brokerData?._id,
            phoneNumber: brokerData?.phoneNumber,
            email: brokerData?.email,
            panNumber: brokerData?.panNumber,
            reraRegistrationNumber: brokerData?.reraRegistrationNumber,
            address: brokerData?.address,
          }));
          setProfilePicView(brokerData?.profilePicture)
          setDocuments(brokerData?.documents)
        }
      })
    );
  }, [brokerId, dispatch]);

  const handleInputChange = (field, value) => {
    setBroker((prevBroker) => ({
      ...prevBroker,
      [field]: value,
    }));
  };

  // Regex
  React.useEffect(() => {
    if (broker.phoneNumber) {
      const isPhoneValid = regex.numberPattern.test(broker.phoneNumber)
      setPhoneError(!isPhoneValid)
    }
  }, [broker.phoneNumber])

  React.useEffect(() => {
    if (broker.email) {
      const isEmailValid = regex.emailPattern.test(broker.email)
      setEmailError(!isEmailValid)

    }
  }, [broker.email])

  React.useEffect(() => {
    if (broker.panNumber) {
      const isPanValid = regex.panPattern.test(broker.panNumber)
      setPanError(!isPanValid)

    }
  }, [broker.panNumber])

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = new FormData()
    formData.append("id", broker?.id)
    formData.append("name", broker?.name)
    formData.append("email", broker?.email)
    formData.append("phoneNumber", broker?.phoneNumber)
    formData.append("panNumber", broker?.panNumber)
    formData.append("reraRegistrationNumber", broker?.reraRegistrationNumber)
    formData.append("address", broker?.address)
    if (profileImage) {
      formData.append("profilePicture", profileImage)
    }
    if (documents.length > 0) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("documents", documents[i]);
      }
    }

    dispatch(updateBrokerInfo(formData, (data) => {
      if (data.status === 200) {
        enqueueSnackbar("Details Added Successfully!", { variant: 'success' })
        setStep(2);
        setIsLoading(false);
      }
      else {
        setIsLoading(false);

      }
      // console.log("Here is Data:", data)
      // setIsLoading(false);
      // setStep(2)
    }))

  }

  const handleProfileImageChange = (event) => {

    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfilePicView(URL.createObjectURL(file));
    }
  };

  const handleRemovePropertyBrochure = (index) => {
    const newArr = documents.filter((item, index1) => index != index1);
    setDocuments(newArr);
  }

  const handleDocumentsChange = (event) => {
    const files = event.target.files;
    const reader = new FileReader();
    var formData = new FormData();
    for (var i = 0; i < files?.length; i++) {
      formData.append('documents', files[i]);
      dispatch(uploadDocToS3(formData, (data) => {
        setDocuments(oldArr => [...oldArr, data.data.documents[0]]);
        setDocumentsView(oldArr => [...oldArr, data.data.documents[0]]);
      }, (err) => {
        console.log(err);
      }));
    }

  };

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "3.3%",
    zIndex: 10,
    width: "115px",
    height: "115px",
    cursor: "pointer",
    top: "26%"
  };

  return (
    <Dialog open={profileAction} onClose={closeHandler} maxWidth="md" fullWidth>
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="end"

          sx={{ mb: 3 }}
        >

          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>


        <Stack direction={'row'} sx={selectBar} spacing={3} className="col-3">
          <Box
            sx={selectBarBtn}
            className={`${step === 1 ? 'active' : step >= 2 && 'done'} box`}
            onClick={() => setStep(1)}
          >
            <Icon className="icon">{step >= 2 ? <Check /> : 1}</Icon>
            Onboarding
          </Box>
          <Box
            sx={selectBarBtn}
            className={`${step === 2 ? 'active' : step === 3 && 'done'} box`}
            onClick={() => setStep(2)}
          >
            <Icon className="icon">{step === 3 ? <Check /> : 2}</Icon>
            Bank Detail
          </Box>
          <Box
            sx={selectBarBtn}
            className={`${step === 3 ? 'active' : step === 4 && 'done'} box`}
            onClick={() => setStep(3)}
          >
            <Icon className="icon">{step === 4 ? <Check /> : 3}</Icon>
            Preferences
          </Box>
        </Stack>


        {
          step == 1
            ?

            <Box component={'form'} flexGrow={1}>
              <div>
                <Typography variant="title" component="h3">
                  {broker?.name}
                </Typography>
              </div>
              <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Typography sx={{ fontSize: '0.8rem', margin: '0', color: '#4C535F' }}>Upload Profile Image</Typography>
                    <Box
                      component="button"
                      sx={{ background: "#FFFFFF", border: "none", cursor: "pointer", mt: 2, mr: 2 }}
                    >
                      {profilePicView
                        ?
                        <img
                          src={profilePicView}
                          alt="profile"
                          className="img-fluid"
                          style={{ width: '130px', height: '130px' }}
                        />
                        :
                        <img
                          src='/uploadImg.png'
                          alt="profile"
                          style={{ width: '125px' }}
                          className="img-fluid"
                        />
                      }
                      <input
                        type="file"
                        name="profileImage"
                        id="profileImage"
                        style={hideInput}
                        accept='image/*'
                        onChange={handleProfileImageChange}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <UploadDataBox>
                      <UploadData name="Upload your documents">
                        <label htmlFor="document-upload" style={{ display: 'block', cursor: 'pointer' }}>
                          <Box sx={{ display: 'flex' }}>
                            {documents?.length > 0 ?
                              documents?.map((item, index) => (
                                <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                  <embed src={item} width="200" height="200" />
                                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '30px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                  </IconButton>
                                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                                    <a href={item} download>
                                      <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                                    </a>
                                  </IconButton>
                                </Box>
                              ))
                              :
                              <></>
                            }
                            <UploadDataCard>
                              <img
                                src={'/uploadImg.png'}
                                alt="upload"
                                className="img-fluid"
                              />
                              <input
                                type="file"
                                accept=".doc,.pdf"
                                onChange={handleDocumentsChange}
                              />
                            </UploadDataCard>
                          </Box>
                        </label>
                      </UploadData>
                    </UploadDataBox>
                  </Box>
                </Box>

              <Divider sx={{ my: 4 }} />
              <Box>
                <Grid container spacing={3}>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="Broker Name">
                      <input type="text" name="" id="" value={broker?.name} onChange={(e) => handleInputChange('name', e.target.value)} />
                    </InputStyle>
                  </Grid>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="Broker ID">
                      <input type="text" value={broker?.id} onChange={(e) => handleInputChange('id', e.target.value)} />
                    </InputStyle>
                  </Grid>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="Phone Number">
                      <input type="text" value={broker?.phoneNumber} onChange={(e) => handleInputChange('phoneNumber', e.target.value)} />
                      {phoneError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
                    </InputStyle>
                  </Grid>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="Email">
                      <input type="email" value={broker?.email} onChange={(e) => handleInputChange('email', e.target.value)} />
                      {emailError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}

                    </InputStyle>
                  </Grid>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="PAN Number">
                      <input type="text" value={broker?.panNumber} onChange={(e) => handleInputChange('panNumber', e.target.value)} />
                      {panError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}

                    </InputStyle>
                  </Grid>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="RERA Registration Number">
                      <input type="text" value={broker?.reraRegistrationNumber} onChange={(e) => handleInputChange('reraRegistrationNumber', e.target.value)} />
                    </InputStyle>
                  </Grid>
                  <Grid item md={6} flexGrow={1}>
                    <InputStyle name="Address">
                      <input type="text" value={broker?.address} onChange={(e) => handleInputChange('address', e.target.value)} />
                    </InputStyle>
                  </Grid>
                  <Grid item md={12} xs={12} flexGrow={1}>
                    <Stack direction={'row'} spacing={3}>
                      {
                        isLoading ?
                          <Button disabled variant="contained" sx={ButtonStyle} onClick={() => handleSubmit()}>
                            <CircularProgress size={24} sx={{ color: '#fff' }} />
                          </Button>
                          :
                          <Button variant="contained" sx={ButtonStyle} onClick={() => handleSubmit()}>
                            Save
                          </Button>
                      }
                      <Button
                        variant="outlined"
                        sx={ButtonStyle}
                        onClick={closeHandler}
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            :
            step == 2
              ?
              <UpdateBankDetails setStep={setStep} brokerId={brokerId} />
              :
              <UpdatePreferences setStep={setStep} setAddBrokerAction={setProfileAction} brokerId={brokerId} />
        }

      </DialogContent>
    </Dialog>
  );
}
