import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Icon,
} from '@mui/material';
import React, { useState } from 'react';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { addNewBroker, updateBrokerBankInfo } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import regex from '../../utils/regex';
import CircularProgress from '@mui/material/CircularProgress';
import { selectBar, selectBarBtn } from '../../Style/stylesheet';
import { Check } from '@mui/icons-material';

export default function BankDetails({ setStep, brokerId }) {
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [recipientName, setReceipentName] = useState("");


  const dispatch = useDispatch();

  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
  };

  const handleSubmit = () => {
    if (!bankName || !accountNumber || !ifscCode || !recipientName) {
      enqueueSnackbar('Please fill in all required fields', { variant: 'error' });

      return;
    }
    let payload = {
      id: brokerId,
      bankName,
      accountNumber,
      ifscCode,
      recipientName
    };
    dispatch(
      updateBrokerBankInfo(payload, (data) => {
        if (data.status === 200) {
          enqueueSnackbar("Broker Added Successfully!", { variant: 'success' });
          setBankName("");
          setAccountNumber("");
          setConfirmAccountNumber("");
          setIfscCode("");
          setReceipentName("");
          setStep(3);
        }
        else {
          enqueueSnackbar("Error While Adding New Broker!", { variant: 'error' })
        }
      }
      )
    )
  };

  const handleReset = () => {
    setBankName("");
    setAccountNumber("");
    setIfscCode("");
    setReceipentName("");
    setConfirmAccountNumber("")
  };

  return (
    <>
      <div>
        <Typography variant="title" component="h3">
          Broker Bank Details
        </Typography>
      </div>
      <Box component={'from'} flexGrow={1}>
        <Divider sx={{ my: 4 }} />
        <Box>
          <Grid container spacing={3}>
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="Bank Name*">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter bank name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </InputStyle>
            </Grid>
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="Account Number*">
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Enter account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </InputStyle>
            </Grid>

            {/* Email input */}
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="Confirm Account Number*">
                <input
                  type="text"
                  placeholder="Re-enter account number"
                  value={confirmAccountNumber}
                  onChange={(e) => setConfirmAccountNumber(e.target.value)}
                />
              </InputStyle>
              {confirmAccountNumber !== accountNumber ? <span style={{ color: 'red' }}>Invalid input</span> : ""}
            </Grid>

            {/* PAN Number input */}
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="IFSC Code">
                <input
                  type="text"
                  name="panNumber"
                  placeholder="Enter IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                />
              </InputStyle>
            </Grid>

            {/* Address input */}
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="Recipient Name*">
                <input
                  type="text"
                  name="address"
                  placeholder="Enter Recipients Name"
                  value={recipientName}
                  onChange={(e) => setReceipentName(e.target.value)}
                />
              </InputStyle>
            </Grid>

            <Grid item md={12} xs={12} flexGrow={1}>
              <Stack direction={'row'} spacing={3}>
                <Button variant="contained" sx={ButtonStyle} onClick={handleSubmit}>
                  Next
                </Button>
                <Button variant="outlined" sx={ButtonStyle} onClick={handleReset}>
                  Reset
                </Button>
              </Stack>
            </Grid>

          </Grid>
        </Box>
      </Box>
    </>
  );
}
