import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../assets/Layout';
import {
    Typography,
    Box,
    Stack,
    Paper,
    Grid,
    Button,
    CircularProgress,
    IconButton,
    Card,
} from '@mui/material';
import Breadcrumbs from '../../assets/Breadcrumbs';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllClaims, getClaimById, getClaimPDFByID, getPaymentPDFByID, updateClaimByAdmin } from '../../actions';
import ClaimsRejectionReason from './ClaimsRejectionReason';
import ClaimsApproveForm from './ClaimsApproveForm';
import TransactionDetailsForm from './TransactionDetailsForm';
import { enqueueSnackbar } from 'notistack';
import { Download } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import html2pdf from 'html2pdf.js';
export default function VisitClaim() {
    const [claimDetails, setClaimDetails] = useState([]);
    const [propertyClaims, setPropertyClaims] = useState([]);
    const [status, setStatus] = useState('');
    const [remarks, setRemarks] = useState('');
    const [claimId, setClaimId] = useState('');
    const [rejectionAction, setRejectionAction] = useState(false);
    const [approveAction, setApproveAction] = useState(false);
    const [transactionDetailsAction, setTransactionDetailsAction] = useState(false);
    const [isGstApplicable, setIsGstApplicable] = useState(false);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { id } = useParams();
    const loadClaimById = useCallback(() => {
        dispatch(
            getClaimById({ id }, (data) => {
                setClaimDetails(data.data);
                setIsGstApplicable(data.data[0].isGstApplicable);
                setStatus(data.data[0].claimStatus);
                setRemarks(data.data[0].remark);
                dispatch(
                    getAllClaims(
                        { boughtPropertyId: data.data[0].boughtPropertyId._id },
                        (data) => {
                            setPropertyClaims(data.data);
                        }
                    )
                );
            })
        );
    }, [dispatch]);

    useEffect(() => {
        loadClaimById();
    }, [loadClaimById]);

    const handleChange = (event, claimId) => {
        setStatus(event.target.value);
        setClaimId(claimId);
        claimDetails[0].status = event.target.value;
        if (event.target.value == 'rejected') {
            setRejectionAction(true);
        } else {
            setRejectionAction(false);
        }
        if (event.target.value == 'approved') {
            setApproveAction(true);
        } else {
            setApproveAction(false);
        }
        if (event.target.value == 'paid') {
            setTransactionDetailsAction(true)
        } else {
            setTransactionDetailsAction(false);
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        let payload = {
            id: id,
            remark: remarks,
        };

        dispatch(
            updateClaimByAdmin(
                payload,
                (data) => {
                    if (data.status == 200) {
                        enqueueSnackbar(data?.message, { variant: 'success' });
                        setLoading(false);
                    }
                },
                (err) => {
                    enqueueSnackbar('Error Occured! please try again', {
                        variant: 'error',
                    });
                }
            )
        );
    };

    const handleDownload = (fileUrl) => {
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.setAttribute('download', '');
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    const handleGSTCheckboxChange = (event) => {
        let payload = {
            id: id,
            isGstApplicable: event.target.checked,
        };

        dispatch(
            updateClaimByAdmin(
                payload,
                (data) => {
                    enqueueSnackbar(data?.message, { variant: 'success' });
                    setIsGstApplicable(data.data.isGstApplicable);
                },
                (err) => {
                    enqueueSnackbar('Error Occured! please try again', {
                        variant: 'error',
                    });
                }
            )
        );
    };

    function formatAmount(input) {
        let number = parseFloat(input || "0.0");

        if (number >= 10000000) {
            let cr = number / 10000000.0;
            return `${cr.toFixed(2)}Cr`;
        } else if (number >= 100000) {
            let l = number / 100000.0;
            return `${l.toFixed(2)}L`;
        } else if (number >= 1000) {
            let thousand = number / 1000.0;
            return `${thousand.toFixed(2)}K`;
        } else {
            return `${number.toFixed(2)}`;
        }
    }

    const handleClaimPDFDownload = (claimId) => {
        dispatch(getClaimPDFByID(claimId, (data) => {
            console.log(data);
            const invoiceHtml = data; // Assuming data contains the HTML content

            const options = {
                filename: 'claim.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html2pdf().from(invoiceHtml).set(options).save();
        }, (err) => {
            console.log(err);
        }))
    }

    const handlePaymentPDFDownload = (invoiceId) => {
        dispatch(getPaymentPDFByID(invoiceId, (data) => {
            console.log(data);
            const invoiceHtml = data; // Assuming data contains the HTML content

            const options = {
                filename: 'payment.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            html2pdf().from(invoiceHtml).set(options).save();
        }, (err) => {
            console.log(err);
        }))
    }

    return (
        <Layout>
            <Box>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary='Visit Claim' />
                </Stack>
                <Paper>
                    <Box padding={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                                    Property Name
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    {claimDetails[0]?.propertyId?.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                                    Visit ID
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    {claimDetails[0]?.visitId?._id}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                                    Visit Date
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    {new Date(
                                        claimDetails[0]?.visitId?.createdAt
                                    ).toLocaleDateString('en-GB', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                                    Builder Name
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    {claimDetails[0]?.builderId?.companyName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                                    Customer Name
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    {claimDetails[0]?.visitId?.clientName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                                    Unit Type
                                </Typography>
                                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                    {claimDetails[0]?.visitId?.unitType.join(", ")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>

            <Box>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary='Property Claim' />
                </Stack>
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box padding={4}>
                        <Typography sx={{ fontSize: '40px' }}>
                            ₹ {claimDetails[0]?.brokerageAmount}
                        </Typography>
                    </Box>
                    {/* <Box padding={4}>
            <Typography pb={2}>Builder Form</Typography>
            <PictureAsPdfIcon fontSize='large' />
          </Box> */}
                </Paper>
            </Box>

            <Box>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary='Visits Details' />
                </Stack>
                <Paper sx={{ my: 2 }}>
                    <Box padding={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Visit Amount</Typography>
                                <Typography sx={{ fontSize: '16px' }}>{claimDetails[0]?.brokerageAmount}</Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Claim ID</Typography>
                                <Typography sx={{ fontSize: '16px' }}>{id}</Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Claim PDF</Typography>
                                <IconButton onClick={() => handleClaimPDFDownload(id)}>
                                    <InsertDriveFileIcon
                                        sx={{
                                            width: {
                                                md: '2rem',
                                            },
                                            height: {
                                                md: '2rem',
                                            },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Payment Date</Typography>
                                <Typography sx={{ fontSize: '16px' }}>{claimDetails[0]?.paymentDate ? claimDetails[0]?.paymentDate : "--"}</Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                            </Grid>

                            <Grid item xs={6} md={3}>
                            </Grid>

                            <Grid item xs={6} md={3}>
                            </Grid>

                            <Grid item xs={6} md={3}>
                                <Typography sx={{ borderRadius: '20px', px: 6, mt: 1, border: '1px solid red', textAlign: 'center', color: 'red', padding: '5px 10px', width: '70%' }}>
                                    {claimDetails[0]?.claimStatus ? claimDetails[0]?.claimStatus : 'Claim'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>

            <form>
                {' '}
                <Grid container gap={5}>
                    <Grid item xs={12}>
                        <label>Status</label>
                        <select
                            value={claimDetails[0]?.claimStatus}
                            onChange={(e) => handleChange(e, claimDetails[0]?._id)}
                            disabled={claimDetails[0]?.claimStatus == "paid"}
                        >
                            <option value='' disabled>
                                Select
                            </option>
                            <option value={'submitted'}>Submitted</option>
                            <option value={'rejected'}>Rejected</option>
                            <option value={'approved'}>Approved</option>
                            <option value={'recieved'}>Payment Recieved</option>
                            <option value={'paid'}>Paid</option>
                        </select>
                    </Grid>
                    <Grid item xs={12}>
                        <label>Remarks</label>
                        <textarea value={remarks} onChange={(e) => setRemarks(e.target.value)} className='inputBox' style={{ resize: 'none', height: '300px', width: '100%' }} placeholder="Write here" disabled={claimDetails[0]?.claimStatus == "paid"}></textarea>
                    </Grid>
                </Grid>
                <Button
                    variant='contained'
                    size='large'
                    sx={{ mt: 3 }}
                    disabled={loading || claimDetails[0]?.claimStatus == "paid"}
                    onClick={handleSubmit}
                >
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </form>

            <ClaimsRejectionReason
                claimId={claimId}
                claimStatus={status}
                rejectionAction={rejectionAction}
                setRejectionAction={setRejectionAction}
            />
            <ClaimsApproveForm
                claimId={claimId}
                claimStatus={status}
                approveAction={approveAction}
                setApproveAction={setApproveAction}
                property={claimDetails[0]?.propertyId}
                broker={claimDetails[0]?.brokerId?._id}
            />

            <TransactionDetailsForm
                claimId={claimId}
                claimStatus={status}
                transactionDetailsAction={transactionDetailsAction}
                setTransactionDetailsAction={setTransactionDetailsAction}
            />
        </Layout>
    );
}
