import * as React from 'react';
import {
  AppBar,
  Divider,
  Box,
  Toolbar,
  IconButton,
  InputBase,
  Badge,
  MenuItem,
  Menu,
  Paper,
  Typography,
  Stack,
  Button,
  Avatar,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom';
import { MenuOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';

export default function Navbar({ menuAction, setMenuAction }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const {user} = useSelector((state) => ({...state}));

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/');
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <div>
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </div>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ background: '#fff', padding: { md: '0px 2.25rem', xs: 0 } }}
      >
        <Toolbar>
          <Box sx={{ mr: 1, display: { md: 'none', xs: 'block' } }}>
            <IconButton
              sx={{
                bgcolor: 'primary.main',
                color: '#fff',
                '&:hover': { bgcolor: 'primary.main' },
              }}
              onClick={() => setMenuAction(!menuAction)}
            >
              <MenuOutlined />
            </IconButton>
          </Box>
          <img
            src="/logo.png"
            alt="nestohub"
            style={{ width: 170, height: 'auto' }}
          />
          {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search> */}
          <Paper
            component="form"
            elevation={0}
            sx={{
              p: '0.125rem 0.25rem',
              display: 'flex',
              alignItems: 'center',
              width: '484px',
              borderRadius: '0.675rem',
              background: '#F9F9F9',
              marginLeft: '6rem',
            }}
          >
            <IconButton
              type="button"
              sx={{ p: '0.625rem' }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search..."
              inputProps={{ 'aria-label': 'Search...' }}
            />
          </Paper>
          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}
          >
            <div>
              <IconButton size="large" aria-label="Help" color="#898989">
                <HelpIcon />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                aria-label="notifications"
                color="#898989"
              >
                <Badge variant="dot" color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </div>

            <Button
              size="large"
              aria-controls={menuId}
              sx={{ textTransform: 'none', textAlign: 'left', my: 1 }}
              onClick={handleProfileMenuOpen}
            >
              <Stack direction="row" spacing={1}>
                <Avatar
                  sx={{ width: 45, height: 45 }}
                >N</Avatar>
                <Box>
                  <Typography variant="p" sx={{ color: '#202020' }}>
                    {user?.employeeName}
                  </Typography>
                  <Typography
                    variant="text"
                    sx={{ color: '#898989', fontSize: '0.872rem', mt: '-5px' }}
                    component={'p'}
                  >
                    Admin
                  </Typography>
                </Box>
              </Stack>
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Divider />
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
