import React, { useEffect } from "react";
import Layout from "../../../assets/Layout";
import { sectionBarBtn } from "../../../Style/stylesheet";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  Button,
} from "@mui/material";

import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";

import { useState } from "react";
import Breadcrumbs from "../../../assets/Breadcrumbs";
import SearchFilter from "../../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllInvoicesForBuilder, updateVisitById } from "../../../actions";

const Invoices = () => {
  const [sectionBarActive, setSectionBarActive] = useState("Received");
  const [sectionBarActive2, setSectionBarActive2] = useState("Invoices");
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headData = [
    "Invoice ID",
    "Invoice Date",
    "Transaction Id ",
    "Transaction  Date",
    "Transaction Amount",
    "Builder Name",
  ];

  const [invoices, setInvoices] = useState([]);
  
  useEffect(() => {
    dispatch(
      getAllInvoicesForBuilder({ }, (data) => {
        var invoices = [];
        data.data.map((item) => {
          item?.invoices.map((item1) => {
            invoices.push(item1);
          });
        });
        const filter = invoices.filter((item) => {
          if (searchQuery) {
            if (
              item?._id
                ?.toString()
                ?.toLowerCase()
                ?.includes(searchQuery?.toLowerCase())
            ) {
              return true;
            }
            return false;
          } else {
            return true;
          }
        });
        console.log(filter);
        setInvoices(filter);
      })
    );
  }, [searchQuery,]);

  return (
    <>
      <Layout path="normalVisits">
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Transaction History" />
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Received" && "active"}
            onClick={() => navigate("/transaction-history/recieved-invoices")}
          >
            Received
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Paid" && "active"}
            onClick={() => navigate("/transaction-history/paid-claims")}
          >
            Paid
          </Button>
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
          mx={20}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive2 === "Invoices" && "active"}
            onClick={() => navigate("/transaction-history/recieved-invoices")}
          >
            Invoices
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive2 === "Subscription" && "active"}
            onClick={() =>
              navigate("/transaction-history/recieved-subscription")
            }
          >
            subscription
          </Button>
        </Stack>

        <SearchFilter my={2}>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </SearchFilter>

        <Paper sx={{ width: "100%", overflow: "hidden", my: 2 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item._id}</TableCell>
                    <TableCell>{new Date(item.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell>{item?.transactionId ? item?.transactionId : "-"}</TableCell>
                    <TableCell>{item?.transactionDate ? new Date(item?.transactionDate).toLocaleDateString() : "-"}</TableCell>
                    <TableCell>{item?.transactionAmount ? `₹ ${item?.transactionAmount}`: "-"}</TableCell>
                    <TableCell>{item?.builderId?.companyName ? item?.builderId?.companyName : "-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Layout>
    </>
  );
};

export default Invoices;
