import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../assets/Layout';
import {
  Typography,
  Box,
  Stack,
  Paper,
  Grid,
  Button,
  CircularProgress,
  IconButton,
  Card,
} from '@mui/material';
import Breadcrumbs from '../../assets/Breadcrumbs';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllClaims, getClaimById, getClaimPDFByID, getPaymentPDFByID, updateClaimByAdmin } from '../../actions';
import ClaimsRejectionReason from './ClaimsRejectionReason';
import ClaimsApproveForm from './ClaimsApproveForm';
import TransactionDetailsForm from './TransactionDetailsForm';
import { enqueueSnackbar } from 'notistack';
import { Download } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import html2pdf from 'html2pdf.js';
export default function PropertyClaim() {
  const [claimDetails, setClaimDetails] = useState([]);
  const [propertyClaims, setPropertyClaims] = useState([]);
  const [status, setStatus] = useState('');
  const [remarks, setRemarks] = useState('');
  const [claimId, setClaimId] = useState('');
  const [rejectionAction, setRejectionAction] = useState(false);
  const [approveAction, setApproveAction] = useState(false);
  const [transactionDetailsAction, setTransactionDetailsAction] = useState(false);
  const [isGstApplicable, setIsGstApplicable] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { id } = useParams();
  const loadClaimById = useCallback(() => {
    dispatch(
      getClaimById({ id }, (data) => {
        setClaimDetails(data.data);
        setIsGstApplicable(data.data[0].isGstApplicable);
        setStatus(data.data[0].claimStatus);
        setRemarks(data.data[0].remark);
        dispatch(
          getAllClaims(
            { boughtPropertyId: data.data[0].boughtPropertyId._id },
            (data) => {
              setPropertyClaims(data.data);
            }
          )
        );
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadClaimById();
  }, [loadClaimById]);

  const handleChange = (event, claimId) => {
    setStatus(event.target.value);
    setClaimId(claimId);
    claimDetails[0].status = event.target.value;
    if (event.target.value == 'rejected') {
      setRejectionAction(true);
    } else {
      setRejectionAction(false);
    }
    if (event.target.value == 'approved') {
      setApproveAction(true);
    } else {
      setApproveAction(false);
    }
    if (event.target.value == 'paid') {
      setTransactionDetailsAction(true)
    } else {
      setTransactionDetailsAction(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    let payload = {
      id: id,
      remark: remarks,
    };

    dispatch(
      updateClaimByAdmin(
        payload,
        (data) => {
          if (data.status == 200) {
            enqueueSnackbar(data?.message, { variant: 'success' });
            setLoading(false);
          }
        },
        (err) => {
          enqueueSnackbar('Error Occured! please try again', {
            variant: 'error',
          });
        }
      )
    );
  };

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.setAttribute('download', '');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleGSTCheckboxChange = (event) => {
    let payload = {
      id: id,
      isGstApplicable: event.target.checked,
    };

    dispatch(
      updateClaimByAdmin(
        payload,
        (data) => {
          enqueueSnackbar(data?.message, { variant: 'success' });
          setIsGstApplicable(data.data.isGstApplicable);
        },
        (err) => {
          enqueueSnackbar('Error Occured! please try again', {
            variant: 'error',
          });
        }
      )
    );
  };

  function formatAmount(input) {
    let number = parseFloat(input || "0.0");

    if (number >= 10000000) {
      let cr = number / 10000000.0;
      return `${cr.toFixed(2)}Cr`;
    } else if (number >= 100000) {
      let l = number / 100000.0;
      return `${l.toFixed(2)}L`;
    } else if (number >= 1000) {
      let thousand = number / 1000.0;
      return `${thousand.toFixed(2)}K`;
    } else {
      return `${number.toFixed(2)}`;
    }
  }

  const handleClaimPDFDownload = (claimId) => {
    dispatch(getClaimPDFByID(claimId, (data) => {
      console.log(data);
      const invoiceHtml = data; // Assuming data contains the HTML content

      const options = {
        filename: 'claim.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(invoiceHtml).set(options).save();
    }, (err) => {
      console.log(err);
    }))
  }

  const handlePaymentPDFDownload = (invoiceId) => {
    dispatch(getPaymentPDFByID(invoiceId, (data) => {
      console.log(data);
      const invoiceHtml = data; // Assuming data contains the HTML content

      const options = {
        filename: 'payment.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(invoiceHtml).set(options).save();
    }, (err) => {
      console.log(err);
    }))
  }

  return (
    <Layout>
      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary='Property Claim' />
        </Stack>
        <Paper>
          <Box padding={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Property Name
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {claimDetails[0]?.propertyId?.name}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Visit ID
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {claimDetails[0]?.visitId?._id}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Visit Date
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {new Date(
                    claimDetails[0]?.boughtPropertyId?.visitId?.date
                  ).getUTCDate()}/{new Date(
                    claimDetails[0]?.boughtPropertyId?.visitId?.date
                  ).getUTCMonth() + 1 < 10 ? "0"+ (new Date(
                    claimDetails[0]?.boughtPropertyId?.visitId?.date
                  ).getUTCMonth() + 1) : new Date(
                    claimDetails[0]?.boughtPropertyId?.visitId?.date
                  ).getUTCMonth() + 1}/{new Date(
                    claimDetails[0]?.boughtPropertyId?.visitId?.date
                  ).getUTCFullYear()}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Builder Name
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {claimDetails[0]?.builderId?.companyName}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Customer Name
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {claimDetails[0]?.boughtPropertyId?.visitId?.clientName}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Unit Type
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {claimDetails[0]?.boughtPropertyId?.unitType}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Unit Number
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {claimDetails[0]?.boughtPropertyId?.unitNumber}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>
                  Selling Price
                </Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                  ₹ {formatAmount(claimDetails[0]?.boughtPropertyId?.sellingPrice)}
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Selling Date</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{new Date(
                  claimDetails[0]?.boughtPropertyId?.bookingDate
                ).toLocaleDateString('en-GB', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Booking Reference Number</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{claimDetails[0]?.boughtPropertyId?.bookingReferenceNumber}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary='Property Claim' />
        </Stack>
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box padding={4}>
            <Typography sx={{ fontSize: '40px' }}>
              ₹ {formatAmount(claimDetails[0]?.boughtPropertyId?.brokerageValue)}
            </Typography>
            <Typography sx={{ fontSize: '20px' }}>
              *Brokerage Percentage -{' '}
              {(
                (claimDetails[0]?.boughtPropertyId?.brokerageValue /
                  claimDetails[0]?.boughtPropertyId?.sellingPrice) *
                100
              ).toFixed(2)}
              %
            </Typography>
          </Box>
          {/* <Box padding={4}>
            <Typography pb={2}>Builder Form</Typography>
            <PictureAsPdfIcon fontSize='large' />
          </Box> */}
        </Paper>
      </Box>

      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary='Milestone ' />
        </Stack>
        {claimDetails[0]?.propertyId?.milestonesTermsConditions?.map(
          (item, index) => (
            <Paper sx={{ my: 2 }}>
              <Box padding={3}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography sx={{ fontSize: '32px' }}>
                      M{index + 1} - {item?.brokerage}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>{propertyClaims?.length >= index + 1 ? new Date(propertyClaims[0]?.createdAt).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    }) : ""}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Brokerage %</Typography>
                    <Typography sx={{ fontSize: '16px' }}>{item?.brokerage}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Brokerage Amount</Typography>
                    <Typography sx={{ fontSize: '16px' }}>{formatAmount(((claimDetails[0]?.boughtPropertyId?.brokerageValue * item?.brokerage?.split("%")[0]) / 100)?.toFixed(2))}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Claimed ID</Typography>
                    <Typography sx={{ fontSize: '16px' }}>{propertyClaims?.length >= index + 1 ? propertyClaims[index]?._id : "--"}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Payment Date</Typography>
                    <Typography sx={{ fontSize: '16px' }}>{propertyClaims?.length >= index + 1 ? propertyClaims[index]?.paymentDate ? propertyClaims[index]?.paymentDate : "--" : '--'}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Claim PDF</Typography>
                    {propertyClaims[index]?._id ?
                      <IconButton onClick={() => handleClaimPDFDownload(propertyClaims[index]?._id)}>
                        <InsertDriveFileIcon
                          sx={{
                            width: {
                              md: '2rem',
                            },
                            height: {
                              md: '2rem',
                            },
                          }}
                        />
                      </IconButton>
                      :
                      "--"
                    }
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Payment PDF</Typography>
                    {propertyClaims[index]?.invoiceIds[index]?._id && propertyClaims[index]?.claimStatus == "paid"
                      ?
                      <IconButton onClick={() => handlePaymentPDFDownload(propertyClaims[index]?._id)}>
                        <InsertDriveFileIcon
                          sx={{
                            width: {
                              md: '2rem',
                            },
                            height: {
                              md: '2rem',
                            },
                          }}
                        />
                      </IconButton>
                      :
                      "--"
                    }
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Payment ID</Typography>
                    <Typography sx={{ fontSize: '16px' }}>{propertyClaims?.length >= index + 1 && propertyClaims[index]?.claimStatus == "paid" ? propertyClaims[index]?.paymentId ? propertyClaims[index]?.paymentId : "--" : "--"}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ fontSize: '18px', color: '#8B9199' }}>Invoice ID</Typography>
                    <Typography sx={{ fontSize: '16px' }}>{propertyClaims?.length >= index + 1 ? propertyClaims[index]?.invoiceIds?.length > 0 ? propertyClaims[index]?.invoiceIds[index]?._id : "--" : "--"}</Typography>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Typography sx={{ borderRadius: '20px', px: 6, mt: 1, border: '1px solid red', textAlign: 'center', color: 'red', padding: '5px 10px', width: '70%' }}>
                      {propertyClaims?.length >= index + 1 ? propertyClaims[index]?.claimStatus : 'Claim'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          )
        )}
      </Box>

      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary='Bought Form ' />
        </Stack>
        <Paper>
          <Box padding={4}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  Booking Date
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {new Date(
                    claimDetails[0]?.boughtPropertyId?.bookingDate
                  ).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>Booking Reference Number</Typography>
                <Typography sx={{ fontSize: '20px' }}>{claimDetails[0]?.boughtPropertyId?.bookingReferenceNumber ? claimDetails[0]?.boughtPropertyId?.bookingReferenceNumber : "--"}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  Unit Number
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {claimDetails[0]?.boughtPropertyId?.unitNumber}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  Unit Type
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {claimDetails[0]?.boughtPropertyId?.unitType}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>Customer Name</Typography>
                <Typography sx={{ fontSize: '20px' }}>{claimDetails[0]?.boughtPropertyId?.customerName}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  Builder Representative Name
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {claimDetails[0]?.boughtPropertyId?.builderRepresentativeName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  Selling Price
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {claimDetails[0]?.boughtPropertyId?.sellingPrice
                    ? `₹ ${formatAmount(claimDetails[0]?.boughtPropertyId?.sellingPrice)}`
                    : ''}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  Brokerage Value
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {' '}
                  {claimDetails[0]?.boughtPropertyId?.brokerageValue
                    ? `₹ ${formatAmount(claimDetails[0]?.boughtPropertyId?.brokerageValue)}`
                    : ''}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary='Claim Details' />
        </Stack>
        <Paper>
          <Box padding={4}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  <b>Approved Claim Amount</b>
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  {' '}
                  {claimDetails[0]?.amount
                    ? `₹ ${claimDetails[0]?.amount}`
                    : ''}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontFamily: 'Manrope',
                    fontWeight: 600,
                  }}
                >
                  <b>Deduction</b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between', ml: 5 }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  TDS (5%)
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  ₹ {(claimDetails[0]?.amount * 5) / 100}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between', ml: 5 }}
              >
                <Stack direction={'row'} alignItems={'center'}>
                  <Typography
                    mr={1}
                    sx={{ fontSize: '20px', color: '#333333' }}
                  >
                    GST (18%) (If Applicable)
                  </Typography>
                  <input
                    type='checkbox'
                    disabled={claimDetails[0]?.claimStatus == "paid"}
                    checked={isGstApplicable}
                    onChange={handleGSTCheckboxChange}
                  />
                </Stack>
                <Typography sx={{ fontSize: '20px' }}>
                  ₹{' '}
                  {isGstApplicable ? (
                    (claimDetails[0]?.amount * 18) / 100
                  ) : (
                    <s>{(claimDetails[0]?.amount * 18) / 100}</s>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between', ml: 5 }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  NestoHub Platform Fees (10%)
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  ₹ {(claimDetails[0]?.amount * 10) / 100}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography sx={{ fontSize: '20px', color: '#333333' }}>
                  <b>Total Amount Payable</b>
                </Typography>
                <Typography sx={{ fontSize: '20px' }}>
                  ₹{' '}
                  {claimDetails[0]?.amount -
                    ((claimDetails[0]?.amount * 5) / 100 +
                      (isGstApplicable
                        ? (claimDetails[0]?.amount * 18) / 100
                        : 0) +
                      (claimDetails[0]?.amount * 10) / 100)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>

      <Box sx={{ my: 4 }}>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={4}
          sx={{ my: 6 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary='Bought Form PDF' />
        </Stack>
        <Paper
          sx={{ height: { xl: '300px', md: 'auto', xs: 'auto', sm: 'auto' } }}
        >
          <Grid container spacing={4} mx={{ xl: '2', md: '0' }}>
            {claimDetails[0]?.boughtPropertyId?.documents?.length > 0 ? (
              claimDetails[0].boughtPropertyId?.documents?.map(
                (item, index) => (
                  <Grid item key={index} xs={12} sm={12} md={6} lg={2} xl={2}>
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        padding: '1rem',
                        position: 'relative',
                      }}
                    >
                      <IconButton
                        onClick={() => handleDownload(item)}
                        sx={{
                          position: 'absolute',
                          top: '2%',
                          right: '2%',
                          background: '#278FD9aa',
                        }}
                      >
                        <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                      </IconButton>
                      <IconButton>
                        <InsertDriveFileIcon
                          sx={{
                            width: {
                              xl: '8rem',
                              xs: '2rem',
                              sm: '2rem',
                              md: '4rem',
                            },
                            height: {
                              xl: '8rem',
                              xs: '2rem',
                              sm: '2rem',
                              md: '4rem',
                            },
                          }}
                        />
                      </IconButton>
                      <Typography align='center' variant='body2'>
                        File {index + 1}
                      </Typography>
                    </Card>
                  </Grid>
                )
              )
            ) : (
              <Grid item xs={12}>
                <Typography align='center'>No document</Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>

      <form>
        {' '}
        <Grid container gap={5}>
          <Grid item xs={12}>
            <label>Status</label>
            <select
              value={claimDetails[0]?.claimStatus}
              onChange={(e) => handleChange(e, claimDetails[0]?._id)}
              disabled={claimDetails[0]?.claimStatus == "paid"}
            >
              <option value='' disabled>
                Select
              </option>
              <option value={'submitted'}>Submitted</option>
              <option value={'rejected'}>Rejected</option>
              <option value={'approved'}>Approved</option>
              <option value={'recieved'}>Payment Recieved</option>
              <option value={'paid'}>Paid</option>
            </select>
          </Grid>
          <Grid item xs={12}>
            <label>Remarks</label>
            <textarea value={remarks} onChange={(e) => setRemarks(e.target.value)} className='inputBox' style={{ resize: 'none', height: '300px', width: '100%' }} placeholder="Write here" disabled={claimDetails[0]?.claimStatus == "paid"}></textarea>
          </Grid>
        </Grid>
        <Button
          variant='contained'
          size='large'
          sx={{ mt: 3 }}
          disabled={loading || claimDetails[0]?.claimStatus == "paid"}
          onClick={handleSubmit}
        >
          {loading ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </form>

      <ClaimsRejectionReason
        claimId={claimId}
        claimStatus={status}
        rejectionAction={rejectionAction}
        setRejectionAction={setRejectionAction}
      />
      <ClaimsApproveForm
        claimId={claimId}
        claimStatus={status}
        approveAction={approveAction}
        setApproveAction={setApproveAction}
        property={claimDetails[0]?.propertyId}
        claimType={claimDetails[0]?.claimType}
        broker={claimDetails[0]?.brokerId?._id}
      />

      <TransactionDetailsForm
        claimId={claimId}
        claimStatus={status}
        transactionDetailsAction={transactionDetailsAction}
        setTransactionDetailsAction={setTransactionDetailsAction}
        broker={claimDetails[0]?.brokerId?._id}
        amount={claimDetails[0]?.amount}
      />
    </Layout>
  );
}
