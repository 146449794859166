import React, { useCallback, useEffect } from "react";
import Layout from "../../assets/Layout";


import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
    Grid,
    Divider,
    Link,
    Rating,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate, useParams } from "react-router-dom";
import { getVisitbyId } from "../../actions";
import { useDispatch } from "react-redux";

const VisitSummary = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [visitDetails, setVisitDetails] = useState([]);
    const [showFullComment, setShowFullComment] = useState(false);

    const handleReadMore = () => {
        setShowFullComment(true);
    };
    const loadVisitById = useCallback(() => {
        dispatch(getVisitbyId({ id }, (data) => {
            setVisitDetails(data?.data);
        }, (err) => {
            console.log(err);
        }))
    }, [dispatch])

    useEffect(() => {
        loadVisitById();
    }, [loadVisitById])

    const navigate = useNavigate();
    const handleRedirect = () => {
        window.scrollTo(0, 0);
        navigate("/broker-management/clients/view/" + visitDetails[0]?.customerId?._id);
    };

    function formatAmount(input) {
        let number = parseFloat(input || "0.0");

        if (number >= 10000000) {
            let cr = number / 10000000.0;
            return `${cr.toFixed(2)}Cr`;
        } else if (number >= 100000) {
            let l = number / 100000.0;
            return `${l.toFixed(2)}L`;
        } else if (number >= 1000) {
            let thousand = number / 1000.0;
            return `${thousand.toFixed(2)}K`;
        } else {
            return `${number.toFixed(2)}`;
        }
    }

    return (
        <>
            <Layout path="normalVisits">
                <Typography variant="h5" mb={2} sx={{ fontWeight: 'bold' }}>
                    Visit Summary
                </Typography>
                <Paper >
                    <Grid container >
                        <Grid item md={7}>
                            <Stack py={1} px={3} >
                                <Typography variant="body1" mb={1}>
                                    Client Name
                                </Typography>
                                <Typography variant="body1" mb={1}>
                                    Visit Id
                                </Typography>
                                <Typography variant="body1" mb={1}>
                                    Last Visit Date
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={5} sx={{ textAlign: 'end' }}>
                            {visitDetails.length > 0 ? visitDetails?.map((item) => (
                                <Stack py={1} px={3} >
                                    <Typography variant="body1" fontWeight={"bold"} mb={1}>
                                        {item?.customerId?.clientName ? item?.customerId?.clientName : "--"}
                                    </Typography>
                                    <Typography variant="body1" fontWeight={"bold"} mb={1}>
                                        {item?._id ? item?._id : "--"}
                                    </Typography>
                                    <Typography variant="body1" fontWeight={"bold"} mb={1}>
                                        {item?.date ? new Date(item?.date)?.toLocaleDateString() : "--"}

                                    </Typography>
                                </Stack>
                            ))
                                : ""}


                        </Grid>

                    </Grid>

                    <Typography px={3} variant="h6" sx={{ fontWeight: 'bold' }} >
                        Property Description
                    </Typography>

                    <Grid container >
                        <Grid item md={7}>
                            <Box >
                                <Stack py={1} px={3}>
                                    <Typography variant="body1" mb={1}>
                                        Property Name
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Location
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Selling Price
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Unit Type
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Follow Up Date
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Follow Up Time
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Visit Status
                                    </Typography>
                                    <Typography variant="body1" mb={1}>
                                        Ratings & Reviews
                                    </Typography>
                                </Stack>
                            </Box>
                        </Grid>

                        <Grid item md={5} sx={{ textAlign: 'end' }}>
                            <Box >
                                {visitDetails.length > 0 ? visitDetails?.map((item) => (
                                    <Stack py={1} px={3}>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.propertyName ? item?.propertyName : "--"}
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.propertyId?.location ? item?.propertyId?.location : "--"}
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            &#8377;  {item?.propertyId?.minPrice ? formatAmount(item?.propertyId?.minPrice) : "--"} - {item?.propertyId?.maxPrice ? formatAmount(item?.propertyId?.maxPrice) : "--"}
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.propertyId?.floorPlanAndPricing?.length > 0 ?
                                                Array.from(new Set(item.propertyId.floorPlanAndPricing.map(f => f.unitType[0]))).join(", ") :
                                                "--"
                                            }
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.visitStatus !== "bought" ? item?.followUpDate ? new Date(item?.followUpDate)?.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : "Not Mentioned" : "--"}
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.visitStatus !== "bought" ? item?.chooseSlot ? item?.chooseSlot : "Not Mentioned" : "--"}
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.visitStatus ? item?.visitStatus : "Not Mentioned"}
                                        </Typography>
                                        <Typography variant="body1" mb={1} fontWeight={"bold"}>
                                            {item?.overAllRating
                                                ?
                                                <Rating value={item?.overAllRating ? item?.overAllRating : 0} readOnly />
                                                :
                                                "No rating"
                                            }
                                        </Typography>
                                    </Stack>
                                ))
                                    : ""}

                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Typography variant="h5" my={3} sx={{ fontWeight: 'bold' }}>
                    Comment History
                </Typography>

                {
                    visitDetails?.[0]?.commentHistory?.length > 0 ? visitDetails?.[0]?.commentHistory.map((item, index) => (
                        <>
                            <Paper key={index}>
                                <Box py={2} px={2} my={2}>
                                    <Typography variant="h6" px={1} pt={2} sx={{ fontWeight: 'bold' }}>
                                        {item?.date ? new Date(item?.date)?.toLocaleDateString() : ""}
                                    </Typography>
                                    <Typography variant="body1" px={1} sx={{ justifyContent: 'center' }}>
                                        {showFullComment ? item?.comment : item?.comment ? item?.comment?.length > 500 ? `${item?.comment.slice(0, 500)}...` : item?.comment : ""}
                                        {!showFullComment && item?.comment && item?.comment?.length > 500 && (
                                            <Button
                                                style={{ color: '#917AFD', textDecoration: 'none' }}
                                                onClick={handleReadMore}
                                            >
                                                Read more
                                            </Button>
                                        )}
                                    </Typography>
                                    <Divider sx={{ height: '1.5px', background: '#BFBFBF', }} />
                                </Box>
                            </Paper>

                        </>

                    ))
                        :
                        <p>No Comments Available Yet</p>
                }

                <Button
                    variant="contained"
                    sx={{
                        borderRadius: '3.5px',
                        boxShadow: 0,
                        px: 5,
                        py: 1,
                        my: 3,
                        textTransform: 'capitalize',
                        fontFamily: 'Poppins'
                    }}
                    onClick={handleRedirect}
                >
                    Done
                </Button>


            </Layout>
        </>
    );
}

export default VisitSummary;