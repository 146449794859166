import React, { useCallback, useEffect } from "react";
import Layout from "../../assets/Layout";
import { BoughtData } from '../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../Style/stylesheet';

import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllVisits, updateVisitById } from "../../actions";
import { enqueueSnackbar } from "notistack";
import ViistRemark from "../BrokerManagement/VisitRemark";

const BoughtVisitsForBroker = () => {
    const [sectionBarActive, setSectionBarActive] = useState('Bought')
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const dispatch = useDispatch();
    const [allVisits, setAllVisits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const { id } = useParams()
    const [searchQuery, setSearchQuery] = useState('');
    const [visitsPagination, setVisitsPagination] = useState([]);

    console.log(id)
    const headData = [
        'VISIT ID',
        'BROKER ID',
        'PROPERTY NAME',
        'CLIENT ID',
        'LAST VISIT DATE',
        'Status',
        'Rating',
        'view',
        'Remarks',
    ];

    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };

    const handleButtonClick = (id) => {
        navigate(`/visit-details/${id}`)
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const loadAllvisits = useCallback(() => {
        dispatch(getAllVisits({ visitStatus: "bought", pageNo: currentPage, pageSize: countPerPage, brokerId: id }, (data) => {
            const filters = data.data.filter((allVisits) => {
                // by id
                if (allVisits?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (allVisits?.brokerId?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }
                if (allVisits?.propertyName.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }
                if (allVisits?.customerId?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                return false
            });
            if(searchQuery){
                setAllVisits(filters);
            }
            setVisitsPagination(data.data);
        }))

    }, [dispatch, searchQuery])

    const updateStatus = (id, status) => {
        let payload = {
            visitId: id, visitStatus: status
        }
        console.log("Here is Payload", payload)
        dispatch(
            updateVisitById(payload, () => {
                loadAllvisits()
                enqueueSnackbar("Status updated!", { variant: 'success' })
            },
            )
        )
    }

    // const searchVisits = useCallback(() => {
    //     dispatch(getAllVisits({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
    //         const filters = data.filter((allVisits) => {
    //             // by id
    //             if (allVisits?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
    //                 console.log("you are here " + searchQuery);
    //                 return true
    //             }
    //             return false
    //         });
    //         console.log("we are here", filters);
    //         setAllVisits(filters);
    //     }));
    // }, [dispatch])

    useEffect(() => {
        dispatch(getAllVisits({ visitStatus: "bought", pageNo: currentPage, pageSize: countPerPage, brokerId: id }, (data) => {
            if(searchQuery == ""){
                setAllVisits(data.data);
            }
        }));
    }, [currentPage, countPerPage, searchQuery]);

    console.log(allVisits)

    useEffect(() => {
        loadAllvisits();
    }, [loadAllvisits, currentPage, countPerPage,])

    return (
        <>
            <Layout path="normalVisits">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Visits" />
                    {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
                </Stack>

                <Stack
                    direction={'row'}
                    sx={{ p: 1, bgcolor: '#fff', mb: 2, borderRadius: 10 }}
                    spacing={3}
                >
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Visits' && 'active'}
                        onClick={() => navigate('/broker-management/visit/' + id)}
                    >
                        Pending/Followup/Negotiate
                    </Button>
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Bought' && 'active'}
                        onClick={() => navigate('/broker-management/bought-visit/' + id)}
                    >
                        Bought
                    </Button>
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActive === 'Completed' && 'active'}
                        onClick={() => navigate('/broker-management/completed-visit/' + id)}
                    >
                        Completed
                    </Button>
                </Stack>

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                    {/* <Box className="style-1 filter p-1">
                        <select>
                            <option value="All" selected>
                                All
                            </option>
                            <option value="option 1">option 1</option>
                        </select>
                    </Box>
                    <Box className="style-1">
                        <input type="date" placeholder="Choose Date" />
                    </Box> */}
                </SearchFilter>

                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allVisits?.slice(0, 10).map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{item._id}</TableCell>
                                        <TableCell>{item.brokerId?._id}</TableCell>
                                        <TableCell>{item.propertyName}</TableCell>
                                        <TableCell>{item.customerId?._id}</TableCell>
                                        <TableCell>{item?.date ? new Date(item?.date)?.toLocaleDateString() : "--"}</TableCell>

                                        <TableCell>
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <Select value={item?.visitStatus} onChange={(e) => { updateStatus(item?._id, e.target.value); }} displayEmpty inputProps={{ 'aria-label': 'Without label' }} >
                                                    <MenuItem value="">
                                                        <em>{item.status}</em>
                                                    </MenuItem>
                                                    <MenuItem value={'followup'}>Followup</MenuItem>
                                                    <MenuItem value={'negotiation'}>Negotiation</MenuItem>
                                                    <MenuItem value={'pending'}>Pending</MenuItem>
                                                    <MenuItem value={'bought'}>Bought</MenuItem>
                                                    <MenuItem value={'completed'}>Completed</MenuItem>
                                                </Select> </FormControl>
                                        </TableCell>

                                        <TableCell sx={{ color: '#278FD9' }}>
                                            <Rating name="half-rating" readOnly defaultValue={item?.overAllRating} precision={0.5} />
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => handleButtonClick(item?._id)}>View</Button>
                                        </TableCell>


                                        <TableCell>
                                            <Box onClick={() => setAddRemarkAction(true)}>
                                                <IconButton size="large" color="primary">
                                                    <Draw />
                                                </IconButton>
                                            </Box>
                                        </TableCell>

                                        <ViistRemark
                                            addRemarkAction={addRemarkAction}
                                            setAddRemarkAction={setAddRemarkAction}
                                            id={item?._id}
                                        />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        sx={{
                            bgcolor: "mute.dark",
                            borderRadius: 1,
                            color: "dark.text",
                            p: 2,
                            mt: 4,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography>
                                {visitsPagination?.length > 0 ? (currentPage - 1) * countPerPage + 1: 0} -{' '}
                                {countPerPage > visitsPagination?.length
                                    ? visitsPagination?.length
                                    : currentPage * countPerPage > visitsPagination?.length
                                        ? visitsPagination?.length
                                        : currentPage * countPerPage}{' '}
                                of {visitsPagination?.length}
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Typography> Rows per page:</Typography>
                            <select style={{ marginTop: "-5px" }}
                                value={countPerPage}
                                onChange={(e) => setCountPerPage(e.target.value)}>
                                <option value="10" >
                                    10
                                </option>
                                <option value="20">
                                    20
                                </option>
                                <option value="30">
                                    30
                                </option>
                            </select>
                            <Box sx={{ ml: 2 }}>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1 || countPerPage > visitsPagination?.length}
                                            sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > visitsPagination?.length ? "#EFEFEF" : "#FFF" }}
                                        >
                                            <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Typography variant="text" component={"span"}>
                                            {currentPage}/{Math.ceil(visitsPagination?.length / countPerPage)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={countPerPage > visitsPagination?.length || currentPage == Math.ceil(visitsPagination?.length / countPerPage)}
                                            sx={{
                                                border: "1px solid",
                                                borderColor: "mute.main",
                                                bgcolor: "white.main",
                                            }}
                                        >
                                            <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Layout>
        </>
    );
}

export default BoughtVisitsForBroker;