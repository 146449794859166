import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { UpdateBrokerPreferences, addNewBroker, getAllBuilders, getAllBuilders1, getAllPropertyCategory, getBrokerDetails } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import CustomAutoComplete from '../../assets/CustomAutoComplete';
import CustomAutoComplete1 from '../../assets/CustomAutoComplete1';
export default function UpdatePreferences({ setStep, brokerId, setAddBrokerAction }) {
  const [locality, setLocality] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [experience, setExperiance] = useState("");
  const [top3Builders, setTop3Builders] = useState([]);
  const [builders, setBuilders] = useState([]);
  const [propertyCategories, setPropertyCategories] = useState([]);

  const dispatch = useDispatch()
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
  };

  useEffect(() => {
    dispatch(
      getBrokerDetails({ id: brokerId }, (data) => {
        if (data && data.length > 0) {
          const brokerData = data[0];
          setLocality(brokerData?.locality);
          setPropertyType(brokerData?.propertyType);
          setExperiance(brokerData?.experience);
          setTop3Builders(brokerData?.top3Builders);
        }
      })
    );
  }, [brokerId, dispatch]);

  const handleReset = () => {
    dispatch(
      getBrokerDetails({ id: brokerId }, (data) => {
        if (data && data.length > 0) {
          const brokerData = data[0];
          setLocality(brokerData?.locality);
          setPropertyType(brokerData?.propertyType);
          setExperiance(brokerData?.experience);
          setTop3Builders(brokerData?.top3Builders);
        }
      })
    );
  };

  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    if (searchQuery) {
      dispatch(getAllBuilders1({ searchQuery }, (data) => {
        setBuilders(data.map(x => x.builderName));
      }, (err) => {
        console.log(err)
      }))
    } else {
      setBuilders([]);
    }
  }, [searchQuery]);

  const handleSubmit = () => {
    if (!locality || !propertyType || !experience || !top3Builders) {
      enqueueSnackbar('Please fill in all required fields', { variant: 'error' });

      return;
    }

    let payload = {
      id: brokerId,
      locality,
      propertyType,
      experience,
      top3Builders
    }
    dispatch(
      UpdateBrokerPreferences(payload, (data) => {
        enqueueSnackbar("Broker Updated Successfully!", { variant: 'success' })
        setAddBrokerAction(false);
        setStep(1);
      }, (err) => {
        enqueueSnackbar("Error Occured! please try again", { variant: 'error' });
      }
      )
    )
  };



  const handleSelectChange = (event) => {
    const { value } = event.target;
    setTop3Builders(value);
  };

  useEffect(() => {
    dispatch(
      getAllPropertyCategory({}, (data) => {
        console.log("properties", data);
        setPropertyCategories(data.data);
      })
    );
  }, [dispatch]);

  return (
    <>
      <div>
        <Typography variant="title" component="h3">
          Preferences
        </Typography>
      </div>
      <Box component={'from'} flexGrow={1}>
        <Divider sx={{ my: 4 }} />
        <Box>
          <Grid container spacing={3}>
            <Grid item md={6} flexGrow={1} flexDirection={'column'}>
              {/* <InputStyle name="Locality*">
                  <Select
                    placeholder="Enter Locality"
                    labelId="multi-select-label"
                    id="multi-select"
                    multiple
                    value={locality}
                    onChange={(e) => setLocality(e.target.value)}

                    sx={{
                      backgroundColor: '#EDF2F7',
                      height: '2.5rem',
                      width: '25rem',
                      '& .MuiSelect-select.MuiSelect-selectMultiple': {
                        height: 'auto',
                        minHeight: '36px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      '& .MuiSelect-icon': {
                        top: 'calc(50% - 12px)',
                      },
                    }}
                  >
                    <MenuItem value="option1">Option 1</MenuItem>
                    <MenuItem value="option2">Option 2</MenuItem>
                    <MenuItem value="option3">Option 3</MenuItem>
                    <MenuItem value="option4">Option 4</MenuItem>
                  </Select>
                </InputStyle> */}
              <CustomAutoComplete1 top3Builders={locality} setTop3Builders={setLocality} />
            </Grid>
            <Grid item md={6} flexGrow={1} flexDirection={'column'}>
              <FormControl>
                <InputStyle name="Property Type*">
                  <Select
                    placeholder="Enter Locality"
                    labelId="multi-select-label"
                    id="multi-select"
                    multiple
                    value={propertyType}
                    onChange={(e) => setPropertyType(e.target.value)}

                    sx={{
                      height: '2.5rem',
                      width: '25rem',
                      backgroundColor: '#EDF2F7',
                      '& .MuiSelect-select.MuiSelect-selectMultiple': {
                        height: 'auto',
                        minHeight: '36px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      '& .MuiSelect-icon': {
                        top: 'calc(50% - 12px)',
                      },
                    }}
                  >
                    {propertyCategories.map((item, index) => (
                      <MenuItem key={index} value={item.name}>{item.name} 1</MenuItem>
                    ))}

                  </Select>
                </InputStyle>
              </FormControl>
            </Grid>

            {/* Email input */}
            <Grid item md={6} flexGrow={1}>
              <InputStyle name="Experience* (In Years)"   >
                <input
                  style={{ backgroundColor: '#fff' }}
                  type="text"
                  placeholder="Enter your experience"
                  value={experience}
                  onChange={(e) => setExperiance(e.target.value)}
                />
              </InputStyle>
            </Grid>

            <Grid item md={6} flexGrow={1} flexDirection={'column'}>

              <CustomAutoComplete searchQuery={searchQuery} setSearchQuery={setSearchQuery} builders={builders} top3Builders={top3Builders} setTop3Builders={setTop3Builders} />
              {/* <Select
                    placeholder="Enter Locality"
                    labelId="multi-select-label"
                    id="multi-select"
                    multiple
                    value={top3Builders}
                    onChange={handleSelectChange}

                    sx={{
                      height: '2.5rem',
                      width: '25rem',
                      backgroundColor: '#EDF2F7',
                      '& .MuiSelect-select.MuiSelect-selectMultiple': {
                        height: 'auto',
                        minHeight: '36px',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                      },
                      '& .MuiSelect-icon': {
                        top: 'calc(50% - 12px)',
                      },
                    }}
                  >
                    {builders && builders.length > 0
                      ?
                      builders.map((item, index) => (
                        <MenuItem value={item.name} key={index}>{item.name}</MenuItem>
                      ))
                      :
                      ""}
                  </Select> */}
            </Grid>

            <Grid item md={12} xs={12} flexGrow={1}>
              <Stack direction={'row'} spacing={3}>
                <Button variant="contained" sx={ButtonStyle} onClick={handleSubmit}>
                  Next
                </Button>

                <Button variant="outlined" sx={ButtonStyle} onClick={handleReset}>
                  Reset
                </Button>
              </Stack>
            </Grid>

          </Grid>
        </Box>
      </Box>
    </>
  );
}
