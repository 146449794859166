import React, { useEffect, useState } from 'react';

import Layout from '../../assets/Layout';
import Breadcrumbs from '../../assets/Breadcrumbs';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';

import {
    Checkbox,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
} from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import ButtonController from '../../assets/ButtonController';
import SearchFilter from '../../assets/SearchFilter';
import { getAllPayment } from '../../actions';
import { useDispatch } from 'react-redux';


// import Profile from './Profile';
// import AddDSA from './AddDSA';

export default function PaymentScreen() {
    const navigate = useNavigate();
    const [addDSAAction, setAddDSAAction] = useState(false);
    const [profileAction, setProfileAction] = useState(false);
    const [status, setStatus] = useState("");
    const [payments, setPayment] = useState("");
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10);

    const headData = [
        'TRANSACTION ID',
        'TRANSACTION DATE',
        'BANK NAME',
        'IFSC CODE',
        'ACCOUNT NUMBER',
        'RECIPIENT NAME',
        'BROKER ID',
        'BUILDER ID',
        'PROPERTY ID',
        'INVOICE ID',
    ];

    // useEffect(() => {
    //     const getAllPayment = async () => {
    //       dispatch(getAllPayment({ isAdmin: true }, (data) => {
    //         console.log(data?.data);
    //       }));
    //     };

    //     getAllPayment();
    //   }, []);

    useEffect(() => {
        dispatch(getAllPayment({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
            setPayment(data.data);
            console.log(data.data);
        }))
    }, [currentPage, countPerPage,])

    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };
    return (
        <Layout path="builder-management">
            <Box>
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Payment Management" />
                    <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        {/* <Box onClick={() => setAddDSAAction(true)}>
              <MainButton>
                <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> New DSA
              </MainButton>
            </Box> */}
                    </ButtonController>
                </Stack>
                {/* <SearchFilter>
                    <Box className="search style-1">
                        <input type="text" placeholder="Search..." />
                    </Box>
                    <Box className="style-1 filter p-1">
                        <select>
                            <option value="All" selected>
                                All
                            </option>
                            <option value="option 1">option 1</option>
                        </select>
                    </Box>
                    <Box className="style-1">
                        <input type="date" placeholder="Choose Date" />
                    </Box>
                </SearchFilter> */}
                <Box sx={{ mb: 4 }}></Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    payments.length === 0 ? <p>No Payment Yet</p> :
                                        [...Array(10)].map((item, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                            >
                                                <TableCell>
                                                    <Checkbox />
                                                </TableCell>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>Eaton Garth Penthouse</TableCell>
                                                {/* <TableCell>
                      <Link
                        onClick={() => setProfileAction(true)}
                        className="link"
                      >
                        Ann Culhane
                      </Link>
                    </TableCell> */}
                                                <TableCell>3BHK</TableCell>
                                                <TableCell>Sector 28</TableCell>
                                                <TableCell>46676732989</TableCell>
                                                <TableCell>Ann Culhane</TableCell>{' '}
                                                <TableCell>Ann Culhane</TableCell>
                                                <TableCell>3245</TableCell>
                                                <TableCell>HDFC BANK</TableCell>
                                                <TableCell>3.94 Cr</TableCell>
                                                <TableCell>12 Jan 2023</TableCell>
                                            </TableRow>
                                        ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: 'mute.dark',
                        borderRadius: 1,
                        color: 'dark.text',
                        p: 2,
                        mt: 4,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {/* <Box>
            <Typography>1 - 10 of {builders.length}</Typography>
          </Box> */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: ' center',
                            alignItems: 'center',
                            float: 'right'
                        }}
                    >
                        <Box>
                            <Typography component="p">Rows per page:</Typography>
                        </Box>
                        <Box>
                            <select value={countPerPage} onChange={handleSelectChange}>
                                <option value="10" selected>
                                    10
                                </option>
                                <option value="20" selected>
                                    20
                                </option>
                                <option value="30" selected>
                                    30
                                </option>
                            </select>
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={'row'}
                                spacing={2}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                                        sx={{ border: '1px solid', borderColor: 'mute.main' }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={'span'}>
                                        {currentPage}/10
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        sx={{
                                            border: '1px solid',
                                            borderColor: 'mute.main',
                                            bgcolor: 'white.main',
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* Modals */}
            {/* <Profile
        profileAction={profileAction}
        setProfileAction={setProfileAction}
      /> */}
            {/* <AddDSA addDSAAction={addDSAAction} setAddDSAAction={setAddDSAAction} /> */}
            {/* Modals */}
        </Layout>
    );
}
