import {
  Box,
  Checkbox,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import SearchFilter from "../../assets/SearchFilter";
import { useCallback, useEffect, useState } from "react";
import { getAllBoughtPropertyByBrokerId } from "../../actions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddBroker from "./AddBroker";


const SoldProperty = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [properties, setProperties] = useState([]);
  const dispatch = useDispatch();
  const { id } = useParams()
  const [searchQuery, setSearchQuery] = useState('');
  const [addBrokerAction, setAddBrokerAction] = useState(false);
  const [allSoldProperties, setAllSoldProperties] = useState([]);

  const loadProperties = useCallback(() => {
    dispatch(getAllBoughtPropertyByBrokerId({ brokerId: id }, (data) => {
      const filters = data?.data.filter((properties) => {
        // by id
        if (properties?.brokerId?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }

        if (properties?.brokerId?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }
        if (properties?.propertyId?.name.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }
        if (properties?.propertyId?.location.toLowerCase().includes(searchQuery?.toLowerCase())) {
          return true
        }
        if (properties?.unitType?.includes(searchQuery?.toLowerCase())) {
          return true
        }
        if(properties?.unitNumber?.toLowerCase()?.includes(searchQuery?.toLowerCase())){
          return true
        }
        if(properties?.customerId?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())){
          return true
        }
        if(properties?.customerId?._id?.toLowerCase()?.includes(searchQuery?.toLowerCase())){
          return true
        }
        if(properties?.customerId?._id?.toLowerCase()?.includes(searchQuery?.toLowerCase())){
          return true
        }

        // if (properties?.customerId.toLowerCase().includes(searchQuery?.toLowerCase())) {
        //   return true
        // }

        return false
      });
      if(searchQuery){
        setProperties(filters);
      }
      setAllSoldProperties(data.data);
    }))

  }, [dispatch, searchQuery])

  useEffect(() => {
    dispatch(getAllBoughtPropertyByBrokerId({ pageNo: currentPage, pageSize: countPerPage, brokerId: id }, (data) => {
      if(searchQuery == ""){
        setProperties(data?.data);
      }
    }));
  }, [currentPage, countPerPage, searchQuery]);

  useEffect(() => {
    loadProperties();
  }, [loadProperties, currentPage, countPerPage,])

  return (
    <>
      <Layout path={"refer"}>
        <Box>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={3}
            sx={{ my: 4 }}
            justifyContent={"space-between"}
          >
            <Breadcrumbs primary="Broker Management : Sold Properties" />
            <ButtonController>
              {/* <Box>
                <IconButton className="iconBtn">
                  <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                </IconButton>
              </Box>
              <Box>
                <Typography variant="text" component={"span"}>
                  <strong>Jun 24, 2022</strong> Today
                </Typography>
              </Box>
              <Box>
                <IconButton className="iconBtn">
                  <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                </IconButton>
              </Box> */}
              {/* <Box onClick={() => setAddBrokerAction(true)}>
                <MainButton>
                  <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> Add Broker
                </MainButton>
              </Box> */}
            </ButtonController>
          </Stack>

          <SearchFilter>
            <Box className="search style-1">
              <input type="text" placeholder="Search..." value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} />
            </Box>
            {/* <Box className="style-1 filter p-1">
              <select>
                <option value="All" selected>
                  All
                </option>
                <option value="option 1">option 1</option>
              </select>
            </Box>
            <Box className="style-1">
              <input type="date" placeholder="Choose Date" />
            </Box> */}
          </SearchFilter>

          <Box sx={{ mb: 4 }}></Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell>
                      <Checkbox />
                    </TableCell> */}
                    <TableCell>#</TableCell>
                    {headData.map((item, index) => (
                      <TableCell
                        key={index}
                        style={{ minWidth: "200px" }}
                        align="left"
                      >
                        <Typography
                          sx={{
                            color: "mute.darktext",
                            textTransform: "uppercase",
                            fontSize: "0.9rem",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    properties?.slice(0, 10).map((item, index) => (
                      <TableRow sx={{ bgcolor: "#f9fafc" }} key={index}>
                        {/* <TableCell align="left">
                          <Checkbox />
                        </TableCell> */}
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell>{item?.brokerId?._id}</TableCell>
                        <TableCell>{item?.brokerId?.name}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {item?.propertyId?.name}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {item?.propertyId?.location}
                        </TableCell>
                        <TableCell>{item?.unitType}</TableCell>
                        <TableCell>{item?.unitNumber}</TableCell>
                        <TableCell> {item?.visitId?.clientName} </TableCell>
                        <TableCell>{item?.claims?.length > 0 ? item?.claims?.map((item) => {return item._id}).join(", ") : "--"}</TableCell>
                        <TableCell>{item?.claims?.length > 0 ? item?.claims?.map((item) => {return new Date(item.createdAt).toLocaleDateString()}).join(", ") : "--"}</TableCell>
                        <TableCell>{item?.claims?.length > 0 ? item?.claims?.map((item) => {return item.claimStatus}).join(", ") : "--"}</TableCell>
                      </TableRow>
                    ))
                  }

                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box
            sx={{
              bgcolor: "mute.dark",
              borderRadius: 1,
              color: "dark.text",
              p: 2,
              mt: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography>
                {allSoldProperties?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                {countPerPage > allSoldProperties?.length
                  ? allSoldProperties?.length
                  : currentPage * countPerPage > allSoldProperties?.length
                    ? allSoldProperties?.length
                    : currentPage * countPerPage}{' '}
                of {allSoldProperties?.length}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography> Rows per page:</Typography>
              <select style={{ marginTop: "-5px" }}
                value={countPerPage}
                onChange={(e) => setCountPerPage(e.target.value)}>
                <option value="10" >
                  10
                </option>
                <option value="20">
                  20
                </option>
                <option value="30">
                  30
                </option>
              </select>
              <Box sx={{ ml: 2 }}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <IconButton
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1 || countPerPage > allSoldProperties?.length}
                      sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allSoldProperties?.length ? "#EFEFEF" : "#FFF" }}
                    >
                      <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="text" component={"span"}>
                      {currentPage}/{Math.ceil(allSoldProperties?.length / countPerPage)}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={countPerPage > allSoldProperties?.length || currentPage == Math.ceil(allSoldProperties?.length / countPerPage)}
                      sx={{
                        border: "1px solid",
                        borderColor: "mute.main",
                        bgcolor: "white.main",
                      }}
                    >
                      <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

const headData = [
  "broker id",
  "broker name",
  "property name",
  "address",
  "unit type",
  "unit number",
  "client name",
  "claim id",
  "claim date",
  "claim status",
];

export default SoldProperty;
