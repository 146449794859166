import axios from 'axios';

// export const baseURL = 'https://backend.nestohub.in/api/';
export const baseURL = 'https://backend.nestohub.in/api/';
const axiosInstance = axios.create({
  baseURL:baseURL,
  // http://nestobackend-env.eba-fk3zufmz.ap-south-1.elasticbeanstalk.com/api/
  timeout: 30000,
});

const setAuthorizationToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.Authorization = `bearer ${token}`;
  }
};

const formatUTCDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = String(date.getUTCFullYear());
  return `${day}/${month}/${year}`;
};

const formatToLacOrCrore = (value, isGraph) => {
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + (isGraph ? ' Cr' : ' Crore');
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + (isGraph ? ' L' : ' Lakh');
  }
  return val;
};

//const downloadPDF = (url, isBase64) => {
//    if (Platform.OS === 'android') {
//        const permission = PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE
//        check(permission).then((result) => {
//            if (result === RESULTS.GRANTED) {
//                if (isBase64)
//                    startDownloadPdfFromBase64(url?.trim())
//                else
//                    startDownloadPdfFromurl(url?.trim())
//            } else {
//                request(permission).then((res) => {
//                    if (res === 'granted') {
//                        if (isBase64)
//                            startDownloadPdfFromBase64(url?.trim())
//                        else
//                            startDownloadPdfFromurl(url?.trim())
//                    }
//                    else {
//                        Alert.alert(
//                            'To download the pdf', 'Please allow the external storage permission.',
//                            [
//                                {
//                                    text: 'cancel',
//                                    onPress: undefined,
//                                    style: 'destructive',
//                                },
//                                {
//                                    text: 'OK',
//                                    onPress: () => openSettings().catch(() => console.warn('cannot open settings'))
//                                },
//                                ],
//                                );
//                    }
//                });
//            }
//        }).catch((error) => {
//            console.log('error while downloading pdf : ', error)
//        })
//    }
//    else {
//        if (isBase64)
//            startDownloadPdfFromBase64(url?.trim())
//        else
//            startDownloadPdfFromurl(url?.trim())
//    }
//
//}

//const startDownloadPdfFromurl = async (url) => {
//    let configOptions =
//        Platform.select({
//            android: {
//                fileCache: true,
//                addAndroidDownloads: {
//                    useDownloadManager: true,
//                    notification: true,
//                    mediaScannable: true,
//                    title: 'Brochure',
//                    path: RNFetchBlob?.fs?.dirs?.DownloadDir + '/' + Math?.random()?.toString(36)?.substring(2, 7) + '.pdf'
//                },
//                indicator: true,
//            },
//            ios: {
//                path: RNFetchBlob?.fs?.dirs?.DocumentDir + '/' + Math?.random()?.toString(36)?.substring(2, 7) + '.pdf'
//            }
//        })
//
//    RNFetchBlob?.config(configOptions)?.fetch('GET', url)?.then(
//        (response) => {
//            console.log('response of download pdf', response)
//            Alert.alert('Brochure Download Sucessfully')
//            if (Platform?.OS === 'ios')
//                RNFetchBlob?.ios?.previewDocument(response?.path())
//        },
//        (error) => {
//            console.log('error of download pdf', error)
//            Alert.alert('Unable To Download Brochure')
//        })
//}

//const startDownloadPdfFromBase64 = async (url) => {
//    let pdfLocation = RNFetchBlob?.fs?.dirs?.DownloadDir + '/' + Math?.random()?.toString(36)?.substring(2, 7) + '.pdf';
//
//    RNFetchBlob?.fs?.writeFile(pdfLocation, url, 'base64')?.then(
//        (response) => {
//            console.log('response of download pdf', response)
//            Alert.alert('Brochure Download Sucessfully')
//            if (Platform?.OS === 'ios')
//                RNFetchBlob?.ios?.previewDocument(response?.path())
//        },
//        (error) => {
//            console.log('error of download pdf', error)
//            Alert.alert('Unable To Download Brochure')
//        })
//}

//const generateAndSavePDF = (path) => {
//    const options = {
//        imagePaths: [path?.replace('file://', '')],
//        name: 'PDFName',
//        maxSize: { width: 900, height: Math.round(screenHeight / screenWidth * 900) },
//        quality: .8
//    };
//    RNImageToPdf.createPDFbyImages(options)?.then(
//        (pdf) => {
//            console.log('response of create PDF', pdf);
//            let pdfLocation = RNFetchBlob?.fs?.dirs?.DownloadDir + '/' + Math?.random()?.toString(36)?.substring(2, 7) + '.pdf';
//            //@ts-ignore
//            RNFetchBlob?.fs?.writeFile(pdfLocation, pdf?.filePath ?? '', 'uri')?.then(
//                (response) => {
//                    console.log('response of save pdf', response)
//                    Alert.alert('PDF Download Sucessfully')
//                    if (Platform?.OS === 'ios')
//                        RNFetchBlob?.ios?.previewDocument(response?.path())
//                },
//                (error) => {
//                    console.log('error of save pdf', error)
//                    Alert.alert('Unable To Download PDF')
//                })
//        },
//        (error) => {
//            console.log('error of create PDF', error);
//        }
//        )
//}

//const getIcon = (format) => {
//    switch (format?.split('.')?.[1]) {
//        case 'pdf': return localImages?.PDF_FILE
//            break;
//        case 'docx': return localImages?.DOC_FILE
//            break;
//        case 'doc': return localImages?.DOC_FILE
//            break;
//        case 'csv': return localImages?.EXCEL_FILE
//            break;
//        case 'xls': return localImages?.EXCEL_FILE
//            break;
//        case 'xlsx': return localImages?.EXCEL_FILE
//            break;
//        default: return ''
//            break;
//    }
//}

//const getDocFormData = (docs) => {
//    let i = 0;
//    const formData = new FormData();
//    let temp = docs?.map((item) => ({
//        name: item.name,
//        type: item.type,
//        uri: Platform.OS === 'ios' ? item?.sourceURL?.replace('file://', '') : item?.uri,
//    }))
//    for (i = 0; i < temp?.length; i++)
//        formData?.append('documents', temp?.[i])
//    return formData;
//}

const formatAmountInWords = (num) => {
  var a = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen ',
  ];
  var b = [
    '',
    '',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eighty',
    'ninety',
  ];

  if ((num = num.toString()).length > 9) return 'overflow';
  let n = ('000000000' + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = '';
  str +=
    n[1] !== 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore '
      : '';
  str +=
    n[2] !== 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh '
      : '';
  str +=
    n[3] !== 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand '
      : '';
  str +=
    n[4] !== 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred '
      : '';
  str +=
    n[5] !== 0
      ? (str !== '' ? 'and ' : '') +
        (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) +
        'only '
      : '';
  return str;
};

const formatToSqmt = (val = 0) => {
  return (val / 10.764)?.toFixed(1);
};

const formatToMeter = (val = 0) => {
  return (val / 3.281)?.toFixed(1);
};

const pricePerSqft = (price, area) => {
  return (price / area)?.toFixed(0);
};

const replaceWithAsterisks = (str) => {
  // Create a regular expression to match any character (except line breaks)
  var regex = /./g;

  // Replace each character with an asterisk
  var result = str.replace(regex, '*');

  return result;
};

const numberToWords = (number) => {
  const ones = [
    '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine',
    'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen',
    'seventeen', 'eighteen', 'nineteen'
  ];

  const tens = [
    '', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'
  ];

  if (number === 0) {
    return 'zero';
  } else if (number < 0) {
    return 'minus ' + numberToWords(Math.abs(number));
  }

  let words = '';

  if (Math.floor(number / 10000000) > 0) {
    words += numberToWords(Math.floor(number / 10000000)) + ' crore ';
    number %= 10000000;
  }

  if (Math.floor(number / 100000) > 0) {
    words += numberToWords(Math.floor(number / 100000)) + ' lakh ';
    number %= 100000;
  }

  if (Math.floor(number / 1000) > 0) {
    words += numberToWords(Math.floor(number / 1000)) + ' thousand ';
    number %= 1000;
  }

  if (Math.floor(number / 100) > 0) {
    words += numberToWords(Math.floor(number / 100)) + ' hundred ';
    number %= 100;
  }

  if (number > 0) {
    if (number < 20) {
      words += ones[number];
    } else {
      words += tens[Math.floor(number / 10)];
      if (number % 10 > 0) {
        words += '-' + ones[number % 10];
      }
    }
  }

  return words.trim();
}

const formatAmount = (input) => {
  let number = parseFloat(input || "0.0");

  if (number >= 10000000) {
    let cr = number / 10000000.0;
    return `${cr.toFixed(2)}Cr`;
  } else if (number >= 100000) {
    let l = number / 100000.0;
    return `${l.toFixed(2)}L`;
  } else if (number >= 1000) {
    let thousand = number / 1000.0;
    return `${thousand.toFixed(2)}K`;
  } else {
    return `${number.toFixed(2)}`;
  }
}


const exportedObject = {
  axiosInstance,
  setAuthorizationToken,
  formatUTCDate,
  formatToLacOrCrore,
  //    downloadPDF,
  //    generateAndSavePDF,
  //    getIcon,
  //    getDocFormData,
  formatAmountInWords,
  formatToSqmt,
  pricePerSqft,
  formatToMeter,
  replaceWithAsterisks,
  numberToWords,
  formatAmount
};

export default exportedObject;
