import { Close } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import InputStyle from '../../assets/InputStyle';
import { getFaqById, updateFaq, uploadDocToS3 } from '../../actions';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function UpdateQNA({ updateQNAAction, setUpdateQNAAction, id }) {
  const [checkboxValues, setCheckboxValues] = useState({
    builder: false,
    broker: false,
    dsa: false,
  });
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [supportFile, setSupportFile] = useState("");
  const dispatch = useDispatch();

  const handleCheckboxChange = (name) => (event) => {
    setCheckboxValues({ ...checkboxValues, [name]: event.target.checked });
  };

  const handleSupportFileChange = (e) => {
    const file = e.target.files;
    if (file) {
      if (file[0].name?.toLowerCase()?.includes('pdf') || file[0].name?.toLowerCase()?.includes('docx') || file[0].name?.toLowerCase()?.includes('doc')) {
        var formData = new FormData();
        formData.append("documents", file[0]);
        dispatch(uploadDocToS3(formData, (data) => {
          setSupportFile(data.data.documents[0]);
          enqueueSnackbar("file uploaded successfully", { variant: 'success' });
        }, (err) => {
          enqueueSnackbar("Error occured while uploading file", { variant: 'error' });
        }));
      } else {
        enqueueSnackbar("Please upload a file with valid format", { variant: 'warning' })
      }
    }
  }

  const handleSubmit = () => {
    if (!question || !answer) {
      enqueueSnackbar("Please enter all fields", { variant: "warning" });
    } else {
      if (type == "support") {
        if (supportFile) {
          const selectedFor = Object.keys(checkboxValues)
            .filter((key) => checkboxValues[key]);

          let ddd = {};
          if (selectedFor.includes('broker')) {
            ddd = { ...ddd, broker: true };
          }
          if (selectedFor.includes('builder')) {
            ddd = { ...ddd, builder: true };
          }
          if (selectedFor.includes('dsa')) {
            ddd = { ...ddd, dsa: true };
          }
          const payload =
          {
            id,
            question,
            answer,
            for: [ddd],
            type
          }

          // Append profile picture to formData

          setIsLoading(true);
          dispatch(
            updateFaq(payload, (data) => {
              enqueueSnackbar("FAQ Updated Successfully!", { variant: 'success' })
              setIsLoading(false);
              setUpdateQNAAction(false)

            }, (err) => {
              enqueueSnackbar("Error While updating FAQ!", { variant: 'error' })
            }
            )
          )
        } else {
          enqueueSnackbar("file for support is mandtory", { variant: 'warning' });
        }
      } else {
        const selectedFor = Object.keys(checkboxValues)
          .filter((key) => checkboxValues[key]);

          let ddd = {};
          if (selectedFor.includes('broker')) {
            ddd = { ...ddd, broker: true };
          }
          if (selectedFor.includes('builder')) {
            ddd = { ...ddd, builder: true };
          }
          if (selectedFor.includes('dsa')) {
            ddd = { ...ddd, dsa: true };
          }

        const payload =
        {
          id,
          question,
          answer,
          for: [ddd],
          type
        }

        // Append profile picture to formData

        setIsLoading(true);
        dispatch(
          updateFaq(payload, (data) => {
            enqueueSnackbar("FAQ Updated Successfully!", { variant: 'success' })
            setIsLoading(false);
            setUpdateQNAAction(false)

          }, (err) => {
            enqueueSnackbar("Error While updating FAQ!", { variant: 'error' })
          }
          )
        )
      }
    }
  };

  useEffect(() => {
    dispatch(getFaqById(id, {}, (data) => {
      setAnswer(data.data.data[0].answer)
      setQuestion(data.data.data[0].question)
      const selectedForValues = data.data.data[0].for[0];
      const updatedCheckboxValues = Object.keys(selectedForValues).filter(key => selectedForValues[key] === true);
      console.log(updatedCheckboxValues);
      setCheckboxValues(selectedForValues);
      setType(data.data.data[0].type);
      if(data.data.data[0].supportFile){
        setSupportFile(data.data.data[0].supportFile);
      }
    }, (err) => {
      console.log(err)
    }))
  }, [id]);

  return (
    <Dialog
      open={updateQNAAction}
      onClose={() => setUpdateQNAAction(false)}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Edit Q & A
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={() => setUpdateQNAAction(false)}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <InputStyle name={'Question'}>
              <textarea placeholder="Enter question"
                value={question}
                onChange={(e) => { setQuestion(e.target.value) }}
              />
            </InputStyle>
          </Grid>
          <Grid item md={6}>
            <InputStyle name={'Answer'}>
              <textarea placeholder="Enter answer"
                value={answer}
                onChange={(e) => { setAnswer(e.target.value) }}
              />
            </InputStyle>
          </Grid>
          <Grid item md={6}>
            <InputStyle name={'Choose'}>
              <FormGroup>
                <Stack direction={'row'} spacing={3}>
                  <FormControlLabel
                    control={<Checkbox checked={checkboxValues.builder} onChange={handleCheckboxChange('builder')} />}
                    label="Builder"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={checkboxValues.broker} onChange={handleCheckboxChange('broker')} />}
                    label="Broker"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={checkboxValues.dsa} onChange={handleCheckboxChange('dsa')} />}
                    label="DSA"
                  />
                </Stack>
              </FormGroup>
            </InputStyle>
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={6}>
            <InputStyle name={'Type'}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                row
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <FormControlLabel value="support" control={<Radio />} label="Support" />
                <FormControlLabel value="faq" control={<Radio />} label="FAQ" />
              </RadioGroup>
            </InputStyle>
          </Grid>
          <Grid item md={6}></Grid>
          <Grid item md={12}>
            {type == "support" && <InputStyle><input onChange={handleSupportFileChange} type='file' accept='.pdf,.docx, .doc' /></InputStyle>}
          </Grid>
          <Grid item md={12}>
            <Button variant="contained" sx={{ width: 120, mr: 1 }} onClick={handleSubmit}>
              Update
            </Button>
            {/* <Button variant="outlined" sx={{ width: 120 }}>
              Reset
            </Button> */}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
