import { Box, Grid, IconButton, Modal, Stack } from '@mui/material';
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import Sidenav from '../components/Sidenav';
import Theme from './Theme';
import { Close } from '@mui/icons-material';
const Layout = ({ children, path }) => {
  const [menuAction, setMenuAction] = useState(false);
  const width = 240;
  return (
    <>
      <Theme>
        <Navbar />
        <Box sx={{ bgcolor: '#fff' }}>
          <Stack direction={'row'}>
            <Box
              sx={{
                position: 'relative',
                display: { md: 'block', xs: 'none' },
                width: 300,
                minHeight: '100vh',
              }}
            >
              <Box sx={{ position: 'sticky', top: 0 }}>
                <Sidenav path={path} />
              </Box>
            </Box>
            <Box
              sx={{
                p: 4,
                maxWidth: '100%',
                overflow: 'hidden',
                bgcolor: '#f9f9f9',
              }}
              flexGrow={1}
            >
              {children}
            </Box>
          </Stack>
          <Modal open={menuAction} onClose={() => setMenuAction(!menuAction)}>
            <Box sx={{ maxWidth: 240 }}>
              <Sidenav setMenuAction={setMenuAction} />
            </Box>
          </Modal>
        </Box>
      </Theme>
    </>
  );
};

export default Layout;
