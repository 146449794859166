import { MenuItem, Select } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const CustomSelect = (props) => {
  return (
    <Select
      defaultValue={""}
      sx={{
        bgcolor: "#E9E9E9",
        borderRadius: "42px",
        height: "42px",
        width: "120px",
        outline: "none",
        boxShadow: "none",
        fontSize: "0.8rem",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
      }}
      IconComponent={() => {
        return (
          <ExpandMoreIcon
            sx={{
              color: "dark.text",
              border: ".5px solid lightgrey",
              borderRadius: "100%",
              height: "20px",
              width: "20px",
              marginRight: "5px",
            }}
          />
        );
      }}
      displayEmpty
      disableUnderline
      onChange={props.onChange}
      value={props.value}
    >
      <MenuItem value="">Select</MenuItem>
      {props.options.map((item, index) => {
        return (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export { CustomSelect };
