import {
  Lock,
  Mail,
  Opacity,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../actions";
import common from "../utils/common";
import { useSnackbar } from "notistack";
import regex from "../utils/regex";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleShowPassword = (show) => {
    if (show) {
      document.getElementById("password").type = "password";
      setShowPassword(false);
    } else {
      document.getElementById("password").type = "test";
      setShowPassword(true);
    }
  };

  const onLogin = (e) => {
    e.preventDefault();
    let payload = {
      username: email,
      password,
    };
    setIsLoading(true); // Start loader
    dispatch(
      adminLogin(payload, 
        (data) => {
        setIsLoading(false); // Stop loader
        if (data.status === 200) {
          console.log(data);
          dispatch({
            type: 'LOG_IN',
            payload: data.data.data.username,
          });
          localStorage.setItem("role", JSON.stringify(data.data.data.username));
          common?.setAuthorizationToken(data.data.data.token);
          localStorage.setItem("token", data.data.data.token);
          navigate("/builder-management/add-builder");
        } else {
          enqueueSnackbar(data.data.message, { variant: "error" });
        }
      }, (err) => {
        setIsLoading(false);
        if(err?.response?.data?.message){
          enqueueSnackbar(err?.response?.data?.message, {variant:'error'});
        }else{
          enqueueSnackbar("Error Occured! Please try after some time", {variant:'error'});
        }
      })
    );
  };

  return (
    <>
      <Paper
        sx={{  height: "90vh", Opacity: "0.5", width:'auto', mx:4, my:3 }}
        elevation={4}
      >
        <Grid container>
          <Grid item md={5} xs={12}>
            <Box sx={{ px:4, py:4 }}>
              <img src="/logo.png" alt="NestoHub" />
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "2.20rem",
                  lineHeight: "3.25rem",
                  mt:3,
                }}
              >
                Sign in
              </Typography>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "1.20rem",
                  lineHeight: "1.75rem",
               
                }}
              >
                Sign in dashboard
              </Typography>
              <Typography sx={{my:1}}>
                Email
              </Typography>
              <TextField
                id="standard-textarea"
                placeholder="Enter your email address"
                required
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Mail />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: "500px" }}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError ? (
                <span style={{ color: "red" }}>Invalid input</span>
              ) : (
                ""
              )}

              <form onSubmit={onLogin}>
                <Typography
                  sx={{mt:3}}
                >
                  Password
                </Typography>
                <TextField
                  id="password"
                  placeholder="Enter your password"
                  type="password"
                  required
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Lock />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => handleShowPassword(showPassword)}
                        sx={{ cursor: "pointer" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "500px" }}
                  value={password}
                  onChange={(e) => {
                      setPassword(e.target.value);
                  }}
                />
                <Box sx={{ marginTop: "1.25rem" }}>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Remeber me"
                  />
                  <span
                    style={{
                      float: "right",
                      marginRight: "3.2rem",
                      marginTop: "0.7rem",
                    }}
                  >
                    Forgot Password?
                  </span>
                </Box>
                {isLoading ? (
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      my:2,
                      width: "494px",
                      height: "51px",
                      boxShadow: "0px 4.60889px 29.9578px rgba(0, 0, 0, 0.25)",
                      borderRadius: "2.625rem",
                    }}
                  >
                    {" "}
                    <CircularProgress size={24} />{" "}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      my:2,
                      width: "494px",
                      height: "51px",
                      boxShadow: "0px 4.60889px 29.9578px rgba(0, 0, 0, 0.25)",
                      borderRadius: "2.625rem",
                    }}
                    type="submit"
                  >
                    Login
                  </Button>
                )}
              </form>
            </Box>
          </Grid>
          <Grid item md={7} sx={12}>
            <img
              src="/LoginBanner.png"
              alt="NestoHub"
              style={{ marginTop: "2rem", width: "85%", height:"80vh", marginLeft:'6rem' }}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Login;
