import React, { useState, useEffect, useCallback } from "react";

import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";

import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";
import { claimsData } from "../../utils/data";
import { Link, useNavigate } from "react-router-dom";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { sectionBarBtn } from "../../Style/stylesheet";
import ClaimsRemarks from "./ClaimsRemarks";
import { useDispatch } from "react-redux";
import { getAllClaims } from "../../actions";
import { getAllEligibleClaims } from "../../actions";
import EligibleClaimsRemarks from "./EligibleClaimsRemarks";

export default function EligibleClaims() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [claims, setClaims] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState("");
  const [claimId, setClaimId] = useState("");
  const [remark, setRemark] = useState("");
  const [allClaims, setAllClaims] = useState([]);


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
        dispatch(
            getAllEligibleClaims({ claimType: "visit" }, (data) => {
              const filter = data?.data.filter((item) => {
                if (
                    item?.propertyId?._id
                      .toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  ) {
                    return true;
                  }

                  if (
                    item?._id
                      .toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  ) {
                    return true;
                  }

                  if (
                    item?.brokerId?._id
                      .toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  ) {
                    return true;
                  }

                  if (
                    item?.propertyId?.name
                      .toLowerCase()
                      .includes(searchQuery?.toLowerCase())
                  ) {
                    return true;
                  }

                  if(new Date(item?.createdAt)?.toLocaleDateString().includes(new Date(searchQuery).toLocaleDateString())){
                    return true;
                  }
                if (
                  item?.builderId?.companyName
                    .toLowerCase()
                    .includes(searchQuery?.toLowerCase())
                ) {
                  return true;
                }
                return false;
              });
              if (searchQuery) {
                setClaims(filter);
              }
              setAllClaims(data.data);
            })
          );
    }
  }, [searchQuery, navigate]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      dispatch(
        getAllEligibleClaims(
          { pageNo: currentPage, pageSize: countPerPage, claimType: "visit" },
          (data) => {
            if (searchQuery == "") {
              setClaims(data.data);
            }
          }
        )
      );
    }
  }, [currentPage, countPerPage, searchQuery]);
  const [sectionBarActive, setSectionBarActive] = useState("Claims");

  const [status, setStatus] = useState("");
  const [addRemarkAction, setAddRemarkAction] = useState(false);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const headData = [
    "PROPERTY ID",
    "Claim ID",
    "Broker ID",
    "Property Name",
    "claim date",
    "Claim amount",
    "builder name",
    // 'milestone no',
    // 'Status',
    // 'Action',
    // 'Remarks',
  ];
  return (
    <Layout path="Claims">
      <Box>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Eligible Claims" />
        </Stack>
        <SearchFilter>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </SearchFilter>
        <Box sx={{ mb: 4 }}></Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {claims.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                  >
                    {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?.propertyId?._id}</TableCell>
                    <TableCell>{item?._id}</TableCell>
                    <TableCell>{item?.brokerId?._id}</TableCell>
                    <TableCell>{item?.propertyId?.name}</TableCell>
                    <TableCell>
                      {item?.createdAt
                        ? new Date(item?.createdAt)?.toLocaleDateString()
                        : "--"}
                    </TableCell>
                    {/* <TableCell>{item?.date ? new Date(item?.date)?.toLocaleDateString() : '--'}</TableCell> */}
                    <TableCell>
                      {item?._id?.includes("VT")
                        ? item?.visitBrokerage
                        : item?.brokerageValue
                        ? item?.brokerageValue
                        : "--"}
                    </TableCell>
                    {/* <TableCell>{item?.brokerageValue ? item?.brokerageValue : item?.visitBrokerage ? item?.visitBrokerage :  '--'}</TableCell> */}
                    <TableCell>{item?.builderId?.companyName}</TableCell>
                    {/* <TableCell> {item?.milestoneNumber}</TableCell> */}
                    {/* <TableCell>
                                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                                <Select value={item.claimStatus} onChange={(e) => handleChange(e, index, item?._id)} displayEmpty inputProps={{ 'aria-label': 'Without label' }} >
                                                    <MenuItem value={'submitted'}>Submitted</MenuItem>
                                                    <MenuItem value={'rejected'}>Rejected</MenuItem>
                                                    <MenuItem value={'approved'}>Approved</MenuItem>
                                                    <MenuItem value={'recieved'}>Payment Recieved</MenuItem>
                                                </Select> </FormControl>
                                        </TableCell>
                                        <TableCell sx={{ color: '#278FD9' }}> <Link to={`/property-claim/${item._id}`} >View</Link></TableCell> */}
                    {/* <TableCell>
                                            <Box onClick={() => { setClaimId(item?._id); setAddRemarkAction(true); setRemark(item?.remark); }}>
                                                <IconButton size="large" color="primary">
                                                    <Draw />
                                                </IconButton>
                                            </Box></TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* <Box
                    sx={{
                        bgcolor: "mute.dark",
                        borderRadius: 1,
                        color: "dark.text",
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography>
                            {allClaims?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
                            {countPerPage > allClaims?.length
                                ? allClaims?.length
                                : currentPage * countPerPage > allClaims?.length
                                    ? allClaims?.length
                                    : currentPage * countPerPage}{' '}
                            of {allClaims?.length}
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography> Rows per page:</Typography>
                        <select style={{ marginTop: "-5px" }}
                            value={countPerPage}
                            onChange={(e) => setCountPerPage(e.target.value)}>
                            <option value="10" >
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="30">
                                30
                            </option>
                        </select>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1 || countPerPage > allClaims?.length}
                                        sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allClaims?.length ? "#EFEFEF" : "#FFF" }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={"span"}>
                                        {currentPage}/{Math.ceil(allClaims?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={countPerPage > allClaims?.length || currentPage == Math.ceil(allClaims?.length / countPerPage)}
                                        sx={{
                                            border: "1px solid",
                                            borderColor: "mute.main",
                                            bgcolor: "white.main",
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box> */}
      </Box>
      <EligibleClaimsRemarks
        remark={remark}
        claimId={claimId}
        setRemark={setRemark}
        addRemarkAction={addRemarkAction}
        setAddRemarkAction={setAddRemarkAction}
      />
    </Layout>
  );
}
