import React, { useEffect, useState } from 'react';
import Layout from '../assets/Layout';
import { Box, Icon, Paper, Stack, Typography } from '@mui/material';
import AddPropertyForm from '../components/PropertyManagement/AddPropertyForm';
import { useNavigate } from 'react-router-dom';
import FloorPlanForm from '../components/PropertyManagement/FloorPlanForm';
import BrokerageForm from '../components/PropertyManagement/BrokerageForm';
import { selectBar, selectBarBtn } from '../Style/stylesheet';
import { Check } from '@mui/icons-material';

const AddProperty = () => {
  const [propertyID, setPropertyID] = useState('');
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);
  return (
    <>
      <Layout path="addproperty">
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '28px',
            color: '#000000',
            marginTop: '48px',
            marginBottom: '48px',
          }}
        >
          Add Property
        </Typography>
        <Paper sx={{ borderRadius: '20px', pt: 4 }}>
          {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Stack direction="row" spacing={2} sx={{ width: '634px', height: '51px', borderRadius: '120px', background: 'rgba(217, 217, 217, 0.44)', marginTop: '65px' }}>
                            <div style={{ width: '40%' }}>
                                {
                                    step === 1
                                        ?
                                        <p style={{ marginTop: '16px' }}>
                                            <span style={{ padding: '7px 13px', background: '#278FD9', borderRadius: '50%', marginLeft: '28px', marginRight: '20px', color: '#FFFFFF' }}>1</span>
                                            Property Description
                                        </p>
                                        :
                                        <p style={{ marginTop: '16px' }}>
                                            <span style={{ padding: '7px 13px', background: '#FFFFFF', borderRadius: '50%', marginLeft: '28px', marginRight: '20px' }}>1</span>
                                            Property Description
                                        </p>
                                }
                            </div>
                            <div style={{ width: '30%' }}>
                                {
                                    step === 2
                                        ?
                                        <p style={{ marginTop: '16px' }}>
                                            <span style={{ padding: '7px 13px', background: '#278FD9', borderRadius: '50%', marginLeft: '28px', marginRight: '20px', color: '#FFFFFF' }}>2</span>
                                            Floor Plan
                                        </p>
                                        :
                                        <p style={{ marginTop: '16px' }}>
                                            <span style={{ padding: '7px 13px', background: '#FFFFFF', borderRadius: '50%', marginLeft: '28px', marginRight: '20px' }}>2</span>
                                            Floor Plan
                                        </p>
                                }
                            </div>
                            <div style={{ width: '30%' }}>
                                {
                                    step === 3
                                        ?
                                        <p style={{ marginTop: '16px' }}>
                                            <span style={{ padding: '7px 13px', background: '#278FD9', borderRadius: '50%', marginLeft: '28px', marginRight: '20px', color: '#FFFFFF' }}>3</span>
                                            Brokerage
                                        </p>
                                        :
                                        <p style={{ marginTop: '16px' }}>
                                            <span style={{ padding: '7px 13px', background: '#FFFFFF', borderRadius: '50%', marginLeft: '28px', marginRight: '20px' }}>3</span>
                                            Brokerage
                                        </p>
                                }
                            </div>
                        </Stack> *
                    </Box> */}

          <Stack direction={'row'} sx={selectBar} spacing={3} className="col-3">
            <Box
              sx={selectBarBtn}
              className={`${step === 1 ? 'active' : step >= 2 && 'done'} box`}
            >
              <Icon className="icon">{step >= 2 ? <Check /> : 1}</Icon>
              Property Description
            </Box>
            <Box
              sx={selectBarBtn}
              className={`${step === 2 ? 'active' : step === 3 && 'done'} box`}
            >
              <Icon className="icon">{step === 3 ? <Check /> : 2}</Icon>
              Floor Plan
            </Box>
            <Box
              sx={selectBarBtn}
              className={`${step === 3 ? 'active' : step === 4 && 'done'} box`}
            >
              <Icon className="icon">{step === 4 ? <Check /> : 3}</Icon>
              Brokerage
            </Box>
          </Stack>

          <Box sx={{ px: 3 }}>
            {step === 1 ? (
              <AddPropertyForm
                setPropertyID={setPropertyID}
                setStep={setStep}
              />
            ) : step === 2 ? (
              <FloorPlanForm propertyID={propertyID} setStep={setStep} />
            ) : step === 3 ? (
              <BrokerageForm propertyID={propertyID} setStep={setStep} />
            ) : (
              ''
            )}
          </Box>
        </Paper>
      </Layout>
    </>
  );
};

export default AddProperty;
