import React, { useEffect, useState } from 'react';
import Layout from '../assets/Layout';
import { Box, Icon, Paper, Stack, Typography } from '@mui/material';
import AddBuilderForm from '../components/BuilderManagement/AddBuilderForm';
import { useNavigate } from 'react-router-dom';
import { selectBar, selectBarBtn } from '../Style/stylesheet';
import { Check } from '@mui/icons-material';

const AddBuilder = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
  }, [navigate]);
  return (
    <>
      <Layout path="builder-management">
        <Typography
          sx={{
            fontWeight: '600',
            fontSize: '28px',
            color: '#000000',
            marginTop: '48px',
            marginBottom: '48px',
          }}
        >
          Add Builder
        </Typography>
        <Paper sx={{ borderRadius: '20px', pt: 7 }}>
          {/* <Stack
              direction="row"
              spacing={2}
              sx={{
                width: '534px',
                height: '51px',
                borderRadius: '120px',
                background: 'rgba(217, 217, 217, 0.44)',
                marginTop: '65px',
              }}
            >
              <div style={{ width: '50%' }}>
                <p style={{ marginTop: '16px' }}>
                  <span
                    style={{
                      padding: '7px 13px',
                      background: '#278FD9',
                      borderRadius: '50%',
                      marginLeft: '28px',
                      marginRight: '20px',
                      color: '#FFFFFF',
                    }}
                  >
                    1
                  </span>
                  Onboarding
                </p>
              </div>
              <div style={{ width: '50%' }}>
                <p style={{ marginTop: '16px' }}>
                  <span
                    style={{
                      padding: '7px 13px',
                      background: '#FFFFFF',
                      borderRadius: '50%',
                      marginLeft: '28px',
                      marginRight: '20px',
                    }}
                  >
                    2
                  </span>
                  Onboarding
                </p>
              </div>
            </Stack> */}
          <Stack direction={'row'} sx={selectBar} spacing={3}>
            <Box
              sx={selectBarBtn}
              className={`${step === 1 ? 'active' : step >= 2 && 'done'} box`}
            >
              <Icon className="icon">{step >= 2 ? <Check /> : 1}</Icon>
              Onboarding
            </Box>
            <Box
              sx={selectBarBtn}
              className={`${step === 2 ? 'active' : step === 3 && 'done'} box`}
            >
              <Icon className="icon">{step === 3 ? <Check /> : 2}</Icon>
              Past Properties
            </Box>
          </Stack>

          {/* Add Builder Form */}
          <Box sx={{ padding: '0px 40px' }}>
            <AddBuilderForm />
          </Box>
        </Paper>
      </Layout>
    </>
  );
};

export default AddBuilder;
