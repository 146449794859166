import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateClaimByAdmin } from '../../actions';
import { enqueueSnackbar } from 'notistack';


export default function ClaimsRejectionReason({ claimId,claimStatus,rejectionAction, setRejectionAction }) {
const dispatch=useDispatch();
const [rejectionReason, setRejectionReason] = useState("")
const [selectReason, setSelectReason] = useState("")
const handleSubmit=(e)=>{
    e.preventDefault()
    let payload={
      id:claimId,
      comment:rejectionReason,
      claimStatus:claimStatus,
      claimRejectReason:selectReason
    }
    dispatch(
      updateClaimByAdmin(payload,(data)=>{
        if(data.status==200){
          enqueueSnackbar(data?.message,{variant:'success'});
          window.location.reload();
        }
      }, (err) => {
        enqueueSnackbar("Error Occured! please try again", { variant: 'error' });
      })
    )
    closeHandler();

}

  const closeHandler = () => setRejectionAction(false);
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
    marginTop:4
  };
  return (
    <Dialog
      open={rejectionAction}
      onClose={closeHandler}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
            Reason of Rejection
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Box component={'from'}  >
            <form onSubmit={handleSubmit}>
                <Stack gap={4} >
                    <Box>
            <label >Select Reason</label>
            <select onChange={(e) => setSelectReason(e.target.value)} >
              <option value="" disabled selected>Select Reason</option>
              <option value="Milestone not achieved">Milestone not achieved</option>
              <option value="Incomplete Documents">Incomplete Documents</option>
              <option value="Fraudulent Claim">Fraudulent Claim</option>
              <option value="Incorrect Information">Incorrect Information</option>
              <option value="Others">Others</option>
            </select>
            </Box>
            <Box>
            <label>Add Comment*</label>
        <textarea value={rejectionReason} onChange={(e)=>setRejectionReason(e.target.value)} className='inputBox' style={{resize:'none',height:'200px', width:'100%'}}  placeholder="Write here"></textarea>
        </Box>
        </Stack>
        <Button variant="contained" sx={ButtonStyle} type='submit' >
            Submit
        </Button>
        </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}



// const [rejectionAction, setRejectionAction] = useState(false);
// <Box onClick={() => setRejectionAction(true)}>

// <ClaimsRejectionReason
//         rejectionAction={rejectionAction}
//         setRejectionAction={setRejectionAction}
//       />