import React, { useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import { addRole, uploadDocToS3 } from "../../actions";
import UploadDataBox from "../../assets/UploadDataBox";
import UploadData from "../../assets/UploadData";
import UploadDataCard from "../../assets/UploadDataCard";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Upload } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { enqueueSnackbar } from "notistack";
const AddRoleForm = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [modules, setModules] = useState("");
  const [permission, setPermission] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [builder, setBuilder] = useState("");
  const [property, setProperty] = useState([]);
  const [img, setImg] = useState({ url: "", obj: null });
  const [checkboxStates, setCheckboxStates] = React.useState({
    Dashboard: false,
    "Nesto Modules": false,
    "Builder Management": false,
    "All Builder": false,
    "Add Builder": false,
    "Past Properties": false,
    Intents: false,
    "Raised Dispute Question": false,
    "Property Management": false,
    "All Property": false,
    "Add Property": false,
    "Add Floor Plans": false,
    "Add Brokerage": false,
    "Loan Approved": false,
    "Furnishing Detail": false,
    Specifications: false,
    "Sub Company": false,
    "Property Type": false,
    "Location Advantages": false,
    Amenity: false,
    "Broker Management": false,
    "All Brokers": false,
    Refer: false,
    "Refer Payments": false,
    "Loan Agent": false,
    "Transaction History": false,
    "Manage Q & A": false,
    Training: false,
    Analytics: false,
    "Queries Management": false,
    Blogs: false,
    Claims: false,
    "Eligible Claims": false,
    Visits: false,
    "Normal Visits": false,
    "Promoted Visits": false,
    Promotions: false,
    Subscription: false,
    "New Subscription": false,
    "Purchase Subscription": false,
    Policies: false,
    "Privacy Policy": false,
    "Terms & Condiions": false,
    "Contact Query": false,
    // Add more checkboxes here
  });
  const [loading, setLoading] = useState(false);
  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "26.3%",
    zIndex: 10,
    width: "100px",
    height: "100px",
    cursor: "pointer",
  };

  // const token = localStorage.getItem('token');
  const dispatch = useDispatch();

  const handleUploadImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("documents", file);
      dispatch(
        uploadDocToS3(
          formData,
          (data) => {},
          (err) => {
            console.log(err);
          }
        )
      );
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!name) {
      enqueueSnackbar("Please enter a name for a role", { variant: "warning" });
    } else if (!Object.values(checkboxStates).includes(true)) {
      enqueueSnackbar("Please select atleast one permission for the role", {
        variant: "warning",
      });
    } else {
      setLoading(true);
      dispatch(
        addRole(
          {
            name,
            permissions: checkboxStates,
          },
          (data) => {
            enqueueSnackbar("Role Added Successfully", { variant: "success" });
            setLoading(false);
            props.setOpen(false);
          },
          (err) => {
            if (err?.response?.data) {
              enqueueSnackbar(err?.response?.data, { variant: "error" });
            } else {
              enqueueSnackbar("Error Occured! Please try after some time", {
                variant: "error",
              });
            }
            setLoading(false);
          }
        )
      );
    }
  }

  const handlePropertyChange = (event) => {
    setProperty((prev) => {
      if (prev.includes(event.target.value)) {
        return prev.filter((val) => val !== event.target.value);
      } else {
        return [...event.target.value];
      }
    });
  };

  const handleAddImage = (event) => {
    setImg({
      url: URL.createObjectURL(event.target.files[0]),
      obj: event.target.files[0],
    });
  };

  const navItem = [
    {
      name: "Dashboard",
      subItem: false,
    },
    {
      name: "Nesto Modules",
      subItem: false,
    },

    {
      name: "Builder Management",
      subItem: [
        { name: "All Builder" },
        { name: "Add Builder" },
        { name: "Past Properties" },
        { name: "Intents" },
        { name: "Raised Dispute Question" },
      ],
    },
    {
      name: "Property Management",
      subItem: [
        { name: "All Property" },
        { name: "Add Property" },
        { name: "Add Floor Plans" },
        { name: "Add Brokerage" },
        { name: "Loan Approved" },
        {
          name: "Furnishing Detail",
        },
        { name: "Specifications" },
        { name: "Sub Company" },
        { name: "Property Type" },
        {
          name: "Location Advantages",
        },
        { name: "Amenity" },
      ],
    },
    {
      name: "Broker Management",
      subItem: [
        { name: "All Brokers" },
        { name: "Refer" },
        { name: "Refer Payments" },
      ],
    },
    {
      name: "Loan Agent",
      subItem: false,
    },
    {
      name: "Transaction History",
      subItem: false,
    },
    {
      name: "Payment Management",
      subItem: false,
    },

    {
      name: "Manage Q & A",
      subItem: false,
    },
    {
      name: "Training",
      subItem: false,
    },
    {
      name: "Analytics",
      subItem: false,
    },
    // {
    //   name: "Content Management",
    //   url: "/",
    //   icon: <ContactMail sx={IconStyle} />,
    //   subItem: false,
    // },
    {
      name: "Queries Management",
      subItem: false,
    },
    {
      name: "Blogs",
      subItem: false,
    },
    {
      name: "Claims",
      subItem: [{ name: "Claims" }, { name: "Eligible Claims" }],
    },
    {
      name: "Visits",
      subItem: [{ name: "Normal Visits" }, { name: "Promoted Visits" }],
    },
    {
      name: "Promotions",
      subItem: false,
    },
    {
      name: "Subscription",
      subItem: [
        { name: "New Subscription" },
        { name: "Purchase Subscription" },
      ],
    },
    {
      name: "Policies",
      subItem: [{ name: "Privacy Policy" }, { name: "Terms & Condiions" }],
    },
    {
      name: "Contact Query",
      subItem: false,
    },
  ];

  const handleCheckboxChange = (checkboxName) => (event) => {
    setCheckboxStates((prevState) => {
      const newState = { ...prevState };

      // If the clicked checkbox is a main item with subitems
      const currentItem = navItem.find((item) => item.name === checkboxName);
      if (currentItem && currentItem.subItem) {
        // Set state for all subitems
        currentItem.subItem.forEach((subItem) => {
          newState[subItem.name] = event.target.checked;
        });

        // Check if all subitems are selected
        const allSubItemsSelected = currentItem.subItem.every(
          (subItem) => newState[subItem.name]
        );

        // If all subitems are selected, set the main item as selected
        if (allSubItemsSelected) {
          newState[checkboxName] = true;
        } else {
          newState[checkboxName] = false;
        }
      } else {
        // If the clicked checkbox is a main item without subitems or a subitem
        newState[checkboxName] = event.target.checked;

        // Check if the clicked checkbox is a subitem
        const parentItem = navItem.find(
          (item) =>
            item.subItem &&
            item.subItem.some((sub) => sub.name === checkboxName)
        );

        if (parentItem) {
          const allParentSubItemsSelected = parentItem.subItem.every(
            (subItem) => newState[subItem.name]
          );

          // If all subitems are selected, set the parent as selected
          if (allParentSubItemsSelected) {
            newState[parentItem.name] = true;
          } else {
            newState[parentItem.name] = false;
          }
        }
      }

      return newState;
    });
  };

  return (
    <Box sx={{ marginTop: "0px" }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <label>Role Name</label>
            <input
              onChange={(e) => setName(e.target.value)}
              value={name}
              className="inputBox"
              type="text"
              name="name"
              placeholder="Enter employee name"
            />
          </Grid>

          {navItem.map((item, index) => (
            <Grid item md={12} key={index}>
              {/* Checkbox for main item */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxStates[item.name]}
                    onChange={handleCheckboxChange(item.name)}
                  />
                }
                label={item.name}
                labelPlacement="end"
              />
              <br />

              <Box sx={{ ml: 5, mt: 1 }}>
                {item.subItem &&
                  // Checkboxes for subitems
                  item.subItem.map((subItem, subIndex) => (
                    <FormControlLabel
                      key={subIndex}
                      control={
                        <Checkbox
                          checked={checkboxStates[subItem.name]}
                          onChange={handleCheckboxChange(subItem.name)}
                        />
                      }
                      label={subItem.name}
                      labelPlacement="end"
                    />
                  ))}
              </Box>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              disableElevation
              type="submit"
              variant="contained"
              sx={{ px: 10, py: 1, mb: "15px", mr: 3, mt: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={26} /> : "Add"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

const MenuProps = {
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "100%",
    },
  },
};

export default AddRoleForm;
