import React, { useState, useEffect, useCallback } from "react";

import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";

import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";
import { claimsData } from "../../utils/data";
import { Link, useNavigate } from "react-router-dom";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { sectionBarBtn } from "../../Style/stylesheet";
import ClaimsRemarks from "./ClaimsRemarks";
import TransactionDetailsForm from "./TransactionDetailsForm";
import ClaimsApproveForm from "./ClaimsApproveForm";
import ClaimsRejectionReason from "./ClaimsRejectionReason";
import { useDispatch } from "react-redux";
import { getAllClaims } from "../../actions";

export default function ClaimsRejected() {
  const [sectionBarActive, setSectionBarActive] = useState("Rejected");
  const [claimsRejected, setClaimsRejected] = useState([]);
  const [status, setStatus] = useState("");
  const [addRemarkAction, setAddRemarkAction] = useState(false);
  const [rejectionAction, setRejectionAction] = useState(false);
  const [approveAction, setApproveAction] = useState(false);
  const [transactionDetailsAction, setTransactionDetailsAction] =
    useState(false);
  const [claimId, setClaimId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState("");
  const [remark, setRemark] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allClaims, setAllClaims] = useState([]);
  const [propertyId, setPropertyId] = useState({});
  const [brokerId, setBrokerId] = useState({});
  const [claimType, setClaimtype] = useState("");

  const loadAllClaims = useCallback(() => {
    dispatch(
      getAllClaims({ claimStatus: "rejected" }, (data) => {
        const filter = data?.data.filter((item) => {
          if (
            item?.claimStatus.toLowerCase().includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.milestoneNumber
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.builderId?.name
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.brokerageAmount
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.createdAt.toLowerCase().includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.propertyId?.name
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.brokerId?._id
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (item?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true;
          }

          if (
            item?.propertyId?._id
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          return false;
        });
        if (searchQuery) {
          setClaimsRejected(filter);
        }
        setAllClaims(data.data);
      })
    );
  }, [dispatch, searchQuery]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      loadAllClaims();
    }
  }, [loadAllClaims, navigate]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      dispatch(
        getAllClaims(
          {
            claimStatus: "rejected",
            pageNo: currentPage,
            pageSize: countPerPage,
          },
          (data) => {
            if (searchQuery == "") {
              setClaimsRejected(data.data);
            }
          }
        )
      );
    }
  }, [currentPage, countPerPage, searchQuery]);

  const [amount, setAmount] = useState(0);

  const handleChange = (
    event,
    index,
    claimId,
    propertyId,
    brokerId,
    amount,
    claimType
  ) => {
    setAmount(amount);
    setStatus(event.target.value);
    setClaimId(claimId);
    setPropertyId(propertyId);
    setBrokerId(brokerId);
    setClaimtype(claimType);
    claimsData[index].status = event.target.value;
    if (event.target.value == "rejected") {
      setRejectionAction(true);
    } else {
      setRejectionAction(false);
    }
    if (event.target.value == "approved") {
      setApproveAction(true);
    } else {
      setApproveAction(false);
    }
    if (event.target.value == "recieved") {
      setTransactionDetailsAction(true);
    } else {
      setTransactionDetailsAction(false);
    }
  };

  const headData = [
    "PROPERTY ID",
    "Claim ID",
    "Broker ID",
    "Property Name",
    "claim date",
    "Claim amount",
    "builder name",
    "milestone no",
    "Rejected Reason",
    "Rejection Comment",
    "Status",
    "Action",
    "Remarks",
  ];
  return (
    <Layout path="claims-rejected">
      <Box>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Claims " />
          {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Claims" && "active"}
            onClick={() => navigate("/claims")}
          >
            Submitted
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Approved" && "active"}
            onClick={() => navigate("/claims/claims-approved")}
          >
            Approved
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Rejected" && "active"}
            onClick={() => navigate("/claims/claims-rejected")}
          >
            Rejected
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Recieved" && "active"}
            onClick={() => navigate("/claims/payment-recieved")}
          >
            Payment Recieved
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Paid" && "active"}
            onClick={() => navigate("/claims/claims-paid")}
          >
            Paid
          </Button>
        </Stack>
        <SearchFilter>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </SearchFilter>
        <Box sx={{ mb: 4 }}></Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {claimsRejected?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                  >
                    {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?.propertyId?._id}</TableCell>
                    <TableCell>{item?._id}</TableCell>
                    <TableCell>{item?.brokerId?._id}</TableCell>
                    <TableCell>{item?.propertyId?.name}</TableCell>
                    <TableCell>
                      {new Date(item?.createdAt)?.toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      {item?.brokerageAmount ? item?.brokerageAmount : "--"}
                    </TableCell>
                    <TableCell>{item?.builderId?.companyName}</TableCell>
                    <TableCell>
                      {" "}
                      {parseInt(item?.milestoneNumber) + 1 < 10
                        ? `0${parseInt(item?.milestoneNumber) + 1}`
                        : parseInt(item?.milestoneNumber) + 1}
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.claimRejectReason}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{item?.comment}</Typography>
                    </TableCell>

                    <TableCell>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={item.claimStatus}
                          onChange={(e) =>
                            handleChange(
                              e,
                              index,
                              item?._id,
                              item?.propertyId,
                              item?.brokerId?._id,
                              item?.amount,
                              item?.claimType
                            )
                          }
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {/* <MenuItem value={'submitted'}>Submitted</MenuItem> */}
                          <MenuItem value={"rejected"}>Rejected</MenuItem>
                          <MenuItem value={"approved"}>Approved</MenuItem>
                          <MenuItem value={"recieved"}>
                            Payment Recieved
                          </MenuItem>
                          <MenuItem value={"paid"}>Paid</MenuItem>
                        </Select>{" "}
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ color: "#278FD9" }}>
                      {" "}
                      <Link
                        to={`/${
                          item?.claimType?.toLowerCase() == "visit"
                            ? "visit-claim"
                            : "property-claim"
                        }/${item._id}`}
                      >
                        View
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Box onClick={() => setAddRemarkAction(true)}>
                        <IconButton size="large" color="primary">
                          <Draw />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
              {allClaims?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0}{" "}
              -{" "}
              {countPerPage > allClaims?.length
                ? allClaims?.length
                : currentPage * countPerPage > allClaims?.length
                ? allClaims?.length
                : currentPage * countPerPage}{" "}
              of {allClaims?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      currentPage === 1 || countPerPage > allClaims?.length
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      background:
                        currentPage === 1 || countPerPage > allClaims?.length
                          ? "#EFEFEF"
                          : "#FFF",
                    }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allClaims?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > allClaims?.length ||
                      currentPage == Math.ceil(allClaims?.length / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <ClaimsRemarks
        remark={remark}
        setRemark={setRemark}
        claimId={claimId}
        claimStatus={status}
        addRemarkAction={addRemarkAction}
        setAddRemarkAction={setAddRemarkAction}
      />
      <ClaimsRejectionReason
        claimId={claimId}
        claimStatus={status}
        rejectionAction={rejectionAction}
        setRejectionAction={setRejectionAction}
      />
      <ClaimsApproveForm
        claimId={claimId}
        claimStatus={status}
        approveAction={approveAction}
        setApproveAction={setApproveAction}
        property={propertyId}
        claimType={claimType}
        broker={brokerId}
      />

      <TransactionDetailsForm
        claimId={claimId}
        claimStatus={status}
        transactionDetailsAction={transactionDetailsAction}
        setTransactionDetailsAction={setTransactionDetailsAction}
        broker={brokerId}
        amount={amount}
      />
    </Layout>
  );
}
