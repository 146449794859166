import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Stack } from "@mui/system";
import Layout from "../../assets/Layout";

import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import {
    Checkbox,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { roleData } from "../../utils/roleData";
import { Link, useNavigate } from "react-router-dom";
import { getAllBuilders, getAllProperty, getAllRoles } from "../../actions";
import { useDispatch } from "react-redux";
import CustomDialog from "../CustomDialog";
import AddRoles from "./AddRoles";
import UpdateRole from "./UpdateRole";
import edit from '../../assets/img/edit.svg'
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
function RolesListingEmployees() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [allRoles, setAllRoles] = React.useState([]);
    const [allBuilders, setAllBuilders] = React.useState([]);
    const [allProperties, setAllProperties] = React.useState([]);

    // Add role states
    const [addModal, setAddModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);

    const [selectedRole, setSelectedRole] = useState("");

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        } else {
            getRoles();
            getAllBuildersList();
            getAllPropertyList();
        }
    }, [navigate]);

    const [permission, setPermission] = React.useState("");
    const [roles, setRoles] = React.useState([]);
    const handleChange = (index, e) => {
        var temp = [...roles];
        temp[index].permission = e.target.value;
        setRoles(temp);
    };

    const getRoles = () => {
        dispatch(
            getAllRoles({ pageSize: 20, pageNo: 1 }, (data) => {
                if (data.status === 200) {
                    setAllRoles(data.data);
                }
            })
        );
    };

    const getAllBuildersList = useCallback(async () => {
        dispatch(
            getAllBuilders({ isAdmin: true }, (data) => {
                console.log(data?.data);
                setAllBuilders(data?.data);
            })
        );
    }, [dispatch]);

    const getAllPropertyList = () => {
        dispatch(
            getAllProperty({}, (data) => {
                setAllProperties(data?.data);
            })
        );
    };

    useEffect(() => {
        dispatch(getAllRoles({}, (data) => {
            setRoles(data.data);
        }))
    }, [])

    return (
        <>
            <Layout path="roles">
                <Box>
                    <Stack
                        direction={{ md: 'row', xs: 'column' }}
                        spacing={3}
                        sx={{ my: 4 }}
                        justifyContent={'space-between'}
                    >
                        <Breadcrumbs primary="Roles & Employees - Builder" />
                        <Box sx={{ display: 'flex' }}>
                            <ButtonController >
                                <Box>
                                    <IconButton className="iconBtn">
                                        <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={'span'}>
                                        <strong>Jun 24, 2022</strong> Today
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton className="iconBtn">
                                        <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                                    </IconButton>
                                </Box>
                            </ButtonController>
                            <Link onClick={() => setAddModal(true)} style={{ marginLeft: '10px' }}>
                                <MainButton>
                                    <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Role
                                </MainButton>
                            </Link>
                        </Box>
                    </Stack>
                    {/* <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Box>
                <Link onClick={() => setAddModal(true)}>
                  <MainButton>
                    <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Role
                  </MainButton>
                </Link>
              </Box>
            </Stack> */}
                    <Box sx={{ mb: 4 }}>{/* <SearchBar /> */}</Box>

                    <Paper elevation={0} sx={{ width: "100%", overflow: "hidden", borderRadius: '16px' }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        {headData.map((item, index) => (
                                            <TableCell
                                                key={index}
                                                style={{ minWidth: "200px" }}
                                                align="center"
                                            >
                                                <Typography
                                                    sx={{
                                                        color: "mute.darktext",
                                                        textTransform: "uppercase",
                                                        fontSize: "0.9rem",
                                                    }}
                                                >
                                                    {item}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allRoles.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                                        >
                                            <TableCell>
                                                <Checkbox />
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>46676732989</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Lorem Ipsum</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>email123@gmail.com</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Builder Management</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>Lorem Ipsum</TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>12 Jan 2022</TableCell>


                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <FormControl sx={{}}>
                                                    {/* <Select
                                                        value={item.permission}
                                                        onChange={handleChange}
                                                        displayEmpty
                                                        inputProps={{ "aria-label": "Without label" }}
                                                        sx={{
                                                            borderRadius: '32px !important', padding: '0 !important', backgroundColor: '#E9E9E9', border: 'none !important',
                                                            '&.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input': {
                                                                padding: '0px !important',
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value="" disabled selected>
                                                            Choose permission
                                                        </MenuItem>
                                                        <MenuItem value={"read"}>Read</MenuItem>
                                                        <MenuItem value={"write"}>Write</MenuItem>
                                                    </Select> */}
                                                    <select className="select">
                                                        <option>Lorem</option>
                                                        <option>Lorem1</option>
                                                        <option>Lorem2</option>
                                                    </select>
                                                </FormControl>
                                            </TableCell>

                                            <TableCell sx={{ textAlign: 'center' }}><img src={edit} /> </TableCell>

                                            {/* <TableCell>
                        <Switch checked={item.isDeleted} onChange={() => {}} />
                      </TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    <Box
                        sx={{
                            bgcolor: "mute.dark",
                            borderRadius: 1,
                            color: "dark.text",
                            p: 2,
                            mt: 4,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography>1 - 10 of 97</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Typography> Rows per page:</Typography>
                            <select style={{ marginTop: "-5px" }}>
                                <option value="10" selected>
                                    10
                                </option>
                                <option value="10" selected>
                                    20
                                </option>
                                <option value="10" selected>
                                    30
                                </option>
                            </select>
                            <Box sx={{ ml: 2 }}>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Box>
                                        <IconButton
                                            sx={{ border: "1px solid", borderColor: "mute.main" }}
                                        >
                                            <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Typography variant="text" component={"span"}>
                                            1/10
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            sx={{
                                                border: "1px solid",
                                                borderColor: "mute.main",
                                                bgcolor: "white.main",
                                            }}
                                        >
                                            <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* <CustomDialog open={true} title="New Roles" /> */}
                <AddRoles
                    open={addModal}
                    setOpen={setAddModal}
                    size={"lg"}
                    builders={allBuilders}
                    properties={allProperties}
                />
                <UpdateRole
                    open={updateModal}
                    setOpen={setUpdateModal}
                    size={"lg"}
                    builders={allBuilders}
                    properties={allProperties}
                    role={selectedRole}
                />
            </Layout>
        </>
    );
}

const headData = ["User Id", "Employee Name", "Email", "Role", "Password", "Creation Date", "Status", "Edit"];

export default RolesListingEmployees;
