import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getSubscriber } from '../../actions';
const Subscribers = ({ open, setOpen, id }) => {
  // const [open, setOpen] = useState(false);
  const [allVisits, setAllVisits] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const headData = [
    'Subscription Id',
    'Builder NAme',
    'No. of Properties ',
    'Date of Purchase',
    'Payment status',
    'Transaction No.',
  ];
  console.log("name", id)

  useEffect((id) => {

    dispatch(getSubscriber(id, (data) => {
      const filteredProperties = data?.data.filter(item => item._id === id);
      console.log("subscription1234", filteredProperties)
      setSubscribers(filteredProperties);
    }))
  }, [dispatch]);
  console.log(subscribers)

  return (
    <div>

      <Dialog
        open={open}
        sx={{
          borderRadius: '20px', // Set border radius

        }}
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant='h5' sx={{ fontWeight: 'bold' }}>No. Of Subscriber</Typography>
          <IconButton>
            <CancelIcon onClick={handleClose} />
          </IconButton>

        </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                      <Typography
                        sx={{
                          color: 'mute.darktext',
                          textTransform: 'uppercase',
                          fontSize: '0.9rem',
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  subscribers.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item?._id}</TableCell>
                      <TableCell sx={{ color: '#D12953' }}>{item?.builderId?.name}</TableCell>
                      <TableCell>{item?.selectProperties?.length}</TableCell>
                      <TableCell>{item?.createdAt}</TableCell>
                      <TableCell>Paid</TableCell>
                      <TableCell>{item?.transactionId}</TableCell>
                    </TableRow>
                  ))
                }

              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Subscribers;
