import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../assets/Layout";
import InputStyle from "../../assets/InputStyle";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import UploadDataBox from "../../assets/UploadDataBox";
import UploadDataCard from "../../assets/UploadDataCard";
import UploadData from "../../assets/UploadData";
import TableStyle from "../../assets/TableStyle";
import { Check, DeleteForever, Download, Draw } from "@mui/icons-material";
import Specifications from "./Specifications";
import FurnishingDetail from "./FurnishingDetail";
import {
  addLoanApproval,
  deleteLoanApproval,
  getAllLoanApproval,
  updateLoanApproval,
} from "../../actions";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

export default function LoanApproved() {
  const dispatch = useDispatch();

  const [bankName, setBankName] = useState("");
  const [allLoanApproval, setAllLoanApproval] = useState([]);
  const [tempIndex, setTempIndex] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempAddress, setTempAddress] = useState("");
  const [tempDistance, setTempDistance] = useState("");
  const [tempImage, setTempImage] = useState("");
  const [tempImagePicView, setTempImagePicView] = useState("");
  const [image, setImage] = useState("");
  const [imagePicView, setImagePicView] = useState("");

  const loadLoanApproval = useCallback(() => {
    dispatch(
      getAllLoanApproval({}, (data) => {
        setAllLoanApproval(data.data);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadLoanApproval();
  }, [loadLoanApproval]);

  function handleSubmit(event) {
    event.preventDefault();
    console.log(bankName);
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (!image || !bankName) {
      enqueueSnackbar("Please fill all the fields", { variant: "error" });
    } else {
      var formData = new FormData();
      formData.append("image", image);
      formData.append("name", bankName);
      dispatch(
        addLoanApproval(formData, (data) => {
          enqueueSnackbar("Data added successfully", { variant: "success" });
          loadLoanApproval();
          setImage("");
          setImagePicView("");
          setBankName("");
        })
      );
    }
  }

  const handleDelete = (id) => {
    dispatch(
      deleteLoanApproval({ id }, (data) => {
        console.log(data);
        enqueueSnackbar("Data deleted successfully", { variant: "success" });
        loadLoanApproval();
      })
    );
  };

  const handleEdit = (index) => {
    setTempIndex(index);
    setTempName(allLoanApproval[index].name);
    setTempImage(allLoanApproval[index].image);
    setTempImagePicView(allLoanApproval[index].image);
  };

  const handleUpdate = (index) => {
    var val = allLoanApproval;
    val[index] = { ...val[index], name: tempName, image: tempImagePicView };
    let formData = new FormData();
    formData.append("id", val[index]._id);
    formData.append("image", tempImage);
    formData.append("name", tempName);
    dispatch(
      updateLoanApproval(formData, (data) => {
        enqueueSnackbar("Data updated successfully", { variant: "success" });
      })
    );
    setTempIndex("");
  };

  return (
    <Layout>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography
            variant="title"
            sx={{ fontWeight: 900, mb: 4 }}
            component={"h3"}
          >
            Loan Approved By
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Stack spacing={3} component="form" onSubmit={handleSubmit}>
                <InputStyle name="Bank Name" topSpace={true}>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Bank Name"
                    onChange={(e) => setBankName(e.target.value)}
                    value={bankName}
                  />
                </InputStyle>{" "}
                <UploadDataBox>
                  <UploadData name={"Icon"} mt={0}>
                    <UploadDataCard>
                      <img
                        src={
                          imagePicView && imagePicView != ""
                            ? imagePicView
                            : "/uploadImg.png"
                        }
                        alt="upload"
                        className="img-fluid"
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          setImage(e.target.files[0]);
                          setImagePicView(
                            URL.createObjectURL(e.target.files[0])
                          );
                        }}
                      />
                    </UploadDataCard>
                  </UploadData>
                </UploadDataBox>
                <Button type="submit" variant="contained" fullWidth>
                  Add
                </Button>
              </Stack>
            </Grid>
            <Grid item md={7}>
              <TableStyle>
                <tr>
                  <th>Icon</th>
                  <th>Bank Name</th>
                  <th>Action</th>
                </tr>
                <tbody>
                  {allLoanApproval?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {tempIndex === index ? (
                          <UploadDataBox>
                            <UploadData mt={0}>
                              <UploadDataCard>
                                <img
                                  src={
                                    tempImagePicView && tempImagePicView != ""
                                      ? tempImagePicView
                                      : "/uploadImg.png"
                                  }
                                  alt="upload"
                                  className="img-fluid"
                                />
                                <IconButton
                                  aria-label="clear_image"
                                  sx={{
                                    position: "absolute",
                                    zIndex: 2,
                                    right: "0px",
                                    top: "5px",
                                    background: "#278FD9aa",
                                    height: "25px",
                                    width: "25px",
                                  }}
                                >
                                  <a
                                    href={tempImagePicView}
                                    target="_blank"
                                    download
                                  >
                                    <Download
                                      color={"white"}
                                      sx={{ fontSize: ".8rem" }}
                                    />
                                  </a>
                                </IconButton>
                                <input
                                  type="file"
                                  accept="image/*"
                                  onChange={(e) => {
                                    setTempImage(e.target.files[0]);
                                    setTempImagePicView(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                  }}
                                />
                              </UploadDataCard>
                            </UploadData>
                          </UploadDataBox>
                        ) : (
                          <img
                            src={item.image}
                            alt="Bank Icon"
                            className="icon"
                          />
                        )}
                      </td>
                      <td>
                        {tempIndex === index ? (
                          <InputStyle topSpace={true}>
                            <input
                              type="text"
                              style={{ width: "90%" }}
                              placeholder="Near By Area Name "
                              value={tempName}
                              onChange={(e) => setTempName(e.target.value)}
                            />
                          </InputStyle>
                        ) : (
                          item.name
                        )}
                      </td>
                      <td>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent={"center"}
                        >
                          <div>
                            {tempIndex === index ? (
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleUpdate(index)}
                              >
                                <Check />
                              </IconButton>
                            ) : (
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleEdit(index)}
                              >
                                <Draw />
                              </IconButton>
                            )}
                          </div>
                          <div>
                            <IconButton
                              color="error"
                              size="small"
                              onClick={() => handleDelete(item._id)}
                            >
                              <DeleteForever />
                            </IconButton>
                          </div>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
}
