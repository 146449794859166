import React, { useState } from 'react';
import {
  Box,
  Typography,
  Stack,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import InputStyle from '../../assets/InputStyle';
import { Close } from '@mui/icons-material';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import ClearIcon from '@mui/icons-material/Clear';

export default function Profile({ setProfileAction, profileAction }) {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [loanRange, setLoanRange] = useState("");
  const [bankAssociation, setBankAssociation] = useState("");
  const [area, setArea] = useState("");
  const [image, setImage] = useState([]);
  const [documents, setDocuments] = useState([]);
  const hideInput = {
    opacity: 0,
    position: 'absolute',
    left: '26.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  const handleRemovePropertyLogo = () => {
    setImage([]);
  }

  const handleRemovePropertyBrochure = (index) => {
    setDocuments(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });
  }

  const handleUploadLogo = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage([file]);
    }
  };

  const handleUploadBrochure = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocuments(oldArr => [...oldArr, e.target.value]);
    }
  };

  return (
    <Dialog
      open={profileAction}
      onClose={() => {
        setProfileAction(!profileAction);
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <UploadDataBox>
          <UploadData name={'Upload Property Logo'}>
            <UploadDataCard>

              {image.length > 0 ?

                <>
                  <img
                    src={URL.createObjectURL(image[0])}
                    alt="upload"
                    className="img-fluid"
                  />
                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyLogo}>
                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                  </IconButton>
                </>
                :
                <>
                  <img
                    src={'/uploadImg.png'}
                    alt="upload"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    style={hideInput}
                    onChange={handleUploadLogo}
                  />
                </>
              }
            </UploadDataCard>
          </UploadData>
          <UploadData name={'Upload Property Brochure'}>
            {documents &&
              documents.map((item, index) => (
                <>
                  <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                    <embed src={item} width="200" height="200" />
                    <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                      <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                    </IconButton>
                  </Box>
                </>
              ))}
            <UploadDataCard>
              <img
                src={'/uploadImg.png'}
                alt="upload"
                className="img-fluid"
              />
              <input
                type="file"
                accept=".doc,.pdf"
                style={hideInput}
                onChange={handleUploadBrochure}
              />
            </UploadDataCard>
          </UploadData>
        </UploadDataBox>
        <Divider />

        <Box sx={{ my: 3 }}>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <InputStyle name="DSA Name">
                <input type="text" placeholder="Enter DSA name" value={name} onChange={(e) => setName(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Phone Number">
                <input type="text" placeholder="Enter phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Email">
                <input type="text" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Company Name">
                <input type="text" placeholder="Enter Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Address">
                <input type="text" placeholder="Enter Address" value={address} onChange={(e) => setAddress(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Loan Range">
                <input type="text" placeholder="Choose range" value={loanRange} onChange={(e) => setLoanRange(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Bank Associations">
                <input type="text" placeholder="Choose bank associations" value={bankAssociation} onChange={(e) => setBankAssociation(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={6}>
              <InputStyle name="Area of Operations">
                <input type="text" placeholder="Enter area of operations" value={area} onChange={(e) => setArea(e.target.value)} />
              </InputStyle>
            </Grid>
            <Grid item md={12}>
              <Button
                variant="contained"
                sx={{ boxShadow: 0, width: 150, mr: 1 }}
              >
                Update
              </Button>
              <Button variant="outlined" sx={{ width: 150 }}>
                Reset
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog >
  );
}
