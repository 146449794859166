import { Box, Stack } from '@mui/material';
import React from 'react';
import MainButton from './MainButton';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function ButtonController({ children }) {
  return (
    <Stack direction={'row'} spacing={2} alignItems={'center'}>
      <Box>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{
            '& .iconBtn': { border: '1px solid', borderColor: 'mute.main' },
          }}
        >
          {children}
        </Stack>
      </Box>
    </Stack>
  );
}
