import React, { useEffect } from 'react'
import Navbar from '../Navbar'
import { Box, Accordion, AccordionSummary, AccordionDetails, Button, Grid, Stack, Typography, IconButton } from '@mui/material'
import Sidenav from '../Sidenav'
import { sectionBarBtn } from '../../Style/stylesheet';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import SearchFilter from '../../assets/SearchFilter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '../../assets/img/edit.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import WriteReview from '../../assets/WriteReview';
import { getAllQueries } from '../../actions';
import { useDispatch } from 'react-redux';
function Queries() {
    const navigate = useNavigate();
    const [sectionBarActive, setSectionBarActive] = useState('New')
    const [expanded, setExpanded] = useState(false);
    const [writeReview, setWriteReview] = useState(false);
    const [queries, setQueries] = useState([]);
    const [queriesId, setQueriesId] = useState("");
    const [searchQuery, setSearchQuery] = useState('');

    const dispatch = useDispatch();

    const DaysAgo = (date) => {
        if (date) {
            const inputDate = new Date(date);
            const currentDate = new Date();
            const timeDifference = currentDate.getTime() - inputDate.getTime();
            const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            return daysAgo;
        }
    }



   

    const openReviewModal = (id) => {
        setWriteReview(true)
        setQueriesId(id)
    };

    useEffect(() => {
        dispatch(getAllQueries({}, (data) => {
            const filter = data.data.filter((item) => {
                if (item?._id.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.subject?.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.createdById?.name?.toLowerCase().includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.answer?.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                return false;
            })

            const filteredProperties = filter.filter(item => item.status === "new");

            setQueries(filteredProperties);
        }))
    }, [searchQuery]);


    const [accordionExpanded, setAccordionExpanded] = useState([]); 

     const handleAccordionChange = (index) => {
        setAccordionExpanded((prevState) => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };

    console.log(queries)

    return (
        <Box>
            <Navbar />
            <Grid container>
                <Grid item md={3}>
                    <Sidenav />
                </Grid>
                <Grid item md={9} py={2} px={2}>
                    <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Queries Management</Typography>
                    <Stack
                        direction={'row'}
                        sx={{ p: 1, bgcolor: '#fff', my: 2, borderRadius: 10, justifyContent: 'center', mx: 20 }}
                    >
                        <Button
                            sx={sectionBarBtn}
                            className={sectionBarActive === 'New' && 'active'}
                            onClick={() => navigate('/queries/new')}
                        >
                            New
                        </Button>
                        <Button
                            sx={sectionBarBtn}
                            className={sectionBarActive === 'Respond' && 'active'}
                            onClick={() => navigate('/queries/responded')}
                        >
                            Respond
                        </Button>
                    </Stack>

                    <SearchFilter >
                        <Box className="search style-1" >
                            <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        </Box>
                    </SearchFilter>

                    {
                        queries.map((item, index) => (
                            <Accordion
                                expanded={accordionExpanded[index]} // Set expansion state based on index
                                onChange={() => handleAccordionChange(index)} // Pass index to the handler
                                key={index}
                                sx={{
                                    my: 1,
                                    borderRadius: '16px',
                                    '& .MuiAccordionSummary-root': {
                                        borderRadius: '16px',
                                    },
                                    '& .MuiAccordionSummary-expandIcon': {
                                        borderRadius: '16px',
                                    },
                                }}>
                                <AccordionSummary
                                    expandIcon={
                                        <IconButton
                                            aria-label="expand"
                                            size="small"
                                            edge="end"
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleAccordionChange();
                                            }}


                                            sx={{
                                                color: expanded ? '#fff' : '#000',
                                                backgroundColor: expanded ? '#278FD9' : '#fff'
                                            }}
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    }
                                >
                                    <Typography>{item.subject}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography sx={{ color: '#6F6C90' }}>
                                        {item.description}
                                    </Typography>

                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'middle', mt: 2 }}>
                                        <Typography sx={{ color: '#278FD9' }}>{item.createdById?.name} <span style={{ color: '#5C5F62' }}> <FiberManualRecordIcon sx={{ fontSize: '8px', ml: 3 }} /> {DaysAgo(item.createdAt)} Days ago</span> </Typography>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                alignSelf: 'flex-end',
                                                marginTop: 'auto',
                                                color: '#000',
                                                background: '#fff',
                                                border: '1px Solid #d3d3d3',
                                                boxShadow: 'none',
                                                borderRadius: '20px',
                                                fontSize: '10px'
                                            }}
                                            onClick={() => openReviewModal(item._id)}
                                        >
                                            <img src={EditIcon} alt='icon' width="16px" />
                                            <Typography mx={2} variant='text' sx={{ fontWeight: 'bold' }}>Write Answer</Typography>
                                        </Button>
                                        {
                                            writeReview === true ? <WriteReview open={writeReview} setOpen={setWriteReview} id={queriesId} /> : ""
                                        }
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Grid>
            </Grid>


        </Box>
    )
}

export default Queries