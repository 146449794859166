import { Typography } from '@mui/material';
import React from 'react';

export default function Breadcrumbs({ primary, title }) {
  return (
    <Typography variant="h6" component="breadcrumbs" sx={{fontWeight:'600'}}>
      {primary} {title && `/ ${title}`}
    </Typography>
  );
}
