import React, { useEffect, useCallback, useState } from 'react';
import Layout from '../../assets/Layout';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Breadcrumbs from '../../assets/Breadcrumbs';
import ButtonController from '../../assets/ButtonController';
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Edit,
} from '@mui/icons-material';
import MainButton from '../../assets/MainButton';
import Update from './update';
import AddBlog from './addBlogs';
import { useDispatch } from 'react-redux';
import { deleteBlog, getAllBlog } from '../../actions';
import { useNavigate, useParams } from 'react-router-dom';
import SearchFilter from '../../assets/SearchFilter';
import DeleteIcon from '@mui/icons-material/Delete';
import { enqueueSnackbar } from 'notistack';

export default function Blogs() {
  const dispatch = useDispatch()
  const [updateDialog, setUpdateDialog] = useState(false);
  const [addDialog, setAddDialog] = useState(false);
  const [blog, setBlog] = useState([]);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [id, setId] = useState('');
  const [slug, setSlug] = useState('');
  const [allBlogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const handleChange = (index, e) => {
  //   var temp = [...blog];
  //   temp[index].permission = e.target.value;
  //   setBlog(temp);
  // };

  const loadAllBlogs = useCallback(() => {
    setLoading(true);
    dispatch(getAllBlog({}, (data) => {
      setAllBlogs(data.data);
      setLoading(false);
    }, (err) => {
      setLoading(false);
    }))

  }, [dispatch])

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
    else {
      loadAllBlogs()
    }
  }, [loadAllBlogs, navigate])

  useEffect(() => {
    setLoading(true);
    dispatch(getAllBlog({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
      // console.log(data.data);
      const filteredBrokers = data.data.filter((broker) => {
        if (broker?._id.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
          return true
        }
        if (broker?.blogName.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
          return true
        }
        if (broker?.postedByName.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
          return true
        }
      })
      setBlog(filteredBrokers);
      setLoading(false);
    }, (err) => {
      console.log(err);
      setLoading(false);
    },
    ))
  }, [currentPage, countPerPage, searchQuery, updateDialog]);

  const handleSelectChange = (event) => {
    setCountPerPage(event.target.value);
  };

  const loadBlogs = useCallback(() => {
    setLoading(true);
    dispatch(getAllBlog({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
      // console.log(data.data);
      const filteredBrokers = data.data.filter((broker) => {
        if (broker?._id.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
          return true
        }
        if (broker?.blogName.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
          return true
        }
        if (broker?.postedByName.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
          return true
        }
      })
      setBlog(filteredBrokers);
    setLoading(false);
    }, (err) => {
      console.log(err);
    setLoading(false);
    },
    ))
  }, [dispatch]);

  useEffect(() => {
    loadBlogs();
  }, [loadBlogs])

  const handleDelete = (id) => {
    dispatch(deleteBlog({ id }, (data) => {
      console.log(data);
      enqueueSnackbar("Data deleted successfully", { variant: 'success' });
      loadBlogs();
    }))
  }

  return (
    <Layout>
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        spacing={3}
        sx={{ my: 4 }}
        justifyContent={'space-between'}
      >
        <Breadcrumbs primary="Blogs" />
        {/* <ButtonController>
          <Box>
            <IconButton className="iconBtn">
              <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="text" component={'span'}>
              <strong>Jun 24, 2022</strong> Today
            </Typography>
          </Box>
          <Box>
            <IconButton className="iconBtn">
              <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
            </IconButton>
          </Box>
          <Box onClick={() => setAddDialog(true)}>
            <MainButton>
              <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> Add Blog
            </MainButton>
          </Box>
        </ButtonController> */}
        <Box onClick={() => setAddDialog(true)}>
          <MainButton>
            <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> Add Blog
          </MainButton>
        </Box>
      </Stack>

      <Box sx={{ mb: 4 }}>
        <SearchFilter>
          <Box className="search style-1">
            <input type="text" placeholder="Search..." value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)} />
          </Box>
          {/* <Box className="style-1 filter p-1">
              <select>
                <option value="All" selected>
                  All
                </option>
                <option value="option 1">option 1</option>
              </select>
            </Box>
            <Box className="style-1">
              <input type="date" placeholder="Choose Date" />
            </Box> */}
        </SearchFilter>
      </Box>
      {
        loading
        ?
        <Typography align='center'>
          <CircularProgress />
        </Typography>
        :
        <TableContainer component={Paper} sx={{ '& th': { color: '#BABABA' } }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              <TableCell>#</TableCell>
              <TableCell>Blog Name</TableCell>
              <TableCell>Posted By</TableCell>
              <TableCell> DATE</TableCell>
              <TableCell align="center">ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blog?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox />
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="link" onClick={() => { setId(item?._id); setSlug(item?.slug); setUpdateDialog(true) }}>
                    {item?.blogName}
                  </div>
                </TableCell>
                <TableCell>{item?.postedByName}</TableCell>
                <TableCell>{item?.blogDate}</TableCell>
                <TableCell align="center">
                  {/* <IconButton size="small">
                    <Edit />
                  </IconButton> */}
                  <IconButton size="small" onClick={() => handleDelete(item._id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }
      <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
            {(currentPage - 1) * countPerPage + 1} -{' '}
            {countPerPage > allBlogs?.length
              ? allBlogs?.length
              : currentPage * countPerPage > allBlogs?.length
              ? allBlogs?.length
              : currentPage * countPerPage}{' '}
            of {allBlogs?.length}
          </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}>
              <option value="10" >
                10
              </option>
              <option value="20">
                20
              </option>
              <option value="30">
                30
              </option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1 || countPerPage > allBlogs?.length}
                    sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allBlogs?.length ? "#EFEFEF" : "#FFF" }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allBlogs?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={countPerPage > allBlogs?.length || currentPage == Math.ceil(allBlogs?.length / countPerPage)}
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      <Update updateDialog={updateDialog} setUpdateDialog={setUpdateDialog} id={id} setId={setId} slug={slug}/>
      <AddBlog addDialog={addDialog} setAddDialog={setAddDialog} />
    </Layout >
  );
}
