import React, { useCallback, useEffect } from "react";
import Layout from "../../assets/Layout";
import { BoughtData } from '../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../Style/stylesheet';
import AddIcon from '@mui/icons-material/Add';
import Edit from '../../assets/img/edit.svg';
import Delete from '../../assets/img/Delete.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
    Switch,
    Grid,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { useState } from "react";
// import ClaimsRemarks from "../../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addSubscription, getAllProperty, getAllSubscription, getAllVisits, updateVisitById } from "../../actions";
import { enqueueSnackbar } from "notistack";
// import VisitRemarks from "./../VisitRemarks";
import Subscribers from './Subscribers'
import EditSubscription from "./EditSubscription";
import InputStyle from "../../assets/InputStyle";
import ActivateSubscription from "./PurchaseSubscription/ActivateSubscription";
import CircularProgress from '@mui/material/CircularProgress';

const AddSubscription = () => {
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const [subscription, setSubscription] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [searchQuery, setSearchQuery] = useState('');
    const [price, setPrice] = useState('');
    const [subscriptionName, setSubscriptionName] = useState('');
    const [noOfProperties, setNoOfProperties] = useState([]);
    const [bottomSubliner, setBottomSubliner] = useState('');
    const [bannerDays, setBannerDays] = useState('');
    const [flashDays, setFlashDays] = useState('');
    const [noOfVisits, setNoOfVisits] = useState('');
    const [description, setDescription] = useState('');
    const [colorCode, setColorCode] = useState('');
    const [isRecommended, setIsRecommended] = useState(false);
    const [subLiner, setSubLiner] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [properties, setProperties] = useState([]);

    const headData = [
        'Subscription Name',
        'Price',
        'No. of Visits ',
        'Banner Days',
        'Flash Day',
        'Description',
        'No. of Properties',
        'No. of Subscribers',
        'Color code',
        'Subliner',
        'Status',
        'Action',


    ];

    const handleSubmit = () => {

        const formData = new FormData();

        // Append broker details to formData
        // formData.append('subscriptionName', subscriptionName);
        // formData.append('colorCode', colorCode);
        // formData.append('numberOfVisits', noOfVisits);
        // formData.append('bannerDays', bannerDays);
        // formData.append('flashDays', flashDays);
        // formData.append('description', description);
        // formData.append('price', price);
        // formData.append('bottomSubliner', bottomSubliner);
        // formData.append('propertyList', noOfProperties);

        const payload =
        {
            subscriptionName,
            colorCode,
            numberOfVisit: noOfVisits,
            bannerDays,
            flashDays,
            description,
            price,
            bottomSubliner,
            propertyList: noOfProperties,
            isRecommended,
            subLiner
        }


        // Append profile picture to formData

        setIsLoading(true);
        dispatch(
            addSubscription(payload, (data) => {
                if (data.status === 200) {
                    setIsLoading(false);

                    enqueueSnackbar("Subscription Updated Successfully!", { variant: 'success' })

                }
                else {
                    enqueueSnackbar("Error While updating subscription!", { variant: 'error' })
                }
            }
            )
        )
        navigate('/subscriptions')


    };

    const handleCheckboxChange = () => {
        setIsRecommended(prevChecked => !prevChecked);
    };

    const handleReset = () => {
        setSubscriptionName("")
        setColorCode("")
        setNoOfVisits("")
        setBannerDays("")
        setFlashDays("")
        setDescription("")
        setPrice("")
        setBottomSubliner("")
        setNoOfProperties("")
        setIsRecommended(false)
        setSubLiner("")
    };


    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };

    const handleButtonClick = (id) => {
        navigate(`/visit-details/${id}`)
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    // const loadAllvisits = useCallback(() => {
    //     dispatch(getAllSubscription({ pageNo: currentPage, pageSize: countPerPage }, (data) => {
    //         setSubscription(data.data);
    //     }))

    // }, [dispatch])



    useEffect(() => {
        dispatch(getAllProperty({}, (data) => {
            console.log(data?.data)
            const filteredProperties = data?.data.filter(item => item.name);
            setProperties(filteredProperties);

        }))
    }, [dispatch]);

    // const updateStatus = (id, status) => {
    //     let payload = {
    //         visitId: id, visitStatus: status
    //     }
    //     console.log("Here is Payload", payload)
    //     dispatch(
    //         updateVisitById(payload, () => {
    //             loadAllvisits()
    //             enqueueSnackbar("Status updated!", { variant: 'success' })
    //         },
    //         )
    //     )
    // }

    const handleEditModalOpen = () => {
        setOpenEditModal(true);
    };

    const handleEditModalClose = () => {
        setOpenEditModal(false);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Layout path="normalVisits">
                <div>

                    <Typography variant="h5" sx={{ fontWeight: '600' }}>Add Subscription Plan</Typography>

                    <Paper sx={{ px: 4, py: 3, borderRadius: '20px', mt: 2 }}>
                        <Grid container spacing={2} mt={1}>
                            <Grid item md={6} flexGrow={1}>
                                <InputStyle name="Subscription Name">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter Name"
                                        value={subscriptionName}
                                        onChange={(e) => { setSubscriptionName(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1}>
                                <InputStyle name="Color Code">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter color code"
                                        value={colorCode}
                                        onChange={(e) => { setColorCode(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="Price">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter price"
                                        value={price}
                                        onChange={(e) => { setPrice(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="Number of Visits">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter visits"
                                        value={noOfVisits}
                                        onChange={(e) => { setNoOfVisits(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="Banner Days">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter banner days"
                                        value={bannerDays}
                                        onChange={(e) => { setBannerDays(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="Flash Days">
                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter flash days"
                                        value={flashDays}
                                        onChange={(e) => { setFlashDays(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="Description">

                                    <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter description"
                                        value={description}
                                        onChange={(e) => { setDescription(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="No. of Properties">
                                    {/* <select
                                        placeholder="Enter no. of properties"
                                        value={noOfProperties}
                                        onChange={(e) => { setNoOfProperties(e.target.value) }}
                                        style={{ height: '2.4rem', borderRadius: '5px', fontSize: '10px', fontWeight: '600' }}
                                    >
                                        <option value="" disabled>Enter number of properties</option>

                                        {properties?.map((item, index) => (
                                            <option key={index} value={item?._id}>
                                                {item?.name}
                                            </option>
                                        ))}
                                    </select> */}

                                    <Select
                                        sx={{ height: '2.4rem', borderRadius: '5px', fontSize: '10px', fontWeight: '600', width: '100%' }}
                                        multiple
                                        placeholder="Enter no. of properties"
                                        value={noOfProperties}
                                        onChange={(e) => { setNoOfProperties(e.target.value) }}
                                        // className={classes.select}
                                        renderValue={(selected) => selected.join(', ')} // Display selected options as a comma-separated list
                                    >
                                        <MenuItem disabled value="">
                                            <em>Select properties</em>
                                        </MenuItem>
                                        {properties.map((item, index) => (
                                            <MenuItem key={index} value={item?._id}>
                                                {item?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <input
                                        type="text"
                                        name="address"
                                        placeholder="Enter no. of properties"
                                        value={noOfProperties}
                                        onChange={(e) => { setNoOfProperties(e.target.value) }}
                                    /> */}
                                </InputStyle>
                            </Grid>

                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name="Bottom Subliner">
                                    <input
                                        type="text"
                                        name="bottom Subliner"
                                        placeholder="Enter bottom subliner"
                                        value={bottomSubliner}
                                        onChange={(e) => { setBottomSubliner(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>
                            <Grid item md={6} flexGrow={1} mt={1}>
                                <InputStyle name=" Subliner">
                                    <input
                                        type="text"
                                        name="subLiner"
                                        placeholder="Enter  subliner"
                                        value={subLiner}
                                        onChange={(e) => { setSubLiner(e.target.value) }}
                                    />
                                </InputStyle>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mt: 10 }}>
                            <Checkbox
                                sx={{ ml: -1.5 }}
                                checked={isRecommended}
                                onChange={handleCheckboxChange}
                            />
                            <Typography>Recommended</Typography>
                        </Box>


                        <Box >
                            <Button variant="contained" disableElevation sx={{ px: 5 }} onClick={handleSubmit} >
                                {isLoading === true ? <CircularProgress /> : "Next"}
                            </Button>
                            <Button variant="outlined" disableElevation sx={{ px: 5, ml: 2 }} onClick={handleReset}>
                                Reset
                            </Button>
                        </Box>
                    </Paper>


                </div>
            </Layout>

            {
                open === true ?
                    <ActivateSubscription open={open} setOpen={setOpen} />
                    :
                    ""
            }

            {
                openEditModal === true ?
                    <EditSubscription open={openEditModal} setOpen={setOpenEditModal} />
                    :
                    ""
            }


            {/* <VisitRemarks
                remark={remark}
                setRemark={setRemark}
                claimId={claimId}
                claimStatus={status}
                addRemarkAction={addRemarkAction}
                setAddRemarkAction={setAddRemarkAction}
            /> */}
        </>
    );
}

export default AddSubscription;