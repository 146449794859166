import React from 'react';
import { TableStyle1 } from '../Style/stylesheet';
import { Box } from '@mui/material';

export default function TableStyle({ children }) {
  return (
    <Box
      component={'table'}
      sx={{
        width: '100%',
        textAlign: 'center',
        borderRadius: 2,
        overflow: 'hidden',
        outline: '1px solid #E9E9E9',
        '& td,': {
          color: '#7D7F88',
          fontSize: '0.8rem',
          border: '1px solid #E9E9E9',
          borderCollapse: 'collapse',
          py: 0.5,
          verticalAlign: 'center',
        },
        '& th': {
          color: '#000',
          border: '1px solid #E9E9E9',
          borderCollapse: 'collapse',
          py: 1,
        },
        '& .icon': {
          height: 32,
          width: 'auto',
          mt: '2px',
          mb: '-2px',
        },
      }}
    >
      {children}
    </Box>
  );
}
