import { Box, Button, CircularProgress, Paper, Stack, TextareaAutosize, Typography } from "@mui/material";
import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllRequirement, getAllVisit, getClientByID } from "../../actions";
import common from '../../utils/common';

const View = () => {
  const [client, setClient] = useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState("");
  const [requirments, setRequirments] = useState([]);
  const [visits, setVisits] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientByID({ id }, (data) => {
      setClient(data.data);
    }))

    dispatch(getAllRequirement({ customerId: id }, (data) => {
      setRequirments(data.data);
    }))
  }, [id]);

  useEffect(() => {
    if (selectedRequirement) {
      setLoading(true);
      dispatch(getAllVisit({ requirementId: selectedRequirement }, (data) => {
        setVisits(data.data);
        setLoading(false);
      }, (err) => {
        setLoading(false);
      }))
    }
  }, [selectedRequirement]);

  const handleRemarkSubmit = () => {

  }
  return (
    <>
      <Layout path={"refer"}>
        <Box>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={3}
            sx={{ mt: 4, mb: 2 }}
            justifyContent={"space-between"}
          >
            <Breadcrumbs primary="Requirement Details" />
          </Stack>
        </Box>
        <Box display={"grid"} sx={{ gap: "30px" }}>
          <Paper
            sx={{
              p: 2.5,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "10px",
            }}
          >
            <Box>
              <p style={{ fontSize: "2rem" }}>{client[0]?.clientName}</p>
              <p style={{ color: "#8B9199" }}>Client ID: {client[0]?._id}</p>
            </Box>
            <Box display={"flex"} alignItems={"center"} fontWeight={500}>
              <LocalPhoneIcon
                color="white"
                sx={{
                  mr: 1,
                  bgcolor: "#FC5C67",
                  borderRadius: "100%",
                  p: "5px",
                  height: "30px",
                  width: "30px",
                }}
              />
              <p>{client[0]?.phoneNumber}</p>
            </Box>
          </Paper>

          <Paper
            sx={{
              p: 2.5,
              display: "grid",
              gridTemplateColumns: "1fr",
              borderRadius: "10px",
              maxHeight: "600px",
              gap: "15px",
              overflowY: "auto",
            }}
          >
            {requirments?.length > 0
              ?
              requirments.map((item, index) => {
                return (
                  <Paper
                    sx={{
                      height: "fit-content",
                      borderRadius: "10px",
                      p: 2,
                      cursor: 'pointer',
                      background: selectedRequirement == item?._id ? '#ddd' : '#fff'
                    }}
                    key={index}
                    onClick={() => setSelectedRequirement(item?._id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: "1.3rem", fontWeight: 600 }}>
                        Requirement ID : {item?._id}
                      </p>
                      {/* <Link
                        style={{
                          color: "#278FD9",
                          textDecoration: "underline",
                          fontWeight: 400,
                        }}
                      >
                        Related Search
                      </Link> */}
                    </Box>
                    <Box display="flex" mt={2} sx={{ gap: "20px" }}>
                      {item?.unitType?.length > 0
                        ?
                        <Box
                          px={2}
                          py={1}
                          sx={{ border: "1px solid #DEDEDE", borderRadius: "12px" }}
                        >
                          {item?.unitType?.join(", ")}
                        </Box>
                        :
                        ""
                      }
                      <Box
                        px={2}
                        py={1}
                        sx={{ border: "1px solid #DEDEDE", borderRadius: "12px" }}
                      >
                        {item?.minPrice ? common?.formatAmount(parseFloat(item?.minPrice)?.toFixed(2)) : "0"} - {item?.maxPrice ? common?.formatAmount(parseFloat(item?.maxPrice)?.toFixed(2)) : "0"}
                      </Box>
                      {item?.preferredLocation?.length > 0
                        ?
                        <Box
                          px={2}
                          py={1}
                          sx={{ border: "1px solid #DEDEDE", borderRadius: "12px" }}
                        >
                          {item?.preferredLocation?.join(", ")}
                        </Box>
                        :
                        ""
                      }
                    </Box>
                  </Paper>
                );
              })
              :
              <Typography align="center">No Requirements</Typography>
            }
          </Paper>
        </Box>
        <Box>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={3}
            sx={{ mt: 4, mb: 2 }}
            justifyContent={"space-between"}
          >
            <Breadcrumbs primary="Visit Details" />
          </Stack>
          {selectedRequirement != ""
            ?
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                gap: "20px",
              }}
            >
              {
                loading
                  ?
                  <Typography align="center">
                    <CircularProgress size={24} />
                  </Typography>
                  : visits?.length > 0
                    ?
                    visits.map((item, index) => {
                      return (
                        <Paper
                          key={index}
                          sx={{
                            p: 2.5,
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            borderRadius: "10px",
                            maxHeight: "600px",
                            gap: "15px",
                            overflowY: "auto",
                          }}
                        >
                          <Link
                            style={{
                              fontSize: "1.1rem",
                              color: "#278FD9",
                              fontWeight: 400,
                              cursor: 'pointer'
                            }}
                            to={`/broker-management/clients/visit-summary/${item?._id}`}
                          >
                            Visit ID:{item?._id}
                          </Link>
                          <Box
                            display={"flex"}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <p style={{ color: "#8B9199" }}>Property Name</p>
                            <p>{item?.propertyName} | {new Date(item?.createdAt)?.toDateString()}</p>
                          </Box>
                          <Box
                            display={"flex"}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <p style={{ color: "#8B9199" }}>Location</p>
                            <p>{item?.propertyId?.location ? item?.propertyId?.location : "--"}</p>
                          </Box>
                          <Box
                            display={"flex"}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <p style={{ color: "#8B9199" }}>Price</p>
                            <p>{item?.propertyId?.minPrice ? common?.formatAmount(item?.propertyId?.minPrice) : "--"} - {item?.propertyId?.maxPrice ? common?.formatAmount(item?.propertyId?.maxPrice) : "--"}</p>
                          </Box>
                          <Box
                            display={"flex"}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <p style={{ color: "#8B9199" }}>Unit Type</p>
                            <p>
                              {item?.propertyId?.floorPlanAndPricing?.length > 0 ?
                                Array.from(new Set(item.propertyId.floorPlanAndPricing.map(f => f.unitType[0]))).join(", ") :
                                "--"
                              }
                            </p>
                          </Box>
                          <Box
                            display={"flex"}
                            sx={{ justifyContent: "space-between" }}
                          >
                            <p style={{ color: "#8B9199" }}>Follow Up date</p>
                            <p>{item?.followUpDate ? <>{new Date(item?.followUpDate)?.toLocaleDateString("en-GB", { day: '2-digit', month: '2-digit', year: 'numeric' })} {item?.chooseSlot ? item?.chooseSlot : ""}</> : "--"}</p>
                          </Box>
                        </Paper>
                      );
                    })
                    :
                    "No visits for selected requirement"}
            </Box>
            :
            <Typography align="center">Select requirement to see visits</Typography>}
        </Box>
        {/* <Box>
          <Stack
            direction={{ md: "row", xs: "column" }}
            spacing={3}
            sx={{ mt: 4, mb: 2 }}
            justifyContent={"space-between"}
          >
            <Breadcrumbs primary="Remarks" />
          </Stack>
          <Box>
            <textarea
              style={{
                maxWidth: "100%",
                width: "100%",
                height: "200px",
                background: "#EDF2F6",
                border: "1px solid #E0E4EC",
                borderRadius: "9px",
                fontFamily: "inherit",
                padding: "10px",
                fontSize: "1rem",
                resize: 'none'
              }}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          onClick={handleRemarkSubmit}
          sx={{ marginTop: "20px", paddingInline: "20px" }}
        >
          Submit
        </Button> */}
      </Layout>
    </>
  );
};

export default View;
