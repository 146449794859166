// eslint-disable-next-line import/no-anonymous-default-export
export default {
    AUTH_REDUCER: 'AUTH_REDUCER',
    CLEAR_AUTH_REDUCER: 'CLEAR_AUTH_REDUCER',
    USER_REDUCER: 'USER_REDUCER',
    CLEAR_USER_REDUCER: 'CLEAR_USER_REDUCER',
    NOTIFICATION_REDUCER: 'NOTIFICATION_REDUCER',
    CLEAR_NOTIFICATION_REDUCER: 'CLEAR_NOTIFICATION_REDUCER',
    BUILDER_HOME_REDUCER: 'BUILDER_HOME_REDUCER',
    BROKER_HOME_REDUCER: 'BROKER_HOME_REDUCER',
    CLEAR_BROKER_HOME_REDUCER: 'CLEAR_BROKER_HOME_REDUCER',
    CLEAR_BUILDER_HOME_REDUCER: 'CLEAR_BUILDER_HOME_REDUCER',
    BUFFER_REDUCER: 'BUFFER_REDUCER',
    CLEAR_BUFFER_REDUCER: 'CLEAR_BUFFER_REDUCER',
    MASTER_REDUCER: 'MASTER_REDUCER',
    FILTER_REDUCER: 'FILTER_REDUCER',
    CLEAR_FILTER_REDUCER: 'CLEAR_FILTER_REDUCER',
}