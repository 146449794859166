import React, { useEffect, useState } from "react";
import Layout from "../assets/Layout";
import { Box, Button, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Breadcrumbs from "../assets/Breadcrumbs";
import SearchFilter from "../assets/SearchFilter";
import { getAllInvoicesForBuilder } from "../actions";
import { useDispatch } from "react-redux";
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import InvoiceModal from "../assets/InvoiceModal";
import { useNavigate } from "react-router-dom";
import { sectionBarBtn } from "../Style/stylesheet";

const AllPaidInvoices = () => {
    const [sectionBarActive, setSectionBarActive] = useState('AllPaidInvoices');
    const [searchQuery, setSearchQuery] = useState("");
    const [invoices, setInvoices] = useState([]);
    const [allInvoices, setAllInvoices] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [viewModal, setViewModal] = useState(false);
    const [invoiceId, setInvoiceId] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAllInvoicesForBuilder({status: "settled"}, (data) => {
            var invoices = [];
            data.data.map((item) => {
                item?.invoices.map((item1) => {
                    invoices.push(item1);
                })
            })
            const filter = invoices.filter((item) => {
                if (searchQuery) {
                    if (item?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.visitId?._id.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.brokerId?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.builderId?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?._id?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (new Date(item?.createdAt).toLocaleDateString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.invoiceAmount.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.status.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.subCompany?.represnetativeName.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.subCompany?.email.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    if (item?.claimId?.propertyId?.subCompany?.contactNumber?.toString()?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
                        return true;
                    }

                    return false;
                } else {
                    return true;
                }
            })
            setAllInvoices(filter);
            setInvoices(filter);
        }))
    }, [searchQuery]);

    const headData = [
        'Inovice ID',
        'Visit ID',
        'Broker ID',
        'Builder ID',
        'Property Name',
        'Claim ID',
        'Invoice Date',
        'Invoice amount',
        'Invoice Status',
        'View Invoice',
        'TRANSACTION NO.',
        'TRANSACTION DATE',
        'TRANSACTION AMOUNT.',
    ];

    const handleButtonClick = (id) => {
        setViewModal(true)
        setInvoiceId(id);
        console.log("fhgvjhx")
    };

    return (
        <>
            <Layout path="invoices">
                <Box>
                    <Stack
                        direction={'row'}
                        sx={{ my: 4 }}
                        justifyContent={'space-between'}
                    >
                        <Breadcrumbs primary="Invoices" title="All" />
                    </Stack>
                    <Stack
                        direction={'row'}
                        sx={{ p: 1, bgcolor: '#fff', my: 5, borderRadius: 10, justifyContent: 'center', mx: 20 }}
                    >
                        <Button
                            sx={sectionBarBtn}
                            className={sectionBarActive === 'AllPendingInvoices' && 'active'}
                            onClick={() => navigate('/invoices/all/')}
                        >
                            Pending Invoice
                        </Button>
                        <Button
                            sx={sectionBarBtn}
                            className={sectionBarActive === 'AllProcessedInvoices' && 'active'}
                            onClick={() => navigate('/invoices/all/processed')}
                        >
                            Processing Invoice
                        </Button>
                        <Button
                            sx={sectionBarBtn}
                            className={sectionBarActive === 'AllPaidInvoices' && 'active'}
                            onClick={() => navigate('/invoices/all/paid')}
                        >
                            Paid Invoice
                        </Button>
                    </Stack>
                    <Box sx={{ mb: 4 }}>
                        <SearchFilter>
                            <Box className="search style-1">
                                <input type="text" placeholder="Search..." value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)} />
                            </Box>
                        </SearchFilter>
                    </Box>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {headData.map((item, index) => (
                                            <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                                <Typography
                                                    sx={{
                                                        color: 'mute.darktext',
                                                        textTransform: 'uppercase',
                                                        fontSize: '0.9rem',
                                                    }}
                                                >
                                                    {item}
                                                </Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {invoices?.map((item, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                        >
                                            <TableCell>{item?._id}</TableCell>
                                            <TableCell>{item?.claimId?.visitId ? item?.claimId?.visitId?._id : '--'}</TableCell>{' '}
                                            <TableCell>{item?.brokerId?._id}</TableCell>
                                            <TableCell sx={{ color: '#D12953' }}>{item?.builderId?._id}</TableCell>
                                            <TableCell>{item?.claimId?.propertyId?.name ? item?.claimId?.propertyId?.name : '--'}</TableCell>
                                            <TableCell>{item?.claimId?._id}</TableCell>
                                            <TableCell>{new Date(item?.createdAt)?.toLocaleDateString()}</TableCell>
                                            <TableCell>{item?.invoiceAmount}</TableCell>
                                            <TableCell>
                                                {item?.status}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    sx={{ color: '#278FD9' }}
                                                    onClick={() => handleButtonClick(item._id)}
                                                >
                                                    VIEW
                                                </Button>
                                            </TableCell>
                                            <TableCell>{item?.transactionId ? item?.transactionId : '--'}</TableCell>
                                            <TableCell>{item?.transactionDate ? item?.transactionDate?.split("-").reverse().join("/") : '--'}</TableCell>
                                            <TableCell>{item?.transactionAmount ? item?.transactionAmount : '--'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Box>
                <InvoiceModal
                    isOpen={viewModal}
                    setIsOpen={setViewModal}
                    invoice={invoiceId} />
            </Layout>
        </>
    );
}

export default AllPaidInvoices;