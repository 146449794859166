import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Logo from '../assets/img/logo.png'
import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Sign from '../assets/img/sign.png'
import { useDispatch } from 'react-redux';
import { getInvoicePDFByID } from '../actions';
import html2pdf from 'html2pdf.js';
import jsPDF from 'jspdf';
import { baseURL } from '../utils/common';

export default function InvoiceModal({ isOpen, setIsOpen, invoice }) {
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        height: '100%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        overflow: 'auto', // Added CSS property
    };

    const dispatch = useDispatch();

    const downloadInvoiceAsPDF = () =>{
        dispatch(getInvoicePDFByID(invoice, (data) =>{
            const invoiceHtml = data; // Assuming data contains the HTML content
        
        const options = {
            filename: 'invoice.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2,useCORS: true },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        html2pdf().from(invoiceHtml).set(options).save();
        }, (err) => {
            console.log(err);
        }))
    }
    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <iframe id="invoiceIframe" style={{width:'100vh', height:'100vh'}} src={`${baseURL}v1/claim/getClaimInvoicePdfView?id=${invoice}`}/>
                <Button variant='contained' sx={{m:1, p:1, float:'right', mr:3}} onClick={downloadInvoiceAsPDF}>Download</Button>
                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <img src={Logo} alt='logo' height="20px" />
                        <Typography sx={{ fontSize: '16px' }} >
                            <b>Invoice No. BOM-5567</b>
                        </Typography>
                    </Box>
                    <Divider sx={{ background: '#278FD9', my: 1, height: '2px' }} />

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', fontSize: '8px' }}>
                        <Box>
                            <Typography sx={{ fontSize: '12px' }} ><b>Billing Address:</b></Typography>
                            <Typography sx={{ fontSize: '12px' }} >Builder Name</Typography>
                            <Typography sx={{ fontSize: '12px' }} >Property Name</Typography>
                            <Typography sx={{ fontSize: '12px' }} >Property Address</Typography>
                            <Typography sx={{ fontSize: '12px' }} mt={1} ><b>Claim No:</b></Typography>
                            <Typography sx={{ fontSize: '12px' }}><b>Claim Date:</b></Typography>
                        </Box>

                        <Box sx={{ textAlign: 'right' }}>
                            <Typography sx={{ fontSize: '12px' }} ><b>Shipping Address:</b></Typography>
                            <Typography sx={{ fontSize: '12px' }} >Builder Name</Typography>
                            <Typography sx={{ fontSize: '12px' }} >Property Name</Typography>
                            <Typography sx={{ fontSize: '12px' }} >Property Address</Typography>

                            <Typography sx={{ fontSize: '12px' }} mt={1} ><b>Invoice Detail:</b></Typography>
                            <Typography sx={{ fontSize: '12px' }} ><b>Invoice Date:</b></Typography>
                        </Box>
                    </Box>

                    <Box my={2}>
                        <Table>
                            <TableHead>
                                <TableRow style={{ backgroundColor: '#278FD9', color: '#fff', p: 0 }}>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Description</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Unit Type</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Unit Price</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Tax Rate</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Tax Type</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Tax Amount</TableCell>
                                    <TableCell sx={{ color: '#fff', fontSize: '12px' }}>Total Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography sx={{ fontSize: '12px' }}>Property Name</Typography>
                                        <Typography sx={{ fontSize: '12px' }}>Property Address</Typography>
                                    </TableCell>
                                    <TableCell><Typography sx={{ fontSize: '12px' }}>2 bhk</Typography></TableCell>
                                    <TableCell><Typography sx={{ fontSize: '12px' }}>30 Lakh</Typography></TableCell>
                                    <TableCell><Typography sx={{ fontSize: '12px' }}>9%</Typography></TableCell>
                                    <TableCell><Typography sx={{ fontSize: '12px' }}>IGST</Typography></TableCell>
                                    <TableCell><Typography sx={{ fontSize: '12px' }}>Rs. 27k</Typography></TableCell>
                                    <TableCell><Typography sx={{ fontSize: '12px' }}>Rs. 3456756</Typography></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={5}><b>Total</b></TableCell>
                                    <TableCell><b>RS. 27k</b></TableCell>
                                    <TableCell><b>Rs. 345678</b></TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={7}><b>Amount in words: </b>
                                        <br />
                                        <b>Thirty Lakh Twenty Seven Thousand only</b>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Box sx={{textAlign:'right', mt:2}}>
                            <Typography sx={{ fontSize: '12px', fontWeight:'bold' }}>For HSR Retail:</Typography>
                            <img src={Sign} alt='signature' height='50px' />
                            <Typography sx={{ fontSize: '12px', fontWeight:'bold' }}>Authorised signature</Typography>
                        </Box>

                        <Box sx={{ mt:2}}>
                            <Typography sx={{ fontSize: '12px', fontWeight:'bold' }}>Whether tax is payable under reverse charge - No</Typography>
                            <Divider sx={{ background: '#278FD9', my: 1, height: '2px' }} />
                        </Box>
                    </Box> */}
                </Box>
            </Modal>
        </div>
    );
}