import React, { useEffect, useState } from "react";
import Layout from "../../assets/Layout";
import {
  Checkbox,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  CircularProgress
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import Breadcrumbs from "../../assets/Breadcrumbs";
import MainButton from "../../assets/MainButton";
import { Link } from "react-router-dom";
import { Add, ArrowBackIosNewRounded, ArrowForwardIosRounded, Delete } from "@mui/icons-material";
import AddEmployee from "./AddEmployee";
import { useDispatch } from "react-redux";
import { deleteEmployeeById, getAllEmployees, updateEmployee } from "../../actions";
import edit from '../../assets/img/edit.svg'
import UpdateEmployee from "./UpdateEmployee";
import CustomDialog from "../CustomDialog";
import { enqueueSnackbar } from "notistack";

const RolesEmployees = () => {
  const [addModal, setAddModal] = useState(false);
  const [allEmployees, setAllEmployees] = useState([]);
  const [countPerPage, setCountPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllEmployees({}, (data) => {
      setAllEmployees(data.data.data);
    }, (err) => {
      console.log(err);
    }))
  }, []);

  const handleStatusUpdate = (id, status) => {
    dispatch(updateEmployee({ id, isDeleted: !status }, (data) => {
      enqueueSnackbar("Employee Status Updated Successfuly", { variant: 'success' });
      dispatch(getAllEmployees({}, (data) => {
        setAllEmployees(data.data.data);
      }, (err) => {
        console.log(err);
      }))
    }, (err) => {
      enqueueSnackbar("Error Occured! while updating employee status", { variant: 'error' });
    }));
  }

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteEmployeeById(selectedEmployee, (data) => {
      enqueueSnackbar("Employee Deleted Successfuly", { variant: 'success' });
      setSelectedEmployee("");
      setLoading(false);
      setTimeout(() => window.location.reload(), 200);
    }, (err) => {
      console.log(err);
      enqueueSnackbar("Error Occured! while deleting employee", { variant: 'error' });
      setLoading(false);
    }))
  }
  return (
    <Layout path={"roles"}>
      <Box>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary="Roles & Employees - Employees" />
          <Box sx={{ display: 'flex' }}>
            <Link onClick={() => setAddModal(true)} style={{ marginLeft: '10px' }}>
              <MainButton>
                <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Employee
              </MainButton>
            </Link>
          </Box>
        </Stack>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="center"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allEmployees.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell style={{ minWidth: "200px" }}
                      align="center">{item._id}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}
                      align="center">{item.employeeName}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}
                      align="center">{item.email}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}
                      align="center">{item.role.name}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}
                      align="center">{new Date(item?.createdAt).toLocaleDateString()}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}
                      align="center"><Switch
                        checked={!item?.isDeleted}
                        onChange={(e) => handleStatusUpdate(item._id, e.target.checked)}
                      // updateDeleteStatus(item?._id, e.target.checked); }}
                      /></TableCell>
                    <TableCell sx={{ textAlign: 'center' }}><img src={edit} style={{ cursor: 'pointer' }} onClick={() => { setSelectedEmployee(item); setUpdateModal(true); }} />  <Delete sx={{ cursor: 'pointer', color: '#F10000' }} onClick={() => { setSelectedEmployee(item._id); setDeleteConfirmation(true); }} /></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
              {allEmployees?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
              {countPerPage > allEmployees?.length
                ? allEmployees?.length
                : currentPage * countPerPage > allEmployees?.length
                  ? allEmployees?.length
                  : currentPage * countPerPage}{' '}
              of {allEmployees?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}>
              <option value="10" >
                10
              </option>
              <option value="20">
                20
              </option>
              <option value="30">
                30
              </option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1 || countPerPage > allEmployees?.length}
                    sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allEmployees?.length ? "#EFEFEF" : "#FFF" }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allEmployees?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={countPerPage > allEmployees?.length || currentPage == Math.ceil(allEmployees?.length / countPerPage)}
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <AddEmployee
        open={addModal}
        setOpen={setAddModal}
        size={"sm"}
      />
      {updateModal && <UpdateEmployee
        open={updateModal}
        setOpen={setUpdateModal}
        size={"sm"}
        selectedEmployee={selectedEmployee}
      />}
      <CustomDialog
        title={""}
        open={deleteConfirmation}
        setOpen={setDeleteConfirmation}
        size={"sm"}
      >
        <Typography sx={{ textAlign: 'center', mb: 2 }}>Are you sure you want to delete the selected Employee</Typography>
        <Button variant="contained" sx={{ float: 'right' }} onClick={handleDelete}>{loading ? <CircularProgress size={24} /> : "Yes"}</Button>
        <Button variant="outlined" sx={{ float: 'right', marginRight: '10px' }} onClick={() => setDeleteConfirmation(false)}>No</Button>
      </CustomDialog>
    </Layout>
  )
}
const headData = ["User ID", "Employee name", "Email", "Role", "Creation Date", "Status", "Action"];

export default RolesEmployees;