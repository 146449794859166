import React, { useEffect } from "react";
import Layout from "../../assets/Layout";
import { Box, Paper, Typography } from "@mui/material";
import AddRoleForm from "./AddRoleForm";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../CustomDialog";

function AddRoles(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <CustomDialog
      title="Add Roles"
      open={props.open}
      setOpen={props.setOpen}
      size={props.size}
    >
      <>
        <Box sx={{ padding: "0px 10px" }}>
          <AddRoleForm
            builders={props.builders}
            properties={props.properties}
            setOpen={props.setOpen}
          />
        </Box>
      </>
    </CustomDialog>
  );
}
export default AddRoles;
