import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,

    Divider,

    FormControlLabel,

    FormGroup,

    Grid,

    IconButton,
    Stack,
    TextField,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { updateQueries } from '../actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

export default function WriteReview({ open, setOpen, id }) {

    const [remark, setRemark] = useState("")
    const [uploadedImage, setUploadedImage] = useState(null);
    const [days, setDays] = useState("");
    const [answer, setAnswer] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            setUploadedImage(e.target.result);
        };

        reader.readAsDataURL(file);
    };
    // const handleSubmit = (e) => {
    //     e.preventDefault()
    //     console.log(remark)
    // }
    console.log(id)
    const handleSubmit = () => {

        const payload =
        {
            id,
            answer,
            status:"Responded"
        }

        setIsLoading(true);
        dispatch(
            // updateQueries(payload, (data) => {
            //     if (data.status === 200) {
            //         enqueueSnackbar("Subscription Updated Successfully!", { variant: 'success' })

            //         setIsLoading(false);
            //     }
            //     else {
            //         enqueueSnackbar("Error While updating subscription!", { variant: 'error' })
            //     }
            // }
            // )
            updateQueries(payload, (data) => {
                enqueueSnackbar("Subscription Updated Successfully!", { variant: 'success' });
                setIsLoading(false);
                // setOpen(false)
              
              }, (err) => {
                enqueueSnackbar("Error Occured! please try again", { variant: 'error' });
              })
        )
        // navigate('/subscriptions')


    };


    return (
        <Dialog
            open={open}
            maxWidth="lg"
        >
            <DialogContent >

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="text" component="h3">
                        Write Answer
                    </Typography>
                    <IconButton onClick={() => setOpen(false)}><CloseIcon /></IconButton>
                </Box>

                <TextField
                    multiline
                    rows={8}
                    variant="outlined"
                    placeholder="Enter your text here"
                    sx={{ width: '500px' }}
                    my={2}
                    value={answer}
                    onChange={(e) => { setAnswer(e.target.value) }}
                />

                <Box py={2} >
                    <Button
                        variant="contained"
                        type='submit'
                        onClick={handleSubmit}
                        sx={{ border: '1px solid #278FD9', background: '#fff', color: "#278FD9", boxShadow: 'none', px: 4, mr: 2 }}
                    >
                        Update
                    </Button>

                    <Button
                        variant="contained"
                        type='submit'
                        onClick={handleSubmit}
                        sx={{ boxShadow: 'none', px: 4 }}
                    >
                        Reset
                    </Button>
                </Box>


            </DialogContent >
        </Dialog >
    );
}
