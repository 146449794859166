import { Stack } from '@mui/material';
import React from 'react';

export default function SearchFilter({ children }) {
  return (
    <Stack
      direction={'row'}
      spacing={2}
      sx={{
        '& .style-1': {
          maxWidth: 250,
          flexGrow: 1,
          borderRadius: 2,
          background: '#EFEFEF',
          overflow: 'hidden',
          height: '100%',
        },
        '& .style-1 input': {
          maxWidth: 250,
          width: '100%',
          background: '#EFEFEF',
          border: '0px',
          height: 40,
          px: '0.8rem',
        },
        '& .search': {
          display: 'block',
          position: 'relative',
        },
        '& .search::after': {
          position: 'absolute',
          content: 'url(/icons/search.svg)',
          right: 15,
          fontSize: '1rem',
          top: 11,
        },
        '& .filter select': {
          display: 'block',
          position: 'relative',
          border: 'none',
          background: '#EFEFEF',
          fontSize: '0.9rem',
          color: '#343434',
          height: 30,
          p: 0,
          px: 1,
        },
        '& .p-1': {
          pr: 1,
        },
      }}
      flexDirection={{ md: 'row', xs: 'column' }}
    >
      {children}
    </Stack>
  );
}
