import React, { useState } from 'react';

import Layout from '../../assets/Layout';
import {
    Add,
    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
} from '@mui/icons-material';

import MainButton from '../../assets/MainButton';
import { sectionBarBtn } from '../../Style/stylesheet';

import {
    Checkbox,
    IconButton,

    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    Button,
    Grid,
    Rating,
} from '@mui/material';

import { Link, useNavigate, useParams } from 'react-router-dom';
import ButtonController from '../../assets/ButtonController';
import SearchFilter from '../../assets/SearchFilter';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Queries from '../Queries/Queries';
import ReviewAccordion from '../../assets/ReviewAccordion';
import ReviewResponseAccordion from '../../assets/ReviewResponseAccordion'

export default function OtherInformationQueries() {
    const builderId = useParams();
    const navigate = useNavigate();
    const [sectionBarActive, setSectionBarActive] = useState('Queries')
    const [sectionBarActiveQueries, setSectionBarActiveQueries] = useState('New')
    const [addDSAAction, setAddDSAAction] = useState(false);
    const [profileAction, setProfileAction] = useState(false);

    const headData = [
        'PROPERTY NAME',
        'PLAN NAME',
        'VISITS LEFT',
        'BANNNER DAYS',
        'FLASH SCREEN',
        'TRANSACTION ID',
        'PAYMENT STATUS',
    ];
    return (
        <Layout path="builder-management">
            <Box>
                <Grid container my={2} >
                    {/* <Grid item md={1}>
                        <IconButton aria-label="left-arrow">
                            <ChevronLeftIcon />
                        </IconButton>
                    </Grid> */}
                    <Grid item md={12}>
                        <Stack
                            direction={"row"}
                            sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
                            spacing={3}
                        >
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Profile" && "active"}
                                onClick={() => navigate("/builder-management/profile/" + builderId?.id)}
                            >
                                Profile
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Properties" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/properties/" + builderId?.id)
                                }
                            >
                                Properties
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Invoices" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/invoices/" + builderId?.id)
                                }
                            >
                                Invoices
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Subscription" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/subscription/" + builderId?.id)
                                }
                            >
                                Subscription
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "Queries" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/queries/" + builderId?.id)
                                }
                            >
                                Queries
                            </Button>
                            <Button
                                sx={sectionBarBtn}
                                className={sectionBarActive === "PropertyEditRequest" && "active"}
                                onClick={() =>
                                    navigate("/builder-management/property-edit-request/" + builderId?.id)
                                }
                            >
                                Property Edit Request
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>

                <Stack
                    direction={'row'}
                    sx={{ p: 1, bgcolor: '#fff', my: 2, borderRadius: 10, justifyContent: 'center', mx: 20 }}
                >
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActiveQueries === 'New' && 'active'}
                        onClick={() => setSectionBarActiveQueries("New")}
                    >
                        New
                    </Button>
                    <Button
                        sx={sectionBarBtn}
                        className={sectionBarActiveQueries === 'Respond' && 'active'}
                        onClick={() => setSectionBarActiveQueries("Respond")}
                    >
                        Respond
                    </Button>
                </Stack>

                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." />
                    </Box>
                </SearchFilter>
                <Box sx={{ my: 4 }}>
                    {sectionBarActiveQueries === "new" ? <ReviewAccordion /> : <ReviewResponseAccordion />}
                </Box>
            </Box>

        </Layout>
    );
}
