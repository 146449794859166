import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputStyle from "../../assets/InputStyle";
import { useDispatch } from "react-redux";
import {
  addReferPayment,
  getBrokerDetails,
  updateClaimByAdmin,
} from "../../actions";
import { enqueueSnackbar } from "notistack";

export default function TransactionDetailsForm({
  claimId,
  claimStatus,
  transactionDetailsAction,
  setTransactionDetailsAction,
  broker,
  amount,
}) {
  const dispatch = useDispatch();

  const [transactionID, setTransactionID] = useState("");
  const [brokerDetails, setBrokerDetails] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [transactionAmount, setTransactionAmount] = useState("");

  useEffect(() => {
    dispatch(
      getBrokerDetails(
        { id: broker },
        (data) => {
          setBrokerDetails(data[0]);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [broker]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      id: claimId,
      claimStatus,
      paymentId: transactionID,
      paymentDate: transactionDate,
      transactionAmount: transactionAmount,
    };
    dispatch(
      updateClaimByAdmin(
        payload,
        (data) => {
          console.log(data);
          if (data?.status == 200) {
            if (brokerDetails?.referedBy) {
              const payload2 = {
                BrokerId: broker,
                amount: amount / 100,
                date: new Date().toLocaleDateString(),
                status: "pending",
                remark: "Refer Payment Created",
                refererId: brokerDetails?.referedBy,
                transactionHistory: [
                  {
                    claimId,
                    amount: amount / 100,
                    date: new Date().toLocaleDateString(),
                  },
                ],
              };
              dispatch(
                addReferPayment(
                  payload2,
                  (data) => {
                    enqueueSnackbar(data?.message, { variant: "success" });
                    window.location.reload();
                    closeHandler();
                  },
                  (err) => {
                    const message = err?.response?.data
                      ? err?.response?.data
                      : "Error Occured! can not create refer payment";
                    enqueueSnackbar(message?.toString(), {
                      variant: "error",
                    });
                  }
                )
              );
            }
          }
        },
        (err) => {
          console.log(err);
          enqueueSnackbar("Error Occured! please try again", {
            variant: "error",
          });
        }
      )
    );
  };

  const closeHandler = () => setTransactionDetailsAction(false);
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: "100%",
    marginTop: 4,
  };
  return (
    <Dialog
      open={transactionDetailsAction}
      onClose={closeHandler}
      maxWidth="xs"
      fullWidth
    >
      <DialogContent sx={{ width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Transaction Details
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: "2px solid #DFDFE6" }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </div>
        </Stack>
        <Box component={"from"}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item md={12}>
                <InputStyle name="Transaction ID">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Transaction ID"
                    value={transactionID}
                    onChange={(e) => setTransactionID(e.target.value)}
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <InputStyle name="Transaction Date">
                  <input
                    type="date"
                    name=""
                    id=""
                    placeholder="Enter Transaction Date"
                    value={transactionDate}
                    onChange={(e) => setTransactionDate(e.target.value)}
                  />
                </InputStyle>
              </Grid>
              <Grid item md={12}>
                <InputStyle name="Transaction Amount">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Transaction Amount"
                    value={transactionAmount}
                    onChange={(e) => setTransactionAmount(e.target.value)}
                  />
                </InputStyle>
              </Grid>

              <Grid item md={12}>
                <Button variant="contained" sx={ButtonStyle} type="submit">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
