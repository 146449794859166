import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import BuilderManagement from "./components/BuilderManagement";
import * as React from "react";
import ForgotPassword from "./pages/ForgotPassword";
import AddBuilder from "./pages/AddBuilder";
import Roles from "./components/Roles/Roles";
import AddProperty from "./pages/AddProperty";
import PropertyManagement from "./components/PropertyManagement";
import AddRoles from "./components/Roles/AddRoles";
import UpdateProperty from "./components/PropertyManagement/UpdateProperty";
import "./index.css";
import PastProperties from "./pages/PastProperties";
import Profile from "./components/BuilderManagement/Profile";
import UpdateRole from "./components/Roles/UpdateRole";
import BrokerManagement from "./components/BrokerManagement";
import Claims from "./components/Claims/Claims";
import PropertyClaim from "./components/Claims/PropertyClaim";
import SubCompany from "./components/PropertyManagement/SubCompany";
import LocationAdvantages from "./components/PropertyManagement/LocationAdvantages";
import PropertyType from "./components/PropertyManagement/PropertyType";
import Amenity from "./components/PropertyManagement/Amenity";
import LoanApproved from "./components/PropertyManagement/LoanApproved";
import NearbyArea from "./components/PropertyManagement/NearbyArea";
import FurnishingDetail from "./components/PropertyManagement/FurnishingDetail";
import Specifications from "./components/PropertyManagement/Specifications";
import FloorPlan from "./pages/FloorPlan";
import Brokerage from "./pages/Brokerage";
import ClaimsApproved from "./components/Claims/ClaimsApproved";
import ClaimsRejected from "./components/Claims/ClaimsRejected";
import PaymentRecieved from "./components/Claims/PaymentRecieved";
import ClaimsPaid from "./components/Claims/ClaimsPaid";
import Properties from "./components/BuilderManagement/Properties";
import NormalVisits from "./components/Visits/NormalVisits";
import BoughtVisits from "./components/Visits/BoughtVisits";
import CompletedVisits from "./components/Visits/CompletedVisits";
import VisitDetails from "./components/Visits/VisitDetails";
import EligibleClaims from "./components/Claims/EligibleClaims";
import UpdateFloorPlan from "./pages/UpdateFloorPlan";
import UpdateBrokerage from "./pages/UpdateBrokerage";
import "./App.css";
import Blogs from "./components/Blogs";
import Training from "./components/Training";
import PendingVisits from "./components/Visits/PromotedPendingVisits";
import PromotedPendingVisits from "./components/Visits/PromotedPendingVisits";
import PendingBoughtVisits from "./components/Visits/PendingBoughtVisits";
import PromotedCompletedVisits from "./components/Visits/PromotedCompletedVisits";
import DSA from "./components/DSA";
import ManageQandA from "./components/ManageQ&A";
import Intents from "./components/BuilderManagement/Intents";
import Refer from "./components/BrokerManagement/Refer";
import ReferPayments from "./components/BrokerManagement/ReferPayments";
import Clients from "./components/BrokerManagement/Clients";
import View from "./components/BrokerManagement/View";
import VisitSummary from "./components/BrokerManagement/VisitSummary";
import SoldProperty from "./components/BrokerManagement/SoldProperty";
import PaymentScreen from "./components/Payment/PaymentScreen";
import VisitsForBokerById from "./components/Visits/VisitsForBokerById";
import ClaimsForBrokerById from "./components/Claims/ClaimsForBrokerById";
import BoughtVisitsForBroker from "./components/Visits/BoughtVisitsForBroker";
import CompletedVisitsForbroker from "./components/Visits/CompletedVisitsForbroker";
import ClaimsApprovedForBroker from "./components/Claims/ClaimsApprovedForBroker";
import ClaimsRejectedForBroker from "./components/Claims/ClaimsRejectedForBroker";
import ClaimsPaidForBroker from "./components/Claims/ClaimsPaidForBroker";
import PrivacyPolicy from "./components/Policies/PrivacyPolicy";
import BrokerPromotion from "./components/BrokerManagement/BrokerPromotion";
import Queries from "./components/Queries/Queries";
import RespondedQueries from "./components/Queries/RespondedQueries";
import Promotion from "./components/Promotion/PromotionBanner";
import PromotionBanner from "./components/Promotion/PromotionBanner";
import PromotionFlash from "./components/Promotion/PromotionFlash";
import PromotionVisits from "./components/Promotion/PromotionVisits";
import PropertyEditRequest from "./components/BuilderManagement/PropertyEditRequest";
import RaisedDisputeQuestion from "./components/BuilderManagement/RaisedDisputeQuestion";
import DeleteConfirmation from "./assets/DeleteConfirmation";
import DsaLoanList from "./components/DSA/DsaLoanList";
import DsaPaymentList from "./components/DSA/DsaPaymentList";
import BuilderAllVisits from "./components/BuilderManagement/assets/BuilderAllVisits";
import OtherInformation from "./components/BuilderManagement/OtherInformation";
import OtherInformationQueries from "./components/BuilderManagement/OtherInformationQueries";
import InvoiceList from "./components/BuilderManagement/InvoiceList";
import WriteReview from "./assets/WriteReview";
import CustomAutoComplete from "./assets/CustomAutoComplete";
import ProcessingInvoices from "./components/BuilderManagement/ProcessingInvoices";
import PaidInvoices from "./components/BuilderManagement/PaidInvoices";
import RolesListing from "./components/Roles/RolesListing";
import RolesListingEmployees from "./components/Roles/RolesListingEmployees";
import Invoices from "./components/Transaction/Received/Invoices";
import PaidClaimsTransaction from "./components/Transaction/Paid/Claims";
import Referal from "./components/Transaction/Paid/Referal";
import { Subscript } from "@mui/icons-material";
import Subscrption from "./components/Transaction/Received/Subscription";
import Subscription from "./components/SubscriptionManagement/Subscription";
import AddSubscription from "./components/SubscriptionManagement/AddSubcription";
import NewSubscription from "./components/SubscriptionManagement/PurchaseSubscription/NewSubscription";
import CurrentSubscription from "./components/SubscriptionManagement/PurchaseSubscription/CurrentSubscription";
import EditSubscription from "./components/SubscriptionManagement/EditSubscription";
import Contact from "./pages/Contact";
import ClaimsReceivedForBroker from "./components/Claims/ClaimsReceivedForBroker";
import AllInvoices from "./pages/AllInvoices";
import AllProcessedInvoices from "./pages/AllProcessedInvoices";
import AllPaidInvoices from "./pages/AllPaidInvoices";
import VisitClaim from "./components/Claims/VisitClaim";
import PaidReferPayments from "./components/BrokerManagement/PaidReferPayments";
import RolesEmployees from "./components/Roles/RolesEmployees";
import BuilderRoles from "./components/Roles/BuilderRoles";
import Dashboard from "./pages/Dashboard";
import { useDispatch } from "react-redux";

const App = () => {
  const [isActive, setIsActive] = React.useState(true);
  const timerRef = React.useRef(null);

  const logout = () => {
    // Perform logout logic here
    localStorage.clear(); // Example: remove auth token
    window.location = '/'; // Redirect to login page
  };

  React.useEffect(() => {
    const handleActivity = () => {
      setIsActive(true);
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setIsActive(false);
        logout();
      }, 1800000); // 30 minutes (1800,000 ms)
    };

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      clearTimeout(timerRef.current);
    };
  }, []);

  const dispatch = useDispatch();
  React.useEffect(() => {
    if(localStorage.getItem("role")){
      // dispatch({type:'LOG_IN', payload: JSON.parse(localStorage.getItem('role'))})
      dispatch({type:'LOG_IN', payload: localStorage.getItem('role')})
    }
  }, []);


  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Authentication Routes */}
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />

          {/* Builder Management Routes */}
          <Route
            path="/builder-management/add-builder"
            element={<AddBuilder />}
          />
          {/* All Invoices */}
          <Route
            path="/invoices/all/"
            element={<AllInvoices />}
          />
          {/* All Invoices Processed */}
          <Route
            path="/invoices/all/processed"
            element={<AllProcessedInvoices />}
          />
          {/* All Invoices Paid */}
          <Route
            path="/invoices/all/paid"
            element={<AllPaidInvoices />}
          />
          <Route path="/builder-management" element={<BuilderManagement />} />
          <Route path="/builder-management/raised-dispute-question" element={<RaisedDisputeQuestion />} />
          <Route path="/builder-management/profile/:id" element={<Profile />} />
          {/* <Route path="/builder-management/properties/:id" element={<Properties />} /> */}
          <Route path="/builder-management/property-edit-request/:id" element={<PropertyEditRequest />} />
          <Route path="/builder-management/all-visits" element={<BuilderAllVisits />} />
          <Route path="/builder-management/subscription/:id" element={<OtherInformation />} />
          <Route path="/builder-management/queries/:id" element={<OtherInformationQueries />} />
          <Route path="/builder-management/invoices/:id" element={<InvoiceList />} />
          <Route path="/builder-management/processing-invoices/:id" element={<ProcessingInvoices />} />
          <Route path="/builder-management/paid-invoices/:id" element={<PaidInvoices />} />
          <Route path="/builder-management/properties/:id" element={<Properties />} />
          <Route path="/past-properties/:id" element={<PastProperties />} />

          {/* Roles Management Routes */}
          <Route path="/roles" element={<Roles />} />
          <Route path="/roles/employees" element={<RolesEmployees />} />
          <Route path="/roles/builder" element={<BuilderRoles />} />
          <Route path="/add-roles" element={<AddRoles />} />
          <Route path="/update-role/:id" element={<UpdateRole />} />
          <Route path="/roles-listing" element={<RolesListing />} />
          <Route path="/roles-listing-employees" element={<RolesListingEmployees />} />

          {/* Property Management Routes */}
          <Route path="/property-management" element={<PropertyManagement />} />
          <Route
            path="/property-management/add-property"
            element={<AddProperty />}
          />
          <Route
            path="/property-management/sub-company"
            element={<SubCompany />}
          />
          <Route
            path="/property-management/location-advantages"
            element={<LocationAdvantages />}
          />
          <Route
            path="/property-management/property-type"
            element={<PropertyType />}
          />
          <Route
            path="/property-management/loan-approved"
            element={<LoanApproved />}
          />
          {/* <Route
            path="/property-management/near-by-area"
            element={<NearbyArea />}
          /> */}
          <Route
            path="/property-management/furnishing-detail"
            element={<FurnishingDetail />}
          />
          <Route
            path="/property-management/specifications"
            element={<Specifications />}
          />
          <Route path="/property-management/amenity" element={<Amenity />} />
          <Route path="/update-property/:id" element={<UpdateProperty />} />
          <Route
            path="/update-property/floor-Plan/:id"
            element={<UpdateFloorPlan />}
          />
          <Route
            path="/update-property/brokerage/:id"
            element={<UpdateBrokerage />}
          />
          <Route
            path="/property-management/floor-Plan"
            element={<FloorPlan />}
          />
          <Route
            path="/property-management/brokerage"
            element={<Brokerage />}
          />

          {/* Broker Management */}
          <Route path="/broker-management" element={<BrokerManagement />} />
          <Route path="/broker-management/refer" element={<Refer />} />
          <Route
            path="/broker-management/refer-payments"
            element={<ReferPayments />}
          />
          <Route
            path="/broker-management/refer-payments/paid"
            element={<PaidReferPayments />}
          />
          <Route path="/broker-management/clients/:id" element={<Clients />} />
          <Route
            path="/broker-management/clients/view/:id"
            element={<View />}
          />
          <Route
            path="/broker-management/clients/visit-summary/:id"
            element={<VisitSummary />}
          />
          <Route
            path="/broker-management/sold-property/:id"
            element={<SoldProperty />}
          />

          <Route
            path="/broker-management/visit/:id"
            element={<VisitsForBokerById />}
          />

          <Route
            path="/broker-management/bought-visit/:id"
            element={<BoughtVisitsForBroker />}
          />

          <Route
            path="/broker-management/completed-visit/:id"
            element={<CompletedVisitsForbroker />}
          />

          <Route
            path="/broker-management/claim/:id"
            element={<ClaimsForBrokerById />}
          />

          <Route
            path="/broker-management/approved-claim/:id"
            element={<ClaimsApprovedForBroker />}
          />

          <Route
            path="/broker-management/rejected-claim/:id"
            element={<ClaimsRejectedForBroker />}
          />

          <Route
            path="/broker-management/received-claim/:id"
            element={<ClaimsReceivedForBroker />}
          />

          <Route
            path="/broker-management/paid-claim/:id"
            element={<ClaimsPaidForBroker />}
          />

          <Route
            path="/broker-promotion"
            element={<BrokerPromotion />}
          />


          {/* DSA Routes */}
          <Route path="/dsa" element={<DSA />} />
          <Route path="/dsa-loan-list" element={<DsaLoanList />} />
          <Route path="/dsa-payment-list" element={<DsaPaymentList />} />

          {/* Claims Management */}
          <Route path="/claims" element={<Claims />} />
          <Route path="/eligible-claims" element={<EligibleClaims />} />
          <Route path="/claims/claims-approved" element={<ClaimsApproved />} />
          <Route path="/claims/claims-rejected" element={<ClaimsRejected />} />
          <Route
            path="/claims/payment-recieved"
            element={<PaymentRecieved />}
          />
          <Route path="/claims/claims-paid" element={<ClaimsPaid />} />
          <Route path="/property-claim/:id" element={<PropertyClaim />} />
          <Route path="/visit-claim/:id" element={<VisitClaim />} />

          {/* Visit Management */}
          <Route path="/all-visits" element={<NormalVisits />} />
          <Route path="/bought-visits" element={<BoughtVisits />} />
          <Route path="/completed-visits" element={<CompletedVisits />} />
          <Route path="/visit-details/:id" element={<VisitDetails />} />
          <Route path="/builder-management/intents" element={<Intents />} />
          <Route
            path="/promoted-pending-visits"
            element={<PromotedPendingVisits />}
          />
          <Route
            path="/promoted-bought-visits"
            element={<PendingBoughtVisits />}
          />
          <Route
            path="/promoted-completed-visits"
            element={<PromotedCompletedVisits />}
          />

          {/*Manage Q & A  */}
          <Route path="/manage-q-&-a" element={<ManageQandA />} />
          {/* Blogs Management */}
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/payment" element={<PaymentScreen />} />

          {/* Training */}
          <Route path="/training" element={<Training />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* Queries */}
          <Route path="/queries/new" element={<Queries />} />
          <Route path="/queries/responded" element={<RespondedQueries />} />

          {/* Promotion */}
          <Route path="/promotion/banner" element={<PromotionBanner />} />
          <Route path="/promotion/flash" element={<PromotionFlash />} />
          <Route path="/promotion/visits" element={<PromotionVisits />} />

          {/* Transation History */}
          <Route path="/transaction-history/recieved-invoices" element={<Invoices />} />
          <Route path="/transaction-history/recieved-subscription" element={<Subscrption />} />
          <Route path="/transaction-history/paid-claims" element={<PaidClaimsTransaction />} />
          <Route path="/transaction-history/paid-referal" element={<Referal />} />

          {/* Subscription Management */}
          <Route path="/subscriptions" element={<Subscription />} />
          <Route path="/edit-subscriptions" element={<EditSubscription />} />
          <Route path="/add-subscriptions" element={<AddSubscription />} />
          <Route path="/new-subscriptions" element={<NewSubscription />} />
          <Route path="/current-subscriptions" element={<CurrentSubscription />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
