export const mbData = [
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: false,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: false,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },

  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },

  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },

  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },

  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
  {
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    loction: 'Lorem Ipsum',
    companyName: 'Lorem Ipsum',
    companyType: 'Lorem Ipsum',
    companyGST: 'Lorem Ipsum',
    companyPlan: 'Lorem Ipsum',
    companyCount: 10,
    companyStatus: true,
  },
];

export const pbData = [
  {
    builder: 'Jane Smith',
    subBuilder: 'Frank',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'planned',
    propertyType: '2BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Gurugram',
    numberOfVisit: '12',
    price: 'Rs. 270',
    brokerage: 'Rs. 400',
    tags: 'Recent',
    status: false,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Alice',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'planned',
    propertyType: '2BHK',
    address: { h_no: 'Unit 5', street: 'Park Avenue' },
    location: 'Mumbai',
    numberOfVisit: '15',
    price: 'Rs. 270',
    brokerage: 'Rs. 600',
    tags: 'Featured',
    status: false,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Frank',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'underConstruction',
    propertyType: '3BHK',
    address: { h_no: 'Unit 5', street: 'Park Avenue' },
    location: 'Gurugram',
    numberOfVisit: '12',
    price: 'Rs. 270',
    brokerage: 'Rs. 500',
    tags: 'Popular',
    status: false,
  },
  {
    builder: 'John Doe',
    subBuilder: 'Frank',
    nameOfProperty: 'Sunset Valley',
    constructionStatus: 'completed',
    propertyType: '2BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Mumbai',
    numberOfVisit: '8',
    price: 'Rs. 300',
    brokerage: 'Rs. 500',
    tags: 'Popular',
    status: true,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Frank',
    nameOfProperty: 'Green Meadows',
    constructionStatus: 'planned',
    propertyType: '3BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Mumbai',
    numberOfVisit: '8',
    price: 'Rs. 270',
    brokerage: 'Rs. 400',
    tags: 'Featured',
    status: true,
  },
  {
    builder: 'John Doe',
    subBuilder: 'Frank',
    nameOfProperty: 'Green Meadows',
    constructionStatus: 'planned',
    propertyType: '4BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Gurugram',
    numberOfVisit: '12',
    price: 'Rs. 300',
    brokerage: 'Rs. 500',
    tags: 'Featured',
    status: false,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Bob',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'planned',
    propertyType: '2BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Mumbai',
    numberOfVisit: '15',
    price: 'Rs. 250',
    brokerage: 'Rs. 400',
    tags: 'Recent',
    status: true,
  },
  {
    builder: 'John Doe',
    subBuilder: 'Frank',
    nameOfProperty: 'Sunset Valley',
    constructionStatus: 'completed',
    propertyType: '4BHK',
    address: { h_no: 'Unit 5', street: 'Park Avenue' },
    location: 'Mumbai',
    numberOfVisit: '12',
    price: 'Rs. 300',
    brokerage: 'Rs. 500',
    tags: 'Popular',
    status: false,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Bob',
    nameOfProperty: 'Sunset Valley',
    constructionStatus: 'planned',
    propertyType: '3BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Gurugram',
    numberOfVisit: '15',
    price: 'Rs. 250',
    brokerage: 'Rs. 600',
    tags: 'Recent',
    status: true,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Bob',
    nameOfProperty: 'Green Meadows',
    constructionStatus: 'planned',
    propertyType: '2BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Gurugram',
    numberOfVisit: '15',
    price: 'Rs. 300',
    brokerage: 'Rs. 400',
    tags: 'Featured',
    status: false,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Bob',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'planned',
    propertyType: '3BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Gurugram',
    numberOfVisit: '12',
    price: 'Rs. 250',
    brokerage: 'Rs. 400',
    tags: 'Featured',
    status: true,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Bob',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'underConstruction',
    propertyType: '4BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Delhi',
    numberOfVisit: '8',
    price: 'Rs. 270',
    brokerage: 'Rs. 500',
    tags: 'Recent',
    status: false,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Frank',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'planned',
    propertyType: '4BHK',
    address: { h_no: 'Unit 5', street: 'Park Avenue' },
    location: 'Delhi',
    numberOfVisit: '12',
    price: 'Rs. 250',
    brokerage: 'Rs. 500',
    tags: 'Recent',
    status: false,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Alice',
    nameOfProperty: 'Green Meadows',
    constructionStatus: 'underConstruction',
    propertyType: '3BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Mumbai',
    numberOfVisit: '12',
    price: 'Rs. 250',
    brokerage: 'Rs. 400',
    tags: 'Recent',
    status: true,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Frank',
    nameOfProperty: 'Sunset Valley',
    constructionStatus: 'completed',
    propertyType: '4BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Gurugram',
    numberOfVisit: '8',
    price: 'Rs. 250',
    brokerage: 'Rs. 500',
    tags: 'Recent',
    status: true,
  },
  {
    builder: 'Ann Culhane',
    subBuilder: 'Alice',
    nameOfProperty: 'Sunset Valley',
    constructionStatus: 'completed',
    propertyType: '2BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Delhi',
    numberOfVisit: '8',
    price: 'Rs. 250',
    brokerage: 'Rs. 400',
    tags: 'Popular',
    status: false,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Bob',
    nameOfProperty: 'Green Meadows',
    constructionStatus: 'completed',
    propertyType: '4BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Delhi',
    numberOfVisit: '15',
    price: 'Rs. 270',
    brokerage: 'Rs. 500',
    tags: 'Featured',
    status: true,
  },
  {
    builder: 'John Doe',
    subBuilder: 'Bob',
    nameOfProperty: 'Green Meadows',
    constructionStatus: 'planned',
    propertyType: '3BHK',
    address: { h_no: 'Unit 5', street: 'Park Avenue' },
    location: 'Gurugram',
    numberOfVisit: '8',
    price: 'Rs. 300',
    brokerage: 'Rs. 600',
    tags: 'Featured',
    status: true,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Bob',
    nameOfProperty: 'Shleelain',
    constructionStatus: 'underConstruction',
    propertyType: '2BHK',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    location: 'Gurugram',
    numberOfVisit: '8',
    price: 'Rs. 270',
    brokerage: 'Rs. 500',
    tags: 'Popular',
    status: true,
  },
  {
    builder: 'Jane Smith',
    subBuilder: 'Frank',
    nameOfProperty: 'Sunset Valley',
    constructionStatus: 'planned',
    propertyType: '2BHK',
    address: { h_no: 'Apt 101', street: 'Main St' },
    location: 'Gurugram',
    numberOfVisit: '8',
    price: 'Rs. 250',
    brokerage: 'Rs. 500',
    tags: 'Featured',
    status: true,
  },
];

export const brokerData = [
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
  {
    _id: '46676732989',
    name: 'Ann Culhane',
    phone: '+91-9384748399',
    eamil: 'willie.jennings@example.com',
    pan_no: 'WD3 12 3321D',
    rr_no: 'WD3 12 3321BF6',
    address: { h_no: 'Sec-29', street: 'Gurugram' },
    visits: 12,
    earnings: '₹ 1000000',
    clients: 12,
    sold_prperties: 10,
    claims: 12,
    status: false,
  },
];
export const claimsData = [
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
  {
    property_id: '23243243243',
    claim_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    claim_date: '12 Jan 2022',
    claim_amount: '₹ 3.94 L',
    builder_name: 'lorem',
    milestone_no: 'M1',
    status: 'Submitted',
    action: 'View',
    remark: 'remark',
  },
];

export const VisitsData = [
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    status: 'Submitted',

  },
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    status: 'Submitted',

  },
  
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    status: 'Submitted',

  },
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    status: 'Submitted',

  },
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    status: 'Submitted',

  },
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    status: 'Submitted',

  },
];

export const BoughtData = [
  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    eligible_claim_id: 12345,
    status: 'Submitted',

  },

  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    eligible_claim_id: 12345,
    status: 'Submitted',

  },

  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    eligible_claim_id: 12345,
    status: 'Submitted',

  },

  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    eligible_claim_id: 12345,
    status: 'Submitted',

  },

  {
    visit_id: '23243243243',
    broker_id: '23243243243',
    property_name: 'Lorem Ipsum',
    client_id: '123331',
    last_visit_date: '05/05/2023',
    next_visit_date: '05/05/2023',
    eligible_claim_id: 12345,
    status: 'Submitted',

  },
 
];


