
import Layout from "../../assets/Layout";
import { VisitsData } from '../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../Style/stylesheet';
import { getAllIntentsForBuilder, updateIntentStatus } from '../../actions/index'
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { React, useCallback, useEffect, useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import IntentsRejection from "./IntentsRejection";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

const Intents = () => {
    const dispatch = useDispatch();
    const [sectionBarActive, setSectionBarActive] = useState('Intents')
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const [intentStatus, setIntentStatus] = useState('')
    const [intentId, setIntentId] = useState('')
    const [allintents, setAllIntents] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    // const [value, setValue] = React.useState < number | null > (2);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [currentPage, setCurrentPage] = useState(1);
    const [intentsPagination, setIntentsPagination] = useState([])

    const headData = [
        'Name',
        'PHONE NUMBER',
        'Email ADDRESS',
        "TYPE OF PROPERTY",
        'LOCATION OF PROPERTY',
        'PROJECT NAME',
        'BRIEF DESCRIPTION',
        'STATUS',


    ];

    const loadAllIntents = useCallback(() => {
        dispatch(getAllIntentsForBuilder({}, (data) => {
            setIntentsPagination(data.data);
            console.log('pag', data.data)
        }))

    }, [dispatch])

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/');
        }
        else {
            loadAllIntents()
        }
    }, [loadAllIntents, navigate])

    const handleChange = (e, item) => {
        setIntentStatus(e)
        setIntentId(item)
        if (e.toLowerCase() === "rejected") {
            setAddRemarkAction(true)
        }
        else {
            dispatch(
                updateIntentStatus({ id: item, status: e.toLowerCase() }, (data) => {
                    if (data?.status == 200) {
                        enqueueSnackbar(data?.data?.message, { variant: 'success' });
                        window.location.reload()
                    }
                    else {
                        enqueueSnackbar(data?.data?.message, { variant: 'error' });
                    }
                })
            )
        }

        // setStatus(event.target.value);
    };

    useEffect(() => {
        let payload = {
            pageNo: 1, pageSize: 10
        }

        dispatch(getAllIntentsForBuilder(payload, (data) => {
            setAllIntents(data?.data)
        }))
    }, [dispatch])

    useEffect(() => {
        dispatch(getAllIntentsForBuilder({ pageNo: 1, pageSize: 10 }, (data) => {

            const filterIntents = data.data.filter((intent) => {

                // by id
                if (intent?._id.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                // by name
                if (intent?.name.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                // by phone number
                if (intent?.phoneNumber.includes(searchQuery)) {
                    return true
                }

                // by email address
                if (intent?.email.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                // by type of property
                if (intent?.typeOfProperty.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                // by location of property
                if (intent?.locationProperty.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                // by project name
                if (intent?.projectName.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                // by brief description
                if (intent?.description.toLowerCase().includes(searchQuery?.toLocaleLowerCase())) {
                    return true
                }

                return false

            })

            setAllIntents(filterIntents)
        }))
    }, [searchQuery]) // currentPage, countPerPage, searchQuery

    return (
        <>
            <Layout path="intents">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Intents" />
                    <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController>
                </Stack>




                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                    <Box className="style-1 filter p-1">
                        <select>
                            <option value="All" selected>
                                All
                            </option>
                            <option value="option 1">option 1</option>
                        </select>
                    </Box>
                    <Box className="style-1">
                        <input type="date" placeholder="Choose Date" />
                    </Box>
                </SearchFilter>

                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} sx={{ minWidth: '220px', width: 'maxContent' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allintents.slice(0, 10).map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell>{item?._id.slice(0, 6)}</TableCell>
                                        <TableCell>{item?.name}</TableCell>
                                        <TableCell>{item?.phoneNumber}</TableCell>
                                        <TableCell>{item?.email}</TableCell>
                                        <TableCell>{item?.typeOfProperty}</TableCell>
                                        <TableCell>{item?.locationProperty}</TableCell>
                                        <TableCell>{item?.projectName}</TableCell>
                                        <TableCell>{item?.description}</TableCell>
                                        <TableCell>
                                            <FormControl sx={{ m: 1, minWidth: 120 }}> <Select value={item?.status?.charAt(0)?.toUpperCase() + item?.status.slice(1)} onChange={(e) => handleChange(e.target.value, item?._id)} displayEmpty inputProps={{ 'aria-label': 'Without label' }} >
                                                <MenuItem value="">
                                                    <em>Select</em>
                                                </MenuItem>
                                                <MenuItem value={'Approved'}>Approved</MenuItem>
                                                <MenuItem value={'Rejected'} >Rejected</MenuItem>
                                                <MenuItem value={'Pending'}>Pending</MenuItem>
                                            </Select> </FormControl>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box
                        sx={{
                            bgcolor: "mute.dark",
                            borderRadius: 1,
                            color: "dark.text",
                            p: 2,
                            mt: 4,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box>
                            <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allintents?.length ? allintents?.length : currentPage * countPerPage} of {intentsPagination?.length}</Typography>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Typography> Rows per page:</Typography>
                            <select style={{ marginTop: "-5px" }}
                                value={countPerPage}
                                onChange={(e) => setCountPerPage(e.target.value)}>
                                <option value="10" >
                                    10
                                </option>
                                <option value="20">
                                    20
                                </option>
                                <option value="30">
                                    30
                                </option>
                            </select>
                            <Box sx={{ ml: 2 }}>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                >
                                    <Box>
                                        <IconButton
                                            onClick={() => setCurrentPage(currentPage - 1)}
                                            disabled={currentPage === 1 || countPerPage > intentsPagination?.length}
                                            sx={{ border: "1px solid", borderColor: "mute.main" }}
                                        >
                                            <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                        </IconButton>
                                    </Box>
                                    <Box>
                                        <Typography variant="text" component={"span"}>
                                            {currentPage}/{Math.ceil(intentsPagination?.length / countPerPage)}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <IconButton
                                            onClick={() => setCurrentPage(currentPage + 1)}
                                            disabled={countPerPage > intentsPagination?.length || currentPage == Math.ceil(intentsPagination?.length / countPerPage)}
                                            sx={{
                                                border: "1px solid",
                                                borderColor: "mute.main",
                                                bgcolor: "white.main",
                                            }}
                                        >
                                            <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>

                    <IntentsRejection
                        intentId={intentId}
                        intentStatus={intentStatus}
                        addRemarkAction={addRemarkAction}
                        setAddRemarkAction={setAddRemarkAction}
                    />
                </Paper>
            </Layout>
        </>
    );
}

export default Intents;