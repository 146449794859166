import React, { useState } from 'react'
import { Box, Accordion, AccordionSummary, AccordionDetails, Button, Grid, Stack, Typography, IconButton } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '../assets/img/edit.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

function ReviewAccordion() {

    const [sectionBarActive, setSectionBarActive] = useState('New')
    const [expanded, setExpanded] = useState(false);

    const handleAccordionChange = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleAccordionChange}
            sx={{
                borderRadius: '16px',
                '& .MuiAccordionSummary-root': {
                    borderRadius: '16px',
                },
                '& .MuiAccordionSummary-expandIcon': {
                    borderRadius: '16px',
                },
                my: 2
            }}>
            <AccordionSummary
                expandIcon={
                    <IconButton
                        aria-label="expand"
                        size="small"
                        edge="end"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleAccordionChange();
                        }}


                        sx={{
                            color: expanded ? '#fff' : '#000',
                            backgroundColor: expanded ? '#278FD9' : '#fff'
                        }}
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
            >
                <Typography>Lorem ipsum dolor sit amet, consectetur elit</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography sx={{ color: '#6F6C90' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus gravida, nulla sed efficitur ullamcorper,
                    lorem ligula ultrices dolor, id convallis erat mi a felis. Nullam tincidunt felis quis libero fermentum, vitae
                    lobortis leo euismod.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', verticalAlign: 'middle', mt: 2 }}>
                    <Typography sx={{ color: '#278FD9' }}>By Juliana Wills <span style={{ color: '#5C5F62' }}> <FiberManualRecordIcon sx={{ fontSize: '8px', ml: 3 }} /> 10min ago</span> </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            alignSelf: 'flex-end',
                            marginTop: 'auto',
                            color: '#000',
                            background: '#fff',
                            border: '1px Solid #d3d3d3',
                            boxShadow: 'none',
                            borderRadius: '20px',
                            fontSize: '10px'
                        }}
                    >
                        <img src={EditIcon} alt='icon' width="16px" />
                        <Typography mx={2} variant='text' sx={{ fontWeight: 'bold' }}>Write Answer</Typography>
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default ReviewAccordion