import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Divider,
  Grid,
  Button,
  Icon,
  Card,
  CardActionArea,
  IconButton,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InputStyle from "../../assets/InputStyle";
import Layout from "../../assets/Layout";
import {
  sectionBarBtn,
  selectBar,
  selectBarBtn,
  uplodeImageBtn,
} from "../../Style/stylesheet";
import { DeleteForever, Done, Download, Draw, Edit } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import TableStyle from "../../assets/TableStyle";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllPastPropertiesForBuilder,
  deleteBuilderPastPropertyById,
  getBuilderDetails,
  UpdateBuilderDetails,
  getAllPropertyCategory,
  addPastPropertyForBuilder,
  updatePastProperties,
  getLocationByInput,
  uploadDocToS3,
} from "../../actions";
import { enqueueSnackbar } from "notistack";
import regex from "../../utils/regex";
import { useRef } from "react";
import JoditEditor from "jodit-react";
import CircularProgress from "@mui/material/CircularProgress";
import MainButton from "../../assets/MainButton";
import UploadData from "../../assets/UploadData";
import UploadDataBox from "../../assets/UploadDataBox";
import UploadDataCard from "../../assets/UploadDataCard";

export default function Profile(id, action) {
  const editor = useRef(null);
  const builderId = useParams();
  const dispatch = useDispatch();
  const [builderDetail, setBuilderDetail] = React.useState({
    _id: "",
    name: "",
    phoneNumber: "",
    locationOfProperty: "",
    companyName: "",
    companyType: "",
    gst: "",
    panOfCompany: "",
    description: "",
    profilePictureUrl: "",
    logoUrl: "",
    documents: [],
  });
  const [phoneError, setPhoneError] = React.useState(false);
  const [gstError, setGstError] = React.useState(false);
  const [panError, setPanError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [selectBarActive, setSelectBarActive] = useState({
    bar2: false,
    status: { bar1: "", bar2: "" },
  });
  const [sectionBarActive, setSectionBarActive] = useState("Profile");
  const [tempIndex, setTempIndex] = useState("");
  const [pastProperties, setPastProperties] = useState([]);
  const [tempName, setTempName] = useState("");
  const [location, setLocation] = useState("");
  const [tempLocation, setTempLocation] = useState("");
  const [tempPrice, setTempPrice] = useState("");
  const [tempUnitType, setTempUnitType] = useState("");
  const [value, setValue] = useState("");
  const [propertyCategory, setPropertyCategories] = useState([]);
  const [locationSearch, setLocationSearch] = React.useState([]);
  const [locationSearch2, setLocationSearch2] = React.useState([]);

  const [locationSelected, setLocationSelected] = React.useState("");
  const [locationSelected2, setLocationSelected2] = React.useState("");

  const [pastProeperty, setPastProperty] = useState({
    propertyName: "",
    builderId: builderId?.id,
    location: "",
    price: "",
    propertyType: "",
    profilePicView: [],
  });
  const [profilePic, setProfilePic] = useState([]);
  const [profilePicView, setProfilePicView] = useState([]);
  const [tempPropertyType, setTempPropertyType] = useState("");
  const [tempPropertyIconPicView, setTempPropertyIconPicView] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [updateImages, setUpdateImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState([]);

  const selectBarHandler = (x) => {
    setSelectBarActive({
      bar2: x,
      status: {
        bar1: selectBarActive.status.bar1,
        bar2: selectBarActive.status.bar2,
      },
    });
  };

  const navigate = useNavigate();

  const onboardingHandler = (event) => {
    event.preventDefault();
    // setSelectBarActive({
    //   bar2: false,
    //   status: {
    //     bar1: 'done',
    //     bar2: '',
    //   },
    // });
  };

  const handleSetFormValues = (name, value) => {
    if (name === "locationOfProperty") {
      getLocationByInput({ searchText: value })
        .then((res) => {
          if (res.data.data.length > 0) {
            var arr = [];
            for (let index = 0; index < res.data.data.length; index++) {
              let element = { label: res.data.data[index].location };
              arr.push(element);
            }
            setLocationSearch(arr);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setBuilderDetail((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  // Regex
  React.useEffect(() => {
    if (builderDetail.phoneNumber) {
      const isPhoneValid = regex.numberPattern.test(builderDetail.phoneNumber);
      setPhoneError(!isPhoneValid);
    }
  }, [builderDetail.phoneNumber]);

  React.useEffect(() => {
    if (builderDetail.email) {
      const isEmailValid = regex.emailPattern.test(builderDetail.email);
      setEmailError(!isEmailValid);
    }
  }, [builderDetail.email]);

  React.useEffect(() => {
    if (builderDetail.panOfCompany) {
      const isPanValid = regex.panPattern.test(builderDetail.panOfCompany);
      setPanError(!isPanValid);
    }
  }, [builderDetail.panOfCompany]);

  React.useEffect(() => {
    if (builderDetail.gst) {
      const isGstValid = regex.gstPattern.test(builderDetail.gst);
      setGstError(!isGstValid);
    }
  }, [builderDetail.gst]);


  const handleUpdatePastProperties = (event) => {
    event.preventDefault();

    setIsLoading(true);

    const formData = new FormData();

    formData.append("propertyName", pastProeperty.propertyName);
    formData.append("builderId", pastProeperty.builderId);
    formData.append("location", locationSelected2);
    formData.append("price", pastProeperty.price);
    formData.append("propertyType", pastProeperty.propertyType);

    if (profilePic.length > 0) {
      for (let i = 0; i < profilePic.length; i++) {
        formData.append("file", profilePic[i]);
      }
    }

    dispatch(
      addPastPropertyForBuilder(formData, (data) => {
        if (data.status === 200) {
          getDetailsOfCurrentBuilder();
          setIsLoading(false);
          enqueueSnackbar(data?.message, { variant: "success" });
          setProfilePic([]);
          setProfilePicView([]);
          setTempName("");
          setLocationSelected2("");
          setTempPrice("");
          setTempPropertyType("");
          setIsLoading(false);
        } else {
          enqueueSnackbar(data?.message, { variant: "error" });
          setIsLoading(false);
        }
      }, (err) => {
        const message = err?.response?.data ? err?.response?.data : "Error Occured! Please try after some time";
        enqueueSnackbar(message, { variant: 'error' });
      })
    );
  };

  const handleAddPastProperties = (event) => {
    event.preventDefault();
    if (!tempName || !tempLocation || !tempPrice || !tempPropertyType) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
  }
    setIsLoading(true);

    const formData = new FormData();

    formData.append("propertyName", tempName);
    formData.append("builderId", pastProeperty.builderId);
    formData.append("location", tempLocation);
    formData.append("price", tempPrice);
    formData.append("propertyType", tempPropertyType);
    formData.append("imageUrl", JSON.stringify(profilePicView));

    dispatch(
      addPastPropertyForBuilder(formData, (data) => {
        getDetailsOfCurrentBuilder();
        setIsLoading(false);
        enqueueSnackbar(data?.message, { variant: "success" });
        setProfilePic([]);
        setProfilePicView([]);
        setTempName("");
        setTempLocation("");
        setLocationSelected2("");
        setTempPrice("");
        setTempPropertyType("");
        setIsLoading(false);
      }, (err) => {
        const message = err?.response?.data ? err?.response?.data : "Error Occured! Please try after some time";
        enqueueSnackbar(message, { variant: 'error' });
        setIsLoading(false);
      })
    );
  };

  const [imageLoading, setImageLoading] = useState(false);
  const handleProfileImageChange = (event) => {

    const file = event.target.files;
    if (file && file?.length > 0) {
      for (let index = 0; index < file.length; index++) {
        setImageLoading(true);
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setProfilePicView(oldArr => [...oldArr, data.data.documents[0]]);
            // setProfilePictureFiles(oldArr => [...oldArr, data.data.documents[0]]);
            setImageLoading(false);
          }, (err) => {
            setImageLoading(false);
          })
        );
      }

    }
  };

  const handleProfileImageRemoval = (index) => {
    setProfilePic(old => {
      old.splice(index, 1)
      return [...old]
    })

    setProfilePicView(old => {
      old.splice(index, 1)
      return [...old]
    })
  }

  const getDetailsOfCurrentBuilder = () => {
    dispatch(
      getBuilderDetails({ id: builderId?.id }, (data) => {
        const builderData = data[0];
        setBuilderDetail({
          _id: builderData._id,
          name: builderData.name,
          phoneNumber: builderData.phoneNumber,
          locationOfProperty: builderData.address,
          companyName: builderData.companyName,
          companyType: builderData.companyType,
          gst: builderData.gst,
          panOfCompany: builderData.panOfCompany,
          description: builderData.description,
          profilePictureUrl: builderData.profilePictureUrl,
          logoUrl: builderData.logoUrl,
          documents: builderData.documents,
        });
        setLocationSelected(builderData.address);
        setValue(builderData?.description);
      })
    );

    dispatch(
      getAllPastPropertiesForBuilder({ builderId: builderId?.id }, (data) => {
        setPastProperties(data?.data);
      })
    );
  };

  React.useEffect(() => {
    getDetailsOfCurrentBuilder();
    loadAllProperties();
  }, [builderId?.id, dispatch]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBuilderDetail((prevBuilderDetail) => ({
      ...prevBuilderDetail,
      [name]: value,
    }));
  };

  const handleInputChangeForPastProperties = (event) => {
    const { name, value } = event.target;
    setPastProperty((lastState) => ({
      ...lastState,
      [name]: value,
    }));
  };

  const loadAllProperties = React.useCallback(() => {
    dispatch(
      getAllPropertyCategory({}, (data) => {
        setPropertyCategories(data.data);
      })
    );
  }, [dispatch]);

  const handleDeletePastProperty = (id) => {
    dispatch(
      deleteBuilderPastPropertyById({ id: id }, (data) => {
        loadAllProperties();
        enqueueSnackbar(data?.message, { variant: "success" });
      })
    );
  };

  const handleRemoveFromPlan = (id) => { };

  const handleEditPackage = (index, forImages = false) => {
    if (!forImages) setTempIndex(index);
    if (JSON.parse(pastProperties[index].imageUrl)?.length > 0) {
      setProfilePic(JSON.parse(pastProperties[index].imageUrl));
      setProfilePicView(JSON.parse(pastProperties[index].imageUrl));
      setTempPropertyIconPicView(pastProperties[index].imageUrl[0]);
    }
    setTempName(pastProperties[index].propertyName);
    setTempLocation(pastProperties[index].location);
    setTempPrice(pastProperties[index].price);
    setTempPropertyType(pastProperties[index].propertyType._id);
    handleSetFormValues("locationOfProperty", pastProperties[index].location);
  };

  const handleUpdatePackage = (e, index) => {
    if (!tempName || !tempLocation || !tempPrice || !tempPropertyType) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    const val = pastProperties;
    let formData = new FormData();
    formData.append("propertyName", tempName);
    formData.append("builderId", builderId?.id);
    formData.append("location", tempLocation);
    formData.append("id", val[index]._id);
    formData.append("propertyType", tempPropertyType);
    formData.append("price", tempPrice);
    formData.append("imageUrl", JSON.stringify(profilePicView));
    // formData.append("file", JSON.stringify(profilePic));

    // if (profilePic.length > 0) {
    //   console.log(profilePic);
    //   for (let i = 0; i < profilePic.length; i++) {
    //     formData.append("file", profilePic[i]);
    //   }
    // }

    val[index] = {
      ...val[index],
      propertyName: tempName,
      location: tempLocation,
      propertyType: propertyCategory.filter((val) => {
        return val._id === tempPropertyType;
      })[0],
      price: tempPrice,
    };
    setPastProperties([...val]);
    dispatch(
      updatePastProperties(formData, (data) => {
        enqueueSnackbar("Data updated successfully", { variant: "success" });
        setProfilePic([]);
        setProfilePicView([]);
        setTempName("");
        setLocationSelected2("");
        setTempPrice("");
        setTempPropertyType("");
        setTempLocation("");
        loadAllProperties();
      })
    );

    setTempIndex("");
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let payload = {
      id: builderId.id,
      companyName: builderDetail.companyName,
      name: builderDetail.name,
      phoneNumber: builderDetail.phoneNumber,
      address: locationSelected,
      companyType: builderDetail?.companyType,
      gst: builderDetail.gst,
      panOfCompany: builderDetail.panOfCompany,
      description: value,
      documents: builderDetail?.documents,
      logoUrl: builderDetail?.logoUrl,
      profilePictureUrl: builderDetail?.profilePictureUrl
    };
    dispatch(
      UpdateBuilderDetails(payload, (data) => {
        if (data.status === 200) {
          enqueueSnackbar(data?.message, { variant: "success" });
          getDetailsOfCurrentBuilder();
          setIsLoading(false);
        } else {
          enqueueSnackbar(data?.message, { variant: "error" });
          setIsLoading(false);
        }
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handlePdfChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const pdfUrl = e.target.result;
      setBuilderDetail({ ...builderDetail, documents: [...builderDetail?.documents, pdfUrl] })
    };

    reader.readAsDataURL(file);
  };

  const handleRemovePropertyBrochure = (data) => {
    const docs = builderDetail?.documents?.filter(x => x != data);
    setBuilderDetail({ ...builderDetail, documents: [] });
  }

  const handleUploadImage = (e) => {
    const file = e.target.files;

    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setBuilderDetail({ ...builderDetail, logoUrl: data.data.documents[0] });
            // setPropertyImages((oldArr) => [...oldArr, data.data.documents[0]]);
            // AddOrUpdateLocalStorage({
            //   images: [...propertyImages, data.data.documents[0]]
            // });
          }, (err) => {
            console.log(err);
          })
        );

      }
    }
  };

  const handleUploadLogo = (e) => {
    const file = e.target.files;

    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setBuilderDetail({ ...builderDetail, profilePictureUrl: data.data.documents[0] });
            // setPropertyImages((oldArr) => [...oldArr, data.data.documents[0]]);
            // AddOrUpdateLocalStorage({
            //   images: [...propertyImages, data.data.documents[0]]
            // });
          }, (err) => {
            console.log(err);
          })
        );

      }
    }
  }

  const handleRemovePropertyLogo = () => {
    setBuilderDetail({ ...builderDetail, logoUrl: "" });
  }

  const handleRemoveProfilePic = () => {
    setBuilderDetail({ ...builderDetail, profilePictureUrl: "" });
  }

  const hideInput = {
    opacity: 0,
    position: 'absolute',
    left: '26.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  return (
    <Layout>
      <Stack
        direction={"row"}
        sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
        spacing={3}
      >
        <Button
          sx={sectionBarBtn}
          className={sectionBarActive === "Profile" && "active"}
        >
          Profile
        </Button>
        <Button
          sx={sectionBarBtn}
          className={sectionBarActive === "Properties" && "active"}
          onClick={() =>
            navigate("/builder-management/properties/" + builderId?.id)
          }
        >
          Properties
        </Button>
        <Button
          sx={sectionBarBtn}
          className={sectionBarActive === "Invoices" && "active"}
          onClick={() =>
            navigate("/builder-management/invoices/" + builderId?.id)
          }
        >
          Invoices
        </Button>
        <Button
          sx={sectionBarBtn}
          className={sectionBarActive === "Subscription" && "active"}
          onClick={() =>
            navigate("/builder-management/subscription/" + builderId?.id)
          }
        >
          Subscription
        </Button>
        <Button
          sx={sectionBarBtn}
          className={sectionBarActive === "Queries" && "active"}
          onClick={() =>
            navigate("/builder-management/queries/" + builderId?.id)
          }
        >
          Queries
        </Button>
        <Button
          sx={sectionBarBtn}
          className={sectionBarActive === "PropertyEditRequest" && "active"}
          onClick={() =>
            navigate("/builder-management/property-edit-request/" + builderId?.id)
          }
        >
          Property Edit Request
        </Button>
      </Stack>
      <Box
        sx={{
          bgcolor: "#fff",
          px: 2,
          py: 2,
          borderRadius: 2,
        }}
      >
        <Box>
          <Stack direction={"row"} sx={selectBar} spacing={3}>
            <Button
              sx={selectBarBtn}
              onClick={() => selectBarHandler(false)}
              className={`${!selectBarActive.bar2 && "active"} ${selectBarActive.status.bar1 && "done"
                }`}
            >
              <Icon className="icon">
                {selectBarActive.status.bar1 ? <>&#10003;</> : "1"}
              </Icon>
              Onboarding
            </Button>
            <Button
              sx={selectBarBtn}
              onClick={() => selectBarHandler(true)}
              className={`${selectBarActive.bar2 && "active"} ${selectBarActive.status.bar2 && "done"
                }`}
            >
              <Icon className="icon">
                {selectBarActive.status.bar2 ? <>&#10003;</> : "2"}
              </Icon>
              Properties
            </Button>
          </Stack>
        </Box>
        {!selectBarActive.bar2 ? (
          <Box component={"form"} onSubmit={onboardingHandler}>
            <Stack>
              <Typography variant="title">{builderDetail?.name}</Typography>
            </Stack>
            <Stack
              direction={{ md: "row", xs: "column" }}
              flexWrap="wrap"
              sx={{ my: 3 }}
              spacing={6}
            >
              <Stack spacing={2}>
                <UploadDataBox>
                  <UploadData name={'Upload Profile Image'}>
                    <UploadDataCard>
                      {builderDetail?.profilePictureUrl ?

                        <>
                          <img
                            src={builderDetail?.profilePictureUrl
                              ? builderDetail?.profilePictureUrl
                              : "/logo (2).png"}
                            style={{ width: '130px', height: '130px' }}
                            alt="upload"
                            className="img-fluid"
                          />
                          <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '27px', top: '0px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemoveProfilePic}>
                            <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                          </IconButton>
                          <a href={builderDetail?.profilePictureUrl}>
                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '0px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                              <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                            </IconButton>
                          </a>
                          {/* <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '90%', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyLogo}>
                            <Edit/>
                            <input
                              type="file"
                              accept="image/*"
                              style={hideInput}
                              onChange={handleUploadImage}
                            />
                          </IconButton> */}
                        </>
                        :
                        <>
                          <img
                            src={'/uploadImg.png'}
                            alt="upload"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            style={hideInput}
                            onChange={handleUploadLogo}
                          />
                        </>
                      }
                    </UploadDataCard>
                  </UploadData>
                </UploadDataBox>
                {/* <Typography variant="text" sx={{ color: "mute.text" }}>
                  Upload Profile Image
                </Typography>
                <Card sx={uplodeImageBtn}>
                  <CardActionArea className="button">
                    <img
                      src={
                        builderDetail?.profilePictureUrl
                          ? builderDetail?.profilePictureUrl
                          : "/logo (2).png"
                      }
                      width='100%'
                      height='100%'
                      alt="profile"
                      className="img-fluid"
                    />
                    <input type="file" name="" id="" />
                    <a href={builderDetail?.profilePictureUrl} download target="_blank" >
                      <IconButton
                        sx={{
                          position: 'absolute',
                          zIndex: 2,
                          right: '0px',
                          top: '5px',
                          background: '#278FD9aa',
                          height: '25px',
                          width: '25px',
                        }}
                      >
                        <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                      </IconButton>
                    </a>
                  </CardActionArea>
                </Card> */}
              </Stack>
              <Stack spacing={2}>
                <UploadDataBox>
                  <UploadData name={'Upload your Business Logo'}>
                    <UploadDataCard>
                      {builderDetail?.logoUrl ?
                        <>
                          <img
                            src={builderDetail?.logoUrl
                              ? builderDetail?.logoUrl
                              : "/logo (2).png"}
                            style={{ width: '130px', height: '130px' }}
                            alt="upload"
                            className="img-fluid"
                          />
                          <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '27px', top: '0px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyLogo}>
                            <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                          </IconButton>
                          <a href={builderDetail?.logoUrl}>
                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '0px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                              <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                            </IconButton>
                          </a>
                        </>
                        :
                        <>
                          <img
                            src={'/uploadImg.png'}
                            alt="upload"
                          />
                          <input
                            type="file"
                            accept="image/*"
                            style={hideInput}
                            onChange={handleUploadImage}
                          />
                        </>
                      }
                    </UploadDataCard>
                  </UploadData>
                </UploadDataBox>
              </Stack>
              <Stack spacing={2}>
                {/* <UploadDataBox>
                  <UploadData name={'Upload Property Brochure'}> */}
                {/* <Box sx={{ display: 'flex' }}>
                      {builderDetail?.documents &&
                        builderDetail?.documents?.map((item, index) => (
                          <>
                            <Box key={index} mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                              <embed src={item} width="200" height="200" content="application/pdf" />
                              <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(item)}>
                                <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                              </IconButton>
                            </Box>
                          </>
                        ))}
                      <UploadDataCard>
                        <img
                          src={'/uploadImg.png'}
                          alt="upload"
                          className="img-fluid"
                        />
                        <input
                          type="file"
                          accept=".doc,.pdf"

                          multiple
                          onChange={handlePdfChange}
                        />
                      </UploadDataCard>
                    </Box> */}
                {/* {
                      builderDetail?.documents.length > 0 ?
                        <>
                          <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                            <embed src={builderDetail?.documents[0]} width="200" height="200" />
                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '30px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyBrochure}>
                              <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                            </IconButton>
                            <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                              <a href={builderDetail?.documents[0]} download>
                                <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                              </a>
                            </IconButton>
                          </Box>
                        </>
                        :
                        <UploadDataCard>
                          <img
                            src={'/uploadImg.png'}
                            alt="upload"
                            className="img-fluid"
                          />
                          <input
                            type="file"
                            accept=".doc,.pdf"
                            onChange={handlePdfChange}
                          />
                        </UploadDataCard>
                    } */}
                {/* </UploadData>
                </UploadDataBox> */}
              </Stack>
            </Stack>
            <Divider />

            <Box sx={{ my: 4 }}>
              <Grid container spacing={3}>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="Builder Name*">
                    <input
                      type="text"
                      name="name"
                      value={builderDetail?.name}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </InputStyle>
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="Phone Number*">
                    <input
                      type="text"
                      name="phoneNumber"
                      value={builderDetail?.phoneNumber}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </InputStyle>
                  {phoneError ? (
                    <span style={{ color: "red" }}>Invalid input</span>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  {/* <InputStyle name="Location*">
                    <input
                      type="text"
                      name="locationOfProperty"
                      value={builderDetail.locationOfProperty}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </InputStyle> */}

                  <Autocomplete
                    value={locationSelected}
                    onChange={(event, value1) =>
                      setLocationSelected(value1?.label)
                    }
                    disablePortal
                    name="location"
                    id="combo-box-demo"
                    options={locationSearch}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        label="Location"
                        className="inputBox"
                        {...params}
                        value={builderDetail.locationOfProperty}
                        onChange={(e) =>
                          handleSetFormValues(
                            "locationOfProperty",
                            e.target.value
                          )
                        }
                        name="locationOfProperty"
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="Company Name*">
                    <input
                      type="text"
                      name="companyName"
                      value={builderDetail?.companyName}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </InputStyle>
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="Company Type*">
                    <select
                      value={builderDetail?.companyType}
                      name="companyType"
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    >
                      <option value="" disabled>
                        Company Type
                      </option>
                      <option value="individual">individual</option>
                      <option value="llp">llp</option>
                      <option value="llc">llc</option>
                      <option value="one person">one person</option>
                      <option value="private limited company">private limited company</option>
                      <option value="proprietorship">proprietorship</option>
                      <option value="partnership">partnership</option>
                      <option value="public">public</option>
                      <option value="solo proprietorship">solo proprietorship</option>
                    </select>
                  </InputStyle>
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="Company GST*">
                    <input
                      type="text"
                      name="gst"
                      value={builderDetail?.gst}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </InputStyle>
                  {gstError ? (
                    <span style={{ color: "red" }}>Invalid input</span>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="Company PAN*">
                    <input
                      type="text"
                      name="panOfCompany"
                      value={builderDetail?.panOfCompany}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                    />
                  </InputStyle>
                  {panError ? (
                    <span style={{ color: "red" }}>Invalid input</span>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  <InputStyle name="About the Builder*">
                    <JoditEditor
                      ref={editor}
                      onBlur={(newContent) => setValue(newContent)}
                      value={value}
                    />
                  </InputStyle>
                </Grid>
                <Grid item md={12} flexGrow={1}>
                  <Typography color={"error"} component="p">
                    "Fields marked with an asterisk (*) are required."
                  </Typography>
                </Grid>
                <Grid item md={12} flexGrow={1}>
                  {isLoading ? (
                    <Button
                      disabled
                      variant="contained"
                      sx={{ boxShadow: 0, width: 150, mr: 1 }}
                      // type="submit"
                      onClick={handleSubmit}
                    >
                      <CircularProgress size={24} sx={{ color: "#fff" }} />
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      sx={{ boxShadow: 0, width: 150, mr: 1 }}
                      // type="submit"
                      onClick={handleSubmit}
                    >
                      Update
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box component={'form'} onSubmit={onboardingHandler}>
            <Stack>
              <Typography variant="title">Past Properties</Typography>
            </Stack>
            {/* <Stack direction={{ md: 'row', xs: 'column' }} sx={{ my: 3 }} spacing={6}>
              <Stack spacing={2}>
                <Typography variant="text" sx={{ color: 'mute.text' }}>
                  Upload Property Image
                </Typography>
                <Box sx={{ display: "flex" }}>
                  {profilePicView.length > 0 &&
                    profilePicView.map((item, index) => (
                      <Box
                        mx={"8px"}
                        height={"140px"}
                        borderRadius={"16px"}
                        overflow={"hidden"}
                        sx={{ aspectRatio: "1/1", position: "relative" }}
                      >
                        <img
                          src={item}
                          alt="profile"
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            backgroundColor: "grey",
                            zIndex: 1,
                          }}
                        />

                        <IconButton
                          aria-label="clear_image"
                          sx={{
                            position: "absolute",
                            zIndex: 2,
                            right: "5px",
                            top: "5px",
                            background: "#278FD9aa",
                            height: "25px",
                            width: "25px",
                          }}
                          onClick={() => handleProfileImageRemoval(index)}
                        >
                          <ClearIcon
                            color={"white"}
                            sx={{ fontSize: ".8rem" }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  <Stack direction="row" spacing={2}>
                    <Card sx={uplodeImageBtn}>
                      <CardActionArea className="button">
                        <img
                          src={"/Images/uploadImg.png"}
                          alt="profile"
                          className="img-fluid"
                        />

                        <input
                          type="file"
                          name="profileImage"
                          id="profileImageInput"
                          onChange={handleProfileImageChange}
                        />
                      </CardActionArea>
                    </Card>
                  </Stack>
                </Box>
              </Stack>
            </Stack> */}

            <UploadDataBox >
              <UploadData name="Upload Property Image">
                <Box sx={{ display: 'flex' }}>
                  {
                    profilePicView.length > 0
                    && profilePicView.map((item, index) => (
                      <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                        <img
                          // value={tempPropertyIconPicView || pastProeperty.propertyName}
                          src={item}
                          className="img-fluid"
                          style={{ width: '130px', height: '130px' }}
                        />
                        <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleProfileImageRemoval(index)}>
                          <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                        </IconButton>
                      </Box>
                    ))
                  }
                  <UploadDataCard>
                    <img
                      src={
                        '/uploadImg.png'
                      }
                      alt="upload"
                      className="img-fluid"
                    />
                    <input type="file" accept="image/*" onChange={handleProfileImageChange} multiple disabled={imageLoading} />
                  </UploadDataCard>
                </Box>
              </UploadData>
            </UploadDataBox>
            <Divider sx={{ my: 2 }} />

            <Box sx={{ my: 4 }}>
              <Grid container spacing={3}>
                <Grid item md={6} flexGrow={1}>
                  {/* <InputStyle name="Property Name" topSpace={true}> */}
                  <input
                    name="propertyName"
                    className='inputBox'
                    type="text"
                    placeholder="Enter Property Name"
                    value={tempName || pastProeperty.propertyName}
                    onChange={(e) => setTempName(e.target.value)}
                  />
                  {/* </InputStyle> */}
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  {/* <Autocomplete
                    value={tempLocation}
                    onChange={(event, value1) =>
                      setLocationSelected(value1?.label)
                    }
                    disablePortal
                    name="location"
                    id="combo-box-demo"
                    options={locationSearch}
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextField
                        label="Location"
                        className="inputBox"
                        {...params}
                        value={tempLocation || pastProeperty.location}
                        onChange={(e) =>
                          handleSetFormValues('locationOfProperty', e.target.value)
                        }
                        name="locationOfProperty"
                      />
                    )}
                  /> */}
                  <Autocomplete
                    onChange={(event, value1) => setTempLocation(value1?.label)}
                    value={tempLocation}
                    disablePortal
                    name="locationOfProperty"
                    id="combo-box-demo"
                    options={locationSearch}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField label="Location" className='inputBox' {...params} value={location} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} name='locationOfProperty' />}
                  />
                </Grid>
                <Grid item md={6} flexGrow={1}>
                  {/* <InputStyle name="Price*" topSpace={true}> */}
                  <input
                    type="text"
                    className='inputBox'
                    placeholder="Price"
                    name="price"
                    value={tempPrice || pastProeperty.price}
                    onChange={(e) => setTempPrice(e.target.value)}
                  />
                  {/* </InputStyle> */}
                </Grid>
                <Grid item xs={6} flexGrow={1}>
                  {/* <label>Property Type</label> */}

                  <select
                    value={tempPropertyType || pastProeperty.propertyType}
                    onChange={(e) => setTempPropertyType(e.target.value)}
                  >
                    <option value="" disabled style={{ height: "40px" }}>
                      Property Type
                    </option>
                    {propertyCategory.length > 0 &&
                      propertyCategory.map((item, index) => (
                        <option
                          value={item._id}
                          key={index}
                          style={{ height: "40px" }}
                        >
                          {item.name}
                        </option>
                      ))}
                  </select>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <TableStyle>
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Price</th>
                  <th>Property Type</th>
                  <th>Action</th>
                </tr>
                <tbody>
                  {pastProperties.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {
                          item?.imageUrl && JSON.parse(item?.imageUrl)?.length > 0
                            ?
                            <img
                              src={JSON.parse(item?.imageUrl)[0]}
                              alt="profile"
                              style={{
                                maxHeight: '100px',
                                maxWidth: '100px',
                                cursor: 'pointer',
                              }}
                              className="img-fluid"
                              onClick={() => {
                                // handleEditPackage(index, true);
                                setUpdateId(item._id);
                                setUpdateImages(
                                  JSON.parse(item?.imageUrl)
                                );
                                handleClickOpen();
                              }}
                            />
                            :
                            "--"
                        }
                      </td>
                      <td>
                        {item?.propertyName}
                      </td>
                      <td>
                        {item?.location}
                      </td>
                      <td>
                        {item?.price}
                      </td>
                      <td>
                        {item?.propertyType?.name}
                      </td>
                      <td>
                        <Stack direction="row" spacing={2} justifyContent="center">
                          <div>
                            <IconButton size="small" color="primary">
                              {tempIndex === index ? (
                                <Done
                                  onClick={(e) => handleUpdatePackage(e, index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <Draw
                                  onClick={() => handleEditPackage(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                            </IconButton>
                          </div>
                          <div onClick={() => handleDeletePastProperty(item?._id)}>
                            <IconButton color="error" size="small">
                              <DeleteForever
                                onClick={() => handleRemoveFromPlan(item._id)}
                              />
                            </IconButton>
                          </div>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
              <Stack spacing={3} sx={{ my: 3 }}>
                <Typography color="error" component="p">
                  "Fields marked with an asterisk (*) are required."
                </Typography>
                {isLoading ? (
                  <Button
                    disabled
                    variant="contained"
                    sx={{ boxShadow: 0, width: 150, mr: 1 }}
                  >
                    <CircularProgress size={24} sx={{ color: '#fff' }} />
                  </Button>
                ) : (
                  tempIndex === ""
                    ?
                    <Button type="submit" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={handleAddPastProperties}>Add</Button>
                    :
                    <Button type="button" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={(e) => handleUpdatePackage(e, tempIndex)}>Update</Button>
                )}
              </Stack>
            </Box>
          </Box>
        )}
      </Box>

      {/* images modal  */}
      <Dialog
        maxWidth={"sm"}
        fullWidth={true}
        size="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Images"} </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex" }}>
            {updateImages.length > 0 &&
              updateImages.map((item, index) => (

                <a href={item} target="_blank" download>
                  <Box
                    mx={"8px"}
                    height={"140px"}
                    borderRadius={"16px"}
                    overflow={"hidden"}
                    sx={{ aspectRatio: "1/1", position: "relative" }}
                  >
                    <img
                      src={item}
                      alt="profile"
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        backgroundColor: "grey",
                        zIndex: 1,
                      }}
                    />

                    {/* <IconButton
                    aria-label="clear_image"
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      right: "5px",
                      top: "5px",
                      background: "#278FD9aa",
                      height: "25px",
                      width: "25px",
                    }}
                    onClick={() => {
                      let temp = updateImages;
                      temp.splice(index, 1);
                      setUpdateImages([...temp]);
                    }}
                  >
                    <ClearIcon color={"white"} sx={{ fontSize: ".8rem" }} />
                  </IconButton> */}

                  </Box>
                </a>
              ))}
            {/* <Stack direction="row" spacing={2}>
              <Card sx={uplodeImageBtn}>
                <CardActionArea className="button">
                  <img
                    src={"/Images/uploadImg.png"}
                    alt="profile"
                    className="img-fluid"
                  />

                  <input
                    type="file"
                    name="profileImage"
                    id="profileImageInput"
                    onChange={(e) => {
                      setUpdateImages((old) => {
                        return [
                          ...old,
                          {
                            imageUrl: URL.createObjectURL(e.target.files[0]),
                            imageObject: e.target.files[0],
                          },
                        ];
                      });
                    }}
                  />
                </CardActionArea>
              </Card>
            </Stack> */}
          </Box>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}
