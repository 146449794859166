import * as React from 'react';
import Button from '@mui/material/Button';
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Stack,
  TextField,
  Autocomplete,
  IconButton,
  Badge,
  Icon
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { useDispatch } from 'react-redux';
import { useRef } from "react";
import JoditEditor from "jodit-react";
import { styled } from '@mui/material/styles';
import {
  getAllBuilders,
  uploadDocToS3,
  getAllPropertyCategory,
  getAllBanks,
  getAllAmenties,
  getAllLocationAdvantages,
  addPropertyDetails,
  getAllpropertySubCategory,
  getAllpropertySubCategorys,
  getAllSpecification,
  getLocationByInput
} from '../../actions';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Article,
  Delete,
  Done,
  Download,
  Edit,
  Info,
  ModeEditOutline,
  PictureAsPdf,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from '@mui/icons-material/Clear';


const AddPropertyForm = ({ setPropertyID }) => {
  const editor = useRef(null);
  const [propertyImages, setPropertyImages] = React.useState([]);
  const [propertyImagesPicView, setPropertyImagesPicView] = React.useState([]);
  const [propertyLogo, setPropertyLogo] = React.useState([]);
  const [propertyLogoPicView, setPropertyLogoPicView] = React.useState([]);
  const [propertyBrochure, setPropertyBrochure] = React.useState([]);
  const [propertyBrochurePicView, setPropertyBrochurePicView] = React.useState([]);
  const [builders, setBuilders] = React.useState([]);
  const [propertyCategories, setPropertyCategories] = React.useState([]);
  const [banks, setBanks] = React.useState([]);
  const [allAmenities, setAllAmenties] = React.useState([]);
  const [allLocationAdvantages, setAllLocationAdvantages] = React.useState([]);
  const [paymentPlans, setPaymentPlans] = React.useState([]);
  const [selectedBuilder, setSelectedBuilder] = React.useState('');
  const [builderDiscount, setBuilderDiscount] = React.useState('');
  const [propertyName, setPropertyName] = React.useState('');
  const [propertyLocation, setPropertyLocation] = React.useState('');
  const [propertyLiner, setPropertyLiner] = React.useState('');
  const [subCompany, setSubCompany] = React.useState('');
  const [constructionStatus, setConstructionStatus] = React.useState('');
  const [possessionDate, setPossessionDate] = React.useState('');
  const [selectedProperty, setSelectedProperty] = React.useState('');
  const [latitutde, setLatitude] = React.useState('');
  const [longitude, setLongitude] = React.useState('');
  const [amenties, setAmenties] = React.useState([]);
  const [advantages, setAdvantages] = React.useState("");
  const [loanApprovedBy, setLoanApprovedBy] = React.useState([]);
  const [about, setAbout] = React.useState('');
  const [specification, setSpecification] = React.useState('');
  const [terms, setTerms] = React.useState('');
  const [paymentPlan, setPayment] = React.useState('');
  const [milestone, setMileStone] = React.useState('');
  const [withLocality, setWithLocality] = React.useState('');
  const [currentPrice, setCurrentPrice] = React.useState('');
  const [last1Year, setFirstYearPrice] = React.useState('');
  const [last2Year, setSecondYearPrice] = React.useState('');
  const [last3Year, setThirdYearPrice] = React.useState('');
  const [last4Year, setFourthYearPrice] = React.useState('');
  const [tempPlan, setTempPlan] = React.useState('');
  const [tempMilestone, setTempMilestone] = React.useState('');
  const [tempIndex, setTempIndex] = React.useState('');
  const [tempLocality, setTempLocality] = React.useState('');
  const [tempCurrentPrice, setTempCurrentPrice] = React.useState('');
  const [tempFirstPrice, setTempFIrstPrice] = React.useState('');
  const [tempSecondPrice, setTempSecondPrice] = React.useState('');
  const [tempThirdPrice, setTempThirdPrice] = React.useState('');
  const [tempFourthPrice, setTemoFourthPrice] = React.useState('');
  const [tempPriceIndex, setTempPriceIndex] = React.useState('');
  const [trends, setTrends] = React.useState([]);
  const [allSubCompanies, setAllSubCompanies] = React.useState([]);
  const [allSpecifications, setAllSpecifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [subCategory, setSubCategory] = React.useState(false);
  const [locationSelected, setLocationSelected] = React.useState('');
  const [locationSearch, setLocationSearch] = React.useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState("");
  const [distance, setDistance] = React.useState("");
  const [locationAdvantages, setLocationAdvantages] = React.useState([]);
  const [tempLocationAdvantageIndex, setTempLocationAdvantageIndex] = React.useState("");
  const [tempLocationAdvantage, setTempLocationAdvantage] = React.useState("");
  const [tempDistance, setTempDistance] = React.useState("");
  const [tempLocationAdvantages, setTempLocationAdvantages] = React.useState([]);
  const [loadingProperty, setLoadingProperty] = React.useState(false)
  const [loadingPropertyLogo, setLoadingPropertyLogo] = React.useState(false)
  const [loadingpropertyBrochure, setLoadingpropertyBrochure] = React.useState(false)
  const hideInput = {
    opacity: 0,
    position: 'absolute',
    left: '26.3%',
    zIndex: 10,
    width: '100px',
    height: '100px',
    cursor: 'pointer',
  };

  const handleSetFormValues = (name, value) => {
    if (name === 'location') {
      getLocationByInput({ searchText: value }).then((res) => {
        if (res.data.data.length > 0) {
          var arr = [];
          for (let index = 0; index < res.data.data.length; index++) {
            let element = { label: res.data.data[index].location };
            arr.push(element);
          }
          setLocationSearch(arr);
        }
      }).catch((err) => {
        console.log(err)
      })
    }
    setPropertyLocation((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };


  React.useEffect(() => {
    if (localStorage.getItem("formData") != null) {
      const data = JSON.parse(localStorage.getItem("formData"));
      if (data.images) {
        setPropertyImages(data.images);
      } else {
        setPropertyImages([]);
      }
      if (data?.propertyLogo) {
        setPropertyLogo(data.propertyLogo);
      }
      if (data.brochure) {
        setPropertyBrochure(data.brochure);
        setPropertyBrochurePicView(data.brochure);
      } else {
        setPropertyBrochure([]);
        setPropertyBrochurePicView([]);
      }
      if (builders?.length > 0) {
        const temppbuilder = builders.filter((item) => item._id == data.builderId).map((item) => ({ id: item._id, companyName: item.companyName || "--" }));
        setSelectedBuilder(temppbuilder[0]);
      }
      setBuilderDiscount(data.builderDiscount);
      setPropertyName(data.name);
      setLocationSelected(data.location);
      setPropertyLiner(data.propertyLiner);
      if(allSubCompanies?.length > 0){
        const temppcompany = allSubCompanies.filter((item) => item._id == data.subCompany).map((item) => ({ id: item._id, subCompanyName: item.subCompanyName || "--" }));
        setSubCompany(temppcompany[0]);
      }
      setConstructionStatus(data.constructionStatus);
      setPossessionDate(data.possessionDate);
      setSelectedProperty(data.propertyType);
      loadAllPropertySubCategory(data.propertyType);
      setLatitude(data.latitude);
      setLongitude(data.longitude);
      if (data.amenitiesId) {
        setAmenties(data.amenitiesId);
      } else {
        setAmenties([]);
      }
      if (data.locationAdvantagesId) {
        setLocationAdvantages(data.locationAdvantagesId);
      } else {
        setLocationAdvantages([]);
      }
      if (data.loanApprovedByIds) {
        setLoanApprovedBy(data.loanApprovedByIds);
      } else {
        setLoanApprovedBy([]);
      }
      setAbout(data.propertyDescription);
      setSpecification(data.projectSpecification);
      setTerms(data.termAndCondition);
      if (data.paymentPlan) {
        setPaymentPlans(data.paymentPlan);
      } else {
        setPaymentPlans([]);
      }
      if (data.currentlyComparing) {
        setTrends(data.currentlyComparing);
      } else {
        setTrends([]);
      }

      setSelectedSubCategory(data.selectedSubCategory);
    }
  }, [builders])

  const dispatch = useDispatch();

  const handleUploadImage = (e) => {
    const file = e.target.files;
    setLoadingProperty(true)
    if (file && file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setPropertyImages((oldArr) => [...oldArr, data.data.documents[0]]);
            setLoadingProperty(false)
            console.log("Images", propertyImages);
            var lala = JSON.parse(localStorage.getItem("formData"));
            AddOrUpdateLocalStorage({
              images: [...lala.images, data.data.documents[0]]
            });
          }, (err) => {
            console.log(err);
            setLoadingProperty(false)
            enqueueSnackbar("upload failed", { variant: "error" });
          })
        );

      }
    }
  };

  const handleUploadLogo = (e) => {
    const file = e.target.files[0];
setLoadingPropertyLogo(true)
    if (file) {
      const formData = new FormData();
      formData.append('documents', file);
      dispatch(
        uploadDocToS3(formData, (data) => {
          setPropertyLogo([data.data.documents[0]]);
          setLoadingPropertyLogo(false)
          AddOrUpdateLocalStorage({
            propertyLogo: [data.data.documents[0]]
          });
        }, (err) => {
          setLoadingPropertyLogo(false)
          enqueueSnackbar("upload failed", { variant: "error" });
          console.log(err);
        })
      );
    }
  };

  const handleUploadBrochure = (e) => {
    const file = e.target.files;
    setLoadingpropertyBrochure(true)
    if (file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let formData = new FormData();
        formData.append('documents', file[index]);
        dispatch(
          uploadDocToS3(formData, (data) => {
            setPropertyBrochure((oldArr) => [...oldArr, data.data.documents[0]]);
            setLoadingpropertyBrochure(false)
            AddOrUpdateLocalStorage({
              brochure: [...propertyBrochure, data.data.documents[0]]
            });
          }, (err) => {
            setLoadingpropertyBrochure(false)
            enqueueSnackbar("upload failed", { variant: "error" });
            console.log(err);
          })
        );

      }
    }
  };

  const loadAllBulders = React.useCallback(() => {
    dispatch(
      getAllBuilders({ isAdmin: true }, (data) => {
        var temp = data.data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setBuilders(temp);
        }
      })
    );
  }, [dispatch]);

  const loadAllProperties = React.useCallback(() => {
    dispatch(
      getAllPropertyCategory({}, (data) => {
        var temp = data.data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setPropertyCategories(temp);
        }
      })
    );
  }, [dispatch]);

  const loadAllBanks = React.useCallback(() => {
    dispatch(
      getAllBanks({}, (data) => {
        var temp = data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setBanks(temp);
        }
      })
    );
  }, [dispatch]);

  const loadAllAmenties = React.useCallback(() => {
    dispatch(
      getAllAmenties({}, (data) => {
        var temp = data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setAllAmenties(temp);
        }
      })
    );
  }, [dispatch]);

  const loadAllLocationAdvantages = React.useCallback(() => {
    dispatch(
      getAllLocationAdvantages({}, (data) => {
        var temp = data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setAllLocationAdvantages(temp);
        }
      })
    );
  }, [dispatch]);

  const loadAllSubCompanies = React.useCallback(() => {
    dispatch(
      getAllpropertySubCategorys({}, (data) => {
        var temp = data.data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setAllSubCompanies(temp);
        }
      })
    )
  }, [dispatch])

  const loadAllSpecifications = React.useCallback(() => {
    dispatch(
      getAllSpecification({}, (data) => {
        var temp = data.data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setAllSpecifications(temp);
        }
      })
    )
  }, [dispatch])


  const loadAllPropertySubCategory = (id) => {
    dispatch(
      getAllpropertySubCategory({ parentId: id }, (data) => {
        var temp = data.data.filter((item) => { return !item.isDeleted });
        if (temp.length > 0) {
          setSubCategory(temp);
        } else {
          setSubCategory([]);
        }
      })
    );
  };

  React.useEffect(() => {
    loadAllBulders();
    loadAllProperties();
    loadAllBanks();
    loadAllAmenties();
    loadAllLocationAdvantages();
    loadAllSubCompanies();
    loadAllSpecifications();
  }, [
    loadAllBulders,
    loadAllProperties,
    loadAllBanks,
    loadAllAmenties,
    loadAllLocationAdvantages,
    loadAllSubCompanies,
    loadAllSpecifications
  ]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAmenties(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    AddOrUpdateLocalStorage({
      amenitiesId: typeof value === 'string' ? value.split(',') : value
    });
  };

  const handleLocationAdvantageChange = (event) => {
    const {
      target: { value },
    } = event;
    setAdvantages(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    AddOrUpdateLocalStorage({
      locationAdvantagesId: typeof value === 'string' ? value.split(',') : value
    });
  };

  const handleLoanApprovedChange = (event) => {
    const {
      target: { value },
    } = event;
    setLoanApprovedBy(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    AddOrUpdateLocalStorage({
      loanApprovedByIds: typeof value === 'string' ? value.split(',') : value
    });
  };

  const handleCreatePlan = () => {
    if (!paymentPlan || !milestone) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    setPaymentPlans((oldArr) => [...oldArr, { payment: paymentPlan, milestone }]);
    AddOrUpdateLocalStorage({
      paymentPlan: [...paymentPlans, { payment: paymentPlan, milestone }]
    });
    setMileStone("");
    setPayment("");
  };

  const handleRemoveFromPlan = (index) => {
    setPaymentPlans((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the new array
    });
  };

  const handleEditPackage = (index) => {
    setTempIndex(index);
    setTempMilestone(paymentPlans[index].milestone);
    setTempPlan(paymentPlans[index].payment);
  };

  const handleUpdatePackage = (index) => {
    const val = paymentPlans;
    val[index] = { payment: tempPlan, milestone: tempMilestone };
    AddOrUpdateLocalStorage({
      paymentPlan: val
    });
    setPaymentPlans(val);
    setTempIndex(null);
    setTempMilestone(null);
    setTempPlan(null);
  };

  const handleCreatePriceTrends = () => {
    if (
      !withLocality ||
      !currentPrice ||
      !last1Year ||
      !last2Year ||
      !last3Year ||
      !last4Year
    ) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    setTrends((oldArr) => [
      ...oldArr,
      {
        withLocality,
        currentPrice,
        last1Year,
        last2year: last2Year,
        last3Year,
        last4Year,
      },
    ]);
    AddOrUpdateLocalStorage({
      currentlyComparing: [...trends, {
        withLocality,
        currentPrice,
        last1Year,
        last2year: last2Year,
        last3Year,
        last4Year,
      }]
    });
    setWithLocality("")
    setCurrentPrice("")
    setFirstYearPrice("")
    setSecondYearPrice("")
    setThirdYearPrice("")
    setFourthYearPrice("")

  };

  const handleRemoveFromPriceTrends = (index) => {
    setTrends((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the new array
    });
  };

  const handleEditPriceTrends = (index) => {
    setTempPriceIndex(index);
    setTempLocality(trends[index].withLocality);
    setTempCurrentPrice(trends[index].currentPrice);
    setTempFIrstPrice(trends[index].last1Year);
    setTempSecondPrice(trends[index].last2year);
    setTempThirdPrice(trends[index].last3Year);
    setTemoFourthPrice(trends[index].last4Year);
  };

  const handleUpdatePriceTrends = (index) => {
    const val = trends;
    val[index] = {
      withLocality: tempLocality,
      currentPrice: tempCurrentPrice,
      last1Year: tempFirstPrice,
      last2year: tempSecondPrice,
      last3Year: tempThirdPrice,
      last4Year: tempFourthPrice,
    };
    setTempPriceIndex(null);
    setTempLocality(null);
    setTempCurrentPrice(null);
    setTempFIrstPrice(null);
    setTempSecondPrice(null);
    setTempThirdPrice(null);
    setTemoFourthPrice(null);
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !selectedBuilder.id ||
      propertyImages?.length === 0 ||
      !propertyName ||
      !locationSelected ||
      !propertyLiner ||
      !subCompany.id || 
      !constructionStatus ||
      (constructionStatus != "Ready to Move" && !possessionDate) ||
      !selectedProperty ||
      !selectedSubCategory ||
      !latitutde ||
      !longitude ||
      !about
    ) {
      enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
      return;
    }
    // farmhouse - PRPTY-911465
    // flats - PRPTY-906626
    // commercial - PRPTY-671815
    // plots - PRPTY-557497
    setIsLoading(true);

    const formData = {
      possessionDate: possessionDate,
      name: propertyName,
      location: locationSelected,
      propertyType: selectedProperty,
      latitude: latitutde,
      longitude: longitude,
      constructionStatus: constructionStatus,
      paymentPlan: paymentPlans,
      propertyDescription: about,
      loanApprovedByIds: loanApprovedBy,
      builderId: selectedBuilder.id,
      termAndCondition: terms,
      currentlyComparing: trends,
      brochure: propertyBrochure,
      images: propertyImages,
      amenitiesId: amenties,
      locationAdvantagesId: locationAdvantages,
      subCompany: subCompany.id,
      propertyLiner: propertyLiner,
      builderDiscount: builderDiscount,
      projectSpecification: specification,
      propertyLogo: propertyLogo[0],
      selectedSubCategory
    }

    localStorage.setItem("formData", JSON.stringify(formData));
    navigate('/property-management/floor-Plan');
    setIsLoading(false);
  };


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const AddOrUpdateLocalStorage = (formData) => {
    if (localStorage.getItem("formData")) {
      var obj = JSON.parse(localStorage.getItem("formData"));
      var final = { ...obj, ...formData };
      localStorage.setItem("formData", JSON.stringify(final));
    } else {
      localStorage.setItem("formData", JSON.stringify(formData));
    }
  }
  const handleRemovePropertyImage = (index) => {
    setPropertyImages(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    setPropertyImagesPicView(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    let tempArr = [...propertyImages];
    tempArr.splice(index, 1);

    if (localStorage.getItem("formData")) {
      localStorage.setItem("formData", JSON.stringify({ ...JSON.parse(localStorage.getItem("formData")), images: tempArr }));
    }
  }

  const handleRemovePropertyLogo = () => {
    setPropertyLogo([]);
    setPropertyLogoPicView([]);
    if (localStorage.getItem("formData")) {
      localStorage.setItem("formData", JSON.stringify({ ...JSON.parse(localStorage.getItem("formData")), propertyLogo: [] }));
    }
  }

  const handleRemovePropertyBrochure = (index) => {
    setPropertyBrochure(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    setPropertyBrochurePicView(prevArray => {
      const newArray = [...prevArray];
      newArray.splice(index, 1);
      return newArray;
    });

    let tempArr = [...propertyBrochure];
    tempArr.splice(index, 1);


    if (localStorage.getItem("formData")) {
      localStorage.setItem("formData", JSON.stringify({ ...JSON.parse(localStorage.getItem("formData")), brochure: tempArr }));
    }
  }

  const handleAddLocationAdvantage = () => {
    if (!distance || !advantages) {
      enqueueSnackbar("Select a location advantage and distance as well", { variant: 'warning' });
    } else if (isNaN(distance)) {
      enqueueSnackbar("Please enter a valid distance", { variant: 'warning' });
    } else if (locationAdvantages.filter((item) => item?.name?.toLowerCase() === advantages?.name?.toLowerCase())?.length > 0) {
      enqueueSnackbar("Can not same location advantage twice", { variant: 'warning' });
    } else {
      const temp = [...locationAdvantages, { ...advantages, distance }];
      setLocationAdvantages(temp);
      setAdvantages("");
      setDistance("");
      AddOrUpdateLocalStorage({
        locationAdvantagesId: temp
      });
    }

  }

  const handleEditLocationAdvantage = (index) => {
    setTempLocationAdvantageIndex(index);
    setTempLocationAdvantage(locationAdvantages[index]);
    setTempDistance(locationAdvantages[index]?.distance);
  }

  const handleUpdateLocationAdvantage = (index) => {
    var tempArr = [...locationAdvantages];
    tempArr[index] = tempLocationAdvantage;
    tempArr[index].distance = tempDistance;
    setLocationAdvantages(tempArr);
    AddOrUpdateLocalStorage({
      locationAdvantagesId: tempArr
    });
    setTempLocationAdvantageIndex("");
    setTempLocationAdvantage("");
    setTempDistance("");
  }

  const handleRemoveLocationAdvantage = (index) => {
    const newArray = locationAdvantages.filter((item, index1) => index1 != index);
    setLocationAdvantages(newArray);
    AddOrUpdateLocalStorage({
      locationAdvantagesId: newArray
    });
    setTempLocationAdvantageIndex("");
  }

  return (
    <Box sx={{ marginTop: '73px' }}>
      <UploadDataBox>
        <UploadData name={'Upload Property Image*'}>
          {
            propertyImages && propertyImages.map((item, index) => (
              <>
                <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                  <img
                    src={item}
                    className="img-fluid"
                    style={{ width: '130px', height: '130px' }}
                  />
                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyImage(index)}>
                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                  </IconButton>
                </Box>
              </>
            ))
          }
          <UploadDataCard>
{loadingProperty ?
<Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
<CircularProgress size={30}/>
</Box>
:

<>
<img
              src={
                '/uploadImg.png'
              }
              alt="upload"
              className="img-fluid"
            />
            <input
              type="file"
              accept="image/*"
              multiple
              style={hideInput}
              onChange={handleUploadImage}
            />
</>
}    
          </UploadDataCard>
        </UploadData>
        <UploadData name={'Upload Property Logo'}>
          <UploadDataCard>

            {propertyLogo.length > 0 ?

              <>
                <img
                  src={propertyLogo[0]}
                  alt="logo"
                  style={{ width: '130px', height: '130px' }}
                  className="img-fluid"
                />
                <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyLogo}>
                  <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                </IconButton>
              </>
              :
              <>
              {loadingPropertyLogo ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <CircularProgress size={30}/>
                </Box>
                :
                
             
              <>
                <img
                  src={'/uploadImg.png'}
                  alt="upload"
                />
                <input
                  type="file"
                  accept="image/*"
                  style={hideInput}
                  onChange={handleUploadLogo}
                />
              </>
              }
              </>
            }
          </UploadDataCard>
        </UploadData>
        <UploadData name={'Upload Property Brochure'}>
          {propertyBrochure &&
            propertyBrochure.map((item, index) => (
              <>
                <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                  <embed src={item} width="200" height="200" />
                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '30px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                  </IconButton>
                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                    <a href={item}>
                      <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                    </a>
                  </IconButton>
                </Box>
              </>
            ))}
          <UploadDataCard>
          {loadingpropertyBrochure ?
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <CircularProgress size={30}/>
                </Box>
                :
<>
            <img
              src={'/uploadImg.png'}
              alt="upload"
              className="img-fluid"
            />
            <input
              type="file"
              accept=".doc,.pdf"
              style={hideInput}
              multiple
              onChange={handleUploadBrochure}
            />
            </>
}
          </UploadDataCard>
        </UploadData>
      </UploadDataBox>
      <Divider
        sx={{
          height: '1.5px',
          background: '#E0E4EC',
          marginTop: '73px',
          marginBottom: '34px',
        }}
      />
      <form onSubmit={handleSubmit}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label>Select Builder*</label>
              {/* <select value={selectedBuilder} onChange={(e) => {
                AddOrUpdateLocalStorage({
                  builderId: e.target.value
                });
                setSelectedBuilder(e.target.value);
              }}>
                <option value="" disabled>
                  Select Builder
                </option>
                {builders.length > 0 &&
                  builders.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item?.companyName ? item?.companyName : "--"}
                    </option>
                  ))}
              </select> */}
              <Autocomplete
                onChange={(event, value) => {
                  console.log(value)
                  if (value) {
                    AddOrUpdateLocalStorage({
                      builderId: value.id
                    });
                    setSelectedBuilder(value);
                  }
                }}
                value={selectedBuilder || {id: '123', companyName: ""}}
                disablePortal
                name="builder"
                id="combo-box-demo"
                options={builders.map((item) => ({ id: item._id, companyName: item.companyName || "--" }))}
                getOptionLabel={(option) => option.companyName}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField className='inputBox' {...params} name='builder' />}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Builder Discount</label>
              <input
                value={builderDiscount}
                onChange={(e) => {
                  setBuilderDiscount(e.target.value);
                  AddOrUpdateLocalStorage({
                    builderDiscount: e.target.value
                  });
                }}
                className="inputBox"
                type="text"
                placeholder="Builder Discount"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Property Name*</label>
              <input
                value={propertyName}
                onChange={(e) => {
                  setPropertyName(e.target.value);
                  AddOrUpdateLocalStorage({
                    name: e.target.value
                  });
                }}
                className="inputBox"
                type="text"
                placeholder="Property Name"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Property Location*</label>
              <Autocomplete
                onChange={(event, value1) => {
                  setLocationSelected(value1?.label);
                  AddOrUpdateLocalStorage({
                    location: value1?.label
                  });
                }}
                disablePortal
                value={locationSelected}
                name="location"
                id="combo-box-demo"
                options={locationSearch}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField className='inputBox' {...params} name='location' value={propertyLocation} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} />}
              />
            </Grid>

            <Grid item xs={6}>
              <label>Property Liner*</label>
              <input
                value={propertyLiner}
                onChange={(e) => {
                  setPropertyLiner(e.target.value);
                  AddOrUpdateLocalStorage({
                    propertyLiner: e.target.value
                  });
                }}
                className="inputBox"
                type="text"
                placeholder="Property Liner"
              />
            </Grid>

            <Grid item xs={6}>
              <label>Sub Company*</label>
              {/* <select value={subCompany} onChange={(e) => {
                setSubCompany(e.target.value);
                AddOrUpdateLocalStorage({
                  subCompany: e.target.value
                });
              }}>
                <option selected disabled value={""}>Choose sub company</option>
                {allSubCompanies && allSubCompanies.length && allSubCompanies.map((item, index) => (
                  <option value={item._id} key={index}>{item.subCompanyName}</option>
                ))}
              </select> */}
              <Autocomplete
                onChange={(event, value) => {
                  console.log(value)
                  if (value) {
                    AddOrUpdateLocalStorage({
                      subCompany: value.id
                    });
                    setSubCompany(value);
                  }
                }}
                value={subCompany || {id: '123', subCompanyName: ""}}
                disablePortal
                name="builder"
                id="combo-box-demo"
                options={allSubCompanies.map((item) => ({ id: item._id, subCompanyName: item.subCompanyName || "--" }))}
                getOptionLabel={(option) => option.subCompanyName}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField className='inputBox' {...params} name='builder' />}
              />
            </Grid>

            <Grid item xs={6}>
              <label>Construction Status*</label>
              <select value={constructionStatus} onChange={(e) => {
                setConstructionStatus(e.target.value);
                AddOrUpdateLocalStorage({
                  constructionStatus: e.target.value
                });
              }}>
                <option value="" disabled selected>
                  Construction Status
                </option>
                <option value="Ready to Move">Ready to move</option>
                <option value="Under Construction">Under Construction</option>
                <option value="New Launch">New Launch</option>
              </select>
            </Grid>

            <Grid item xs={6}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <label>Possession Date*</label>
                {constructionStatus == 'Ready to Move' && (
                  <Tooltip title="this field will be disabled for 'ready to move' construction status">
                    <Info sx={{ fontSize: '1rem' }} />
                  </Tooltip>
                )}
              </Stack>

              <input
                value={possessionDate}
                onChange={(e) => {
                  setPossessionDate(e.target.value);
                  AddOrUpdateLocalStorage({
                    possessionDate: e.target.value
                  });
                }}
                className="inputBox"
                type="date"
                placeholder="Possession Date"
                disabled={constructionStatus == 'Ready to Move'}
              />
            </Grid>
            <Grid item xs={6}>
              <label>Property Type*</label>

              <select
                value={selectedProperty}
                onChange={(e) => {
                  setSelectedProperty(e.target.value);
                  localStorage.removeItem("floorplans");
                  loadAllPropertySubCategory(e.target.value);
                  AddOrUpdateLocalStorage({
                    propertyType: e.target.value
                  });
                }}
              >
                <option value="" disabled>
                  Property Type*
                </option>
                {propertyCategories.length > 0 &&
                  propertyCategories.map((item, index) => (
                    <option value={item._id} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6}>
              <label>Sub . Category*</label>
              <select
                value={selectedSubCategory}
                onChange={(e) => {
                  localStorage.removeItem("floorplans");
                  if (e.target.value !== "") {
                    AddOrUpdateLocalStorage({
                      selectedSubCategory: e.target.value
                    });
                  }
                  setSelectedSubCategory(e.target.value)
                }}>
                <option value={""}>Select</option>
                {subCategory &&
                  subCategory.map((item) => (
                    <option key={item._id} value={item.name}>{item.name}</option>
                  ))}
              </select>
            </Grid>
            <Grid item xs={6}>
              <label>Location Latitude*</label>
              <input
                value={latitutde}
                onChange={(e) => {
                  setLatitude(e.target.value);
                  AddOrUpdateLocalStorage({
                    latitude: e.target.value
                  });
                }}
                className="inputBox"
                type="text"
                placeholder="Location Latitude"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Location Longitude*</label>
              <input
                value={longitude}
                onChange={(e) => {
                  setLongitude(e.target.value);
                  AddOrUpdateLocalStorage({
                    longitude: e.target.value
                  });
                }}
                className="inputBox"
                type="text"
                placeholder="Location Longitude"
              />
            </Grid>
            <Grid item xs={6}>
              <label>Amenities</label>
              <FormControl sx={{ m: 1, width: '100%', display: 'block' }}>
                <InputLabel id="amenties">Amenities</InputLabel>
                <Select
                  labelId="amenties"
                  id="demo-multiple-name"
                  multiple
                  value={amenties}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={handleChange}
                >
                  {allAmenities.length > 0
                    ?
                    allAmenities.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        {item.name} : (quantity: {item.quantity})
                      </MenuItem>
                    ))
                    :
                    ""}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <label>Loan Approved by</label>
              <FormControl sx={{ m: 1, width: '100%', display: 'block' }}>
                <InputLabel id="loans">Loan Approved by</InputLabel>
                <Select
                  labelId="loans"
                  id="demo-multiple-name2"
                  multiple
                  value={loanApprovedBy}
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  onChange={handleLoanApprovedChange}
                >
                  {banks.length > 0 &&
                    banks.map((item, index) => (
                      <MenuItem key={index} value={item._id}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <label>Location Advantage</label>
              <FormControl sx={{ m: 1, width: '100%', display: 'block' }}>
                <InputLabel id="advantage">Location Advantage</InputLabel>
                <Select
                  labelId="advantage"
                  id="demo-multiple-name1"
                  sx={{
                    width: '100%',
                    background:
                      'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                  }}
                  value={JSON.stringify(advantages)}
                  onChange={(e) => setAdvantages(JSON.parse(e.target.value))}
                >
                  {allLocationAdvantages.length > 0 &&
                    allLocationAdvantages.map((item, index) => (
                      <MenuItem key={index} value={JSON.stringify(item)}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={5} sx={{ m: 0.3 }}>
              <label>Distance (in mtr)</label>
              <input
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Enter distance"
              />
            </Grid>
            <Grid item xs={1}>
              <p
                onClick={handleAddLocationAdvantage}
                style={{
                  borderRadius: '50%',
                  background: '#278FD9',
                  color: '#FFFFFF',
                  fontWeight: '600',
                  padding: '1px 16px',
                  fontSize: '35px',
                  width: '54px',
                  height: '54px',
                  marginTop: '25px',
                  cursor: 'pointer',
                }}
              >
                +
              </p>
            </Grid>
            {
              locationAdvantages?.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Location Advantage</TableCell>
                        <TableCell align="center">Distance</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {locationAdvantages.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {tempLocationAdvantageIndex === index ? (
                              <select
                                labelId="advantage"
                                id="demo-multiple-name2"
                                style={{
                                  width: '100%',
                                  background:
                                    'linear-gradient(0deg, #EDF2F7, #EDF2F7), #FFFFFF',
                                }}
                                value={JSON.stringify(tempLocationAdvantage)}
                                onChange={(e) => setTempLocationAdvantage(JSON.parse(e.target.value))}
                              >
                                {allLocationAdvantages.length > 0 &&
                                  allLocationAdvantages.map((item, index) => (
                                    <option key={index} value={JSON.stringify(item)}>
                                      {item.name}
                                    </option>
                                  ))}
                              </select>
                            ) : (
                              item.name
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {tempLocationAdvantageIndex === index ? (
                              <input
                                value={tempDistance}
                                onChange={(e) => setTempDistance(e.target.value)}
                                className="inputBox"
                                type="text"
                                placeholder="Plan"
                              />
                            ) : (
                              item.distance
                            )}
                          </TableCell>
                          <TableCell>
                            <p style={{ textAlign: 'center' }}>
                              {tempLocationAdvantageIndex === index ? (
                                <Done
                                  onClick={() => handleUpdateLocationAdvantage(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              ) : (
                                <ModeEditOutline
                                  onClick={() => handleEditLocationAdvantage(index)}
                                  sx={{ cursor: 'pointer' }}
                                />
                              )}
                              <Delete
                                onClick={() => handleRemoveLocationAdvantage(index)}
                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                              />
                            </p>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            }
            <Grid item xs={12}>
              <label>About The Project*</label>
              <JoditEditor
                ref={editor}
                onBlur={(newContent) => {
                  setAbout(newContent);
                  AddOrUpdateLocalStorage({
                    propertyDescription: newContent
                  });
                }}
                value={about}

              />
            </Grid>
            <Grid item xs={12}>
              <label>Project Specification</label>
              <JoditEditor
                ref={editor}
                onBlur={(newContent) => {
                  setSpecification(newContent);
                  AddOrUpdateLocalStorage({
                    projectSpecification: newContent
                  });
                }
                }
                value={specification}

              />
            </Grid>
            <Grid item xs={12} mt={20}>
              <label>Terms & Conditions</label>
              <JoditEditor
                ref={editor}
                onBlur={(newContent) => {
                  setTerms(newContent)
                  AddOrUpdateLocalStorage({
                    termAndCondition: newContent
                  });
                }}
                value={terms}

              />
            </Grid>

            <Grid item xs={4}>
              <label>Payment Plan(%)</label>
              <input
                value={paymentPlan}
                onChange={(e) => setPayment(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Payment Plan"
              />
            </Grid>
            <Grid item xs={7}>
              <label>Milestone</label>
              <input
                value={milestone}
                onChange={(e) => setMileStone(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Milestone"
              />
            </Grid>
            <Grid item xs={1}>
              <p
                onClick={handleCreatePlan}
                style={{
                  borderRadius: '50%',
                  background: '#278FD9',
                  color: '#FFFFFF',
                  fontWeight: '600',
                  padding: '1px 16px',
                  fontSize: '35px',
                  width: '54px',
                  height: '54px',
                  marginTop: '25px',
                  cursor: 'pointer',
                }}
              >
                +
              </p>
            </Grid>
            {paymentPlans.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Payment %</TableCell>
                      <TableCell align="center">Milestone</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentPlans.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {tempIndex === index ? (
                            <input
                              value={tempPlan}
                              onChange={(e) => setTempPlan(e.target.value)}
                              className="inputBox"
                              type="text"
                              placeholder="Milestone"
                            />
                          ) : (
                            item.payment
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempIndex === index ? (
                            <input
                              value={tempMilestone}
                              onChange={(e) => setTempMilestone(e.target.value)}
                              className="inputBox"
                              type="text"
                              placeholder="Plan"
                            />
                          ) : (
                            item.milestone
                          )}
                        </TableCell>
                        <TableCell>
                          <p style={{ textAlign: 'center' }}>
                            {tempIndex === index ? (
                              <Done
                                onClick={() => handleUpdatePackage(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <ModeEditOutline
                                onClick={() => handleEditPackage(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                            <Delete
                              onClick={() => handleRemoveFromPlan(index)}
                              sx={{ color: '#EA000D', cursor: 'pointer' }}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </Grid>
        </Box>

        <Box mt={6}>
          <Typography variant="h6" mb={2}>
            Price Trend
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label>Locality</label>
              <input
                value={withLocality}
                onChange={(e) => setWithLocality(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Locality"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Current Price</label>
              <input
                value={currentPrice}
                onChange={(e) => setCurrentPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Current Price"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Price For Last 1 Year</label>
              <input
                value={last1Year}
                onChange={(e) => setFirstYearPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Price For Last 1 Year"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Price For Last 2 Year</label>
              <input
                value={last2Year}
                onChange={(e) => setSecondYearPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Price For Last 2 Year"
              />
            </Grid>
            <Grid item xs={4}>
              <label>Price For Last 3 Year</label>
              <input
                value={last3Year}
                onChange={(e) => setThirdYearPrice(e.target.value)}
                className="inputBox"
                type="text"
                placeholder="Price For Last 3 Year"
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={9}>
                  <label>Price For Last 4 Year</label>
                  <input
                    value={last4Year}
                    onChange={(e) => setFourthYearPrice(e.target.value)}
                    className="inputBox"
                    type="text"
                    placeholder="Price For Last 4 Year"
                  />
                </Grid>
                <Grid item xs={3}>
                  <p
                    onClick={handleCreatePriceTrends}
                    style={{
                      marginLeft: '15px',
                      borderRadius: '50%',
                      background: '#278FD9',
                      color: '#FFFFFF',
                      fontWeight: '600',
                      padding: '1px 16px',
                      fontSize: '35px',
                      width: '54px',
                      height: '54px',
                      marginTop: '25px',
                      cursor: 'pointer',
                    }}
                  >
                    +
                  </p>
                </Grid>
              </Grid>
            </Grid>
            {trends.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Locality</TableCell>
                      <TableCell align="center">Current Price</TableCell>
                      <TableCell align="center">Last 1 year</TableCell>
                      <TableCell align="center">Last 2 year</TableCell>
                      <TableCell align="center">Last 3 year</TableCell>
                      <TableCell align="center">Last 4 year</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {trends.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempLocality}
                              onChange={(e) => setTempLocality(e.target.value)}
                              className="inputBox"
                              type="text"
                              placeholder="locality"
                            />
                          ) : (
                            item.withLocality
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempCurrentPrice}
                              onChange={(e) =>
                                setTempCurrentPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Current Price"
                            />
                          ) : (
                            item.currentPrice
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempFirstPrice}
                              onChange={(e) =>
                                setTempFIrstPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 1 year price"
                            />
                          ) : (
                            item.last1Year
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempSecondPrice}
                              onChange={(e) =>
                                setTempSecondPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 2 year price"
                            />
                          ) : (
                            item.last2year
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempThirdPrice}
                              onChange={(e) =>
                                setTempThirdPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 3 year price"
                            />
                          ) : (
                            item.last3Year
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {tempPriceIndex === index ? (
                            <input
                              value={tempFourthPrice}
                              onChange={(e) =>
                                setTemoFourthPrice(e.target.value)
                              }
                              className="inputBox"
                              type="text"
                              placeholder="Last 4 year price"
                            />
                          ) : (
                            item.last4Year
                          )}
                        </TableCell>
                        <TableCell>
                          <p style={{ textAlign: 'center' }}>
                            {tempPriceIndex === index ? (
                              <Done
                                onClick={() => handleUpdatePriceTrends(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <ModeEditOutline
                                onClick={() => handleEditPriceTrends(index)}
                                sx={{ cursor: 'pointer' }}
                              />
                            )}
                            <Delete
                              onClick={() => handleRemoveFromPriceTrends(index)}
                              sx={{ color: '#EA000D', cursor: 'pointer' }}
                            />
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ''
            )}
          </Grid>
        </Box>

        <Button
          type="submit"
          style={{
            marginTop: '44px',
            width: '224px',
            height: '52px',
            borderRadius: '8.62px',
            background: '#278FD9',
            color: '#FFFFFF',
            fontWeight: '700',
            fontSize: '19px',
            marginBottom: '33px',
          }}
        >
          Next
        </Button>
      </form>
    </Box>
  );
};

export default AddPropertyForm;
