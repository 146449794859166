import axios from "axios";
import actionNames from "../utils/actionNames";
import { deleteAPI, getAPI, patchApi, postAPI, putAPI } from "../utils/api";
import common, { baseURL } from "../utils/common";
import endpoint from "../utils/endPoints";

export const adminLogin = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADMIN_LOGIN,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};
export const updateLoginStatus = (status) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.AUTH_REDUCER,
      payload: {
        isLoggedIn: status ?? false,
      },
    });
  };
};

export const updateLoginModuleType = (type) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.AUTH_REDUCER,
      payload: {
        loggedInModule: type,
      },
    });
  };
};

export const clearReducerOnLogout = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.CLEAR_AUTH_REDUCER,
      payload: {},
    });
    dispatch({
      type: actionNames?.CLEAR_USER_REDUCER,
      payload: {},
    });
    dispatch({
      type: actionNames?.CLEAR_NOTIFICATION_REDUCER,
      payload: {},
    });
    dispatch({
      type: actionNames?.CLEAR_BUFFER_REDUCER,
      payload: {},
    });
    dispatch({
      type: actionNames?.CLEAR_BROKER_HOME_REDUCER,
      payload: {},
    });
    dispatch({
      type: actionNames?.CLEAR_BUILDER_HOME_REDUCER,
      payload: {},
    });
  };
};

export const updateLoaderState = (status) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.AUTH_REDUCER,
      payload: {
        isLoading: status ?? false,
      },
    });
  };
};

export const sendOTP = (payload, loggedInModule, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      payload,
      (response) => {
        console.log("response of send otp ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of send otp ", error);
      }
    );
  };
};

export const resendOTP = (payload, loggedInModule, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      loggedInModule === "broker"
        ? endpoint?.BROKER_RESEND_OTP
        : endpoint?.BUILDER_SEND_OTP,
      payload,
      (response) => {
        console.log("response of resend otp ", response);
        common?.snackBar("OTP resent Successfully");
        callBack(response);
      },
      (error) => {
        console.log("error of resend otp ", error);
      }
    );
  };
};

export const verifyOTP = (payload, loggedInModule, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      loggedInModule === "broker"
        ? endpoint?.BROKER_VERIFY_OTP
        : endpoint?.BUILDER_VERIFY_OTP,
      payload,
      (response) => {
        console.log("response of verify otp ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of verify otp ", error);
      }
    );
  };
};

export const registerBroker = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.REGISTER_BROKER,
      payload,
      (response) => {
        console.log("response of register broker ", response);
        const { name, phoneNumber, token, _id, referalCode, status } =
          response?.data?.data;
        dispatch({
          type: actionNames?.AUTH_REDUCER,
          payload: {
            name,
            phoneNumber,
            token,
            _id,
            referalCode,
            status,
          },
        });
        if (token?.length) common?.setAuthorizationToken(token);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of register broker", error);
      }
    );
  };
};

export const getAllPropertiesForBroker = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PROPERTIES,
      payload,
      (response) => {
        console.log("response of get all property for broker ", response);
        dispatch({
          type: actionNames?.BROKER_HOME_REDUCER,
          payload: payload?.isPromoted
            ? {
                allPromotedProperties: response?.data?.data,
              }
            : payload?.isBestSelling
            ? {
                allBestSellingProperties: response?.data?.data,
              }
            : payload?.propertyType?.length
            ? {
                allSimilarProperties: response?.data?.data,
              }
            : payload?.isRecommended
            ? {
                allRecommendedProperties: response?.data?.data,
              }
            : payload?.propertyType?.length
            ? {
                currentCategoryProperties: response?.data?.data,
              }
            : {
                allProperties: response?.data?.data,
              },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property for broker ", error);
      }
    );
  };
};

export const getAllPropertiesForBuilder = (payload, callBack, isSearchable) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PROPERTIES,
      payload,
      (response) => {
        console.log("response of getting all Property ", response);
        if (!isSearchable) {
          let chartData = response?.data?.data
            ?.filter((item) => !item?.isPropertySold)
            ?.map((item) => item?.currentlyComparing?.[0])
            ?.map((item) => ({
              data: [
                item?.last4Year ?? "0",
                item?.last3Year ?? "0",
                item?.last2Year ?? "0",
                item?.last1Year ?? "0",
                item?.currentPrice ?? "0",
              ],
              color: () =>
                "#" + Math.floor(Math.random() * 16777215).toString(16),
            }));
          dispatch({
            type: actionNames?.BUILDER_HOME_REDUCER,
            payload: {
              allProperties: response?.data?.data,
              chartData,
            },
          });
        }
        callBack(response?.data);
      },
      (error) => {
        console.log("error while getting Properties ", error);
      }
    );
  };
};

export const getAllPastPropertiesForBuilder = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PAST_PROPERTIES,
      payload,
      (response) => {
        console.log("response of getting all Property ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while getting Properties ", error);
      }
    );
  };
};

export const getVisitAnalytics = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_VISIT_ANALYTICS,
      payload,
      (response) => {
        console.log("response of get visit analytics ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get visit analytics ", error);
      }
    );
  };
};

export const getAllBoughtPropertiesForBuilder = (
  payload,
  callBack,
  isSearchable
) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BOUGHT_PROPERTY,
      payload,
      (response) => {
        console.log("response of get all bought Property ", response);
        if (!isSearchable) {
          dispatch({
            type: actionNames?.BUILDER_HOME_REDUCER,
            payload: {
              allBoughtProperties: response?.data?.data,
            },
          });
        }
        callBack(response?.data);
      },
      (error) => {
        console.log("error while get bought Properties ", error);
      }
    );
  };
};

export const getPropertyAnalytics = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_PROPERTY_ANALYTICS,
      payload,
      (response) => {
        console.log("response of getting Property Analytics ", response);
        dispatch({
          type: actionNames?.BUILDER_HOME_REDUCER,
          payload: {
            propertyAnalytics: response?.data?.data,
          },
        });
        callBack(response?.data);
      },

      (error) => {
        console.log("error while getting Property Analytics ", error);
      }
    );
  };
};

export const registerbuilder = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.REGISTER_BUILDER,
      payload,
      (response) => {
        console.log("response of sharing the builder intent ", response);
        const {
          token,
          email,
          gst,
          locationOfProperty,
          name,
          panOfCompany,
          phoneNumber,
          profilePictureUrl,
          projectName,
          rating,
          referalCode,
          status,
          termAndCondition,
          typeOfProperty,
          _id,
        } = response?.data?.data;
        dispatch({
          type: actionNames?.AUTH_REDUCER,
          payload: {
            _id,
            email,
            gst,
            locationOfProperty,
            name,
            panOfCompany,
            phoneNumber,
            profilePictureUrl,
            projectName,
            rating,
            referalCode,
            status,
            termAndCondition,
            typeOfProperty,
            token,
          },
        });
        if (token?.length) common?.setAuthorizationToken(token);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while sharing the builder intent ", error);
      }
    );
  };
};

export const getBuilderDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_BUILDER_DETAILS,
      payload,
      (response) => {
        console.log("response of Getiing Builder Details", response);
        dispatch({
          type: actionNames?.USER_REDUCER,
          payload: {
            ...response?.data?.data?.[0],
          },
        });
        callBack(response?.data.data);
      },
      (error) => {
        console.log("error while Getiing Builder Details", error);
      }
    );
  };
};

export const getBuilderSubscription = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_BUILDER_SUBSCRIPTION,
      payload,
      (response) => {
        console.log("response of get builder subscription ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get builder subscription  ", error);
      }
    );
  };
};

export const updatePropertyStatus = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.DELETE_PROPERTY,
      payload,
      (response) => {
        console.log("response of updating Status of Property ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of updating Status in Property  ", error);
      }
    );
  };
};

export const getAllSoldOutProperties = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SOLD_OUT_PROPERTIES,
      payload,
      (response) => {
        console.log("response of get all sold out properties ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all sold out properties  ", error);
      }
    );
  };
};

export const getAllCurrentPackage = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_CURRENT_PACKAGE,
      payload,
      (response) => {
        console.log("response of get all current package ", response);
        dispatch({
          type: actionNames.BUILDER_HOME_REDUCER,
          payload: {
            allCurrentPackage: response?.data?.data,
          },
        });
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all current package  ", error);
      }
    );
  };
};

export const UpdateBuilderDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_BUILDER,
      payload,
      (response) => {
        console.log("response of Update builder details", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of Update builder details", error);
      }
    );
  };
};

export const getBrokerDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_BROKER_DETAILS,
      payload,
      (response) => {
        console.log("response of get broker details ", response);
        dispatch({
          type: actionNames?.USER_REDUCER,
          payload: {
            ...response?.data?.data?.[0],
          },
        });
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get broker details ", error);
      }
    );
  };
};

export const getNotifications = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_NOTIFICATIONS,
      payload,
      (response) => {
        console.log("response of get notification ", response);
        dispatch({
          type: actionNames?.NOTIFICATION_REDUCER,
          payload: {
            notifications: response?.data?.data ?? [],
          },
        });
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get notification", error);
      }
    );
  };
};

export const UpdateBrokerPreferences = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_PREFERENCES,
      payload,
      (response) => {
        console.log("response of Update broker preferences", response);
        dispatch({
          type: actionNames?.USER_REDUCER,
          payload: {
            ...payload,
          },
        });
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of Update broker preferences", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllRaiseQuery = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_RAISE_QUERY,
      payload,
      (response) => {
        console.log("response of get all raise query ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all raise query ", error);
      }
    );
  };
};

export const getAllSupportAndFaq = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SUPPORT_FAQ,
      payload,
      (response) => {
        console.log("response of get all support and faq", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all support and faq", error);
      }
    );
  };
};

export const updateBrokerEye = (status) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.BROKER_HOME_REDUCER,
      payload: {
        isEyeOpen: status,
      },
    });
  };
};

export const getAllCustomers = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_CUSTOMER,
      payload,
      (response) => {
        console.log("response of get all customer", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all customer", error);
      }
    );
  };
};

export const updateCustomer = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_CUSTOMER,
      payload,
      (response) => {
        console.log("response of update customer", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update customer", error);
      }
    );
  };
};

export const addCustomer = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_CUSTOMER,
      payload,
      (response) => {
        console.log("response of add customer", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add customer", error);
      }
    );
  };
};

export const addPropertyDetails = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_PROPERTY,
      payload,
      (response) => {
        console.log("response of add customer", response);
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllRequirement = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_REQUIREMENT,
      payload,
      (response) => {
        console.log("response of get all requirement", response);
        // dispatch({
        //     type: actionNames?.BUFFER_REDUCER,
        //     payload: {
        //         clientData: response?.data?.data?.[0]?.customerId ?? {},
        //     },
        // });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all requirement", error);
      }
    );
  };
};

export const updateCurrentClient = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.BUFFER_REDUCER,
      payload: {
        clientData: payload ?? {},
      },
    });
  };
};

export const getTermsAndConditions = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_TERMS_AND_CONDITIONS,
      payload,
      (response) => {
        console.log("response of get terms and conditions", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get terms and conditions", error);
      }
    );
  };
};

export const getAllVisit = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_VISIT,
      payload,
      (response) => {
        console.log("response of get all visit", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all visit", error);
        errorCallback(error);
      }
    );
  };
};

export const updateRoles = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_ROLES,
      payload,
      (response) => {
        console.log("response of Update Roles", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of Update Roles", error);
        errorCallback(error);
      }
    );
  };
};

export const getVisitbyId = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_VISIT_BY_ID,
      payload,
      (response) => {
        console.log("response of get visit by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get visit by id", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllBanners = (payload, callBack) => {
  return (dispatch, getState) => {
    const { loggedInModule } = getState()?.authReducer;
    getAPI(
      endpoint?.GET_ALL_BANNERS,
      payload,
      (response) => {
        console.log("response of get all banners", response);
        dispatch({
          type:
            loggedInModule === "broker"
              ? actionNames?.BROKER_HOME_REDUCER
              : actionNames?.BUILDER_HOME_REDUCER,
          payload: {
            allBanners: response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property category", error);
      }
    );
  };
};

export const getVisitsByPropertyId = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_VISIT,
      payload,
      (response) => {
        console.log(
          `response of getting all visits for property id:${payload?.propertyId}`,
          response
        );
        dispatch({
          type: actionNames?.BUILDER_HOME_REDUCER,
          payload: {
            allVisitsByPropertyId: response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all banners", error);
      }
    );
  };
};

export const getAllPromotionBanners = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PROMOTION_BANNERS,
      payload,
      (response) => {
        console.log("response of get all promotion banners", response);
        dispatch({
          type: actionNames?.BROKER_HOME_REDUCER,
          payload: {
            promotionBanners: response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all promotion banners", error);
      }
    );
  };
};

export const getAllProperty = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GETALLPROPERTY,
      payload,
      (response) => {
        console.log("response of get all property", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property");
      }
    );
  };
};

export const getAllPropertyCategory = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PROPERTY_CATEGORY,
      payload,
      (response) => {
        console.log("response of get all property category", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property category", error);
      }
    );
  };
};

export const getPropertyById = (id, payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_PROPERTY_BY_ID + `?id=${id}`,
      payload,
      (response) => {
        console.log("response of get property by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log(`error of property by id `, error);
      }
    );
  };
};

export const sharePropertyBrochure = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.SHARE_BBROCHURE,
      payload,
      (response) => {
        console.log("response of share brochure ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of share brochure ", error);
      }
    );
  };
};

export const addRole = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_ROLE,
      payload,
      (response) => {
        console.log("response of Add Role ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of Add Role ", error);
        errorCallback(error);
      }
    );
  };
};

export const updateBrokerInfo = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_BROKER_PERSONAL_INFO,
      payload,
      (response) => {
        console.log("response of update broker info ", response);
        dispatch({
          type: actionNames?.USER_REDUCER,
          payload: {
            ...response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update broker info ", error);
      }
    );
  };
};

export const getAllInvoicesForBuilder = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_INVOICES_FOR_BUILDER,
      payload,
      (response) => {
        console.log("response of get all invoice : ", response);
        // if (payload.status === 'pending') {
        //     dispatch({
        //         type: actionNames?.BUILDER_HOME_REDUCER,
        //         payload: {
        //             allBuilderPendingInvoices: response?.data?.data
        //         }
        //     })
        //     callBack(response?.data);
        // }
        // else {
        //     dispatch({
        //         type: actionNames?.BUILDER_HOME_REDUCER,
        //         payload: {
        //             allBuilderSettledInvoices: response?.data?.data
        //         }
        //     })
        //     callBack(response?.data);
        // }
        callBack(response?.data);
      },
      (error) => {
        console.log("Error Occured While getting All Invoices", error);
      }
    );
  };
};
export const updateBrokerBankInfo = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_BROKER_BANK_INFO,
      payload,
      (response) => {
        console.log("response of update broker bank info ", response);
        dispatch({
          type: actionNames?.USER_REDUCER,
          payload: {
            ...response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update broker bank info ", error);
      }
    );
  };
};

export const getAllEligibleClaims = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_ELIGIBLE_CLAIMS,
      payload,
      (response) => {
        console.log("response of get all eligile claims", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all eligile claims", error);
      }
    );
  };
};

export const getAllClaims = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_CLAIMS,
      payload,
      (response) => {
        console.log("response of get all claims", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all claims", error);
      }
    );
  };
};

export const getAllLoanQueries = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_LOAN_QUERIES,
      payload,
      (response) => {
        console.log("response of get all loan queries", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all loan queries", error);
      }
    );
  };
};

export const getAllFloorPlansAndPricing = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_FLOOR_PLAN_AND_PRICING,
      payload,
      (response) => {
        console.log("response of get all floor plans and pricing", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all floor plans and pricing", error);
      }
    );
  };
};

export const updateVisitSummary = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_VISIT_SUMMARY,
      payload,
      (response) => {
        console.log("response of update visit summary", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update visit summary", error);
      }
    );
  };
};

export const addVisitComment = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.ADD_VISIT_COMMENT,
      payload,
      (response) => {
        console.log("response of add visit comment", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add visit comment", error);
      }
    );
  };
};

export const getAllBrokerEarnings = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BROKER_EARNINGS,
      payload,
      (response) => {
        console.log("response of get all broker earnings", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all broker earnings", error);
      }
    );
  };
};

export const getDisabledTime = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_DISABLED_TIME,
      payload,
      (response) => {
        console.log("response of get disabled time", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get disabled time", error);
      }
    );
  };
};

export const brokerBookVisit = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_VISIT,
      payload,
      (response) => {
        console.log("response of book visit", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of book visit", error);
      }
    );
  };
};

export const getBrokerEarningsByBrokerId = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BROKER_EARNING_BY_BROKER_ID,
      payload,
      (response) => {
        console.log("response of get broker earnings by broker id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get broker earnings by broker id", error);
      }
    );
  };
};

export const addNewRequirement = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_REQUIREMENT,
      payload,
      (response) => {
        console.log("response of add requirement", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add requirement", error);
      }
    );
  };
};

export const getAllLocationsAndCountryCodes = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_LOCATION_AND_COUNTRY_CODES,
      payload,
      (response) => {
        console.log("response of get location and country codes", response);
        dispatch({
          type: actionNames?.MASTER_REDUCER,
          payload: {
            preferredLocations: response?.data?.data?.city ?? [],
            countries: response?.data?.data?.country ?? [],
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get location and country codes", error);
      }
    );
  };
};

export const addBoughtProperty = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_BOUGHT_PROPERTY,
      payload,
      (response) => {
        console.log("response of add bought property", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add bought property", error);
      }
    );
  };
};

export const updateClaimStatus = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_CLAIM_STATUS,
      payload,
      (response) => {
        console.log("response of update claim status", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update claim status", error);
      }
    );
  };
};

export const addLoanQueryDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_LOAN_QUERIES,
      payload,
      (response) => {
        console.log("response of add loan queries details", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add loan queries details", error);
      }
    );
  };
};

export const addVisitClaim = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_VISIT_CLAIM,
      payload,
      (response) => {
        console.log("response of add visit claim", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add visit claim", error);
      }
    );
  };
};

export const bookVisitVerifyOtp = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.BOOK_VISIT_VERIFY_OTP,
      payload,
      (response) => {
        console.log("response of book visit verify otp", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of book visit verify otp", error);
      }
    );
  };
};

export const getAllVisitAlert = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_VISIT_ALERT,
      payload,
      (response) => {
        console.log("response of get all visit alert", response);
        dispatch({
          type: actionNames?.BROKER_HOME_REDUCER,
          payload: {
            allVisitAlerts: response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all visit alert", error);
      }
    );
  };
};

export const getAllPayment = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PAYMENT,
      payload,
      (response) => {
        console.log("response of get all payment", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all payment", error);
      }
    );
  };
};

export const getPendingInvoice = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_PENDING_INVOICE,
      payload,
      (response) => {
        console.log("response of get pending invoice", response);
        dispatch({
          type: actionNames?.BUILDER_HOME_REDUCER,
          payload: {
            allPendingInvoice: response?.data?.data,
          },
        });
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get pending invoice", error);
      }
    );
  };
};

export const getAllRoles = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_ROLES,
      payload,
      (response) => {
        console.log("response of get all roles", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all roles", error);
      }
    );
  };
};

export const addRequestProperty = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_REQUEST_PROPERTY,
      payload,
      (response) => {
        console.log("response of add request property ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of add request property ", error);
      }
    );
  };
};

export const getAllRequestPropertybyId = (id, callBack, errorCallback) => {
   console.log(id)
  return (dispatch, getState) => {
    getAPI(
      `${endpoint?.GET_ALL_REQUEST_PROPERTY}?builderId=${id}`,
      {},
      (response) => {
        console.log("response of get visit by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get visit by id", error);
        errorCallback(error);
      }
    );
  };
};



export const updateRequestProperty = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_REQUEST_PROPERTY,
      payload,
      (response) => {
        console.log("response of update request property ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of update request property ", error);
      }
    );
  };
};

export const getAllBanks = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BANK,
      payload,
      (response) => {
        console.log("response of get all banks ", response);
        dispatch({
          type: actionNames?.MASTER_REDUCER,
          payload: {
            allBanks: response?.data?.data ?? [],
          },
        });
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all banks ", error);
      }
    );
  };
};

export const requestNewProperty = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.REQUEST_NEW_PROPERTY,
      payload,
      (response) => {
        console.log("response request new property ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of request new property ", error);
      }
    );
  };
};

export const addRaiseDispute = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_RAISE_DISPUTE,
      payload,
      (response) => {
        console.log("response add raise dispute ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of add raise dispute ", error);
      }
    );
  };
};

export const uploadDocToS3 = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.UPLOAD_DOC,
      payload,
      (response) => {
        console.log("response of upload doc to S3", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of upload doc to S3", error);
        errorCallback(error);
      }
    );
  };
};

export const updateCurrentManager = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.BUFFER_REDUCER,
      payload: {
        managerData: payload ?? {},
      },
    });
  };
};

export const getAllDisputeReasons = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_DISPUTE_REASONS,
      payload,
      (response) => {
        console.log("response of get all dispute reasons", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all dispute reasons", error);
      }
    );
  };
};

export const getAllPriceRange = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PRICE_RANGE,
      payload,
      (response) => {
        console.log("response of get all price range", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all price range", error);
      }
    );
  };
};

export const updateSearchText = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.BUFFER_REDUCER,
      payload: {
        searchedText: payload ?? {},
      },
    });
  };
};

export const updateCurrentInvoice = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.BUFFER_REDUCER,
      payload: {
        invoiceData: payload ?? {},
      },
    });
  };
};

export const getPropertiesByFilter = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.PROPERTY_FILTER,
      payload,
      (response) => {
        console.log("response of get all properties by filter", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all properties by filter", error);
      }
    );
  };
};

export const getAllBuilders = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BUILDERS,
      payload,
      (response) => {
        console.log("response builder by count", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all builders", error);
      }
    );
  };
};

export const updateFilter = (payload) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.FILTER_REDUCER,
      payload: {
        filterData: payload ?? {},
      },
    });
  };
};

export const clearFilter = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionNames?.CLEAR_FILTER_REDUCER,
      payload: {},
    });
  };
};

export const getAllPastProperties = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PAST_PROPERTIES,
      payload,
      (response) => {
        console.log("response of get all past properties", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all past properties", error);
      }
    );
  };
};

export const addPastPropertyForBuilder = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_BUILDER_PAST_PROPERTIES,
      payload,
      (response) => {
        console.log("response of addPast Property", response?.data);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add Past Property", error);
        errorCallback(error);
        //
      }
    );
  };
};

export const addBuilder = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_BUILDER,
      payload,
      (response) => {
        console.log("response of upload doc to S3", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of upload doc to S3", error);
        errorCallback(error);
        //
      }
    );
  };
};

export const getAllpropertySubCategory = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_PROPERTY_SUB_CATEGORY,
      payload,
      (response) => {
        console.log("response of upload doc to S3", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of upload doc to S3", error);
        //
      }
    );
  };
};

export const getAllpropertySubCategorys = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SUB_COMPANIES,
      payload,
      (response) => {
        console.log("response of all subCompany", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of all subCompany", error);
        //
      }
    );
  };
};

export const getAllSpecification = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SPECIFICATIONS,
      payload,
      (response) => {
        console.log("response of all specifications", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of all specifications", error);
        //
      }
    );
  };
};

export const addFloorPlanInProperty = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.Add_FLOOR_PLAN_IN_PROPERTY,
      payload,
      (response) => {
        console.log("response of add floor plan", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add floor plan", error);
      }
    );
  };
};

export const updateBrokerageDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_BROKERAGE,
      payload,
      (response) => {
        console.log("response of update brokerage details", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update brokerage details", error);
        //
      }
    );
  };
};

export const updateBuilderStatus = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.DELETE_BUILDERS,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const deleteBuilderPastPropertyById = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.DELETE_PAST_PROPERTY_BY_BUILDER,
      payload,
      (response) => {
        console.log("response of deleting  of Past Property ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of deleting  in Past Property  ", error);
      }
    );
  };
};

export const getAllBroker = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BROKERS,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const addsubcompany = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_SUBCOMPANY,
      payload,
      (response) => {
        console.log("response of adding sub company ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of adding sub company ", error);
      }
    );
  };
};

export const deleteSubCompany = (id, payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      `${endpoint?.DELETE_SUBCOMAPNIES}/${id}`,
      payload,
      (response) => {
        console.log("response of delete sub company ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of delete sub company ", error);
      }
    );
  };
};

export const updateSubCompany = (id, payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      `${endpoint?.UPDATE_SUBCOMPANY}/${id}`,
      payload,
      (response) => {
        console.log("response of delete sub company ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of delete sub company ", error);
      }
    );
  };
};

export const addPropertySubType = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_SUB_PROPERTY_TYPE,
      payload,
      (response) => {
        console.log("response of delete sub company ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of delete sub company ", error);
      }
    );
  };
};

export const updatePropertySubType = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_SUB_PROPERTY_TYPE,
      payload,
      (response) => {
        console.log("response of update sub company ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of update sub company ", error);
        errorCallback(error);
      }
    );
  };
};

export const addNewBroker = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_BROKER,
      payload,
      (response) => {
        console.log("response of register broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of register broker", error);
      }
    );
  };
};

export const updatePropertyDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_PROPERTY_DETAILS,
      payload,
      (response) => {
        console.log("response of register broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of register broker", error);
      }
    );
  };
};

export const updatePropertyAvailability = (id, payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_PROPERTY_AVAILABILITY.replace("{0}", id),
      payload,
      (response) => {
        console.log("response of register broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of register broker", error);
      }
    );
  };
};

export const updateFloorPlanAndPricing = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_FLOOR_PLAN_PRICING,
      payload,
      (response) => {
        console.log("response of register broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of register broker", error);
      }
    );
  };
};

export const deleteFloorPlanAndPricing = (
  floorPlanId,
  mainId,
  payload,
  callBack
) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_FLOOR_PLAN_PRICING.replace("{0}", mainId).replace(
        "{1}",
        floorPlanId
      ),
      payload,
      (response) => {
        console.log("response of register broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of register broker", error);
      }
    );
  };
};

export const getPropertyCategoryById = (id, payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_PROPERTY_CATEGORY_BY_ID.replace("{0}", id),
      payload,
      (response) => {
        console.log("response of get property by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log(`error of property by id `, error);
      }
    );
  };
};

export const updateBrokerStatus = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.DELETE_BROKER,
      payload,
      (response) => {
        console.log("response of updating Status of Broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of updating Status in Broker  ", error);
      }
    );
  };
};

export const getAllFurnishingDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_FURNISHING_DETAILS,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const getFurnishingDetailsByID = (ID, payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_FURNISHING_DETAIL_BY_ID.replace("{0}", ID),
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const addFurnishingDetail = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_FURNISHING_DETAIL,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const deleteFurnishingDetail = (ID, payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_FURNISHING_DETAIL.replace("{0}", ID),
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const updateFurnishingDetail = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_FURNISHING_DETAIL_BY_ID,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const deleteSubCategory = (ID, payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_SUB_CATEGORY.replace("{0}", ID),
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const getAllSubCategory = (ID, payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SUBCATEGORIS.replace("{0}", ID),
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const addLocationAdvantages = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_LOCATION_ADVANTAGES,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const getAllLocationAdvantages = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_LOCATION_ADVANTAGES,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const deleteLocationAdvantage = (ID, payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_LOCATION_ADVANTAGES.replace("{0}", ID),
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const updateLocationAdvantage = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_LOCATION_ADVANTAGES,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const getAllAmenties = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_AMENITIES,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const addAmenties = (payload, callback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_AMENTIES,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const deleteAmenities = (ID, payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_AMENTIES.replace("{0}", ID),
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const updateAmenities = (payload, callback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_AMENTIES,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const updatePastProperties = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_PAST_PROPERTY,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const getAllIntentsForBuilder = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_INTENTS,
      payload,
      (response) => {
        console.log("response of get all property for broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property for broker ", error);
      }
    );
  };
};

export const updateClaimByAdmin = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_CLAIM_FOR_ADMIN,
      payload,
      (response) => {
        console.log("response of update claim status By Admin", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update claim status By Admin", error);
        errorCallback(error);
      }
    );
  };
};

export const addSpecification = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_SPECIFICATIONS,
      payload,
      (response) => {
        console.log("response of updating Status of Builder ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in Builder  ", error);
      }
    );
  };
};

export const getAllSpecificationDetails = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SPECIFICATIONS,
      payload,
      (response) => {
        console.log("response of updating Status of specifications ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in specifications  ", error);
      }
    );
  };
};

export const getAllNearByArea = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_NEAR_BY_AREA,
      payload,
      (response) => {
        console.log("response of get all property for broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property for broker ", error);
      }
    );
  };
};

export const addNearByArea = (payload, callback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_NEAR_BY_AREA,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const deleteSpecificationDetail = (payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_SPECIFICATIONS,
      payload,
      (response) => {
        console.log("response of updating Status of specifications ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in specifications  ", error);
      }
    );
  };
};

export const deleteNearByArea = (payload, callback) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_NEAR_BY_AREA,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const updateSpecificationDetail = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_SPECIFICATIONS_BY_ID,
      payload,
      (response) => {
        console.log("response of updating Status of specifications ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of updating Status in specifications  ", error);
      }
    );
  };
};

export const updateNearByArea = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_NEAR_BY_AREA,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const getAllLoanApproval = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_LOAN_APPROVAL,
      payload,
      (response) => {
        console.log("response of get all property for broker ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all property for broker ", error);
      }
    );
  };
};

export const deleteLoanApproval = (payload, callback) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_LOAN_APPROVAL,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const addLoanApproval = (payload, callback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_LOAN_APPROVAL,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const updateLoanApproval = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_LOAN_APPROVAL,
      payload,
      (response) => {
        console.log("response of get all amenities ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of get all amenities ", error);
      }
    );
  };
};

export const getLocationByInput = (payload) => {
  try {
    const response = axios.get(
      baseURL + "v1/globals/search-location",
      {
        params: {
          searchQuery: payload.searchText,
        },
      }
    );

    return response;
  } catch (error) {
    console.log("Error fetching suggestions:", error);
    throw error;
  }
};

export const getAllVisits = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_VISITS,
      payload,
      (response) => {
        console.log("response of all visits", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of all specifications", error);
        //
      }
    );
  };
};

export const updateIntentStatus = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_INTENT_STATUS,
      payload,
      (response) => {
        console.log("response of updating Status of Intent ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of updating Status in Intent  ", error);
      }
    );
  };
};

export const addBlog = (payload, callback, errorCallback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_BLOG,
      payload,
      (response) => {
        console.log("response of add blog ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error while adding blog ", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllBlog = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BLOG_ADMIN,
      payload,
      (response) => {
        console.log("response of all blogs", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while fetching all blogs", error);
        errorCallback(error);
        //
      }
    );
  };
};

export const deleteBlog = (payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_BLOG,
      payload,
      (response) => {
        console.log("response of delete blog", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error of delete blog", error);
      }
    );
  };
};

export const getAllBlogClient = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_BLOG,
      payload,
      (response) => {
        console.log("response of all blogs", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while fetching all blogs", error);
        //
      }
    );
  };
};

export const getBlogById = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_BLOG_BY_ID.replace("{0}", payload.id),
      {},
      (response) => {
        console.log("response of blog by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while fetching blog by id", error);
        //
      }
    );
  };
};

export const updateBlog = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_BLOG,
      payload,
      (response) => {
        console.log("response of update blog ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error while updating blog ", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllTutorial = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_TUTORIAL,
      payload,
      (response) => {
        console.log("response of all tutorials", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while fetching all tutorials", error);
      }
    );
  };
};

export const addTutotial = (payload, callback) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_TUTORIAL,
      payload,
      (response) => {
        console.log("response of add tutorial ", response);
        callback(response?.data?.data);
      },
      (error) => {
        console.log("error while adding tutorial ", error);
      }
    );
  };
};

export const getTutorialById = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_TUTORIAL_BY_ID.replace("{0}", payload.id),
      {},
      (response) => {
        console.log("response of tutorial by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error while fetching tutorial by id", error);
        //
      }
    );
  };
};

export const updateTutorial = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_TUTORIAL,
      payload,
      (response) => {
        console.log("response of update tutorial ", response);
        callBack(response?.data?.data);
      },
      (error) => {
        console.log("error while updating tutorial ", error);
      }
    );
  };
};

export const getConsumerByClientId = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_CONSUMERS_CLIENT_ID,
      payload,
      (response) => {
        console.log("response of get clients by broker id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get clients by broker id", error);
      }
    );
  };
};

export const getClientByID = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_CLIENT_DETAIL_BY_ID,
      payload,
      (response) => {
        console.log("response of get client by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get client by id", error);
      }
    );
  };
};

export const getClaimById = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_CLAIMS_BY_ID,
      payload,
      (response) => {
        console.log("response of get claim by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get claim by id", error);
      }
    );
  };
};

export const updateVisitById = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_VISIT_BY_ID,
      payload,
      (response) => {
        console.log("response of update visit by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update visit by id", error);
        errorCallback(error);
      }
    );
  };
};

export const getAllreferals = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_REFERALS,
      payload,
      (response) => {
        console.log("response of get referals", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get referals", error);
        errorCallback(error);
      }
    );
  };
};

export const updateReferalById = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_REFERAL_BY_ID,
      payload,
      (response) => {
        console.log("response of update referal by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update referal by id", error);
      }
    );
  };
};
export const getAllBoughtPropertyByBrokerId = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SOLD_PROPERTY_BY_BROKER_ID,
      payload,
      (response) => {
        console.log("response of get bought propert by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get bought property by id", error);
      }
    );
  };
};

export const addSubscription = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_SUBSCRIPTION,
      payload,
      (response) => {
        console.log("response of add subsription", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add subsription", error);
      }
    );
  };
};

export const getAllSubscription = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_SUBSCRIPTION,
      payload,
      (response) => {
        console.log("response of all subscription", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get all subscription", error);
      }
    );
  };
};

export const deleteSubscription = (payload, callBack) => {
  return (dispatch, getState) => {
    deleteAPI(
      endpoint?.DELETE_SUBSCRIPTION,
      payload,
      (response) => {
        console.log("response of deleting subscription ", response);
        callBack(response);
      },
      (error) => {
        console.log("error of deleting subscription  ", error);
      }
    );
  };
};

export const getSubscriptionById = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_SUBSCRIPTION_BY_ID,
      payload,
      (response) => {
        console.log("response of get subscription by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get subscription by id", error);
      }
    );
  };
};

export const updateSubscription = (id, payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_SUBSCRIPTION + "?id=" + id,
      payload,
      (response) => {
        console.log("response of updating Subscription", response);
        callBack(response);
      },
      (error) => {
        console.log("error of updating Subscription  ", error);
      }
    );
  };
};

export const getSubscriber = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_SUBSCRIBERS_BY_SUBSCRIPTION_ID,
      payload,
      (response) => {
        console.log("response of get subscribers by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get subscribers by id", error);
      }
    );
  };
};

export const getAllQueries = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_QUERIES,
      payload,
      (response) => {
        console.log("response of get queries", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get queries", error);
      }
    );
  };
};

export const updateQueries = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_QUERIES,
      payload,
      (response) => {
        console.log("response of updating queries", response);
        callBack(response);
      },
      (error) => {
        console.log("error of updating queries  ", error);
      }
    );
  };
};

export const getAllFaq = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_FAQ,
      payload,
      (response) => {
        console.log("response of get FAQ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get FAQ", error);
      }
    );
  };
};

export const updateFaq = (payload, callBack) => {
  return (dispatch, getState) => {
    putAPI(
      endpoint?.UPDATE_FAQ,
      payload,
      (response) => {
        console.log("response of updating queries", response);
        callBack(response);
      },
      (error) => {
        console.log("error of updating queries  ", error);
      }
    );
  };
};

export const getFaqById = (id, payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_FAQ_BY_ID + "?id=" + id,
      payload,
      (response) => {
        console.log("response of updating Subscription", response);
        callBack(response);
      },
      (error) => {
        console.log("error of updating Subscription  ", error);
      }
    );
  };
};

export const addFaq = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_FAQ,
      payload,
      (response) => {
        console.log("response of add subsription", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add subsription", error);
      }
    );
  };
};

export const getAllDsa = (payload, callBack) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_DSA,
      payload,
      (response) => {
        console.log("response of get FAQ", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get FAQ", error);
      }
    );
  };
};

export const addDsa = (payload, callBack) => {
  return (dispatch, getState) => {
    postAPI(
      endpoint?.ADD_DSA,
      payload,
      (response) => {
        console.log("response of add subsription", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add subsription", error);
      }
    );
  };
};

export const getAllContactUs = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_US_CONTACT_US,
      payload,
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const createInvoice = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoint?.ADD_INVOICE,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateInvoice = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    putAPI(
      endpoint?.UPDATE_INVOICE,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const addPayment = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoint?.ADD_PAYMENT,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getAllBuilders1 = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_GLOBAL_BUILDER,
      payload,
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getInvoicePDFByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_INVOICE_PDF,
      { id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getClaimPDFByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_CLIAM_PDF,
      { id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const getPaymentPDFByID = (id, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_PAYMENT_PDF,
      { claimId: id },
      (response) => {
        callBack(response?.data);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const updateContactStatus = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    putAPI(
      endpoint?.UPDATE_CONTACT_STATUS,
      payload,
      (response) => {
        callBack(response);
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
};

export const deleteRoleById = (id, callBack, errorCallback) => {
  return (dispatch) => {
    deleteAPI(
      endpoint?.DELETE_ROLE,
      { id },
      (success) => {
        callBack(success);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const addEmployee = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoint?.ADMIN_EMPLOYEE,
      payload,
      (success) => {
        callBack(success);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const getAllEmployees = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    getAPI(
      endpoint?.ADMIN_EMPLOYEE,
      payload,
      (success) => {
        callBack(success);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const deleteEmployeeById = (id, callBack, errorCallback) => {
  return (dispatch) => {
    deleteAPI(
      endpoint?.ADMIN_EMPLOYEE + "/" + id,
      {},
      (success) => {
        callBack(success);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const updateEmployee = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    putAPI(
      endpoint?.ADMIN_EMPLOYEE,
      payload,
      (success) => {
        callBack(success);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const deleteQNAById = (id, callBack, errorCallback) => {
  return (dispatch) => {
    deleteAPI(
      endpoint?.DELETE_QNA_BY_ID + "?id=" + id,
      {},
      (success) => {
        callBack(success);
      },
      (err) => {
        errorCallback(err);
      }
    );
  };
};

export const getAllreferalsPayment = (payload, callBack, errorCallback) => {
  return (dispatch, getState) => {
    getAPI(
      endpoint?.GET_ALL_REFERAL_PAYMENT,
      payload,
      (response) => {
        console.log("response of get referal payments", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of get referal payments", error);
        errorCallback(error)
      }
    );
  };
};

export const updateReferalPayment = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    patchApi(
      endpoint?.UPDATE_REFERAL_PAYMENT,
      payload,
      (response) => {
        console.log("response of update referal payment by id", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of update referal payment by id", error);
        errorCallback(error);
      }
    );
  };
};

export const addReferPayment = (payload, callBack, errorCallback) => {
  return (dispatch) => {
    postAPI(
      endpoint?.ADD_REFERAL_PAYMENT,
      payload,
      (response) => {
        console.log("response of add referal payment", response);
        callBack(response?.data);
      },
      (error) => {
        console.log("error of add referal payment", error);
        errorCallback(error);
      }
    )
  }
}