import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,

    Divider,

    FormControlLabel,

    FormGroup,

    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

export default function AddPromotionModal({ addRemarkAction, setAddRemarkAction }) {

    const [remark, setRemark] = useState("")
    const [uploadedImage, setUploadedImage] = useState(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            setUploadedImage(e.target.result);
        };

        reader.readAsDataURL(file);
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(remark)
    }

    const closeHandler = () => setAddRemarkAction(false);
    const ButtonStyle = {
        maxWidth: 150,
        boxShadow: 0,
        width: '100%',
        marginTop: 4,
        border: '1px solid #278FD9',
        mx: 3
    };
    return (
        <Dialog
            open={addRemarkAction}
            onClose={closeHandler}
            maxWidth="md"
            fullWidth
        >
            <DialogContent sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                >
                    <div>
                        <Typography variant="title" component="h3">
                            Add Promotion
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            sx={{ border: '2px solid #DFDFE6' }}
                            size="small"
                            onClick={closeHandler}
                        >
                            <Close sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </div>
                </Stack>
                <Box component={'from'} flexGrow={1}>
                    <form onSubmit={handleSubmit}>
                        <Box
                            mx={5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                border: '1px solid #d2d2d2',
                                borderRadius: '10px',
                                height: '10rem',
                                width: '10rem'
                            }}
                        >
                            <input
                                accept="image/*"
                                id="upload-input"
                                type="file"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                            {!uploadedImage && (
                                <label htmlFor="upload-input">
                                    <IconButton component="span" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <PhotoCameraIcon />
                                        <Typography variant="subtitle1" color="textSecondary" mt={2}>
                                            Upload banner image
                                        </Typography>
                                    </IconButton>
                                </label>
                            )}

                            {uploadedImage && (
                                <Box sx={{ position: 'relative', width: '100%', height: '100%', }}>
                                    <img
                                        src={uploadedImage}
                                        alt="Uploaded Image"
                                        style={{ width: '10rem', height: '10rem', objectFit: 'cover' }}
                                    />
                                </Box>
                            )}

                        </Box>

                        <Divider sx={{ color: '#d2d2d2', my: 3 }} />

                        <Box className="style-1 filter p-1">
                            <Typography className='text1Bold' color="textSecondary" mt={2}>
                                Upload banner image
                            </Typography>
                            <select style={{width:'40%'}}>
                                <option value="All" selected>
                                    All
                                </option>
                                <option value="option 1">option 1</option>
                            </select>
                        </Box>


                        <Button variant="contained" sx={ButtonStyle} type='submit'>
                            Update
                        </Button>

                        <Button
                            mx={3}
                            sx={ButtonStyle}
                        >
                            Reset
                        </Button>
                    </form>
                </Box>
            </DialogContent >
        </Dialog >
    );
}
