import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../assets/Layout";
import { Box, Icon, Paper, Stack, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { selectBar, selectBarBtn } from '../Style/stylesheet';
import FloorPlanForm from "../components/PropertyManagement/FloorPlanForm";


const FloorPlan = () => {
    const params = useParams();
    const { id } = params;
    return (
        <>
            <Layout path="addproperty">
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontSize: '28px',
                        color: '#000000',
                        marginTop: '48px',
                        marginBottom: '48px',
                    }}
                >
                    Add Property
                </Typography>
                <Paper sx={{ borderRadius: '20px', pt: 4 }}>
                    <Stack direction={'row'} sx={selectBar} spacing={3} className="col-3">
                        <Box
                            sx={selectBarBtn}
                            className={`done box`}
                        >
                            <Icon className="icon">{<Check />}</Icon>
                            Property Description
                        </Box>
                        <Box
                            sx={selectBarBtn}
                            className={`active box`}
                        >
                            <Icon className="icon">2</Icon>
                            Floor Plan
                        </Box>
                        <Box
                            sx={selectBarBtn}
                            className={`box`}
                        >
                            <Icon className="icon">3</Icon>
                            Brokerage
                        </Box>
                    </Stack>
                    <Box sx={{ px: 3 }}>
                        <FloorPlanForm propertyID={id} />
                    </Box>
                </Paper>
            </Layout>
        </>
    )
}

export default FloorPlan;