import {
  Box,
  Button,
  Checkbox,
  Icon,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "../../assets/Layout";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import SearchFilter from "../../assets/SearchFilter";
import { useEffect, useState } from "react";
import { selectBarBtn, selectBar, sectionBarBtn } from "../../Style/stylesheet";
import { CustomSelect } from "../CustomInput";
import CustomDialog from "../CustomDialog";
import { useNavigate } from "react-router-dom";
import { getAllreferalsPayment } from "../../actions";
import { useDispatch } from "react-redux";

export default function PaidReferPayments() {
  const [currentPage, setCurrentPage] = useState(1);
  const [section, setSection] = useState("pending");
  const [openModal, setOpenModal] = useState(false);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [sectionBarActive, setSectionBarActive] = useState("PaidReferals");
  const [searchQuery, setSearchQuery] = useState("");
  const [allReferalPayments, setAllReferalPayments] = useState([]);
  const [referalPayments, setReferalPayments] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllreferalsPayment(
        { status: "paid" },
        (data) => {
          const filter = data.data.filter((item) => {
            // by id
            if (item?.status?.includes(searchQuery?.toLocaleLowerCase())) {
              return true;
            }
            if (
              item?.BrokerId?._id
                .toLowerCase()
                .includes(searchQuery?.toLowerCase())
            ) {
              return true;
            }
            if (
              item?.refererId?._id
                .toLowerCase()
                .includes(searchQuery?.toLowerCase())
            ) {
              return true;
            }
            if (item?.amount.toString().includes(searchQuery?.toLowerCase())) {
              return true;
            }
            if(item?.date?.includes(new Date(searchQuery?.toLowerCase()).toLocaleDateString())){
              return true;
            }
          });
          if (searchQuery) {
            setReferalPayments(filter);
          }
          setAllReferalPayments(data.data);
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [searchQuery]);

  useEffect(() => {
    dispatch(
      getAllreferalsPayment(
        { pageNo: currentPage, pageSize: countPerPage, status: "paid" },
        (data) => {
          if (searchQuery == "") {
            setReferalPayments(data.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }, [countPerPage, currentPage, searchQuery]);
  return (
    <Layout path={"refer"}>
      <Box>
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Broker Management : Refer Payments" />
        </Stack>

        <Box>
          <Stack
            direction={"row"}
            sx={{
              p: 1,
              bgcolor: "#fff",
              my: 5,
              borderRadius: 10,
              justifyContent: "center",
              mx: 20,
            }}
          >
            <Button
              sx={sectionBarBtn}
              className={sectionBarActive === "PendingReferals" && "active"}
              onClick={() => navigate("/broker-management/refer-payments")}
            >
              Pending
            </Button>
            <Button
              sx={sectionBarBtn}
              className={sectionBarActive === "PaidReferals" && "active"}
              onClick={() => navigate("/broker-management/refer-payments/paid")}
            >
              Paid
            </Button>
          </Stack>
        </Box>

        <SearchFilter>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </Box>
          {/* <Box className="style-1 filter p-1">
              <select>
                <option value="All" selected>
                  All
                </option>
                <option value="option 1">option 1</option>
              </select>
            </Box>
            <Box className="style-1">
              <input type="date" placeholder="Choose Date" />
            </Box> */}
        </SearchFilter>

        <Box sx={{ mb: 4 }}></Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                      <Checkbox />
                    </TableCell> */}
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ bgcolor: "#f9fafc" }}>
                {/* <TableCell align="left">
                    <Checkbox />
                  </TableCell> */}
                {referalPayments.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>₹ {item?.amount}</TableCell>
                    <TableCell>{item?.date}</TableCell>
                    <TableCell>{item?.refererId?._id}</TableCell>
                    <TableCell>{item?.BrokerId?._id}</TableCell>
                    <TableCell>{item?.transactionId}</TableCell>
                    <TableCell>{item?.transactionAmount}</TableCell>
                    <TableCell>{item?.trnasactionDate}</TableCell>
                    <TableCell align="left">
                      <CustomSelect
                        options={selectData}
                        value=""
                        onChange={(event) => {
                          if (event.target.value === "paid") {
                            setOpenModal(true);
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allReferalPayments?.length ? allReferalPayments?.length : currentPage * countPerPage} of {allReferalPayments?.length}</Typography> */}
            <Typography>
              {allReferalPayments?.length > 0
                ? (currentPage - 1) * countPerPage + 1
                : 0}{" "}
              -{" "}
              {countPerPage > allReferalPayments?.length
                ? allReferalPayments?.length
                : currentPage * countPerPage > allReferalPayments?.length
                ? allReferalPayments?.length
                : currentPage * countPerPage}{" "}
              of {allReferalPayments?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      currentPage === 1 ||
                      countPerPage > allReferalPayments?.length
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      background:
                        currentPage === 1 ||
                        countPerPage > allReferalPayments?.length
                          ? "#EFEFEF"
                          : "#FFF",
                    }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/
                    {Math.ceil(allReferalPayments?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > allReferalPayments?.length ||
                      currentPage ==
                        Math.ceil(allReferalPayments?.length / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
      <CustomDialog
        open={openModal}
        setOpen={(val) => setOpenModal(val)}
        title="transaction Details"
      >
        <Box sx={{ p: 2 }}>
          <p>Transaction Id</p>
          <TextField fullWidth />
        </Box>
        <Box sx={{ p: 2 }}>
          <p>Transaction Date</p>
          <TextField type="date" fullWidth />
        </Box>
        <Box sx={{ p: 2 }}>
          <p>Transaction Amount</p>
          <TextField type="number" fullWidth />
        </Box>
        <Box sx={{ p: 2 }}>
          <Button variant="contained">Submit</Button>
        </Box>
      </CustomDialog>
    </Layout>
  );
}

const headData = [
  "amount",
  "date",
  "refer to id",
  "refer by id",
  "Transaction ID",
  "Transaction Amount",
  "Transaction Date",
  "status",
];
const selectData = [
  { value: "paid", label: "Paid" },
  { value: "unpaid", label: "Unpaid" },
];
