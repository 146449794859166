import Layout from "../../assets/Layout";
import { VisitsData } from "../../utils/data";
import Rating from "@mui/material/Rating";
import { sectionBarBtn } from "../../Style/stylesheet";

import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Button,
} from "@mui/material";

import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";

import { useCallback, useEffect, useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { getAllVisits, updateVisitById } from "../../actions";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import VisitRemarks from "./VisitRemarks";

const PromotedPendingVisits = () => {
  const [allVisits, setAllVisits] = useState([]);
  const [sectionBarActive, setSectionBarActive] = useState("Visits");
  const [addRemarkAction, setAddRemarkAction] = useState(false);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [remark, setRemark] = useState("");
  const [claimId, setClaimId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visitsPagination, setVisitsPagination] = useState([]);

  // const [value, setValue] = React.useState < number | null > (2);
  const headData = [
    "VISIT ID",
    "BROKER ID",
    "PROPERTY NAME",
    "CLIENT ID",
    "LAST VISIT DATE",
    "Status",
    "Rating",
    "view",
    "Remarks",
  ];
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  const handleSelectChange = (event) => {
    setCountPerPage(event.target.value);
  };

  const handleButtonClick = (id) => {
    navigate(`/visit-details/${id}`);
  };

  // const loadAllvisits = useCallback(() => {
  //     dispatch(getAllVisits({ visitStatus: "pending", pageNo: currentPage, pageSize: countPerPage, isPromoted: true }, (data) => {
  //         setAllVisits(data.data);
  //     }))

  // }, [dispatch])

  const loadAllvisits = useCallback(() => {
    dispatch(
      getAllVisits({ isPromoted: true, activeVisits: true }, (data) => {
        const filter = data.data.filter((item) => {
          if (item?._id?.toLowerCase()?.includes(searchQuery?.toLowerCase())) {
            return true;
          }

          if (
            item?.brokerId?._id
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.propertyName
              ?.toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.customerId?._id
              ?.toLowerCase()
              ?.includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (item?.date?.includes(searchQuery?.toLowerCase())) {
            return true;
          }

          if (item?.visitStatus?.includes(searchQuery?.toLowerCase())) {
            return true;
          }

          return false;
        });
        setVisitsPagination(data.data);
        if (searchQuery) {
          setAllVisits(filter);
        }
      })
    );
  }, [dispatch, searchQuery]);

  useEffect(() => {
    dispatch(
      getAllVisits(
        {
          pageNo: currentPage,
          pageSize: countPerPage,
          isPromoted: true,
          activeVisits: true
        },
        (data) => {
          if (searchQuery == "") {
            setAllVisits(data.data);
          }
        }
      )
    );
  }, [currentPage, countPerPage, searchQuery, addRemarkAction]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      loadAllvisits();
    }
  }, [loadAllvisits, navigate]);

  const updateStatus = (id, status) => {
    let payload = {
      visitId: id,
      visitStatus: status,
    };
    dispatch(
      updateVisitById(payload, () => {
        loadAllvisits();
        enqueueSnackbar("Status updated!", { variant: "success" });
      })
    );
  };

  return (
    <>
      <Layout path="normalVisits">
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Promoted Visits" />
          {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Visits" && "active"}
            onClick={() => navigate("/promoted-pending-visits")}
          >
            Pending/Followup/Negotiate
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Bought" && "active"}
            onClick={() => navigate("/promoted-bought-visits")}
          >
            Bought
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Completed" && "active"}
            onClick={() => navigate("/promoted-completed-visits")}
          >
            Completed
          </Button>
        </Stack>

        <SearchFilter my={2}>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          {/* <Box className="style-1 filter p-1">
                        <select>
                            <option value="All" selected>
                                All
                            </option>
                            <option value="option 1">option 1</option>
                        </select>
                    </Box>
                    <Box className="style-1">
                        <input type="date" placeholder="Choose Date" />
                    </Box> */}
        </SearchFilter>

        <Paper sx={{ width: "100%", overflow: "hidden", my: 2 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allVisits.slice(0, 10).map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                  >
                    {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?._id}</TableCell>
                    <TableCell>{item?.brokerId?._id}</TableCell>
                    <TableCell>{item?.propertyName}</TableCell>
                    <TableCell>{item?.customerId?._id}</TableCell>
                    <TableCell>
                      {item?.date
                        ? new Date(item?.date)?.toLocaleDateString()
                        : "--"}
                    </TableCell>

                    <TableCell>
                      <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                          value={item?.visitStatus}
                          onChange={(e) => {
                            updateStatus(item?._id, e.target.value);
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="">
                            <em>{item.status}</em>
                          </MenuItem>
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"followup"}>Follow Up</MenuItem>
                          <MenuItem value={"bought"}>Bought</MenuItem>
                          <MenuItem value={"completed"}>Completed</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell sx={{ color: "#278FD9" }}>
                      <Rating
                        name="half-rating"
                        readOnly
                        defaultValue={item?.overAllRating}
                        precision={0.5}
                      />
                    </TableCell>
                    <TableCell>
                      <Button onClick={() => handleButtonClick(item?._id)}>
                        View
                      </Button>
                    </TableCell>

                    <TableCell>
                      <Box
                        onClick={() => {
                          setClaimId(item?._id);
                          setAddRemarkAction(true);
                          setRemark(item?.remark);
                        }}
                      >
                        <IconButton size="large" color="primary">
                          <Draw />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
              {(currentPage - 1) * countPerPage + 1} -{" "}
              {countPerPage > allVisits?.length
                ? allVisits?.length
                : currentPage * countPerPage}{" "}
              of {visitsPagination?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select
              style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={
                      currentPage === 1 ||
                      countPerPage > visitsPagination?.length
                    }
                    sx={{ border: "1px solid", borderColor: "mute.main" }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/
                    {Math.ceil(visitsPagination?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={
                      countPerPage > visitsPagination?.length ||
                      currentPage ==
                        Math.ceil(visitsPagination?.length / countPerPage)
                    }
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
        <VisitRemarks
          remark={remark}
          setRemark={setRemark}
          claimId={claimId}
          claimStatus={status}
          addRemarkAction={addRemarkAction}
          setAddRemarkAction={setAddRemarkAction}
        />
      </Layout>
    </>
  );
};

export default PromotedPendingVisits;
