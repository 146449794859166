import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../assets/Layout';
import Breadcrumbs from '../../assets/Breadcrumbs';
import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded
} from '@mui/icons-material';
import MainButton from '../../assets/MainButton';
import SearchBar from './assets/SearchBar';
import {
  Checkbox,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getAllBuilders, updateBuilderStatus } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import SearchFilter from '../../assets/SearchFilter';

export default function BuilderManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [allBulders, setAllBulders] = useState([]);
  const [builders, setBuilders] = React.useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState([])
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [totalPages, setTotalPages] = useState();
  const [builderByCount, setBuilderBycount] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false)
  const [updateId, setUpdateId] = useState('')
  const [updateValue, setUpdateValue] = useState('')

  const handleSelectChange = (event) => {
    setCountPerPage(event.target.value);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getAllBuildersList = useCallback(async () => {
    dispatch(
      getAllBuilders({ isAdmin: true }, (data) => {
        const filteredBrokers = data.data.filter((broker) => {

          // by id
          if (broker?._id?.includes(searchQuery?.toLocaleLowerCase())) {
            return true
          }

          // by name
          if (broker?.name?.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }

          // by phone number
          if (broker?.phoneNumber?.includes(searchQuery)) {
            return true
          }

          // by company name
          if (broker?.companyName?.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }

          // by company type
          if (broker?.companyType?.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }

          // by company gst
          if (broker?.gst?.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }

          // by company pan
          if (broker?.panOfCompany?.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true
          }

          return false
        });
        if (searchQuery) {
          setBuilders(filteredBrokers);
        }
        setAllBulders(data?.data)
      })
    )

  }, [dispatch, searchQuery])

  useEffect(() => {
    dispatch(getAllBuilders({ pageNo: currentPage, pageSize: countPerPage, isAdmin: true }, (data) => {
      if(searchQuery == ""){
        setBuilders(data.data);
      }
    }))
  }, [currentPage, countPerPage, searchQuery])


  const updateDeleteStatus = (id, status) => {
    let payload = {
      id
    }
    dispatch(
      updateBuilderStatus(payload, (data) => {
        enqueueSnackbar("Status Updated!", { variant: 'success' })
        dispatch(getAllBuilders({ pageNo: currentPage, pageSize: countPerPage, isAdmin: true }, (data) => {

          const filteredBrokers = data.data.filter((broker) => {

            // by id
            if (broker?._id?.includes(searchQuery?.toLocaleLowerCase())) {
              return true
            }

            // by name
            if (broker?.name?.toLowerCase().includes(searchQuery?.toLowerCase())) {
              return true
            }

            // by phone number
            if (broker?.phoneNumber?.includes(searchQuery)) {
              return true
            }

            // by company name
            if (broker?.companyName?.toLowerCase().includes(searchQuery?.toLowerCase())) {
              return true
            }

            // by company type
            if (broker?.companyType?.toLowerCase().includes(searchQuery?.toLowerCase())) {
              return true
            }

            // by company gst
            if (broker?.gst?.toLowerCase().includes(searchQuery?.toLowerCase())) {
              return true
            }

            // by company pan
            if (broker?.panOfCompany?.toLowerCase().includes(searchQuery?.toLowerCase())) {
              return true
            }

            return false
          });
          setBuilders(filteredBrokers);
        }))
      })
    )
    handleClose()
  }



  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
    }
    else {
      getAllBuildersList()
    }
  }, [getAllBuildersList, navigate])

  const headData = [
    'Builder Name',
    'Phone Number',
    'Company Name',
    'Company Type',
    'Company GST',
    'Company pan',
    'Property Count',
    'status',
  ];

  return (
    <Layout path="builder-management">
      <Box>
        <Stack
          direction={'row'}
          sx={{ my: 4 }}
          justifyContent={'space-between'}
        >
          <Breadcrumbs primary="Onboard" title="List" />
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Box onClick={() => navigate("/builder-management/add-builder")}>
              <MainButton>
                <Add sx={{ mr: 1, fontSize: '1.2rem' }} /> Add Builder
              </MainButton>
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ mb: 4 }}>
          <SearchFilter>
            <Box className="search style-1">
              <input type="text" placeholder="Search..." value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} />
            </Box>
            {/* <Box className="style-1 filter p-1">
              <select>
                <option value="All" selected>
                  All
                </option>
                <option value="option 1">option 1</option>
              </select>
            </Box>
            <Box className="style-1">
              <input type="date" placeholder="Choose Date" />
            </Box> */}
          </SearchFilter>
        </Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                    <Checkbox />
                  </TableCell> */}
                  <TableCell>ID</TableCell>
                  {headData.map((item, index) => (
                    <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                      <Typography
                        sx={{
                          color: 'mute.darktext',
                          textTransform: 'uppercase',
                          fontSize: '0.9rem',
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {builders?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                  >
                    {/* <TableCell>
                      <Checkbox />
                    </TableCell> */}
                    <TableCell >{item?._id}</TableCell>
                    <TableCell>
                      <Link to={`/builder-management/profile/${item?._id}`} className="link">
                        {item.name}
                      </Link>
                    </TableCell>
                    <TableCell>{item?.phoneNumber}</TableCell>
                    <TableCell>{item?.companyName}</TableCell>
                    <TableCell>{item?.companyType}</TableCell>
                    <TableCell>{item?.gst}</TableCell>
                    <TableCell>{item?.panOfCompany}</TableCell>
                    <TableCell>{item?.totalCount}</TableCell>
                    <TableCell>
                      <Switch
                        checked={item?.isDeleted}
                        onChange={(e) => {
                          setUpdateId(item?._id);
                          setUpdateValue(item?.value)
                          handleClickOpen()
                          // updateDeleteStatus(item?._id, e.target.checked); 
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {/* <Box
          sx={{
            bgcolor: 'mute.dark',
            borderRadius: 1,
            color: 'dark.text',
            p: 2,
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography>1 - {builders?.length >= 10 ? 10 : builders?.length} of {builders?.length}</Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: ' center',
            alignItems: 'center',
          }}>
            <Box>
              <Typography component="p">Rows per page:</Typography>
            </Box>
            <Box>
              <select style={{
                fontSize: '0.8rem',
                height: '35px',
                padding: '0',
                background: '#FFF',
              }}
                value={countPerPage}
                onChange={(e) => setCountPerPage(e.target.value)}
                disabled={countPerPage > builders?.length}>
                <option value="10">
                  10
                </option>
                <option value="20" >
                  20
                </option>
                <option value="30" >
                  30
                </option>
              </select>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                    sx={{ border: '1px solid', borderColor: 'mute.main' }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={'span'}>
                    {currentPage}/{builders && builders?.length > 0 && Math.ceil(builders?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    disabled={Math.ceil(builders?.length / countPerPage) <= currentPage}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    sx={{
                      border: '1px solid',
                      borderColor: 'mute.main',
                      bgcolor: 'white.main',
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box> */}
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {/* <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allBulders?.length ? allBulders?.length : currentPage * countPerPage} of {allBulders?.length}</Typography> */}
            <Typography>
              {allBulders?.length > 0 ? (currentPage - 1) * countPerPage + 1 : 0} -{' '}
              {countPerPage > allBulders?.length
                ? allBulders?.length
                : currentPage * countPerPage > allBulders?.length
                  ? allBulders?.length
                  : currentPage * countPerPage}{' '}
              of {allBulders?.length}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography> Rows per page:</Typography>
            <select style={{ marginTop: "-5px" }}
              value={countPerPage}
              onChange={(e) => setCountPerPage(e.target.value)}>
              <option value="10" >
                10
              </option>
              <option value="20">
                20
              </option>
              <option value="30">
                30
              </option>
            </select>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1 || countPerPage > allBulders?.length}
                    sx={{ border: "1px solid", borderColor: "mute.main", background: currentPage === 1 || countPerPage > allBulders?.length ? "#EFEFEF" : "#FFF" }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/{Math.ceil(allBulders?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={countPerPage > allBulders?.length || currentPage == Math.ceil(allBulders?.length / countPerPage)}
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>

      </Box>


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Want to Update the Status?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box onClick={handleClose}>
            <MainButton>
              No
            </MainButton>
          </Box>
          <Box onClick={() => updateDeleteStatus(updateId, updateValue)} autoFocus>
            <MainButton >
              Yes
            </MainButton>
          </Box>
        </DialogActions>
      </Dialog>
      {/*Profile Modals */}
    </Layout>
  );
}
