import React from "react";
import Layout from "../../assets/Layout";
import { Box, Paper, Typography } from "@mui/material";
import UpdateRoleForm from "./UpdateRoleForm";
import CustomDialog from "../CustomDialog";

const UpdateRole = (props) => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //     if (!(localStorage.getItem('token'))) {
  //         navigate("/");
  //     }
  // }, [navigate])

  return (
    <CustomDialog
      title={"Update Roles"}
      open={props.open}
      setOpen={props.setOpen}
      size={props.size}
    >
      {/* <Layout path="addproperty">
                <Typography sx={{fontWeight:'600', fontSize:'28px', color:'#000000',marginTop:'48px', marginBottom:'48px'}}>Update Role</Typography>
                <Paper sx={{borderRadius:'20px'}}>
              
                    <Box sx={{padding:'0px 40px'}}>
                        <UpdateRoleForm />
                    </Box>
                </Paper>

            </Layout> */}

      <Box sx={{ padding: "0px 10px" }}>
        <UpdateRoleForm
          builders={props?.builders}
          properties={props?.properties}
          role={props?.role}
          setOpen={props?.setOpen}
        />
      </Box>
    </CustomDialog>
  );
};

export default UpdateRole;
