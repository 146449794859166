import React, { useEffect, useState } from "react";
import { Box, Icon, List, ListItemButton, Typography } from "@mui/material";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import GroupIcon from "@mui/icons-material/Group";
import PaymentIcon from "@mui/icons-material/Payment";
import QuizIcon from "@mui/icons-material/Quiz";
import SignalCellularAlt from "@mui/icons-material/SignalCellularAlt";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  ChevronRight,
  Engineering,
  Groups,
  KeyboardArrowDown,
  KeyboardDoubleArrowRight,
  ManageAccounts,
  MapsHomeWork,
  ViewInAr,
  CreditCard,
  ReceiptLong,
  Analytics,
  QuestionAnswer,
  Groups3,
  ContactMail,
  LiveHelp,
  HistoryEdu,
  StickyNote2,
  CreditScore,
  AutoAwesomeMotion,
  EditCalendar,
  BadgeOutlined,
  SupportAgent,
  ViewList,
} from "@mui/icons-material";
import { menuBtn, menuDropDown, menuIcon } from "../Style/stylesheet";
import { useDispatch } from "react-redux";
import { getAllRoles } from "../actions";
import { enqueueSnackbar } from "notistack";
import { pathToRegexp, match } from 'path-to-regexp';

const MenuSubItem = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const role = localStorage.getItem("role")
    ? JSON.parse(localStorage.getItem("role")).role.name
    : "";
  // const [activeMenu, setActiveMenu] = useState(false);
  let location = useLocation();
  let activeMenu = data.subItem.filter(
    (item) => item.url === location.pathname
  );
  useEffect(() => {
    activeMenu.length === 1 && setIsOpen(true);
  }, []);
  return role == "agent" &&
    (data.name == "Property Management" ||
      data.name == "Builder Management") ? (
    <List sx={menuDropDown} className={isOpen && "open"}>
      <ListItemButton
        sx={menuBtn}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={activeMenu.length === 1 && "active"}
      >
        <Icon sx={menuIcon} className="icon">
          {data.icon}
        </Icon>
        <Typography sx={{ flexGrow: 1 }} className="menu-text">
          {data.name}
        </Typography>
        {isOpen ? <KeyboardArrowDown /> : <ChevronRight />}
      </ListItemButton>
      {isOpen &&
        data.subItem.map((item, index) =>
          role == "agent" &&
          (item.name == "Add Builder" || data.name == "Property Management") &&
          item.name != "All Property" &&
          item.name != "Property Type" ? (
            <Link to={item.url} key={index}>
              <ListItemButton
                sx={menuBtn}
                className={location.pathname === item.url && "active"}
              >
                <KeyboardDoubleArrowRight
                  sx={{ mr: 2 }}
                  className="menu-text"
                />
                <Typography sx={{ flexGrow: 1 }} className="menu-text">
                  {item.name}
                </Typography>
              </ListItemButton>
            </Link>
          ) : role == "agent" ? (
            <></>
          ) : (
            <Link to={item.url} key={index}>
              <ListItemButton
                sx={menuBtn}
                className={location.pathname === item.url && "active"}
              >
                <KeyboardDoubleArrowRight
                  sx={{ mr: 2 }}
                  className="menu-text"
                />
                <Typography sx={{ flexGrow: 1 }} className="menu-text">
                  {item.name}
                </Typography>
              </ListItemButton>
            </Link>
          )
        )}
    </List>
  ) : role == "agent" ? (
    <></>
  ) : (
    <List sx={menuDropDown} className={isOpen && "open"}>
      <ListItemButton
        sx={menuBtn}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={activeMenu.length === 1 && "active"}
      >
        <Icon sx={menuIcon} className="icon">
          {data.icon}
        </Icon>
        <Typography sx={{ flexGrow: 1 }} className="menu-text">
          {data.name}
        </Typography>
        {isOpen ? <KeyboardArrowDown /> : <ChevronRight />}
      </ListItemButton>
      {isOpen &&
        data.subItem.map((item, index) => (
          <Link to={item.url} key={index}>
            <ListItemButton
              sx={menuBtn}
              className={location.pathname === item.url && "active"}
            >
              <KeyboardDoubleArrowRight sx={{ mr: 2 }} className="menu-text" />
              <Typography sx={{ flexGrow: 1 }} className="menu-text">
                {item.name}
              </Typography>
            </ListItemButton>
          </Link>
        ))}
    </List>
  );
};

const Sidenav = ({ path }) => {
  const role = localStorage.getItem("role")
    ? JSON.parse(localStorage.getItem("role")).role.name
    : "";

  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllRoles({}, (data) => {
        setRoles(data.data);
      })
    );
  }, []);

  const currentUserRole = roles.find(
    (item) => item.name?.toLowerCase() === role?.toLowerCase()
  );
  useEffect(() => {
    if (currentUserRole) {
      setPermissions(currentUserRole.permissions);
    }
  }, [currentUserRole]);

  const IconStyle = {
    marginTop: "9px",
    height: "20px",
    width: "20px",
    color: "inherit",
  };

  const navItem = [
    {
      name: "Dashboard",
      url: "/dashboard",
      icon: <AutoAwesomeMosaicIcon sx={IconStyle} />,
      subItem: false,
    },
    {
      name: "Roles & Employees",
      url: "/",
      icon: <Groups sx={IconStyle} />,
      subItem: [
        { name: "Roles", url: "/roles" },
        { name: "Employees", url: "/roles/employees" },
        // { name: "Builder Roles", url: "/roles/builder" },
      ],
    },
    // {
    //   name: "Nesto Modules",
    //   url: "/",
    //   icon: <ViewInAr sx={IconStyle} />,
    //   subItem: false,
    // },

    {
      name: "Builder Management",
      url: "/",
      icon: <Engineering sx={IconStyle} />,
      subItem: [
        { name: "All Builder", url: "/builder-management" },
        { name: "Add Builder", url: "/builder-management/add-builder" },
        { name: "Intents", url: "/builder-management/intents" },
        {
          name: "Raised Dispute Question",
          url: "/builder-management/raised-dispute-question",
        },
      ],
    },
    {
      name: "Invoices",
      url: "/invoices/all",
      icon: <ViewList sx={IconStyle} />,
      subItem: false,
    },
    {
      name: "Property Management",
      url: "/",
      icon: <MapsHomeWork sx={IconStyle} />,
      subItem: [
        { name: "All Property", url: "/property-management" },
        { name: "Add Property", url: "/property-management/add-property" },
        { name: "Loan Approved", url: "/property-management/loan-approved" },
        // { name: 'Near By Area', url: '/property-management/near-by-area' },
        {
          name: "Furnishing Detail",
          url: "/property-management/furnishing-detail",
        },
        { name: "Specifications", url: "/property-management/specifications" },
        { name: "Sub Company", url: "/property-management/sub-company" },
        { name: "Property Type", url: "/property-management/property-type" },
        {
          name: "Location Advantages",
          url: "/property-management/location-advantages",
        },
        { name: "Amenity", url: "/property-management/amenity" },
      ],
    },
    {
      name: "Broker Management",
      url: "/",
      icon: <ManageAccounts sx={IconStyle} />,
      subItem: [
        { name: "All Brokers", url: "/broker-management" },
        { name: "Refer", url: "/broker-management/refer" },
        { name: "Refer Payments", url: "/broker-management/refer-payments" },
      ],
    },
    // {
    //   name: "Loan Agent",
    //   url: "/dsa",
    //   icon: <ReceiptLong sx={IconStyle} />,
    //   subItem: false,
    // },
    {
      name: "Transaction History",
      url: "/transaction-history/recieved-invoices",
      icon: <CreditScore sx={IconStyle} />,
      subItem: false,
    },
    // {
    //   name: "Payment Management",
    //   url: "/payment",
    //   icon: <CreditCard sx={IconStyle} />,
    //   subItem: false,
    // },

    {
      name: "Manage Q & A",
      url: "/manage-q-&-a",
      icon: <QuestionAnswer sx={IconStyle} />,
      subItem: false,
    },
    {
      name: "Training",
      url: "/training",
      icon: <Groups3 sx={IconStyle} />,
      subItem: false,
    },
    // {
    //   name: "Analytics",
    //   url: "/",
    //   icon: <Analytics sx={IconStyle} />,
    //   subItem: false,
    // },
    // {
    //   name: "Content Management",
    //   url: "/",
    //   icon: <ContactMail sx={IconStyle} />,
    //   subItem: false,
    // },
    {
      name: "Queries Management",
      url: "/queries/new",
      icon: <LiveHelp sx={IconStyle} />,
      subItem: false,
    },
    {
      name: "Blogs",
      url: "/blogs",
      icon: <StickyNote2 sx={IconStyle} />,
      subItem: false,
    },
    {
      name: "Claims",
      url: "/",
      icon: <StickyNote2 sx={IconStyle} />,
      subItem: [
        { name: "Claims", url: "/claims" },
        { name: "Eligible Claims", url: "/eligible-claims" },
      ],
    },
    {
      name: "Visits",
      url: "/",
      icon: <StickyNote2 sx={IconStyle} />,
      subItem: [
        { name: "Normal Visits", url: "/all-visits" },
        { name: "Promoted Visits", url: "/promoted-pending-visits" },
      ],
    },
    {
      name: "Promotions",
      url: "/promotion/banner",
      icon: <AutoAwesomeMotion sx={IconStyle} />,
      subItem: false,
    },
    {
      name: "Subscription",
      url: "/",
      icon: <EditCalendar sx={IconStyle} />,
      subItem: [
        { name: "New Subscription", url: "/subscriptions" },
        { name: "Purchase Subscription", url: "/new-subscriptions" },
      ],
    },
    {
      name: "Policies",
      url: "/",
      icon: <BadgeOutlined sx={IconStyle} />,
      subItem: [
        { name: "Privacy Policy", url: "/privacy-policy" },
        { name: "Terms & Condiions", url: "/terms-and-conditions" },
      ],
    },
    {
      name: "Contact Query",
      url: "/contact",
      icon: <SupportAgent sx={IconStyle} />,
      subItem: false,
    },
  ];

  let location = useLocation();

  const isAdmin = () => role === "Super Admin";
  const hasPermission = (permission) => permissions[permission] === true;
  const navigate = useNavigate();

  const other_Routes = [
    {
      name: "Past Properties",
      route: "/past-properties/:id",
    },
    {
      name: "Add Floor Plans",
      route: "/property-management/floor-Plan",
    },
    {
      name: "Add Brokerage",
      route: "/property-management/brokerage",
    },
  ];
  useEffect(() => {
    const accessibleUrls = [];
    // looping over the navitems to fetch urls which can be accessed by current user
    navItem.forEach((item) => {
      // Check if the user has permission for the main item or any sub-item
      const hasSubItemPermission =
        item.subItem &&
        item.subItem.some((subItem) => permissions[subItem.name]);
      if (isAdmin() || permissions[item.name] || hasSubItemPermission) {
        if (item.subItem && item.subItem.length > 0) {
          const filteredSubItems = isAdmin()
            ? item.subItem.filter((item) => item.url)
            : item.subItem.filter((subItem) => permissions[subItem.name]);
          accessibleUrls.push(...filteredSubItems.map((item) => item.url));
        } else {
          accessibleUrls.push(item.url);
        }
      }
    });

    other_Routes.forEach((item) => {
      if (permissions[item.name]) {
        accessibleUrls.push(item.route);
      }
    });

    const isPathAccessible = (path) => {
      return accessibleUrls.some(route => {
        const re = pathToRegexp(route);
        return re.test(path);
      });
    };

    // redirecting user if he can not access the route
    if (accessibleUrls.length > 0 && !isPathAccessible(location.pathname) && !isAdmin()) {
      enqueueSnackbar("Permission Denied", { variant: "error" });
      navigate(accessibleUrls[0]);
    }
  }, [role, permissions]);

  return (
    <>
      <Box>
        <Box
          sx={{
            bgcolor: "#fff",
            py: 2,
            px: 1,
            bottom: 0,
            minWidth: 300,
          }}
          className="menuBar"
        >
          <List>
            {navItem.map((item, index) => {
              // Check if the user is admin or if the permission is granted
              const hasSubItemPermission =
                item.subItem &&
                item.subItem.some((subItem) => permissions[subItem.name]);
              if (isAdmin() || permissions[item.name] || hasSubItemPermission) {
                if (item.subItem && item.subItem.length > 0) {
                  // If user is admin, render all subitems
                  const filteredSubItems = isAdmin()
                    ? item.subItem
                    : item.subItem.filter(
                        (subItem) => permissions[subItem.name]
                      );
                  if (filteredSubItems.length > 0) {
                    return (
                      <MenuSubItem
                        key={index}
                        data={{ ...item, subItem: filteredSubItems }}
                      />
                    );
                  } else {
                    return null;
                  }
                } else {
                  return (
                    <Link to={item.url} key={index}>
                      <ListItemButton
                        key={index}
                        sx={menuBtn}
                        className={location.pathname === item.url && "active"}
                      >
                        <Icon sx={menuIcon} className="icon">
                          {item.icon}
                        </Icon>
                        <Typography className="menu-text">
                          {item.name}
                        </Typography>
                      </ListItemButton>
                    </Link>
                  );
                }
              } else {
                return null;
              }
            })}
          </List>

          {/* <List>
            {navItem.map((item, index) =>
              item.subItem ? (
                <MenuSubItem data={item} key={index} />
              ) : (
                role == "agent" ? 
                <></>
                :
                <Link to={item.url} key={index}>
                  <ListItemButton
                    sx={menuBtn}
                    className={location.pathname === item.url && "active"}
                  >
                    <Icon sx={menuIcon} className="icon">
                      {item.icon}
                    </Icon>
                    <Typography className="menu-text">{item.name}</Typography>
                  </ListItemButton>
                </Link>
              )
            )}
          </List> */}
        </Box>
      </Box>
    </>
  );
};

export default Sidenav;
