import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateIntentStatus } from '../../actions';
import { enqueueSnackbar } from 'notistack';


export default function IntentsRejection({ intentId, intentStatus, addRemarkAction, setAddRemarkAction }) {
  const dispatch = useDispatch();
  const [remark, setRemark] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      id: intentId,
      status: intentStatus.toLowerCase(),
      rejectionReason: remark
    }
    dispatch(
      updateIntentStatus(payload, (data) => {
        if (data?.status == 200) {
          enqueueSnackbar(data?.data?.message, { variant: 'success' });
          window.location.reload()
        }
        else {
          enqueueSnackbar(data?.data?.message, { variant: 'error' });
        }
      })
    )

  }

  const closeHandler = () => setAddRemarkAction(false);
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
    marginTop: 4
  };
  return (
    <Dialog
      open={addRemarkAction}
      onClose={closeHandler}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Reason for Rejection
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Box component={'from'} flexGrow={1}>
          <form onSubmit={handleSubmit}>
            <textarea value={remark} onChange={(e) => setRemark(e.target.value)} className='inputBox' style={{ resize: 'none', height: '300px', width: '100%' }} placeholder="Enter reason for Rejection"></textarea>
            <Button variant="contained" sx={ButtonStyle} type='submit'>
              Update
            </Button>
            <Button variant="contained" sx={ButtonStyle} type='submit'>
              Reset
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
