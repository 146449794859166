import { Button } from '@mui/material';
import React from 'react';

export default function MainButton({ children, sx, size }) {
  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: '3.5px',
        boxShadow: 0,
        px: 3,
        py: 1,
        textTransform: 'capitalize',
        fontFamily:'Poppins'
      }}
    >
      {children}
    </Button>
  );
}
