import React, { useCallback, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Stack } from "@mui/system";
import Layout from "../../assets/Layout";

import {
  Add,
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Delete,
} from "@mui/icons-material";
import MainButton from "../../assets/MainButton";
import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { roleData } from "../../utils/roleData";
import { Link, useNavigate } from "react-router-dom";
import { deleteRoleById, getAllBuilders, getAllProperty, getAllRoles } from "../../actions";
import { useDispatch } from "react-redux";
import CustomDialog from "../CustomDialog";
import AddRoles from "./AddRoles";
import UpdateRole from "./UpdateRole";
import edit from '../../assets/img/edit.svg'
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import { enqueueSnackbar } from "notistack";
function Roles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [allRoles, setAllRoles] = React.useState([]);
  const [allBuilders, setAllBuilders] = React.useState([]);
  const [allProperties, setAllProperties] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page

  // Add role states
  const [addModal, setAddModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [selectedRole, setSelectedRole] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      getRoles();
      getAllBuildersList();
      getAllPropertyList();
    }
  }, [navigate, addModal, updateModal]);

  const [permission, setPermission] = React.useState("");
  const [roles, setRoles] = React.useState([]);
  const handleChange = (index, e) => {
    var temp = [...roles];
    temp[index].permission = e.target.value;
    setRoles(temp);
  };

  const getRoles = () => {
    dispatch(
      getAllRoles({ pageSize: 20, pageNo: 1 }, (data) => {
        if (data.status === 200) {
          setAllRoles(data.data);
        }
      })
    );
  };

  const getAllBuildersList = useCallback(async () => {
    dispatch(
      getAllBuilders({ isAdmin: true }, (data) => {
        console.log(data?.data);
        setAllBuilders(data?.data);
      })
    );
  }, [dispatch]);

  const getAllPropertyList = () => {
    dispatch(
      getAllProperty({}, (data) => {
        setAllProperties(data?.data);
      })
    );
  };

  useEffect(() => {
    dispatch(getAllRoles({}, (data) => {
      setRoles(data.data);
    }))
  }, [])

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteRoleById(selectedRole, (data) => {
      enqueueSnackbar("Role Deleted Successfuly", {variant: 'success'});
      setSelectedRole("");
      setLoading(false);
      setTimeout(()=> window.location.reload(),200);
    }, (err) => {
      console.log(err);
      enqueueSnackbar("Error Occured! while deleting role", {variant: 'error'});
      setLoading(false);
    }))
  }

  return (
    <>
      <Layout path="roles">
        <Box>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            spacing={3}
            sx={{ my: 4 }}
            justifyContent={'space-between'}
          >
            <Breadcrumbs primary="Roles & Employees - Roles" />
            <Box sx={{ display: 'flex' }}>
              {/* <ButtonController >
                <Box>
                  <IconButton className="iconBtn">
                    <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={'span'}>
                    <strong>Jun 24, 2022</strong> Today
                  </Typography>
                </Box>
                <Box>
                  <IconButton className="iconBtn">
                    <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                  </IconButton>
                </Box>
              </ButtonController> */}
              <Link onClick={() => setAddModal(true)} style={{ marginLeft: '10px' }}>
                <MainButton>
                  <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Role
                </MainButton>
              </Link>
            </Box>
          </Stack>
          {/* <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Box>
                <Link onClick={() => setAddModal(true)}>
                  <MainButton>
                    <Add sx={{ mr: 1, fontSize: "1.2rem" }} /> Add Role
                  </MainButton>
                </Link>
              </Box>
            </Stack> */}
          <Box sx={{ mb: 4 }}>{/* <SearchBar /> */}</Box>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox />
                    </TableCell>
                    {headData.map((item, index) => (
                      <TableCell
                        key={index}
                        style={{ minWidth: "200px" }}
                        align="center"
                      >
                        <Typography
                          sx={{
                            color: "mute.darktext",
                            textTransform: "uppercase",
                            fontSize: "0.9rem",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allRoles.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                    >
                      <TableCell>
                        <Checkbox />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{item?.name}</TableCell>
                      {/* <TableCell>
                        <Link
                          onClick={() => {
                            setSelectedRole(item);
                            setUpdateModal(true);
                          }}
                          className="link"
                        >
                          {item.name}
                        </Link>
                      </TableCell> */}
                      <TableCell sx={{ textAlign: 'center', maxWidth: '50px' }}>{Object?.keys(item?.permissions)?.filter((key) => item?.permissions[key])?.join(', ')}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}><img src={edit} style={{ cursor: 'pointer' }} onClick={() => { setSelectedRole(item); setUpdateModal(true); }} />  <Delete sx={{ cursor: 'pointer', color: '#F10000' }} onClick={() => { setSelectedRole(item._id); setDeleteConfirmation(true);}} /></TableCell>
                      {/* <TableCell>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                          <Select
                            value={item.permission}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                          >
                            <MenuItem value="" disabled selected>
                              Choose permission
                            </MenuItem>
                            <MenuItem value={"read"}>Read</MenuItem>
                            <MenuItem value={"write"}>Write</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Switch checked={item.isDeleted} onChange={() => {}} />
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Box
            sx={{
              bgcolor: "mute.dark",
              borderRadius: 1,
              color: "dark.text",
              p: 2,
              mt: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allRoles?.length ? allRoles?.length : currentPage * countPerPage} of {allRoles?.length}</Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography> Rows per page:</Typography>
              <select style={{ marginTop: "-5px" }}
                value={countPerPage}
                onChange={(e) => setCountPerPage(e.target.value)}>
                <option value="10" >
                  10
                </option>
                <option value="20">
                  20
                </option>
                <option value="30">
                  30
                </option>
              </select>
              <Box sx={{ ml: 2 }}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <IconButton
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1 || countPerPage > allRoles?.length}
                      sx={{ border: "1px solid", borderColor: "mute.main" }}
                    >
                      <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography variant="text" component={"span"}>
                      {currentPage}/{Math.ceil(allRoles?.length / countPerPage)}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={countPerPage > allRoles?.length || currentPage == Math.ceil(allRoles?.length / countPerPage)}
                      sx={{
                        border: "1px solid",
                        borderColor: "mute.main",
                        bgcolor: "white.main",
                      }}
                    >
                      <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                    </IconButton>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* <CustomDialog open={true} title="New Roles" /> */}
        <AddRoles
          open={addModal}
          setOpen={setAddModal}
          size={"lg"}
          builders={allBuilders}
          properties={allProperties}
        />
        <UpdateRole
          open={updateModal}
          setOpen={setUpdateModal}
          size={"lg"}
          builders={allBuilders}
          properties={allProperties}
          role={selectedRole}
        />

        <CustomDialog
          title={""}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          size={"sm"}
        >
          <Typography sx={{textAlign:'center', mb: 2}}>Are you sure you want to delete the selected role</Typography>
          <Button variant="contained" sx={{float:'right'}} onClick={handleDelete}>{loading ? <CircularProgress size={24}/> : "Yes" }</Button>
          <Button variant="outlined" sx={{float:'right', marginRight:'10px'}} onClick={() => setDeleteConfirmation(false)}>No</Button>
        </CustomDialog>
      </Layout>
    </>
  );
}

const headData = ["Roles Name", "Permissions", "Action",];

export default Roles;
