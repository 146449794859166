import { Delete, Done, ModeEditOutline } from "@mui/icons-material";
import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addPropertyDetails, getPropertyById, updateBrokerageDetails, updatePropertyDetails } from "../../actions";
import { enqueueSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';

const UpdateBrokerageForm = ({ propertyID }) => {
    const navigate = useNavigate();
    const [tempIndex, setTempIndex] = React.useState("");
    const [tempSpecification, setTempSpecification] = React.useState("");
    const [tempQuantity, setTempQuantity] = React.useState("");
    const [specifications, setSpecifications] = React.useState([]);
    const [specification, setSpecification] = React.useState("");
    const [quanitity, setQuantity] = React.useState("");
    const [brokerageValue, setBrokerageValue] = React.useState("");
    const [brokerageValueType, setBrokerageValueType] = React.useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [propertyType, setPropertyType] = useState("");
    const [subProperty, setSubProperty] = useState("");
    const [floorPlans, setFloorPlans] = useState([]);

    useEffect(() => {
        if (!(localStorage.getItem('token'))) {
            navigate("/");
        }
    }, [navigate])

    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer'
    };

    const loadPropertyDetails = useCallback(() => {
        dispatch(
            getPropertyById(propertyID, {}, (data) => {
                if (data.data[0].brokerageType && data.data[0].brokerageType != "") {
                    setBrokerageValueType(data.data[0].brokerageType);
                } else {
                    setBrokerageValueType("Amount");
                }
                setPropertyType(data.data[0].propertyType.name);
                setBrokerageValue(data.data[0].brokerageValue);
                setSpecifications(data.data[0].milestonesTermsConditions);
                setFloorPlans(data?.data[0]?.floorPlanAndPricing)
                console.log("FloorPlan",data?.data[0]?.floorPlanAndPricing)
            })
        );
    }, [propertyID]);

    useEffect(() => {
        loadPropertyDetails();
    }, [loadPropertyDetails]);

    const handleCreatePlan = () => {
        setSpecifications((oldArr) => [...oldArr, { condition: specification, brokerage: quanitity }]);
    }

    const handleUpdatePlan = (index) => {
        const val = specifications;
        val[index] = { condition: tempSpecification, brokerage: tempQuantity };
        setSpecifications(val);
        setTempIndex(null);
    }

    const handleRemoveFromPlan = (index) => {
        setSpecifications((prevArray) => {
            const newArray = [...prevArray]; // Create a copy of the original array
            newArray.splice(index, 1); // Remove the element at the specified index
            return newArray; // Update the state with the new array
        });
    }

    const handleEditPlan = (index) => {
        setTempIndex(index);
        setTempSpecification(specifications[index].condition);
        setTempQuantity(specifications[index].brokerage);
    };

    const dispatch = useDispatch();


    const getPriceForProperty = (floorPlanAndPricing) => {
        if (propertyType.toLowerCase() === "commercial" && subProperty.toLowerCase() === "warehouse") {
            if (floorPlanAndPricing.length > 0) {
                if (floorPlanAndPricing[0].price == "") {
                    var minPrice = 0;
                    var maxPrice = 0;
                } else {
                    var minPrice = floorPlanAndPricing[0].price * floorPlanAndPricing[0].minUnitSize;
                    var maxPrice = floorPlanAndPricing[0].price * floorPlanAndPricing[0].maxUnitSize;
                }
                for (let i = 1; i < floorPlanAndPricing.length; i++) {
                    if (floorPlanAndPricing[i].price * floorPlanAndPricing[0].minUnitSize < minPrice) {
                        minPrice = floorPlanAndPricing[i].price;
                    }

                    if (floorPlanAndPricing[i].price * floorPlanAndPricing[0].maxUnitSize > maxPrice) {
                        maxPrice = floorPlanAndPricing[i].price;
                    }
                }
                return { minPrice, maxPrice };
            }
        } else {
            if (floorPlanAndPricing.length > 0) {
                if (floorPlanAndPricing[0].price == "") {
                    var minPrice = 0;
                    var maxPrice = 0;
                } else {
                    var minPrice = floorPlanAndPricing[0].price;
                    var maxPrice = floorPlanAndPricing[0].price;
                }
                for (let i = 1; i < floorPlanAndPricing.length; i++) {
                    if (parseInt(floorPlanAndPricing[i].price) < parseInt(minPrice)) {
                        minPrice = floorPlanAndPricing[i].price;
                    }

                    if (parseInt(floorPlanAndPricing[i].price) > parseInt(maxPrice)) {
                        maxPrice = floorPlanAndPricing[i].price;
                    }
                }
                return { minPrice, maxPrice };
            }
        }
    }

    // let floorPlans = JSON.parse(localStorage.getItem("floorplans"));

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(propertyID, brokerageValueType, brokerageValue, specifications.length);
        if (!brokerageValueType || !brokerageValue || specifications.length === 0) {
            enqueueSnackbar("Please fill in all the required fields!", { variant: 'error' });
            return;
        }
        setIsLoading(true)
        let payload = {
            id: propertyID,
            brokerageType: brokerageValueType,
            brokerageValue,
            milestonesTermsConditions: specifications,
            // formData.append("minPrice",getPriceForProperty(floorPlans).minPrice);
            // formData.append("maxPrice",getPriceForProperty(floorPlans).maxPrice);
            // minPrice: getPriceForProperty(floorPlans).minPrice,
            // maxPrice: getPriceForProperty(floorPlans).maxPrice
        }
        dispatch(
            updateBrokerageDetails(payload, (data) => {
                console.log("I am in updateBrokerageDetail")
                console.log(data.data)
                if (data.data !== "" ) {
                console.log("I am in formData")

                if(floorPlans.length > 0){

                    const formData = new FormData();
                    formData.append("id", propertyID);
                    formData.append("minPrice", getPriceForProperty(floorPlans).minPrice);
                    formData.append("maxPrice", getPriceForProperty(floorPlans).maxPrice);
                    dispatch(
                        updatePropertyDetails(formData, (data) => {
                            enqueueSnackbar("Data Updated succesfully", { variant: 'success' });
                            navigate("/property-management");
                        })
                      );
                }else{
                    enqueueSnackbar("Data Updated succesfully", { variant: 'success' });
                    navigate("/property-management");
                }
                    // dispatch(
                    //     addPropertyDetails(formData, async (data) => {
                    //         console.log("I am in addPropertyDetails")

                    //     }, (err) => {
                    //         enqueueSnackbar(err.message, { variant: 'error' });
                    //         setIsLoading(false)
                    //     })
                    // );
                }
            })
        )
        setIsLoading(false)
    }

    return (
        <>
            <Box sx={{ marginTop: '73px' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <label>Brokerage Type</label>
                            <FormControl sx={{ display: 'block' }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="Amount"
                                    name="radio-buttons-group"
                                    row
                                    value={brokerageValueType}
                                    onChange={(e) => setBrokerageValueType(e.target.value)}
                                >
                                    <FormControlLabel value="Percentage" control={<Radio />} label="Percentage %" />
                                    <FormControlLabel value="Amount" control={<Radio />} label="Amount" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <label>Brokerage Value {brokerageValueType === "Percentage" ? "(%)" : ""}</label>
                            <input className='inputBox' placeholder="Enter Amount" value={brokerageValue} onChange={(e) => setBrokerageValue(e.target.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{ color: '#4C535F', fontWeight: '700', fontSize: '20px' }}>
                                Milestones
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <label>Milestone Terms</label>
                            <input className='inputBox' placeholder="Condition" onChange={(e) => setSpecification(e.target.value)} />
                        </Grid>
                        <Grid item xs={7}>
                            <label>Brokerage %</label>
                            <input className='inputBox' placeholder="Brokerage" onChange={(e) => setQuantity(e.target.value)} />
                        </Grid>
                        <Grid item xs={1}>
                            <p onClick={handleCreatePlan} style={{ borderRadius: '50%', background: '#278FD9', color: '#FFFFFF', fontWeight: '600', padding: '1px 16px', fontSize: '35px', width: '54px', height: '54px', marginTop: '25px', cursor: 'pointer' }}>+</p>
                        </Grid>
                        <Grid item xs={12}>
                            {specifications.length > 0 ? (
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Milestone Terms</TableCell>
                                                <TableCell align="center">Brokerage %</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {specifications.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell Cell align="center">
                                                        {tempIndex === index ? (
                                                            <input
                                                                value={tempSpecification}
                                                                onChange={(e) => setTempSpecification(e.target.value)}
                                                                className="inputBox"
                                                                type="text"
                                                                placeholder="Condition"
                                                            />
                                                        ) : (
                                                            item.condition
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {tempIndex === index ? (
                                                            <input
                                                                value={tempQuantity}
                                                                onChange={(e) => setTempQuantity(e.target.value)}
                                                                className="inputBox"
                                                                type="text"
                                                                placeholder="Brokerage"
                                                            />
                                                        ) : (
                                                            item.brokerage
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <p style={{ textAlign: 'center' }}>
                                                            {tempIndex === index ? (
                                                                <Done
                                                                    onClick={() => handleUpdatePlan(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            ) : (
                                                                <ModeEditOutline
                                                                    onClick={() => handleEditPlan(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            )}
                                                            <Delete
                                                                onClick={() => handleRemoveFromPlan(index)}
                                                                sx={{ color: '#EA000D', cursor: 'pointer' }}
                                                            />
                                                        </p>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                ''
                            )}
                        </Grid>
                    </Grid>
                    <br />
                    {isLoading ?
                        <Button disbaled type="submit" style={{ width: '204px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '16px', marginBottom: '33px' }}><CircularProgress size={24} sx={{ color: '#fff' }} /></Button>

                        :
                        <Button type="submit" style={{ width: '204px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '16px', marginBottom: '33px' }}>Update</Button>
                    }
                    {/* <Button onClick={() => navigate('/property-management/add-property')} variant="outlined" style={{ width: '204px', height: '52px', borderRadius: '8.62px', fontWeight: '500', fontSize: '16px', marginBottom: '33px', marginLeft: '10px', marginRight: '10px' }}>Reset</Button> */}
                    <Button onClick={() => navigate('/update-property/floor-Plan/' + propertyID)} style={{ background: '#E2E2E2', color: '#69748C', width: '204px', height: '52px', borderRadius: '8.62px', fontWeight: '500', fontSize: '16px', marginBottom: '33px', marginLeft: '10px', marginRight: '10px' }}>Back</Button>
                </form>
            </Box>
        </>
    )
};

export default UpdateBrokerageForm;
