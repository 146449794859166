import { Close } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { addBlog, uploadDocToS3 } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import JoditEditor from 'jodit-react';

export default function AddBlog({ addDialog, setAddDialog }) {
  const dispatch = useDispatch()
  const [date, setDate] = useState('');
  const [blogName, setBlogName] = useState('');
  const [postedBy, setPostedBy] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [description, setDescription] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleUploadProfileImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      setProfileImage(file)
    }
  }

  const handleUploadThumbnailImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      setThumbnailImage(file)
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    if (!blogName || !postedBy || !date || !description) {
      enqueueSnackbar('Please fill in all required fields', { variant: 'warning' });
      setLoading(false);
      return;
    }

    let payload = {
      profileImage,
      thumbnailImage,
      blogName: blogName,
      blogDate: date,
      postedByName: postedBy.trim(),
      excerpt: description.trim(), // remove leading and trailing spaces if any in the
      description: description.trim().replace(/\n+/g, '\r'),// replace multiple line
    }

    let formData = new FormData();

    formData.append("profileImage", profileImage);
    formData.append("thumbnailImage", thumbnailImage);
    formData.append("blogName", blogName);
    formData.append("blogDate", date);
    formData.append("postedByName", postedBy.trim());
    formData.append("excerpt", description.trim());
    formData.append("description", description.trim().replace(/\n+/g, '\r'));

    dispatch(
      addBlog(formData, (data) => {
        console.log("Here is Data:", data);
        enqueueSnackbar('Blog Added Successfully', { variant: 'success' });
        setLoading(false);
        setDate('');
        setBlogName('');
        setPostedBy('');
        setDescription('');
        setAddDialog(false)
        window.location.reload();
      }, (err) => {
        const message = err?.response?.data ? err?.response?.data : "Error Occured! please try again";
        enqueueSnackbar(message, { variant: 'error' });
        setLoading(false);
      })
    );
  }

  const handleReset = () => {
    setBlogName('');
    setPostedBy('')
    setDescription('');
    setExcerpt('')
    setDate('');
    // setProfileImage("")
    // setThumbnailImage("")
  };
  const editor = useRef(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: 'Enter description...'
  }

  return (
    <Dialog
      maxWidth="md"
      open={addDialog}
      onClose={() => setAddDialog(!addDialog)}
      fullWidth
    >
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Blog Name
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={() => setAddDialog(!addDialog)}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <UploadData name={'Upload  Profile Image'}>
              <UploadDataCard>
                {/* <img src="/Images/uploadImg.png" alt="Profile"/> */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleUploadProfileImage(e)}
                />
                {profileImage != null ? <img src={URL.createObjectURL(profileImage)} alt="Uploaded" /> : <img src="/uploadImg.png" alt="Profile" />}
              </UploadDataCard>
            </UploadData>
          </Grid>
          <Grid item md={6}>
            <UploadData name={'Upload  Thumbnail Image'}>
              <UploadDataCard>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleUploadThumbnailImage(e)}
                />
                {thumbnailImage != null ? <img src={URL.createObjectURL(thumbnailImage)} alt="Uploaded" /> : <img src="/uploadImg.png" alt="thumbnail" />}
              </UploadDataCard>
            </UploadData>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={6}>
            <InputStyle topSpace={true} name="Blog Name*">
              <input type="text" value={blogName} onChange={(e) => setBlogName(e.target.value)} />
            </InputStyle>
          </Grid>
          <Grid item md={6}>
            <InputStyle topSpace={true} name="Blog Date*">
              <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
            </InputStyle>
          </Grid>
          <Grid item md={6}>
            <InputStyle topSpace={true} name="Posted by - Name*">
              <input type="text" value={postedBy} onChange={(e) => setPostedBy(e.target.value)} />
            </InputStyle>
          </Grid>
          <Grid item md={12}>
            <InputStyle topSpace={true} name="Description*">
              {/* <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} /> */}
              <JoditEditor
                ref={editor}
                value={description}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </InputStyle>
          </Grid>
          <Grid item md={12}>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={1}>
              <Button variant="contained" sx={{ width: 120 }} disabled={loading} onClick={handleSubmit}>
                {
                  loading
                    ?
                    <CircularProgress size={24} />
                    :
                    "Submit"
                }
              </Button>
              <Button variant="outlined" sx={{ width: 120 }} onClick={handleReset}>
                Reset
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
