import { Done } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';

export default function Stepper({ arrData, step }) {
  return (
    <Stack direction="row" spacing={2}>
      <Stack
        direction="column"
        spacing={3}
        sx={{
          borderRadius: 8,
          bgcolor: '#eee',
          p: '5px',
          '& .icon': {
            background: '#fff',
            height: 28,
            width: 28,
            borderRadius: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&.active': {
              bgcolor: 'primary.main',
              color: '#fff',
            },
            '&.done': {
              bgcolor: 'success.light',
              color: '#fff',
            },
          },
        }}
      >
        {/* <div className="icon active">1</div>
        <div className="icon">2</div> */}
        {arrData.map((item, index) => (
          <div
            className={`icon ${step === index + 1 && 'active'} ${
              step > index + 1 && 'done'
            }`}
            key={index}
          >
            {step > index + 1 ? <Done sx={{ fontSize: '1rem' }} /> : index + 1}
          </div>
        ))}
      </Stack>
      <Stack
        direction="column"
        spacing={3}
        sx={{
          py: '5px',
          '& .text': {
            height: 28,
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {/* <div className="text">
          <p>Onboarding</p>
        </div>
        <div className="text">
          <p>Details</p>
        </div> */}
        {arrData.map((item, index) => (
          <div className="text" key={index}>
            <p>{item}</p>
          </div>
        ))}
      </Stack>
    </Stack>
  );
}
