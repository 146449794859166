import { ThemeProvider } from '@emotion/react';
import { CssBaseline, createTheme } from '@mui/material';
import React from 'react';

export default function Theme({ children }) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#278FD9',
        color2: '#FC5C67',
      },
      white: {
        main: '#fff',
      },
      mute: {
        main: '#DCDFE3',
        dark: '#EFEFEF',
        darktext: '#BABABA',
        text: '#4C535F',
      },
      dark: {
        text: '#687182',
      },
      body: {
        main: '#F5F5F5',
      },
    },

    typography: {
      breadcrumbs: {
        fontWeight: 600,
        fontSize: 'calc(1.2rem + 0.6vw)',
      },
      text: {
        fontSize: '0.8rem',
      },
      title: {
        fontFamily: 'Manrope',
        fontSize: 'calc(1.2rem + 0.6vh)',
        fontWeight: 700,
      },

      color: '#666E7D',
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
