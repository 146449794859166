
import Layout from "../../assets/Layout";
import { VisitsData } from '../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../Style/stylesheet';
import { getAllIntentsForBuilder, updateIntentStatus } from '../../actions/index'
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
    FormControlLabel,
    Grid,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { React, useEffect, useState } from "react";
import ClaimsRemarks from "../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";

const RolesListing = () => {
    const dispatch = useDispatch();
    const [sectionBarActive, setSectionBarActive] = useState('Intents')
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const [intentStatus, setIntentStatus] = useState('')
    const [intentId, setIntentId] = useState('')
    const [allintents, setAllIntents] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    // const [value, setValue] = React.useState < number | null > (2);
    const [finance, setFinance] = useState({
        home: false,
        property: false,
        brokerageManagement: false,
        subscription: false,
        profile: false,

        // Add more checkboxes here
    });


    const [property, setProperty] = useState({
        home: false,
        property: false,
        brokerageManagement: false,
        subscription: false,
        profile: false,

        // Add more checkboxes here
    });

    const handleFinanceCheckbox = (checkboxName) => (event) => {
        setFinance({
            ...finance,
            [checkboxName]: event.target.checked,
        });
    };

    const handlePropertyCheckbox = (checkboxName) => (event) => {
        setProperty({
            ...property,
            [checkboxName]: event.target.checked,
        });
    };








    return (
        <>
            <Layout path="intents">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Roles & Employees - Builder" />
                    <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController>
                </Stack>

                <Box>
                    <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: '600' }}>Finance  Manager</Typography>
                    <Grid container>
                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={finance.home} onChange={handleFinanceCheckbox('home')}  />}
                                label="Home"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={finance.property} onChange={handleFinanceCheckbox('property')} />}
                                label="Property"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={finance.brokerageManagement} onChange={handleFinanceCheckbox('brokerageManagement')} />}
                                label="Brokerage Management"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={finance.subscription} onChange={handleFinanceCheckbox('subscription')} />}
                                label="Subscription"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={finance.profile} onChange={handleFinanceCheckbox('profile')} />}
                                label="Profile"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                </Box>

                <Box>
                    <Typography variant="h6" sx={{ fontFamily: 'Inter', fontWeight: '600', mt:5 }}>Property  Manager</Typography>
                    <Grid container>
                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={property.home} onChange={handlePropertyCheckbox('home')} />}
                                label="Home"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={property.property} onChange={handlePropertyCheckbox('property')} />}
                                label="Property"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={property.brokerageManagement} onChange={handlePropertyCheckbox('brokerageManagement')} />}
                                label="Brokerage Management"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={property.subscription} onChange={handlePropertyCheckbox('subscription')} />}
                                label="Subscription"
                                labelPlacement="end"
                            />
                        </Grid>

                        <Grid item md={4} mt={3}>
                            <FormControlLabel
                                control={<Checkbox checked={property.profile} onChange={handlePropertyCheckbox('profile')} />}
                                label="Profile"
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                </Box>





            </Layout>
        </>
    );
}

export default RolesListing;