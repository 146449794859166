import * as React from 'react';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { enqueueSnackbar } from 'notistack';
import { getLocationByInput } from '../actions';

export default function CustomAutoComplete1({ top3Builders, setTop3Builders }) {
    const [value, setValue] = React.useState("");
    const [locationSearch, setLocationSearch] = React.useState([]);
    const handleKeyDown = (e) => {
        if (e.keyCode == 13) {
            if (top3Builders.length <= 3) {
                setTop3Builders(oldArr => [...oldArr, value]);
            } else {
                enqueueSnackbar("you have already selected top 3 builders", { variant: 'warning' });
            }
        }
    }

    const handleSetFormValues = (value) => {
        getLocationByInput({ searchText: value }).then((res) => {
            if (res.data.data.length > 0) {
                var arr = [];
                for (let index = 0; index < res.data.data.length; index++) {
                    let element = { label: res.data.data[index].location };
                    arr.push(element);
                }
                setLocationSearch(arr);
            }
        }).catch((err) => {
            console.log(err)
        })
    };
    return (
        <>
            {/* <Autocomplete
        multiple
        id="tags-standard"
        options={builders}
        defaultValue={top3Builders}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Multiple values"
            placeholder="Favorites"
          />
        )}
      /> */}
            <Autocomplete
                multiple
                value={top3Builders}
                onChange={(event, newValue) => {
                    if(newValue && newValue.length > 0 && newValue[newValue.length - 1]?.label)
                    {
                        setTop3Builders(oldArr => [...oldArr, newValue[newValue.length - 1]?.label])
                    }else{
                        console.log("We are here",newValue)
                        setTop3Builders([])
                    }
                }}
                // inputValue={inputValue}
                // onInputChange={(event, newInputValue) => {
                //   setInputValue(newInputValue);
                // }}
                id="controllable-states-demo"
                sx={{ width: '100%' }}
                options={locationSearch}
                renderInput={(params) => <TextField sx={{width:'100%'}} {...params} onChange={(e) => handleSetFormValues(e.target.value)}
                    label="Select Location" variant="standard" />}
            />
        </>
    );
}