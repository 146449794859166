import React, { useCallback, useEffect } from "react";
import Layout from "../../../assets/Layout";
import { sectionBarBtn } from "../../../Style/stylesheet";

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  Paper,
  Button,
} from "@mui/material";

import {
  ArrowBackIosNewRounded,
  ArrowForwardIosRounded,
  Draw,
} from "@mui/icons-material";

import { useState } from "react";
import Breadcrumbs from "../../../assets/Breadcrumbs";
import SearchFilter from "../../../assets/SearchFilter";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAllClaims } from "../../../actions";

const Claims = () => {
  const [sectionBarActive, setSectionBarActive] = useState("Paid");
  const [sectionBarActive2, setSectionBarActive2] = useState("Claims");
  const [currentPage, setCurrentPage] = useState(1);
  const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const headData = [
    "Claim ID",
    "Transaction  Id",
    "Transaction date",
    "Transaction Amount",
    "Broker Id"
  ];

  const [claimsPaid, setClaimsPaid] = useState([]);
  const [allClaims, setAllClaims] = useState([]);
  const loadAllClaims = useCallback(() => {
    dispatch(
      getAllClaims({ claimStatus: "paid" }, (data) => {
        const filter = data?.data.filter((item) => {
          if (
            item?.claimStatus.toLowerCase().includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.milestoneNumber
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.builderId?.name
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.brokerageAmount
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.createdAt.toLowerCase().includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.propertyId?.name
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (
            item?.brokerId?._id
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          if (item?._id.toLowerCase().includes(searchQuery?.toLowerCase())) {
            return true;
          }

          if (
            item?.propertyId?._id
              .toLowerCase()
              .includes(searchQuery?.toLowerCase())
          ) {
            return true;
          }

          return false;
        });
        if (searchQuery) {
          setClaimsPaid(filter);
        }
        setAllClaims(data.data);
      })
    );
  }, [dispatch, searchQuery]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      loadAllClaims();
    }
  }, [loadAllClaims, navigate]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      dispatch(
        getAllClaims(
          {
            claimStatus: "paid",
            pageNo: currentPage,
            pageSize: countPerPage,
          },
          (data) => {
            if (searchQuery == "") {
              setClaimsPaid(data.data);
            }
          }
        )
      );
    }
  }, [currentPage, countPerPage, searchQuery]);

  return (
    <>
      <Layout path="normalVisits">
        <Stack
          direction={{ md: "row", xs: "column" }}
          spacing={3}
          sx={{ my: 4 }}
          justifyContent={"space-between"}
        >
          <Breadcrumbs primary="Transaction History" />
          {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Received" && "active"}
            onClick={() => navigate("/transaction-history/recieved-invoices")}
          >
            Received
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive === "Paid" && "active"}
            onClick={() => navigate("/transaction-history/paid-claims")}
          >
            Paid
          </Button>
        </Stack>

        <Stack
          direction={"row"}
          sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
          spacing={3}
          mx={20}
        >
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive2 === "Claims" && "active"}
            onClick={() => navigate("/transaction-history/paid-claims")}
          >
            Claims
          </Button>
          <Button
            sx={sectionBarBtn}
            className={sectionBarActive2 === "Referal" && "active"}
            onClick={() => navigate("/transaction-history/paid-referal")}
          >
            Referal
          </Button>
        </Stack>

        <SearchFilter my={2}>
          <Box className="search style-1">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
        </SearchFilter>

        <Paper sx={{ width: "100%", overflow: "hidden", my: 2 }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                  <TableCell>#</TableCell>
                  {headData.map((item, index) => (
                    <TableCell
                      key={index}
                      style={{ minWidth: "200px" }}
                      align="left"
                    >
                      <Typography
                        sx={{
                          color: "mute.darktext",
                          textTransform: "uppercase",
                          fontSize: "0.9rem",
                        }}
                      >
                        {item}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {claimsPaid?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                  >
                    {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item?._id}</TableCell>
                    <TableCell>{item?.transactionDate ? item?.transactionDate : "-"}</TableCell>
                    <TableCell>{item?.transactionDate ? new Date(item?.transactionDate).toLocaleDateString() : "-"}</TableCell>
                    <TableCell>{item?.transactionAmount ? item?.transactionAmount : "-"}</TableCell>
                    <TableCell>{item?.brokerId?._id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box
          sx={{
            bgcolor: "mute.dark",
            borderRadius: 1,
            color: "dark.text",
            p: 2,
            mt: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>
              1 - {claimsPaid?.length >= 10 ? 10 : claimsPaid?.length} of{" "}
              {claimsPaid?.length}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: " center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography component="p">Rows per page:</Typography>
            </Box>
            <Box>
              <select
                style={{
                  fontSize: "0.8rem",
                  height: "35px",
                  padding: "0",
                  background: "#FFF",
                }}
                value={countPerPage}
                onChange={(e) => setCountPerPage(e.target.value)}
                disabled={countPerPage > claimsPaid?.length}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box>
                  <IconButton
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    sx={{ border: "1px solid", borderColor: "mute.main" }}
                  >
                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
                <Box>
                  <Typography variant="text" component={"span"}>
                    {currentPage}/
                    {claimsPaid &&
                      claimsPaid?.length > 0 &&
                      Math.ceil(claimsPaid?.length / countPerPage)}
                  </Typography>
                </Box>
                <Box>
                  <IconButton
                    disabled={
                      Math.ceil(claimsPaid?.length / countPerPage) >= currentPage
                    }
                    onClick={() => setCurrentPage(currentPage + 1)}
                    sx={{
                      border: "1px solid",
                      borderColor: "mute.main",
                      bgcolor: "white.main",
                    }}
                  >
                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                  </IconButton>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Layout>

      {/* <VisitRemarks
                remark={remark}
                setRemark={setRemark}
                claimId={claimId}
                claimStatus={status}
                addRemarkAction={addRemarkAction}
                setAddRemarkAction={setAddRemarkAction}
            /> */}
    </>
  );
};

export default Claims;
