import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../assets/Layout";
import InputStyle from "../../assets/InputStyle";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import TableStyle from "../../assets/TableStyle";
import { Check, DeleteForever, Draw } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {
  addFurnishingDetail,
  deleteFurnishingDetail,
  getAllFurnishingDetails,
  updateFurnishingDetail,
} from "../../actions";
import { enqueueSnackbar } from "notistack";

export default function FurnishingDetail() {
  const [quantityNumber, setQuantityNumber] = useState("");
  const [name, setName] = useState("");
  const [allfurnishingDetails, setAllFurnishingDetails] = useState([]);
  const [tempIndex, setTempIndex] = useState("");
  const [tempName, setTempName] = useState("");
  const [tempQuantity, setTempQuantity] = useState("");

  const dispatch = useDispatch();

  const loadALLFurnishingDetails = useCallback(() => {
    dispatch(
      getAllFurnishingDetails({}, (data) => {
        setAllFurnishingDetails(data);
      })
    );
  }, [dispatch]);

  useEffect(() => {
    loadALLFurnishingDetails();
  }, [loadALLFurnishingDetails]);

  const handleDelete = (id) => {
    dispatch(
      deleteFurnishingDetail(id, {}, (data) => {
        enqueueSnackbar("Data deleted succesfully", { variant: "success" });
        loadALLFurnishingDetails();
      })
    );
  };

  const handleEditFurnishing = (index) => {
    setTempIndex(index);
    setTempName(allfurnishingDetails[index].name);
    setTempQuantity(allfurnishingDetails[index].furnishingQuantity);
  };

  const handleUpdateFurnishing = (index) => {
    const val = allfurnishingDetails[index];
    dispatch(
      updateFurnishingDetail(
        { id: val._id, furnishingQuantity: tempQuantity, name: tempName },
        (data) => {
          enqueueSnackbar("Data updated successfully", { variant: "success" });
          loadALLFurnishingDetails();
          setTempIndex("");
        }
      )
    );
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (!quantityNumber || !name) {
      enqueueSnackbar("please fill all the fields", { variant: "error" });
    } else {
      dispatch(
        addFurnishingDetail(
          { furnishingQuantity: quantityNumber, name },
          (data) => {
            enqueueSnackbar("Data added succesfully", { variant: "success" });
            setQuantityNumber("");
            setName("");
            loadALLFurnishingDetails();
          }
        )
      );
    }
  }

  return (
    <Layout>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography
            variant="title"
            sx={{ fontWeight: 900, mb: 4 }}
            component={"h3"}
          >
            Furnishing Detail
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={5}>
              <Stack spacing={3} component="form" onSubmit={handleSubmit}>
                <InputStyle name="Name" topSpace={true}>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </InputStyle>
                <InputStyle name="Furnishing Quantity" topSpace={true}>
                  <input
                    type="number"
                    placeholder="Quantity Number"
                    value={quantityNumber}
                    onChange={(e) => setQuantityNumber(e.target.value)}
                  />
                </InputStyle>
                <Button type="submit" variant="contained" fullWidth>
                  Add
                </Button>
              </Stack>
            </Grid>
            <Grid item md={7}>
              <TableStyle>
                <tr>
                  <th>Furnishing Quantity</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
                <tbody>
                  {allfurnishingDetails.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {tempIndex === index ? (
                          <input
                            type="text"
                            name=""
                            id=""
                            placeholder="Full Name"
                            value={tempQuantity}
                            onChange={(e) => setTempQuantity(e.target.value)}
                          />
                        ) : (
                          item.furnishingQuantity
                        )}
                      </td>
                      <td>
                        {tempIndex === index ? (
                          <input
                            type="text"
                            placeholder="Quantity Number"
                            value={tempName}
                            onChange={(e) => setTempName(e.target.value)}
                          />
                        ) : (
                          item.name
                        )}
                      </td>
                      <td>
                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent={"center"}
                        >
                          <div>
                            {tempIndex === index ? (
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleUpdateFurnishing(index)}
                              >
                                <Check />
                              </IconButton>
                            ) : (
                              <IconButton
                                size="small"
                                color="primary"
                                onClick={() => handleEditFurnishing(index)}
                              >
                                <Draw />
                              </IconButton>
                            )}
                          </div>
                          <div>
                            <IconButton color="error" size="small">
                              <DeleteForever
                                onClick={() => handleDelete(item._id)}
                              />
                            </IconButton>
                          </div>
                        </Stack>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableStyle>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Layout>
  );
}
