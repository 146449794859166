import React, { useState } from "react";
import { Autocomplete, Card, CardActionArea, Dialog, DialogContent, DialogTitle, Divider, Grid, Icon, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/base";
import { Check, Delete, DeleteForever, Done, Draw, ModeEditOutline } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { addPastPropertyForBuilder, deleteBuilderPastPropertyById, getAllPastProperties, getAllPastPropertiesForBuilder, getAllPropertyCategory, getBuilderDetails, getLocationByInput, updatePastProperties, uploadDocToS3 } from "../actions";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../assets/Layout";
import { sectionBarBtn, selectBar, selectBarBtn } from '../Style/stylesheet';
import { enqueueSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
import ClearIcon from "@mui/icons-material/Clear";
import {
    uplodeImageBtn,
} from "../Style/stylesheet";
import UploadDataBox from "../assets/UploadDataBox";
import UploadData from "../assets/UploadData";
import UploadDataCard from "../assets/UploadDataCard";
import TableStyle from "../assets/TableStyle";
const PastProperties = () => {
    const dispatch = useDispatch();
    const builderId = useParams();
    const navigate = useNavigate();
    const [profilePic, setProfilePic] = React.useState([])
    const [profilePictureFiles, setProfilePictureFiles] = React.useState([]);
    const [propertyName, setPropertyName] = React.useState('');
    const [location, setLocation] = React.useState('');
    const [price, setPrice] = React.useState('');
    const [propertyCategory, setPropertyCategory] = React.useState('')
    const [allPastProperties, setAllPastProperties] = React.useState([])
    const [tempIndex, setTempIndex] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false);
    const [locationSelected, setLocationSelected] = React.useState('');
    const [locationSearch, setLocationSearch] = React.useState([]);
    const [open, setOpen] = useState(false);
    const [tempIndex2, setTempIndex2] = useState("");
    const [builderDetail, setBuilderDetail] = React.useState({
        _id: "",
        name: "",
        phoneNumber: "",
        locationOfProperty: "",
        companyName: "",
        companyType: "",
        gst: "",
        panOfCompany: "",
        description: "",
        profilePictureUrl: "",
        logoUrl: "",
        documents: [],
    });
    const [profilePicView, setProfilePicView] = useState([]);
    const [tempPropertyIconPicView, setTempPropertyIconPicView] = useState("");
    const [tempName, setTempName] = useState("");
    const [tempLocation, setTempLocation] = useState("");
    const [tempUnitType, setTempUnitType] = useState("");
    const [locationSelected2, setLocationSelected2] = React.useState("");
    const [updateId, setUpdateId] = useState("");
    const [updateImages, setUpdateImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState([]);

    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer'
    };


    const [value, setValue] = useState()
    const [tempPlan, setTempPlan] = useState()
    const [tempMilestone, setTempMilestone] = useState()
    const [category, setCategory] = useState([]);
    const [tempPrice, setTempPrice] = useState('');
    const [tempPropertyType, setTempPropertyType] = useState('')

    const [sectionBarActive, setSectionBarActive] = useState('Properties');
    const [pastProeperty, setPastProperty] = useState({
        propertyName: "",
        builderId: builderId?.id,
        location: "",
        price: "",
        propertyType: "",
        profilePicView: [],
    });

    const handleSetFormValues = (name, value) => {
        if (name === 'location') {
            getLocationByInput({ searchText: value }).then((res) => {
        if (res.data.data.length > 0) {
                    var arr = [];
                    for (let index = 0; index < res.data.data.length; index++) {
                        let element = { label: res.data.data[index].location };
                        arr.push(element);
                    }
                    setLocationSearch(arr);
                }
            }).catch((err) => {
                console.log(err)
            })
        }
        setLocation((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
    };



    const [imageLoading, setImageLoading] = useState(false);
    const handleProfileImageChange = (event) => {

        const file = event.target.files;
        if (file && file?.length > 0) {
            for (let index = 0; index < file.length; index++) {
                setImageLoading(true);
                let formData = new FormData();
                formData.append('documents', file[index]);
                dispatch(
                    uploadDocToS3(formData, (data) => {
                        setProfilePic(oldArr => [...oldArr, data.data.documents[0]]);
                        setProfilePictureFiles(oldArr => [...oldArr, data.data.documents[0]]);
                        setImageLoading(false);
                    }, (err) => {
                        setImageLoading(false);
                    })
                );
            }

        }
    };

    // const handleUpdatePackage = () => {

    // }

    const handleRemoveFromPlan = (index) => {

    }

    // const handleEditPackage = (index) => {
    //     const updatedProperties = allPastProperties.map((item, i) => {
    //         if (i === index) {
    //             return {
    //                 ...item,
    //                 propertyName: tempPlan,
    //                 location: tempMilestone,
    //                 price: price,
    //                 propertyType: tempPropertyType

    //             };
    //         }
    //         return item;
    //     });
    //     console.log("updatedProperties", updatedProperties)
    //     setAllPastProperties(updatedProperties);
    //     setTempIndex("");
    //     setTempPlan("");
    //     setTempMilestone("");
    // }

    React.useEffect(() => {
        dispatch(getAllPropertyCategory({}, (data) => {
            setCategory(data.data)
        }))
        dispatch(getAllPastProperties({ builderId: builderId?.id.toString() }, (data) => {
            setAllPastProperties(data.data)
        }))
    }, [])

    const handleProfileImageRemoval = (index) => {
        setProfilePictureFiles(old => {
            old.splice(index, 1)
            return [...old]
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getDetailsOfCurrentBuilder = () => {
        dispatch(
            getBuilderDetails({ id: builderId?.id }, (data) => {
                const builderData = data[0];
                setBuilderDetail({
                    _id: builderData._id,
                    name: builderData.name,
                    phoneNumber: builderData.phoneNumber,
                    locationOfProperty: builderData.address,
                    companyName: builderData.companyName,
                    companyType: builderData.companyType,
                    gst: builderData.gst,
                    panOfCompany: builderData.panOfCompany,
                    description: builderData.description,
                    profilePictureUrl: builderData.profilePictureUrl,
                    logoUrl: builderData.logoUrl,
                    documents: builderData.documents,
                });
                setLocationSelected(builderData.address);
                setValue(builderData?.description);
            })
        );

        dispatch(
            getAllPastPropertiesForBuilder({ builderId: builderId?.id }, (data) => {
                console.log("Here is The Past Properties Of the Builder:", data?.data);
                setAllPastProperties(data?.data);
            })
        );
    };

    const loadAllProperties = React.useCallback(() => {
        dispatch(
            getAllPastPropertiesForBuilder({ builderId: builderId?.id }, (data) => {
                setAllPastProperties(data.data);
            })
        );
    }, [dispatch]);

    const handleDeletePastProperty = (id) => {
        dispatch(
            deleteBuilderPastPropertyById({ id: id }, (data) => {
                getDetailsOfCurrentBuilder();
                enqueueSnackbar(data?.message, { variant: "success" });
            })
        );
    };

    const handleEditPackage = (index, forImages = false) => {
        if (!forImages) setTempIndex(index);
        setProfilePic(JSON.parse(allPastProperties[index].imageUrl));
        setProfilePictureFiles(JSON.parse(allPastProperties[index].imageUrl));
        setTempPropertyIconPicView(allPastProperties[index].imageUrl[0]);
        setPropertyName(allPastProperties[index].propertyName);
        setLocation(allPastProperties[index].location);
        setLocationSelected(allPastProperties[index].location);
        setPrice(allPastProperties[index].price);
        setPropertyCategory(allPastProperties[index].propertyType._id);
        handleSetFormValues("location", allPastProperties[index].location);
    };

    const handleUpdatePackage = (e, index) => {
        e.preventDefault();

        if (!allPastProperties[index].propertyName || !allPastProperties[index].location || !allPastProperties[index].price || !allPastProperties[index].propertyType._id) {
            enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
            return;
        }

        const val = allPastProperties;
        let formData = new FormData();
        formData.append("propertyName", allPastProperties[index].propertyName);
        formData.append("builderId", builderId?.id);
        formData.append("location", allPastProperties[index].location);
        formData.append("id", val[index]._id);
        formData.append("propertyType", allPastProperties[index].propertyType._id);
        formData.append("price", allPastProperties[index].price);
        formData.append("imageUrl", JSON.stringify(allPastProperties[index].imageUrl));

        // if (profilePic.length > 0) {
        //   console.log(profilePic);
        //   for (let i = 0; i < profilePic.length; i++) {
        //     formData.append("file", profilePic[i]);
        //   }
        // }

        val[index] = {
            ...val[index],
            imageUrl: allPastProperties[index].imageUrl,
            propertyName: tempName,
            location: tempLocation,
            propertyType: category.filter((val) => {
                return val._id === tempPropertyType;
            })[0],
            price: tempPrice,
        };
        setAllPastProperties([...val]);
        dispatch(
            updatePastProperties(formData, (data) => {
                enqueueSnackbar("Data updated successfully", { variant: "success" });
                loadAllProperties();
                setProfilePic([]);
                setProfilePicView([]);
                setTempName("");
                setPropertyName("");
                setLocation("");
                setPrice("");
                setPropertyCategory("");
                setLocationSelected2("");
                setTempPrice("");
                setTempPropertyType("");
                setTempIndex("");
                setProfilePictureFiles([]);
                setLocationSelected("");
            })
        );

        setTempIndex("");
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!propertyName || !location || !price || !propertyCategory) {
            enqueueSnackbar('Please fill in all the required fields.', { variant: 'error' });
            return;
        }
        setIsLoading(true);

        const formData = new FormData();
        formData.append('propertyName', propertyName);
        formData.append('builderId', builderId?.id);
        formData.append('location', locationSelected);
        formData.append('price', price);
        formData.append('propertyType', propertyCategory);
        // formData.append('file', profilePictureFiles);
        formData.append("imageUrl", JSON.stringify(profilePictureFiles)); // Assuming API expects an array of images with key 'images'

        dispatch(addPastPropertyForBuilder(formData, (data) => {
            enqueueSnackbar('Property Successfully Added', { variant: 'success' });
            setPropertyName("");
            setLocationSelected("");
            setLocationSearch([]);
            setProfilePictureFiles([]);
            setPrice("");
            setPropertyCategory("");
            setIsLoading(false);
            dispatch(getAllPastProperties({ builderId: builderId?.id.toString() }, (data) => {
                setAllPastProperties(data.data)

            }))
        }, (err) => {
            const message = err?.response?.data ? err?.response?.data : "Error Occured! Please try after some time";
            enqueueSnackbar(message, { variant: 'error' });
            setIsLoading(false);
        }))
    }

    return (
        <Layout path="builder-management">

            {/* <Stack
                direction={"row"}
                sx={{ p: 1, bgcolor: "#fff", mb: 2, borderRadius: 10 }}
                spacing={3}
            >
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Profile" && "active"}
                >
                    Profile
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Properties" && "active"}
                    onClick={() =>
                        navigate("/past-properties/" + builderId?.id)
                    }
                >
                    Properties
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Invoices" && "active"}
                    onClick={() =>
                        navigate("/builder-management/invoices/")
                    }
                >
                    Invoices
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Subscription" && "active"}
                    onClick={() =>
                        navigate("/builder-management/subscription/")
                    }
                >
                    Subscription
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "Queries" && "active"}
                    onClick={() =>
                        navigate("/builder-management/queries")
                    }
                >
                    Queries
                </Button>
                <Button
                    sx={sectionBarBtn}
                    className={sectionBarActive === "PropertyEditRequest" && "active"}
                    onClick={() =>
                        navigate("/builder-management/property-edit-request/")
                    }
                >
                    Property Edit Request
                </Button>
            </Stack> */}

            <Paper sx={{ pt: 3 }}>
                <Stack direction={'row'} sx={selectBar} spacing={3} className="col-3">
                    <Box
                        sx={selectBarBtn}
                        className={`done box`}
                    >
                        <Icon className="icon"> <Check /></Icon>
                        OnBoarding
                    </Box>
                    <Box
                        sx={selectBarBtn}
                        className={`active box`}
                    >
                        <Icon className="icon">2</Icon>
                        Past Properties
                    </Box>
                </Stack>
                <Box sx={{ marginTop: '73px', px: 4 }}>
                    <Grid container>
                        <Grid item md={3}>
                            {/* <Box sx={{ py: 2 }}>
                                <Typography>Upload Profile Image</Typography>
                                <Box component="button" sx={{ background: '#FFFFFF', border: 'none', cursor: 'pointer' }}>
                                    {profilePictureFiles ?
                                        <img className='uploadimg' src={profilePictureFiles} alt='upload' />
                                        :
                                        <img className='uploadimg' src={"/Images/uploadImg.png"} alt='upload' />
                                    }
                                    <input
                                        type="file"
                                        accept="image/*"
                                        style={hideInput}
                                        onChange={handleProfileImageChange}
                                    />
                                </Box>
                            </Box> */}
                            <UploadDataBox>
                                <UploadData name="Upload Property Photos">
                                    <Box sx={{ display: 'flex' }}>
                                        {
                                            profilePictureFiles.length > 0
                                            && profilePictureFiles.map((item, index) => (
                                                <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                                    <img
                                                        src={item}
                                                        className="img-fluid"
                                                        style={{ width: '130px', height: '130px' }}
                                                    />
                                                    <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleProfileImageRemoval(index)}>
                                                        <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                                    </IconButton>
                                                </Box>
                                            ))
                                        }
                                        <UploadDataCard>
                                            <img
                                                src={
                                                    '/uploadImg.png'
                                                }
                                                alt="upload"
                                                className="img-fluid"
                                            />
                                            <input type="file" accept="image/*" onChange={handleProfileImageChange} multiple disabled={imageLoading} />
                                        </UploadDataCard>
                                    </Box>
                                </UploadData>
                            </UploadDataBox>
                        </Grid>
                    </Grid>
                    <Divider sx={{ height: '1.5px', background: '#E0E4EC', marginTop: '73px', marginBottom: '34px' }} />
                    <form>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <label for="fname">Property Name*</label>
                                <input required className='inputBox' type="text" placeholder="Enter Property Name" value={propertyName} onChange={(e) => setPropertyName(e.target.value)} name="name" />
                            </Grid>
                            <Grid item xs={6}>
                                <label for="fname">Location* </label>
                                <Autocomplete
                                    onChange={(event, value1) => setLocationSelected(value1?.label)}
                                    value={locationSelected}
                                    disablePortal
                                    name="location"
                                    id="combo-box-demo"
                                    options={locationSearch}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField label="Location" className='inputBox' {...params} value={location} onChange={(e) => handleSetFormValues(e.target.name, e.target.value)} name='location' />}
                                />
                                {/* <input required className='inputBox' type="text" name="location" value={location} onChange={(e) => setLocation(e.target.value)} placeholder="Enter location" /> */}
                            </Grid>

                            <Grid item xs={6}>
                                <label for="fname">Price*</label>
                                <input required className='inputBox' type="text" name="price" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Enter price" />
                            </Grid>

                            <Grid item xs={6}>
                                <label>Property Type*</label>

                                <select value={propertyCategory} onChange={(e) => setPropertyCategory(e.target.value)}>
                                    <option value="" disabled>
                                        Property Type
                                    </option>
                                    {category.length > 0 &&
                                        category.map((item, index) => (
                                            <option value={item?._id} key={index}>
                                                {item.name}
                                            </option>
                                        ))}
                                </select>
                            </Grid>

                        </Grid>
                        <Typography sx={{ fontSize: '19px', color: '#F1323D', fontWeight: '600', marginTop: '44px', marginBottom: '22px' }}>
                            "Fields marked with an asterisk (*) are required."
                        </Typography>
                        {isLoading ?
                            <Button disabled type="submit" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#E5E5E5', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={handleSubmit}><CircularProgress size={24} sx={{ color: '#278FD9' }} /></Button>
                            :
                            tempIndex === ""
                                ?
                                <Button type="submit" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={handleSubmit}>Add</Button>
                                :
                                <Button type="button" style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px' }} onClick={(e) => handleUpdatePackage(e, tempIndex)}>Update</Button>
                        }
                        <Button style={{ width: '224px', height: '52px', borderRadius: '8.62px', background: '#278FD9', color: '#FFFFFF', fontWeight: '700', fontSize: '19px', marginBottom: '33px', marginLeft: '20px' }} onClick={() => navigate('/builder-management')}>Done</Button>
                    </form>
                    {allPastProperties?.length > 0
                        ?
                        <Box sx={{ pb: 3 }}>
                            <TableStyle>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Location</th>
                                    <th>Price</th>
                                    <th>Property Type</th>
                                    <th>Action</th>
                                </tr>
                                <tbody>
                                    {allPastProperties.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                <img
                                                    src={
                                                        item?.imageUrl && JSON.parse(item?.imageUrl)?.length > 0
                                                            ? JSON.parse(item?.imageUrl)[0]
                                                            : 'https://www.investopedia.com/thmb/bfHtdFUQrl7jJ_z-utfh8w1TMNA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/houses_and_land-5bfc3326c9e77c0051812eb3.jpg'
                                                    }
                                                    alt="profile"
                                                    style={{
                                                        maxHeight: '100px',
                                                        maxWidth: '100px',
                                                        cursor: 'pointer',
                                                    }}
                                                    className="img-fluid"
                                                    onClick={() => {
                                                        // handleEditPackage(index, true);
                                                        setUpdateId(item._id);
                                                        setUpdateImages(
                                                            JSON.parse(item?.imageUrl)
                                                        );
                                                        handleClickOpen();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                {item?.propertyName}
                                            </td>
                                            <td>
                                                {item?.location}
                                            </td>
                                            <td>
                                                {item?.price}
                                            </td>
                                            <td>
                                                {item?.propertyType?.name}
                                            </td>
                                            <td>
                                                <Stack direction="row" spacing={2} justifyContent="center">
                                                    <div>
                                                        <IconButton size="small" color="primary">
                                                            {tempIndex === index ? (
                                                                <Done
                                                                    onClick={(e) => handleUpdatePackage(e, index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            ) : (
                                                                <Draw
                                                                    onClick={() => handleEditPackage(index)}
                                                                    sx={{ cursor: 'pointer' }}
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </div>
                                                    <div onClick={() => handleDeletePastProperty(item?._id)}>
                                                        <IconButton color="error" size="small">
                                                            <DeleteForever
                                                                onClick={() => handleRemoveFromPlan(item._id)}
                                                            />
                                                        </IconButton>
                                                    </div>
                                                </Stack>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </TableStyle>
                            {/* <Stack spacing={3} sx={{ my: 3 }}>
                                <Typography color="error" component="p">
                                    "Fields marked with an asterisk (*) are required."
                                </Typography>
                                {isLoading ? (
                                    <Button
                                        disabled
                                        variant="contained"
                                        sx={{ boxShadow: 0, width: 150, mr: 1 }}
                                    >
                                        <CircularProgress size={24} sx={{ color: '#fff' }} />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="contained"
                                        sx={{ boxShadow: 0, width: 150, mr: 1 }}
                                        type="submit"
                                        disabled={tempIndex != ""}
                                        onClick={(e) => handleAddPastProperties(e)}
                                    >
                                        Update
                                    </Button>
                                )}
                            </Stack> */}
                        </Box>
                        :
                        ""}
                </Box>
            </Paper>
            <Dialog
                maxWidth={"sm"}
                fullWidth={true}
                size="lg"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Images</DialogTitle>
                <DialogContent>
                    {/* <Box sx={{ display: "flex" }}>
                        {updateImages.length > 0
                            ?
                            updateImages?.map((item, index) => (
                                <a href={item} download key={index} target="_blank">
                                    <img src={item} alt="past property" style={{ width: '180px', height: '180px' }} />
                                </a>
                            ))
                            :
                            "No Images"}
                    </Box> */}
                    <Box sx={{ display: "flex" }}>
                        {updateImages.length > 0 &&
                            updateImages.map((item, index) => (

                                <a href={item} target="_blank" key={index} download>
                                    <Box
                                        mx={"8px"}
                                        height={"140px"}
                                        borderRadius={"16px"}
                                        overflow={"hidden"}
                                        sx={{ aspectRatio: "1/1", position: "relative" }}
                                    >
                                        <img
                                            src={item}
                                            alt="profile"
                                            className="img-fluid"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                                backgroundColor: "grey",
                                                zIndex: 1,
                                            }}
                                        />

                                        {/* <IconButton
                    aria-label="clear_image"
                    sx={{
                      position: "absolute",
                      zIndex: 2,
                      right: "5px",
                      top: "5px",
                      background: "#278FD9aa",
                      height: "25px",
                      width: "25px",
                    }}
                    onClick={() => {
                      let temp = updateImages;
                      temp.splice(index, 1);
                      setUpdateImages([...temp]);
                    }}
                  >
                    <ClearIcon color={"white"} sx={{ fontSize: ".8rem" }} />
                  </IconButton> */}

                                    </Box>
                                </a>
                            ))}
                        {/* <Stack direction="row" spacing={2}>
              <Card sx={uplodeImageBtn}>
                <CardActionArea className="button">
                  <img
                    src={"/Images/uploadImg.png"}
                    alt="profile"
                    className="img-fluid"
                  />

                  <input
                    type="file"
                    name="profileImage"
                    id="profileImageInput"
                    onChange={(e) => {
                      setUpdateImages((old) => {
                        return [
                          ...old,
                          {
                            imageUrl: URL.createObjectURL(e.target.files[0]),
                            imageObject: e.target.files[0],
                          },
                        ];
                      });
                    }}
                  />
                </CardActionArea>
              </Card>
            </Stack> */}
                    </Box>
                </DialogContent>
            </Dialog>
        </Layout>
    );
}

export default PastProperties;