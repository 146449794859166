import React, { useState } from 'react';
import {
    Box,
    Typography,
    Stack,
    Divider,
    Grid,
    Button,
    Dialog,
    DialogContent,
    IconButton,
    CircularProgress,
} from '@mui/material';
import InputStyle from '../../assets/InputStyle';
import { Close } from '@mui/icons-material';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch } from 'react-redux';
import { getTutorialById, updateTutorial } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';

export default function UpdateTraining({ setProfileAction, profileAction, id }) {
    const dispatch = useDispatch()
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [link, setLink] = useState("");
    const [loading, setLoading] = useState("");

    const hideInput = {
        opacity: 0,
        position: 'absolute',
        left: '26.3%',
        zIndex: 10,
        width: '100px',
        height: '100px',
        cursor: 'pointer',
    };

    useEffect(() => {
        dispatch(getTutorialById({ id }, (data) => {

            console.log("TutorialById data = ", data);
            setTitle(data.data[0].title)
            setDescription(data.data[0].description)
            setLink(data.data[0].link)
            //   setDate(data.data[0].blogDate)

        }, (err) => {
            console.log(err);
        },
        ))
    }, [id]);

    const handleSubmit = () => {
        setLoading(true);
        if (!title || !description || !link) {
            enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
            setLoading(false);
            return;
        }
        // const linkPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;
        const linkPattern = /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;

        if (!linkPattern.test(link)) {
            enqueueSnackbar('Please enter a valid link', { variant: 'error' });
            setLoading(false);
            return;
        }
        // let payload = {
        //     id,
        //   title,
        //   description: description.trim().replace(/\n+/g, '\r'),// replace multiple line,
        //   link 
        // }
        let formData = new FormData();

        formData.append("id", id);
        formData.append("title", title);
        formData.append("description", description.trim().replace(/\n+/g, '\r'));
        formData.append("link", link);

        dispatch(
            updateTutorial(formData, (data) => {
                console.log("Here is Data:", data);
                enqueueSnackbar('Tutorial Updated Successfully', { variant: 'success' });
                setLoading(false);
                setProfileAction(false)
                window.location.reload();
            })
        );
    }

    const handleReset = () => {
        setTitle('');
        setDescription('');
        setLink('');
    };




    return (
        <Dialog
            open={profileAction}
            onClose={() => {
                setProfileAction(!profileAction);
            }}
            maxWidth="md"
            fullWidth
        >
            <DialogContent sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                >
                    <div>
                        <Typography variant="title" component="h3">
                            Update Training
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            sx={{ border: '2px solid #DFDFE6' }}
                            size="small"
                            onClick={() => setProfileAction(false)}
                        >
                            <Close sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </div>
                </Stack>
                <Box sx={{ mb: 2 }}>
                    {/* <UploadDataBox>
                        <UploadData name={'Upload Video Thumbnail'}>
                            <UploadDataCard>
                                {image.length > 0 ?

                                    <>
                                        <img
                                            src={URL.createObjectURL(image[0])}
                                            alt="upload"
                                            className="img-fluid"
                                        />
                                        <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '5px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={handleRemovePropertyLogo}>
                                            <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                        </IconButton>
                                    </>
                                    :
                                    <>
                                        <img
                                            src={'/Images/uploadImg.png'}
                                            alt="upload"
                                        />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={hideInput}
                                            onChange={handleUploadLogo}
                                        />
                                    </>
                                }
                            </UploadDataCard>
                        </UploadData>
                    </UploadDataBox> */}
                </Box>
                <Divider />

                <Box sx={{ my: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item md={6}>
                            <InputStyle name="Training Name*">
                                <input type="text" placeholder="Enter training name" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </InputStyle>
                        </Grid>
                        <Grid item md={6}>
                            <InputStyle name="Training Except*">
                                <input type="text" placeholder="Enter training except" value={description} onChange={(e) => setDescription(e.target.value)} />
                            </InputStyle>
                        </Grid>
                        <Grid item md={6}>
                            <InputStyle name="Video Link*">
                                <input type="text" placeholder="Enter video link" value={link} onChange={(e) => setLink(e.target.value)} />
                            </InputStyle>
                        </Grid>
                        <Grid item md={12}>
                            <Button
                                variant="contained"
                                sx={{ boxShadow: 0, width: 150, mr: 1 }}
                                onClick={() => handleSubmit()}
                                disabled={loading}
                            >
                                {
                                    loading
                                        ?
                                        <CircularProgress size={24} />
                                        :
                                        "Update"
                                }
                            </Button>
                            <Button variant="outlined" sx={{ width: 150 }} onClick={handleReset}>
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog >
    );
}
