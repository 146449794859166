import React, { useEffect, useState } from "react";
import { Box, Grid, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../CustomDialog";
import { useDispatch } from "react-redux";
import { addEmployee, getAllRoles } from "../../actions";
import { enqueueSnackbar } from "notistack";

function AddEmployee(props) {
    const [selectedRole, setSelectedRole] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("token")) {
            navigate("/");
        }
    }, [navigate]);

    useEffect(() => {
        dispatch(getAllRoles({}, (data) => {
            setRoles(data.data);
        }))
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const payload = {
            employeeName: name,
            email,
            password,
            role: selectedRole
        }
        dispatch(addEmployee(payload, (data) => {
            enqueueSnackbar("Employee Created successfully", { variant: "success" });
            setLoading(false);
            setTimeout(() => window.location.reload(), 200);
        }, (err) => {
            console.log(err);
            enqueueSnackbar("Error Occured! while creating employee", { variant: "error" })
        }))
    }
    return (
        <CustomDialog
            title="Add New Employee"
            open={props.open}
            setOpen={props.setOpen}
            size={props.size}
        >
            <>
                <Box sx={{ padding: "0px 10px" }}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <label>Employee Name*</label>
                                <input
                                    className="inputBox"
                                    type="text"
                                    name="name"
                                    placeholder="Enter employee name"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <label>Email*</label>
                                <input
                                    className="inputBox"
                                    type="email"
                                    name="email"
                                    placeholder="Enter employee email"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <label>Role*</label>
                                <select
                                    className="inputBox"
                                    required
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                >
                                    <option selected disabled value={""}>Select Role</option>
                                    {roles.map((item, index) => (
                                        <option value={item._id}>{item.name}</option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid item xs={12}>
                                <label>Password*</label>
                                <input
                                    className="inputBox"
                                    type="text"
                                    name="password"
                                    placeholder="Enter password"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    disableElevation
                                    type="submit"
                                    variant="contained"
                                    sx={{ px: 10, py: 1, mb: "15px", mr: 3, mt: 2 }}
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={26} /> : "Add"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </>
        </CustomDialog>
    );
}
export default AddEmployee;
