import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const CustomDialog = (props) => {
  return (
    <Dialog
      maxWidth={props?.size ? props.size : "sm"}
      fullWidth={true}
      size={props?.size ? props.size : "sm"}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: 900, fontSize: "1.5rem", letterSpacing: ".04rem" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span style={{fontFamily:'Manrope'}}>{props.title}</span>
          <IconButton
            aria-label="close"
            sx={{
              border: "1px solid #DFDFE6",
              height: "25px",
              width: "25px",
            }}
            onClick={() => props.setOpen(false)}
          >
            <ClearIcon
              sx={{
                color: "black",
                fontSize: "1rem",
              }}
            />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
