import { Close, Download } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Icon,
} from '@mui/material';
import React, { useState } from 'react';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { addNewBroker } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import regex from '../../utils/regex';
import CircularProgress from '@mui/material/CircularProgress';
import { selectBar, selectBarBtn } from '../../Style/stylesheet';
import { Check } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import Preferences from './Preferences';
import BankDetails from './BankDetails';

export default function AddBroker({ addBrokerAction, setAddBrokerAction }) {
  const [isLoading, setIsLoading] = useState(false);
  const closeHandler = () => {
    setBrokerDetails({
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      panNumber: '',
      reraRegistrationNumber: '',
    });
    setProfilePic(null);
    setProfilePicView('');
    setDocument([]);
    setStep(1)
    setAddBrokerAction(false);

  };

  const dispatch = useDispatch()
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
  };
  const [brokerDetails, setBrokerDetails] = React.useState(
    {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
      panNumber: '',
      reraRegistrationNumber: '',
    }
  )
  const [profilePic, setProfilePic] = React.useState(null);
  const [profilePicView, setProfilePicView] = React.useState(null)
  const [document, setDocument] = React.useState([]);
  const [documentView, setDocumentView] = React.useState([])
  const [phoneError, setPhoneError] = React.useState(false);
  const [panError, setPanError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [step, setStep] = useState(1);

  const handleProfileImageChange = (event) => {

    const file = event.target.files[0];
    setProfilePic(file);
    setProfilePicView(URL.createObjectURL(file));
  };

  const handleDocumentsChange = (event) => {
    const files = event.target.files;
    const reader = new FileReader();
    for (var i = 0; i < files?.length; i++) {
      reader.onload = (e) => {
        const pdfUrl = e.target.result;
        setDocumentView(oldArr => [...oldArr, pdfUrl])
      }
      setDocument(oldArr => [...oldArr, files[i]]);
      reader.readAsDataURL(files[i]);
    }
    // Update the state with the selected files

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBrokerDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Regex
  React.useEffect(() => {
    if (brokerDetails.phoneNumber) {
      const isPhoneValid = regex.numberPattern.test(brokerDetails.phoneNumber)
      setPhoneError(!isPhoneValid)
    }
  }, [brokerDetails.phoneNumber])


  React.useEffect(() => {
    if (brokerDetails.email) {
      const isEmailValid = regex.emailPattern.test(brokerDetails.email)
      setEmailError(!isEmailValid)

    }
  }, [brokerDetails.email])

  React.useEffect(() => {
    if (brokerDetails.panNumber) {
      const isPanValid = regex.panPattern.test(brokerDetails.panNumber)
      setPanError(!isPanValid)

    }
  }, [brokerDetails.panNumber])


  const [brokerId, setBrokerId] = useState("");
  const handleSubmit = () => {
    if (step == 1) {
      const isEmpty = Object.values(brokerDetails).some(value => value === '');
      if (isEmpty) {
        enqueueSnackbar("Please fill in all the required fields!", { variant: 'error' });
        return;
      }
      const formData = new FormData();

      // Append broker details to formData
      formData.append('name', brokerDetails.name);
      formData.append('email', brokerDetails.email);
      formData.append('phoneNumber', brokerDetails.phoneNumber);
      formData.append('address', brokerDetails.address);
      formData.append('panNumber', brokerDetails.panNumber);
      formData.append('reraRegistrationNumber', brokerDetails.reraRegistrationNumber);

      // Append profile picture to formData
      if (profilePic) {
        formData.append('profilePicture', profilePic);
      }
      if (document?.length > 0) {
        for (var x = 0; x < document?.length; x++) {
          formData.append('documents', document[x])
        }
      }
      setIsLoading(true);
      dispatch(
        addNewBroker(formData, (data) => {
          setBrokerId(data.data._id);
          if (data.status === 200) {
            enqueueSnackbar("Broker Added Successfully!", { variant: 'success' })
            setProfilePic(null);
            setProfilePicView(null);
            setDocument(null);
            setDocumentView(null);
            setBrokerDetails({
              name: '',
              email: '',
              phoneNumber: '',
              address: '',
              panNumber: '',
              reraRegistrationNumber: '',
            });
            setStep(2);
            setIsLoading(false);
          }
          else {
            enqueueSnackbar("Error While Adding New Broker!", { variant: 'error' })
          }
        }
        )
      )
    }
  };

  const handleRemovePropertyBrochure = (index) => {
    const newArr = documentView.filter((item, index1) => index != index1);
    setDocumentView(newArr);
  }

  const hideInput = {
    opacity: 0,
    position: "absolute",
    left: "3.3%",
    zIndex: 10,
    width: "115px",
    height: "115px",
    cursor: "pointer",
    top: "26%"
  };

  return (
    <Dialog
      open={addBrokerAction}
      onClose={closeHandler}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="end"
          alignItems="left"
          sx={{ mb: 1 }}
        >
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>

        <Stack direction={'row'} sx={selectBar} spacing={3} className="col-3">
          <Box
            sx={selectBarBtn}
            className={`${step === 1 ? 'active' : step >= 2 && 'done'} box`}
          >
            <Icon className="icon">{step >= 2 ? <Check /> : 1}</Icon>
            Onboarding
          </Box>
          <Box
            sx={selectBarBtn}
            className={`${step === 2 ? 'active' : step === 3 && 'done'} box`}
          >
            <Icon className="icon">{step === 3 ? <Check /> : 2}</Icon>
            Bank Detail
          </Box>
          <Box
            sx={selectBarBtn}
            className={`${step === 3 ? 'active' : step === 4 && 'done'} box`}
          >
            <Icon className="icon">{step === 4 ? <Check /> : 3}</Icon>
            Preferences
          </Box>
        </Stack>

        {
          step == 1
            ?
            <>
              <div>
                <Typography variant="title" component="h3">
                  Add Broker
                </Typography>
              </div>
              <Box component={'form'} flexGrow={1}>
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Typography sx={{ fontSize: '0.8rem', margin: '0', color: '#4C535F' }}>Upload Profile Image</Typography>
                    <Box
                      component="button"
                      sx={{ background: "#FFFFFF", border: "none", cursor: "pointer", mt: 2, mr: 2 }}
                    >
                      {profilePicView
                        ?
                        <img
                          src={profilePicView}
                          alt="profile"
                          className="img-fluid"
                          style={{ width: '130px', height: '130px' }}
                        />
                        :
                        <img
                          src='/uploadImg.png'
                          alt="profile"
                          style={{ width: '125px' }}
                          className="img-fluid"
                        />
                      }
                      <input
                        type="file"
                        name="profileImage"
                        id="profileImage"
                        style={hideInput}
                        accept='image/*'
                        onChange={handleProfileImageChange}
                      />
                    </Box>
                  </Box>
                  <Box>
                    <UploadDataBox>
                      <UploadData name="Upload your documents">
                        <label htmlFor="document-upload" style={{ display: 'block', cursor: 'pointer' }}>
                          <Box sx={{ display: 'flex' }}>
                            {documentView?.length > 0 ?
                              documentView?.map((item, index) => (
                                <Box mx={'8px'} height={'140px'} borderRadius={'16px'} overflow={'hidden'} sx={{ aspectRatio: '1/1', position: 'relative' }}>
                                  <embed src={item} width="200" height="200" />
                                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '30px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }} onClick={() => handleRemovePropertyBrochure(index)}>
                                    <ClearIcon color={'white'} sx={{ fontSize: '.8rem' }} />
                                  </IconButton>
                                  <IconButton aria-label='clear_image' sx={{ position: 'absolute', zIndex: 2, right: '0px', top: '5px', background: '#278FD9aa', height: '25px', width: '25px' }}>
                                    <a href={item} download>
                                      <Download color={'white'} sx={{ fontSize: '.8rem' }} />
                                    </a>
                                  </IconButton>
                                </Box>
                              ))
                              :
                              <></>
                            }
                            <UploadDataCard>
                              <img
                                src={'/uploadImg.png'}
                                alt="upload"
                                className="img-fluid"
                              />
                              <input
                                type="file"
                                accept=".doc,.pdf"
                                onChange={handleDocumentsChange}
                              />
                            </UploadDataCard>
                          </Box>
                        </label>
                      </UploadData>
                    </UploadDataBox>
                  </Box>
                </Box>

                <Divider sx={{ my: 4 }} />
                <Box>
                  <Grid container spacing={3}>
                    <Grid item md={6} flexGrow={1}>
                      <InputStyle name="Broker Name">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Enter broker name"
                          value={brokerDetails.name}
                          onChange={handleChange}
                        />
                      </InputStyle>
                    </Grid>
                    <Grid item md={6} flexGrow={1}>
                      <InputStyle name="Phone Number">
                        <input
                          type="text"
                          name="phoneNumber"
                          placeholder="Enter Phone Number"
                          value={brokerDetails.phoneNumber}
                          onChange={handleChange}
                        />
                      </InputStyle>
                      {phoneError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}

                    </Grid>

                    {/* Email input */}
                    <Grid item md={6} flexGrow={1}>
                      <InputStyle name="Email">
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter Email"
                          value={brokerDetails.email}
                          onChange={handleChange}
                        />
                      </InputStyle>
                      {emailError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}

                    </Grid>

                    {/* PAN Number input */}
                    <Grid item md={6} flexGrow={1}>
                      <InputStyle name="PAN Number">
                        <input
                          type="text"
                          name="panNumber"
                          placeholder="Enter PAN Number"
                          value={brokerDetails.panNumber}
                          onChange={handleChange}
                        />
                      </InputStyle>
                      {panError ? <span style={{ color: 'red' }}>Invalid input</span> : ""}

                    </Grid>

                    {/* RERA Registration Number input */}
                    <Grid item md={6} flexGrow={1}>
                      <InputStyle name="RERA Registration Number">
                        <input
                          type="text"
                          name="reraRegistrationNumber"
                          placeholder="Enter RERA Registration Number"
                          value={brokerDetails.reraRegistrationNumber}
                          onChange={handleChange}
                        />
                      </InputStyle>
                    </Grid>

                    {/* Address input */}
                    <Grid item md={6} flexGrow={1}>
                      <InputStyle name="Address">
                        <input
                          type="text"
                          name="address"
                          placeholder="Enter Address"
                          value={brokerDetails.address}
                          onChange={handleChange}
                        />
                      </InputStyle>
                    </Grid>

                    <Grid item md={12} xs={12} flexGrow={1}>
                      <Stack direction={'row'} spacing={3}>
                        {!emailError && !phoneError && !panError ?
                          <Button variant="contained" sx={ButtonStyle} onClick={handleSubmit}>
                            Add
                          </Button>
                          : isLoading ?
                            <Button disabled variant="contained" sx={ButtonStyle} onClick={handleSubmit}>
                              <CircularProgress size={24} sx={{ color: '#fff' }} />
                            </Button> :
                            <Button disabled variant="contained" sx={ButtonStyle} onClick={handleSubmit}>
                              Add
                            </Button>
                        }
                        <Button variant="outlined" sx={ButtonStyle} onClick={() => {
                          setBrokerDetails({
                            name: '',
                            email: '',
                            phoneNumber: '',
                            address: '',
                            panNumber: '',
                            reraRegistrationNumber: '',
                          });
                          setProfilePic(null);
                          setProfilePicView('');
                          setDocument([])
                        }}>
                          Reset
                        </Button>
                      </Stack>
                    </Grid>

                  </Grid>
                </Box>
              </Box>
            </>
            :
            step == 2
              ?
              <BankDetails setStep={setStep} brokerId={brokerId} />
              :
              <Preferences setStep={setStep} setAddBrokerAction={setAddBrokerAction} brokerId={brokerId} />
        }
      </DialogContent>
    </Dialog>
  );
}
