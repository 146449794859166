import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import UploadDataBox from '../../assets/UploadDataBox';
import UploadData from '../../assets/UploadData';
import UploadDataCard from '../../assets/UploadDataCard';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { getAllBlog, getBlogById, updateBlog, uploadDocToS3 } from '../../actions';
import { enqueueSnackbar } from 'notistack';
import JoditEditor from 'jodit-react';

export default function Update({ updateDialog, setUpdateDialog, id, setId, slug }) {

  const dispatch = useDispatch()

  const [date, setDate] = useState('');
  const [blogName, setBlogName] = useState('');
  const [postedBy, setPostedBy] = useState('');
  const [excerpt, setExcerpt] = useState('');
  const [description, setDescription] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [profileImage1, setProfileImage1] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailImage1, setThumbnailImage1] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);


  const handleUploadProfileImage = (e) => {
    const file = e.target.files[0];
    setLoadingImage(false)
    if (file) {
      var formData = new FormData();
      formData.append("documents", file);
      dispatch(uploadDocToS3(formData, (data) => {
        setLoadingImage(false)
        setProfileImage(data.data.documents[0]);
      }, (err) => {
        setLoadingImage(false)
        console.log(err)
      }))
    }
  }

  const handleUploadThumbnailImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      var formData = new FormData();
      formData.append("documents", file);
      dispatch(uploadDocToS3(formData, (data) => {
        setThumbnailImage(data.data.documents[0]);
      }, (err) => {
        console.log(err)
      }))
      // setThumbnailImage(URL.createObjectURL(file))
    }
  }

  useEffect(() => {
  console.log(slug);
  dispatch(getBlogById({ id: slug }, (data) => {
        console.log("BlogById data = ", data);
        setBlogName(data.data[0].blogName)
        setPostedBy(data.data[0].postedByName)
        setDescription(data.data[0].description)
        setDate(data.data[0].blogDate)
        setProfileImage(data.data[0].profileImage)
        setThumbnailImage(data.data[0].thumbnailImage)
        console.log(data.data[0].thumbnailImage)
      }, (err) => {
        console.log(err);
      },
      ))
  }, [slug]);

  const handleSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    if (!blogName || !postedBy || !date || !description) {
      enqueueSnackbar('Please fill in all required fields', { variant: 'error' });
      setLoading(false);
      return;
    }
    // formData.append('profileImage', profileImage);
    // formData.append('thumbnailImage', thumbnailImage);
    formData.append('id', id);
    formData.append('blogName', blogName);
    formData.append('blogDate', date);
    formData.append('postedByName', postedBy.trim());
    formData.append('excerpt', description.trim());
    formData.append('description', description.trim().replace(/\n+/g, '\r'));
    formData.append('profileImage', profileImage);
    formData.append('thumbnailImage', thumbnailImage);

    dispatch(
      updateBlog(formData, (data) => {
        console.log("Here is Data:", data);
        enqueueSnackbar(`Blog Updated Successfully`, { variant: 'success' });
        setLoading(false);
        setUpdateDialog(false);
        setBlogName('');
        setPostedBy('')
        setDescription('');
        setExcerpt('')
        setDate('');
      }, (err) => {
        const message = err?.response?.data? err?.response?.data : "Error occurred! please try again";
        enqueueSnackbar(message, {variant: "error"});
        setLoading(false);
      })
    );
  };

  const handleReset = () => {
    setBlogName('');
    setPostedBy('')
    setDescription('');
    setExcerpt('')
    setDate('');
    setProfileImage("")
    setThumbnailImage("")
    setId("")
  };


  const editor = useRef(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: 'Enter description...'
  }
  return (
    <Dialog
      maxWidth="md"
      open={updateDialog}
      onClose={() => setUpdateDialog(!updateDialog)}
      fullWidth
    >
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Update Blog
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={() => setUpdateDialog(!updateDialog)}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <UploadData name={'Upload  Profile Image'}>
              <UploadDataCard>
                {/* <img src="/Images/uploadImg.png" alt="Profile"/> */}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleUploadProfileImage(e)}
                />
                {loadingImage ? 
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <CircularProgress size={30}/>
                </Box>
                :
                <>
                {profileImage != null ? <img src={profileImage} value={profileImage} alt="Uploaded" /> : <img src="/uploadImg.png" alt="Profile" />}
                </>
              }
                </UploadDataCard>
            </UploadData>
          </Grid>
          <Grid item md={6}>
            <UploadData name={'Upload  Thumbnail Image'}>
              <UploadDataCard>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleUploadThumbnailImage(e)}
                />
                {thumbnailImage != null ? <img src={thumbnailImage} value={thumbnailImage} alt="Uploaded" /> : <img src="/uploadImg.png" alt="thumbnail" />}
              </UploadDataCard>
            </UploadData>
          </Grid>
          <Grid item md={12}>
            <Divider />
          </Grid>
          <Grid item md={6}>
            <InputStyle topSpace={true} name="Blog Name*">
              <input type="text" value={blogName} onChange={(e) => setBlogName(e.target.value)} />
            </InputStyle>
          </Grid>
          <Grid item md={6}>
            <InputStyle topSpace={true} name="Blog Date*">
              <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
            </InputStyle>
          </Grid>
          <Grid item md={6}>
            <InputStyle topSpace={true} name="Posted by - Name*">
              <input type="text" value={postedBy} onChange={(e) => setPostedBy(e.target.value)} />
            </InputStyle>
          </Grid>
          <Grid item md={12}>
            <InputStyle topSpace={true} name="Description*">
              {/* <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} /> */}
              <JoditEditor
                ref={editor}
                value={description}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => setDescription(newContent)} // preferred to use only this option to update the content for performance reasons
              />
            </InputStyle>
          </Grid>
          <Grid item md={12}>
            <Stack direction={{ md: 'row', xs: 'column' }} spacing={1}>
              <Button variant="contained" sx={{ width: 120 }} disabled={loading} onClick={() => handleSubmit()}>
                {loading
                  ?
                  <CircularProgress size={24} />
                  :
                  "Update"}
              </Button>
              <Button variant="outlined" sx={{ width: 120 }} onClick={handleReset}>
                Reset
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
