import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogContent,

  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { updateClaimByAdmin } from '../../actions';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'notistack';


export default function EligibleClaimsRemarks({ claimId, remark, setRemark, addRemarkAction, setAddRemarkAction }) {

const dispatch = useDispatch();

const handleSubmit=(e)=>{
    e.preventDefault() 
    let payload = {
      id:claimId,
      remark
    }

    dispatch(updateClaimByAdmin(payload, (data) => {
      enqueueSnackbar(data?.message, { variant: 'success' });
      setRemark("");
    }, (err) => {
      enqueueSnackbar("Error Occured! please try again", { variant: 'error' });
    }))
    setAddRemarkAction(false);
}

  const closeHandler = () => setAddRemarkAction(false);
  const ButtonStyle = {
    maxWidth: 150,
    boxShadow: 0,
    width: '100%',
    marginTop:4
  };
  return (
    <Dialog
      open={addRemarkAction}
      onClose={closeHandler}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <div>
            <Typography variant="title" component="h3">
              Remarks
            </Typography>
          </div>
          <div>
            <IconButton
              sx={{ border: '2px solid #DFDFE6' }}
              size="small"
              onClick={closeHandler}
            >
              <Close sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </div>
        </Stack>
        <Box component={'from'} flexGrow={1}>
            <form onSubmit={handleSubmit}>
        <textarea value={remark} onChange={(e)=>setRemark(e.target.value)} className='inputBox'style={{resize:'none',height:'300px', width:'100%'}}  placeholder="Write here"></textarea>
        <Button variant="contained" sx={ButtonStyle} type='submit'>
            Done
        </Button>
        </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
