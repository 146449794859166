import { Close } from '@mui/icons-material';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import InputStyle from '../../assets/InputStyle';
import { useDispatch } from 'react-redux';
import { addPayment, updateClaimByAdmin, updateInvoice } from '../../actions';
import { enqueueSnackbar } from 'notistack';


export default function InvoiceTransactionDetailsForm({ claimId, invoiceId, transactionDetailsAction, setTransactionDetailsAction, invoicePrice }) {

    const dispatch = useDispatch();

    const [transactionID, setTransactionID] = useState("")
    const [transactionDate, setTransactionDate] = useState("")
    const [loading, setLoading] = useState(false)
    const [price, setPrice] = useState(invoicePrice);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!transactionID || !transactionDate) {
            enqueueSnackbar("Please enter transaction date and Id", { variant: 'warning' });
        } else {
            setLoading(true);
            const payyload = {
                invoiceId,
                transactionDate,
                transactionAmount: price,
                transactionId: transactionID
            }
            dispatch(addPayment(payyload, (data) => {
                const payload = {
                    id: invoiceId,
                    status: 'settled'
                }
                dispatch(updateInvoice(payload, (data) => {
                    const payload1 = {
                        id: claimId,
                        claimStatus: 'recieved'
                    }
                    dispatch(updateClaimByAdmin(payload1, (data) => {
                        enqueueSnackbar("Success", { variant: 'success' });
                        window.location.reload();
                        setLoading(false);
                    }, (err) => {
                        console.log(err);
                        enqueueSnackbar("Error Occurred while updating", { variant: 'error' });
                        setLoading(false);
                    }))
                }, (err) => {
                    console.log(err);
                    enqueueSnackbar("Error Occurred while creating api", { variant: 'error' });
                    setLoading(false);
                }))
            }, (err) => {
                setLoading(false);
            }
            ))
        }
    }

    const closeHandler = () => setTransactionDetailsAction(false);
    const ButtonStyle = {
        maxWidth: 150,
        boxShadow: 0,
        width: '100%',
        marginTop: 4
    };
    return (
        <Dialog
            open={transactionDetailsAction}
            onClose={closeHandler}
            maxWidth="xs"
            fullWidth
        >
            <DialogContent sx={{ width: '100%' }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mb: 3 }}
                >
                    <div>
                        <Typography variant="title" component="h3">
                            Transaction Details
                        </Typography>
                    </div>
                    <div>
                        <IconButton
                            sx={{ border: '2px solid #DFDFE6' }}
                            size="small"
                            onClick={closeHandler}
                        >
                            <Close sx={{ fontSize: '1.2rem' }} />
                        </IconButton>
                    </div>
                </Stack>
                <Box component={'from'}  >
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={4}>
                            <Grid item md={12}>
                                <InputStyle name="Transaction ID">
                                    <input
                                        type="text"
                                        name=""
                                        id=""
                                        placeholder="Enter Transaction ID"
                                        value={transactionID}
                                        onChange={(e) => setTransactionID(e.target.value)}
                                    />
                                </InputStyle>
                            </Grid>
                            <Grid item md={12}>
                                <InputStyle name="Transaction Date">
                                    <input
                                        type="date"
                                        name=""
                                        id=""
                                        placeholder="Enter Transaction Date"
                                        value={transactionDate}
                                        onChange={(e) => setTransactionDate(e.target.value)}
                                    />
                                </InputStyle>
                            </Grid>
                            <Grid item md={12}>
                                <InputStyle name="Transaction Amount">
                                    <input
                                        type="number"
                                        name=""
                                        id=""
                                        placeholder="Enter Transaction Amount"
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </InputStyle>
                            </Grid>


                            <Grid item md={12}>
                                <Button variant="contained" sx={ButtonStyle} type='submit' disabled={loading}>
                                    {
                                        loading
                                            ?
                                            <CircularProgress size={22} />
                                            :
                                            "Submit"
                                    }
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                </Box>
            </DialogContent>
        </Dialog>
    );
}



