import React, { useEffect, useState } from "react";
import edit from '../../assets/img/edit.svg';
import { Box, Checkbox, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ArrowBackIosNewRounded, ArrowForwardIosRounded, CloseRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { getAllContactUs, updateContactStatus } from "../../actions";
import { enqueueSnackbar } from "notistack";

const ContactUsTable = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [selectedMessage, setSelectedMessage] = useState("");
    const [allQuries, setAllQuries] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAllContactUs({ pageSize: countPerPage, pageNo: currentPage }, (data) => {
            setAllQuries(data.data);
        }, (err) => {
            setAllQuries([]);
        }));
    }, []);

    const handleStatusChange = (e, id) => {
        const payload = {
            status: e.target.value,
            id
        }
        dispatch(updateContactStatus(payload, (data) => {
            enqueueSnackbar("Contact Query upadated sucessfully", {variant:'success'});
            setTimeout(()=>{
                window.location.reload();
            }, 1000);
        }, (err) => {
            enqueueSnackbar("Error Occured! While tried updating query, please try later", {variant:'error'});
        }))
    }
    return (
        <>
            <Paper>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox />
                                </TableCell>
                                {headData.map((item, index) => (
                                    <TableCell
                                        key={index}
                                        style={{ minWidth: "200px" }}
                                        align="center"
                                    >
                                        <Typography
                                            sx={{
                                                color: "mute.darktext",
                                                textTransform: "uppercase",
                                                fontSize: "0.9rem",
                                            }}
                                        >
                                            {item}
                                        </Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allQuries.map((item, index) => (
                                <>
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? "#F9FAFC" : "#fff" }}
                                    >
                                        <TableCell>
                                            <Checkbox />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.name}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.email}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.service}</TableCell>
                                        <TableCell sx={{ textAlign: 'center' }}>{item?.phoneNumber}</TableCell>
                                        <TableCell sx={{ textAlign: 'center', cursor: item?.msg?.length > 20 ? 'pointer' : 'default' }} onClick={item?.msg?.length > 20 ? () => { setShowMessage(true); setSelectedMessage(item?.msg) } : {}}>{item?.msg?.length > 20 ? `${item?.msg?.substring(0, 20)?.trim()}...` : item?.msg}</TableCell>
                                        <TableCell>
                                            <select value={item?.status} onChange={(e) => handleStatusChange(e, item?._id)}>
                                                <option value="All" selected disabled>
                                                    Select
                                                </option>
                                                <option value="contacted">Contacted</option>
                                                <option value="not_contacted">Not Contactacted</option>
                                            </select>
                                        </TableCell>
                                        {/* <TableCell>
                        <Link
                        onClick={() => {
                            setSelectedRole(item);
                            setUpdateModal(true);
                        }}
                        className="link"
                        >
                        {item.name}
                        </Link>
                    </TableCell> */}
                                        {/* <TableCell sx={{ textAlign: 'center' }}><img src={edit} /> </TableCell> */}
                                        {/* <TableCell>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <Select
                        value={item.permission}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        >
                        <MenuItem value="" disabled selected>
                        Choose permission
                        </MenuItem>
                        <MenuItem value={"read"}>Read</MenuItem>
                        <MenuItem value={"write"}>Write</MenuItem>
                        </Select>
                        </FormControl>
                        </TableCell>
                        <TableCell>
                        <Switch checked={item.isDeleted} onChange={() => {}} />
                    </TableCell> */}
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Box
                sx={{
                    bgcolor: "mute.dark",
                    borderRadius: 1,
                    color: "dark.text",
                    p: 2,
                    mt: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > allQuries?.length ? allQuries?.length : currentPage * countPerPage} of {allQuries?.length}</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Typography> Rows per page:</Typography>
                    <select style={{ marginTop: "-5px" }}
                        value={countPerPage}
                        onChange={(e) => setCountPerPage(e.target.value)}>
                        <option value="10" >
                            10
                        </option>
                        <option value="20">
                            20
                        </option>
                        <option value="30">
                            30
                        </option>
                    </select>
                    <Box sx={{ ml: 2 }}>
                        <Stack
                            direction={"row"}
                            spacing={2}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Box>
                                <IconButton
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage === 1 || countPerPage > allQuries?.length}
                                    sx={{ border: "1px solid", borderColor: "mute.main" }}
                                >
                                    <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                </IconButton>
                            </Box>
                            <Box>
                                <Typography variant="text" component={"span"}>
                                    {currentPage}/{Math.ceil(allQuries?.length / countPerPage)}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={countPerPage > allQuries?.length || currentPage == Math.ceil(allQuries?.length / countPerPage)}
                                    sx={{
                                        border: "1px solid",
                                        borderColor: "mute.main",
                                        bgcolor: "white.main",
                                    }}
                                >
                                    <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                </IconButton>
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </Box>

            <Dialog
                open={showMessage}
                onClose={() => setShowMessage(false)}
                minWidth="lg"
            >
                <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
                    {"Query Message"} <CloseRounded sx={{ float: 'right', cursor: 'pointer' }} onClick={() => setShowMessage(false)} />
                    <hr style={{ marginTop: '5px' }} />
                </DialogTitle>
                <DialogContent sx={{ width: '400px' }}>
                    <Typography variant="text" component="h3">
                    </Typography>
                    {selectedMessage}
                </DialogContent>
            </Dialog>
        </>
    )
}

const headData = ["Name", "Email", "Service", "Phone Number", "Message", "Status"];
export default ContactUsTable;