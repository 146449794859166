import React, { useCallback, useEffect } from "react";
import Layout from "../../assets/Layout";
import { BoughtData } from '../../utils/data';
import Rating from '@mui/material/Rating';
import { sectionBarBtn } from '../../Style/stylesheet';
import AddIcon from '@mui/icons-material/Add';
import Edit from '../../assets/img/edit.svg';
import Delete from '../../assets/img/Delete.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Stack,
    Paper,
    MenuItem,
    Select,
    FormControl,
    Button,
    Switch,
} from '@mui/material';

import {

    ArrowBackIosNewRounded,
    ArrowForwardIosRounded,
    Draw,
} from '@mui/icons-material';

import { useState } from "react";
// import ClaimsRemarks from "../../Claims/ClaimsRemarks";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ButtonController from "../../assets/ButtonController";
import SearchFilter from "../../assets/SearchFilter";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteSubscription, getAllSubscription, getAllVisits, updateVisitById } from "../../actions";
import { enqueueSnackbar } from "notistack";
// import VisitRemarks from "./../VisitRemarks";
import Subscribers from './Subscribers'
import EditSubscription from "./EditSubscription";
import ActivateSubscription from "./PurchaseSubscription/ActivateSubscription";
const Subscription = () => {
    const [sectionBarActive, setSectionBarActive] = useState('Paid')
    const [sectionBarActive2, setSectionBarActive2] = useState('Claims')
    const [status, setStatus] = useState('');
    const [addRemarkAction, setAddRemarkAction] = useState(false);
    const [allVisits, setAllVisits] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [countPerPage, setCountPerPage] = useState(10); // Number of items to display per page
    const [searchQuery, setSearchQuery] = useState('');
    const [remark, setRemark] = useState("");
    const [claimId, setClaimId] = useState('')
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [openSubscriberModal, setSubscriberModal] = React.useState(false);
    const [subscription, setSubscription] = useState([]);
    const [subscriptionId, setSubscriptionId] = React.useState("");
    const [allSubscription, setAllSubscription] = useState('');


    const headData = [
        'Subscription Name',
        'Price',
        'No. of Visits ',
        'Banner Days',
        'Flash Day',
        'Description',
        'No. of Properties',
        'No. of Subscribers',
        'Color code',
        'Subliner',
        // 'Status',
        'Action',


    ];


    const handleSelectChange = (event) => {
        setCountPerPage(event.target.value);
    };

    const handleButtonClick = (id) => {
        navigate(`/visit-details/${id}`)
    };

    const handleChange = (event) => {
        setStatus(event.target.value);
    };

    const loadAllSubscription = useCallback(() => {
        dispatch(getAllSubscription({}, (data) => {
            setAllSubscription(data.data);
        }))

    }, [dispatch])

    useEffect(() => {
        dispatch(getAllSubscription({ pageNo: currentPage, pageSize: countPerPage }, (data) => {

            const filter = data.data.filter((item) => {

                if (item.subscriptionName?.toLowerCase().includes(searchQuery?.toLowerCase())) {
            console.log("subscription")

                    return true
                }


                if (item.description?.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if (item.colorCode?.includes(searchQuery?.toLowerCase())) {
                    return true
                }

                if ( item.price?.toString().includes(searchQuery?.toLowerCase())) {
                    return true;
                }

                return false;
            })
            console.log("subscription", filter)
            setSubscription(filter);
        }))
    }, [currentPage, countPerPage, searchQuery,]);


    useEffect(() => {
        loadAllSubscription()
    }, [loadAllSubscription]);

    const handleDelete = (id) => {
        let payload = {
            id
        }
        console.log("Here is Payload", payload)
        dispatch(
            deleteSubscription(payload, () => {
                enqueueSnackbar("Delete Successfull!", { variant: 'success' })
                loadAllSubscription()
                window.location.reload()
            },
            )
        )
    }

    const handleEditModalOpen = (id) => {
        setSubscriptionId(id)
        setOpenEditModal(true);
    };

    const handleSubscriberModal = (id) => {
        setSubscriptionId(id)
        setSubscriberModal(true);
    };

    const handleEditModalClose = () => {
        setOpenEditModal(false);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Layout path="normalVisits">
                <Stack
                    direction={{ md: 'row', xs: 'column' }}
                    spacing={3}
                    sx={{ my: 4 }}
                    justifyContent={'space-between'}
                >
                    <Breadcrumbs primary="Subscription - New Subscription" />
                    {/* <ButtonController>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowBackIosNewRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                        <Box>
                            <Typography variant="text" component={'span'}>
                                <strong>Jun 24, 2022</strong> Today
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton className="iconBtn">
                                <ArrowForwardIosRounded sx={{ fontSize: '0.8rem' }} />
                            </IconButton>
                        </Box>
                    </ButtonController> */}
                    <Button variant="contained" disableElevation onClick={handleEditModalOpen} >
                        <Link to="/add-subscriptions" style={{ display: 'flex', justifyContent: 'cente', alignItems: 'center' }}> <AddIcon />Add Subscription</Link>
                    </Button>
                </Stack>




                <SearchFilter my={2}>
                    <Box className="search style-1" >
                        <input type="text" placeholder="Search..." value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                    </Box>
                </SearchFilter>

                <Paper sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox />
                                    </TableCell> */}
                                    <TableCell>#</TableCell>
                                    {headData.map((item, index) => (
                                        <TableCell key={index} style={{ minWidth: '200px' }} align="left">
                                            <Typography
                                                sx={{
                                                    color: 'mute.darktext',
                                                    textTransform: 'uppercase',
                                                    fontSize: '0.9rem',
                                                }}
                                            >
                                                {item}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {subscription?.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ bgcolor: index % 2 === 0 ? '#F9FAFC' : '#fff' }}
                                    >
                                        {/* <TableCell>
                                            <Checkbox />
                                        </TableCell> */}
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell sx={{}}>{item.subscriptionName}</TableCell>
                                        <TableCell sx={{ color: '#D12953' }}>{item.price}</TableCell>
                                        <TableCell sx={{}}>{item.numberOfVisit}</TableCell>
                                        <TableCell sx={{}}>{item.bannerDays}</TableCell>
                                        <TableCell sx={{}}>{item.flashDays}</TableCell>
                                        <TableCell sx={{}}>{item.description}</TableCell>
                                        <TableCell>
                                            {
                                                item?.propertyList?.length > 0 ? (
                                                    item.propertyList.map((property, index) => (
                                                        <Typography key={index}>{property?.name || ""}</Typography>
                                                    ))
                                                ) : (
                                                    ""
                                                )
                                            }
                                        </TableCell>

                                        <TableCell sx={{}} onClick={() => handleSubscriberModal(item?._id)} >{item.noOfSubscriptions}</TableCell>
                                        <TableCell sx={{}}>{item.colorCode}</TableCell>
                                        <TableCell sx={{}}>{item.subLiner}</TableCell>

                                        {/* <TableCell>
                                            <Switch defaultChecked />
                                        </TableCell> */}
                                        <TableCell >
                                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                <IconButton onClick={() => handleEditModalOpen(item._id)}>
                                                    <img src={Edit} />
                                                </IconButton>
                                                <IconButton onClick={() => handleDelete(item._id)}>
                                                    <Box  > <img src={Delete} /></Box>
                                                </IconButton>
                                            </Box>
                                        </TableCell>


                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Box
                    sx={{
                        bgcolor: "mute.dark",
                        borderRadius: 1,
                        color: "dark.text",
                        p: 2,
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box>
                        <Typography>{((currentPage - 1) * countPerPage) + 1} - {countPerPage > subscription?.length ? subscription?.length : currentPage * countPerPage} of {allSubscription?.length}</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Typography> Rows per page:</Typography>
                        <select style={{ marginTop: "-5px" }}
                            value={countPerPage}
                            onChange={(e) => setCountPerPage(e.target.value)}>
                            <option value="10" >
                                10
                            </option>
                            <option value="20">
                                20
                            </option>
                            <option value="30">
                                30
                            </option>
                        </select>
                        <Box sx={{ ml: 2 }}>
                            <Stack
                                direction={"row"}
                                spacing={2}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage - 1)}
                                        disabled={currentPage === 1 || countPerPage > allSubscription?.length}
                                        sx={{ border: "1px solid", borderColor: "mute.main" }}
                                    >
                                        <ArrowBackIosNewRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                                <Box>
                                    <Typography variant="text" component={"span"}>
                                        {currentPage}/{Math.ceil(allSubscription?.length / countPerPage)}
                                    </Typography>
                                </Box>
                                <Box>
                                    <IconButton
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={countPerPage > allSubscription?.length || currentPage == Math.ceil(allSubscription?.length / countPerPage)}
                                        sx={{
                                            border: "1px solid",
                                            borderColor: "mute.main",
                                            bgcolor: "white.main",
                                        }}
                                    >
                                        <ArrowForwardIosRounded sx={{ fontSize: "0.8rem" }} />
                                    </IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Layout>

            {
                open === true ?
                    <Subscribers open={open} setOpen={setOpen} />
                    :
                    ""
            }

            {
                openSubscriberModal === true ?
                    <Subscribers open={openSubscriberModal} setOpen={setSubscriberModal} id={subscriptionId} />
                    :
                    ""
            }


            {
                openEditModal === true ?
                    <EditSubscription open={openEditModal} setOpen={setOpenEditModal} id={subscriptionId} />
                    :
                    ""
            }


            {/* <VisitRemarks
                remark={remark}
                setRemark={setRemark}
                claimId={claimId}
                claimStatus={status}
                addRemarkAction={addRemarkAction}
                setAddRemarkAction={setAddRemarkAction}
            /> */}
        </>
    );
}

export default Subscription;